import React from 'react';
import CheckBox from '../../bootstrapComponent/checkBox';
import TextInputParallel from '../../bootstrapComponent/textInputParallel';
import SelectInput from '../../bootstrapComponent/selectInput';
const UpdateMaterialModal = ({id,updateMaterial,onChange, updateItem, Activate, DeActivate,asiMaterials,ashlinMaterials,addKeyword, deleteKeyword,translate})=>{
    const ashlinMaterialCode = ashlinMaterials.map(i=>i.materialCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="upDateMaterialModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="upDateMaterialModalLabel" style={{textAlign:'center !important'}}>Update A Material</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <SelectInput  id={'update_ashlin_materials'} label={'Materials Code'} name={'materialCode'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={ashlinMaterialCode}    onChange={(e)=>onChange(e,'updateMaterial')}/>
                        <SelectInput  id={'update_asi_material'} label={'Associated ASI Material'} name={'pickedMaterial'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={asiMaterials}  defaultValue={updateMaterial.pickedMaterial}  onChange={(e)=>onChange(e,'updateMaterial')}/>
                        <TextInputParallel id='update_material_hts' label={'Material HTS Description'}
                                           value={updateMaterial.materialHTSDescription} name='materialHTSDescription' onChange={(e)=>onChange(e,'updateMaterial')}/>
                        <br/>
                        <div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                                </div>
                                <div className={'col-2'}>
                                    <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>translate('updateMaterial')}>TRANSLATE</button>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateMaterial.shortENG}
                                              onChange={(e)=>onChange(e,'updateMaterial')} name={'shortENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateMaterial.shortFR}
                                              onChange={(e)=>onChange(e,'updateMaterial')} name={'shortFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateMaterial.longENG} rows="5"
                                              onChange={(e)=>onChange(e,'updateMaterial')} name={'longENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateMaterial.longFR} rows="5"
                                              onChange={(e)=>onChange(e,'updateMaterial')} name={'longFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Web Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateMaterial.webENG} rows="5"
                                              onChange={(e)=>onChange(e,'updateMaterial')} name={'webENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateMaterial.webFR} rows="5"
                                              onChange={(e)=>onChange(e,'updateMaterial')} name={'webFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <br/>
                            <div className={'row justify-content-center'}>
                                <CheckBox  value={updateMaterial.active === 1}  id={'update-material-active'}
                                           onChange={(e)=>onChange(e,'updateMaterial')} name={'active'} label={'Is this Material Active'}/>
                            </div>
                            <hr/>
                            <div className={'row justify-content-center'}>
                                <div className={'col-3'}>
                                    <h4> Keyword Box</h4>
                                </div>
                                <div className={'col-7'}>
                                    <input type={'text'} style={{width:'200px'}} value={updateMaterial.keyword} name={'keyword'}  onChange={(e)=>onChange(e,'updateMaterial')} />
                                    <button className={'btn-success'} style={{width:'75px', margin:'0 15px'}} onClick={()=>addKeyword(updateMaterial.searchKeyWords,updateMaterial.keyword,'updateMaterial','searchKeyWords')}>Add</button>
                                    <button className={'btn-danger'} style={{width:'75px'}} onClick={()=>deleteKeyword(updateMaterial.searchKeyWords,updateMaterial.keyword,'updateMaterial','searchKeyWords')}>Delete</button>
                                    <textarea className={'col'} style={{width:'100%', marginTop:'10px'}} value={updateMaterial.searchKeyWords} rows="5"
                                              readOnly >

                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>updateItem(e,'updateMaterial')}>Update Material</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'updateMaterial','materialCode')} >Deactivate Material</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'updateMaterial','materialCode')}>Activate Material</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default UpdateMaterialModal;