import axios from 'axios';
import { baseURL, PRODUCTION, proxyurl } from "./requestHelper";
import moment from 'moment';
import { bestbuyMarketplaceOrders } from './test/bestbuyMarketOrders'
const Json2csvParser = require('json2csv').Parser;
const BASE_URL = proxyurl + 'https://marketplace.bestbuy.ca/api';
const KEY = "3128f278-df8d-4419-a8da-1b4831645382";
const headers = {
    'Content-Type': 'application/json',
    'Authorization': KEY
};
const generateDate=()=>{
    const d = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
    return d.slice(0,19);
};
export const getOffer= async (sku)=>{
    return await axios.get(`${BASE_URL}/offers?sku=${sku}`,{headers});
};

export const bestbuySearchProductBySku = async (sku)=>{
    try{
        const res =  (await axios.get(`${BASE_URL}/offers?sku=${sku}`,{headers})).data.offers[0];

        if(res.discount===null){
            return {
                sku,
                listPrice : res.price,
                discount : false,
                currency : res.currency_iso_code
            }
        }else{

            const isOnDiscount = new Date(res.discount.end_date) > new Date();

            const regularPrice = res.price;
            const listPrice = isOnDiscount ? res.discount.discount_price : regularPrice;

            return {
                sku,
                listPrice,
                regularPrice,
                discount: isOnDiscount,
                currency : res.currency_iso_code,
                productId: res.product_references[0].reference,
                quantity: res.quantity,
            }
        }
    }catch (e) {
        return undefined
    }
};

const bestBuy_offer = (obj)=>{
    return {
        "available_ended": null,
        "available_started": '2018-07-23T13:39:27',
        "description": "This is description field",
        "internal_description": "This is internal description field",
        "logistic_class": "This is logistic_class field",
        "min_quantity_alert": 1,
        "price": 30.50,
        "product_id": "12345678901",
        "product_id_type": "UPC-A",
        "quantity": 2,
        "shop_sku": "TEST1",
        "state_code": "New",
        "update_delete": ""
    }
};
export const bestbuyFeedPrices = async (priceList)=>{
    const offers = priceList.map(p=>{
        if(p.applyDiscount){
            return {
                "available_ended": null,
                "available_started": null,
                "description": null,
                "internal_description": null,
                "min_quantity_alert": null,
                "price": p.regularPrice,
                "product_id": p.productId,
                "product_id_type": "UPC-A",
                "quantity": p.quantity,
                "shop_sku": p.sku,
                "state_code":"11",
                "update_delete": "update",
                "discount": {
                    "end_date": moment(p.discountEndDate).format('YYYY-MM-DD'),
                    "price": p.listPrice,
                    "start_date": moment(p.discountStartDate).format('YYYY-MM-DD'),
                }
            }
        }else{
            return {
                "available_ended": null,
                "available_started": null,
                "description": null,
                "internal_description": null,
                "min_quantity_alert": null,
                "price": p.listPrice,
                "product_id":  p.productId,
                "product_id_type": "UPC-A",
                "quantity": p.quantity,
                "shop_sku": p.sku,
                "state_code":"11",
                "update_delete": "update"
            }
        }
    });
    return await updateOffers(offers);
};
export const getBestBuyOffer = async (Sku)=>{
    const bestBuyRes = await getOffer(Sku);
    return bestBuyRes.data.offers[0];
};
export const bestBuy_offer_discount = (offer)=>{
    if(offer.applyDiscount){
        return {
            "available_ended": null,
            "available_started": null,
            "description": null,
            "internal_description": null,
            "min_quantity_alert": null,
            "price": parseFloat(offer.price).toFixed(2)*1,
            "product_id": offer.product_references[0].reference,
            "product_id_type": "UPC-A",
            "quantity": offer.quantity,
            "shop_sku": offer.shop_sku,
            "state_code":"11",
            "update_delete": "update",
            "discount": {
                "end_date": offer.discountEndDate.format('YYYY-MM-DD'),
                "price": parseFloat(offer.discountPrice).toFixed(2)*1,
                "start_date": offer.discountStartDate.format('YYYY-MM-DD')
            }
        }
    }
    return {
        "available_ended": null,
        "available_started": null,
        "description": null,
        "internal_description": null,
        "min_quantity_alert": null,
        "price": parseFloat(offer.price).toFixed(2)*1,
        "product_id": offer.product_references[0].reference,
        "product_id_type": "UPC-A",
        "quantity": offer.quantity,
        "shop_sku": offer.shop_sku,
        "state_code":"11",
        "update_delete": "update"
    }
};
//2018-07-24T19:31:23Z
export const updateOffers = async (offers)=>{
    const data = {"offers": offers};
    //console.log(data);
    return await axios.post(`${BASE_URL}/offers`,data,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': KEY
        }
    });
};
const bestBuy_offer_to_inventory = (offer,inventory)=>{

    const discount = offer.discount === null? null:{
        "end_date": offer.discount.end_date,
        "price": offer.discount.discount_price,
        "ranges": [{
            "price": offer.discount.discount_price,
            "quantity_threshold": inventory
        }],
        "start_date": offer.discount.discount_start_date
    };
    return{
            "available_ended": null,
            "available_started": null,
            "description": null,
            "discount": discount,
        "internal_description": null,
            "min_quantity_alert": null,
            "logistic_class":"SMALL",
            "offer_additional_fields": [
                {
                    "code": "manufacturer-warranty",
                    "type": "REGEX",
                    "value": "365"
                }
            ],
            "product_id": offer.product_references[0].reference,
            "product_id_type": "UPC-A",
            "quantity": inventory,
            "price":offer.all_prices[0].unit_origin_price,
            "shop_sku": offer.shop_sku,
            "state_code": "11",
            "update_delete": "update",
            "manufacturer-warranty":"365"
    }
};
export const updateInventory = async (list)=>{
    const offersPromises =  list.map(async i=>{
       const offerRes = await getOffer(i.Sku);
       if(offerRes.data.offers[0] !==undefined)
            return  bestBuy_offer_to_inventory(offerRes.data.offers[0],i.Inventory);
    });
    let data;
    await Promise.all(offersPromises)
        .then( offers => {
            offers = offers.filter(i=>i !== undefined);
            data = {"offers": offers};

        })
        .catch(e => {
        console.error(e);
    });
    //console.log(data);
    return await axios.post(`${BASE_URL}/offers`,data,{
        headers: {
            'Content-Type': 'application/json',
            'Authorization': KEY
        }
    });

};


const item = (obj)=>{
    const image = obj.Urls.GoogleDriveUrls.BASE.join("|");
    let gender = obj.Gender.toUpperCase().replace("'","");
    if( gender==="LADIES" || gender ==="WOMENS"){
        gender = "Women's";
    }else if(gender ==="MENS"){
        gender = "Men's"
    }else{
        gender = "Unisex"
    }
    const title = obj.Brand + " DESIGNER | " + obj.Name + " " + obj.ShortDescription.replace(/[^a-zA-Z0-9 '"]/g, '');
    const titleCheck = title.toUpperCase();
    /*****************MAPPING START**************************/
    let BBYCat = '';
    let WalletClutchType = "";
    let HandbagType="";
    if(titleCheck.includes('TICKET')||titleCheck.includes('PASSPORT')){
        BBYCat = "CAT_37937";
        WalletClutchType = "Travel Wallet";
    }else if(titleCheck.includes('CLUTCH')){
        BBYCat = "CAT_37937";
        WalletClutchType = "Clutch";
    }else if(titleCheck.includes('WALLET')){
        BBYCat = "CAT_37937";
        WalletClutchType = "Wallet";
    }else if(titleCheck.includes('CARD')){
        BBYCat = "CAT_37937";
        WalletClutchType = "Credit Card Wallet & Card Case";
    }else if(titleCheck.includes('SHOULDER')){
        BBYCat = 'CAT_32007';
        HandbagType = "Shoulder Bag";
    }else if(titleCheck.includes('DUFFLE')){
        BBYCat = 'CAT_32007';
        HandbagType= "Duffle Bag";
    }else if(titleCheck.includes('TOTE')){
        BBYCat = 'CAT_32007';
        HandbagType = "Tote Bag";
    }else if(titleCheck.includes('BRIEFCASE')){
        BBYCat = 'CAT_32007';
        HandbagType = "Briefcase";
    }else {
        BBYCat = "CAT_37937";
        WalletClutchType = "Travel Wallet";
    }

    //CAT_37937
    let Depth_14236_CAT_37937_EN = '';
    let Height_15404_CAT_37937_EN = '';
    let ProductLineCollection_35401_CAT_37937_EN= '';
    let ProductLineCollection_35401_CAT_37937_FR = '';
    let Material_23642_CAT_37937_EN = '';
    let Material_23642_CAT_37937_FR = '';
    let Gender_26914_CAT_37937_EN = '';
    let Colour_5105_CAT_37937_EN = '';
    let Colour_5105_CAT_37937_FR = '';
    let CountryofOrigin_26726_CAT_37937_EN = '';
    let PatternPrintDesign_1653147_CAT_37937_EN = '';
    let Width_6823_CAT_37937_EN = '';
    let Weight_6738_CAT_37937_EN = '';
    let WidthInches_25132_CAT_37937_EN = '';
    let HeightInches_25131_CAT_37937_EN = '';
    let DepthInches_25133_CAT_37937_EN = '';
    let WidthInches_25132_CAT_32007_EN = '';
    let Gender_26914_CAT_32007_EN = '';
    let Material_23642_CAT_32007_EN = '';
    let Material_23642_CAT_32007_FR = '';
    let Colour_5105_CAT_32007_EN = '';
    let Colour_5105_CAT_32007_FR = '';
    let CountryofOrigin_26726_CAT_32007_EN = '';
    let Width_6823_CAT_32007_EN = '';
    let PatternPrintDesign_1653147_CAT_32007_EN = '';
    let Weight_5302_CAT_32007_EN = '';
    let Depth_14236_CAT_32007_EN = '';
    let Height_15404_CAT_32007_EN = '';
    let ProductLineCollection_35401_CAT_32007_EN = '';
    let ProductLineCollection_35401_CAT_32007_FR = '';
    let HandbagType_847453_CAT_32007_EN = '';
    let HeightInches_25131_CAT_32007_EN = '';
    let DepthInches_25133_CAT_32007_EN = '';
    if(BBYCat === 'CAT_37937'){
         Depth_14236_CAT_37937_EN = obj.DepthCm;
         Height_15404_CAT_37937_EN = obj.HeightCm;
         ProductLineCollection_35401_CAT_37937_EN= obj.DesignServiceFamilyDescription;
         ProductLineCollection_35401_CAT_37937_FR = obj.DesignServiceFamilyDescriptionFr;
         Material_23642_CAT_37937_EN = obj.Material.replace(/[^a-zA-Z0-9 '"]/g, '');
         Material_23642_CAT_37937_FR = obj.MaterialFr.replace(/[^a-zA-Z0-9 '"]/g, '');
         Gender_26914_CAT_37937_EN = gender;
         Colour_5105_CAT_37937_EN = obj.Color;
         Colour_5105_CAT_37937_FR = obj.ColorFr;
         CountryofOrigin_26726_CAT_37937_EN = obj.Country;
         PatternPrintDesign_1653147_CAT_37937_EN = 'Solid Colour';
         Width_6823_CAT_37937_EN = obj.WidthCm;
         Weight_6738_CAT_37937_EN = obj.WeightGrams;
         WidthInches_25132_CAT_37937_EN = (obj.WidthCm * 0.393701).toFixed(2);
         HeightInches_25131_CAT_37937_EN = (obj.HeightCm * 0.393701).toFixed(2);
         DepthInches_25133_CAT_37937_EN = (obj.DepthCm * 0.393701).toFixed(2);
    }else{
         WidthInches_25132_CAT_32007_EN = (obj.WidthCm * 0.393701).toFixed(2);
         Gender_26914_CAT_32007_EN = gender;
         Material_23642_CAT_32007_EN = obj.Material.replace(/[^a-zA-Z0-9 '"]/g, '');
         Material_23642_CAT_32007_FR = obj.MaterialFr.replace(/[^a-zA-Z0-9 '"]/g, '');
         Colour_5105_CAT_32007_EN = obj.Color;
         Colour_5105_CAT_32007_FR = obj.ColorFr;
         CountryofOrigin_26726_CAT_32007_EN = obj.Country;
         Width_6823_CAT_32007_EN = obj.WidthCm;
         PatternPrintDesign_1653147_CAT_32007_EN = 'Solid Colour';
         Weight_5302_CAT_32007_EN = obj.WeightGrams;
         Depth_14236_CAT_32007_EN = obj.DepthCm;
         Height_15404_CAT_32007_EN = obj.HeightCm;
         ProductLineCollection_35401_CAT_32007_EN = obj.Material.replace(/[^a-zA-Z0-9 '"]/g, '');
         ProductLineCollection_35401_CAT_32007_FR = obj.MaterialFr.replace(/[^a-zA-Z0-9 '"]/g, '');
         HandbagType_847453_CAT_32007_EN = HandbagType;
         HeightInches_25131_CAT_32007_EN = (obj.HeightCm * 0.393701).toFixed(2);
         DepthInches_25133_CAT_32007_EN = (obj.DepthCm * 0.393701).toFixed(2);
    }

    /**********************MAPPING FINISH*******************/
    return{
        "BBYCat":BBYCat,
        "shop_sku":obj.Upc,
        "_Title_BB_Category_Root_EN":title,
        "_Short_Description_BB_Category_Root_EN":obj.ShortDescription.replace(/[^a-zA-Z0-9 '"]/g, ''),
        "_Brand_Name_Category_Root_EN":obj.Brand,
        "_Primary_UPC_Category_Root_EN":obj.Upc,
        "_Model_Number_Category_Root_EN":obj.Model,
        "_Manufacturers_Part_Number_Category_Root_EN":obj.Sku,
        "_Carry_In_Warranty_Days_Category_Root_EN":"365",
        "_Parts_Warranty_Days_Category_Root_EN":"365",
        "_Seller_Image_URL_Category_Root_EN": image,
        "_Long_Description_BB_Category_Root_EN":obj.ExtendedDescription.replace(/[^a-zA-Z0-9 '"]/g, ''),
        "_Long_Description_BB_Category_Root_FR":obj.ExtendedDescriptionFr,
        "_Energy_Star_Indicator_Category_Root_EN":"",
        "_ESRB_Category_Root_EN":"",
        "_Web_Hierarchy_Location_Category_Root_EN":"",
        "_RFIDProtected_42760_CAT_37937_EN":"",
        "_Style_1678013_CAT_37937_EN":"",
        "_Depth_14236_CAT_37937_EN":Depth_14236_CAT_37937_EN,
        "_Height_15404_CAT_37937_EN":Height_15404_CAT_37937_EN,
        "_ProductLineCollection_35401_CAT_37937_EN":ProductLineCollection_35401_CAT_37937_EN,
        "_ProductLineCollection_35401_CAT_37937_FR":ProductLineCollection_35401_CAT_37937_FR,
        "_Material_23642_CAT_37937_EN":Material_23642_CAT_37937_EN,
        "_Material_23642_CAT_37937_FR":Material_23642_CAT_37937_FR,
        "_Gender_26914_CAT_37937_EN":Gender_26914_CAT_37937_EN,
        "_Colour_5105_CAT_37937_EN":Colour_5105_CAT_37937_EN,
        "_Colour_5105_CAT_37937_FR":Colour_5105_CAT_37937_FR,
        "_CountryofOrigin_26726_CAT_37937_EN":CountryofOrigin_26726_CAT_37937_EN,
        "_CountryofOrigin_26726_CAT_37937_FR":"",
        "_Width_6823_CAT_37937_EN":Width_6823_CAT_37937_EN,
        "_Weight_6738_CAT_37937_EN":Weight_6738_CAT_37937_EN,
        "_WhatsintheBox_4667_CAT_37937_EN":"",
        "_WhatsintheBox_4667_CAT_37937_FR":"",
        "_Kids_1227252_CAT_37937_EN":"",
        "_ColourFamily_30576_CAT_37937_EN":"",
        "_PatternPrintDesign_1653147_CAT_37937_EN":PatternPrintDesign_1653147_CAT_37937_EN,
        "_WidthInches_25132_CAT_37937_EN": WidthInches_25132_CAT_37937_EN,
        "_HeightInches_25131_CAT_37937_EN":HeightInches_25131_CAT_37937_EN,
        "_DepthInches_25133_CAT_37937_EN":DepthInches_25133_CAT_37937_EN,
        "_WalletClutchType_848458_CAT_37937_EN":WalletClutchType,
                '_WidthInches_25132_CAT_32007_EN':WidthInches_25132_CAT_32007_EN,
                '_Gender_26914_CAT_32007_EN':Gender_26914_CAT_32007_EN,
                '_Material_23642_CAT_32007_EN':Material_23642_CAT_32007_EN,
                '_Material_23642_CAT_32007_FR':Material_23642_CAT_32007_FR,
                '_Colour_5105_CAT_32007_EN':Colour_5105_CAT_32007_EN,
                '_Colour_5105_CAT_32007_FR':Colour_5105_CAT_32007_FR,
                '_CountryofOrigin_26726_CAT_32007_EN':CountryofOrigin_26726_CAT_32007_EN,
                '_Width_6823_CAT_32007_EN':Width_6823_CAT_32007_EN,
                '_PatternPrintDesign_1653147_CAT_32007_EN':PatternPrintDesign_1653147_CAT_32007_EN,
                '_Weight_5302_CAT_32007_EN':Weight_5302_CAT_32007_EN,
                '_Depth_14236_CAT_32007_EN':Depth_14236_CAT_32007_EN,
                '_Height_15404_CAT_32007_EN':Height_15404_CAT_32007_EN,
                '_ProductLineCollection_35401_CAT_32007_EN':ProductLineCollection_35401_CAT_32007_EN,
                '_ProductLineCollection_35401_CAT_32007_FR':ProductLineCollection_35401_CAT_32007_FR,
                '_HandbagType_847453_CAT_32007_EN':HandbagType_847453_CAT_32007_EN,
                '_HeightInches_25131_CAT_32007_EN':HeightInches_25131_CAT_32007_EN,
                '_DepthInches_25133_CAT_32007_EN':DepthInches_25133_CAT_32007_EN,
                "sku":obj.Sku,
                "product-id":obj.Upc,
                "product-id-type":"UPC-A",
                "description":obj.ShortDescription.replace(/[^a-zA-Z0-9 '"]/g, ''),
                "internal-description":obj.ShortDescription.replace(/[^a-zA-Z0-9 '"]/g, ''),
                "price":(obj.Price-0.01).toFixed(2),
                "price-additional-info":"",
                "quantity":1,
                "min-quantity-alert":null,
                "state":11,
                "available-start-date":"2017-12-25",
                "available-end-date":"",
                "logistic-class":"",
                "discount-price":"",
                "discount-start-date":"",
                "discount-end-date":"",
                "update-delete":"UPDATE",
                "manufacturer-warranty":"365"
    }
};
export const createItems =async (items)=>{
    const fields = [
                'BBYCat',
                'shop_sku',
                '_Title_BB_Category_Root_EN',
                '_Short_Description_BB_Category_Root_EN',
                '_Brand_Name_Category_Root_EN',
                '_Primary_UPC_Category_Root_EN',
                '_Model_Number_Category_Root_EN',
                '_Manufacturers_Part_Number_Category_Root_EN',
                '_Carry_In_Warranty_Days_Category_Root_EN',
                '_Parts_Warranty_Days_Category_Root_EN',
                '_Seller_Image_URL_Category_Root_EN',
                '_Long_Description_BB_Category_Root_EN',
                '_Long_Description_BB_Category_Root_FR',
                '_Energy_Star_Indicator_Category_Root_EN',
                '_ESRB_Category_Root_EN',
                '_Web_Hierarchy_Location_Category_Root_EN',
                '_RFIDProtected_42760_CAT_37937_EN',
                '_Style_1678013_CAT_37937_EN',
                '_Depth_14236_CAT_37937_EN',
                '_Height_15404_CAT_37937_EN',
                '_ProductLineCollection_35401_CAT_37937_EN',
                '_ProductLineCollection_35401_CAT_37937_FR',
                '_Material_23642_CAT_37937_EN',
                '_Material_23642_CAT_37937_FR',
                '_Gender_26914_CAT_37937_EN',
                '_Colour_5105_CAT_37937_EN',
                '_Colour_5105_CAT_37937_FR',
                '_CountryofOrigin_26726_CAT_37937_EN',
                '_CountryofOrigin_26726_CAT_37937_FR',
                '_Width_6823_CAT_37937_EN',
                '_Weight_6738_CAT_37937_EN',
                '_WhatsintheBox_4667_CAT_37937_EN',
                '_WhatsintheBox_4667_CAT_37937_FR',
                '_Kids_1227252_CAT_37937_EN',
                '_ColourFamily_30576_CAT_37937_EN',
                '_PatternPrintDesign_1653147_CAT_37937_EN',
                '_WidthInches_25132_CAT_37937_EN',
                '_HeightInches_25131_CAT_37937_EN',
                '_DepthInches_25133_CAT_37937_EN',
                '_WalletClutchType_848458_CAT_37937_EN',
                '_WidthInches_25132_CAT_32007_EN',
                '_Gender_26914_CAT_32007_EN',
                '_Material_23642_CAT_32007_EN',
                '_Material_23642_CAT_32007_FR',
                '_Colour_5105_CAT_32007_EN',
                '_Colour_5105_CAT_32007_FR',
                '_CountryofOrigin_26726_CAT_32007_EN',
                '_Width_6823_CAT_32007_EN',
                '_PatternPrintDesign_1653147_CAT_32007_EN',
                '_Weight_5302_CAT_32007_EN',
                '_Depth_14236_CAT_32007_EN',
                '_Height_15404_CAT_32007_EN',
                '_ProductLineCollection_35401_CAT_32007_EN',
                '_ProductLineCollection_35401_CAT_32007_FR',
                '_HandbagType_847453_CAT_32007_EN',
                '_HeightInches_25131_CAT_32007_EN',
                '_DepthInches_25133_CAT_32007_EN',
                'sku',
                'product-id',
                'product-id-type',
                'description',
                'internal-description',
                'price',
                'price-additional-info',
                'quantity',
                'min-quantity-alert',
                'state',
                'available-start-date',
                'available-end-date',
                'logistic-class',
                'discount-price',
                'discount-start-date',
                'discount-end-date',
                'update-delete',
                'manufacturer-warranty'

             ];
    const testItem = [];
    items.forEach(i=>{
        testItem.push(item(i));
    });

    const json2csvParser = new Json2csvParser({ fields });
    const csv = json2csvParser.parse(testItem);
    //console.log(csv);
    const blob = new Blob([csv], { type: 'text/csv' });
    //console.log(blob);
    let data = new FormData();
    data.append("file", blob);
    data.append("import_mode", "NORMAL");
    data.append("with_products", "true");
    return await axios.post(`${BASE_URL}/offers/imports`,data,{
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': KEY
        }
    });
};

export const getAllOrders = async()=>{
    try {
        if(PRODUCTION )
            return (await axios.get(`${BASE_URL}/orders?paginate=false`,{headers})).data.orders;
        return bestbuyMarketplaceOrders.orders;
    }  catch (e) {
        return [];
    }
};
export const toInsightsModelMapper = (orders)=>{
    return orders.map(order=>{
        const status = order.order_state  === "RECEIVED"? "Closed":order.order_state;
        return  {
            "LeanSupplyOrderId": "",
            "MerchantName": "Bestbuy Marketplace",
            "Amount": parseInt(order.total_price - order.total_commission),
            "ChannelId": "4753cc0f-74c1-4684-ba0e-05e0238963c6",
            "ChannelOrderId": order.order_id,
            "LastUpdated": order.last_updated_date,
            "FulfillmentStatus": status ,
            "TrackingNumber": order.shipping_tracking,
            "TaxState": ""
        }
    });
};

