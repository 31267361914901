import React from 'react';
import CheckBox from '../../bootstrapComponent/checkBox';
import SelectInput from '../../bootstrapComponent/selectInput';
const updateColorModal = ({id,colors,updateColor,onChange, updateItem, Activate, DeActivate,ashlinColors,addKeyword, deleteKeyword,translate})=>{

    const ashlinColorCode = ashlinColors.map(i=>i.colorCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="upDateColorModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="upDateColorModalLabel" style={{textAlign:'center !important'}}>Update A Color</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <SelectInput  id={'update_ashlin_colors'} label={'Colors Code'} name={'colorCode'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={ashlinColorCode}    onChange={(e)=>onChange(e,'updateColor')}/>
                        <SelectInput  id={'update_asi_colors'} label={'Associated ASI Colors'} name={'pickedColor'} width={'450px'}
                                      style={{fontWeight:'bold'}}  defaultValue={updateColor.pickedColor}    value={colors}    onChange={(e)=>onChange(e,'updateColor')}/>
                        <br/>
                        <div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                                </div>
                                <div className={'col-2'}>
                                    <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>translate('updateColor')}>TRANSLATE</button>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateColor.shortENG}
                                              onChange={(e)=>onChange(e,'updateColor')} name={'shortENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateColor.shortFR}
                                              onChange={(e)=>onChange(e,'updateColor')} name={'shortFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateColor.longENG} rows="5"
                                              onChange={(e)=>onChange(e,'updateColor')} name={'longENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateColor.longFR} rows="5"
                                              onChange={(e)=>onChange(e,'updateColor')} name={'longFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Web Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateColor.webENG} rows="5"
                                              onChange={(e)=>onChange(e,'updateColor')} name={'webENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={updateColor.webFR} rows="5"
                                              onChange={(e)=>onChange(e,'updateColor')} name={'webFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <br/>
                            <div className={'row justify-content-center'}>
                                     <CheckBox  value={updateColor.active === 1}  id={'update-color-active'}
                                       onChange={(e)=>onChange(e,'updateColor')} name={'active'} label={'Is this Color Active'}/>
                            </div>
                            <hr/>
                            <div className={'row justify-content-center'}>
                                <div className={'col-3'}>
                                    <h4> Keyword Box</h4>
                                </div>
                                <div className={'col-7'}>
                                    <input type={'text'} style={{width:'200px'}} value={updateColor.keyword} name={'keyword'}  onChange={(e)=>onChange(e,'updateColor')} />
                                    <button className={'btn-success'} style={{width:'75px', margin:'0 15px'}} onClick={()=>addKeyword(updateColor.searchKeyWords,updateColor.keyword,'updateColor','searchKeyWords')}>Add</button>
                                    <button className={'btn-danger'} style={{width:'75px'}} onClick={()=>deleteKeyword(updateColor.searchKeyWords,updateColor.keyword,'updateColor','searchKeyWords')}>Delete</button>
                                    <textarea className={'col'} style={{width:'100%', marginTop:'10px'}} value={updateColor.searchKeyWords} rows="5"
                                              readOnly >

                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>updateItem(e,'updateColor')}>Update Color</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'updateColor','colorCode')} >Deactivate Color</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'updateColor','colorCode')}>Activate Color</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default updateColorModal