import React,{Component} from 'react';
import AddFamilyModal from './addFamiliesModal';
import UpdateFamilyModal from './updateFamiliesModal';
import {legacyFamiliesURL, legacyFamiliesModelMapper} from '../../../Utils/ashlinDesign'
import axios from 'axios';
import { proxyurl } from '../../../Utils/requestHelper';
class ProductFamilies extends Component{
    constructor(props){
        super(props);
        this.state={
            ashlinCollections:[],
            ashlinFamilies:[]
        }
    }
    handleGetFamilies = async () => {
        const asiResponse = await axios.get(proxyurl+'https://sandbox-productservice.asicentral.com/api/v4/lookup/colors');
        const ashlinResponse = await axios.get(legacyFamiliesURL);
        const ashlinFamilies = ashlinResponse.data.map(family=>legacyFamiliesModelMapper(family));
        const collections = [
            'ABOU',
            'VEGAN',
            'CORPORATE',
            'ASIB',
            'ITALIAN COLLECTION',
            'MEN',
            'WOMEN',
            'TRAVEL',
            'HOMEOFFICE',
            '6IXHIDE',
            'SPECIALTY'
        ];
        Promise.all([asiResponse,ashlinResponse]).then(()=>{
            this.setState({
                ashlinCollections:collections,
                ashlinFamilies:ashlinFamilies
            })
        })
    };
    render(){
        return(
            <div className='container-fluid'>
                <br/>
                <h2 className={'text-secondary'} style={{textAlign:'center'}}>Manager Family</h2>
                <br/>
                <br/>
                <div className={'row justify-content-center'}>
                    <button className={' btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px",fontSize:'30px'}} data-toggle="modal" data-target="#add_new_family" onClick={this.handleGetFamilies} >Add Family</button>
                    <AddFamilyModal id={'add_new_family'}
                        collections = {this.state.ashlinCollections} addFamily = {this.props.addFamily} families={this.state.ashlinFamilies}
                        onChange={this.props.onChange} addItem = {this.props.addItem}
                        Activate={this.props.Activate} DeActivate={this.props.DeActivate}
                        translate={this.props.translate}
                    />



                    <div className={'col-1'}> </div>
                    <button className={'btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px", fontSize:'30px'}} data-toggle="modal" data-target="#update_family" onClick={this.handleGetFamilies}>Update Family</button>
                    <UpdateFamilyModal id={'update_family'}
                           collections = {this.state.ashlinCollections} families={this.state.ashlinFamilies} updateFamily = {this.props.updateFamily}
                           addItem = {this.props.addItem} onChange={(a,b)=>this.props.onChange(a,b,this.state.ashlinFamilies)}
                           Activate={this.props.Activate} DeActivate={this.props.DeActivate}  translate={this.props.translate}
                           updateItem={this.props.updateItem} addKeyword={this.props.addKeyword} deleteKeyword={this.props.deleteKeyword}
                    />
                </div>
            </div>
        )
    }
}

export default ProductFamilies;