import React, {Component} from 'react';
import $ from "jquery";
import { postundledItems } from '../../../Utils/ashlinDesign';
class NewBundledItem extends Component{
    constructor(props){
        super(props);
        this.state={
            ProductSKU:'',
            BundledItems:[
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                }

            ]
        }
    }
    onChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleClose=()=>{
        this.setState({
            ProductSKU:'',
            BundledItems:[
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                }

            ]
        },()=>{
            $('#newBundledItem').modal('hide');
        })
    };
    handleItemOnChange = (e,index)=>{
        const BundledItems = this.state.BundledItems;
        const name = e.target.name;
        let value;
        if(name === "Qty"){
            value = parseInt(e.target.value);
        }else{
            value = e.target.value;
        }
        if(name === "SKU"){
            value = e.target.value.toUpperCase();
        }else{
            value = e.target.value;
        }
        BundledItems[index][name] = value;
        this.setState({
            BundledItems
        })
    };
    handleCreateNewBundledItem=async()=>{
        const d = new Date();
        const n = d.toISOString().slice(0,19);

        //console.log(this.state)
        const requestData = this.state.BundledItems.filter(i=>i.SKU!==""&&i.Name!==""&&i.Description!==""&&i.Qty!==0).map(item=>{
            return {
                "Sku": this.state.ProductSKU,
                "Item": item.SKU,
                "Qty": item.Qty,
                "ItemName": item.Name,
                "ItemDescription": item.Description,
                "LastModifiedDate": n
            }
        })
        const resData = await postundledItems(requestData);
      //  console.log(resData);
        const insertData = {
            "Sku":  this.state.ProductSKU,
            "Items": requestData
        };
        this.props.addNewBundledItemToList(insertData);
        this.setState({
            ProductSKU:'',
            BundledItems:[
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                },
                {
                    SKU:"",
                    Name:"",
                    Description:"",
                    Qty:0
                }

            ]
        },()=>{
            $('#newBundledItem').modal('hide');
        })
    };
    render(){
        return(<div className="modal fade" id="newBundledItem" tabIndex="-1" role="dialog" data-backdrop="static"
                    aria-labelledby="newBundledItem" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                    <div className="modal-header">
                        <h3 className="modal-title text-center" id="newBrightpearlChannelInfoTitle" style={{fontWeight:'bold'}}>New Shipping Option</h3>
                        <button type="button" className="close"
                                onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className={'table table-bordered newBrightpearlChannelInfoDetail'}>
                            <thead>
                            </thead>
                            <tbody>
                            <tr>
                                <th colSpan={'2'}>Product SKU</th>
                                <td colSpan={'2'}> <input type={'text'} onChange={this.onChange}
                                           style={{width:'100%'}}  name={'ProductSKU'} value={this.state.ProductSKU} /></td>
                            </tr>
                            <tr>
                                <th>
                                    Item SKU
                                </th>
                                <th>
                                    Item Name
                                </th>
                                <th>
                                    Item Description
                                </th>
                                <th>
                                    Item Qty
                                </th>
                            </tr>
                            {
                                this.state.BundledItems.map((item,index)=>{
                                    const qty = item.Qty === 0? "":item.Qty;
                                    return <tr key={index}>
                                        <td><input type={'text'} onChange={(e)=>this.handleItemOnChange(e,index)} name={'SKU'} value={item.SKU} /></td>
                                        <td><input type={'text'} onChange={(e)=>this.handleItemOnChange(e,index)} name={'Name'} value={item.Name} /></td>
                                        <td><input type={'text'} onChange={(e)=>this.handleItemOnChange(e,index)} name={'Description'} value={item.Description} /></td>
                                        <td><input type={'text'} onChange={(e)=>this.handleItemOnChange(e,index)} name={'Qty'} value={qty} /></td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        <div className={'col'}> </div>
                        <div className={'col'}>
                            <button className={'btn btn-danger'} onClick={this.handleCreateNewBundledItem} >Create Bundled Item</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default NewBundledItem;