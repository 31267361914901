import React from 'react';
import { Link } from 'react-router-dom';
const  ProductSideBar =(props)=>{
    const path = window.location.pathname;
    return(
        <ul className="list-group ashlinSideBar">
            <Link to='/SKUs'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/SKUs" ? "sidebar-selected" : ""}`} >
                    Show All SKUs
                    <span className="badge badge-primary badge-pill">{props.allSKUs}</span>
                </button>
            </Link>
            <Link to='/designManager'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/designManager" ? "sidebar-selected" : ""}`} >
                   Manage Product Concepts
                </button>
            </Link>
            <Link to='/export'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/export" ? "sidebar-selected" : ""}`} >
                   Product Export
                </button>
            </Link>
            <Link to='/printUPC'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/printUPC" ? "sidebar-selected" : ""}`} >
                    Print UPC Label
                </button>
            </Link>
            <Link to='/bulkSkuUpload'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/bulkSkuUpload" ? "sidebar-selected" : ""}`} >
                    Bulk Upload Products
                </button>
            </Link>
            <Link to='/bulkInventoryManager'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/bulkInventoryManager" ? "sidebar-selected" : ""}`} >
                    Bulk Inventory Manager
                </button>
            </Link>
            <div className={'field'}>
                <a href="#ModifyProductMenu" data-toggle="collapse" aria-expanded="false"
                   className="dropdown-toggle ">Modify Product</a>
                <div className="collapse " id="ModifyProductMenu">
                    <Link to='/asiUpdate'>
                        <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/asiUpdate" ? "sidebar-selected" : ""}`} >
                            Update ASI Products
                        </button>
                    </Link>
                </div>
            </div>
            <Link to='/bulkModifyProduct'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/bulkModifyProduct" ? "sidebar-selected" : ""}`} >
                    Bulk Modify Products
                </button>
            </Link>
            <Link to='/bulkDiscount'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/bulkDiscount" ? "sidebar-selected" : ""}`} >
                    Bulk Discount
                </button>
            </Link>
            <Link to='/bulkSkuRetire'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${path==="/bulkSkuRetire" ? "sidebar-selected" : ""}`} >
                    Bulk Retire Products
                </button>
            </Link>
        </ul>
    );

};


export default ProductSideBar;