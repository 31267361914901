import React, {Component} from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {getAllSKUNumberList} from "../../Utils/ashlinDesign";
import ModifyPrice from './ModifyPrice';
import {credentials, amazonSearchProductBySku, amazonFeedPrices} from '../../Utils/amazonMarketplace';
import {walmartSearchProductBySku,walmartFeedStandardPrices, walmartFeedDiscountPrices} from '../../Utils/walmartMarketplace';
import {bestbuySearchProductBySku, bestbuyFeedPrices} from '../../Utils/bestbuyMarketplace';
import $ from "jquery";
import moment from 'moment';
class MarketplacePriceManager extends Component {
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            skuNumberList:[],
            filteredSkuNumberList:[],
            skuFilter:'',
            selectedSKUNumber:"",
            amazonCaCredentials: undefined,
            amazonUsCredentials: undefined,

            amazonCaPrice: undefined,
            amazonUsPrice: undefined,
            walmartCaPrice: undefined,
            bestbuyCaPrice: undefined,


            updatingList:[],


            //updating price lists
            amazonCaUpdatingPrices:[],
            amazonUsUpdatingPrices:[],
            walmartCaUpdatingPrices:[],
            bestbuyCaUpdatingPrices:[],

            //selectedPrice
            selectedChannelName:'',
            selectedPrice:undefined,
            discountStartDate: undefined,
            discountEndDate: undefined,
        }
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    getData = async() => {
        const skuNumberList = await getAllSKUNumberList();
        const amazonCaCredentials = credentials('CA');
        const amazonUsCredentials = credentials('US');
        Promise.all([skuNumberList]).then(()=>{
            if(this._mounted){
                this.setState({
                    skuNumberList,
                    filteredSkuNumberList:skuNumberList,
                    amazonCaCredentials,
                    amazonUsCredentials
                })
            }
        });
    };
    componentDidMount(){
        this._mounted = true;
        this.getData();
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleGetPrice= async ()=>{
        const amazonCaCredentials = this.state.amazonCaCredentials;
        const amazonUsCredentials = this.state.amazonUsCredentials;
        const selectedSKUNumber = this.state. selectedSKUNumber;
        if(selectedSKUNumber === ""){
            alert('Must pick a SKU');
        }else{
            const amazonCaPrice = await amazonSearchProductBySku(amazonCaCredentials,selectedSKUNumber,'CA');
            const amazonUsPrice = await amazonSearchProductBySku(amazonCaCredentials,selectedSKUNumber,'US');
            const walmartCaPrice = await walmartSearchProductBySku(selectedSKUNumber);
            const bestbuyCaPrice = await bestbuySearchProductBySku(selectedSKUNumber);
            this.setState({
                amazonCaPrice,
                amazonUsPrice,
                walmartCaPrice,
                bestbuyCaPrice
            })
        }
    };
    handlePriceOnchange = (e, stateName, price)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        price[name] = value;
        this.setState({
            [stateName] : price
        })
    };
    handleChangeStartDate=(date)=>{
        this.setState({
            discountStartDate : date
        });
    };
    handleChangeEndDate=(date)=>{
        this.setState({
            discountEndDate : date
        });
    };
    handleSelectedPriceOnChange = (e)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        const selectedPrice = this.state.selectedPrice;
        selectedPrice[name] = value;
        this.setState({
            selectedPrice
        });
    };
    handleAddToList = () =>{

        const selectedPrice = this.state.selectedPrice;
        if(selectedPrice.applyDiscount){
            selectedPrice.discountStartDate = this.state.discountStartDate;
            selectedPrice.discountEndDate = this.state.discountEndDate;
        }
        const selectedChannelName = this.state.selectedChannelName;
        switch (selectedChannelName) {
            case 'Amazon CA':
                const amazonCaUpdatingPrices = this.state.amazonCaUpdatingPrices;
                amazonCaUpdatingPrices.push(Object.assign({}, selectedPrice));
                this.setState({
                    amazonCaUpdatingPrices
                });
                break;
            case 'Amazon US':
                const amazonUsUpdatingPrices = this.state.amazonUsUpdatingPrices;
                amazonUsUpdatingPrices.push(Object.assign({}, selectedPrice));
                this.setState({
                    amazonUsUpdatingPrices
                });
                break;
            case "Walmart Marketplace CA":
                const walmartCaUpdatingPrices = this.state.walmartCaUpdatingPrices;
                walmartCaUpdatingPrices.push(Object.assign({}, selectedPrice));
                this.setState({
                    walmartCaUpdatingPrices
                });
                break;
            case "Bestbuy Marketplace CA":
                const bestbuyCaUpdatingPrices = this.state.bestbuyCaUpdatingPrices;
                bestbuyCaUpdatingPrices.push(Object.assign({}, selectedPrice));
                this.setState({
                    bestbuyCaUpdatingPrices
                });
                break;
            default:
        }
        $('#ModifyPriceModal').modal('hide');
        this.setState({
            selectedChannelName:'',
            selectedPrice:undefined,
            discountStartDate: undefined,
            discountEndDate: undefined,
        })
    };
    handleRemoveFromList = (listName, index)=>{
        const list = this.state[listName];
        list.splice(index,1);
        this.setState({
            [listName] : list
        });
    };
    handleModifyPrice = (channelName, price)=>{
        price.applyDiscount = false;
        this.setState({
            selectedChannelName: channelName,
            selectedPrice : price
        },()=>{
            $('#ModifyPriceModal').modal('toggle');
        });
    };
    handleOnClosed = ()=>{
        $('#ModifyPriceModal').modal('hide');
        this.setState({
            selectedChannelName:'',
            selectedPrice:undefined,
            discountStartDate: undefined,
            discountEndDate: undefined,
        })
    };
    handleUpdatePriceList = async()=>{
        const amazonCaCredentials = this.state.amazonCaCredentials;
        //Amazon CA
        const amazonCaUpdatingPrices = this.state.amazonCaUpdatingPrices;
        if(amazonCaUpdatingPrices.length > 0){
            await amazonFeedPrices(amazonCaCredentials,amazonCaUpdatingPrices,'CA');
        }
        //Amazon US
        const amazonUsUpdatingPrices = this.state.amazonUsUpdatingPrices;
        if(amazonUsUpdatingPrices.length > 0){
            await amazonFeedPrices(amazonCaCredentials,amazonUsUpdatingPrices,'US');
        }

        //Bestbuy marketplace CA
        const bestbuyCaUpdatingPrices = this.state.bestbuyCaUpdatingPrices;
        if(bestbuyCaUpdatingPrices.length > 0){
            await bestbuyFeedPrices(bestbuyCaUpdatingPrices);
        }

        //Walmart marketplace CA
        const walmartCaUpdatingPrices = this.state.walmartCaUpdatingPrices;
        //Update standard prices
        const standardPrices = walmartCaUpdatingPrices.filter(p=>p.applyDiscount === false);
        if(standardPrices.length > 0){
            await walmartFeedStandardPrices(standardPrices);
        }
        //Update discount prices
        const discountPrices = walmartCaUpdatingPrices.filter(p=>p.applyDiscount === true);
        if(discountPrices.length >0){
            await walmartFeedDiscountPrices(discountPrices);
        }
    };
    handleSKUFilter = (e)=>{
        const value = e.target.value;
        const filteredSkuNumberList = this.state.skuNumberList.filter(sku=>sku.toUpperCase().includes(value.trim().toUpperCase()));
        this.setState({
            skuFilter : value,
            filteredSkuNumberList
        });
    };
    handleClearPriceList = ()=>{
        this.setState({
            amazonCaUpdatingPrices:[],
            amazonUsUpdatingPrices:[],
            walmartCaUpdatingPrices:[],
            bestbuyCaUpdatingPrices:[],
        });
    };
    render(){
        const filteredSkuNumberList = this.state.filteredSkuNumberList;
        const amazonCaPrice = this.state.amazonCaPrice;
        const amazonCaUpdatingPrices = this.state.amazonCaUpdatingPrices;
        const amazonUsPrice = this.state.amazonUsPrice;
        const amazonUsUpdatingPrices = this.state.amazonUsUpdatingPrices;
        const walmartCaPrice = this.state.walmartCaPrice;
        const walmartCaUpdatingPrices = this.state.walmartCaUpdatingPrices;
        const bestbuyCaPrice = this.state.bestbuyCaPrice;
        const bestbuyCaUpdatingPrices = this.state.bestbuyCaUpdatingPrices;

        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col-9'} style={{textAlign:'center'}}>

                    {
                        this.state.selectedPrice !== undefined &&
                        <ModifyPrice
                            channel = {this.state.selectedChannelName}
                            price = {this.state.selectedPrice}
                            onClosed ={this.handleOnClosed}
                            priceOnchange = {this.handleSelectedPriceOnChange}
                            discountStartDate = {this.state.discountStartDate}
                            discountEndDate = {this.state.discountEndDate}
                            changeStartDate = {this.handleChangeStartDate}
                            changeEndDate = {this.handleChangeEndDate}
                            addToList = {this.handleAddToList}
                        />
                    }
                    <br/>
                    <div className={'row'} style={{borderBottom:' 1px solid gray', marginBottom:'15px'}}>
                        <div className={'col-7'}>
                            <div className={'text-muted display-4 font-weight-bold'} style={{verticalAlign:'middle'}}>Marketplace Price Manager</div>
                        </div>
                        <div className={'col-5'}>
                            <table className="table table-bordered searchTable" style={{fontSize:'20px'}}>
                                <tbody>
                                <tr>
                                    <th>
                                        SKU
                                    </th>
                                    <td>
                                        <input type={'text'} onChange={this.handleSKUFilter} placeholder={'Enter SKU Filter Here'}  style={{textAlign:'center'}} value={this.state.skuFilter} className={'quick-pricing-sku'}/>
                                        <select name={'selectedSKUNumber'}  onChange={this.handleOnchange} className={'quick-pricing-sku'} >
                                            <option value="" >-- select a SKU --</option>
                                            {filteredSkuNumberList.map((op,i)=>{
                                                return <option value={op} key={i}>{op}</option>
                                            })}
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <button type={'button'} className={'btn btn-success btn-block'} onClick={this.handleGetPrice}>Get Price</button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {(amazonCaPrice !== undefined || amazonUsPrice !== undefined || walmartCaPrice !== undefined || bestbuyCaPrice !== undefined) &&
                    <div>
                        <h2 className={'text-muted display-5 font-weight-bold'}>Current Prices by Channels</h2>
                        <table className="table table-bordered priceListTable">

                            <thead>
                            <tr>
                                <th>Channel Name</th>
                                <th>Currency</th>
                                <th>Listing/Sale Price</th>
                                <th>Regular/Standard Price</th>
                                <th>Is Discount</th>
                                <th>Action</th>
                            </tr>
                            </thead>

                            <tbody>
                            {amazonCaPrice !== undefined && <tr>
                                <td>Amazon CA</td>
                                <td>{amazonCaPrice.currency}</td>
                                <td>{amazonCaPrice.listPrice}</td>
                                <td>{amazonCaPrice.regularPrice}</td>
                                <td>{amazonCaPrice.discount ? "YES" : "NO"}</td>
                                <td>
                                    <button type={'button'} className={'btn-primary'}
                                            onClick={() => this.handleModifyPrice('Amazon CA', amazonCaPrice)}>Modify
                                        Price
                                    </button>
                                </td>
                            </tr>}

                            {amazonUsPrice !== undefined && <tr>
                                <td>Amazon US</td>
                                <td>{amazonUsPrice.currency}</td>
                                <td>{amazonUsPrice.listPrice}</td>
                                <td>{amazonUsPrice.regularPrice}</td>
                                <td>{amazonUsPrice.discount ? "YES" : "NO"}</td>
                                <td>
                                    <button type={'button'} className={'btn-primary'}
                                            onClick={() => this.handleModifyPrice('Amazon US', amazonUsPrice)}>Modify
                                        Price
                                    </button>
                                </td>
                            </tr>}

                            {walmartCaPrice !== undefined && <tr>
                                <td>Walmart CA</td>
                                <td>{walmartCaPrice.currency}</td>
                                <td>{walmartCaPrice.listPrice}</td>
                                <td>{walmartCaPrice.regularPrice}</td>
                                <td>{walmartCaPrice.discount ? "YES" : "NO"}</td>
                                <td>
                                    <button type={'button'} className={'btn-primary'}
                                            onClick={() => this.handleModifyPrice('Walmart Marketplace CA', walmartCaPrice)}>Modify
                                        Price
                                    </button>
                                </td>
                            </tr>}

                            {bestbuyCaPrice !== undefined && <tr>
                                <td>Bestbuy CA</td>
                                <td>{bestbuyCaPrice.currency}</td>
                                <td>{bestbuyCaPrice.listPrice}</td>
                                <td>{bestbuyCaPrice.regularPrice}</td>
                                <td>{bestbuyCaPrice.discount ? "YES" : "NO"}</td>
                                <td>
                                    <button type={'button'} className={'btn-primary'}
                                            onClick={() => this.handleModifyPrice('Bestbuy Marketplace CA', bestbuyCaPrice)}>Modify
                                        Price
                                    </button>
                                </td>
                            </tr>}
                            </tbody>
                        </table>
                    </div>
                    }
                    { (amazonCaUpdatingPrices.length > 0 || amazonUsUpdatingPrices.length > 0 || walmartCaUpdatingPrices.length > 0 || bestbuyCaUpdatingPrices.length > 0) &&
                        <div style={{borderTop:' 1px solid gray', margin:'20px 0', padding:'10px 0'}}>
                            <div className={'row'}>
                                <div className={'col-2'}>
                                    <button type={'button'} className={'btn btn-primary btn-block'} onClick={this.handleClearPriceList} >Clear List</button>
                                </div>
                                <div className={'col-8'}>
                                    <h2 className={'text-muted display-5 font-weight-bold '}>Updating Price Lists</h2>
                                </div>
                                <div className={'col-2'}>
                                    <button type={'button'} className={'btn btn-danger btn-block'} onClick={this.handleUpdatePriceList} >Update Price</button>
                                </div>
                            </div>
                            <table className="table table-bordered updatingPriceTable">
                                <tbody>

                                    <tr>
                                        <th>Channel Name</th>
                                        <th>SKU</th>
                                        <th>Currency</th>
                                        <th>Listing Price</th>
                                        <th>Regular Price</th>
                                        <th>Apply Discount</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Action</th>
                                    </tr>

                                    { amazonCaUpdatingPrices.length > 0 &&
                                         amazonCaUpdatingPrices.map((p,index)=>{
                                             return <tr key={index}>
                                                 <td>Amazon CA</td>
                                                 <td>{p.sku}</td>
                                                 <td>{p.currency}</td>
                                                 <td>{p.listPrice}</td>
                                                 <td>{p.regularPrice}</td>
                                                 <td>{p.applyDiscount ? "YES" : "NO"}</td>
                                                 <td>{p.discountStartDate === undefined ? "" : moment(p.discountStartDate).format('YYYY-MM-DD')}</td>
                                                 <td>{p.discountEndDate === undefined ? "" : moment(p.discountEndDate).format('YYYY-MM-DD')}</td>
                                                 <td><button type={'button'} className={'btn-danger'} onClick={()=>this.handleRemoveFromList('amazonCaUpdatingPrices',index)}>remove</button></td>
                                             </tr>
                                         })
                                    }

                                    { amazonUsUpdatingPrices.length > 0 &&
                                        amazonUsUpdatingPrices.map((p,index)=>{
                                            return <tr key={index}>
                                                <td>Amazon US</td>
                                                <td>{p.sku}</td>
                                                <td>{p.currency}</td>
                                                <td>{p.listPrice}</td>
                                                <td>{p.regularPrice}</td>
                                                <td>{p.applyDiscount ? "YES" : "NO"}</td>
                                                <td>{p.discountStartDate === undefined ? "" : moment(p.discountStartDate).format('YYYY-MM-DD')}</td>
                                                <td>{p.discountEndDate === undefined ? "" : moment(p.discountEndDate).format('YYYY-MM-DD')}</td>
                                                <td><button type={'button'} className={'btn-danger'} onClick={()=>this.handleRemoveFromList('amazonUsUpdatingPrices',index)}>remove</button></td>
                                            </tr>
                                        })
                                    }

                                    { walmartCaUpdatingPrices.length > 0 &&
                                        walmartCaUpdatingPrices.map((p,index)=>{
                                            return <tr key={index}>
                                                <td>Walmart CA</td>
                                                <td>{p.sku}</td>
                                                <td>{p.currency}</td>
                                                <td>{p.listPrice}</td>
                                                <td>{p.regularPrice}</td>
                                                <td>{p.applyDiscount ? "YES" : "NO"}</td>
                                                <td>{p.discountStartDate === undefined ? "" : moment(p.discountStartDate).format('YYYY-MM-DD')}</td>
                                                <td>{p.discountEndDate === undefined ? "" : moment(p.discountEndDate).format('YYYY-MM-DD')}</td>
                                                <td><button type={'button'} className={'btn-danger'} onClick={()=>this.handleRemoveFromList('walmartCaUpdatingPrices',index)}>remove</button></td>
                                            </tr>
                                        })
                                    }

                                    { bestbuyCaUpdatingPrices.length > 0 &&
                                        bestbuyCaUpdatingPrices.map((p,index)=>{
                                            return <tr key={index}>
                                                <td>Bestbuy CA</td>
                                                <td>{p.sku}</td>
                                                <td>{p.currency}</td>
                                                <td>{p.listPrice}</td>
                                                <td>{p.regularPrice}</td>
                                                <td>{p.applyDiscount ? "YES" : "NO"}</td>
                                                <td>{p.discountStartDate === undefined ? "" : moment(p.discountStartDate).format('YYYY-MM-DD')}</td>
                                                <td>{p.discountEndDate === undefined ? "" : moment(p.discountEndDate).format('YYYY-MM-DD')}</td>
                                                <td><button type={'button'} className={'btn-danger'} onClick={()=>this.handleRemoveFromList('bestbuyCaUpdatingPrices',index)}>remove</button></td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        )}
}
export default MarketplacePriceManager;