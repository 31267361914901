export const findCountryCode = (fullName)=>{
    const result = ISOCountry.find(i=>i.name===fullName.toUpperCase());
    return result.Code;
};

const ISOCountry=[
    {name:'CANADA',Code:'CA',},
    {name:'CAN',Code:'CA',},
    {name:'CA',Code:'CA',},
    {name:'USA',Code:'US'},
    {name:'US',Code:'US'},
];