import React from 'react';

const DispatchedOrderDetailModal =(props)=>{
    return(
        <div className="modal fade" id="DispatchedOrderDetailModal" tabIndex="-1" role="dialog"
             aria-labelledby="DispatchedOrderDetailModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="DispatchedOrderDetailModalTitle">Bright Dispatched Order Detail</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col">
                                {props.hasCheckedStatus && <button onClick={props.handleUpDateOrder} id='updateOrders'  className={'btn btn-danger btn-block'}>Update Orders Status</button>}
                            </div>
                            <div className="col ml-auto">
                                {!props.hasCheckedStatus && <button onClick={props.handleCheckOrderStatus} className={'btn btn-primary btn-block'}>Check Order Status</button>}
                            </div>
                        </div>
                        <table className="table dispatchedOrderTable" style={{margin:'16px 0 0'}}>
                            <tbody>
                            <tr className={'row'}>
                                <th className={'col'} style={{textAlign:'center'}}>OrderID</th>
                                <td className={'col'}>{props.selectedOrder!== undefined ? props.selectedOrder.id:""}</td>
                                <th className={'col'} style={{textAlign:'center'}}>Buyer Name</th>
                                <td className={'col'}>{(props.selectedOrder!==undefined&&props.selectedOrder.parties.customer.addressFullName!==undefined)?props.selectedOrder.parties.customer.addressFullName:""}</td>
                            </tr>
                            <tr className={'row'}>
                                <th className={'col'} style={{textAlign:'center'}}>Created Date</th>
                                <td className={'col'}>{props.selectedOrder!==undefined?props.selectedOrder.createdOn.slice(0,19).replace("T"," "):""}</td>
                                <th className={'col'} style={{textAlign:'center'}}>Payment Status</th>
                                <td className={'col'}>{props.selectedOrder!==undefined?props.selectedOrder.orderPaymentStatus:""}</td>
                            </tr>
                            <tr className={'row'}>
                                <th className={'col'} style={{textAlign:'center'}}>Shipping Method</th>
                                <td className={'col'}>{props.shippingMethod !==undefined ?props.shippingMethod:""}</td>
                                <th className={'col'} style={{textAlign:'center'}}>Tracking Number</th>
                                <td className={'col'}>{props.trackingNumber !==undefined ?props.trackingNumber :""}</td>
                            </tr>
                            <tr className={'row'}>
                                <td className={'col-4'}> </td>
                                <th className={'col'}>Send Email?</th>
                                <td className={'col'}>
                                    <input type="checkbox"  name={'sendEmail'} onChange={props.handleOnChange}
                                      value={props.sendEmail} checked={props.sendEmail} />
                                </td>
                                <td className={'col-3'}> </td>
                            </tr>
                            <tr className={'row'}>
                                <th className={'col-2'}>Email List separate by ,</th>
                                <td className={'col-10'}>
                                    <input type="text"  name={'emailList'} onChange={props.handleOnChange} style={{width:'100%'}}
                                           value={props.emailList}  />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <textarea style={{width:'100%'}} rows='12' name={'emailDetail'}
                                        value={props.emailDetail} onChange={props.handleOnChange}
                                    >
                                    </textarea>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        {/*{!props.canFulfill && <button className={'btn btn-block btn-success'} onClick={(e)=>props.checkFulfill(e,orderRows)}>Check FulfillMent</button>}*/}
                        {/*{props.canFulfill && <button className={'btn btn-block btn-danger'} onClick={props.sendOrder}>Send Order to Lean Supply</button>}*/}

                        {/*<button className={'btn col btn-success'} onClick={(e)=>props.checkFulfill(e,orderRows)}>Check FulfillMent</button>*/}
                        {/*<button className={'btn col btn-danger'} onClick={props.sendOrder}>Send Order to Lean Supply</button>*/}
                    </div>
                </div>
            </div>
        </div>
    )

};
export default DispatchedOrderDetailModal;