export const sageCategoriesObject = [
    { SageCategoriesID: '866', SageCategories: 'Adapters' },
    { SageCategoriesID: '224', SageCategories: 'Address Books' },
    { SageCategoriesID: '742', SageCategories: 'Adhesives' },
    { SageCategoriesID: '2', SageCategories: 'Air Fresheners' },
    { SageCategoriesID: '4', SageCategories: 'Alarms' },
    { SageCategoriesID: '5', SageCategories: 'Albums' },
    { SageCategoriesID: '860', SageCategories: 'All-Terrain Vehicles' },
    { SageCategoriesID: '273', SageCategories: 'Animals-Stuffed' },
    { SageCategoriesID: '815', SageCategories: 'Antenna Toppers' },
    { SageCategoriesID: '6', SageCategories: 'Appliances' },
    { SageCategoriesID: '7', SageCategories: 'Aprons' },
    { SageCategoriesID: '745', SageCategories: 'Aquariums' },
    { SageCategoriesID: '8', SageCategories: 'Armbands' },
    { SageCategoriesID: '682', SageCategories: 'Art' },
    { SageCategoriesID: '9', SageCategories: 'Ashtrays' },
    { SageCategoriesID: '10', SageCategories: 'Atlases' },
    { SageCategoriesID: '402', SageCategories: 'Attaches' },
    { SageCategoriesID: '860', SageCategories: 'ATVs' },
    { SageCategoriesID: '652', SageCategories: 'Automotive Kits' },
    { SageCategoriesID: '13', SageCategories: 'Awards' },
    { SageCategoriesID: '697', SageCategories: 'Back Braces' },
    { SageCategoriesID: '357', SageCategories: 'Backpacks' },
    { SageCategoriesID: '819', SageCategories: 'Badge Holders' },
    { SageCategoriesID: '15', SageCategories: 'Badges' },
    { SageCategoriesID: '20', SageCategories: 'Bags' },
    { SageCategoriesID: '24', SageCategories: 'Balloons' },
    { SageCategoriesID: '341', SageCategories: 'Balls' },
    { SageCategoriesID: '864', SageCategories: 'Bandages' },
    { SageCategoriesID: '826', SageCategories: 'Bandannas' },
    { SageCategoriesID: '25', SageCategories: 'Banks' },
    { SageCategoriesID: '26', SageCategories: 'Banners' },
    { SageCategoriesID: '759', SageCategories: 'Barrettes' },
    { SageCategoriesID: '477', SageCategories: 'Bases' },
    { SageCategoriesID: '706', SageCategories: 'Basketball Hoops' },
    { SageCategoriesID: '816', SageCategories: 'Baskets' },
    { SageCategoriesID: '414', SageCategories: "Baskets-Gift/Food" },
    { SageCategoriesID: '622', SageCategories: 'Bath Gels' },
    { SageCategoriesID: '606', SageCategories: 'Bats' },
    { SageCategoriesID: '484', SageCategories: 'Batteries' },
    { SageCategoriesID: '832', SageCategories: 'Beads' },
    { SageCategoriesID: '868', SageCategories: 'Beanies' },
    { SageCategoriesID: '33', SageCategories: 'Bells' },
    { SageCategoriesID: '34', SageCategories: 'Belt Buckles' },
    { SageCategoriesID: '35', SageCategories: 'Belts' },
    { SageCategoriesID: '429', SageCategories: 'Beverage Insulators' },
    { SageCategoriesID: '480', SageCategories: 'Beverages' },
    { SageCategoriesID: '558', SageCategories: 'Bibs' },
    { SageCategoriesID: '593', SageCategories: 'Bicycles' },
    { SageCategoriesID: '38', SageCategories: 'Binders' },
    { SageCategoriesID: '39', SageCategories: 'Binoculars' },
    { SageCategoriesID: '390', SageCategories: 'Bird Feeders' },
    { SageCategoriesID: '40', SageCategories: 'Blankets' },
    { SageCategoriesID: '504', SageCategories: 'Blenders' },
    { SageCategoriesID: '452', SageCategories: 'Boards-Bulletin' },
    { SageCategoriesID: '41', SageCategories: 'Boards-Carving' },
    { SageCategoriesID: '831', SageCategories: 'Boards-Chalk' },
    { SageCategoriesID: '41', SageCategories: 'Boards-Cutting' },
    { SageCategoriesID: '200', SageCategories: 'Boards-Memo' },
    { SageCategoriesID: '717', SageCategories: 'Boats' },
    { SageCategoriesID: '812', SageCategories: 'Bodysuits' },
    { SageCategoriesID: '475', SageCategories: 'Bolos' },
    { SageCategoriesID: '369', SageCategories: 'Book Ends' },
    { SageCategoriesID: '43', SageCategories: 'Bookmarks' },
    { SageCategoriesID: '44', SageCategories: 'Books' },
    { SageCategoriesID: '413', SageCategories: 'Boomerangs' },
    { SageCategoriesID: '429', SageCategories: 'Bottle Covers' },
    { SageCategoriesID: '429', SageCategories: 'Bottle Holders' },
    { SageCategoriesID: '46', SageCategories: 'Bottles' },
    { SageCategoriesID: '47', SageCategories: 'Bowls' },
    { SageCategoriesID: '619', SageCategories: 'Bows' },
    { SageCategoriesID: '49', SageCategories: 'Boxes' },
    { SageCategoriesID: '305', SageCategories: 'Bracelets' },
    { SageCategoriesID: '780', SageCategories: 'Breath Fresheners' },
    { SageCategoriesID: '50', SageCategories: 'Briefcases' },
    { SageCategoriesID: '421', SageCategories: 'Brochures' },
    { SageCategoriesID: '550', SageCategories: 'Brooms' },
    { SageCategoriesID: '51', SageCategories: 'Brushes' },
    { SageCategoriesID: '461', SageCategories: 'Brushes-Lint' },
    { SageCategoriesID: '563', SageCategories: 'Brushes-Tooth' },
    { SageCategoriesID: '622', SageCategories: 'Bubble Bath' },
    { SageCategoriesID: '789', SageCategories: 'Bubble Machines' },
    { SageCategoriesID: '52', SageCategories: 'Buckets' },
    { SageCategoriesID: '452', SageCategories: 'Bulletin Boards' },
    { SageCategoriesID: '57', SageCategories: 'Buttons' },
    { SageCategoriesID: '542', SageCategories: 'Cables' },
    { SageCategoriesID: '410', SageCategories: 'Caddies' },
    { SageCategoriesID: '58', SageCategories: 'Calculators' },
    { SageCategoriesID: '327', SageCategories: 'Calendars' },
    { SageCategoriesID: '586', SageCategories: 'Camcorders' },
    { SageCategoriesID: '332', SageCategories: 'Cameras' },
    { SageCategoriesID: '429', SageCategories: 'Can Covers' },
    { SageCategoriesID: '429', SageCategories: 'Can Holders' },
    { SageCategoriesID: '66', SageCategories: 'Candles' },
    { SageCategoriesID: '67', SageCategories: 'Candy' },
    { SageCategoriesID: '453', SageCategories: 'Candy Machines' },
    { SageCategoriesID: '867', SageCategories: 'Cannons' },
    { SageCategoriesID: '472', SageCategories: 'Cans' },
    { SageCategoriesID: '594', SageCategories: 'Canteens' },
    { SageCategoriesID: '69', SageCategories: 'Caps' },
    { SageCategoriesID: '852', SageCategories: 'Car Seats' },
    { SageCategoriesID: '772', SageCategories: 'Carabiners' },
    { SageCategoriesID: '383', SageCategories: 'Carafes' },
    { SageCategoriesID: '793', SageCategories: 'Card Readers' },
    { SageCategoriesID: '324', SageCategories: 'Cards' },
    { SageCategoriesID: '801', SageCategories: 'Cards-Download' },
    { SageCategoriesID: '809', SageCategories: 'Cards-Gift' },
    { SageCategoriesID: '225', SageCategories: 'Cards-Phone' },
    { SageCategoriesID: '434', SageCategories: 'Carts' },
    { SageCategoriesID: '41', SageCategories: 'Carving Boards' },
    { SageCategoriesID: '72', SageCategories: 'Cases' },
    { SageCategoriesID: '108', SageCategories: 'Castings' },
    { SageCategoriesID: '420', SageCategories: 'Catalogs/Cat Sheets' },
    { SageCategoriesID: '11', SageCategories: 'CDs' },
    { SageCategoriesID: '427', SageCategories: 'Certificate Frames' },
    { SageCategoriesID: '75', SageCategories: 'Certificates' },
    { SageCategoriesID: '76', SageCategories: 'Chairs' },
    { SageCategoriesID: '471', SageCategories: 'Chalk' },
    { SageCategoriesID: '831', SageCategories: 'Chalkboards' },
    { SageCategoriesID: '838', SageCategories: 'Champagne' },
    { SageCategoriesID: '792', SageCategories: 'Chargers' },
    { SageCategoriesID: '664', SageCategories: 'Charms' },
    { SageCategoriesID: '323', SageCategories: 'Charts' },
    { SageCategoriesID: '361', SageCategories: 'Checkbook Covers' },
    { SageCategoriesID: '598', SageCategories: 'Checks' },
    { SageCategoriesID: '403', SageCategories: 'Chests' },
    { SageCategoriesID: '303', SageCategories: 'Chimes' },
    { SageCategoriesID: '690', SageCategories: 'Cigars' },
    { SageCategoriesID: '663', SageCategories: 'Clapboards' },
    { SageCategoriesID: '674', SageCategories: 'Clay' },
    { SageCategoriesID: '651', SageCategories: 'Cleaners' },
    { SageCategoriesID: '368', SageCategories: 'Clip Holders/Dispensers' },
    { SageCategoriesID: '80', SageCategories: 'Clipboards' },
    { SageCategoriesID: '564', SageCategories: 'Clippers' },
    { SageCategoriesID: '81', SageCategories: 'Clips' },
    { SageCategoriesID: '349', SageCategories: 'Clips-Money' },
    { SageCategoriesID: '82', SageCategories: 'Clocks' },
    { SageCategoriesID: '547', SageCategories: 'Cloths' },
    { SageCategoriesID: '614', SageCategories: 'Clubs' },
    { SageCategoriesID: '83', SageCategories: 'Coasters' },
    { SageCategoriesID: '162', SageCategories: 'Coats' },
    { SageCategoriesID: '519', SageCategories: 'Cocoa' },
    { SageCategoriesID: '343', SageCategories: 'Coffee' },
    { SageCategoriesID: '505', SageCategories: 'Coffee Makers' },
    { SageCategoriesID: '85', SageCategories: 'Coin Holders' },
    { SageCategoriesID: '86', SageCategories: 'Coins' },
    { SageCategoriesID: '767', SageCategories: 'Colanders' },
    { SageCategoriesID: '639', SageCategories: 'Collars' },
    { SageCategoriesID: '222', SageCategories: 'Colognes' },
    { SageCategoriesID: '351', SageCategories: 'Coloring Books' },
    { SageCategoriesID: '87', SageCategories: 'Combs' },
    { SageCategoriesID: '11', SageCategories: 'Compact Discs' },
    { SageCategoriesID: '88', SageCategories: 'Compasses' },
    { SageCategoriesID: '557', SageCategories: 'Compressors' },
    { SageCategoriesID: '711', SageCategories: 'Computers' },
    { SageCategoriesID: '795', SageCategories: 'Condiments' },
    { SageCategoriesID: '467', SageCategories: 'Condoms' },
    { SageCategoriesID: '760', SageCategories: 'Cones' },
    { SageCategoriesID: '568', SageCategories: 'Confetti' },
    { SageCategoriesID: '90', SageCategories: 'Containers' },
    { SageCategoriesID: '727', SageCategories: 'Cookies' },
    { SageCategoriesID: '91', SageCategories: 'Coolers' },
    { SageCategoriesID: '435', SageCategories: 'Cords' },
    { SageCategoriesID: '671', SageCategories: 'Corks' },
    { SageCategoriesID: '495', SageCategories: 'Corkscrews' },
    { SageCategoriesID: '621', SageCategories: 'Costumes' },
    { SageCategoriesID: '725', SageCategories: 'Cots' },
    { SageCategoriesID: '396', SageCategories: 'Coupons' },
    { SageCategoriesID: '92', SageCategories: 'Covers' },
    { SageCategoriesID: '456', SageCategories: 'Crates' },
    { SageCategoriesID: '320', SageCategories: 'Crayons' },
    { SageCategoriesID: '559', SageCategories: 'Creepers' },
    { SageCategoriesID: '462', SageCategories: 'Crowns' },
    { SageCategoriesID: '93', SageCategories: 'Crystal' },
    { SageCategoriesID: '763', SageCategories: 'Cuff Links' },
    { SageCategoriesID: '94', SageCategories: 'Cups' },
    { SageCategoriesID: '517', SageCategories: 'Currency Converters' },
    { SageCategoriesID: '665', SageCategories: 'Curtains' },
    { SageCategoriesID: '319', SageCategories: 'Cushions' },
    { SageCategoriesID: '173', SageCategories: 'Cutters' },
    { SageCategoriesID: '41', SageCategories: 'Cutting Boards' },
    { SageCategoriesID: '96', SageCategories: 'Decals' },
    { SageCategoriesID: '383', SageCategories: 'Decanters' },
    { SageCategoriesID: '632', SageCategories: 'Decorating Services' },
    { SageCategoriesID: '97', SageCategories: 'Decorations' },
    { SageCategoriesID: '679', SageCategories: 'Dental Floss' },
    { SageCategoriesID: '98', SageCategories: 'Desk Organizers' },
    { SageCategoriesID: '756', SageCategories: 'Desk Sets' },
    { SageCategoriesID: '555', SageCategories: 'Desks' },
    { SageCategoriesID: '512', SageCategories: 'Detectors' },
    { SageCategoriesID: '100', SageCategories: 'Diaries' },
    { SageCategoriesID: '754', SageCategories: 'Die Cast Vehicles' },
    { SageCategoriesID: '101', SageCategories: 'Dishes' },
    { SageCategoriesID: '493', SageCategories: 'Disk Cases' },
    { SageCategoriesID: '596', SageCategories: 'Disks' },
    { SageCategoriesID: '102', SageCategories: 'Dispensers' },
    { SageCategoriesID: '103', SageCategories: 'Displays' },
    { SageCategoriesID: '739', SageCategories: 'Diving Equipment' },
    { SageCategoriesID: '566', SageCategories: 'Divot Tools' },
    { SageCategoriesID: '836', SageCategories: 'Dog Treats' },
    { SageCategoriesID: '104', SageCategories: 'Dolls' },
    { SageCategoriesID: '438', SageCategories: 'Door Knockers' },
    { SageCategoriesID: '801', SageCategories: 'Download Cards' },
    { SageCategoriesID: '105', SageCategories: 'Drafting Instruments' },
    { SageCategoriesID: '391', SageCategories: 'Dresses' },
    { SageCategoriesID: '137', SageCategories: 'Drinking Glasses' },
    { SageCategoriesID: '480', SageCategories: 'Drinks' },
    { SageCategoriesID: '856', SageCategories: 'Drones' },
    { SageCategoriesID: '561', SageCategories: 'Droppers' },
    { SageCategoriesID: '618', SageCategories: 'Dryers' },
    { SageCategoriesID: '516', SageCategories: 'Dusters' },
    { SageCategoriesID: '11', SageCategories: 'DVDs' },
    { SageCategoriesID: '820', SageCategories: 'E-Book Readers' },
    { SageCategoriesID: '716', SageCategories: 'Ear Buds' },
    { SageCategoriesID: '530', SageCategories: 'Ear Plugs' },
    { SageCategoriesID: '720', SageCategories: 'Earmuffs' },
    { SageCategoriesID: '716', SageCategories: 'Earphones' },
    { SageCategoriesID: '799', SageCategories: 'Earrings' },
    { SageCategoriesID: '106', SageCategories: 'Easels' },
    { SageCategoriesID: '773', SageCategories: 'Electronic Organizers' },
    { SageCategoriesID: '786', SageCategories: 'Embedments' },
    { SageCategoriesID: '109', SageCategories: 'Emblems' },
    { SageCategoriesID: '111', SageCategories: 'Emery Boards' },
    { SageCategoriesID: '808', SageCategories: 'Entrapments' },
    { SageCategoriesID: '112', SageCategories: 'Envelopes' },
    { SageCategoriesID: '114', SageCategories: 'Erasers' },
    { SageCategoriesID: '479', SageCategories: 'Essential Oils' },
    { SageCategoriesID: '691', SageCategories: 'Exercise Equipment' },
    { SageCategoriesID: '528', SageCategories: 'Eyeglasses' },
    { SageCategoriesID: '116', SageCategories: 'Fabrics-Non-Wearables' },
    { SageCategoriesID: '360', SageCategories: 'Fanny Packs' },
    { SageCategoriesID: '117', SageCategories: 'Fans' },
    { SageCategoriesID: '118', SageCategories: 'Figurines' },
    { SageCategoriesID: '119', SageCategories: 'Files' },
    { SageCategoriesID: '677', SageCategories: 'Film' },
    { SageCategoriesID: '837', SageCategories: 'Filters' },
    { SageCategoriesID: '640', SageCategories: 'Fire Extinguishers' },
    { SageCategoriesID: '858', SageCategories: 'Fire pits' },
    { SageCategoriesID: '858', SageCategories: 'Fireplaces' },
    { SageCategoriesID: '374', SageCategories: 'First Aid Kits' },
    { SageCategoriesID: '770', SageCategories: 'Fishing Poles' },
    { SageCategoriesID: '534', SageCategories: 'Fitness Trackers' },
    { SageCategoriesID: '524', SageCategories: 'Flag Accessories' },
    { SageCategoriesID: '122', SageCategories: 'Flags' },
    { SageCategoriesID: '123', SageCategories: 'Flashlights' },
    { SageCategoriesID: '441', SageCategories: 'Flasks' },
    { SageCategoriesID: '818', SageCategories: 'Flooring' },
    { SageCategoriesID: '680', SageCategories: 'Flowers' },
    { SageCategoriesID: '126', SageCategories: 'Fly Swatters' },
    { SageCategoriesID: '125', SageCategories: 'Flying Discs' },
    { SageCategoriesID: '581', SageCategories: 'Foam' },
    { SageCategoriesID: '790', SageCategories: 'Fog Machines' },
    { SageCategoriesID: '127', SageCategories: 'Folders' },
    { SageCategoriesID: '128', SageCategories: 'Food' },
    { SageCategoriesID: '850', SageCategories: 'Forks' },
    { SageCategoriesID: '598', SageCategories: 'Forms' },
    { SageCategoriesID: '791', SageCategories: 'Fountains' },
    { SageCategoriesID: '427', SageCategories: 'Frames-Certificate' },
    { SageCategoriesID: '784', SageCategories: 'Frames-License' },
    { SageCategoriesID: '490', SageCategories: 'Frames-Monitor' },
    { SageCategoriesID: '227', SageCategories: 'Frames-Picture' },
    { SageCategoriesID: '814', SageCategories: 'Fruit' },
    { SageCategoriesID: '130', SageCategories: 'Fulfillment Services' },
    { SageCategoriesID: '549', SageCategories: 'Funnels' },
    { SageCategoriesID: '132', SageCategories: 'Furniture' },
    { SageCategoriesID: '133', SageCategories: 'Games' },
    { SageCategoriesID: '326', SageCategories: 'Garden Supplies' },
    { SageCategoriesID: '134', SageCategories: 'Garters' },
    { SageCategoriesID: '135', SageCategories: 'Gauges' },
    { SageCategoriesID: '444', SageCategories: 'Gavels' },
    { SageCategoriesID: '813', SageCategories: 'Gel' },
    { SageCategoriesID: '809', SageCategories: 'Gift Cards' },
    { SageCategoriesID: '834', SageCategories: 'Gift Wrap' },
    { SageCategoriesID: '414', SageCategories: 'Gift/Food Baskets' },
    { SageCategoriesID: '137', SageCategories: 'Glasses-Drinking' },
    { SageCategoriesID: '528', SageCategories: 'Glasses-Eye' },
    { SageCategoriesID: '869', SageCategories: 'Glasses-shot' },
    { SageCategoriesID: '776', SageCategories: 'Global Positioning Systems' },
    { SageCategoriesID: '138', SageCategories: 'Globes' },
    { SageCategoriesID: '139', SageCategories: 'Gloves' },
    { SageCategoriesID: '636', SageCategories: 'Goggles' },
    { SageCategoriesID: '614', SageCategories: 'Golf Clubs' },
    { SageCategoriesID: '615', SageCategories: 'Golf Kits' },
    { SageCategoriesID: '566', SageCategories: 'Golf Tools' },
    { SageCategoriesID: '714', SageCategories: 'Gowns' },
    { SageCategoriesID: '776', SageCategories: 'GPSs' },
    { SageCategoriesID: '764', SageCategories: 'Graters' },
    { SageCategoriesID: '650', SageCategories: 'Greens/Grass' },
    { SageCategoriesID: '576', SageCategories: 'Grills' },
    { SageCategoriesID: '765', SageCategories: 'Grinders' },
    { SageCategoriesID: '805', SageCategories: 'Grips' },
    { SageCategoriesID: '533', SageCategories: 'Grocery Dividers' },
    { SageCategoriesID: '553', SageCategories: 'Guards' },
    { SageCategoriesID: '810', SageCategories: 'Gum' },
    { SageCategoriesID: '796', SageCategories: 'Hair Products' },
    { SageCategoriesID: '431', SageCategories: 'Hammocks' },
    { SageCategoriesID: '797', SageCategories: 'Hand Sanitizers' },
    { SageCategoriesID: '699', SageCategories: 'Handcuffs' },
    { SageCategoriesID: '379', SageCategories: 'Handkerchiefs' },
    { SageCategoriesID: '683', SageCategories: 'Handles' },
    { SageCategoriesID: '346', SageCategories: 'Hangers' },
    { SageCategoriesID: '358', SageCategories: 'Hangers-Door' },
    { SageCategoriesID: '778', SageCategories: 'Hard Drives' },
    { SageCategoriesID: '769', SageCategories: 'Hard Hats' },
    { SageCategoriesID: '841', SageCategories: 'Hardware' },
    { SageCategoriesID: '143', SageCategories: 'Hats' },
    { SageCategoriesID: '145', SageCategories: 'Headbands' },
    { SageCategoriesID: '716', SageCategories: 'Headphones' },
    { SageCategoriesID: '716', SageCategories: 'Headsets' },
    { SageCategoriesID: '700', SageCategories: 'Heat Packs' },
    { SageCategoriesID: '854', SageCategories: 'Heat Presses' },
    { SageCategoriesID: '769', SageCategories: 'Helmets' },
    { SageCategoriesID: '147', SageCategories: 'Highlighters' },
    { SageCategoriesID: '675', SageCategories: 'Hockey Pucks' },
    { SageCategoriesID: '72', SageCategories: 'Holders' },
    { SageCategoriesID: '539', SageCategories: 'Hole Punches' },
    { SageCategoriesID: '430', SageCategories: 'Holograms' },
    { SageCategoriesID: '459', SageCategories: 'Holsters' },
    { SageCategoriesID: '833', SageCategories: 'Hoodies' },
    { SageCategoriesID: '346', SageCategories: 'Hooks' },
    { SageCategoriesID: '155', SageCategories: 'Horns' },
    { SageCategoriesID: '156', SageCategories: 'Hosiery' },
    { SageCategoriesID: '580', SageCategories: 'Hourglasses' },
    { SageCategoriesID: '787', SageCategories: 'Hubs' },
    { SageCategoriesID: '821', SageCategories: 'Humidifiers' },
    { SageCategoriesID: '694', SageCategories: 'Humidors' },
    { SageCategoriesID: '375', SageCategories: 'Hygiene Kits' },
    { SageCategoriesID: '829', SageCategories: 'Ice Cubes' },
    { SageCategoriesID: '703', SageCategories: 'Ice Packs' },
    { SageCategoriesID: '632', SageCategories: 'Imprinting Services' },
    { SageCategoriesID: '158', SageCategories: 'Incentive Programs' },
    { SageCategoriesID: '499', SageCategories: 'Index Tabs' },
    { SageCategoriesID: '160', SageCategories: 'Inflatables' },
    { SageCategoriesID: '556', SageCategories: 'Inflators' },
    { SageCategoriesID: '445', SageCategories: 'Information Packets' },
    { SageCategoriesID: '463', SageCategories: 'Ink-Stamp Pads' },
    { SageCategoriesID: '740', SageCategories: 'Insect Repellent' },
    { SageCategoriesID: '393', SageCategories: 'Inserts' },
    { SageCategoriesID: '713', SageCategories: 'Internet Terminals' },
    { SageCategoriesID: '161', SageCategories: 'Invitations' },
    { SageCategoriesID: '466', SageCategories: 'Irons' },
    { SageCategoriesID: '162', SageCategories: 'Jackets' },
    { SageCategoriesID: '163', SageCategories: 'Jars' },
    { SageCategoriesID: '100', SageCategories: 'Journals' },
    { SageCategoriesID: '437', SageCategories: 'Jugs' },
    { SageCategoriesID: '329', SageCategories: 'Kaleidoscopes' },
    { SageCategoriesID: '382', SageCategories: 'Karaoke Systems' },
    { SageCategoriesID: '696', SageCategories: 'Kayaks' },
    { SageCategoriesID: '168', SageCategories: 'Key Chains / Rings' },
    { SageCategoriesID: '788', SageCategories: 'Keyboards' },
    { SageCategoriesID: '497', SageCategories: 'Keys' },
    { SageCategoriesID: '171', SageCategories: 'Kites' },
    { SageCategoriesID: '652', SageCategories: 'Kits-Automotive' },
    { SageCategoriesID: '374', SageCategories: 'Kits-First Aid' },
    { SageCategoriesID: '615', SageCategories: 'Kits-Golf' },
    { SageCategoriesID: '375', SageCategories: 'Kits-Hygiene' },
    { SageCategoriesID: '634', SageCategories: 'Kits-Makeup' },
    { SageCategoriesID: '540', SageCategories: 'Kits-Other' },
    { SageCategoriesID: '643', SageCategories: 'Kits-Sewing' },
    { SageCategoriesID: '348', SageCategories: 'Kits-Shoe Shine' },
    { SageCategoriesID: '531', SageCategories: 'Kits-Survival' },
    { SageCategoriesID: '289', SageCategories: 'Kits-Tool' },
    { SageCategoriesID: '375', SageCategories: 'Kits-Travel' },
    { SageCategoriesID: '817', SageCategories: 'Kits-Wine' },
    { SageCategoriesID: '173', SageCategories: 'Knives' },
    { SageCategoriesID: '523', SageCategories: 'Label Printers' },
    { SageCategoriesID: '174', SageCategories: 'Labels' },
    { SageCategoriesID: '310', SageCategories: 'Laminators' },
    { SageCategoriesID: '175', SageCategories: 'Lamps' },
    { SageCategoriesID: '176', SageCategories: 'Lanterns' },
    { SageCategoriesID: '744', SageCategories: 'Lanyards' },
    { SageCategoriesID: '867', SageCategories: 'Launchers' },
    { SageCategoriesID: '326', SageCategories: 'Lawn Supplies' },
    { SageCategoriesID: '639', SageCategories: 'Leashes' },
    { SageCategoriesID: '620', SageCategories: 'Leis' },
    { SageCategoriesID: '768', SageCategories: 'Lenticulars' },
    { SageCategoriesID: '784', SageCategories: 'License Frames' },
    { SageCategoriesID: '181', SageCategories: 'License Plates' },
    { SageCategoriesID: '92', SageCategories: 'Lids' },
    { SageCategoriesID: '609', SageCategories: 'Light bulbs' },
    { SageCategoriesID: '182', SageCategories: 'Lighters' },
    { SageCategoriesID: '849', SageCategories: 'Liners' },
    { SageCategoriesID: '461', SageCategories: 'Lint Removers' },
    { SageCategoriesID: '616', SageCategories: 'Lip Balm' },
    { SageCategoriesID: '183', SageCategories: 'Locks' },
    { SageCategoriesID: '735', SageCategories: 'Lotion' },
    { SageCategoriesID: '185', SageCategories: 'Luggage' },
    { SageCategoriesID: '605', SageCategories: 'Lures' },
    { SageCategoriesID: '188', SageCategories: 'Magnets' },
    { SageCategoriesID: '189', SageCategories: 'Magnifiers' },
    { SageCategoriesID: '458', SageCategories: 'Mailboxes' },
    { SageCategoriesID: '634', SageCategories: 'Makeup Kits' },
    { SageCategoriesID: '522', SageCategories: 'Manicure Sets' },
    { SageCategoriesID: '190', SageCategories: 'Maps' },
    { SageCategoriesID: '673', SageCategories: 'Marble' },
    { SageCategoriesID: '191', SageCategories: 'Markers' },
    { SageCategoriesID: '395', SageCategories: 'Masks' },
    { SageCategoriesID: '611', SageCategories: 'Massagers' },
    { SageCategoriesID: '192', SageCategories: 'Matches' },
    { SageCategoriesID: '193', SageCategories: 'Mats' },
    { SageCategoriesID: '197', SageCategories: 'Measuring Devices' },
    { SageCategoriesID: '198', SageCategories: 'Measuring Tapes' },
    { SageCategoriesID: '392', SageCategories: 'Medallions' },
    { SageCategoriesID: '392', SageCategories: 'Medals' },
    { SageCategoriesID: '635', SageCategories: 'Medical Equipment' },
    { SageCategoriesID: '582', SageCategories: 'Medicine' },
    { SageCategoriesID: '405', SageCategories: 'Megaphones' },
    { SageCategoriesID: '200', SageCategories: 'Memo Boards' },
    { SageCategoriesID: '201', SageCategories: 'Memo Pad & Paper Holders' },
    { SageCategoriesID: '719', SageCategories: 'Menus' },
    { SageCategoriesID: '755', SageCategories: 'Microphones' },
    { SageCategoriesID: '339', SageCategories: 'Microscopes' },
    { SageCategoriesID: '204', SageCategories: 'Mirrors' },
    { SageCategoriesID: '546', SageCategories: 'Mittens' },
    { SageCategoriesID: '237', SageCategories: 'Mitts' },
    { SageCategoriesID: '504', SageCategories: 'Mixers' },
    { SageCategoriesID: '811', SageCategories: 'Mobile Marketing' },
    { SageCategoriesID: '206', SageCategories: 'Mobiles' },
    { SageCategoriesID: '754', SageCategories: 'Models' },
    { SageCategoriesID: '857', SageCategories: 'Molds' },
    { SageCategoriesID: '349', SageCategories: 'Money Clips' },
    { SageCategoriesID: '515', SageCategories: 'Money-Play' },
    { SageCategoriesID: '490', SageCategories: 'Monitor Frames' },
    { SageCategoriesID: '698', SageCategories: 'Monoculars' },
    { SageCategoriesID: '859', SageCategories: 'Motorcycles' },
    { SageCategoriesID: '646', SageCategories: 'Motors' },
    { SageCategoriesID: '488', SageCategories: 'Mouse Pads' },
    { SageCategoriesID: '494', SageCategories: 'Mouse-Computer' },
    { SageCategoriesID: '207', SageCategories: 'Mugs' },
    { SageCategoriesID: '682', SageCategories: 'Murals' },
    { SageCategoriesID: '242', SageCategories: 'Music Players' },
    { SageCategoriesID: '208', SageCategories: 'Musical Instruments' },
    { SageCategoriesID: '111', SageCategories: 'Nail Files' },
    { SageCategoriesID: '737', SageCategories: 'Nail Polish' },
    { SageCategoriesID: '209', SageCategories: 'Name Plates' },
    { SageCategoriesID: '600', SageCategories: 'Napkin Rings' },
    { SageCategoriesID: '415', SageCategories: 'Napkins' },
    { SageCategoriesID: '394', SageCategories: 'Necklaces' },
    { SageCategoriesID: '743', SageCategories: 'Needles' },
    { SageCategoriesID: '353', SageCategories: 'Nickels' },
    { SageCategoriesID: '211', SageCategories: 'Night Lights' },
    { SageCategoriesID: '661', SageCategories: 'Noise Makers' },
    { SageCategoriesID: '455', SageCategories: 'Notebooks' },
    { SageCategoriesID: '428', SageCategories: 'Nuts' },
    { SageCategoriesID: '479', SageCategories: 'Oil Products' },
    { SageCategoriesID: '45', SageCategories: 'Openers' },
    { SageCategoriesID: '325', SageCategories: 'Organizers' },
    { SageCategoriesID: '213', SageCategories: 'Ornaments' },
    { SageCategoriesID: '562', SageCategories: 'Overalls' },
    { SageCategoriesID: '862', SageCategories: 'Oxygen' },
    { SageCategoriesID: '670', SageCategories: 'Pacifiers' },
    { SageCategoriesID: '851', SageCategories: 'Paddle Boards' },
    { SageCategoriesID: '236', SageCategories: 'Padfolios' },
    { SageCategoriesID: '215', SageCategories: 'Pads' },
    { SageCategoriesID: '52', SageCategories: 'Pails' },
    { SageCategoriesID: '865', SageCategories: 'Paint' },
    { SageCategoriesID: '682', SageCategories: 'Paintings' },
    { SageCategoriesID: '749', SageCategories: 'Pajamas' },
    { SageCategoriesID: '658', SageCategories: 'Palettes' },
    { SageCategoriesID: '628', SageCategories: 'Pans' },
    { SageCategoriesID: '315', SageCategories: 'Pants' },
    { SageCategoriesID: '216', SageCategories: 'Paper' },
    { SageCategoriesID: '217', SageCategories: 'Paper Weights' },
    { SageCategoriesID: '432', SageCategories: 'Patches' },
    { SageCategoriesID: '773', SageCategories: 'PDAs' },
    { SageCategoriesID: '534', SageCategories: 'Pedometers' },
    { SageCategoriesID: '367', SageCategories: 'Pen/Pencil Holders' },
    { SageCategoriesID: '686', SageCategories: 'Pencil Sharpeners' },
    { SageCategoriesID: '219', SageCategories: 'Pencils' },
    { SageCategoriesID: '800', SageCategories: 'Pendants' },
    { SageCategoriesID: '220', SageCategories: 'Pennants' },
    { SageCategoriesID: '221', SageCategories: 'Pens' },
    { SageCategoriesID: '597', SageCategories: 'Pepper spray' },
    { SageCategoriesID: '222', SageCategories: 'Perfume' },
    { SageCategoriesID: '552', SageCategories: 'Permits' },
    { SageCategoriesID: '281', SageCategories: 'Phone Accessories' },
    { SageCategoriesID: '224', SageCategories: 'Phone Books' },
    { SageCategoriesID: '225', SageCategories: 'Phone Cards' },
    { SageCategoriesID: '224', SageCategories: 'Phone Indexes' },
    { SageCategoriesID: '442', SageCategories: 'Phones' },
    { SageCategoriesID: '802', SageCategories: 'Photography Services' },
    { SageCategoriesID: '843', SageCategories: 'Picks' },
    { SageCategoriesID: '226', SageCategories: 'Picnic Baskets' },
    { SageCategoriesID: '227', SageCategories: 'Picture Frames' },
    { SageCategoriesID: '228', SageCategories: 'Pillows' },
    { SageCategoriesID: '687', SageCategories: 'Pinatas' },
    { SageCategoriesID: '229', SageCategories: 'Pins' },
    { SageCategoriesID: '704', SageCategories: 'Pipes (smoke)' },
    { SageCategoriesID: '230', SageCategories: 'Pitchers' },
    { SageCategoriesID: '325', SageCategories: 'Planners' },
    { SageCategoriesID: '398', SageCategories: 'Plants' },
    { SageCategoriesID: '231', SageCategories: 'Plaques' },
    { SageCategoriesID: '233', SageCategories: 'Plates' },
    { SageCategoriesID: '322', SageCategories: 'Platters' },
    { SageCategoriesID: '641', SageCategories: 'Plugs' },
    { SageCategoriesID: '842', SageCategories: 'Plush Toy Accessories' },
    { SageCategoriesID: '273', SageCategories: 'Plush Toys' },
    { SageCategoriesID: '235', SageCategories: 'Pocket Protectors' },
    { SageCategoriesID: '377', SageCategories: 'Pointers' },
    { SageCategoriesID: '798', SageCategories: 'Poker Chips' },
    { SageCategoriesID: '400', SageCategories: 'Pom-Poms' },
    { SageCategoriesID: '244', SageCategories: 'Ponchos' },
    { SageCategoriesID: '835', SageCategories: 'Popcorn' },
    { SageCategoriesID: '236', SageCategories: 'Portfolios' },
    { SageCategoriesID: '419', SageCategories: 'Postcards' },
    { SageCategoriesID: '365', SageCategories: 'Posters' },
    { SageCategoriesID: '237', SageCategories: 'Pot Holders' },
    { SageCategoriesID: '603', SageCategories: 'Potpourri' },
    { SageCategoriesID: '753', SageCategories: 'Pots' },
    { SageCategoriesID: '238', SageCategories: 'Pouches' },
    { SageCategoriesID: '570', SageCategories: 'Pourers' },
    { SageCategoriesID: '701', SageCategories: 'Power Supplies/Inverters' },
    { SageCategoriesID: '474', SageCategories: 'Price Markers/Guns' },
    { SageCategoriesID: '709', SageCategories: 'Printers-PC' },
    { SageCategoriesID: '682', SageCategories: 'Prints' },
    { SageCategoriesID: '718', SageCategories: 'Projectors' },
    { SageCategoriesID: '675', SageCategories: 'Pucks-Hockey' },
    { SageCategoriesID: '729', SageCategories: 'Pumps' },
    { SageCategoriesID: '608', SageCategories: 'Puppets' },
    { SageCategoriesID: '633', SageCategories: 'Purifiers' },
    { SageCategoriesID: '240', SageCategories: 'Purses' },
    { SageCategoriesID: '614', SageCategories: 'Putters' },
    { SageCategoriesID: '241', SageCategories: 'Puzzles' },
    { SageCategoriesID: '666', SageCategories: 'Rackets' },
    { SageCategoriesID: '409', SageCategories: 'Racks' },
    { SageCategoriesID: '242', SageCategories: 'Radios' },
    { SageCategoriesID: '560', SageCategories: 'Rattles' },
    { SageCategoriesID: '364', SageCategories: 'Razors' },
    { SageCategoriesID: '752', SageCategories: 'Record Players' },
    { SageCategoriesID: '312', SageCategories: 'Record/Book Keeping' },
    { SageCategoriesID: '526', SageCategories: 'Recorders' },
    { SageCategoriesID: '607', SageCategories: 'Reels' },
    { SageCategoriesID: '248', SageCategories: 'Reflectors' },
    { SageCategoriesID: '406', SageCategories: 'Remote Controls' },
    { SageCategoriesID: '251', SageCategories: 'Ribbons' },
    { SageCategoriesID: '166', SageCategories: 'Rings' },
    { SageCategoriesID: '363', SageCategories: 'Robes' },
    { SageCategoriesID: '559', SageCategories: 'Rompers' },
    { SageCategoriesID: '376', SageCategories: 'Ropes' },
    { SageCategoriesID: '604', SageCategories: 'Rubber Bands' },
    { SageCategoriesID: '525', SageCategories: 'Rugs' },
    { SageCategoriesID: '252', SageCategories: 'Rulers' },
    { SageCategoriesID: '645', SageCategories: 'Safes' },
    { SageCategoriesID: '579', SageCategories: 'Sashes' },
    { SageCategoriesID: '779', SageCategories: 'Sauces' },
    { SageCategoriesID: '254', SageCategories: 'Scales' },
    { SageCategoriesID: '710', SageCategories: 'Scanners-PC' },
    { SageCategoriesID: '255', SageCategories: 'Scarves' },
    { SageCategoriesID: '256', SageCategories: 'Scissors' },
    { SageCategoriesID: '571', SageCategories: 'Scoopers' },
    { SageCategoriesID: '853', SageCategories: 'Scooters' },
    { SageCategoriesID: '617', SageCategories: 'Scorekeepers' },
    { SageCategoriesID: '257', SageCategories: 'Scrapers' },
    { SageCategoriesID: '258', SageCategories: 'Scratch-Off Items' },
    { SageCategoriesID: '257', SageCategories: 'Scratchers' },
    { SageCategoriesID: '490', SageCategories: 'Screen Frames' },
    { SageCategoriesID: '259', SageCategories: 'Screwdrivers' },
    { SageCategoriesID: '807', SageCategories: 'Scrubs' },
    { SageCategoriesID: '758', SageCategories: 'Scrunchies' },
    { SageCategoriesID: '260', SageCategories: 'Sculptures' },
    { SageCategoriesID: '464', SageCategories: 'Seals' },
    { SageCategoriesID: '261', SageCategories: 'Seed Paper' },
    { SageCategoriesID: '261', SageCategories: 'Seeds' },
    { SageCategoriesID: '512', SageCategories: 'Sensors' },
    { SageCategoriesID: '643', SageCategories: 'Sewing Kits' },
    { SageCategoriesID: '275', SageCategories: 'Shades' },
    { SageCategoriesID: '416', SageCategories: 'Shakers' },
    { SageCategoriesID: '686', SageCategories: 'Sharpeners' },
    { SageCategoriesID: '364', SageCategories: 'Shavers' },
    { SageCategoriesID: '500', SageCategories: 'Sheet Protectors' },
    { SageCategoriesID: '847', SageCategories: 'Sheets' },
    { SageCategoriesID: '681', SageCategories: 'Shelves' },
    { SageCategoriesID: '262', SageCategories: 'Shirts' },
    { SageCategoriesID: '660', SageCategories: 'Shoe Horns' },
    { SageCategoriesID: '263', SageCategories: 'Shoe Laces' },
    { SageCategoriesID: '348', SageCategories: 'Shoe Shine Kit' },
    { SageCategoriesID: '537', SageCategories: 'Shoes' },
    { SageCategoriesID: '264', SageCategories: 'Shorts' },
    { SageCategoriesID: '869', SageCategories: 'Shot glasses' },
    { SageCategoriesID: '630', SageCategories: 'Shovels' },
    { SageCategoriesID: '622', SageCategories: 'Shower Gels' },
    { SageCategoriesID: '863', SageCategories: 'Showerheads' },
    { SageCategoriesID: '513', SageCategories: 'Shredders' },
    { SageCategoriesID: '738', SageCategories: 'Shrink Wrap Machine' },
    { SageCategoriesID: '265', SageCategories: 'Signs' },
    { SageCategoriesID: '732', SageCategories: 'Silverware' },
    { SageCategoriesID: '782', SageCategories: 'Skateboards' },
    { SageCategoriesID: '726', SageCategories: 'Skewers' },
    { SageCategoriesID: '449', SageCategories: 'Skirts' },
    { SageCategoriesID: '783', SageCategories: 'Skis' },
    { SageCategoriesID: '827', SageCategories: 'Sleds' },
    { SageCategoriesID: '846', SageCategories: 'Sleeping Bags' },
    { SageCategoriesID: '848', SageCategories: 'Sleeves' },
    { SageCategoriesID: '825', SageCategories: 'Slingshots' },
    { SageCategoriesID: '870', SageCategories: 'Slippers' },
    { SageCategoriesID: '408', SageCategories: 'Snow Domes' },
    { SageCategoriesID: '781', SageCategories: 'Snowboards' },
    { SageCategoriesID: '622', SageCategories: 'Soap' },
    { SageCategoriesID: '602', SageCategories: 'Soap Dishes' },
    { SageCategoriesID: '266', SageCategories: 'Socks' },
    { SageCategoriesID: '538', SageCategories: 'Software' },
    { SageCategoriesID: '761', SageCategories: 'Solar Panels' },
    { SageCategoriesID: '444', SageCategories: 'Sound Blocks' },
    { SageCategoriesID: '545', SageCategories: 'Spa Sets' },
    { SageCategoriesID: '545', SageCategories: 'Spas' },
    { SageCategoriesID: '577', SageCategories: 'Spatulas' },
    { SageCategoriesID: '590', SageCategories: 'Speakers' },
    { SageCategoriesID: '267', SageCategories: 'Spices' },
    { SageCategoriesID: '672', SageCategories: 'Spinners' },
    { SageCategoriesID: '318', SageCategories: 'Sponges' },
    { SageCategoriesID: '627', SageCategories: 'Spoon Rests' },
    { SageCategoriesID: '731', SageCategories: 'Spoons' },
    { SageCategoriesID: '548', SageCategories: 'Squeegees' },
    { SageCategoriesID: '707', SageCategories: 'Squeeze Toys' },
    { SageCategoriesID: '689', SageCategories: 'Squeezers' },
    { SageCategoriesID: '463', SageCategories: 'Stamp Pads/Ink' },
    { SageCategoriesID: '331', SageCategories: 'Stamps' },
    { SageCategoriesID: '370', SageCategories: 'Stands' },
    { SageCategoriesID: '344', SageCategories: 'Staple Removers' },
    { SageCategoriesID: '344', SageCategories: 'Staplers' },
    { SageCategoriesID: '270', SageCategories: 'Stationery' },
    { SageCategoriesID: '612', SageCategories: 'Statues' },
    { SageCategoriesID: '506', SageCategories: 'Steamers' },
    { SageCategoriesID: '399', SageCategories: 'Stemware' },
    { SageCategoriesID: '283', SageCategories: 'Stencils' },
    { SageCategoriesID: '242', SageCategories: 'Stereos' },
    { SageCategoriesID: '96', SageCategories: 'Stickers' },
    { SageCategoriesID: '567', SageCategories: 'Sticks' },
    { SageCategoriesID: '844', SageCategories: 'Stirrers' },
    { SageCategoriesID: '407', SageCategories: 'Stockings' },
    { SageCategoriesID: '751', SageCategories: 'Stones' },
    { SageCategoriesID: '775', SageCategories: 'Stools' },
    { SageCategoriesID: '572', SageCategories: 'Stoppers' },
    { SageCategoriesID: '767', SageCategories: 'Strainers' },
    { SageCategoriesID: '272', SageCategories: 'Straps' },
    { SageCategoriesID: '574', SageCategories: 'Straws' },
    { SageCategoriesID: '551', SageCategories: 'Streamers' },
    { SageCategoriesID: '707', SageCategories: 'Stress Toys' },
    { SageCategoriesID: '435', SageCategories: 'Strings' },
    { SageCategoriesID: '734', SageCategories: 'Strobe Lights' },
    { SageCategoriesID: '822', SageCategories: 'Strollers' },
    { SageCategoriesID: '842', SageCategories: 'Stuffed Animal Accessories' },
    { SageCategoriesID: '273', SageCategories: 'Stuffed Animals' },
    { SageCategoriesID: '823', SageCategories: 'Styluses' },
    { SageCategoriesID: '536', SageCategories: 'Sunblock' },
    { SageCategoriesID: '274', SageCategories: 'Sunglasses' },
    { SageCategoriesID: '536', SageCategories: 'Sunscreen' },
    { SageCategoriesID: '275', SageCategories: 'Sunshades' },
    { SageCategoriesID: '536', SageCategories: 'Suntan Lotion' },
    { SageCategoriesID: '804', SageCategories: 'Surfboards' },
    { SageCategoriesID: '531', SageCategories: 'Survival Kits' },
    { SageCategoriesID: '276', SageCategories: 'Suspenders' },
    { SageCategoriesID: '145', SageCategories: 'Sweatbands' },
    { SageCategoriesID: '317', SageCategories: 'Sweaters' },
    { SageCategoriesID: '277', SageCategories: 'Sweats' },
    { SageCategoriesID: '715', SageCategories: 'Swim Fins' },
    { SageCategoriesID: '747', SageCategories: 'Swimming Pool' },
    { SageCategoriesID: '748', SageCategories: 'Swimsuits' },
    { SageCategoriesID: '861', SageCategories: 'Swing Sets' },
    { SageCategoriesID: '626', SageCategories: 'Syringes' },
    { SageCategoriesID: '417', SageCategories: 'Table Liners' },
    { SageCategoriesID: '417', SageCategories: 'Tablecloths' },
    { SageCategoriesID: '702', SageCategories: 'Tables' },
    { SageCategoriesID: '685', SageCategories: 'Tablets' },
    { SageCategoriesID: '498', SageCategories: 'Tabs-Metal' },
    { SageCategoriesID: '279', SageCategories: 'Tags' },
    { SageCategoriesID: '839', SageCategories: 'Tank Tops' },
    { SageCategoriesID: '280', SageCategories: 'Tape' },
    { SageCategoriesID: '198', SageCategories: 'Tape Measures' },
    { SageCategoriesID: '435', SageCategories: 'Tassels' },
    { SageCategoriesID: '656', SageCategories: 'Tattoos' },
    { SageCategoriesID: '518', SageCategories: 'Tea' },
    { SageCategoriesID: '385', SageCategories: 'Teapots' },
    { SageCategoriesID: '565', SageCategories: 'Tees (Golf)' },
    { SageCategoriesID: '282', SageCategories: 'Telescopes' },
    { SageCategoriesID: '501', SageCategories: 'Televisions' },
    { SageCategoriesID: '283', SageCategories: 'Templates' },
    { SageCategoriesID: '595', SageCategories: 'Tents' },
    { SageCategoriesID: '436', SageCategories: 'Test Tubes' },
    { SageCategoriesID: '512', SageCategories: 'Testers' },
    { SageCategoriesID: '811', SageCategories: 'Text Messaging' },
    { SageCategoriesID: '285', SageCategories: 'Thermometers' },
    { SageCategoriesID: '347', SageCategories: 'Thermos' },
    { SageCategoriesID: '642', SageCategories: 'Thimbles' },
    { SageCategoriesID: '638', SageCategories: 'Tiaras' },
    { SageCategoriesID: '529', SageCategories: 'Tickets' },
    { SageCategoriesID: '828', SageCategories: 'Tie Bars' },
    { SageCategoriesID: '286', SageCategories: 'Ties' },
    { SageCategoriesID: '465', SageCategories: 'Tiles' },
    { SageCategoriesID: '287', SageCategories: 'Timers' },
    { SageCategoriesID: '472', SageCategories: 'Tins' },
    { SageCategoriesID: '855', SageCategories: 'Tissues' },
    { SageCategoriesID: '507', SageCategories: 'Toasters' },
    { SageCategoriesID: '662', SageCategories: 'Toilet Accessories' },
    { SageCategoriesID: '375', SageCategories: 'Toiletries' },
    { SageCategoriesID: '288', SageCategories: 'Tokens' },
    { SageCategoriesID: '624', SageCategories: 'Tongs' },
    { SageCategoriesID: '289', SageCategories: 'Tool Kits' },
    { SageCategoriesID: '289', SageCategories: 'Tools' },
    { SageCategoriesID: '563', SageCategories: 'Toothbrushes' },
    { SageCategoriesID: '563', SageCategories: 'Toothpaste' },
    { SageCategoriesID: '736', SageCategories: 'Toothpicks' },
    { SageCategoriesID: '290', SageCategories: 'Towels' },
    { SageCategoriesID: '291', SageCategories: 'Toys' },
    { SageCategoriesID: '338', SageCategories: 'Trade Show Booths' },
    { SageCategoriesID: '292', SageCategories: 'Transfers' },
    { SageCategoriesID: '381', SageCategories: 'Translators' },
    { SageCategoriesID: '443', SageCategories: 'Trash Cans' },
    { SageCategoriesID: '375', SageCategories: 'Travel Kits' },
    { SageCategoriesID: '322', SageCategories: 'Trays' },
    { SageCategoriesID: '398', SageCategories: 'Trees' },
    { SageCategoriesID: '741', SageCategories: 'Trivets' },
    { SageCategoriesID: '293', SageCategories: 'Trophies' },
    { SageCategoriesID: '535', SageCategories: 'Trophy Parts' },
    { SageCategoriesID: '489', SageCategories: 'Tubes-Mail/Shipping' },
    { SageCategoriesID: '840', SageCategories: 'Tumblers' },
    { SageCategoriesID: '752', SageCategories: 'Turntables' },
    { SageCategoriesID: '693', SageCategories: 'Tweezers' },
    { SageCategoriesID: '294', SageCategories: 'Umbrellas' },
    { SageCategoriesID: '468', SageCategories: 'Underwear' },
    { SageCategoriesID: '295', SageCategories: 'Uniforms' },
    { SageCategoriesID: '684', SageCategories: 'Urns' },
    { SageCategoriesID: '778', SageCategories: 'USB Drives' },
    { SageCategoriesID: '296', SageCategories: 'Utensils' },
    { SageCategoriesID: '337', SageCategories: 'Vacuums' },
    { SageCategoriesID: '728', SageCategories: 'Valves' },
    { SageCategoriesID: '362', SageCategories: 'Vases' },
    { SageCategoriesID: '389', SageCategories: 'Vests' },
    { SageCategoriesID: '585', SageCategories: 'Video Players' },
    { SageCategoriesID: '669', SageCategories: 'Viewers' },
    { SageCategoriesID: '298', SageCategories: 'Vinyl Letters' },
    { SageCategoriesID: '299', SageCategories: 'Visors' },
    { SageCategoriesID: '526', SageCategories: 'Voice Recorders' },
    { SageCategoriesID: '667', SageCategories: 'Wagons' },
    { SageCategoriesID: '803', SageCategories: 'Wakeboards' },
    { SageCategoriesID: '300', SageCategories: 'Wallets' },
    { SageCategoriesID: '527', SageCategories: 'Wands' },
    { SageCategoriesID: '583', SageCategories: 'Warmers' },
    { SageCategoriesID: '443', SageCategories: 'Wastebaskets' },
    { SageCategoriesID: '845', SageCategories: 'Watch Winders' },
    { SageCategoriesID: '301', SageCategories: 'Watches' },
    { SageCategoriesID: '806', SageCategories: 'Water' },
    { SageCategoriesID: '824', SageCategories: 'Wax' },
    { SageCategoriesID: '302', SageCategories: 'Weather Instruments' },
    { SageCategoriesID: '610', SageCategories: 'Weights' },
    { SageCategoriesID: '708', SageCategories: 'Whips' },
    { SageCategoriesID: '354', SageCategories: 'Whistles' },
    { SageCategoriesID: '830', SageCategories: 'Wigs' },
    { SageCategoriesID: '373', SageCategories: 'Windsocks' },
    { SageCategoriesID: '575', SageCategories: 'Wine' },
    { SageCategoriesID: '817', SageCategories: 'Wine Kits' },
    { SageCategoriesID: '544', SageCategories: 'Wipers' },
    { SageCategoriesID: '353', SageCategories: 'Wood Nickels' },
    { SageCategoriesID: '487', SageCategories: 'Wrist Rests' },
    { SageCategoriesID: '305', SageCategories: 'Wristbands' },
    { SageCategoriesID: '307', SageCategories: 'Yardsticks' },
    { SageCategoriesID: '414', SageCategories: 'Baskets-GiftFood' },
    { SageCategoriesID: '836', SageCategories: 'Pet Treats' },
    { SageCategoriesID: '489', SageCategories: 'Tubes-MailShipping' },
    { SageCategoriesID: '308', SageCategories: 'Yo-Yo\'s' },
    { SageCategoriesID: '860', SageCategories: 'AllTerrain Vehicles' },
    { SageCategoriesID: '273', SageCategories: 'AnimalsStuffed' },
    { SageCategoriesID: '414', SageCategories: 'BasketsGiftFood' },
    { SageCategoriesID: '452', SageCategories: 'BoardsBulletin' },
    { SageCategoriesID: '41', SageCategories: 'BoardsCarving' },
    { SageCategoriesID: '831', SageCategories: 'BoardsChalk' },
    { SageCategoriesID: '41', SageCategories: 'BoardsCutting' },
    { SageCategoriesID: '200', SageCategories: 'BoardsMemo' },
    { SageCategoriesID: '461', SageCategories: 'BrushesLint' },
    { SageCategoriesID: '563', SageCategories: 'BrushesTooth' },
    { SageCategoriesID: '801', SageCategories: 'CardsDownload' },
    { SageCategoriesID: '809', SageCategories: 'CardsGift' },
    { SageCategoriesID: '225', SageCategories: 'CardsPhone' },
    { SageCategoriesID: '420', SageCategories: 'CatalogsCat Sheets' },
    { SageCategoriesID: '368', SageCategories: 'Clip HoldersDispensers' },
    { SageCategoriesID: '349', SageCategories: 'ClipsMoney' },
    { SageCategoriesID: '820', SageCategories: 'EBook Readers' },
    { SageCategoriesID: '116', SageCategories: 'FabricsNonWearables' },
    { SageCategoriesID: '427', SageCategories: 'FramesCertificate' },
    { SageCategoriesID: '784', SageCategories: 'FramesLicense' },
    { SageCategoriesID: '490', SageCategories: 'FramesMonitor' },
    { SageCategoriesID: '227', SageCategories: 'FramesPicture' },
    { SageCategoriesID: '414', SageCategories: 'GiftFood Baskets' },
    { SageCategoriesID: '137', SageCategories: 'GlassesDrinking' },
    { SageCategoriesID: '528', SageCategories: 'GlassesEye' },
    { SageCategoriesID: '869', SageCategories: 'Glassesshot' },
    { SageCategoriesID: '650', SageCategories: 'GreensGrass' },
    { SageCategoriesID: '358', SageCategories: 'HangersDoor' },
    { SageCategoriesID: '463', SageCategories: 'InkStamp Pads' },
    { SageCategoriesID: '168', SageCategories: 'Key Chains  Rings' },
    { SageCategoriesID: '652', SageCategories: 'KitsAutomotive' },
    { SageCategoriesID: '374', SageCategories: 'KitsFirst Aid' },
    { SageCategoriesID: '615', SageCategories: 'KitsGolf' },
    { SageCategoriesID: '375', SageCategories: 'KitsHygiene' },
    { SageCategoriesID: '634', SageCategories: 'KitsMakeup' },
    { SageCategoriesID: '540', SageCategories: 'KitsOther' },
    { SageCategoriesID: '643', SageCategories: 'KitsSewing' },
    { SageCategoriesID: '348', SageCategories: 'KitsShoe Shine' },
    { SageCategoriesID: '531', SageCategories: 'KitsSurvival' },
    { SageCategoriesID: '289', SageCategories: 'KitsTool' },
    { SageCategoriesID: '375', SageCategories: 'KitsTravel' },
    { SageCategoriesID: '817', SageCategories: 'KitsWine' },
    { SageCategoriesID: '201', SageCategories: 'Memo Pad  Paper Holders' },
    { SageCategoriesID: '515', SageCategories: 'MoneyPlay' },
    { SageCategoriesID: '494', SageCategories: 'MouseComputer' },
    { SageCategoriesID: '367', SageCategories: 'PenPencil Holders' },
    { SageCategoriesID: '704', SageCategories: 'Pipes smoke' },
    { SageCategoriesID: '400', SageCategories: 'PomPoms' },
    { SageCategoriesID: '701', SageCategories: 'Power SuppliesInverters' },
    { SageCategoriesID: '474', SageCategories: 'Price MarkersGuns' },
    { SageCategoriesID: '709', SageCategories: 'PrintersPC' },
    { SageCategoriesID: '675', SageCategories: 'PucksHockey' },
    { SageCategoriesID: '312', SageCategories: 'RecordBook Keeping' },
    { SageCategoriesID: '710', SageCategories: 'ScannersPC' },
    { SageCategoriesID: '258', SageCategories: 'ScratchOff Items' },
    { SageCategoriesID: '463', SageCategories: 'Stamp PadsInk' },
    { SageCategoriesID: '498', SageCategories: 'TabsMetal' },
    { SageCategoriesID: '565', SageCategories: 'Tees Golf' },
    { SageCategoriesID: '489', SageCategories: 'TubesMailShipping' },
    { SageCategoriesID: '308', SageCategories: "YoYo's" },
    { SageCategoriesID: '309', SageCategories: 'Zipper Pulls' }
];

export const sageCategories = sageCategoriesObject.map(cate=>cate.SageCategories);

