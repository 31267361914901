import React from 'react';
const TabContentItem = ({id,children,style,active})=>{
    const className = active?"tab-pane active":"tab-pane fade in";
    return(
        <div role="tabpanel" className={className} style={style} id={id}>
            <ul className="list-group media-list media-list-stream">
                {children}
            </ul>
        </div>
    );
};
export default TabContentItem;
