import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import { getSwatchURL } from '../../Utils/ashlinDesign';
import { getVariants,getStoreInfo,updateVariantOptionValue   } from '../../Utils/bigCommerce';
class UpdateSwatch extends Component{

    handleUpdateSwatch=async()=>{
        const storeInfo =await getStoreInfo("BigCommerce Ashlin");
        const list = [
            {"Sku":"751-07-01","MerchanSku":"2698+7149"},
            {"Sku":"7728-09-38","MerchanSku":"2610+6912"},
            {"Sku":"7728-18-04","MerchanSku":"2611+6914"},
            {"Sku":"7728-48-08","MerchanSku":"2612+6916"},
            {"Sku":"755-18-01","MerchanSku":"2699+7151"},
            {"Sku":"7748-18-01","MerchanSku":"2625+6950"},
            {"Sku":"7748-18-04","MerchanSku":"2625+6952"},
            {"Sku":"7971-07-01","MerchanSku":"2630+6967"},
            {"Sku":"7931-18-01","MerchanSku":"2138+6245"},
            {"Sku":"7931-18-08","MerchanSku":"2138+6247"},
            {"Sku":"7976-18-01","MerchanSku":"2269+6255"},
            {"Sku":"7976-18-02","MerchanSku":"2269+6256"},
            {"Sku":"7786-18-01","MerchanSku":"2606+6897"},
            {"Sku":"B619-18-01","MerchanSku":"2206+6159"},
            {"Sku":"B619-18-09","MerchanSku":"2206+6162"},
            {"Sku":"B620-18-08","MerchanSku":"2661+7049"},
            {"Sku":"B620-18-01","MerchanSku":"2661+7048"},
            {"Sku":"B620-18-09","MerchanSku":"2661+7050"},
            {"Sku":"B8262-18-01","MerchanSku":"1996+5915"},
            {"Sku":"B8262-18-08","MerchanSku":"1996+5916"},
            {"Sku":"7976-18-08","MerchanSku":"2269+6257"},
            {"Sku":"BOTTLHLDR-08-01","MerchanSku":"2448+6515"},
            {"Sku":"BOTTLHLDR-08-02","MerchanSku":"2448+6516"},
            {"Sku":"BOWTIE01-08-06","MerchanSku":"2666+7061"},
            {"Sku":"BOWTIE01-18-01","MerchanSku":"2667+7063"},
            {"Sku":"BOWTIE01-18-08","MerchanSku":"2667+7104"},
            {"Sku":"BRACELT01-00-01","MerchanSku":"2669+7067"},
            {"Sku":"BRACELT01-00-02","MerchanSku":"2669+7105"},
            {"Sku":"BRACELT01-00-08","MerchanSku":"2669+7106"},
            {"Sku":"BRACELT01-00-16","MerchanSku":"2669+7107"},
            {"Sku":"BRACELT01-00-47","MerchanSku":"2669+7108"},
            {"Sku":"BRACELT02-00-01","MerchanSku":"2686+7114"},
            {"Sku":"BRACELT02-00-02","MerchanSku":"2686+7115"},
            {"Sku":"BRACELT02-00-08","MerchanSku":"2686+7116"},
            {"Sku":"BRACELT02-00-47","MerchanSku":"2686+7120"},
            {"Sku":"BRACELT02-00-16","MerchanSku":"2686+7117"},
            {"Sku":"BOWTIE01-48-47","MerchanSku":"2668+7065"},
            {"Sku":"CERTIF01-00-01","MerchanSku":"2631+6972"},
            {"Sku":"COAST01-00-01","MerchanSku":"2445+6528"},
            {"Sku":"COAST01-00-02","MerchanSku":"2445+6804"},
            {"Sku":"COAST01-00-08","MerchanSku":"2445+6806"},
            {"Sku":"COAST01-00-06","MerchanSku":"2445+6805"},
            {"Sku":"COAST01-00-10","MerchanSku":"2445+6807"},
            {"Sku":"COAST01-08-02","MerchanSku":"2619+6932"},
            {"Sku":"COAST01-08-47","MerchanSku":"2619+6933"},
            {"Sku":"coast02-00-01","MerchanSku":"2463+6537"},
            {"Sku":"COAST02-00-06","MerchanSku":"2463+6816"},
            {"Sku":"coast02-00-02","MerchanSku":"2463+6538"},
            {"Sku":"COAST02-00-08","MerchanSku":"2463+6817"},
            {"Sku":"COAST02-00-10","MerchanSku":"2463+6818"},
            {"Sku":"CERTIF02-00-01","MerchanSku":"2632+6974"},
            {"Sku":"F7550-18-02","MerchanSku":"2696+7144"},
            {"Sku":"F7550-18-01","MerchanSku":"2696+7143"},
            {"Sku":"F7550-18-08","MerchanSku":"2696+7145"},
            {"Sku":"F7550-49-01","MerchanSku":"2665+7058"},
            {"Sku":"FOB08-18-02","MerchanSku":"2634+6979"},
            {"Sku":"FOB08-18-01","MerchanSku":"2634+6978"},
            {"Sku":"FOB08-18-08","MerchanSku":"2634+6980"},
            {"Sku":"FOB10-18-01","MerchanSku":"2635+7094"},
            {"Sku":"FOB10-18-02","MerchanSku":"2635+6982"},
            {"Sku":"FOB10-18-08","MerchanSku":"2635+7095"},
            {"Sku":"FOB14-18-09","MerchanSku":"2636+6984"},
            {"Sku":"FOB119-48-14","MerchanSku":"2379+6284"},
            {"Sku":"GOLF08-18-01","MerchanSku":"2602+6889"},
            {"Sku":"GOLF14-18-01","MerchanSku":"2604+6893"},
            {"Sku":"GOLF18-18-01","MerchanSku":"2603+6891"},
            {"Sku":"JOURNAL9R7IRX-ECOPAPER-01","MerchanSku":"2697+7147"},
            {"Sku":"JOURNALA19-ECOPAPER-01","MerchanSku":"2685+7111"},
            {"Sku":"K258-18-02","MerchanSku":"2613+6918"},
            {"Sku":"K262-18-01","MerchanSku":"2006+5972"},
            {"Sku":"L8772-18-09","MerchanSku":"2681+7096"},
            {"Sku":"L8772-18-02","MerchanSku":"2681+7093"},
            {"Sku":"L8981-18-08","MerchanSku":"2637+6987"},
            {"Sku":"L8981-18-01","MerchanSku":"2637+6986"},
            {"Sku":"L8981-18-09","MerchanSku":"2637+6988"},
            {"Sku":"L9951-18-08","MerchanSku":"2639+6994"},
            {"Sku":"L9951-18-01","MerchanSku":"2639+6993"},
            {"Sku":"L9951-48-47","MerchanSku":"2640+6996"},
            {"Sku":"P2211-07-76","MerchanSku":"2620+6935"},
            {"Sku":"P2211-48-01","MerchanSku":"2621+7112"},
            {"Sku":"P2211-48-06","MerchanSku":"2621+6937"},
            {"Sku":"P2211-48-08","MerchanSku":"2621+6938"},
            {"Sku":"P2211-48-14","MerchanSku":"2621+6939"},
            {"Sku":"P2211-48-47","MerchanSku":"2621+6940"},
            {"Sku":"P7207AA-08-01","MerchanSku":"2682+7098"},
            {"Sku":"P9003-00-01","MerchanSku":"2689+7126"},
            {"Sku":"P9003-18-01","MerchanSku":"2690+7128"},
            {"Sku":"P7414-18-01","MerchanSku":"2674+7079"},
            {"Sku":"P9180-09-38","MerchanSku":"2594+6867"},
            {"Sku":"P9180-48-14","MerchanSku":"2595+6870"},
            {"Sku":"P9180-48-47","MerchanSku":"2595+6872"},
            {"Sku":"P9180-48-37","MerchanSku":"2595+6871"},
            {"Sku":"P9024-18-01","MerchanSku":"2643+7002"},
            {"Sku":"PDT407-18-01","MerchanSku":"2692+7132"},
            {"Sku":"PHONE55-18-01","MerchanSku":"2647+7014"},
            {"Sku":"PHONE55-18-09","MerchanSku":"2647+7133"},
            {"Sku":"PHONE55-18-08","MerchanSku":"2647+7016"},
            {"Sku":"PHONE55-18-02","MerchanSku":"2647+7015"},
            {"Sku":"P9004-00-01","MerchanSku":"2642+7000"},
            {"Sku":"PENROLLUP06-18-01","MerchanSku":"2646+7012"},
            {"Sku":"PDT37-18-01","MerchanSku":"2691+7130"},
            {"Sku":"POUCH06-18-01","MerchanSku":"2693+7135"},
            {"Sku":"POUCH06-18-08","MerchanSku":"2693+7136"},
            {"Sku":"RCMP7786-18-01","MerchanSku":"2615+6922"},
            {"Sku":"RCMP7786-18-08","MerchanSku":"2615+6941"},
            {"Sku":"RCMPP9004-18-01","MerchanSku":"2622+6943"},
            {"Sku":"RCMP9787-18-01","MerchanSku":"2623+6945"},
            {"Sku":"RFIDT7774-18-01","MerchanSku":"2117+6189"},
            {"Sku":"SPEC11-18-01","MerchanSku":"2474+6567"},
            {"Sku":"SPEC11-18-02","MerchanSku":"2474+6568"},
            {"Sku":"SPEC11-18-08","MerchanSku":"2474+6569"},
            {"Sku":"SPEC11-48-14","MerchanSku":"2617+6926"},
            {"Sku":"SPEC11-48-37","MerchanSku":"2617+6927"},
            {"Sku":"SPEC11-48-47","MerchanSku":"2617+6928"},
            {"Sku":"T7538-00-01","MerchanSku":"2650+7022"},
            {"Sku":"T7540-18-01","MerchanSku":"2651+7024"},
            {"Sku":"T7540-18-02","MerchanSku":"2651+7137"},
            {"Sku":"T7540-18-08","MerchanSku":"2651+7025"},
            {"Sku":"TAG64-00-16","MerchanSku":"2694+7139"},
            {"Sku":"RFID5728-00-01","MerchanSku":"2468+6561"},
            {"Sku":"TAG64-18-01","MerchanSku":"2671+7071"},
            {"Sku":"TAG64-18-08","MerchanSku":"2671+7073"},
            {"Sku":"TAG64-18-02","MerchanSku":"2671+7072"},
            {"Sku":"TAG78-07-76","MerchanSku":"2695+7141"},
            {"Sku":"TAG78-48-01","MerchanSku":"2600+6881"},
            {"Sku":"TAG78-48-14","MerchanSku":"2600+6883"},
            {"Sku":"TAG78-48-08","MerchanSku":"2600+6882"},
            {"Sku":"TAG78-48-47","MerchanSku":"2600+6884"},
            {"Sku":"TAG78-48-06","MerchanSku":"2600+6885"},
            {"Sku":"P9087-07-31","MerchanSku":"2355+6049"},
            {"Sku":"TR1-00-01","MerchanSku":"2672+7075"},
            {"Sku":"RFIDT7774-00-01","MerchanSku":"2648+7018"},
            {"Sku":"WINE02-18-01","MerchanSku":"2679+7089"}
            ];

        const finalRes = list.map(async product=>{
            const Skus = product.Sku.split('-');
            const swatchName = `${Skus[1]}-${Skus[2]}`;
            const imageURL =await getSwatchURL(swatchName);
            //console.log(imageURL);
            if(imageURL !== "Not Found"){
                const valueData = {"image_url": `${imageURL}`};
                const productIDs = product.MerchanSku.split('+');
                const bgVariants = await getVariants(storeInfo,productIDs[0]);
                //console.log(bgVariants);
                const variant = bgVariants.data.data.find(v=>v.sku===product.Sku);
                const optionValue = variant.option_values[0];
                //console.log(optionValue);
                const data = {
                    "label": optionValue.label,
                    "sort_order": "2",
                    "value_data": valueData,
                    "is_default": false
                };
                //console.log(data);
                //storeInfo,product_id,option_id,value_id,data
                const res = await updateVariantOptionValue(storeInfo,productIDs[0],optionValue.option_id,optionValue.id,data);
                //console.log(res);
                return res
            }else{
                return ""
            }
        });

        Promise.all(finalRes).then(()=>{
            alert("Finish Update");
        })
    };
    render() {
        return (
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        <br/>  <br/>
                        <h1 style={{textAlign:'center'}}>Update Swatch Images</h1>
                        <br/>  <br/>
                        <button type={'button'} onClick={this.handleUpdateSwatch} className={'btn btn-danger'}>Update</button>
                    </div>
                </div>
            </div>
        );
    }
};

export default UpdateSwatch;