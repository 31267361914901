import React,{Component} from 'react';
import AddColorModal from './addColorModal';
import UpDateColorModal from './updateColorModal';
import { legacyColoursURL, legacyColoursModelMapper } from '../../../Utils/ashlinDesign'
import axios from 'axios';
import { proxyurl } from '../../../Utils/requestHelper';
class ProductColors extends Component{
    constructor(props){
        super(props);
        this.state={
            asiColors:[],
            ashlinColors:[]
        }
    }
    handleGetColors = async () => {
        const asiResponse = await axios.get(proxyurl+'https://sandbox-productservice.asicentral.com/api/v4/lookup/colors');
        const ashlinResponse = await axios.get(legacyColoursURL);
        const ashlinColours = ashlinResponse.data.map(colour=>legacyColoursModelMapper(colour));
        Promise.all([asiResponse,ashlinResponse]).then(()=>{
            this.setState({
                asiColors:asiResponse.data.colors,
                ashlinColors:ashlinColours
            })
        })
    };
    render(){
        return(
            <div className='container-fluid'>
                <br/>
                <h2 className={'text-secondary'} style={{textAlign:'center'}}>Manager Colors</h2>
                <br/>
                <br/>
                <div className={'row justify-content-center'}>
                    <button className={' btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px",fontSize:'30px'}} data-toggle="modal" data-target="#add_new_color" onClick={this.handleGetColors} >Add Color</button>
                    <AddColorModal id={'add_new_color'} colors={this.state.asiColors}  addItem={this.props.addItem} ashlinColors={this.state.ashlinColors}
                                   addColor={this.props.addColor} onChange={this.props.onChange} translate={this.props.translate}
                                   Activate={this.props.Activate} DeActivate={this.props.DeActivate}/>
                    <div className={'col-1'}> </div>
                    <button className={'btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px", fontSize:'30px'}} data-toggle="modal" data-target="#update_color" onClick={this.handleGetColors}>Update Color</button>
                    <UpDateColorModal id={'update_color'} colors={this.state.asiColors}  updateItem={this.props.updateItem} addKeyword={this.props.addKeyword} deleteKeyword={this.props.deleteKeyword}
                                      updateColor={this.props.updateColor} onChange={(a,b)=>this.props.onChange(a,b,this.state.ashlinColors)} translate={this.props.translate}
                                      Activate={this.props.Activate} DeActivate={this.props.DeActivate} ashlinColors={this.state.ashlinColors}/>
                </div>
            </div>
        )
    }
}

export default ProductColors;