import React from 'react';
const AsiModifyProductModal =({product,handleAsiCategoriesChange,onChange})=>{
    //console.log(product);
    return (
        <div className="modal fade" id="asiModifyProductModal" tabIndex="-1" role="dialog"
             aria-labelledby="asiModifyProductModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title text-center" id="asiModifyProductModalTitle">Product
                            Information Detail</h1>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className="table asiModifyProductTable" style={{margin:'16px 0 0'}}>
                            <tbody>
                            <tr className={'row'}>
                                <th className={'col-4'} style={{textAlign:'center'}}>Product Name</th>
                                <td className={'col-8'}>
                                    <input type="text"  name={'Name'} onChange={onChange} style={{width:'100%'}} value={product.Name}/>
                                </td>
                            </tr>
                            <tr className={'row'}>
                                <th className={'col-1'} style={{textAlign:'center'}}>Product Type</th>
                                <td className={'col-5'}>
                                    {
                                        product.AsiProductTypes.length !==0 &&
                                        <select className="form-control" value={product.ProductType} name={'ProductType'} onChange={onChange}>
                                            {product.AsiProductTypes.map((op,i)=>{
                                                return <option value={op} key={i}>{op}</option>
                                            })}
                                        </select>
                                    }
                                </td>
                                <th className={'col-1'} style={{textAlign:'center'}}>Product Category</th>
                                <td className={'col-5'}>
                                    {
                                        product.AsiCategories.length !==0 &&
                                        <select className="form-control" value={product.Categories[0]} name={'Categories'} onChange={onChange}>
                                            {product.AsiCategories.map((op,i)=>{
                                                return <option value={op} key={i}>{op}</option>
                                            })}
                                        </select>
                                    }
                                </td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                </div>
            </div>
        </div>
    )
};

export default AsiModifyProductModal