import React from 'react';
const TextParallelWithAddition =({className,id,label,value,name,onChange,placeholder})=>{
    const inchValue = parseFloat(value)?(parseFloat(value)*0.393701).toFixed(2) + " inches":'';
    return(
        <div className={`${className} row`}>
            <div className={'col-4'} style={{textAlign:'right'}}>
                <p style={{margin:'auto',fontWeight:'bold'}}>{label}:</p>
            </div>
            <div className={'col-8 row'}>
                <input type="text" style={{width:'37%'}} id={id} value={value}
                       onChange={onChange} name={name} placeholder={placeholder} />
               <span style={{fontWeight:'bold',margin:'0 17px'}}>(inches)</span> <input style={{width:'37%'}}  value={inchValue} readOnly/>
            </div>
        </div>
    )
};
export default TextParallelWithAddition;

