import {baseURL,PUROLATOR_TEST_ENVIRONMENT, proxyurl } from "../requestHelper";
import axios from "axios";
import momentTimeZone from "moment-timezone";

const BASE_URL = PUROLATOR_TEST_ENVIRONMENT ? proxyurl + "https://devwebservices.purolator.com/EWS" : proxyurl +"https://webservices.purolator.com/EWS";

export const getTodayDate = ()=>{
    const d = new Date();
    const myTimezone = "America/Toronto";
    return momentTimeZone(d).tz(myTimezone).format();
};


export const getPurolatorCredentials = async()=>{
    if(PUROLATOR_TEST_ENVIRONMENT){
        return {
            'token':'Zjc2ZDA2OTRhYWFhNGY0MGJlN2E1ZDY2ZjI1YTI5MDU6S3g+Snl0O09JTnBqeCEg',
            'account':'9999999999'

        }
    }  else{
        const res =  (await axios.get(`${baseURL}api/purolator`)).data;
        return {
            token :window.btoa(res.key+':'+res.password),
            account: res.account
        };
    }
};

export const getPurolatorRates = async (data, credentials,isExpress,ShippingMatrices,shippingMethodsFilter)=>{
    const today = getTodayDate();
    const weight = (parseFloat(data.PackageInfo.Weight) * 2.20462).toFixed(2);
    let zip;

    if(data.ShipTo.CountryCode==="US"){
        const codes = data.ShipTo.Zip.split('-');
        zip = codes[0];
    }else{
        zip = data.ShipTo.Zip;
    }
    const headers = {
                        "headers": {
                            "Content-Type": "text/xml;charset=UTF-8",
                            "Authorization": `Basic ${credentials.token}`,
                            "Accept-language": "en-CA",
                            "SOAPACtion": "http://purolator.com/pws/service/v2/GetQuickEstimate"
                    }};
    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v2="http://purolator.com/pws/datatypes/v2">
                   <soapenv:Header>
                      <v2:RequestContext>
                         <v2:Version>2.0</v2:Version>
                         <v2:Language>en</v2:Language>
                         <v2:GroupID></v2:GroupID>
                         <v2:RequestReference></v2:RequestReference>
                      </v2:RequestContext>
                   </soapenv:Header>
                   <soapenv:Body>
                      <v2:GetQuickEstimateRequest>
                         <v2:BillingAccountNumber>${credentials.account}</v2:BillingAccountNumber>
                         <v2:SenderPostalCode>${data.ShipFrom.Zip}</v2:SenderPostalCode>
                         <v2:ReceiverAddress>
                            <v2:City>${data.ShipTo.City}</v2:City>
                            <v2:Province>${data.ShipTo.State}</v2:Province>
                            <v2:Country>${data.ShipTo.CountryCode}</v2:Country>
                            <v2:PostalCode>${zip}</v2:PostalCode>
                         </v2:ReceiverAddress>
                         <v2:PackageType>CustomerPackaging</v2:PackageType>
                         <v2:ShipmentDate>${today}</v2:ShipmentDate>
                         <v2:TotalWeight>
                            <v2:Value>${weight}</v2:Value>
                            <v2:WeightUnit>lb</v2:WeightUnit>
                         </v2:TotalWeight>
                      </v2:GetQuickEstimateRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;

    try{
        const res = (await axios.post(`${BASE_URL}/v2/Estimating/EstimatingService.asmx`,xml,headers)).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
        const shipments = [...document.getElementsByTagName('ShipmentEstimate')];
        //console.log(shipments);
        const result =  shipments.map(q=>{

            const serviceCode = q.getElementsByTagName('ServiceID')[0].textContent;

            const service = ShippingMatrices.find(s=>s.ServiceCode === serviceCode);
            //console.log(service)
            if(service)
                return {
                    serviceCode: serviceCode,
                    serviceName: service.DisplayedName,
                    isExpress: service.IsExpress,
                    totalCost:q.getElementsByTagName('TotalPrice')[0].textContent,
                    deliverByDate:q.getElementsByTagName('ExpectedDeliveryDate')[0].textContent,
                };
            return service;
        });
        //console.log(shippingMethodsFilter);
        if(shippingMethodsFilter.length > 0){
            const conditions = shippingMethodsFilter.filter(s=>s.Carrier==="PUROLATOR").map(s=>s.ServiceCode);
            //console.log(conditions)
            return  result.filter(r=>r && conditions.some(el=> r.serviceCode.includes(el)));

        }
        return result.filter(r=>r && r.isExpress === isExpress);
    }catch (e) {
        return [];
    }

};

export const createPurolatorShipment = async(data,credentials)=>{
    //console.log(data);

    const headers = {
        "headers": {
            "Content-Type": "text/xml",
            "Authorization": `Basic ${credentials.token}`,
            "Accept-language": "en",
            "SOAPACtion": "http://purolator.com/pws/service/v2/CreateShipment"
    }};

    const today = getTodayDate().slice(0,10);
    const senderStreet = data.ShipFrom.AddressLine1.split(' ');
    // const senderStreetNum = senderStreet[0];
    // const senderStreetName =  (senderStreet.slice(1)).join(' ');

    let senderPhone = data.ShipFrom.ContactPhone.replace( /\D+/g, '');
    senderPhone = senderPhone.length === 11? senderPhone.substring(1, 11) : senderPhone;
    // const senderPhoneAreaCode = senderPhone.substring(0,3);
    // const senderPhoneNumber = senderPhone.substring(3,10);


    const receiverStreet = data.ShipTo.AddressLine1.split(' ');
    const receiverStreetNum = receiverStreet[0];
    const receiverStreetName =  (receiverStreet.slice(1)).join(' ');

    let receiverPhone = data.ShipTo.ContactPhone.replace( /\D+/g, '');
    receiverPhone = receiverPhone.length === 11? receiverPhone.substring(1, 11) : receiverPhone;
    const receiverPhoneAreaCode = receiverPhone.substring(0,3);
    const receiverPhoneNumber = receiverPhone.substring(3,10);

    let paymentInformation,InternationalInformation;
    if (data.Use3rdPartyAccount) {
        paymentInformation = `<v2:PaymentInformation>
                                   <v2:PaymentType>ThirdParty</v2:PaymentType>
                                   <v2:RegisteredAccountNumber>${data.ThirdPartyAccountNum}</v2:RegisteredAccountNumber>
                                   <v2:BillingAccountNumber>${data.ThirdPartyAccountNum}</v2:BillingAccountNumber>
                               </v2:PaymentInformation>`;
    }else{
        paymentInformation = `<v2:PaymentInformation>
                                   <v2:PaymentType>Sender</v2:PaymentType>
                                   <v2:RegisteredAccountNumber>${credentials.account}</v2:RegisteredAccountNumber>
                                   <v2:BillingAccountNumber>${credentials.account}</v2:BillingAccountNumber>
                                </v2:PaymentInformation>`;
    }

    const Commodities = data.PackageInfo.ItemDetails.map(item=>{
        return `<v2:ContentDetail>
            <v2:Description>${item.Description}</v2:Description>
            <v2:HarmonizedCode>${item.Hts}</v2:HarmonizedCode>
            <v2:CountryOfManufacture>CA</v2:CountryOfManufacture>
            <v2:ProductCode>${item.Sku}</v2:ProductCode>
            <v2:UnitValue>${item.Amount}</v2:UnitValue>
            <v2:Quantity>${item.Qty}</v2:Quantity>
        </v2:ContentDetail>`
    });

    if(data.ShipTo.CountryCode!=="CA") {
        InternationalInformation = `<v2:InternationalInformation>
                                       <v2:DocumentsOnlyIndicator>false</v2:DocumentsOnlyIndicator>
                                       <v2:ContentDetails>
                                          ${Commodities.join('')}
                                       </v2:ContentDetails>

                                       <v2:BuyerInformation>
                                           <v2:Address>
                                              <v2:Name>${data.ShipTo.ContactName}</v2:Name>
                                              <v2:Company>${data.ShipTo.Name}</v2:Company>
                                              <v2:StreetNumber>${receiverStreetNum}</v2:StreetNumber>
                                              <v2:StreetName>${receiverStreetName}</v2:StreetName>
                                              <v2:StreetAddress2>${data.ShipTo.AddressLine2}</v2:StreetAddress2>
                                              <v2:City>${data.ShipTo.City}</v2:City>
                                              <v2:Province>${data.ShipTo.State}</v2:Province>
                                              <v2:Country>${data.ShipTo.CountryCode}</v2:Country>
                                              <v2:PostalCode>${data.ShipTo.Zip}</v2:PostalCode>
                                              <v2:PhoneNumber>
                                                 <v2:CountryCode>1</v2:CountryCode>
                                                 <v2:AreaCode>${receiverPhoneAreaCode}</v2:AreaCode>
                                                 <v2:Phone>${receiverPhoneNumber}</v2:Phone>
                                              </v2:PhoneNumber>
                                           </v2:Address> 
                                       </v2:BuyerInformation>
                                       <v2:DutyInformation>
                                          <v2:BillDutiesToParty>Receiver</v2:BillDutiesToParty>
                                          <v2:BusinessRelationship>NotRelated</v2:BusinessRelationship>
                                          <v2:Currency>USD</v2:Currency>
                                       </v2:DutyInformation>
                                       <v2:ImportExportType>Temporary</v2:ImportExportType>
                                    </v2:InternationalInformation>`;

    }else{
        InternationalInformation = '';
    }

    let insurance;
    const numberOfBox = parseInt(data.NumberOfBox);

    if(data.DeclareValueInsurance) {
        let value = parseInt(data.PackageInfo.TotalValue);
        value = value > 500? 500 : value;
        insurance = `<v2:OptionsInformation>
                       <v2:Options>
                          <v2:OptionIDValuePair>
                             <v2:ID>DeclaredValue</v2:ID>
                             <v2:Value>${ (value/numberOfBox).toFixed(0) }</v2:Value>
                          </v2:OptionIDValuePair>
                       </v2:Options>
                    </v2:OptionsInformation>`;
    }else{
        insurance = '';
    }

    const weight = (data.PackageInfo.Weight/ numberOfBox ).toFixed(2);
    let packages = [];

    for (let i = 1; i <= numberOfBox; i++) {
        const p =  `<v2:PackageInformation>
                               <v2:ServiceID>${data.Service}</v2:ServiceID>
                               <v2:Description></v2:Description>
                               <v2:TotalWeight>
                                  <v2:Value>${weight}</v2:Value>
                                  <v2:WeightUnit>kg</v2:WeightUnit>
                               </v2:TotalWeight>
                               <v2:TotalPieces>1</v2:TotalPieces>
                               <v2:DangerousGoodsDeclarationDocumentIndicator>false</v2:DangerousGoodsDeclarationDocumentIndicator>
                               ${insurance}
                            </v2:PackageInformation>`;
        packages.push(p);
    }

    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v2="http://purolator.com/pws/datatypes/v2">
                   <soapenv:Header>
                      <v2:RequestContext>
                         <v2:Version>2.0</v2:Version>
                         <v2:Language>en</v2:Language>
                         <v2:GroupID></v2:GroupID>
                         <v2:RequestReference></v2:RequestReference>
                      </v2:RequestContext>
                   </soapenv:Header>
                   <soapenv:Body>
                      <v2:CreateShipmentRequest>
                         <v2:Shipment>
                            <v2:SenderInformation>
                               <v2:Address>
                                  <v2:Name>Nancy Taccone</v2:Name>
                                  <v2:Company>Ashlin-BPG Marketing</v2:Company>
                                  <v2:StreetNumber>2351</v2:StreetNumber>
                                  <v2:StreetName>Royal Windsor Dr</v2:StreetName>
                                  <v2:StreetAddress2>Unit #10</v2:StreetAddress2>
                                  <v2:City>Mississauga</v2:City>
                                  <v2:Province>ON</v2:Province>
                                  <v2:Country>CA</v2:Country>
                                  <v2:PostalCode>L5J4S7</v2:PostalCode>
                                  <v2:PhoneNumber>
                                     <v2:CountryCode>1</v2:CountryCode>
                                     <v2:AreaCode>905</v2:AreaCode>
                                     <v2:Phone>8553027</v2:Phone>
                                  </v2:PhoneNumber>
                               </v2:Address>
                            </v2:SenderInformation>
                            <v2:ReceiverInformation>
                                <v2:Address>
                                  <v2:Name>${data.ShipTo.ContactName}</v2:Name>
                                  <v2:Company>${data.ShipTo.Name}</v2:Company>
                                  <v2:StreetNumber>${receiverStreetNum}</v2:StreetNumber>
                                  <v2:StreetName>${receiverStreetName}</v2:StreetName>
                                  <v2:StreetAddress2>${data.ShipTo.AddressLine2}</v2:StreetAddress2>
                                  <v2:City>${data.ShipTo.City}</v2:City>
                                  <v2:Province>${data.ShipTo.State}</v2:Province>
                                  <v2:Country>${data.ShipTo.CountryCode}</v2:Country>
                                  <v2:PostalCode>${data.ShipTo.Zip}</v2:PostalCode>
                                  <v2:PhoneNumber>
                                     <v2:CountryCode>1</v2:CountryCode>
                                     <v2:AreaCode>${receiverPhoneAreaCode}</v2:AreaCode>
                                     <v2:Phone>${receiverPhoneNumber}</v2:Phone>
                                  </v2:PhoneNumber>
                               </v2:Address>
                            </v2:ReceiverInformation>
                            <v2:ShipmentDate>${today}</v2:ShipmentDate>
                            
                            ${packages.join('\n')}
                            
                            
                           ${InternationalInformation}
                           ${paymentInformation}
                            
                            <v2:PickupInformation>
                               <v2:PickupType>PreScheduled</v2:PickupType>
                            </v2:PickupInformation>
                
                            <v2:TrackingReferenceInformation>
                               <v2:Reference1>${data.Reference1!==''?data.Reference1 : data.Order.id.toString()}</v2:Reference1>
                               <v2:Reference2>${data.Reference2!==''?data.Reference2 : `Number of Box: ${data.NumberOfBox}`}</v2:Reference2>
                            </v2:TrackingReferenceInformation>
                         </v2:Shipment>
                         <v2:PrinterType>Regular</v2:PrinterType>
                      </v2:CreateShipmentRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;

    //console.log(xml);
    try{
        const res = (await axios.post(`${BASE_URL}/v2/Shipping/ShippingService.asmx`,xml,headers)).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
        const ShipmentPIN = document.getElementsByTagName('ShipmentPIN')[0].textContent;
        //console.log(ShipmentPIN);
        if(ShipmentPIN){
            const URL = await getDocument(credentials,ShipmentPIN);
            if(URL === "LABEL FAILED"){
                return undefined;
            }else{
                const PickUpConfirmationNumber = await schedulePickUp(data,credentials);
                //console.log(PickUpConfirmationNumber)
                return {
                    "tracking": ShipmentPIN,
                    "labels" : URL,
                    "service" : data.Service,
                    "pickupId": PickUpConfirmationNumber
                };
            }
        }
    }catch (e) {
        return undefined;
    }

};

const getDocument = async(credentials,PIN)=>{
    const headers = {
        "headers": {
            "Content-Type": "text/xml",
            "Authorization": `Basic ${credentials.token}`,
            "Accept-language": "en",
            "SOAPACtion": "http://purolator.com/pws/service/v1/GetDocuments"
        }};

    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v1="http://purolator.com/pws/datatypes/v1">
                   <soapenv:Header>
                      <v1:RequestContext>
                         <v1:Version>1.3</v1:Version>
                         <v1:Language>en</v1:Language>
                         <v1:GroupID></v1:GroupID>
                         <v1:RequestReference></v1:RequestReference>
                      </v1:RequestContext>
                   </soapenv:Header>
                   <soapenv:Body>
                      <v1:GetDocumentsRequest>
                         <!--Optional:-->
                         <v1:OutputType>PDF</v1:OutputType>
                         <!--Optional:-->
                         <v1:Synchronous>false</v1:Synchronous>
                         <v1:DocumentCriterium>
                            <!--Zero or more repetitions:-->
                            <v1:DocumentCriteria>
                               <v1:PIN>
                                  <v1:Value>${PIN}</v1:Value>
                               </v1:PIN>
                            </v1:DocumentCriteria>
                         </v1:DocumentCriterium>
                      </v1:GetDocumentsRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;

    try {
        const res = (await axios.post(`${BASE_URL}/v1/ShippingDocuments/ShippingDocumentsService.asmx`,xml,headers)).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
       
        let labels = [];
        const labelsDOM = document.getElementsByTagName('URL');
        for(let i = 0; i < labelsDOM.length; i++){
            labels.push(labelsDOM[i].textContent)
        }

        if(labels.length > 0){
            return labels.join(' | ');
        }
        return "LABEL FAILED";
    }catch (e) {
        return "LABEL FAILED";
    }
};

export const voidPurolatorShipment = async (credentials,PIN)=>{
    const headers = {
        "headers": {
            "Content-Type": "text/xml",
            "Authorization": `Basic ${credentials.token}`,
            "Accept-language": "en",
            "SOAPACtion": "http://purolator.com/pws/service/v2/VoidShipment"
    }};
    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v2="http://purolator.com/pws/datatypes/v2">
                   <soapenv:Header>
                      <v2:RequestContext>
                         <v2:Version>2.0</v2:Version>
                         <v2:Language>en</v2:Language>
                         <v2:GroupID></v2:GroupID>
                         <v2:RequestReference></v2:RequestReference>
                      </v2:RequestContext>
                   </soapenv:Header>
                   <soapenv:Body>
                      <v2:VoidShipmentRequest>
                         <v2:PIN>
                            <v2:Value>${PIN}</v2:Value>
                         </v2:PIN>
                      </v2:VoidShipmentRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;
    try {
        const res = (await axios.post(`${BASE_URL}/v2/Shipping/ShippingService.asmx`,xml,headers)).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
        const ShipmentVoided = document.getElementsByTagName('ShipmentVoided')[0].textContent;
        return ShipmentVoided === "true" ? "SUCCESS" : document.getElementsByTagName('Description')[0].textContent;
    }catch (e) {

    }
};

const schedulePickUp = async(data,credentials)=>{


    const headers = {
        "headers": {
            "Content-Type": "text/xml",
            "Authorization": `Basic ${credentials.token}`,
            "Accept-language": "en",
            "SOAPACtion": "http://purolator.com/pws/service/v1/SchedulePickUp"
        }};

    const today = getTodayDate().slice(0,10);
    const senderStreet = data.ShipFrom.AddressLine1.split(' ');
    const senderStreetNum = senderStreet[0];
    const senderStreetName =  (senderStreet.slice(1)).join(' ');

    let senderPhone = data.ShipFrom.ContactPhone.replace( /\D+/g, '');
    senderPhone = senderPhone.length === 11? senderPhone.substring(1, 11) : senderPhone;
    const senderPhoneAreaCode = senderPhone.substring(0,3);
    const senderPhoneNumber = senderPhone.substring(3,10);
    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v1="http://purolator.com/pws/datatypes/v1">
                   <soapenv:Header>
                      <v1:RequestContext>
                         <v1:Version>1.2</v1:Version>
                         <v1:Language>en</v1:Language>
                         <v1:GroupID/>
                         <v1:RequestReference/>
                      </v1:RequestContext>
                   </soapenv:Header>
                   <soapenv:Body>
                      <v1:SchedulePickUpRequest>
                         <v1:BillingAccountNumber>${credentials.account}</v1:BillingAccountNumber>
                         <v1:PickupInstruction>
                            <v1:Date>${today}</v1:Date>
                            <v1:AnyTimeAfter>1530</v1:AnyTimeAfter>
                            <v1:UntilTime>1630</v1:UntilTime>
                            <v1:PickUpLocation>Shipping</v1:PickUpLocation>
                         </v1:PickupInstruction>
                         
                         <v1:Address>
                            <v1:Name>${data.ShipFrom.ContactName}</v1:Name>
                            <v1:Company>${data.ShipFrom.Name}</v1:Company>
                            <v1:StreetNumber>${senderStreetNum}</v1:StreetNumber>
                            <v1:StreetName>${senderStreetName}</v1:StreetName>
                            <v1:StreetAddress2>${data.ShipFrom.AddressLine2}</v1:StreetAddress2>
                            <v1:City>${data.ShipFrom.City}</v1:City>
                            <v1:Province>${data.ShipFrom.State}</v1:Province>
                            <v1:Country>${data.ShipFrom.CountryCode}</v1:Country>
                            <v1:PostalCode>${data.ShipFrom.Zip}</v1:PostalCode>
                            <v1:PhoneNumber>
                            <v1:CountryCode>${data.ShipTo.Zip}</v1:CountryCode>
                               <v1:AreaCode>${senderPhoneAreaCode}</v1:AreaCode>
                               <v1:Phone>${senderPhoneNumber}</v1:Phone>
                            </v1:PhoneNumber>
                         </v1:Address>
                         
                         <v1:NotificationEmails>
                            <v1:NotificationEmail>intern1002@ashlinbpg.com</v1:NotificationEmail>
                         </v1:NotificationEmails>
                      </v1:SchedulePickUpRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;


    try {
        const res = (await axios.post(`${BASE_URL}/V1/PickUp/PickUpService.asmx`,xml,headers)).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
        const PickUpConfirmationNumber = document.getElementsByTagName('PickUpConfirmationNumber')[0].textContent;
        if(PickUpConfirmationNumber){
            return PickUpConfirmationNumber;
        }
        return "SCHEDULE FAILED";
    }catch (e) {
        return "SCHEDULE FAILED";
    }
};

export const voidPickUp = async (credentials,PickUpConfirmationNumber)=>{

    const headers = {
        "headers": {
            "Content-Type": "text/xml",
            "Authorization": `Basic ${credentials.token}`,
            "Accept-language": "en",
            "SOAPACtion": "http://purolator.com/pws/service/v1/VoidPickUp"
        }};

    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v1="http://purolator.com/pws/datatypes/v1">
                   <soapenv:Header>
                      <v1:RequestContext>
                         <v1:Version>1.2</v1:Version>
                         <v1:Language>en</v1:Language>
                         <v1:GroupID/>
                         <v1:RequestReference/>
                      </v1:RequestContext>
                   </soapenv:Header>
                   <soapenv:Body>
                      <v1:VoidPickUpRequest>
                         <v1:PickUpConfirmationNumber>${PickUpConfirmationNumber}</v1:PickUpConfirmationNumber>
                      </v1:VoidPickUpRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;

    try {
        const res = (await axios.post(`${BASE_URL}/V1/PickUp/PickUpService.asmx`,xml,headers)).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
        const PickUpVoided = document.getElementsByTagName('PickUpVoided')[0].textContent;
        return PickUpVoided === "true" ? "SUCCESS" : document.getElementsByTagName('Description')[0].textContent;
    }catch (e) {

    }
};
