import React from 'react';
import AshlinInvoice from "./AshlinInvoice";
const PendingOrderDetailModal = (props)=>{
    let orderRows;
    if(props.orderRows !== undefined){
        orderRows= props.orderRows;
    }
    return(
        <div className="modal fade" id="PendingOrderDetailModal" tabIndex="-1" role="dialog"
             aria-labelledby="PendingOrderDetailModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="PendingOrderDetailModalTitle">Bright Pearl Pending Order Detail</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <AshlinInvoice
                            {...props}
                        />
                        <table className="table table-bordered pendingOrderTable">
                            <thead>
                            <tr>
                                <th scope="col"> </th>
                                <th scope="col">Company Address</th>
                                <th scope="col">Invoice address</th>
                                <th scope="col">Delivery address</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Name</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.addressFullName!==undefined)?props.parties.customer.addressFullName:""}</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.addressFullName !==undefined)?props.parties.billing.addressFullName :""}</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.addressFullName !==undefined)?props.parties.delivery.addressFullName:""}</td>
                            </tr>
                            <tr>
                                <th scope="row">Company</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.companyName !==undefined)?props.parties.customer.companyName:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.companyName !==undefined)?props.parties.billing.companyName:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.companyName !==undefined)?props.parties.delivery.companyName:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Street</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.addressLine1 !==undefined)?props.parties.customer.addressLine1:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.addressLine1 !==undefined)?props.parties.billing.addressLine1:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.addressLine1 !==undefined)?props.parties.delivery.addressLine1:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Suburb</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.addressLine2 !==undefined)?props.parties.customer.addressLine2:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.addressLine2 !==undefined)?props.parties.billing.addressLine2:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.addressLine2 !==undefined)?props.parties.delivery.addressLine2:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">City</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.addressLine3 !==undefined)?props.parties.customer.addressLine3:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.addressLine3 !==undefined)?props.parties.billing.addressLine3:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.addressLine3 !==undefined)?props.parties.delivery.addressLine3:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">State/county</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.addressLine4 !==undefined)?props.parties.customer.addressLine4:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.addressLine4 !==undefined)?props.parties.billing.addressLine4:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.addressLine4 !==undefined)?props.parties.delivery.addressLine4:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Postcode</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.postalCode !==undefined)?props.parties.customer.postalCode:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.postalCode !==undefined)?props.parties.billing.postalCode:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.postalCode !==undefined)?props.parties.delivery.postalCode:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Country</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.country !==undefined)?props.parties.customer.country:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.country !==undefined)?props.parties.billing.country:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.country !==undefined)?props.parties.delivery.country:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Telephone</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.telephone !==undefined)?props.parties.customer.telephone:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.telephone !==undefined)?props.parties.billing.telephone:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.telephone !==undefined)?props.parties.delivery.telephone:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Mobile</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.mobileTelephone !==undefined)?props.parties.customer.mobileTelephone:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.mobileTelephone !==undefined)?props.parties.billing.mobileTelephone:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.mobileTelephone !==undefined)?props.parties.delivery.mobileTelephone:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Email address</th>
                                <td>{(props.parties!==undefined&&props.parties.customer.email !==undefined)?props.parties.customer.email:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.billing.email !==undefined)?props.parties.billing.email:"" }</td>
                                <td>{(props.parties!==undefined&&props.parties.delivery.email !==undefined)?props.parties.delivery.email:"" }</td>
                            </tr>
                            </tbody>
                        </table>
                        <span style={{fontWeight:'bold'}}>Ware House</span> &nbsp;
                        <select name={'WarehouseCode'}  onChange={props.onChange}  value={props.WarehouseCode}>
                            <option value="14">Lean Supply</option>
                            {/*<option value="10">10</option>*/}
                        </select> &nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{fontWeight:'bold'}}>Priority</span> &nbsp;
                        <select name={'Priority'}  onChange={props.onChange}  value={props.Priority}>
                            <option value="">Normal</option>
                            <option value="RUSH">RUSH</option>
                        </select> &nbsp;

                        {
                            props.useCustomizedCarrier &&
                            <button className={'btn-danger'} onClick={(e)=>props.changeCarrier(e,false)}>Cancel Customize Carrier</button>
                        }
                        {
                            !props.useCustomizedCarrier &&
                            <span>&nbsp;&nbsp;&nbsp;Default Carrier - <span style={{fontWeight:'bold'}}>
                                {/*{props.parties!==undefined&&props.parties.delivery.countryIsoCode==="US"?"Ashlin FEDEX":"Ashlin Canpar"}*/}
                                {/*{props.parties!==undefined&&props.parties.delivery.countryIsoCode!=="CA"&&props.parties.delivery.addressLine1.toUpperCase().includes('BOX')?"CHITCHATS":  props.ServiceCode.label}*/}
                                {props.ServiceCode.label}
                                </span> &nbsp;
                                <button className={'btn-danger'} onClick={(e)=>props.changeCarrier(e,true)}>Change Carrier</button>
                            </span>
                        }
                        {
                            (props.parties!==undefined&&props.parties.delivery.countryIsoCode==="US")&&
                                <button
                                data-toggle="modal" onClick={props.handleGenerateInvoiceBtn}
                                type={'button'}
                                data-backdrop="static" data-keyboard="false"
                                className={'btn col btn-success'} style={{fontSize: '25px', lineHeight: '38px',margin:'10px 0'}}>GENERATE PROFORMA INVOICE</button>
                                // <button
                                //     data-toggle="modal" data-target="#ProformaInvoiceModal"
                                //     type={'button'}
                                //     data-backdrop="static" data-keyboard="false"
                                //     className={'btn-success'} style={{fontSize: '25px', lineHeight: '38px',marginLeft:'50px'}}>GENERATE PROFORMA INVOICE</button>
                        }
                        <br/>
                        {props.useCustomizedCarrier &&
                            <div>
                                <hr/>
                                <span style={{fontWeight:'bold'}}>Shipment Type</span>&nbsp; <select name={'ServiceType'}  onChange={props.shipmentTypeOnchange}  value={props.ServiceType}>
                                <option value="BESTPRICE">Cheapest</option>
                                <option value="BESTETA">Fastest</option>
                                <option value="CUSTOMERPICKUP">Customer Pickup</option>
                                <option value="CUSTOMIZECARRIER">Customize Carrier</option>
                            </select> &nbsp;
                            </div>
                        }
                        {
                            props.needChooseCarrier &&
                            <div>
                                <hr/>
                                <span style={{fontWeight:'bold'}}>Carrier</span> &nbsp;
                                <select name={'CarrierCode'}  onChange={props.carrierCodeOnchange} value={props.CarrierCode}>
                                    <option value="CANPAR">CANPAR</option>
                                    <option value="CANP">CANADA POST</option>
                                    <option value="UPS">UPS</option>
                                    <option value="FEDEX">FEDEX</option>
                                    <option value="PUROLATOR">PUROLATOR</option>
                                    <option value="CHITCHATS">CHITCHATS</option>
                                </select> &nbsp;
                                <select name={'ServiceCodeGroup'}  onChange={props.carrierServiceOnchange} >
                                    {
                                        props.ServiceCodeGroup.map((sc,i)=>{
                                            return <option value={JSON.stringify(sc)} key={i}>{sc.label}</option>
                                        })
                                    }
                                </select> &nbsp;
                                <hr/>
                                <span style={{fontWeight:'bold'}}>Use 3rd Party Account</span>&nbsp;
                                <input type="checkbox"  name={'use3rdAccount'} onChange={props.onChange}
                                       value={props.use3rdAccount} checked={props.use3rdAccount}   /> &nbsp;&nbsp;&nbsp;
                                {
                                    props.use3rdAccount&&
                                        <span>
                                            <span style={{fontWeight:'bold'}}> &nbsp;Account#&nbsp;
                                                <input type="text"  name={'ChargeToAccount'} onChange={props.onChange}
                                                       value={props.ChargeToAccount}/>
                                            </span>&nbsp;&nbsp;
                                            <span style={{fontWeight:'bold'}}> &nbsp;Registered Address&nbsp;
                                                <input type="text"  name={'registeredAddress'} onChange={props.onChange} style={{width:'40%'}}
                                                       value={props.registeredAddress}/>
                                            </span>
                                        </span>
                                }
                            </div>
                        }
                        {/*<br/>*/}
                        {/*{*/}
                            {/*( props.parties!==undefined && props.parties.delivery.countryIsoCode==="US" )&&*/}
                                {/*<div>*/}
                                    {/*<span style={{fontWeight:'bold'}}>Send Proforma Invoice Email To: &nbsp;</span>*/}
                                    {/*<input type="text"  name={'EmailList'} onChange={props.onChange} style={{width:'65%'}} value={props.EmailList}/>&nbsp;&nbsp; separate by ";"*/}
                                {/*</div>*/}
                        {/*}*/}
                        {/*<br/>*/}
                        <div>
                             <span style={{fontWeight:'bold', fontSize:'30px',color:'red'}}>Is this a Cross Dock Order: &nbsp;</span>
                            <input type="checkbox"  style={{width:'30px',height:'30px'}} name={'isCrossDock'} onChange={props.isCrossDockOnchange} checked={props.isCrossDock}/>&nbsp;&nbsp;
                            {   props.isCrossDock &&
                                <span>
                                    <span style={{fontWeight:'bold', fontSize:'30px',color:'red'}}>&nbsp;&nbsp;&nbsp;Total Box Number:</span>
                                    <input type={'text'} style={{fontWeight:'bold', fontSize:'30px',color:'red'}} name={'crossDockBoxTotal'} value={props.crossDockBoxTotal} onChange={props.onChange}/>
                                </span>
                            }

                        </div>
                        <hr/>
                        {/*ORDER LINE START*/}
                        {
                            orderRows!==undefined&&
                                <table className="table table-bordered pendingOrderTable">
                                    <thead>
                                    <tr>
                                        <th scope="col">Line#</th>
                                        <th scope="col">SKU</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">HTS Code</th>
                                        <th scope="col">HTS Duty</th>
                                        <th scope="col">Line Total</th>
                                        <th scope="col">Delete Row</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            orderRows.map((ol,index)=>{
                                               return <tr key={index}>
                                                    <th scope="row">{index+1}</th>
                                                    {/*<td>{ol.productSku}</td>*/}
                                                    <td><input type={'text'} onChange={e=>props.replaceSKU(e,index)} value={ol.productSku}/></td>
                                                    <td>{ol.productName}</td>
                                                    <td>{parseInt(ol.quantity.magnitude)}</td>
                                                    <td><select name={'htsCode'}  defaultValue={props.parties.delivery.countryIsoCode==="US"?ol.HtsUs:ol.HtsCa}
                                                                onChange={e=>props.htsCodeOnchange(e,index)}
                                                    >
                                                        {
                                                            props.parties.delivery.countryIsoCode==="US"? props.HtsUs.map((h,i)=>{
                                                            return <option value={h.HtsUs1} key={i}> {h.HtsUs1}</option>
                                                            }):props.HtsCa.map((h,i)=>{
                                                                return <option value={h.HtsCa1} key={i}> {h.HtsCa1}</option>
                                                            })
                                                        }
                                                    </select></td>
                                                    <td>{props.parties.delivery.countryIsoCode==="US"?ol.UsDuty:ol.CaDuty}</td>
                                                    <td>{parseFloat(ol.rowValue.rowNet.value).toFixed(2)}</td>
                                                    <td><button onClick={e=>props.deleteRow(e,index)} className={'btn-danger'}>DELETE</button></td>
                                              </tr>
                                            })
                                        }
                                        {/*{*/}
                                            {/*orderRows.length === 0 &&*/}
                                                {/*<tr>*/}
                                                    {/*<th colSpan={'1'} style={{verticalAlign:'middle'}} className={'text-center'}>*/}
                                                        {/*<span style={{fontSize:'30px'}}>Total Box Number:</span>*/}
                                                        {/*<input type={'text'} name={'crossDockBoxTotal'} value={props.crossDockBoxTotal} onChange={props.onChange}/>*/}
                                                    {/*</th>*/}
                                                    {/*<td colSpan={'7'}>*/}
                                                        {/*<button className={'btn btn-danger'}  onClick={props.addCrossDock}*/}
                                                                {/*style={{width:'100%'}}>Add Cross Dock Box Order Line</button>*/}
                                                    {/*</td>*/}
                                                {/*</tr>*/}
                                        {/*}*/}
                                    </tbody>
                                </table>
                        }
                        <span style={{fontWeight:'bold'}}>Comment &nbsp;</span>
                        <textarea rows={'5'}  name={'Comment'} onChange={props.onChange} style={{width:'65%'}} value={props.Comment}/>&nbsp;&nbsp;
                        {
                            ( props.parties!==undefined && props.parties.delivery.countryIsoCode==="US" )&&
                                <span>
                                    <span style={{fontWeight:'bold'}}>FEIN #&nbsp;</span>
                                    <input type="text"  name={'FEIN'} onChange={props.onChange} style={{width:'20%'}} value={props.FEIN}/>
                                    <br/>
                                </span>
                        }


                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        {/*{!props.canFulfill && <button className={'btn btn-block btn-success'} onClick={(e)=>props.checkFulfill(e,orderRows)}>Check FulfillMent</button>}*/}
                        {/*{props.canFulfill && <button className={'btn btn-block btn-danger'} onClick={props.sendOrder}>Send Order to Lean Supply</button>}*/}

                        <button className={'btn col btn-success'} onClick={(e)=>props.checkFulfill(e,orderRows)}>Check FulfillMent</button>
                        {
                            ( props.parties!==undefined && (props.parties.delivery.countryIsoCode==="CA" || props.attachBase64!==undefined) ) &&
                            <button className={'btn col btn-danger'} onClick={props.sendOrder}>Send Order to Lean Supply</button>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
    //data-dismiss="modal"
};
export default PendingOrderDetailModal;