import React, {Component} from 'react';
import {getOrderDetailsByOrderID,channelsMapping} from './../../../Utils/brightpearl';
import {createAshlinManualOrder} from "../../../Utils/ashlinDesign";
import $ from "jquery";

class PullOrderFromBrightpearl extends Component{

    constructor(props){
        super(props);
        this.state={
            searchCondition:'',
            brightPearlOrder:undefined,
        }
    }
    handleStateOnchange = (e)=>{
        const name = e.target.name;
        const value =e.target.value;
        this.setState({
            [name]:value
        });
    };
    handleSearchBrightpearlOrder=async()=>{
        //search orderID exist or not
        const ashlinManualOrderList = this.props.ashlinManualOrderList;
        const searchID = this.state.searchCondition;
        const existOrder = ashlinManualOrderList.find(o=>o.BrightpearlOrderId === searchID);
        if(existOrder){
            if(window.confirm(`The #${searchID} already exist / processed. Do you confirm to re-download?`)){
                const brightPearlOrder = await getOrderDetailsByOrderID(searchID, this.props.brightpearlHeader);
                if(brightPearlOrder===undefined){
                    alert(`Can't find order ID: ${this.state.brightPearlOrder} on Brightpearl!`);
                }
                this.setState({
                    brightPearlOrder
                })
            }
        }else{
            const brightPearlOrder = await getOrderDetailsByOrderID(searchID, this.props.brightpearlHeader);
            if(brightPearlOrder===undefined){
                alert(`Can't find order ID: ${this.state.brightPearlOrder} on Brightpearl!`);
            }
            this.setState({
                brightPearlOrder
            })
        }
    };
    handlePullOrder=async()=>{
        // console.log(this.state.brightPearlOrder)
        // console.log(this.props.BrightpearlChannelInfos)
        // console.log(this.props.brightPearlWarehouses);
        const channelInfo = this.props.BrightpearlChannelInfos.find(c=>c.ChannelId === this.state.brightPearlOrder.assignment.current.channelId);
        const warehouseInfo = this.props.brightPearlWarehouses.find(w=>w.id === this.state.brightPearlOrder.warehouseId);
        const ashlinManualOrder =  {
            "SaleChannelId": channelInfo  ? channelInfo.AshlinChannelId.toUpperCase()  : "" ,
            "SaleChannelOrderId": this.state.brightPearlOrder.reference,
            "BrightpearlOrderId": this.state.brightPearlOrder.id,
            "Warehouse":warehouseInfo.name,
            "ShippingCarrier": "",
            "ShippingService": "",
            "ShippingLabelId": "",
            "TrackingNumber": "",
            "Status": "OrderCreated"
        };
        const newAshlinManualOrder = await createAshlinManualOrder(ashlinManualOrder);
        this.props.handleInsertNewLine(newAshlinManualOrder);

        this.setState({
            searchCondition:'',
            brightPearlOrder:undefined,
        },()=>{
            alert("Finished Pull order!");
            $('#pullOrderFromBrightpearlModal').modal('hide');
        })
    };
    render(){
        const order = this.state.brightPearlOrder;
        let orderRows;
        if(order!==undefined && order.orderRows !== undefined){
            orderRows= Object.values(order.orderRows);
        }
        return(
            <div className="modal fade" id="pullOrderFromBrightpearlModal" tabIndex="-1" role="dialog" data-backdrop="static"
                 aria-labelledby="pullOrderFromBrightpearlModalTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title text-center" id="pullOrderFromBrightpearlModalTitle">Search Brightpearl Order</h2>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">

                            <table className="table table-bordered">
                                <thead>
                                {
                                    order !== undefined &&
                                    <tr>
                                        <th colSpan={'3'}> </th>
                                        <th ><button type={'button'} onClick={this.handlePullOrder} className={'btn btn-success btn-block'}>Pull Order</button></th>
                                    </tr>
                                }
                                {
                                    order === undefined &&
                                    <tr>
                                        <th>Enter Brightpearl Order ID</th>
                                        <th colSpan={'2'}><input type={'text'} onChange={this.handleStateOnchange} name={'searchCondition'} value={this.state.searchCondition}/></th>
                                        <th ><button type={'button'} onClick={this.handleSearchBrightpearlOrder} className={'btn btn-success'}>Search Order</button></th>
                                    </tr>
                                }

                                {order !== undefined &&
                                <tr>
                                    <th scope="col"> </th>
                                    <th scope="col">Company Address</th>
                                    <th scope="col">Invoice address</th>
                                    <th scope="col">Delivery address</th>
                                </tr>}
                                </thead>
                                {order !== undefined &&
                                <tbody>
                                <tr>
                                    <th scope="row">Name</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.addressFullName!==undefined)?order.parties.customer.addressFullName:""}</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.addressFullName !==undefined)?order.parties.billing.addressFullName :""}</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.addressFullName !==undefined)?order.parties.delivery.addressFullName:""}</td>
                                </tr>
                                <tr>
                                    <th scope="row">Company</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.companyName !==undefined)?order.parties.customer.companyName:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.companyName !==undefined)?order.parties.billing.companyName:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.companyName !==undefined)?order.parties.delivery.companyName:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Street</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.addressLine1 !==undefined)?order.parties.customer.addressLine1:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.addressLine1 !==undefined)?order.parties.billing.addressLine1:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.addressLine1 !==undefined)?order.parties.delivery.addressLine1:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Suburb</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.addressLine2 !==undefined)?order.parties.customer.addressLine2:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.addressLine2 !==undefined)?order.parties.billing.addressLine2:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.addressLine2 !==undefined)?order.parties.delivery.addressLine2:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">City</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.addressLine3 !==undefined)?order.parties.customer.addressLine3:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.addressLine3 !==undefined)?order.parties.billing.addressLine3:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.addressLine3 !==undefined)?order.parties.delivery.addressLine3:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">State/county</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.addressLine4 !==undefined)?order.parties.customer.addressLine4:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.addressLine4 !==undefined)?order.parties.billing.addressLine4:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.addressLine4 !==undefined)?order.parties.delivery.addressLine4:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Postcode</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.postalCode !==undefined)?order.parties.customer.postalCode:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.postalCode !==undefined)?order.parties.billing.postalCode:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.postalCode !==undefined)?order.parties.delivery.postalCode:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Country</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.country !==undefined)?order.parties.customer.country:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.country !==undefined)?order.parties.billing.country:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.country !==undefined)?order.parties.delivery.country:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Telephone</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.telephone !==undefined)?order.parties.customer.telephone:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.telephone !==undefined)?order.parties.billing.telephone:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.telephone !==undefined)?order.parties.delivery.telephone:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mobile</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.mobileTelephone !==undefined)?order.parties.customer.mobileTelephone:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.mobileTelephone !==undefined)?order.parties.billing.mobileTelephone:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.mobileTelephone !==undefined)?order.parties.delivery.mobileTelephone:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Email address</th>
                                    <td>{(order.parties!==undefined&&order.parties.customer.email !==undefined)?order.parties.customer.email:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.billing.email !==undefined)?order.parties.billing.email:"" }</td>
                                    <td>{(order.parties!==undefined&&order.parties.delivery.email !==undefined)?order.parties.delivery.email:"" }</td>
                                </tr>
                                </tbody>
                                }
                            </table>

                            {
                                orderRows!==undefined&&
                                <table className="table table-bordered ">
                                    <thead>
                                    <tr>
                                        <th scope="col">Line#</th>
                                        <th scope="col">SKU</th>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Qty</th>
                                        <th scope="col">Line Total</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        orderRows.map((ol,index)=>{
                                            return <tr key={index}>
                                                <th scope="row">{index+1}</th>
                                                <td>{ol.productSku}</td>
                                                <td>{ol.productName}</td>
                                                <td>{parseInt(ol.quantity.magnitude)}</td>
                                                <td>{parseFloat(ol.rowValue.rowNet.value).toFixed(2)}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
export default PullOrderFromBrightpearl;