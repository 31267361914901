import React from 'react';
import { BrowserRouter, Route, HashRouter} from 'react-router-dom';

import Loading from './Loading';
import Navbar from './Navbar';
import Admin from './Admin/Admin';

import Insights from './Admin/Insights';
import SubmitSKU from './LeanSupply/SubmitSKU';
import NewOrder from './LeanSupply/NewOrder';
import SubmittedOrder from './LeanSupply/SubmittedOrder';
import ManualOrder from './LeanSupply/ManualOrder';
import LeanSupplyInventory from './LeanSupply/LeanSupplyInventory';
import InventoryTransfer from './LeanSupply/InventoryTransfer';
import bpPendingOrder from './BrightPearl/PendingOrder';
import bpDispatchedOrder from './BrightPearl/DispatchedOrder';
import bpCompletedOrder from './BrightPearl/CompletedOrder';

import CreateNewSKU from './Ashlin/CreateNewSKU';
import DesignManager from './Ashlin/ProductConcepts';
import AshlinExport from './Ashlin/AshlinExport';
import Proforma from './ProformaInvoice/manualProformaInvoice';
import PrintUPCLabel from './Ashlin/PrintUPCLabel'

import BCBulkPrice from './BigCommerce/BulkPrice';
import ManageImage from './BigCommerce/ManageImage';
import SyncImage from './BigCommerce/SyncImage';
import BulkUpdateDescription from './BigCommerce/BulkUpdateDescription';
import UpdateSwatch from './BigCommerce/UpdateSwatch';
import BCDetailPrice from './BigCommerce/DetailPrice';
import BCDescription from './BigCommerce/BCDescription';
import BCProduct from './BigCommerce/BCProduct';
import BCOptionSet from './BigCommerce/BCOptionSet';

import BulkSkuUpload from './MarketPlace/BulkSkuUpload';
import BulkRetireProduct from './MarketPlace/BulKRetireProduct';
import BulkUpdateInventory from './MarketPlace/BulkUpdateInventory';
import BulkModifyProduct from './MarketPlace/BulkModifyProduct';
import BulkInventoryManager from './MarketPlace/BulkInventoryManager';
import BulkDiscount from    './MarketPlace/BulkDiscount';

import BigCommerce from './BigCommerce/BigCommerce';
import requireAuth from  './require_auth';
import ASI from './ASI/Asi';
import ASIUpdate from './MarketPlace/ModifyProduct/ASI_Update';
import ACE from './ACE/ACE';

import Amazon from './Amazon/Amazon';
import AmazonUpdateFBALocation from './Amazon/FBALocation/updateFBALocation';

import AshlinManualOrders from './AshlinManualOrder/ManualOrders';
import BrightPearlProduct from "./BrightPearl/BrightPearlProduct";

import PriceManager from './PriceManager/PriceManager';
import MarketplacePriceManager from './PriceManager/MarketplacePriceManager';

import WalmartApi from '../Utils/test/WalmartApi';
import GoogleDrive from '../Utils/test/googleDrive';
import BestBuy from '../Utils/test/BestbuyApi';
import BigCommerceApi from '../Utils/test/BigCommerceApi';
import BrightPearlTest from '../Utils/test/BpTest';
import ASITest from '../Utils/test/AsiTest';
import LoomisTest from '../Utils/test/loomisTest';
import AmazonTest from '../Utils/test/AmazonTest';

import UniShip from './UniShip/UniShip';
const App = () => {
    return(
        <div>
            <BrowserRouter >
                <div>
                    <Navbar />
                    <Route path="/" exact component={Loading} />
                    <Route path="/insights" exact  component={requireAuth(Insights)} />
                    <Route path="/admin" exact  component={requireAuth(Admin)} />

                    <Route path="/ashlinManualOrders" exact  component={requireAuth(AshlinManualOrders)} />

                    <Route path="/proforma" exact  component={requireAuth(Proforma)} />

                    <Route path="/orders" exact component={requireAuth(NewOrder)}/>
                    <Route path="/submittedOrders" exact component={requireAuth(SubmittedOrder)}/>
                    <Route path="/SKUs" exact component={requireAuth(SubmitSKU)}/>
                    {/*<Route path="/manualOrder" component={requireAuth(ManualOrder)}/>*/}
                    <Route path="/leanSupplyInventory" exact component={requireAuth(LeanSupplyInventory)}/>
                    <Route path="/inventoryTransfer" exact component={requireAuth(InventoryTransfer)}/>
                    {/*<Route path="/asi" exact component={requireAuth(ASI)}/>*/}

                    {/*<Route path="/bpPendingOrder" component={requireAuth(bpPendingOrder)}/>*/}
                    {/*<Route path="/bpDispatchedOrder" component={requireAuth(bpDispatchedOrder)}/>*/}
                    {/*<Route path="/bpCompletedOrder" component={requireAuth(bpCompletedOrder)}/>*/}

                    <Route path="/createNewSKU" exact component={requireAuth(CreateNewSKU)}/>
                    <Route path="/designManager" exact component={requireAuth(DesignManager)}/>
                    <Route path="/export" exact component={requireAuth(AshlinExport)}/>
                    <Route path="/printUPC" exact component={requireAuth(PrintUPCLabel)}/>


                    <Route path="/bigCommerce" exact component={requireAuth(BigCommerce)}/>
                    <Route path={"/bigCommerce/manageImage"} exact component={requireAuth(ManageImage)}/>
                    <Route path={"/bigCommerce/syncImage"} exact component={requireAuth(SyncImage)}/>
                    <Route path={"/bigCommerce/bulkUpdateDescription"} exact component={requireAuth(BulkUpdateDescription)}/>
                    <Route path={"/bigCommerce/updateSwatch"} exact component={requireAuth(UpdateSwatch)}/>
                    {/*<Route path={"/bigCommerce/detailPrice"} exact component={requireAuth(BCDetailPrice)}/>*/}
                    {/*<Route path={"/bigCommerce/description"} exact component={requireAuth(BCDescription)}/>*/}
                    {/*<Route path={"/bigCommerce/product"} exact component={requireAuth(BCProduct)}/>*/}
                    {/*<Route path={"/bigCommerce/optionSet"} exact component={requireAuth(BCOptionSet)}/>*/}

                    <Route path={"/bulkSkuUpload"} exact component={requireAuth(BulkSkuUpload)}/>
                    <Route path={"/bulkSkuRetire"} exact component={requireAuth(BulkRetireProduct)}/>
                    {/*<Route path={"/bulkInventoryUpdate"} exact component={requireAuth(BulkUpdateInventory)}/>*/}
                    <Route path={"/bulkModifyProduct"} exact component={requireAuth(BulkModifyProduct)}/>
                    <Route path={"/bulkInventoryManager"} exact component={requireAuth(BulkInventoryManager)}/>
                    <Route path={"/bulkDiscount"} exact component={requireAuth(BulkDiscount)}/>
                    <Route path={"/asiUpdate"} exact component={requireAuth(ASIUpdate)}/>

                    {/*<Route path={"/ace"} exact component={requireAuth(ACE)}/>*/}

                    <Route path={"/amazon"} exact component={requireAuth(Amazon)}/>
                    <Route path={"/amazon/FBA"} exact component={requireAuth(AmazonUpdateFBALocation)}/>
                    <Route path={"/brightpearl"} exact component={requireAuth(BrightPearlProduct)}/>



                    <Route path={"/uniShip"} exact component={requireAuth(UniShip)}/>
                    <Route path={"/priceManager"} exact component={requireAuth(PriceManager)}/>
                    <Route path={"/marketplacePriceManager"} exact component={requireAuth(MarketplacePriceManager)}/>

                    {/*<Route path={"/walmart"} exact component={WalmartApi}/>*/}
                    {/*<Route path={"/amazonTest"} exact component={AmazonTest}/>*/}
                    {/*<Route path={"/google"} exact component={GoogleDrive}/>*/}
                    {/*<Route path={"/bestbuy"} exact component={BestBuy}/>*/}
                    {/*<Route path={"/bgtest"} exact component={BigCommerceApi}/>*/}
                    {/*<Route path={"/bptest"} exact component={BrightPearlTest}/>*/}
                    {/*<Route path={"/asi"} exact component={ASITest}/>*/}
                    {/*<Route path={"/loomis"} exact component={LoomisTest}/>*/}
                </div>
            </BrowserRouter>
        </div>
    )
};

export default App;