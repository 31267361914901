import React, {Component} from 'react';
import $ from "jquery";
import {createEmailConfiguration} from '../../../Utils/ashlinDesign';
import { Editor } from '@tinymce/tinymce-react';
import { proxyurl } from '../../../Utils/requestHelper';
class NewEmailConfiguration extends Component{
    constructor(props){
        super(props);
        this.state={
            Description:'',
            Recipient:'',
            Subject:'',
            EmailBody:''
        }
    }
    newEmailConfigurationOnChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleClose=()=>{
        this.setState({
            Description:'',
            Recipient:'',
            Subject:'',
            EmailBody:''
        },()=>{
            $('#newEmailConfigurationDetail').modal('hide');
        })
    };
    handleCreateNewEmailConfiguration=async()=>{
        const d = new Date();
        const n = d.toISOString().slice(0,19);
        const data = this.state;
        data.LastModifiedDate = n;
        const resData = await createEmailConfiguration(data);
        this.props.addNewEmailConfigurationToList(resData);
        this.setState({
            Description:'',
            Recipient:'',
            Subject:'',
            EmailBody:''
        },()=>{
            $('#newEmailConfigurationDetail').modal('hide');
        })
    };
    emailConfigurationEditorOnchange = (e)=>{
        const EmailBody  = e.target.getContent();
        this.setState({
            EmailBody
        });
    };
    render(){
        return(<div className="modal fade" id="newEmailConfigurationDetail" tabIndex="-1" role="dialog" data-focus="false"
                    aria-labelledby="newEmailConfigurationDetail" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                    <div className="modal-header">
                        <h3 className="modal-title text-center" id="newEmailConfigurationDetailTitle" style={{fontWeight:'bold'}}>New Email Configuration Detail</h3>
                        <button type="button" className="close"
                                onClick={this.handleClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <table className={'table table-bordered emailConfigurationDetail'}>
                            <thead>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Description</th>
                                <td><input type={'text'} onChange={this.newEmailConfigurationOnChange}
                                           style={{width:'100%'}}  name={'Description'} value={this.state.Description} /></td>
                            </tr>
                            <tr>
                                <th>Recipients</th>
                                <td><textarea rows='6' onChange={this.newEmailConfigurationOnChange}
                                           style={{width:'100%'}}  name={'Recipient'} value={this.state.Recipient} /></td>
                            </tr>
                            <tr>
                                <th>Subject</th>
                                <td><input type={'text'} onChange={this.newEmailConfigurationOnChange}
                                           style={{width:'100%'}}  name={'Subject'} value={this.state.Subject} /></td>
                            </tr>
                            <tr>
                                <th>EmailBody</th>
                                <td>
                                    {/*<textarea rows='12' onChange={this.newEmailConfigurationOnChange}*/}
                                           {/*style={{width:'100%'}}  name={'EmailBody'} value={this.state.EmailBody} />*/}
                                    <Editor
                                        apiKey='6gnhsbvuquvldb5m01twgkp1sfil84udbzfct2o3btx9o9pm'
                                        initialValue= {this.state.EmailBody}
                                        init={{
                                            height : 300,
                                            menubar: 'edit view insert format table tools',
                                            theme: 'modern',
                                            plugins: [
                                                'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                                                'searchreplace wordcount visualblocks visualchars code fullscreen',
                                                'insertdatetime media nonbreaking save table contextmenu directionality',
                                                'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc'
                                            ],
                                            toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                                            toolbar2: 'print preview media | forecolor backcolor emoticons | codesample',
                                            image_advtab: true,
                                            content_css: [
                                                '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                                                proxyurl+ '/www.tinymce.com/css/codepen.min.css'
                                            ]
                                        }}
                                        onChange={this.emailConfigurationEditorOnchange}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                        <div className={'col'}> </div>
                        <div className={'col'}>
                            <button className={'btn btn-danger'} onClick={this.handleCreateNewEmailConfiguration} >Create Email Configuration</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}
export default NewEmailConfiguration;