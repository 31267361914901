import React, {Component} from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {getAllSKUNumberList, getUPCLabelInfo,exportAveryUPC } from "../../Utils/ashlinDesign";

class PrintUPCLabel extends Component {
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            skuNumberList:[],
            currentStatusIndex: 0,
            status:['NEW','SELECT_DESIGN', 'SELECT_CURRENCY', 'ADD_SKU', 'GENERATING'],
            currency:[
                {name:"USD", rate: 0.85, select: false},
                {name:"CAD", rate: 1, select: false},
                {name:"AUD", rate: 1.0364, select: false},
                {name:"EUR", rate: 0.7, select: false},
                {name:"GBP", rate: 0.65, select: false},
                {name:"INR", rate: 50.12, select: false}
            ],
            designTemplate:[
                {name:"5160NoPrice",description:"AVERY 5160 format, 30 per page ( 3 across x 10 down )"},
                {name:"5160WithPrice",description:"AVERY 5160 format, 30 per page ( 3 across x 10 down )"},
                // {name:"5167NoPrice",description:"AVERY 5167 format, 80 per page ( 4 across x 20 down )"},
                // {name:"5167WithPrice",description:"AVERY 5167 format, 80 per page ( 4 across x 20 down )"},
                {name:"5161NoPrice",description:"AVERY 5161 format, 20 per page ( 2 across x 10 down )"},
                {name:"5161WithPrice",description:"AVERY 5161 format, 20 per page ( 2 across x 10 down )"},
                ],
            filteredTemplate:[],
            selectedDesign:undefined,
            selectCurrency:false,
            sku:'',
            qty:0,
            printList:[]
        }
    }
    componentDidMount(){
        this.getData();
    }
    getData=async()=>{
        const skuNumberList = await getAllSKUNumberList();
        this.setState({
            skuNumberList
    })
    };
    componentWillUnmount() {
        this._mounted = false;
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value =e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]:value
        });
    };
    handleSelectDesign=(design)=>{
        const containPrice = design.name.includes('With');
        let currentStatusIndex;
        if(containPrice){
            currentStatusIndex = 2;
        }else{
            currentStatusIndex = 3;
        }
        this.setState({
            selectedDesign:design,
            currentStatusIndex
        })
    };
    handleGoBackTo=(index)=>{
        switch (index) {
            case 0:
                this.setState({
                    currentStatusIndex:index,
                    selectedDesign:undefined,
                    filteredTemplate:[]
                });
                break;
            case 1:
                this.setState({
                    currentStatusIndex:index,
                    selectCurrency:false,
                    currency:[
                        {name:"USD", rate: 0.85, select: false},
                        {name:"CAD", rate: 1, select: false},
                        {name:"AUD", rate: 1.0364, select: false},
                        {name:"EUR", rate: 0.7, select: false},
                        {name:"GBP", rate: 0.65, select: false},
                        {name:"INR", rate: 50.12, select: false}
                    ],
                    printList:[]
                });
                break;
            default:
                this.setState({
                    currentStatusIndex: index
                });
                break;
        }
    };
    handleNext=()=>{
        this.setState({
            selectCurrency:true,
            currentStatusIndex: 3
        },()=>console.log(this.state))
    };
    handleAddLabelInfo=async()=>{
        const sku = this.state.sku.toUpperCase();
        const qty = parseInt(this.state.qty);
        if(sku==='' || qty===0){
            alert('Need SKU or Qty!')
        }else{
            const upcLabelInfo = await getUPCLabelInfo(sku);
            if(upcLabelInfo === undefined){
                alert("Can't find SKU information, please re-enter")
            }else{
                upcLabelInfo.SKU = sku;
                // const selectCurrency = this.state.selectCurrency;
                // if(selectCurrency!==undefined){
                //     upcLabelInfo.Currency = `MSRP - ${selectCurrency.name}`;
                //     upcLabelInfo.BasePrice = (upcLabelInfo.BasePrice * selectCurrency.rate).toFixed(2);
                // }
                upcLabelInfo.Currency = 'MSRP - ';
                const tempPrice = upcLabelInfo.BasePrice;
                upcLabelInfo.BasePrice = "";
                const currency = this.state.currency;
                if(currency[0].select){
                    upcLabelInfo.BasePrice += `${currency[0].name}:${(tempPrice  * currency[0].rate).toFixed(2)} | `;
                }
                if(currency[1].select){
                    upcLabelInfo.BasePrice += `${currency[1].name}:${(tempPrice  * currency[1].rate).toFixed(2)} | `;
                }
                if(currency[2].select){
                    upcLabelInfo.BasePrice += `${currency[2].name}:${(tempPrice  * currency[2].rate).toFixed(2)} | `;
                }
                if(currency[3].select){
                    upcLabelInfo.BasePrice += `${currency[3].name}:${(tempPrice  * currency[3].rate).toFixed(2)} | `;
                }
                if(currency[4].select){
                    upcLabelInfo.BasePrice += `${currency[4].name}:${(tempPrice  * currency[4].rate).toFixed(2)} | `;
                }
                if(currency[5].select){
                    upcLabelInfo.BasePrice += `${currency[5].name}:${(tempPrice  * currency[5].rate).toFixed(2)} | `;
                }
                //console.log(upcLabelInfo);
                const list= [];
                for(let i=0 ; i<qty; i++){
                    list.push(upcLabelInfo);
                }

                const printList = this.state.printList;
                printList.push(list);
                this.setState({
                    printList,
                    sku:'',
                    qty:0
                })
            }
        }
    };
    handleDeleteLabelInfo=(index)=>{
        const  printList = this.state.printList;
        printList.splice(index,1);
        this.setState({
            printList
        });
    };
    handleCreateLabel=async ()=>{
        const  printList = this.state.printList;
        const finalList = printList.flat();
        if(this.state.selectCurrency){
            await exportAveryUPC(finalList,"YES");
        }else{
            await exportAveryUPC(finalList,"NO");
        }
        const averyDesignFileName = this.state.selectedDesign.name;
        // const url = `https://services.print.avery.com/dpp/public/v3/dpo/merge/?averyFileName=http%3A%2F%2Fashlinapp.xyz%2FAveryTemplates%2F${averyDesignFileName}.avery&mergeDataUrl=http%3A%2F%2Fashlinapp.xyz%2FAveryTemplates%2FInputData.xlsx&deploymentId=US_en&consumer=Ashlin`;
        const url = `https://services.print.avery.com/dpp/public/v3/dpo/merge/direct/?averyBundleUrl=https://ashlinapp.xyz/AveryTemplates/${averyDesignFileName}.avery&mergeDataUrl=https://ashlinapp.xyz/AveryTemplates/InputData.xlsx&deploymentId=US_en&consumer=Ashlin`;
        window.open(url, '_blank');
    };
    handleStartNew=()=>{
        this.setState({
            currentStatusIndex: 0,
            selectedDesign:undefined,
            selectCurrency:false,
            currency:[
                {name:"USD", rate: 0.85, select: false},
                {name:"CAD", rate: 1, select: false},
                {name:"AUD", rate: 1.0364, select: false},
                {name:"EUR", rate: 0.7, select: false},
                {name:"GBP", rate: 0.65, select: false},
                {name:"INR", rate: 50.12, select: false}
            ],
            sku:'',
            qty:0,
            printList:[],
            filteredTemplate:[]
        })
    };
    handlePickPrice=(containPrice)=>{
        let filteredTemplate;
        if(containPrice){
            filteredTemplate = this.state.designTemplate.filter(d=>d.name.includes("With"))
        }else{
            filteredTemplate = this.state.designTemplate.filter(d=>d.name.includes("No"))
        }
        this.setState({
            filteredTemplate,
            currentStatusIndex: 1,
        })
    };
    handleSelectCurrency=(e,index)=>{
        const value = e.target.checked;
        const currency = this.state.currency;
        currency[index].select = value;
        this.setState({
            currency
        })
    };
    handleRenderView=()=>{
        const currentStatusIndex = this.state.currentStatusIndex;
        const status = this.state.status[currentStatusIndex];
        switch (status) {
            case 'SELECT_CURRENCY':
                return <div>
                    <h2>Please choose currency</h2>
                    <br/><br/>
                    <div className={'row'}>
                        <div className={'col-4'}> </div>
                        <div className={'col-4'}> </div>
                        <div className={'col-4'}>
                            <button className={'btn btn-danger'} onClick={()=>this.handleGoBackTo(1)}>GO BACK</button>
                            <button className={'btn btn-success'} onClick={this.handleNext}>NEXT</button>
                        </div>
                    </div>
                    <div className={'row'}>
                        {this.state.currency.map((c,index)=>{
                            return <div className={'col-4 '} key={index} style={{padding:'20px 10px'}}>
                                <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}}>
                                    <h3><input type={'checkbox'} style={{width:'30px',height:'30px',marginRight:'10px'}} checked={c.select} onChange={(e)=>this.handleSelectCurrency(e,index)}/>{c.name}</h3>
                                </div>
                            </div>
                        })}
                    </div>
                </div>;
            case 'ADD_SKU':
                return <div>
                    <h2>Please add SKU and Qty</h2>
                    <br/><br/>
                    <div className={'row'}>
                        <div className={'col-8'}>
                            <table className="table  table-bordered">
                                <tbody>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Qty</th>
                                        <th> </th>
                                    </tr>
                                    <tr>
                                        <th style={{padding:'0', height:'20px',textAlign:'center'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', textAlign:'center'}} value={this.state.sku} onChange={this.handleOnchange} name={'sku'}/></th>
                                        <th style={{padding:'0', height:'20px',textAlign:'center'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box', textAlign:'center'}} value={this.state.qty} onChange={this.handleOnchange} name={'qty'}/></th>
                                        <th><button className={'btn-success'} onClick={this.handleAddLabelInfo}>ADD</button></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={'col-4'}>
                            <button className={'btn btn-danger'} onClick={()=>this.handleGoBackTo(1)}>GO BACK</button>
                        </div>
                    </div>
                    <br/><br/>
                    {this.state.printList.length> 0 &&
                        <div className={'row'}>
                            <div className={'col-4'}>
                                <button className={'btn btn-primary'} onClick={this.handleStartNew}>START NEW</button>
                            </div>
                            <div className={'col-4'}> </div>
                            <div className={'col-4'}>
                                <button className={'btn btn-success'} onClick={this.handleCreateLabel}>CREATE LABEL</button>
                            </div>
                        </div>
                    }
                    <br/>
                    {this.state.printList.length> 0 &&
                        <table className="table  table-bordered">
                            <tbody>
                            <tr>
                                <th>SKU</th>
                                <th>Qty</th>
                                <th> </th>
                            </tr>
                                {this.state.printList.map((list,index)=>{
                                    return  <tr key={index}>
                                                <th>{list[0].SKU}</th>
                                                <th>{list.length}</th>
                                                <th><button className={'btn-danger'} onClick={()=>this.handleDeleteLabelInfo(index)}>DELETE</button></th>
                                            </tr>
                                })}

                            </tbody>
                        </table>
                    }
                </div>;
            case 'SELECT_DESIGN':
                    return <div >
                        <h2>Please choose a design/template</h2>
                        <br/><br/>
                        <div className={'row'}>
                            <div className={'col-4'}> </div>
                            <div className={'col-4'}> </div>
                            <div className={'col-4'}>
                                <button className={'btn btn-danger'} onClick={()=>this.handleGoBackTo(0)}>GO BACK</button>
                            </div>
                        </div>
                        <div className={'row'}>
                            {this.state.filteredTemplate.map((d,index)=>{
                                return <div className={'col-4 '} key={index} style={{padding:'20px 10px'}}>
                                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.handleSelectDesign(d)}>
                                        <h3>{d.name}</h3>
                                        <p>{d.description}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>;
            default:
                //NEW
                return <div >
                          <h2>Do you want the label showing MSRP price?</h2>
                          <br/><br/>
                          <div className={'row'}>

                              <div className={'col-6'} style={{padding:'20px 10px'}}>
                                        <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.handlePickPrice(true)}>
                                            <h3>YES</h3>
                                        </div>
                              </div>

                              <div className={'col-6'} style={{padding:'20px 10px'}}>
                                  <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.handlePickPrice(false)} >
                                      <h3>NO</h3>
                                  </div>
                              </div>

                          </div>
                       </div>
        }
    };
    render(){
        //console.log(this.state);
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col-9'} style={{textAlign:'center'}}>
                    <br/>
                    <br/>
                    <h1 >Print UPC Label</h1>
                    <hr/>
                    <hr/>
                    <br/>
                    {this.handleRenderView()}
                </div>
            </div>
        )}
}
export default PrintUPCLabel;