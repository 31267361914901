import React, { Component } from 'react';
import {  CheckCircleTwoTone  } from '@ant-design/icons';

import $ from "jquery";

// const  NewEggModifyProductModal = ({skuInfo, itemListOnNewEgg, cleanTheForm})=>{
    class NewEggModifyProductModal extends Component{
    // const isAdded = modifiedList.findIndex(i=>i.shop_sku === item.shop_sku);
    constructor(props){
        super(props)
        this.state ={
            skuNum : this.props.skuInfo.Sku,
            currentActivation : this.props.itemListOnNewEgg.currentItemInventory.data.Active,
            currentInventory : this.props.itemListOnNewEgg.currentItemInventory.data.AvailableQuantity,
            currentPrice : this.props.itemListOnNewEgg.currentItemPrice.data.SellingPrice
        }

        this.handleChangeActivation = this.handleChangeActivation.bind(this);
        this.handleChangePrice = this.handleChangePrice.bind(this);
        this.handleChangeInventory = this.handleChangeInventory.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleChangePrice(event) {
        this.setState({
            currentPrice: event.target.value
        })
    }

    handleChangeInventory(event) {
        this.setState({
            currentInventory: event.target.value
        })
    }

    handleChangeActivation(event) {
        this.setState({
            currentActivation: event.target.value
        })
    }

    handleSubmit() {
        const formData = {
            "SellingPrice": this.state.currentPrice,
            "Active": this.state.currentActivation,
            "Inventory": this.state.currentInventory
        }
        this.props.updateItemFieldFromNewEgg(this.state.skuNum,formData)
        this.props.handleRestSkuDetail()
        $('#NewEggModifyProductModal').modal('hide');
    }

    
    render(){
        return(
        <div className="modal fade" id="NewEggModifyProductModal" tabIndex="-1" role="dialog"
             aria-labelledby="BestBuyDiscountTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="NewEggModifyProductTitle">NewEgg Product Information Detail</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.handleRestSkuDetail}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>                    
                    <div className="modal-body">
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>SKU: </span>{this.state.skuNum === undefined ? "" : this.state.skuNum}
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>CurrentActivation: </span>
                                {this.state.currentActivation === "1" ?<CheckCircleTwoTone  twoToneColor="#52c41a" />: 
                                  <CheckCircleTwoTone  twoToneColor="#ff0505" />
                                }

                                <select value={this.state.currentActivation} onChange={this.handleChangeActivation}>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>CurrentPrice: </span>
                                <input type="text" value={this.state.currentPrice} onChange={this.handleChangePrice} />

                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>CurrentInventory: </span>
                                <input type="text" value={this.state.currentInventory} onChange={this.handleChangeInventory} />
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={this.handleSubmit}>Update to NewEgg</button>
                    </div>
                </div>
            </div>

        </div>
    )
    }
};
  

  

export default NewEggModifyProductModal;