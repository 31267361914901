import React, {Component} from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {getOrdersByStatusId,shipMethod} from '../../Utils/brightpearl';
import ReactTable from 'react-table';
import {getTrackingNumber} from "../../Utils/requestHelper";
class CompletedOrder extends Component{
    constructor(props){
        super(props);
        this.state={
            completedOrders:[]
        }
    }
    componentDidMount(){
        this.getCompletedOrder();
    }
    getCompletedOrder=async()=>{
        const auth = JSON.parse(localStorage.getItem('auth'));
        const orders =await getOrdersByStatusId(33);
        const completedOrders = await orders.map(async order=>{
            const shipment = shipMethod.find(s=>s.value===order.delivery.shippingMethodId);
            if(shipment === undefined) {
                order.ShipmentMethod = "Unknown";
            }else {
                order.ShipmentMethod = shipment.label;
            }
            const data = {
                "Authorization":auth,
                "OrderHeader":{
                    "OrderHandle":`Ashlin-${order.id}`
                }
            };
            order.TrackingNumber = await getTrackingNumber(data);

        });
        Promise.all(completedOrders).then(()=>{
            this.setState({
                completedOrders:orders
            })
        });

    };

    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <br/>
                    <h1 className={"text-center"}> Completed Orders</h1>
                    <br/>
                    <div style={{ textAlign: "center" }}>
                        <ReactTable
                            data={this.state.completedOrders}
                            columns={
                                [
                                    {
                                        Header: "Order ID",
                                        accessor: "id",
                                    },
                                    {
                                        Header: "Buyer Name",
                                        accessor: o => o.parties.customer.addressFullName,
                                        id: "buyerName"
                                    },
                                    {
                                        Header: "Shipped Date",
                                        accessor: o => o.delivery.deliveryDate.slice(0,19).replace("T"," "),
                                        id: "shippedOn"
                                    },
                                    {
                                        Header: "Payment Status",
                                        accessor: "orderPaymentStatus"
                                    },
                                    {
                                        Header:"Shipment Method",
                                        accessor:"ShipmentMethod"
                                    },
                                    {
                                        Header:"Tracking #",
                                        accessor:"TrackingNumber"
                                    }
                                ]
                            }
                            defaultPageSize={10}
                            className="-striped -highlight orderTable"
                        />
                    </div>
                </div>
            </div>
        )
    }
}
export default CompletedOrder;