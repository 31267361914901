import React,{Component} from 'react';
import AddDesignModal from './addDesignsModal';
import UpdateDesignModal from './updateDesignsModal';
import {
    legacySubFamiliesURL,
    legacySubFamiliesModelMapper,
    legacyDesignsModelMapper,legacyDesignsURL
} from "../../../Utils/ashlinDesign";
import axios from 'axios';
class ProductDesigns extends Component{
    constructor(props){
        super(props);
        this.state={
            subFamilies:[],
            ashlinDesigns:[]
        }
    };
    handleGetDesign = async()=>{

        const ashlinSubFamiliesResponse = await axios.get(legacySubFamiliesURL);
        const ashlinSubFamilies = ashlinSubFamiliesResponse.data.map(subFamily=>legacySubFamiliesModelMapper(subFamily));
        const ashlinDesignsResponse = await axios.get(legacyDesignsURL);
        const ashlinDesigns = ashlinDesignsResponse.data.map(design=>legacyDesignsModelMapper(design));
        //console.log(ashlinDesigns);
        Promise.all([ashlinSubFamiliesResponse,ashlinDesignsResponse]).then(()=>{
            this.setState({
                subFamilies:ashlinSubFamilies,
                ashlinDesigns:ashlinDesigns
            });
        })
    };
    render(){

        return(
            <div className='container-fluid'>
                <br/>
                <h2 className={'text-secondary'} style={{textAlign:'center'}}>Manager Family</h2>
                <br/>
                <br/>
                <div className={'row justify-content-center'}>
                    <button className={' btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px",fontSize:'30px'}} data-toggle="modal" data-target="#add_new_design" onClick={this.handleGetDesign} >Add Design</button>
                    <AddDesignModal id={'add_new_design'}
                        subFamilies = {this.state.subFamilies}  ashlinDesign={this.state.ashlinDesigns} addDesign = {this.props.addDesign}
                        onChange={this.props.onChange} addItem = {this.props.addItem}
                        Activate={this.props.Activate} DeActivate={this.props.DeActivate}
                        translate={this.props.translate} shipmentCalculation={this.props.shipmentCalculation}
                                    translateWithField={this.props.translateWithField}
                                    generateSEOSection={this.props.generateSEOSection}

                    />
                    <div className={'col-1'}> </div>
                    <button className={'btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px", fontSize:'30px'}} data-toggle="modal" data-target="#update_design" onClick={this.handleGetDesign}>Update Design</button>
                    <UpdateDesignModal id={'update_design'}
                           subFamilies = {this.state.subFamilies} updateDesign = {this.props.updateDesign}
                           onChange={(a,b)=>this.props.onChange(a,b,this.state.ashlinDesigns)} updateItem = {this.props.updateItem}
                           Activate={this.props.Activate} DeActivate={this.props.DeActivate} ashlinDesign={this.state.ashlinDesigns}
                           translate={this.props.translate} shipmentCalculation={this.props.shipmentCalculation}
                                       translateWithField={this.props.translateWithField}
                                       generateSEOSection={this.props.generateSEOSection} onModalClose={this.props.onUpdateModalClose}
                    />
                </div>
            </div>
        )
    }
}
export default ProductDesigns;