import React from 'react';
import { Editor } from '@tinymce/tinymce-react';
import $ from "jquery";
import { proxyurl } from '../../../Utils/requestHelper';

const email_configuration_list_item_detail =({item,index,emailConfigurationOnChange, updateEmailConfiguration,testSendEmail,emailConfigurationEditorOnchange})=>{
    return(<div className="modal fade" id="emailConfigurationListItemDetail" tabIndex="-1" role="dialog" data-focus="false"
                aria-labelledby="emailConfigurationListItemDetail" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                <div className="modal-header">
                    <h3 className="modal-title text-center" id="emailConfigurationListItemDetailTitle" style={{fontWeight:'bold'}}>Email Configuration Detail</h3>
                    <button type="button" className="close"
                            onClick={()=>{$('#emailConfigurationListItemDetail').modal('hide')}}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <table className={'table table-bordered emailConfigurationListItemDetail'}>
                        <thead>
                        </thead>
                        <tbody>
                        <tr>
                            <th>Description</th>
                            <td><input type={'text'} onChange={(e)=>emailConfigurationOnChange(e,item)}
                                       style={{width:'100%'}}  name={'Description'} value={item.Description} /></td>
                        </tr>
                        <tr>
                            <th>Recipients</th>
                            <td><textarea rows='6' onChange={(e)=>emailConfigurationOnChange(e,item)}
                                       style={{width:'100%'}}  name={'Recipient'} value={item.Recipient} /></td>
                        </tr>
                        <tr>
                            <th>Subject</th>
                            <td><input type={'text'} onChange={(e)=>emailConfigurationOnChange(e,item)}
                                       style={{width:'100%'}}  name={'Subject'} value={item.Subject} /></td>
                        </tr>
                        <tr>
                            <th>EmailBody</th>
                            <td>
                                {/*<textarea rows='12' onChange={(e)=>emailConfigurationOnChange(e,item)}*/}
                                       {/*style={{width:'100%'}}  name={'EmailBody'} value={item.EmailBody} />*/}
                                {/*{console.log(item.EmailBody)}*/}
                                <Editor
                                    apiKey='6gnhsbvuquvldb5m01twgkp1sfil84udbzfct2o3btx9o9pm'
                                    // key={Math.random()}
                                    value={item.EmailBody}
                                    init={{
                                        height: 300,
                                        menubar: 'edit view insert format table tools',
                                        theme: 'modern',
                                        plugins: [
                                            'advlist autolink lists link image charmap print preview hr anchor pagebreak',
                                            'searchreplace wordcount visualblocks visualchars code fullscreen',
                                            'insertdatetime media nonbreaking save table contextmenu directionality',
                                            'emoticons template paste textcolor colorpicker textpattern imagetools codesample toc'
                                        ],
                                        toolbar1: 'undo redo | insert | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image',
                                        toolbar2: 'print preview media | forecolor backcolor emoticons | codesample',
                                        image_advtab: true,
                                        content_css: [
                                            '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
                                            proxyurl+ '/www.tinymce.com/css/codepen.min.css'
                                        ]
                                    }}
                                    onChange={(e) => emailConfigurationEditorOnchange(e, index)}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                    <div className={'col'}>
                        <button className={'btn btn-danger'} onClick={()=>testSendEmail(item)} >Test Email</button>
                    </div>
                    <div className={'col'}>
                        <button className={'btn btn-danger'} onClick={()=>updateEmailConfiguration(item)} >Update Email Configuration</button>
                    </div>
                </div>
            </div>
        </div>
    </div>)
};

export default email_configuration_list_item_detail;