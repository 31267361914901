import React, { Component } from 'react';
import {   credentials,amazonSearchProductBySku } from '../amazonMarketplace';

class AmazonTest extends Component{

    handleSearchItem=async()=>{
        const secrets = credentials('CA');
        // const searchCondition = `&IdList.Id.2=7706-18-01&IdType=SellerSKU`;
        // const query = await generateRequestQuery(secrets,'CA','ListMatchingProducts','2011-10-01', searchCondition);
        // await searchProduct(query)

        const res = await amazonSearchProductBySku(secrets,'L2200-050-47','US');
        //console.log(res)
        // const res1 = await amazonSearchProductBySku(secrets,'L2200-050-47','CA');
        // console.log(res1)

    };
    render(){
        return(
            <div>
                <button className={'btn btn-danger'} type={'button'} onClick={this.handleSearchItem} >Search Item</button>
            </div>
        )
    }
}

export default AmazonTest;