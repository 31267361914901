import React from 'react';

const  OrderManagerModal =(props)=>{
  return(
      <div className="modal fade" id="submitItemDetail" tabIndex="-1" role="dialog" aria-labelledby="submitItemDetailModalLabel"
           aria-hidden="true">
          <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title" id="submitItemDetailModalLabel">Item Details</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                  <div className="modal-body">
                      <p className="text-center"><span className='font-weight-bold'>Status: &nbsp; </span>{props.Status}</p>

                      <form onSubmit={(e)=>props.handleReSubmit(e,props)} className="container-fluid">

                          <InputHelper name="WarehouseCode" value={props.WarehouseCode === undefined? "":props.WarehouseCode } func={props.handleOnChange} />
                          <InputHelper name="SKU" value={props.SKU === undefined? "":props.SKU} func={props.handleOnChange} />
                          <InputHelper name="Description" value={props.Description === undefined? "":props.Description} func={props.handleOnChange} />
                          <InputHelper name="UPC" value={props.UPC === undefined? "":props.UPC} func={props.handleOnChange} />
                          <InputHelper name="Price" value={props.Price === undefined? "":props.Price} func={props.handleOnChange} />
                          <InputHelper name="PerBox" value={props.PerBox === undefined? "":props.PerBox} func={props.handleOnChange} />
                          <InputHelper name="Size" value={props.Size === undefined? "":props.Size} func={props.handleOnChange} />
                          <InputHelper name="Style" value={props.Style === undefined? "":props.Style} func={props.handleOnChange} />
                          <InputHelper name="Color" value={props.Color === undefined? "":props.Color} func={props.handleOnChange} />
                          <InputHelper name="Width" value={props.Width === undefined? "":props.Width} func={props.handleOnChange} />
                          <InputHelper name="Height" value={props.Height === undefined? "":props.Height} func={props.handleOnChange} />
                          <InputHelper name="Length" value={props.Length === undefined? "":props.Length} func={props.handleOnChange} />
                          <InputHelper name="Weight" value={props.Weight === undefined? "":props.Weight} func={props.handleOnChange} />
                          <InputHelper name="WeightPerBox" value={props.WeightPerBox === undefined? "":props.WeightPerBox} func={props.handleOnChange} />
                          <InputHelper name="Cube" value={props.Cube === undefined? "":props.Cube} func={props.handleOnChange} />
                          <InputHelper name="UofM" value={props.UofM === undefined? "":props.UofM} func={props.handleOnChange} />
                          <InputHelper name="CustomerItemID" value={props.CustomerItemID === undefined? "":props.CustomerItemID} func={props.handleOnChange} />

                          <div className="modal-footer">
                              <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                              <button type="submit" className="btn btn-danger" >Submit Again</button>
                          </div>
                      </form>

                  </div>
              </div>
          </div>
      </div>
  );
};
const InputHelper = ({name,value,func})=>{

    return(
        <div className="form-group row">
            <label htmlFor={name} className={'col-3 col-form-label font-weight-bold'}>{name}:</label>
            <input type="text" className="form-control col-9" id={name} value={value}
                   onChange={func} name={name}  />
        </div>
    )
};
export default OrderManagerModal;