import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {
    exportColours,
    exportMaterials,
    exportFamilies,
    exportSubFamilies,
    exportDesigns,
    exportSKUs,
    exportSKUDetails,exportUPCs,
    getAllSKUNumberList,getUPCLabelInfo,createUPCLabel,exportASIProducts
} from "../../Utils/ashlinDesign";
import PrintUPCLabelModal from './PrintUPCLabelModal';
import $ from "jquery";
class AshlinExport extends Component{
    constructor() {
        super();
        this.state={
            isExporting:false,
            skuNumberList:[],
            selectedSKUNumber:'',
            hasMSRP:false,
            currency:[
                {name:"AUD", rate: 1.0364},
                {name:"CAD", rate: 1},
                {name:"EUR", rate: 0.7},
                {name:"GBP", rate: 0.65},
                {name:"INR", rate: 50.12},
                {name:"USD", rate: 0.85}
            ],
            selectedCurrency: {name:"CAD", rate: 1}
        }
    }
    componentDidMount(){
        this.handleGetSKUList();
    }
    handleExportColors = async(type)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportColours(type);
            this.setState({
                isExporting:false
            })
        });
    };
    handleExportUPCs = async()=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportUPCs();
            this.setState({
                isExporting:false
            })
        });
    };

    handleExportMaterials = async(type)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportMaterials(type);
            this.setState({
                isExporting:false
            })
        });
    };
    handleExportFamilies = async(type)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportFamilies(type);
            this.setState({
                isExporting:false
            })
        });
    };
    handleExportSubFamilies = async(type)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportSubFamilies(type);
            this.setState({
                isExporting:false
            })
        });
    };
    handleExportDesigns = async(type)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportDesigns(type);
            this.setState({
                isExporting:false
            })
        });
    };
    handleExportSKUs = async(type)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportSKUs(type);
            this.setState({
                isExporting:false
            })
        });
    };
    handleExportSKUDetails = async(type)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportSKUDetails(type);
            this.setState({
                isExporting:false
            })
        });
    };
    handleGetSKUList=async()=>{
        const skuNumberList = await getAllSKUNumberList();
        this.setState({
            skuNumberList
        })
    };
    handleSKUNunberOnchange=async(e)=>{
        const selected = e.target.value;
        if(selected !== "optionDefaultHeader"){
            this.setState({
                selectedSKUNumber:selected
            })
        }
    };
    handleClose = ()=>{
        this.setState({
            selectedCurrency:{name:"CAD", rate: 1}
        },()=>{
            $('#printUPCLabelModal').modal('hide')
        });
    };
    handleCurrencyOnchange = (e)=>{
        const name = e.target.value;
        const selectedCurrency = this.state.currency.find(c=>c.name === name);
        this.setState({
            selectedCurrency
        });
    };
    handleCreateLabel = async()=>{
        //console.log(this.state)
        if(this.state.selectedSKUNumber!=='optionDefaultHeader'){
            const upcLabelInfo = await getUPCLabelInfo(this.state.selectedSKUNumber);
            if(upcLabelInfo){
                if(this.state.hasMSRP){
                    upcLabelInfo.BasePrice = (upcLabelInfo.BasePrice * 1.05 * this.state.selectedCurrency.rate).toFixed(2);
                    upcLabelInfo.Currency = this.state.selectedCurrency.name;
                }else{
                    upcLabelInfo.BasePrice = "";
                    upcLabelInfo.Currency = "";
                }
                upcLabelInfo.SKU = this.state.selectedSKUNumber;
                await createUPCLabel(upcLabelInfo);

                //console.log(upcLabelInfo);

            }else{
                alert("Can't find UPC Label Info");
            }
        }else{
            alert('Must selected a SKU!');
        }
    };
    handleOnchange = (e)=>{
        const name = e.target.name;
        let value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleExportASI = async(currency)=>{
        this.setState({
            isExporting:true
        },async()=>{
            await exportASIProducts(currency);
            this.setState({
                isExporting:false
            })
        });
    };
    render() {
        return (
            <div className='container-fluid row'>
                <div className={'col'} style={{paddingLeft: "initial"}}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <br/>
                    <PrintUPCLabelModal
                        skuNumList={this.state.skuNumberList}
                        SKUNunberOnchange={this.handleSKUNunberOnchange}
                        handleClose = {this.handleClose}
                        currencyOnchange = {this.handleCurrencyOnchange}
                        createLabel = {this.handleCreateLabel}
                        hasMSRP = {this.state.hasMSRP}
                        onChange = {this.handleOnchange}
                    />
                    <h1 className={"text-center"}>Ashlin Product Information Export</h1>
                    {this.state.isExporting &&<div className={"text-center text-success display-3"}><i className="fa fa-spinner fa-spin"> </i> &nbsp; Exporting...<br/></div>}
                    <hr style={{color:'red',margin:'5px'}}/>
                    <div className={'row justify-content-center'}>
                        <button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportColors('ALL')}>Export ALL Colors</button>
                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportColors('ACTIVE')}>Export Active Colors</button>
                        <button className={'btn-danger '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportColors('INACTIVE')}>Export Inactive Colors</button>
                    </div>
                    <hr style={{color:'red',margin:'5px'}}/>
                    <div className={'row justify-content-center'}>
                        <button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportMaterials('ALL')}>Export ALL Materials</button>
                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportMaterials('ACTIVE')}>Export Active Materials</button>
                        <button className={'btn-danger '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportMaterials('INACTIVE')}>Export Inactive Materials</button>
                    </div>
                    {/*<hr style={{color:'red',margin:'5px'}}/>*/}
                    {/*<div className={'row justify-content-center'}>*/}
                        {/*<button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}*/}
                                {/*onClick={()=>this.handleExportFamilies('ALL')}>Export ALL Families</button>*/}
                        {/*<button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}*/}
                                {/*onClick={()=>this.handleExportFamilies('ACTIVE')}>Export Active Families</button>*/}
                        {/*<button className={'btn-danger '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}*/}
                                {/*onClick={()=>this.handleExportFamilies('INACTIVE')}>Export Inactive Families</button>*/}
                    {/*</div>*/}
                    <hr style={{color:'red',margin:'5px'}}/>
                    <div className={'row justify-content-center'}>
                        <button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSubFamilies('ALL')}>Export ALL Families</button>
                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSubFamilies('ACTIVE')}>Export Active Families</button>
                        <button className={'btn-danger '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSubFamilies('INACTIVE')}>Export Inactive Families</button>
                    </div>
                    <hr style={{color:'red',margin:'5px'}}/>
                    <div className={'row justify-content-center'}>
                        <button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportDesigns('ALL')}>Export ALL Designs</button>
                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportDesigns('ACTIVE')}>Export Active Designs</button>
                        <button className={'btn-danger '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportDesigns('INACTIVE')}>Export Inactive Designs</button>
                    </div>
                    <hr style={{color:'red',margin:'5px'}}/>
                    <div className={'row justify-content-center'}>
                        <button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSKUs('ALL')}>Export ALL SKUs</button>
                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSKUs('ACTIVE')}>Export Active SKUs</button>
                        <button className={'btn-danger '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSKUs('INACTIVE')}>Export Inactive SKUs</button>
                    </div>
                    <hr style={{color:'red',margin:'5px'}}/>
                    <div className={'row justify-content-center'}>
                        <button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSKUDetails('ALL')}>Export ALL SKU Details</button>
                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSKUDetails('ACTIVE')}>Export Active Details</button>
                        <button className={'btn-danger '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportSKUDetails('INACTIVE')}>Export Inactive Details</button>


                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportUPCs()}>Export All UPCs</button>

                        {/*<button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}*/}
                        {/*        onClick={()=>{*/}
                        {/*            $('#printUPCLabelModal').modal('show')*/}
                        {/*        }}>Print UPC Label</button>*/}
                    </div>
                    <hr style={{color:'red',margin:'5px'}}/>
                    <div className={'row justify-content-center'}>
                        <button className={'btn-primary '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px',margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportASI('USD')}>Export ALL ASI Products in USD</button>
                        <button className={'btn-success '} style={{borderRadius:'50%',width:"150px",height:"150px", fontSize:'23px', margin:'0 50px'}} type={'button'}
                                onClick={()=>this.handleExportASI('CAD')}>Export ALL ASI Products in CAD</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default AshlinExport;
