import React, {Component} from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import DispatchedOrderDetailModal from './DispatchedOrderDetailModal';
import {
    getBPheader, shipMethod,
    getOrdersByStatusId,
    updateOrderStatus,getOrderNotesByOrderID,postShippedEvent,
    updateShipmentMethodAndDeliveryDate, shippingMethods,searchGoodsOutNote,updateGoodsOutNote
} from '../../Utils/brightpearl';
import ReactTable from 'react-table';
import { sendEmail,getOrderRecord,putOrderRecord } from '../../Utils/ashlinDesign';
import {ENABLE_LEANSUPPLY, ENABLE_BRIGHTPEARL, getTrackingNumber, currentDateTime} from "../../Utils/requestHelper";
import $ from "jquery";
class DispatchedOrder extends Component{
    constructor(props){
        super(props);
        this.state={
            dispatchedOrders:[],
            hasCheckedStatus:false,
            selectedOrder:undefined,
            selectedOrderNotes:undefined,
            trackingNumber:'',
            sendEmail:true,
            emailList:'ashlin@ashlinbpg.com, nancy.taccone@ashlinbpg.com, ron.chandler@ashlinbpg.com',
            emailDetail:"",
            shippingMethod:""
        }
    }
    componentDidMount(){
        this.getDispatchedOrder();
    }
    getDispatchedOrder=async()=>{
        const dispatchedOrders =await getOrdersByStatusId(44);
        //console.log(dispatchedOrders);
        this.setState({
            dispatchedOrders
        })
    };
    handleOrderDetail=()=>{
        $('#DispatchedOrderDetailModal').modal({backdrop: 'static', keyboard: false});
    };
    handleUpDateOrder=async(e)=>{
        e.preventDefault();
        const orderToUpdate = this.state.selectedOrder;
        const headers = await getBPheader();
        const shippingMethod  = shippingMethods.find(s=>s.value === orderToUpdate.delivery.shippingMethodId);
        let conditions = ["Xpresspost","2Day","EXPRESS","1DAY","2nd","Next","Priority","Dedicated","2","1"];
        const isExpress = conditions.some(el=>shippingMethod.label.toUpperCase().includes(el.toUpperCase()));
        let d = new Date();
        if(isExpress){
            d.setDate(d.getDate() + 10);
        }else{
            d.setDate(d.getDate() + 30);
        }
        const dayOfWeek = d.getDay();
        if(dayOfWeek===0){
            d.setDate(d.getDate() + 1);
        }
        if(dayOfWeek===6){
            d.setDate(d.getDate() + 2);
        }
        const date = d.toISOString().slice(0,10);
        const note = `Tracking number: ${this.state.trackingNumber}`;
        const data = [
                {
                    "op": "replace",
                    "path": "/delivery/deliveryDate",
                    "value": date
                }
        ];
        //console.log(data);

        //get goods out note
        const goodsOutNoteId = await searchGoodsOutNote(orderToUpdate.id,headers);
        //console.log(goodsOutNoteId);

        if(goodsOutNoteId!== null){
            //update goods out tracking reference
            const newNote = {
                "priority": false,
                "shipping": {
                    "shippingMethodId": orderToUpdate.delivery.shippingMethodId,
                    "reference": this.state.trackingNumber,
                },
                "labelUri": ""
            };
            //console.log(newNote);
            await updateGoodsOutNote(goodsOutNoteId,newNote,headers);
            //update goods out event to shipped

            //This event can be handled by allocated and invoice on BP
            await postShippedEvent(goodsOutNoteId,headers);

        }

        const brightPearlRes = await updateOrderStatus(orderToUpdate.id, 33, note,headers);
        const updateRes = await updateShipmentMethodAndDeliveryDate(orderToUpdate.id,data,headers);
        //Send email
        const recipients = this.state.emailList.split(',').map(item => item.trim());
        const subject = `Order #${orderToUpdate.id} Shipped and Ready for Invoice`;
        const emailNotificationRes = this.state.sendEmail ? await sendEmail(recipients,subject,this.state.emailDetail,"",""):'Not Send';

        //Update order record
        const shippedDate = currentDateTime();
        const ashlinOrderRecord = await getOrderRecord(orderToUpdate.id)

        ashlinOrderRecord.ShippedDate = shippedDate;
        ashlinOrderRecord.TrackingNumber = this.state.trackingNumber;
        ashlinOrderRecord.Status = "Shipped";
        //console.log(ashlinOrderRecord);
        const updateOrderRecordRes = await putOrderRecord(ashlinOrderRecord);
        //console.log(updateOrderRecordRes);

        Promise.all([brightPearlRes,updateRes,emailNotificationRes]).then(()=>{
            alert("Change Order Status to Brightpearl");
            this.getDispatchedOrder();
            this.setState({
                hasCheckedStatus:false,
                trackingNumber:'',
                sendEmail:true,
                emailList:'ashlin@ashlinbpg.com,nancy.taccone@ashlinbpg.com,ron.chandler@ashlinbpg.com',
                emailDetail:"",
                shippingMethod:""
            });
            $('#DispatchedOrderDetailModal').modal('hide');
        });
    };
    handleCheckOrderStatus=async(e)=>{
        e.preventDefault();
        const auth = JSON.parse(localStorage.getItem('auth'));
        const data = {
            "Authorization":auth,
            "OrderHeader":{
                "OrderHandle":`Ashlin-${this.state.selectedOrder.id}`
                //"OrderHandle":"Ashlin-807314"
            }
        };
        const trackingNumber = await getTrackingNumber(data);
        //console.log(trackingNumber);
        if(trackingNumber !=="" && trackingNumber!==undefined){
            const orderRows= Object.values(this.state.selectedOrder.orderRows);
            //console.log(orderRows);
            let shippedItems ='';
            orderRows.forEach(row=>{
                shippedItems += ` ${row.productSku} * ${parseInt(row.quantity.magnitude)}`;
                shippedItems +='\n                              ';
            });
            let d = new Date();
            const date = d.toLocaleDateString();
            const shippingMethod = (shippingMethods.find(s=>s.value === this.state.selectedOrder.delivery.shippingMethodId)).label;
            const USPSNoteIndex = this.state.selectedOrderNotes.findIndex(note=>note.text.includes("USPS Tracking"));
            let emailDetail;
            if(USPSNoteIndex!==-1){
                const USPSURL = this.state.selectedOrderNotes[USPSNoteIndex].text.substring(this.state.selectedOrderNotes[USPSNoteIndex].text.indexOf("USPS Tracking"));
                emailDetail  = `    Order # ${this.state.selectedOrder.id} has been dispatched from the 3PL. <br/>
    Canpar Tracking #: ${trackingNumber} <br/>
    ${USPSURL } <br/>
    DATE SHIPPED : ${date} <br/>
    ITEM SHIPPED : ${shippedItems} <br/>
    Please finalize order: <br/>
    * assign stock in BP <br/>
    * complete invoice <br/>
    * notify customer <br/>`;
            }else{
                emailDetail  = `    Order # ${this.state.selectedOrder.id} has been dispatched from the 3PL. <br/>
    Tracking #: ${trackingNumber} <br/>
    Shipping Method: ${shippingMethod} <br/>
    DATE SHIPPED : ${date} <br/>
    ITEM SHIPPED : ${shippedItems} <br/>
    Please finalize order: <br/>
    * assign stock in BP <br/>
    * complete invoice <br/>
    * notify customer <br/>`;
            }

        this.setState({
            hasCheckedStatus:true,
            trackingNumber,
            emailDetail,
            shippingMethod
        });
        }else{
            alert("This order is still processing!")
        }
    };
    handleOnChange=e=>{
        const value =e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        this.setState({
            [name]:value
        })
    };


    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <br/>
                    <DispatchedOrderDetailModal
                        hasCheckedStatus = {this.state.hasCheckedStatus}
                        selectedOrder = {this.state.selectedOrder}
                        trackingNumber = {this.state.trackingNumber}
                        sendEmail = {this.state.sendEmail}
                        emailList = {this.state.emailList}
                        emailDetail = {this.state.emailDetail}
                        shippingMethod = {this.state.shippingMethod}
                        handleCheckOrderStatus = {this.handleCheckOrderStatus}
                        handleUpDateOrder = {this.handleUpDateOrder}
                        handleOnChange = {this.handleOnChange}
                    />
                    <h1 className={"text-center"}> Dispatched Orders</h1>
                    <br/>
                    {/*<div className="row">*/}
                        {/*<div className="col-4">*/}
                            {/*{this.state.hasCheckedStatus && <button onClick={this.handleUpDateOrders} id='updateOrders'  className={'btn btn-danger btn-block'}>Update Orders Status</button>}*/}
                        {/*</div>*/}
                        {/*<div className="col-4 ml-auto">*/}
                            {/*{!this.state.hasCheckedStatus && <button onClick={this.handleUpdateOrderStatuses} className={'btn btn-primary btn-block'}>Update Order Statuses</button>}*/}
                        {/*</div>*/}
                    {/*</div>*/}

                    <div style={{ textAlign: "center" }}>
                        <ReactTable
                            data={this.state.dispatchedOrders}
                            columns={
                                [
                                    {
                                        Header: "Order ID",
                                        accessor: "id",
                                    },
                                    {
                                        Header: "Buyer Name",
                                        accessor: o => o.parties.customer.addressFullName,
                                        id: "buyerName"
                                    },
                                    {
                                        Header: "Created Date",
                                        accessor: o => o.createdOn.slice(0,19).replace("T"," "),
                                        id: "createdOn"
                                    },
                                    {
                                        Header: "Payment Status",
                                        accessor: "orderPaymentStatus"
                                    },
                                    {
                                        Header:"Shipment Method",
                                        accessor:"ShipmentMethod"
                                    },
                                    {
                                        Header:"Tracking #",
                                        accessor:"TrackingNumber"
                                    }
                                ]
                            }
                            defaultPageSize={10}
                            className="-striped -highlight orderTable"
                            getTrProps={(state, rowInfo) => {
                                return {
                                    onClick: async(e) => {
                                        const headers = await getBPheader();
                                        const selectedOrderNotes= await getOrderNotesByOrderID(rowInfo.original.id,headers);
                                        this.setState({
                                            selectedOrder: rowInfo.original,
                                            hasCheckedStatus:false,
                                            trackingNumber:'',
                                            sendEmail:true,
                                            emailList:'ashlin@ashlinbpg.com, nancy.taccone@ashlinbpg.com, ron.chandler@ashlinbpg.com',
                                            emailDetail:"",
                                            shippingMethod:"",
                                            selectedOrderNotes
                                        },this.handleOrderDetail);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default DispatchedOrder;