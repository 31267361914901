import React from 'react';
import { findBestMatch } from 'string-similarity';
import TextInput from '../bootstrapComponent/textInput';
import TextInputParallel from '../bootstrapComponent/textInputParallel';
import TierCostModal from './TierCostModal';
import SelectInput from '../bootstrapComponent/selectInput';
const NewSKUInfo =({ designChange, ashlinDesign, ashlinMaterial, ashlinColor,
                       ashlinFamily, ashlinSubFamily, selectedDesign, collections,
                       selectedMaterial, selectedColor, selectedFamily, selectedSubFamily,
                       ashlinHtsCa,ashlinHtsUs, onChange,  basicInfo ,asiCategory ,isUpdating,ashlinSKUs,pricingTierOnchange,
                       selectedSKU,generateUPC,calculateTierPrice,tierCostMatrix,skuCost,skuCostOnchange,upDateCostMatrix
                   })=>{


        const ashlinDesignCode = ashlinDesign.map(i=>i.designCode);
        const ashlinMaterialCode = ashlinMaterial.map(i=>i.materialCode);
        const ashlinColorCode = ashlinColor.map(i=>i.colorCode);
        const ashlinFamilyCode = ashlinFamily.map(i=>i.SuperFamily);
        const ashlinSubFamilyCode = ashlinSubFamily.map(i=>i.subFamilyCode);
        const materialList = ashlinMaterial.map(i=>i.shortENG);
        if(selectedSubFamily.shortENG!==undefined && basicInfo.selectedAsiCategory===''){
            const matches = findBestMatch(selectedSubFamily.shortENG,asiCategory).bestMatch.target;
            if(matches==='WALLETS-TRICK'){
                basicInfo.selectedAsiCategory = 'WALLETS-GENERAL';
            }else{
             basicInfo.selectedAsiCategory = matches;
            }
        }
        let  ashlinSKUsCode=[];
        if(isUpdating){
            ashlinSKUsCode= ashlinSKUs.map(i=>i.SkuAshlin);
        }
        return(
            <div className={'col'}>
                {tierCostMatrix !== null?
                    <TierCostModal
                        tierCostMatrix = {tierCostMatrix }
                        skuCost = {skuCost}
                        onChange ={skuCostOnchange}
                        upDateCostMatrix = {upDateCostMatrix}
                        create = {true}
                        pricingTierOnchange={pricingTierOnchange}
                    />:null}
                { isUpdating &&
                    <div>
                        <div className={"row"}>
                            <div className="col-sm-6">
                                <SelectInput  id={'add_sku_code'} label={'SKU'} name={'selectedSKU'}
                                              style={{fontWeight:'bold'}}     value={ashlinSKUsCode}    onChange={designChange}/>
                            </div>
                            <div className="col-sm-6">
                                <SelectInput  id={'add_sku_design_code'} label={'Design'} name={'selectedDesign'} defaultValue={ selectedSKU.DesignServiceCode!==undefined?selectedSKU.DesignServiceCode:""}
                                              style={{fontWeight:'bold'}}     value={ashlinDesignCode}    onChange={designChange}/>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-sm-6">
                                <SelectInput  id={'add_sku_brand'} label={'Brand'} name={'selectedBrand'} defaultValue={selectedDesign.brand!==undefined?selectedDesign.brand:""}
                                              style={{fontWeight:'bold'}}     value={['6ixHide™','Ashlin®','PoppyBuzz™']}    onChange={designChange}/>
                            </div>
                            <div className="col-sm-6">
                                <SelectInput  id={'add_sku_collection'} label={'Collections'} name={'pickedCollection'} width={'450px'}
                                              style={{fontWeight:'bold'}}     value={collections}  defaultValue={selectedFamily.Collections}   onChange={designChange}/>
                               {/*<input type={'text'} value={this.state.selectedDesign.shortENG!==undefined?this.state.selectedDesign.shortENG:""} readOnly style={{width:"100%",height:"100%"}}/>*/}
                            </div>
                        </div>
                    </div>
                }
                { !isUpdating &&
                <div className={"row"}>
                    <div className="col-sm-4">
                        <SelectInput  id={'add_sku_design_code'} label={'Design'} name={'selectedDesign'} defaultValue={ selectedSKU.DesignServiceCode!==undefined?selectedSKU.DesignServiceCode:"optionDefaultHeader"}
                                      style={{fontWeight:'bold'}}     value={ashlinDesignCode}    onChange={designChange}/>
                    </div>
                    <div className={'col-sm-3'}>
                        <SelectInput  id={'add_sku_brand'} label={'Brand'} name={'selectedBrand'} defaultValue={selectedDesign.brand!==undefined?selectedDesign.brand:""}
                                      style={{fontWeight:'bold'}}     value={['6ixHide™','Ashlin®','PoppyBuzz™']}    onChange={designChange}/>
                    </div>
                    <div className={'col-sm-4'}>
                        <SelectInput  id={'add_sku_collection'} label={'Collections'} name={'pickedCollection'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={collections}  defaultValue={selectedFamily.Collections}   onChange={designChange}/>
                        {/*<input type={'text'} value={this.state.selectedDesign.shortENG!==undefined?this.state.selectedDesign.shortENG:""} readOnly style={{width:"100%",height:"100%"}}/>*/}
                    </div>
                </div>
                }
                <hr style={{margin:"5px 0",border:'none'}}/>
                <div className={"row"}>
                    <div className="col-sm-3">
                        <SelectInput  id={'add_sku_family'} label={'Family'} name={'selectedFamily'} defaultValue={selectedSubFamily.pickedFamily}
                                      style={{fontWeight:'bold', marginLeft:'-10px'}}     value={ashlinFamilyCode}    onChange={designChange}/>
                    </div>
                    <div className={'col-sm-3'}>
                        <input type={'text'} value={selectedFamily.SuperFamilyDescription!==undefined?selectedFamily.SuperFamilyDescription:""} disabled style={{width:"100%",height:"100%"}}/>
                    </div>
                    <div className="col-sm-3">
                        <SelectInput  id={'add_sku_sub_family'} label={'SubFamily'} name={'selectedSubFamily'} defaultValue={selectedDesign.pickedSubFamily}
                                      style={{fontWeight:'bold'}}     value={ashlinSubFamilyCode}    onChange={designChange}/>
                    </div>
                    <div className={'col-sm-3'}>
                        <input type={'text'} value={selectedSubFamily.shortENG!==undefined?selectedSubFamily.shortENG:""} disabled  style={{width:"100%",height:"100%"}}/>
                    </div>
                </div>
                <hr style={{margin:"5px 0",border:'none'}}/>
                <div className={"row"}>
                    <div className="col-sm-3">
                        <SelectInput  id={'add_sku_material_code'} label={'Material'} name={'selectedMaterial'} defaultValue={ selectedSKU!==undefined?selectedSKU.MaterialCode:""}
                                      style={{fontWeight:'bold', marginLeft:'-10px'}}     value={ashlinMaterialCode}    onChange={designChange}/>
                    </div>
                    <div className={'col-sm-3'}>
                        <input type={'text'} value={selectedMaterial.shortENG!==undefined?selectedMaterial.shortENG:""} disabled style={{width:"100%",height:"100%"}}/>
                    </div>
                    <div className="col-sm-3">
                        <SelectInput  id={'add_sku_color_code'} label={'Color'} name={'selectedColor'} defaultValue={ selectedSKU!==undefined?selectedSKU.ColourCode:""}
                                      style={{fontWeight:'bold'}}     value={ashlinColorCode}    onChange={designChange}/>
                    </div>
                    <div className={'col-sm-3'}>
                        <input type={'text'} value={selectedColor.shortENG!==undefined?selectedColor.shortENG:""} disabled style={{width:"100%",height:"100%"}}/>
                    </div>
                </div>
                <hr style={{margin:"5px 0",border:'none'}}/>
                <div className={'row container'}>
                    <div className={'col-4'}>
                        <div className={'row'}>
                            <h6 className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>Pantone Color</h6>
                            <input className={'col'} style={{}} name={'pantoneColor'}  value={basicInfo.pantoneColor} onChange={(e)=>onChange(e,"basicInfo")}/>
                        </div>
                    </div>
                    <div className={'col'}>
                        <div className={'row'}>
                            <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>R</h6>
                            <input className={'col'} style={{}} name={'colorR'}  value={basicInfo.colorR} onChange={(e)=>onChange(e,"basicInfo")}/>
                        </div>
                    </div>
                    <div className={'col'}>
                        <div className={'row'}>
                            <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>G</h6>
                            <input className={'col'} style={{}} name={'colorG'}  value={basicInfo.colorG} onChange={(e)=>onChange(e,"basicInfo")}/>
                        </div>
                    </div>
                    <div className={'col'}>
                        <div className={'row'}>
                            <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>B</h6>
                            <input className={'col'} style={{}} name={'colorB'}  value={basicInfo.colorB} onChange={(e)=>onChange(e,"basicInfo")}/>
                        </div>
                    </div>
                    <div className={'col-4'}>
                        <div className={'row'}>
                            <h6 className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>Designer Color Name</h6>
                            <input className={'col'} style={{}} name={'designerColorName'}  value={basicInfo.designerColorName} onChange={(e)=>onChange(e,"basicInfo")}/>
                        </div>
                    </div>

                </div>
                
                <hr style={{margin:"5px 0",border:'none'}}/>
                <hr/>
                <div className={'row'}>
                    {/*<button className={'col'} onClick={(e)=>generateUPC(e)}> Generate UPC</button>*/}
                    <div className={'col'}>
                       UPC 9: <input value={basicInfo.upc9!==undefined?basicInfo.upc9:""} readOnly/>
                    </div>
                    <div className={'col'}>
                        UPC 10: <input value={basicInfo.upc10!==undefined?basicInfo.upc10:""} readOnly/>
                    </div>
                </div>
                <hr />
                <div className={'row'}>
                    <div className={'row col-6'} >
                        <div className={'col-4'} style={{margin:'auto 0',textAlign:'right'}}>
                            <h6>Canadian HTS</h6>
                        </div>
                        <div className={'col-8'}>
                            <select style={{width:'48%'}}  name={'selectedHtsCa'} onChange={designChange} value={selectedSubFamily.htsCaCode!==null?selectedSubFamily.htsCaCode:''}>
                                <option value=''>-- select an option --</option>
                                {ashlinHtsCa.map(i=>{
                                    return(
                                        <option value={i.id} key={i.id}>
                                            {i.id}
                                        </option>
                                    )
                                })}
                            </select>
                            <input style={{width:'48%',marginLeft:'4%'}}   value={selectedSubFamily.htsCaValue!==undefined? selectedSubFamily.htsCaValue:""} disabled/>
                        </div>
                    </div>
                    <div className={'row col-6'} >
                        <div className={'col-4'} style={{margin:'auto 0',textAlign:'right'}}>
                            <h6>US HTS</h6>
                        </div>
                        <div className={'col-8'}>
                            <select style={{width:'48%'}} onChange={designChange} name={'selectedHtsUs'} value={selectedSubFamily.htsUsCode}>
                                <option value=''>-- select an option --</option>
                                {ashlinHtsUs.map(i=>{
                                    return(
                                        <option value={i.id} key={i.id}>
                                            {i.id}
                                        </option>
                                    )
                                })}
                            </select>
                            <input style={{width:'48%',marginLeft:'4%'}}   value={selectedSubFamily.htsUsValue!==undefined?selectedSubFamily.htsUsValue:""} disabled/>
                        </div>
                    </div>
                </div>
                <hr style={{margin:"5px 0",border:'none'}}/>
                <div className={'row container'}>
                    <div className={'col-4'}>
                        <div className={'row'}>
                            <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Pricing Tier</h6>
                            <input className={'col'} style={{}} name={'pricingTier'}  value={selectedSubFamily.pricingTier!==undefined?selectedSubFamily.pricingTier:""} onChange={designChange}/>
                        </div>
                    </div>
                    <div className={'col-4'}>
                        <div className={'row'}>
                            <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Reorder Qty</h6>
                            <input className={'col'} style={{}} name={'ReorderQty'}  value={selectedSubFamily.ReorderQty!==undefined?selectedSubFamily.ReorderQty:""} onChange={designChange}/>
                        </div>
                    </div>
                    <div className={'col-4'}>
                        <div className={'row'}>
                            <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Reorder Lvl</h6>
                            <input className={'col'} style={{}} name={'ReorderLevel'}  value={selectedSubFamily.ReorderLevel!==undefined?selectedSubFamily.ReorderLevel:""} onChange={designChange}/>
                        </div>
                    </div>
                </div>
                <hr/>
                <hr style={{margin:"5px 0",border:'none'}}/>
                <div className={'row'}>
                    <div className={'col row'}>
                        <div className={'col-4'}>
                            <h6 style={{textAlign:'right',margin:'auto 0',paddingTop:'5px'}}>Base Price</h6>
                        </div>
                        <div className={'col-8'}>
                            $<input onChange={(e)=>onChange(e,"basicInfo")} name={'selectedBasePrice'} value={ basicInfo.selectedBasePrice}/>
                        </div>
                    </div>
                    <div className={'col row'}>
                        <div className={'col-4'}>
                            <h6 style={{textAlign:'right',margin:'auto 0',paddingTop:'5px'}}>Minimum Order</h6>
                        </div>
                        <div className={'col-8'}>
                            <input onChange={(e)=>onChange(e,"basicInfo")} name={'selectedMinimumOrder'} value={ basicInfo.selectedMinimumOrder}/>
                        </div>
                    </div>
                    <div className={'col row'}>
                        <button className={'btn-block btn-danger'} onClick={(e)=>calculateTierPrice(e)}>Calculate Tier Cost Price</button>
                    </div>
                </div>
                <hr/>
                <hr style={{margin:"5px 0",border:'none'}}/>
                <div className={'row'}>
                    <div className={'col-6 row'}>
                        <div className={'col-4'}>
                            <h6 style={{textAlign:'right',margin:'auto 0',paddingTop:'5px'}}>Trim</h6>
                        </div>
                        <div className={'col-8'}>
                            <input onChange={(e)=>onChange(e,"basicInfo")} name={'selectedTrim'} value={ basicInfo.selectedTrim}/>
                        </div>
                    </div>
                    <div className={'col-6 row'}>
                        <div className={'col-4'}>
                            <h6 style={{textAlign:'right',margin:'auto 0',paddingTop:'5px'}}>Handle Material</h6>
                        </div>
                        <select style={{width:'48%'}} onChange={(e)=>onChange(e,"basicInfo")} name={'selectedHandleMaterial'} value={basicInfo.selectedHandleMaterial} >
                            <option value=''>-- select an option --</option>
                            {materialList.map((i,index)=>{
                                return(
                                    <option value={i} key={i+index}>
                                        {i}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <hr style={{margin:"5px 0",border:'none'}}/>
                <div className={'row'}>
                    <div className={'col-6 row'}>
                        <div className={'col-4'}>
                            <h6 style={{textAlign:'right',margin:'auto 0',paddingTop:'2px'}}>Lining Material</h6>
                        </div>
                        <select style={{width:'48%'}} onChange={(e)=>onChange(e,"basicInfo")} name={'selectedLiningMaterial'} value={basicInfo.selectedLiningMaterial}>
                            <option value=''>-- select an option --</option>
                            {['100% Cotton','100% Felt Backing','100% Lycra','100% matching leather','100% matching vegan leather','100% Nylon','100% Polyester Cotton','100% Rayon','100% Suede','100% Suedeine (non-leather)','100% Velvet','50%/50% Cotton-Poly blend'].map(i=>{
                                return(
                                    <option value={i} key={i}>
                                        {i}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div className={'col-6 row'}>
                        <div className={'col-4'}>
                            <h6 style={{textAlign:'right',margin:'auto 0',paddingTop:'2px'}}>Hardware Color</h6>
                        </div>
                        <select style={{width:'48%'}} onChange={(e)=>onChange(e,"basicInfo")} name={'selectedHardwareColor'} value={basicInfo.selectedHardwareColor}>
                            <option value=''>-- select an option --</option>
                            {['Nickel/Silver','Gold - Shiny', 'Golt - Matte-Satin','Silver -Matte-Satin','Antique'].map(i=>{
                                return(
                                    <option value={i} key={i}>
                                        {i}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <hr/>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <SelectInput  id={'add_sku_asi_category'} label={'ASI Category'} name={'selectedAsiCategory'} defaultValue={basicInfo.selectedAsiCategory}
                                      style={{fontWeight:'bold', marginLeft:'-10px'}}     value={asiCategory}    onChange={(e)=>onChange(e,"basicInfo")}/>
                    </div>
                    <div className={'col-6'}>

                    </div>
                </div>

            </div>
        )

};

export default NewSKUInfo;