import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import OrderLineChart from './components/OrderLineChart';
import {getEmailConfigurationById, getSubmittedOrdersDetail, sendPDFEmail} from '../../Utils/ashlinDesign';
import moment from 'moment';
import _ from 'lodash';
import ChannelSalesChart from './components/channel_sales_chart';
import {
    checkOrdersByStatusID,
    getBPheader,
    getOrdersByStatusId,
    getTaxRate,
    updateOrderStatus,channelsMapping
} from '../../Utils/brightpearl';
import { toInsightsModelMapper,getAllOrders  } from '../../Utils/bestbuyMarketplace';
import BrightpearlPendingOrdersAlertModal from './components/brightpearl_pending_alert';
import $ from "jquery";
class Insights extends Component{
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            isDownLoading:true,
            chartData:{},

            selectedPeriod:"Today",
            lineChartLabels:[],
            shippedOrder:[],
            dispatchedOrder:[],
            selectedOrders:[],
            submittedOrders:[],
            pendingOrder:[],

            theBay:0,
            WalMartCanada:0,
            BestBuy:0,
            StaplesExchange:0,
            StaplesAdvantage:0,
            BestBuyMarketPlace:0,

            //Brightpearl pending orders
            pendingOrdersData:[],
            dispatchedOrdersData:[],
            notificationEmail:undefined

        }
    }
    //countWords is a OBJECT {}!!!
     countWords=(arr)=> {
        return arr.reduce((wordCount, currentWord)=>{
            if(typeof wordCount[currentWord] !== "undefined"){
                wordCount[currentWord]++;
                return wordCount;
            } else {
                wordCount[currentWord]=1;
                return wordCount;
            }
        }, {});
    };
    getChartData(){

        const dates= this.props.newOrders.map(i=>{
           return i.OrderHeader.OrderDate.slice(0,10);
        });
        const resultSet = this.countWords(dates);
        const label=Object.keys(resultSet);
        const data = [];
        for(let x in resultSet){
            data.push(resultSet[x]);
        }
        this.setState({
            chartData:{
                labels: label,
                datasets:[
                    {
                        label:'Total Order Numbers',
                        data:data,
                        backgroundColor:[
                            'rgba(255, 40, 100, 0.6)',
                            'rgba(54, 162, 235, 0.6)',
                            'rgba(255, 206, 86, 0.6)',
                            'rgba(75, 192, 192, 0.6)',
                            'rgba(153, 102, 255, 0.6)',
                            'rgba(255, 159, 64, 0.6)',
                            'rgba(255, 40, 10, 0.6)'
                        ]
                    }
                ]
            },
            options: {
                title:{
                    display:true,
                    text:'New Orders Summary',
                    fontSize:30,
                    padding:30
                },
                legend:{
                    display:true,
                    position:'bottom',
                    labels:{
                        fontSize:20
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            min: 0,
                            suggestedMax: 10,
                            stepSize: 1
                        }
                    }]
                }
            }
        })
    }
    getData = async()=>{
        const todayDate = new Date().toISOString().slice(0,10);
        const sftpOrders = await getSubmittedOrdersDetail();
        const bestBuyMarketplaceOrders = toInsightsModelMapper(await getAllOrders());
        const submittedOrders = [...sftpOrders,...bestBuyMarketplaceOrders];
        let lineChartLabels,shippedOrder,dispatchedOrder,pendingOrder;
        let theBay = 0;
        let  WalMartCanada = 0;
        let  BestBuy = 0;
        let  StaplesExchange = 0;
        let  StaplesAdvantage = 0;
        let BestBuyMarketPlace = 0;
        const todayOrders = submittedOrders.filter(o=>(o.LastUpdated!==undefined && o.LastUpdated.includes(todayDate))|| o.FulfillmentStatus === "Received");
        const pendingOrders = submittedOrders.filter(o=>o.LastUpdated === undefined && o.TrackingNumber === "NULL" && o.FulfillmentStatus === "NULL");
        lineChartLabels = this.createArray(0,24,false);
        shippedOrder = this.createArray(0,24,true);
        dispatchedOrder =this.createArray(0,24,true);
        pendingOrder = this.createArray(0,24,true);
        todayOrders.forEach((o)=>{
            const tax = getTaxRate(o.TaxState);
            let hour = o.LastUpdated.substring(11, 13);
            if(hour === "00"){
                hour = new Date().toISOString().substring(11, 13);
            }
            const hourIndex = parseInt(hour) - 1 - 5;
            if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                shippedOrder[hourIndex] += 1;
            }else{
                dispatchedOrder[hourIndex] += 1;
            } if(pendingOrders.length > 0){
                pendingOrder[8] = pendingOrders.length;
            }
            switch(o.MerchantName){
                case "Walmart Canada":
                    WalMartCanada += o.Amount * (1+tax);
                    break;
                case "Hudson's Bay Company":
                    theBay += o.Amount * (1+tax);
                    break;
                case "Staples.ca":
                    StaplesExchange += o.Amount * (1+tax);
                    break;
                case "StaplesAdvantage CAN":
                    StaplesAdvantage += o.Amount * (1+tax);
                    break;
                case "Bestbuy Marketplace":
                    BestBuyMarketPlace += o.Amount;
                    break;
                case "Best Buy Canada":
                    BestBuy += o.Amount * (1+tax);
                    break;
                default:
            }
        });
        if(this._mounted){
            this.setState({
                isDownLoading:false,
                lineChartLabels,
                shippedOrder,
                dispatchedOrder,
                submittedOrders,
                pendingOrder,

                WalMartCanada:parseInt(WalMartCanada),
                theBay:parseInt(theBay),
                StaplesExchange:parseInt(StaplesExchange),
                StaplesAdvantage:parseInt(StaplesAdvantage),
                BestBuy:parseInt(BestBuy),
                BestBuyMarketPlace:parseInt(BestBuyMarketPlace)
            });
        }
    };

    checkBrightpealOrders=async()=>{
        const headers = await getBPheader();
        const ebayOrders = await checkOrdersByStatusID(22,headers);
        const ashlinLeatherOrders = await checkOrdersByStatusID(40,headers);
        const amazonASHLINFulfillOrders  = await checkOrdersByStatusID(23,headers);
        const amazonSellerFulfilledOrders = await checkOrdersByStatusID(38,headers);
        const toUpdateOrders = [...ebayOrders,...ashlinLeatherOrders,...amazonASHLINFulfillOrders, ...amazonSellerFulfilledOrders];
        const toUpdateRes =  await toUpdateOrders.map(async o=>{
            const message = o.orderStatusId === 22?"Update Ebay Order Status to Manual 3PL Order Ready for Dispatch":"Update Amazon Seller fulfill Order Status to Manual 3PL Order Ready for Dispatch";
            return  await updateOrderStatus(o.orderId,32,message,headers);

        });
        Promise.all(toUpdateRes).then(async()=>{
            const pendingOrders =await getOrdersByStatusId(32);
            const notificationEmail = await getEmailConfigurationById('E0042CF1-1C2F-46DA-ACD3-5E89819225ED');
            const pendingOrdersData = pendingOrders.map(o=>{
                let channel = channelsMapping.find(c=>c.id===o.assignment.current.channelId);
                channel = channel !==undefined? channel.name:"";
                return{
                    "orderId":o.id,
                    "channel":channel,
                    "ref":o.reference,
                    "createdDate":o.createdOn.substring(0,10)
                }
            });
            const dispatchedOrders = await getOrdersByStatusId(44);
            const dispatchedOrdersData = dispatchedOrders.map(o=>{
                let channel = channelsMapping.find(c=>c.id===o.assignment.current.channelId);
                channel = channel !==undefined? channel.name:"";
                return{
                    "orderId":o.id,
                    "channel":channel,
                    "ref":o.reference,
                    "createdDate":o.createdOn.substring(0,10)
                }
            });
            this.setState({
                pendingOrdersData,
                dispatchedOrdersData,
                notificationEmail
            },()=>{
                $('#brightpearlPendingOrdersAlertModal').modal('show');
            });
            //
            // console.log(pendingOrdersData);
            // console.log(dispatchedOrdersData);
        })
    };
    handleSendNotification = async()=>{
       const pendingOrdersData = this.state.pendingOrdersData;
       const dispatchedOrdersData = this.state.dispatchedOrdersData;
       const notificationEmail = this.state.notificationEmail;
        const recipients = notificationEmail.Recipient.split("\n").map(email=>email.trim()).filter(email=>email!=="");
        const subject = notificationEmail.Subject;
        let emailBody = this.state.notificationEmail.EmailBody;
        if(pendingOrdersData.length > 0){
            emailBody = emailBody.replace("[PendingOrderTitle]"," <h3>Pending Orders - Need to dispatch</h3>");
            let pendingOrderDetail = `<table style="border:1px solid black; border-collapse: collapse; "><thead> <tr><th style="border:1px solid black; border-collapse: collapse ">Order ID</th><th style="border:1px solid black; border-collapse: collapse ">Sales Channel</th><th style="border:1px solid black; border-collapse: collapse ">Channel Reference</th><th style="border:1px solid black; border-collapse: collapse ">Created Date</th></tr></thead><tbody>`;
            const pendingOrderRows = pendingOrdersData.map(p=>{
                return( `<tr>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.orderId}</td>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.channel}</td>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.ref}</td>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.createdDate}</td>
                </tr>`)
            });
            pendingOrderDetail += pendingOrderRows.join('') + ` </tbody></table>`;
            emailBody = emailBody.replace("[PendingOrders]",pendingOrderDetail);
        }else{
            emailBody = emailBody.replace("[PendingOrderTitle]","");
            emailBody = emailBody.replace("[PendingOrders]","");
        }

        if(dispatchedOrdersData.length > 0){
            emailBody = emailBody.replace("[DispatchedOrderTitle]"," <h3>Dispatched Orders - Need to track shipment</h3>");
            let dispatchedOrdersDetail = `<table style="border:1px solid black; border-collapse: collapse; "><thead> <tr><th style="border:1px solid black; border-collapse: collapse ">Order ID</th><th style="border:1px solid black; border-collapse: collapse ">Sales Channel</th><th style="border:1px solid black; border-collapse: collapse ">Channel Reference</th><th style="border:1px solid black; border-collapse: collapse ">Created Date</th></tr></thead><tbody>`;
            const dispatchedOrdersOrderRows = dispatchedOrdersData.map(p=>{
                return( `<tr>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.orderId}</td>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.channel}</td>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.ref}</td>
                    <td style="border:1px solid black; border-collapse: collapse; text-align: center ">${p.createdDate}</td>
                </tr>`)
            });
            dispatchedOrdersDetail += dispatchedOrdersOrderRows .join('') + ` </tbody></table>`;
            emailBody = emailBody.replace("[DispatchedOrders]",dispatchedOrdersDetail);
        }else{
            emailBody = emailBody.replace("[DispatchedOrderTitle]","");
            emailBody = emailBody.replace("[DispatchedOrders]","");
        }
        await sendPDFEmail(recipients,subject,emailBody,"","");
        alert("Email notification has sent to sales team!");
        $('#brightpearlPendingOrdersAlertModal').modal('hide');
    };
    componentDidMount(){
        this._mounted = true;
        this.getData();
        this.checkBrightpealOrders();
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    lineChartTimeFilter = (filter)=>{
        const months = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"];
        const obj =  _(this.state.submittedOrders)
            .groupBy(v => moment(v.LastUpdated).format(filter))
            .mapValues()
            .value();
        const arr = Object.entries(obj).map(([key, value]) => ({key,value}));
        return arr.sort(function(a,b){
            return months.indexOf(a.key) > months.indexOf(b.key);
        });
    };
    selectTimePeriod=(name)=>{
        const todayDate = new Date().toISOString().slice(0,10);
        const yesterdayDate = ( d => new Date(d.setDate(d.getDate()-1)) )(new Date).toISOString().slice(0,10);
        const last7DaysDatetime = (new Date(( d => new Date(d.setDate(d.getDate()-6)) )(new Date).toISOString().slice(0,10))).getTime();
        const last30DaysDatetime = (new Date(( d => new Date(d.setDate(d.getDate()-29)) )(new Date).toISOString().slice(0,10))).getTime();
        const thisMonth = new Date().toISOString().slice(0,7);
        const lastMonth = (new Date(( d => new Date(d.setMonth(d.getMonth()-1)) )(new Date).toISOString().slice(0,10))).toISOString().slice(0,7);
        let lineChartLabels,shippedOrder,dispatchedOrder,selectedOrders, pendingOrder;
        let theBay = 0;
        let  WalMartCanada = 0;
        let  BestBuy = 0;
        let  StaplesExchange = 0;
        let  StaplesAdvantage = 0;
        let BestBuyMarketPlace = 0;
        switch (name){
            case "Today":
                const todayOrders = this.state.submittedOrders.filter(o=>(o.LastUpdated!==undefined && o.LastUpdated.includes(todayDate))|| o.FulfillmentStatus === "Received");
                const pendingOrders = this.state.submittedOrders.filter(o=>o.LastUpdated === undefined && o.TrackingNumber === "NULL" && o.FulfillmentStatus === "NULL");
                selectedOrders = todayOrders;
                lineChartLabels = this.createArray(0,24,false);
                shippedOrder = this.createArray(0,24,true);
                dispatchedOrder =this.createArray(0,24,true);
                pendingOrder = this.createArray(0,24,true);
                todayOrders.forEach((o)=>{
                    let hour = o.LastUpdated.substring(11, 13);
                    if(hour === "00"){
                        hour = new Date().toISOString().substring(11, 13);
                    }
                    const hourIndex = parseInt(hour) - 1 - 5;
                    if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                        shippedOrder[hourIndex] += 1;
                    }else{
                        dispatchedOrder[hourIndex] += 1;
                    }
                    if(pendingOrders.length > 0){
                        pendingOrder[8] = pendingOrders.length;
                    }
                    const tax = getTaxRate(o.TaxState);
                    switch(o.MerchantName){
                        case "Walmart Canada":
                            WalMartCanada += o.Amount * (1+tax);
                            break;
                        case "Hudson's Bay Company":
                            theBay += o.Amount * (1+tax);
                            break;
                        case "Staples.ca":
                            StaplesExchange += o.Amount * (1+tax);
                            break;
                        case "StaplesAdvantage CAN":
                            StaplesAdvantage += o.Amount * (1+tax);
                            break;
                        case "Bestbuy Marketplace":
                            BestBuyMarketPlace += o.Amount;
                            break;
                        case "Best Buy Canada":
                            BestBuy += o.Amount * (1+tax);
                            break;
                        default:
                    }
                });
                break;
            case "Yesterday":
                const yesterdayOrders = this.state.submittedOrders.filter(o=>o.LastUpdated!==undefined && o.LastUpdated.includes(yesterdayDate));
                selectedOrders = yesterdayOrders;
                lineChartLabels = this.createArray(0,24,false);
                shippedOrder = this.createArray(0,24,true);
                dispatchedOrder =this.createArray(0,24,true);
                pendingOrder= [];
                yesterdayOrders.forEach((o)=>{
                    let hour = o.LastUpdated.substring(11, 13);
                    if(hour === "00"){
                        hour = new Date().toISOString().substring(11, 13);
                    }
                    const hourIndex = parseInt(hour) - 1 - 5;
                    if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                        shippedOrder[hourIndex] += 1;
                    }else{
                        dispatchedOrder[hourIndex] += 1;
                    }
                    const tax = getTaxRate(o.TaxState);
                    switch(o.MerchantName){
                        case "Walmart Canada":
                            WalMartCanada += o.Amount * (1+tax);
                            break;
                        case "Hudson's Bay Company":
                            theBay += o.Amount * (1+tax);
                            break;
                        case "Staples.ca":
                            StaplesExchange += o.Amount * (1+tax);
                            break;
                        case "StaplesAdvantage CAN":
                            StaplesAdvantage += o.Amount * (1+tax);
                            break;
                        case "Bestbuy Marketplace":
                            BestBuyMarketPlace += o.Amount;
                            break;
                        case "Best Buy Canada":
                            BestBuy += o.Amount * (1+tax);
                            break;
                        default:
                    }
                });
                break;
            case "Last 7 Days":
                const last7DaysOrders = this.state.submittedOrders.filter(o=>(o.LastUpdated!==undefined && (new Date (o.LastUpdated)) )> last7DaysDatetime || o.FulfillmentStatus ==='Received');
                selectedOrders = last7DaysOrders;
                lineChartLabels = this.createLastDateArray(todayDate,7) ;
                shippedOrder = this.createArray(0,7,true);
                dispatchedOrder =this.createArray(0,7,true);
                pendingOrder= [];
                last7DaysOrders.forEach((o)=>{
                    let date = o.LastUpdated.substring(0, 10);
                    if(date === "0001-01-01"){
                        date = new Date().toISOString().substring(0, 10);
                    }
                    const dateIndex = lineChartLabels.findIndex(li=>li === date);
                    if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                        shippedOrder[dateIndex ] += 1;
                    }else{
                        dispatchedOrder[dateIndex] += 1;
                    }
                    const tax = getTaxRate(o.TaxState);
                    switch(o.MerchantName){
                        case "Walmart Canada":
                            WalMartCanada += o.Amount * (1+tax);
                            break;
                        case "Hudson's Bay Company":
                            theBay += o.Amount * (1+tax);
                            break;
                        case "Staples.ca":
                            StaplesExchange += o.Amount * (1+tax);
                            break;
                        case "StaplesAdvantage CAN":
                            StaplesAdvantage += o.Amount * (1+tax);
                            break;
                        case "Bestbuy Marketplace":
                            BestBuyMarketPlace += o.Amount;
                            break;
                        case "Best Buy Canada":
                            BestBuy += o.Amount * (1+tax);
                            break;
                        default:
                    }
                });
                break;
            case "Last 30 Days":
                const last30DaysOrders = this.state.submittedOrders.filter(o=>(o.LastUpdated!==undefined && (new Date (o.LastUpdated))) > last30DaysDatetime || o.FulfillmentStatus ==='Received');
                selectedOrders = last30DaysOrders;
                lineChartLabels = this.createLastDateArray(todayDate,30) ;
                shippedOrder = this.createArray(0,30,true);
                dispatchedOrder =this.createArray(0,30,true);
                pendingOrder= [];
                last30DaysOrders.forEach((o)=>{
                    let date = o.LastUpdated.substring(0, 10);
                    if(date === "0001-01-01"){
                        date = new Date().toISOString().substring(0, 10);
                    }
                    const dateIndex = lineChartLabels.findIndex(li=>li === date);
                    if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                        shippedOrder[dateIndex ] += 1;
                    }else{
                        dispatchedOrder[dateIndex] += 1;
                    }
                    const tax = getTaxRate(o.TaxState);
                    switch(o.MerchantName){
                        case "Walmart Canada":
                            WalMartCanada += o.Amount * (1+tax);
                            break;
                        case "Hudson's Bay Company":
                            theBay += o.Amount * (1+tax);
                            break;
                        case "Staples.ca":
                            StaplesExchange += o.Amount * (1+tax);
                            break;
                        case "StaplesAdvantage CAN":
                            StaplesAdvantage += o.Amount * (1+tax);
                            break;
                        case "Bestbuy Marketplace":
                            BestBuyMarketPlace += o.Amount;
                            break;
                        case "Best Buy Canada":
                            BestBuy += o.Amount * (1+tax);
                            break;
                        default:
                    }
                });
                break;
            case "This Month":
                const thisMonthOrders = this.state.submittedOrders.filter(o=>(o.LastUpdated!==undefined && (new Date (o.LastUpdated)).toISOString().slice(0,7) === thisMonth) || o.FulfillmentStatus ==='Received');
                selectedOrders = thisMonthOrders;
                const numbersOfDaysOfThisMonth = this.getDaysInMonth(todayDate);
                lineChartLabels = this.createDateArray(todayDate,numbersOfDaysOfThisMonth) ;
                shippedOrder = this.createArray(0,numbersOfDaysOfThisMonth,true);
                dispatchedOrder =this.createArray(0,numbersOfDaysOfThisMonth,true);
                pendingOrder= [];
                thisMonthOrders.forEach((o)=>{
                    let date = o.LastUpdated.substring(0, 10);
                    if(date === "0001-01-01"){
                        date = new Date().toISOString().substring(0, 10);
                    }
                    const dateIndex = lineChartLabels.findIndex(li=>li === date);
                    if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                        shippedOrder[dateIndex ] += 1;
                    }else{
                        dispatchedOrder[dateIndex] += 1;
                    }
                    const tax = getTaxRate(o.TaxState);
                    switch(o.MerchantName){
                        case "Walmart Canada":
                            WalMartCanada += o.Amount * (1+tax);
                            break;
                        case "Hudson's Bay Company":
                            theBay += o.Amount * (1+tax);
                            break;
                        case "Staples.ca":
                            StaplesExchange += o.Amount * (1+tax);
                            break;
                        case "StaplesAdvantage CAN":
                            StaplesAdvantage += o.Amount * (1+tax);
                            break;
                        case "Bestbuy Marketplace":
                            BestBuyMarketPlace += o.Amount;
                            break;
                        case "Best Buy Canada":
                            BestBuy += o.Amount * (1+tax);
                            break;
                        default:
                    }
                });
                break;
            case "Last Month":
                const lastMonthOrders = this.state.submittedOrders.filter(o=> o.LastUpdated!==undefined &&(new Date (o.LastUpdated)).toISOString().slice(0,7) === lastMonth);
                selectedOrders = lastMonthOrders;
                const lastMonthDate = lastMonth+'-01';
                const numbersOfDaysOfLastMonth = this.getDaysInMonth(lastMonthDate);
                lineChartLabels = this.createDateArray(lastMonthDate,numbersOfDaysOfLastMonth) ;
                shippedOrder = this.createArray(0,numbersOfDaysOfLastMonth,true);
                dispatchedOrder =this.createArray(0,numbersOfDaysOfLastMonth,true);
                pendingOrder= [];
                lastMonthOrders.forEach((o)=>{
                    let date = o.LastUpdated.substring(0, 10);
                    const dateIndex = lineChartLabels.findIndex(li=>li === date);
                    if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                        shippedOrder[dateIndex ] += 1;
                    }else{
                        dispatchedOrder[dateIndex] += 1;
                    }
                    const tax = getTaxRate(o.TaxState);
                    switch(o.MerchantName){
                        case "Walmart Canada":
                            WalMartCanada += o.Amount * (1+tax);
                            break;
                        case "Hudson's Bay Company":
                            theBay += o.Amount * (1+tax);
                            break;
                        case "Staples.ca":
                            StaplesExchange += o.Amount * (1+tax);
                            break;
                        case "StaplesAdvantage CAN":
                            StaplesAdvantage += o.Amount * (1+tax);
                            break;
                        case "Bestbuy Marketplace":
                            BestBuyMarketPlace += o.Amount;
                            break;
                        case "Best Buy Canada":
                            BestBuy += o.Amount * (1+tax);
                            break;
                        default:
                    }
                });
                break;
            case "ALL Orders":
                const allOrders = this.state.submittedOrders.filter(o=> (o.LastUpdated!==undefined &&o.LastUpdated !== "0001-01-01T00:00:00" )|| o.FulfillmentStatus ==='Received');
                selectedOrders = allOrders;
                lineChartLabels = this.getMonthes(allOrders) ;
                shippedOrder = this.createArray(0,lineChartLabels.length,true);
                dispatchedOrder =this.createArray(0,lineChartLabels.length,true);
                pendingOrder= [];
                allOrders.forEach((o)=>{
                    let month = o.LastUpdated.substring(0, 7);
                    if(month  === "0001-01"){
                        month  = new Date().toISOString().substring(0,7);
                    }
                    const monthIndex = lineChartLabels.findIndex(li=>li === month);
                    if(o.FulfillmentStatus==="Closed"&& o.TrackingNumber!==""){
                        shippedOrder[monthIndex] += 1;
                    }else{
                        dispatchedOrder[monthIndex] += 1;
                    }
                    const tax = getTaxRate(o.TaxState);
                    switch(o.MerchantName){
                        case "Walmart Canada":
                            WalMartCanada += o.Amount * (1+tax);
                            break;
                        case "Hudson's Bay Company":
                            theBay += o.Amount * (1+tax);
                            break;
                        case "Staples.ca":
                            StaplesExchange += o.Amount * (1+tax);
                            break;
                        case "StaplesAdvantage CAN":
                            StaplesAdvantage += o.Amount * (1+tax);
                            break;
                        case "Bestbuy Marketplace":
                            BestBuyMarketPlace += o.Amount;
                            break;
                        case "Best Buy Canada":
                            BestBuy += o.Amount * (1+tax);
                            break;
                        default:
                    }
                });
                break;
            default:
        }
        this.setState({
            selectedPeriod:name,
            lineChartLabels,
            shippedOrder,
            dispatchedOrder,
            selectedOrders,
            pendingOrder,

            WalMartCanada:parseInt(WalMartCanada),
            theBay:parseInt(theBay),
            StaplesExchange:parseInt(StaplesExchange),
            StaplesAdvantage:parseInt(StaplesAdvantage),
            BestBuy:parseInt(BestBuy),
            BestBuyMarketPlace:parseInt(BestBuyMarketPlace)
        })
    };
    createArray=(startNum,endNum,initZero)=>{
        if(initZero){
            const arr = new Array(endNum-startNum);
            for (let i = startNum; i < endNum; i++) arr[i] = 0;
            return arr;
        }else{
            const arr = new Array(endNum-startNum);
            for (let i = startNum; i < endNum; i++) {
                arr[i] = ( i< 10) ? "0"+i.toString():i.toString();
            }
            return arr;
        }
    };
    createLastDateArray = (today,numbersOfDays)=>{
        const arr = new Array(numbersOfDays);
        for (let i = 0; i < numbersOfDays ; i++) {
                let d = new Date(today);
                d.setDate(d.getDate()-i);
                arr[numbersOfDays-i-1] = d.toISOString().slice(0,10);
        }
        return arr;
    };
    createDateArray=(date,numbersOfDays)=>{
        const time = date.split("-");
        const arr = new Array(numbersOfDays);
        for (let i = 0; i < numbersOfDays ; i++) {
            let day = i+1;
            if(day < 10){
                day = `0${day}`
            }
            arr[i] = `${time[0]}-${time[1]}-${day}`;
        }
        return arr;
    };
    getDaysInMonth = (date)=>{
        const time = date.split("-");
        return new Date(time[0], time[1], 0).getDate();
    };
    getMonthes = (orders)=>{
       const arr = [];
       orders.forEach(order=>{
           const month = order.LastUpdated.substring(0, 7)
           const index = arr.findIndex(a=>a===month);
           if(index === -1 && month !=='0001-01'){
               arr.push(month)
           }
       });
       return arr;
    };
    render(){
        const data = {
            labels: this.state.lineChartLabels ,
            datasets: [
                {
                    label: 'Shipped Order',
                    type:'line',
                    data: this.state.shippedOrder,
                    fill: false,          // Don't fill area under the line
                    borderColor: '#4BC0C0',// Line color
                    backgroundColor: '#4BC0C0'
                },
                {
                    label: 'In Process Order',
                    type:'line',
                    data: this.state.dispatchedOrder ,
                    fill: false,          // Don't fill area under the line
                    borderColor: '#ff9b3b',// Line color
                    backgroundColor: '#ff9b3b'
                },
                {
                    type: 'bar',
                    label: 'Pending Order',
                    data: this.state.pendingOrder ,
                    fill: false,          // Don't fill area under the line
                    borderColor: '#FF6384',// Line color
                    backgroundColor: '#FF6384'
                }
            ]
        };
        let sales = {
            "WalMart Ca":this.state.WalMartCanada,
            "Hudson's Bay":this.state.theBay,
            "Staples Exchange":this.state.StaplesExchange,
            "Staples Advantage":this.state.StaplesAdvantage,
            "BestBuy Ca":this.state.BestBuy,
            "BestBuy MarketPlace":this.state.BestBuyMarketPlace
        };
        sales = _.chain(sales)
            .map((val, key) => {
                return { name: key, count: val }
            })
            .sortBy('count')
            .reverse()
            .keyBy('name')
            .mapValues('count')
            .value();
        return(
            <div className='container-fluid row'>
                <div className={'col-3'} style={{paddingLeft: "0"}}>
                <MainSideBar/>
                </div>
                <div className={'col-9'}>
                    <br/>
                    {this.state.isDownLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Downloading Data...</div>}
                    <BrightpearlPendingOrdersAlertModal
                        pendingOrdersData = {this.state.pendingOrdersData}
                        dispatchedOrdersData = {this.state.dispatchedOrdersData}
                        sendNotification = {this.handleSendNotification}
                    />
                    <br/>
                    <div className={'row'}>
                        <div className={'col-6 text-secondary'}>
                            <h1>Overview</h1>
                        </div>
                        <div className={'col-6'}>
                            <div className="btn-group float-right" style={{width:'50%'}}>
                                <button type="button" className="btn  dropdown-toggle" data-toggle="dropdown"
                                        aria-haspopup="true" aria-expanded="false" style={{width:'100%'}}>
                                    {this.state.selectedPeriod}
                                </button>
                                <div className="dropdown-menu">
                                    <button className="dropdown-item" onClick={()=>this.selectTimePeriod("Today")} >Today</button>
                                    <button className="dropdown-item" onClick={()=>this.selectTimePeriod("Yesterday")}>Yesterday</button>
                                    <div className="dropdown-divider"> </div>
                                    <button className="dropdown-item" onClick={()=>this.selectTimePeriod("Last 7 Days")} >Last 7 Days</button>
                                    <button className="dropdown-item" onClick={()=>this.selectTimePeriod("Last 30 Days")}>Last 30 Days</button>
                                    <div className="dropdown-divider"> </div>
                                    <button className="dropdown-item" onClick={()=>this.selectTimePeriod("This Month")} >This Month</button>
                                    <button className="dropdown-item" onClick={()=>this.selectTimePeriod("Last Month")}>Last Month</button>
                                    <div className="dropdown-divider"> </div>
                                    <button className="dropdown-item" onClick={()=>this.selectTimePeriod("ALL Orders")}>ALL</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <OrderLineChart legendPosition={'bottom'} chartData={data} options={this.state.options}/>
                    <br/>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <h3 className={'text-center text-secondary'}>Sale by Store</h3>
                            <ChannelSalesChart sales={sales}/>
                        </div>
                        <div className={'col-6'}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Insights;