import React,{Component} from 'react';

class onlineCategory extends Component{
    constructor(props){
        super(props);
        this.state={
            selectedCategory:'',
            categories:'',
            loaded:false
        }
    }
    handleOnChange=(e)=>{
        const value=e.target.value;
        this.setState({
            selectedCategory:value
        })
    };
    handleAddCategory=(value)=>{
        let categories;
        if(!this.state.loaded && this.props.value!==''){
            categories = value+";" + this.state.categories;
        }else{
            categories = this.state.categories;
        }
        if(!categories.includes(this.state.selectedCategory)){
            categories += this.state.selectedCategory+';'
        }
        this.setState({
            categories,
            loaded:true
        },()=> this.props.handleCategory(this.props.name,this.state.categories));

    };
    handleDeleteCategory=(val)=>{
        let categories;
        if(!this.state.loaded && this.props.value!==''){
            categories = val+";" + this.state.categories;
        }else{
            categories = this.state.categories;
        }
        categories = categories.trim().split(';').slice(0, -1);
        const result = categories.findIndex(i=>i === this.state.selectedCategory);
        if(result!==-1){
            categories.splice(result,1);
            let value = categories.join(';');
            if(value !==''){
                value +=';';
            }
            //console.log(value);
            this.setState({
                categories: value,
                loaded:true
            },()=>this.props.handleCategory(this.props.name,this.state.categories));

        }
    };
    componentDidMount(){
        this.setState({
            categories :this.props.value
        });
    }
    render(){
        let inputValue='';
        if(this.props.value !==""){
           inputValue = this.props.value;
        }else{
            inputValue = this.state.categories
        }
        return(
            <div className={'row'}>
                <div className={'col-4'}>
                    <h4>{this.props.label}</h4>
                </div>
                <div className={'col-7'}>
                    <select style={{width:'48%'}} className={'col'} onChange={this.handleOnChange}>
                        <option value=''>-- select an option --</option>
                        {this.props.categories.map(i=>{
                            return(
                                <option value={i} key={i}>
                                    {i}
                                </option>
                            )
                        })}
                    </select>
                    <button type="button" className="btn-danger " style={{width:'75px',margin:'0 25px'}} onClick={()=>this.handleAddCategory(this.props.value)}>Add</button>
                    <button type="button" className="btn-success " style={{width:'75px'}} onClick={()=>this.handleDeleteCategory(this.props.value)}>Remove</button>
                    <textarea className={'col'} value={inputValue} readOnly>
                    </textarea>
                </div>
            </div>
        )
    }
}

export default onlineCategory;