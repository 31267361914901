import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {
    getBPheader,
    checkOrdersByStatusID,
    updateOrderStatus,
    getOrdersByStatusId,
    getUSCustomerFEINNumber,
    updateShipmentMethodAndDeliveryDate,
    shippimgMethodMapper,
    createGoodsOutNote,
    updateGoodsOutNote,
    searchGoodsOutNote, postShippedEvent, shippingMethods, channelsMapping
} from '../../Utils/brightpearl';
import PendingOrderDetailModal from './pendingOrderDetailModal';
import ReactTable from 'react-table';
import $ from "jquery";
import {
    SubmitOrderModel,
    sendOrderToLeanSupply,
    ENABLE_LEANSUPPLY,
    ENABLE_BRIGHTPEARL,
    getBlanceByWarehouseCode,
    currentDateTime, submittedOrdersURL, headers, proxyurl
} from "../../Utils/requestHelper";
import {
    getHtsCa,
    getHtsUs,
    getSkuWithHts,
    getDesignByCode,
    sendPDFEmail,
    getCompanyInfoById,
    getSignatureById,
    getEmailConfigurationById, postOrderRecords,
    getHtsDescription, getDesignsPacking,
    getBrightpearlChannelInfos, createAshlinManualOrder, createAshlinManualOrderItems
} from "../../Utils/ashlinDesign";
import { createChitChatsShipment, getChitChatsShipment } from "../../Utils/chitChats";
import axios from "axios/index";
class PendingOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingOrders: [],
            selected: undefined,
            selectedBackUp: undefined,
            crossDockBoxTotal: "1",
            isCrossDock: false,
            WarehouseCode: 14,
            Priority: "",
            useCustomizedCarrier: false,
            needChooseCarrier: false,

            ServiceType: "BESTPRICE",
            CarrierCode: "CANPAR",
            ServiceCode: { label: "Canpar Normal", value: "01" },
            Comment: "Please use Ashlin Canpar Account to ship",
            FEIN: "",
            ServiceCodeGroup: [
                { label: "Normal", value: "01" },
                { label: "Express", value: "05" }
            ],
            use3rdAccount: false,
            ChargeToAccount: '',
            canFulfill: false,
            registeredAddress: "",

            HtsCa: [],
            HtsUs: [],
            SkuWithHts: [],
            isLoading: true,
            attachBase64: undefined,
            EmailList: "ashlin@ashlinbpg.com;nancy.taccone@ashlinbpg.com;cstedlo@leansupplysolutions.com;shragoobar@leansupplysolutions.com;archit.sandesara@consulero.com",
            AshlinInfo: undefined,
            Signature: undefined,
            DesignsPacking: [],
            ProformaEmail: undefined,
            BrightpearlChannelInfos: []
        }
    }
    componentDidMount() {
        this.getPendingOrder();
        this.getAshlinHTS();
        this.getAshlinInfo();
    }
    getAshlinInfo = async () => {
        const AshlinInfo = await getCompanyInfoById('D8AE77CD-3551-4373-BE57-8057AF0F49F1');
        const Signature = await getSignatureById('8D1D8DBB-4805-4BE9-83C8-EEE441AAE7E5');
        const ProformaEmail = await getEmailConfigurationById('A1F51FC9-E333-4377-A06D-54959E050FD0');
        const BrightpearlChannelInfos = await getBrightpearlChannelInfos();
        const DesignsPacking = await getDesignsPacking();
        const EmailList = ProformaEmail.Recipient;
        this.setState({
            AshlinInfo,
            Signature,
            ProformaEmail,
            EmailList,
            DesignsPacking,
            BrightpearlChannelInfos
        })
    };
    getAshlinHTS = async () => {
        const HtsCa = await getHtsCa();
        const HtsUs = await getHtsUs();
        const SkuWithHts = await getSkuWithHts();

        this.setState({
            HtsCa,
            HtsUs,
            SkuWithHts
        }, () => {
            setTimeout(
                this.setState({
                    isLoading: false
                })
                , 10000);
        });
    };
    getPendingOrder = async () => {
        const headers = await getBPheader();
        const ebayOrders = await checkOrdersByStatusID(22, headers);
        //const amazonOrders = await checkOrdersByStatusID(38,headers);
        const ashlinLeatherOrders = await checkOrdersByStatusID(40, headers);
        const amazonASHLINFulfillOrders = await checkOrdersByStatusID(23, headers);
        const amazonSellerFulfilledOrders = await checkOrdersByStatusID(38, headers);
        const toUpdateOrders = [...ebayOrders, ...ashlinLeatherOrders, ...amazonASHLINFulfillOrders, ...amazonSellerFulfilledOrders];

        const toUpdateRes = await toUpdateOrders.map(async o => {
            const message = o.orderStatusId === 22 ? "Update Ebay Order Status to Manual 3PL Order Ready for Dispatch" : "Update Amazon Seller fulfill Order Status to Manual 3PL Order Ready for Dispatch";
            return await updateOrderStatus(o.orderId, 32, message, headers);

        });

        Promise.all(toUpdateRes).then(async () => {
            const pendingOrders = await getOrdersByStatusId(32);
            //console.log(pendingOrders);
            this.setState({
                pendingOrders
            })
        })
    };
    handleOrderDetail = () => {
        $('#PendingOrderDetailModal').modal({ backdrop: 'static', keyboard: false });
    };
    handleOnchange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        this.setState({
            [name]: value
        })
    };
    handleIsCrossDockOnchange = (e) => {
        const value = e.target.checked;
        if (value) {
            const selected = this.state.selected;
            const backUpOrderRows = this.state.selectedBackUp.orderRows;
            let Comment = this.state.Comment;
            Comment += " ***Box # *** ";
            backUpOrderRows.forEach(row => {
                Comment += `,ship to Qty ${parseInt(row.quantity.magnitude)} of ${row.productSku} `;
            });
            //console.log(backUpOrderRows);
            this.setState({
                isCrossDock: value,
                selected,
                Comment
            });
        } else {
            this.setState({
                isCrossDock: value,
                Comment: ''
            });
        }

    };
    handleChangeCarrier = (e, value) => {
        e.preventDefault();
        if (value) {
            if (this.state.selected.parties.delivery.countryIsoCode === "US") {
                this.setState({
                    useCustomizedCarrier: value,
                    ServiceType: "BESTPRICE",
                    CarrierCode: "FEDEX",
                    ServiceCode: { label: "FEDEX_GROUND", value: "FEDEX_GROUND" },
                    ServiceCodeGroup: [
                        { label: "FEDEX_GROUND", value: "FEDEX_GROUND" },
                        { label: "FEDEX_2DAY", value: "FEDEX_2DAY" },
                        { label: "FEDEX_OVERNITE", value: "FEDEX_OVERNITE" }
                    ],
                    use3rdAccount: false,
                    ChargeToAccount: '',
                    registeredAddress: '',
                    Comment: '',
                    attachBase64: undefined
                })
            } else {
                this.setState({
                    useCustomizedCarrier: value,
                    ServiceType: "BESTPRICE",
                    CarrierCode: "CANPAR",
                    ServiceCode: { label: "Canpar Normal", value: "01" },
                    ServiceCodeGroup: [
                        { label: "Normal", value: "01" },
                        { label: "Express", value: "05" }
                    ],
                    use3rdAccount: false,
                    ChargeToAccount: '',
                    registeredAddress: '',
                    Comment: '',
                    attachBase64: undefined
                })
            }
        } else {
            if (this.state.selected.parties.delivery.countryIsoCode === "US") {
                this.setState({
                    useCustomizedCarrier: value,
                    needChooseCarrier: false,
                    use3rdAccount: false,
                    ChargeToAccount: '',
                    registeredAddress: '',
                    attachBase64: undefined,
                    Comment: "Please use Ashlin FEDEX Account to ship"
                })
            } else {
                this.setState({
                    useCustomizedCarrier: value,
                    needChooseCarrier: false,
                    use3rdAccount: false,
                    ChargeToAccount: '',
                    registeredAddress: '',
                    attachBase64: undefined,
                    Comment: "Please use Ashlin Canpar Account to ship"
                })
            }

        }
    };
    handleShipmentTypeOnchange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        if (value === "CUSTOMIZECARRIER") {
            this.setState({
                [name]: value,
                needChooseCarrier: true,
                use3rdAccount: false,
                ChargeToAccount: '',
                registeredAddress: '',
                attachBase64: undefined,
            })
        } else {
            this.setState({
                [name]: value,
                needChooseCarrier: false,
                use3rdAccount: false,
                ChargeToAccount: '',
                registeredAddress: '',
                attachBase64: undefined,
            })
        }

    };

    handleCarrierCodeOnchange = (e) => {
        const value = e.target.value;
        let ServiceCodeGroup = [];
        let ServiceCode = {};
        if (value === "CANP") {
            ServiceCodeGroup = [
                { label: "Canada Post DOM.EP", value: "DOM.EP" },
                { label: "Canada Post DOM.XP", value: "DOM.XP" },
                { label: "Canada Post USA.EP", value: "USA.EP" }
            ];
            ServiceCode = { label: "Canada Post DOM.EP", value: "DOM.EP" };
        } else if (value === "UPS") {
            ServiceCodeGroup = [
                { label: "UPS Ground", value: "11" },
                { label: "UPS_2DAY", value: "08" },
                { label: "UPS_1DAY", value: "03" },
            ];
            ServiceCode = { label: "UPS Groupd", value: "11" };
        } else if (value === "FEDEX") {
            ServiceCodeGroup = [
                { label: "FEDEX_GROUND", value: "FEDEX_GROUND" },
                { label: "FEDEX_2DAY", value: "FEDEX_2DAY" },
                { label: "FEDEX_OVERNITE", value: "FEDEX_OVERNITE" }
            ];
            ServiceCode = { label: "FEDEX_GROUND", value: "FEDEX_GROUND" };
        } else if (value === "PUROLATOR") {
            ServiceCodeGroup = [
                { label: "PUROLATOR_GROUND", value: "PRLA" },
                { label: "PUROLATOR_EXPRESS", value: "PRLA_PM" },
            ];
            ServiceCode = { label: "PUROLATOR_GROUND", value: "PRLA" };
        } else if (value === "CHITCHATS") {
            ServiceCodeGroup = [
                { label: "Canpar Normal", value: "01" },
                { label: "Canpar Express", value: "05" }
            ];
            ServiceCode = { label: "Canpar Normal", value: "01" };
        } else {
            ServiceCodeGroup = [
                { label: "Canpar Normal", value: "01" },
                { label: "Canpar Express", value: "05" }
            ];
            ServiceCode = { label: "Canpar Normal", value: "01" };
        }
        this.setState({
            CarrierCode: value,
            ServiceCodeGroup,
            ServiceCode
        })
    };
    handleCarrierServiceOnchange = (e) => {
        this.setState({
            ServiceCode: JSON.parse(e.target.value)
        })
    };
    handleSendOrder = async (e) => {
        e.preventDefault();
        let AddtioanalComment = "";
        const bpOrder = this.state.selected;
        if (bpOrder.parties.delivery.countryIsoCode === "US" && this.state.FEIN !== undefined) {
            AddtioanalComment += `* FEIN# ${this.state.FEIN} *`;
        }
        let orderRows = bpOrder.orderRows;
        //console.log(orderRows);
        const OrderDetailTotal = orderRows.map((ol, index) => {
            if (ol.productSku === undefined)
                return;
            return {
                "OrderLine": (++index).toString(),
                "ItemCode": ol.productSku,
                "ItemQty": parseInt(ol.quantity.magnitude),
                "ItemName": ol.productName,
                "ItemDescription": ol.productName,
                "uofm": "EACH",
                "per_pack": "1"
            }

        });
        const ProductsTotal = orderRows.map(row => {
            if (row.productSku === undefined)
                return;
            return {
                "productId": row.productId,
                "salesOrderRowId": parseInt(row.rowId),
                "quantity": parseInt(row.quantity.magnitude)
            }
        });

        const OrderDetail = OrderDetailTotal.filter(od => od !== undefined);
        const Products = ProductsTotal.filter(od => od !== undefined);
        let OrderItemMessage = "";
        let PackingInfo = " ***Preferred Packaging: BUBBLE PACK***";
        OrderDetail.forEach(o => {
            OrderItemMessage += o.ItemCode + '\n';
            const designCode = o.ItemCode.split('-')[0];
            const isCarton = this.state.DesignsPacking.find(d => d.DesignServiceCode === designCode);
            if (isCarton !== undefined) {
                PackingInfo = " ***Preferred Packaging: CARTON***";
            }
        });
        if (this.state.use3rdAccount && (this.state.CarrierCode === "FEDEX" || this.state.CarrierCode === "UPS") && (this.state.ChargeToAccount === "" || this.state.registeredAddress === "")) {
            if (this.state.ChargeToAccount === "") {
                alert("Need enter charge to account.");
            } else {
                alert("Need enter charge to account billing address.")
            }
        } else if (this.state.use3rdAccount && this.state.CarrierCode === "UPS" && (this.state.ChargeToAccount.length !== 6 || !this.state.ChargeToAccount.match(/^[0-9a-zA-Z]+$/))) {
            alert("UPS account number must be 6 alphanumeric numbers")
        } else if (this.state.use3rdAccount && this.state.CarrierCode === "FEDEX" && ((this.state.ChargeToAccount.length !== 7 && this.state.ChargeToAccount.length !== 9) || !this.state.ChargeToAccount.match(/^[0-9]+$/))) {
            alert("UPS account number must be 7 or 9 numbers")
        } else if (this.state.use3rdAccount && this.state.CarrierCode === "PUROLATOR" && ((this.state.ChargeToAccount.length !== 6 && this.state.ChargeToAccount.length !== 9) || !this.state.ChargeToAccount.match(/^[0-9]+$/))) {
            alert("UPS account number must be 6 or 9 numbers")
        } else {
            let confirmBox = window.confirm(`Do you confirm to send this order? \n\nShipping Carrier: *** ${this.state.CarrierCode} *** \n\nShipping Item:\n${OrderItemMessage}`);
            if (confirmBox) {
                let OrderDelivery, ChargeToAccount;

                if (this.state.CarrierCode === "CANPAR" || this.state.CarrierCode === "CHITCHATS") {
                    ChargeToAccount = "42074982"
                } else if (this.state.CarrierCode === "FEDEX") {
                    ChargeToAccount = "242175506";
                } else if (this.state.CarrierCode === "CANP") {
                    ChargeToAccount = "3950328";
                } else if (this.state.CarrierCode === "PUROLATOR") {
                    ChargeToAccount = "2914081";
                } else if (this.state.CarrierCode === "UPS") {
                    ChargeToAccount = "";
                } else {
                    ChargeToAccount = "";
                }
                if (this.state.useCustomizedCarrier && this.state.needChooseCarrier && this.state.use3rdAccount) {
                    //use others account
                    OrderDelivery = {
                        "CarrierCode": this.state.CarrierCode,
                        "ServiceCode": this.state.ServiceCode.value,
                        "FreightCharges": "3RDPARTY",
                        "ChargeToAccount": this.state.ChargeToAccount
                    };
                    //Add registeredAddress:'',
                    AddtioanalComment = ` * Please ship by ${this.state.CarrierCode} Account# ${this.state.ChargeToAccount}, Registered Address-${this.state.registeredAddress} `;
                } else if (this.state.useCustomizedCarrier && this.state.needChooseCarrier) {
                    //Use ashlin account
                    if (this.state.CarrierCode === "CHITCHATS") {
                        OrderDelivery = {
                            "CarrierCode": "CANPAR",
                            "ServiceCode": this.state.ServiceCode.value,
                            "FreightCharges": "3RDPARTY",
                            ChargeToAccount
                        }
                    } else {
                        OrderDelivery = {
                            "CarrierCode": this.state.CarrierCode,
                            "ServiceCode": this.state.ServiceCode.value,
                            "FreightCharges": "3RDPARTY",
                            ChargeToAccount
                        }
                    }
                } else if (this.state.useCustomizedCarrier) {
                    OrderDelivery = {
                        "ServiceType": this.state.ServiceType
                    }
                } else {
                    //use ashlin default account
                    OrderDelivery = {
                        "CarrierCode": this.state.CarrierCode === "CHITCHATS" ? "CANPAR" : this.state.CarrierCode,
                        "ServiceCode": this.state.ServiceCode.value,
                        "FreightCharges": "3RDPARTY",
                        ChargeToAccount
                    }
                }


                let ShipTo;
                if (this.state.CarrierCode === "CHITCHATS") {
                    ShipTo = {
                        "Name": "CHITCHATS EXPRESS INC",
                        "AddressLine1": "1735 Bayly St. Unit 7D",
                        "AddressLine2": "SHIPPING/RECEIVING - USPS SHIPMENTS",
                        "AddressLine3": "#ITEMS FOR USPS",
                        "City": "Pickering",
                        "State": "ON",
                        "CountryCode": "CA",
                        "ContactName": "CHITCHATS EXPRESS",
                        "ContactPhone": "905-492-3607",
                        "ContactPhoneExt": "",
                        "Zip": "L1W 3G7"
                    }
                } else {
                    ShipTo = {
                        "Name": bpOrder.parties.delivery.companyName,
                        "AddressLine1": bpOrder.parties.delivery.addressLine1,
                        "AddressLine2": bpOrder.parties.delivery.addressLine2,
                        "AddressLine3": "",
                        "City": bpOrder.parties.delivery.addressLine3.replace(',', ''),
                        "State": bpOrder.parties.delivery.addressLine4,
                        "CountryCode": bpOrder.parties.delivery.countryIsoCode,
                        "ContactName": bpOrder.parties.delivery.addressFullName,
                        "ContactPhone": bpOrder.parties.delivery.telephone,
                        "ContactPhoneExt": "",
                        "Zip": bpOrder.parties.delivery.postalCode
                    }
                }

                const order = {
                    "OrderHeader": {
                        "OrderNumber": `Ashlin-${bpOrder.id}`,
                        "EndCustomerOrderNo": bpOrder.parties.customer.contactId,
                        "ThirdPartyOrderNo": bpOrder.id,
                        "OrderDateCode": "CR",
                        "OrderDate": bpOrder.createdOn.slice(0, 19).replace("T", " "),
                        "Comment": this.state.Comment + AddtioanalComment + PackingInfo,
                        "ShipTo": ShipTo,
                        "ShipFrom": {
                            "Name": "WAREHOUSE"
                        },
                        "WarehouseCode": this.state.WarehouseCode,
                        "OrderStatus": "NEW",
                        "OrderType": "NO",
                        "Priority": this.state.Priority,
                        "Version": ""
                    },
                    "OrderDetail": OrderDetail,
                    "OrderDelivery": OrderDelivery
                };

                const data = SubmitOrderModel(order);
                if (this.state.attachBase64 !== undefined) {
                    data.OrderAttachment = {
                        "FileType": "PDF",
                        "FileName": "PROFORMA  INVOICE",
                        "File": this.state.attachBase64.split(',')[1]
                    }
                }

                let d = new Date();
                const date = d.toISOString().slice(0, 10);

                //console.log(data);
                //console.log(this.state)
                let leanSupplyChannelId;
                const channel = this.state.BrightpearlChannelInfos.find(c => c.ChannelId === bpOrder.assignment.current.channelId);
                //console.log(channel)
                if (channel) {
                    leanSupplyChannelId = channel.AshlinChannelId;
                } else {
                    leanSupplyChannelId = 'C5C64492-786C-4F52-B89B-35A70328B225';
                }

                //send order through Chitchats
                if (this.state.CarrierCode === "CHITCHATS") {
                    let shippingInfo = {
                        ShippableDepthCm: 0,
                        ShippableHeightCm: 0,
                        ShippableWidthCm: 0,
                        ShippableWeightGrams: 0,
                        ShippingDescription: ""
                    };
                    const res = await orderRows.map(async row => {
                        const designCode = (row.productSku.split('-'))[0];
                        const ashlinDesign = await getDesignByCode(designCode);
                        shippingInfo.ShippableDepthCm = shippingInfo.ShippableDepthCm < ashlinDesign.ShippableDepthCm ? parseInt(ashlinDesign.ShippableDepthCm) : shippingInfo.ShippableDepthCm;
                        shippingInfo.ShippableHeightCm = shippingInfo.ShippableHeightCm < ashlinDesign.ShippableHeightCm ? parseInt(ashlinDesign.ShippableHeightCm) : shippingInfo.ShippableHeightCm;
                        shippingInfo.ShippableWidthCm = shippingInfo.ShippableWidthCm < ashlinDesign.ShippableWidthCm ? parseInt(ashlinDesign.ShippableWidthCm) : shippingInfo.ShippableWidthCm;
                        shippingInfo.ShippableWeightGrams += parseInt((parseInt(ashlinDesign.ShippableWeightGrams) * parseInt(row.quantity.magnitude)).toString());
                        shippingInfo.ShippingDescription += `${parseInt(row.quantity.magnitude)}, ${ashlinDesign.GeneralName}  `;
                    });
                    let searchCode = bpOrder.parties.delivery.postalCode.includes('-') ? bpOrder.parties.delivery.postalCode.split('-')[0] : bpOrder.parties.delivery.postalCode;

                    const searchAddress = (await axios.get(proxyurl + `https://geocoder.ca/?postal=${searchCode}&geoit=xml&json=1&standard=1&showcountry=1&moreinfo=1`)).data;
                    const provinceCode = searchAddress.standard.prov;

                    //console.log(shippingInfo);
                    Promise.all([...res, searchAddress]).then(async () => {
                        const chitchatsShipmentId = await createChitChatsShipment(bpOrder, shippingInfo, provinceCode);
                        //Here need to check if returned the tracking #
                        //console.log(chitchatsShipmentId );
                        const chitchatsShipment = await getChitChatsShipment(chitchatsShipmentId);
                        //console.log(chitchatsShipment );
                        const headers = await getBPheader();
                        //Create Goods-Out Note
                        const goodsOutNote = {
                            "warehouses": [
                                {
                                    "releaseDate": date,
                                    "warehouseId": 5,
                                    "transfer": false,
                                    "products": Products

                                }
                            ],
                            "priority": false,
                            "shippingMethodId": 5,
                            "labelUri": ""
                        };
                        //console.log(goodsOutNote);
                        const goodsOutNoteRes = await createGoodsOutNote(bpOrder.id, goodsOutNote, headers);


                        const shippingData = [
                            {
                                "op": "replace",
                                "path": "/delivery/shippingMethodId",
                                "value": 5
                            }
                        ];
                        const updateRes = await updateShipmentMethodAndDeliveryDate(bpOrder.id, shippingData, headers);
                        //get tracking #

                        //send to lean supply


                        //////////////////////////////////////////////////////

                        Promise.all([chitchatsShipment, goodsOutNoteRes, updateRes]).then(async () => {

                            // const shipmentDetail = await getChitChatsShipment(chitchatsShipment.id);
                            // console.log(shipmentDetail);
                            const trackingNum = chitchatsShipment.carrier_tracking_code;//shipmentDetail.carrier_tracking_code,
                            const note = `USPS label created, USPS Tracking URL: ${chitchatsShipment.tracking_url}`;
                            //console.log(note);
                            //Update Brightpearl status
                            const brightPearlRes = await updateOrderStatus(bpOrder.id, 20, note, headers);
                            const goodsOutNoteId = await searchGoodsOutNote(bpOrder.id, headers);
                            if (goodsOutNoteId !== null) {
                                //update goods out tracking reference
                                const newNote = {
                                    "priority": false,
                                    "shipping": {
                                        "shippingMethodId": 5,
                                        "reference": trackingNum,
                                    },
                                    "labelUri": ""
                                };
                                //console.log(newNote);
                                await updateGoodsOutNote(goodsOutNoteId, newNote, headers);
                                //update goods out event to shipped

                                //This event can be handled by allocated and invoice on BP
                                await postShippedEvent(goodsOutNoteId, headers);

                            }
                            //Create new record in the manual order table
                            const ashlinManualOrder = {
                                "SaleChannelId": leanSupplyChannelId.toUpperCase(),
                                "SaleChannelOrderId": bpOrder.reference !== undefined ? bpOrder.reference : "",
                                "BrightpearlOrderId": bpOrder.id,
                                "Warehouse": "LS14 LeanSupply 3PL",
                                "ShippingCarrier": "USPS",
                                "ShippingService": "GROUND",
                                "ShippingLabelId": chitchatsShipment.id,
                                "TrackingNumber": trackingNum,
                                "Status": "USPSCreated",
                                "LabelURL": `https://chitchats.com/labels/shipments/${chitchatsShipment.id}.png`
                            };
                            const newAshlinManualOrder = await createAshlinManualOrder(ashlinManualOrder);

                            const chitChatsOrderItems = OrderDetail.map((ol) => {
                                return {
                                    "OrderLine": ol.OrderLine,
                                    "ItemCode": ol.ItemCode,
                                    "ItemQty": ol.ItemQty,
                                    "ItemName": ol.ItemName,
                                    "ItemDescription": ol.ItemDescription,
                                    "AshlinManualOrderId": newAshlinManualOrder.Id
                                }
                            });
                            await createAshlinManualOrderItems(chitChatsOrderItems);


                            const processDate = currentDateTime();

                            //HERE IS A BP Order, SO we can get the total directly
                            let SalesChannel;
                            if (bpOrder.assignment.current.channelId !== undefined) {
                                const sc = channelsMapping.find(s => s.id === bpOrder.assignment.current.channelId);
                                if (sc) {
                                    SalesChannel = sc.name
                                } else {
                                    SalesChannel = "Unknown Sales Channel"
                                }
                            } else {
                                SalesChannel = "Unknown Sales Channel"
                            }
                            // const ashlinOrderRecord = [{
                            //         "BrightpearlOrderId": bpOrder.id,
                            //         "Carrier": "USPS",
                            //         "Currency": bpOrder.currency.orderCurrencyCode,
                            //         "ProcessedDate": processDate,
                            //         "SaleChannelName": SalesChannel,
                            //         "SaleChannelOrderId": bpOrder.reference,
                            //         "ShippedDate": "",
                            //         "ShippedFrom": "Lean Supply",
                            //         "Status": "USPS Label Created",
                            //         "TotalValue": bpOrder.totalValue.total,
                            //         "TrackingNumber": trackingNum
                            //     }
                            //
                            // ];
                            //
                            // console.log(ashlinOrderRecord);
                            // const orderRecordRes = await postOrderRecords(ashlinOrderRecord);
                            //console.log(orderRecordRes);
                            this.getPendingOrder();
                            $('#PendingOrderDetailModal').modal('hide');
                        });

                    })
                } else {
                    //send to lean supply
                    let orderHandle = "";
                    if (ENABLE_LEANSUPPLY && ENABLE_BRIGHTPEARL) {
                        if (this.state.isCrossDock) {
                            data.OrderDetail = [
                                {
                                    "OrderLine": "1",
                                    "ItemCode": "CROSS DOCK BOX",
                                    "ItemQty": this.state.crossDockBoxTotal,
                                    "ItemName": "Cross Dock Box",
                                    "ItemDescription": "Cross Dock Box",
                                    "uofm": "EACH",
                                    "per_pack": "1"
                                }
                            ];
                        }
                        //console.log(data);
                        const leanSupplyRes = await sendOrderToLeanSupply(data);
                        //console.log(leanSupplyRes);
                        orderHandle = leanSupplyRes.data.OrderConfirm.OrderHandle;
                        //update status on Brightpearl

                        const note = `Order Sent to Lean Supply, Order ID - Ashlin-${bpOrder.id}`;
                        //console.log(note);
                        const headers = await getBPheader();
                        const brightPearlRes = await updateOrderStatus(bpOrder.id, 44, note, headers);
                        const shipment = shippimgMethodMapper(data.OrderDelivery);

                        const shippingData = [
                            {
                                "op": "replace",
                                "path": "/delivery/shippingMethodId",
                                "value": shipment
                            }
                        ];
                        const updateRes = await updateShipmentMethodAndDeliveryDate(bpOrder.id, shippingData, headers);


                        //Create Goods-Out Note
                        const goodsOutNote = {
                            "warehouses": [
                                {
                                    "releaseDate": date,
                                    "warehouseId": 5,
                                    "transfer": false,
                                    "products": Products

                                }
                            ],
                            "priority": false,
                            "shippingMethodId": shipment,
                            "labelUri": ""
                        };
                        //console.log(goodsOutNote);
                        const goodsOutNoteRes = await createGoodsOutNote(bpOrder.id, goodsOutNote, headers);



                        // //////////////////////////////////////////////////////
                        const processDate = currentDateTime();

                        //HERE IS A BP Order, SO we can get the total directly
                        const leanSupplyOrder = [{
                            "ChannelId": leanSupplyChannelId,
                            "ChannelOrderId": bpOrder.reference !== undefined ? bpOrder.reference : "",
                            "ERPOrderId": bpOrder.id,
                            "FulfillmentStatus": "Received",
                            "OrderHandle": orderHandle,
                            "OrderStatus": "Entered",
                            "ProcessedDate": processDate,
                            "LastUpdated": processDate
                        }];
                        //console.log(leanSupplyOrder);
                        const response = await axios.put(submittedOrdersURL, leanSupplyOrder, headers);
                        //console.log(response);
                        /////////////////////////////////////////////////////////////
                        Promise.all([leanSupplyRes, brightPearlRes, updateRes, goodsOutNoteRes, response]).then(async () => {
                            alert("Order Sent to Lean Supply");
                            if (this.state.attachBase64 !== undefined) {
                                const recipients = this.state.ProformaEmail.Recipient.split("\n").map(email => email.trim()).filter(email => email !== "");
                                const subject = this.state.ProformaEmail.Subject.replace("[bpOrder.id]", bpOrder.id);
                                let emailBody = this.state.ProformaEmail.EmailBody.replace("[bpOrder.id]", bpOrder.id);
                                emailBody = emailBody.replace("[orderHandle]", orderHandle);
                                await sendPDFEmail(recipients, subject, emailBody, this.state.attachBase64, "PROFORMA_INVOICE.pdf");
                            }


                            let Carrier;
                            const bpCarrierInfo = shippingMethods.find(c => c.value === shipment);
                            if (bpCarrierInfo) {
                                Carrier = bpCarrierInfo.label
                            } else {
                                Carrier = "Unknown Carrier"
                            }
                            let SalesChannel;
                            if (bpOrder.assignment.current.channelId !== undefined) {
                                const sc = channelsMapping.find(s => s.id === bpOrder.assignment.current.channelId);
                                if (sc) {
                                    SalesChannel = sc.name
                                } else {
                                    SalesChannel = "Unknown Sales Channel"
                                }
                            } else {
                                SalesChannel = "Unknown Sales Channel"
                            }

                            const processedBy = localStorage.getItem('user');

                            const ashlinOrderRecord = [{
                                "BrightpearlOrderId": bpOrder.id,
                                "Carrier": Carrier,
                                "Currency": bpOrder.currency.orderCurrencyCode,
                                "ProcessedDate": processDate,
                                "SaleChannelName": SalesChannel,
                                "SaleChannelOrderId": bpOrder.reference,
                                "ShippedDate": "",
                                "ShippedFrom": "LS14 LeanSupply 3PL",
                                "Status": "Dispatched",
                                "TotalValue": bpOrder.totalValue.total,
                                "TrackingNumber": "",
                                "AttnName": bpOrder.parties.delivery.addressFullName,
                                "CompanyName": bpOrder.parties.delivery.companyName,
                                "PostalCode": bpOrder.parties.delivery.postalCode,
                                "ProcessedBy": processedBy,
                                "EmailAddress": bpOrder.parties.customer.email !== undefined ? bpOrder.parties.customer.email : "",
                                "EmailNotification": "NEED"
                            }];

                            //console.log(ashlinOrderRecord);
                            const orderRecordRes = await postOrderRecords(ashlinOrderRecord);
                            //console.log(orderRecordRes);

                            this.getPendingOrder();
                            $('#PendingOrderDetailModal').modal('hide');
                        });
                    }
                }
            }
        }
    };
    handleCheckFulfill = async (e, orderRows) => {
        e.preventDefault();
        const leanSupplyInventory = await getBlanceByWarehouseCode(14);
        let counter = 0;
        const fulFullRes = await orderRows.map(async o => {
            //console.log(o);
            if (o.productSku !== undefined) {
                const needQty = parseInt(o.quantity.magnitude);
                //HERE check Lean Supply
                //console.log(leanSupplyInventory);
                const stockLevel = await leanSupplyInventory.find(s => s.sku === o.productSku);
                if (stockLevel === undefined) {
                    counter++
                } else {
                    //console.log(stockLevel)
                    if (needQty > parseInt(stockLevel.qty)) {
                        counter++
                    }
                }

            }
        });
        Promise.all(fulFullRes).then(() => {
            if (counter > 0) {
                alert("Can't fulfill this order!");
            } else {
                alert("This order can be fulfilled, please click red button to send this order");
                this.setState({
                    canFulfill: true
                })
            }
        })
    };
    handleOnDrop = (accepted) => {
        // console.log(accepted);
        const file = new File(accepted, accepted[0].name, { type: accepted[0].type });
        // file.preview = accepted[0].preview;
        // const file = accepted[0];
        // const reader = new FileReader();
        // reader.onload = (event) => {
        //     console.log(event.target.result);
        // };
        // console.log(reader.readAsDataURL(file));
        // console.log(file);
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (event) => {
            //console.log(event.target.result);
            // this.setState({
            //     generalPhotos: this.state.generalPhotos.concat([{ base64: event.target.result }])
            // });
        };

    };
    handleHtsCodeOnchange = (e, index) => {
        //console.log(e.target.value);
        const newHts = e.target.value;
        const selected = this.state.selected;

        if (selected.parties.delivery.countryIsoCode === "US") {
            selected.orderRows[index].HtsUs = newHts;
            const newUsDuty = this.state.HtsUs.find(h => h.HtsUs1 === newHts);
            //console.log(newUsDuty);
            selected.orderRows[index].UsDuty = newUsDuty.UsDuty;
        } else {
            selected.orderRows[index].HtsCa = newHts;
            const newCaDuty = this.state.HtsCa.find(h => h.HtsCa1 === newHts);
            //console.log(newCaDuty);
            selected.orderRows[index].CaDuty = newCaDuty.CaDuty;
        }
        this.setState({ selected });
    };
    handleDeleteRow = (e, index) => {
        e.preventDefault();
        const selected = this.state.selected;
        selected.orderRows.splice(index, 1);
        this.setState({
            selected
        });
    };
    handleReplaceSKU = (e, index) => {
        const selected = this.state.selected;
        selected.orderRows[index].productSku = e.target.value.toUpperCase();
        this.setState({
            selected
        }, () => console.log(selected.orderRows));

    };
    handleOrderAttachFile = (base64String) => {
        this.setState({
            attachBase64: base64String
        })
    };
    handleAddCrossDock = () => {
        const selected = this.state.selected;
        const backUpOrderRows = this.state.selectedBackUp.orderRows;
        let Comment = this.state.Comment;
        if (parseInt(this.state.crossDockBoxTotal) > 1) {
            Comment += " ***Box # *** ";
        } else {
            Comment += " ***Split Box # *** ";
        }
        backUpOrderRows.forEach(row => {
            Comment += `,ship to Qty ${parseInt(row.quantity.magnitude)} of ${row.productSku} `;
        });
        //console.log(backUpOrderRows);
        const crossDockLine = {
            "orderRowSequence": "1",
            "productId": 0,
            "productName": "Cross Dock Box",
            "productSku": "Cross Dock Box",
            "quantity": {
                "magnitude": this.state.crossDockBoxTotal
            },
            "itemCost": {
                "currencyCode": "CAD",
                "value": "0.00"
            },
            "productPrice": {
                "currencyCode": "CAD",
                "value": "0.00"
            },
            "rowValue": {
                "taxRate": "0.0000",
                "taxCode": "Z",
                "taxCalculator": "brightpearl",
                "rowNet": {
                    "currencyCode": "CAD",
                    "value": "0.00"
                },
                "rowTax": {
                    "currencyCode": "CAD",
                    "value": "0.00"
                },
                "taxClassId": 1
            },
            "HtsCa": "4202.31.00.10",
            "HtsUs": "4205.00.8000",
            "CaDuty": 0,
            "UsDuty": 0
        };
        selected.orderRows.push(crossDockLine);
        this.setState({
            selected,
            Comment
        }, () => console.log(this.state));
    };
    handleGenerateInvoiceBtn = () => {
        if (this.state.FEIN === undefined || this.state.FEIN === "") {
            let confirmBox = window.confirm(`There is NO FEIN # for this order, Ashlin will be the IMPORTER. Do you confirm this?`);
            if (confirmBox) {
                $('#ProformaInvoiceModal').modal('toggle');
            }
        } else {
            $('#ProformaInvoiceModal').modal('toggle');
        }
    };
    render() {
        return (
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar />
                </div>
                <div className={'col'}>
                    <br />
                    {this.state.isLoading && <div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Downloading Data...</div>}
                    <PendingOrderDetailModal
                        {...this.state.selected}
                        WarehouseCode={this.state.WarehouseCode}
                        Priority={this.state.Priority}
                        useCustomizedCarrier={this.state.useCustomizedCarrier}
                        ServiceType={this.state.ServiceType}
                        needChooseCarrier={this.state.needChooseCarrier}
                        CarrierCode={this.state.CarrierCode}
                        ServiceCode={this.state.ServiceCode}
                        ChargeToAccount={this.state.ChargeToAccount}
                        ServiceCodeGroup={this.state.ServiceCodeGroup}
                        use3rdAccount={this.state.use3rdAccount}
                        Comment={this.state.Comment}
                        onChange={this.handleOnchange}
                        changeCarrier={this.handleChangeCarrier}
                        shipmentTypeOnchange={this.handleShipmentTypeOnchange}
                        carrierCodeOnchange={this.handleCarrierCodeOnchange}
                        carrierServiceOnchange={this.handleCarrierServiceOnchange}
                        sendOrder={this.handleSendOrder}
                        checkFulfill={this.handleCheckFulfill}
                        canFulfill={this.state.canFulfill}
                        onDrop={this.handleOnDrop}
                        HtsCa={this.state.HtsCa}
                        HtsUs={this.state.HtsUs}
                        SkuWithHts={this.state.SkuWithHts}
                        FEIN={this.state.FEIN}
                        htsCodeOnchange={this.handleHtsCodeOnchange}
                        deleteRow={this.handleDeleteRow}
                        orderAttachFile={this.handleOrderAttachFile}
                        EmailList={this.state.EmailList}
                        addCrossDock={this.handleAddCrossDock}
                        crossDockBoxTotal={this.state.crossDockBoxTotal}
                        isCrossDock={this.state.isCrossDock}
                        isCrossDockOnchange={this.handleIsCrossDockOnchange}
                        AshlinInfo={this.state.AshlinInfo}
                        Signature={this.state.Signature}
                        handleGenerateInvoiceBtn={this.handleGenerateInvoiceBtn}
                        attachBase64={this.state.attachBase64}
                        replaceSKU={this.handleReplaceSKU}
                    />
                    <br />
                    <h1 className={"text-center"}> Bright Pearl Pending Orders</h1>
                    <br />
                    <div style={{ textAlign: "center" }}>
                        <ReactTable
                            data={this.state.pendingOrders}
                            columns={
                                [
                                    {
                                        Header: "Order ID",
                                        accessor: "id",
                                    },
                                    {
                                        Header: "Buyer Name",
                                        accessor: o => o.parties.customer.addressFullName,
                                        id: "buyerName"
                                    },
                                    {
                                        Header: "Created Date",
                                        accessor: o => o.createdOn.slice(0, 19).replace("T", " "),
                                        id: "createdOn"
                                    },
                                    {
                                        Header: "Payment Status",
                                        accessor: "orderPaymentStatus"
                                    }
                                ]
                            }
                            defaultPageSize={10}
                            className="-striped -highlight orderTable"
                            getTrProps={(state, rowInfo) => {
                                return {
                                    onClick: async (e) => {
                                        const selected = rowInfo.original;
                                        //console.log(selected.orderRows);
                                        const temp = Object.entries(selected.orderRows);
                                        selected.orderRows = temp.map(arr => {
                                            arr[1].rowId = arr[0];
                                            return arr[1];
                                        });
                                        //console.log(selected.orderRows);

                                        //get hts code and description here
                                        selected.orderRows.forEach(ol => {
                                            const hts = this.state.SkuWithHts.find(hts => hts.Sku === ol.productSku);

                                            if (hts !== undefined) {
                                                if (hts.CaDuty !== undefined && hts.UsDuty !== undefined) {
                                                    ol.HtsCa = hts.HtsCa;
                                                    ol.HtsUs = hts.HtsUs;
                                                    ol.CaDuty = hts.CaDuty.toString().startsWith("0.0") ? hts.CaDuty * 100 : hts.CaDuty;
                                                    ol.UsDuty = hts.UsDuty.toString().startsWith("0.0") ? hts.UsDuty * 100 : hts.UsDuty;
                                                    ol.HtsDescription = `[${hts.Sku}] ${hts.DesignDescription}-${hts.MaterialDesignDescription}, HTS:${ol.HtsUs}`;
                                                }
                                            } else {
                                                ol.HtsCa = "4205.00.8000";
                                                ol.HtsUs = "4205.00.8000";
                                                ol.CaDuty = 0;
                                                ol.UsDuty = 0;
                                            }
                                            // if(selected.parties.delivery.countryIsoCode==="US"){
                                            //     ol.HtsUs = "4205.00.8000";
                                            //     ol.UsDuty = 0;
                                            // }

                                        });
                                        let FEIN = "";
                                        //IF US Customer, try to get FEIN Number
                                        if (selected.parties.delivery.countryIsoCode === "US") {
                                            const res = await getUSCustomerFEINNumber(selected.parties.customer.contactId);
                                            if (res !== undefined)
                                                FEIN = res;
                                        }
                                        let ServiceCode, Comment, ServiceCodeGroup, CarrierCode;
                                        const channelID = [16, 25, 26, 27, 29];
                                        if (selected.parties.delivery.countryIsoCode !== "CA") {
                                            if (selected.parties.delivery.countryIsoCode === "US" && (selected.parties.delivery.addressLine1.toUpperCase().includes('BOX') || channelID.indexOf(selected.assignment.current.channelId) !== -1)) {
                                                ServiceCodeGroup = [
                                                    { label: "Canpar Normal", value: "01" },
                                                    { label: "Canpar Express", value: "05" }
                                                ];
                                                ServiceCode = { label: "CHITCHATS", value: "01" };
                                                CarrierCode = "CHITCHATS";
                                                Comment = "Please use Ashlin Canpar Account to ship to ChitChats";
                                            } else {
                                                Comment = "Please use Ashlin FEDEX Account to ship";
                                                ServiceCodeGroup = [
                                                    { label: "FEDEX_GROUND", value: "FEDEX_GROUND" },
                                                    { label: "FEDEX_2DAY", value: "FEDEX_2DAY" },
                                                    { label: "FEDEX_OVERNITE", value: "FEDEX_OVERNITE" }
                                                ];
                                                ServiceCode = { label: "FEDEX_GROUND", value: "FEDEX_GROUND" };
                                                CarrierCode = "FEDEX"
                                            }
                                        } else {
                                            if (selected.parties.delivery.addressLine1.toUpperCase().includes('BOX') || channelID.indexOf(selected.assignment.current.channelId) !== -1) {
                                                ServiceCode = { label: "Canada Post DOM.EP", value: "DOM.EP" };
                                                Comment = "Please use Ashlin Canada Post Account to ship";
                                                ServiceCodeGroup = [
                                                    { label: "Canada Post DOM.EP", value: "DOM.EP" },
                                                    { label: "Canada Post DOM.XP", value: "DOM.XP" },
                                                    { label: "Canada Post USA.EP", value: "USA.EP" }
                                                ];
                                                CarrierCode = "CANP";
                                            } else {
                                                ServiceCode = { label: "Canpar Normal", value: "01" };
                                                Comment = "Please use Ashlin Canpar Account to ship";
                                                ServiceCodeGroup = [
                                                    { label: "Normal", value: "01" },
                                                    { label: "Express", value: "05" }
                                                ];
                                                CarrierCode = "CANPAR";
                                            }

                                        }
                                        this.setState({
                                            selected: selected,
                                            selectedBackUp: JSON.parse(JSON.stringify(selected)),
                                            crossDockBoxTotal: "1",
                                            WarehouseCode: 14,
                                            Priority: "",
                                            useCustomizedCarrier: false,
                                            needChooseCarrier: false,
                                            attachBase64: undefined,
                                            ServiceType: "BESTPRICE",
                                            CarrierCode: CarrierCode,
                                            ServiceCode: ServiceCode,
                                            Comment: Comment,
                                            FEIN: FEIN,
                                            ServiceCodeGroup: ServiceCodeGroup,
                                            use3rdAccount: false,
                                            ChargeToAccount: '',
                                            registeredAddress: '',
                                            canFulfill: false,
                                            isCrossDock: false
                                        }, this.handleOrderDetail);
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default PendingOrder;