import React from 'react';
const brightpearl_channel_info_list_item = ({item, index, brightpearlChannelInfoOnchange, upDateBrightpearlChannelInfo})=>{
    return(
        <tr>
            <td>
                <span style={{fontWeight:'bold'}}>{item.ChannelName}</span>
            </td>
            <td>
                <input type={'text'} value={item.AshlinChannelId} className={'text-center'} name={"AshlinChannelId"} onChange={(e)=>brightpearlChannelInfoOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.ChannelId} className={'text-center'} name={"ChannelId"} onChange={(e)=>brightpearlChannelInfoOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.ContactId} name={'ContactId'} className={'text-center'} onChange={(e)=>brightpearlChannelInfoOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.PreferredShippingCarrier} name={'PreferredShippingCarrier'} className={'text-center'} onChange={(e)=>brightpearlChannelInfoOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.ShippingMethodId} name={'ShippingMethodId'} className={'text-center'} onChange={(e)=>brightpearlChannelInfoOnchange(e,index)}/>
            </td>
            <td>
                <input type={'text'} value={item.DiscountRate} name={'DiscountRate'} className={'text-center'} onChange={(e)=>brightpearlChannelInfoOnchange(e,index)}/>
            </td>
            <td>
                <button className={'btn-danger'} type={'button'} onClick={()=>upDateBrightpearlChannelInfo(item)}>Update</button>
            </td>
        </tr>
    );
};
export default brightpearl_channel_info_list_item;