import React, { Component } from 'react';
import axios from 'axios';
import {  generateHeader, WALMART_FEED_URL, WALMART_ITEM_URL,WALMART_ORDER_URL, WalmartInventoryUpdate,WalmartFeedStatus} from '../walmartMarketplace';
import { jsonToXml, xmlToJson } from '../json_xml_converter';
import $ from "jquery";
import { proxyurl } from '../requestHelper';
const needtoFeed = ""
class WalmartApi extends Component{
   

    //%40 == @
    handleSubmit =async (e)=>{
        e.preventDefault();
        const feedID = encodeURIComponent("58FC5972076440CD9C5CB7EA665F52EF@AQMBAAA");
        const query =`/${feedID}?includeDetails=true`;
        const requestUrl = WALMART_FEED_URL + query;
        const headers = await generateHeader(requestUrl,'GET',"123456abcdef","json","json");
        //console.log(headers);
        const res = await axios.get(requestUrl,{headers});
        //console.log(res);
    };
    handleJsToXml = (e)=>{
        e.preventDefault();
        const json = xmlToJson("<Clothing>\n" +
            "<variantGroupId>S1001</variantGroupId>\n" +
            "<variantAttributeNames>\n" +
            "      <variantAttributeName>clothingSize</variantAttributeName>\n" +
            "      <variantAttributeName>color</variantAttributeName>\n" +
            "</variantAttributeNames>\n" +
            "<clothingSize>L</clothingSize>\n" +
            "<color>\n" +
            "    <colorValue>Blue</colorValue>\n" +
            "</color>\n" +
            "<swatchImages>\n" +
            "  <swatchImage>\n" +
            "     <swatchImageUrl>http://10.10.10.10/product_images/blue_shirt.jpg</swatchImageUrl>\n" +
            "     <swatchVariantAttribute>color</swatchVariantAttribute>\n" +
            "  </swatchImage>\n" +
            "</swatchImages>\n" +
            "<isPrimaryVariant>true</isPrimaryVariant>\n" +
            "</Clothing>");
        const xml = jsonToXml(inventory);
        //console.log(json);
        //console.log(json,"\n",xml);
        //console.log(json);

    };
    handleGetItem = async (e)=>{
        e.preventDefault();
        const query ="/L2200-050-47";
        const requestUrl = WALMART_ITEM_URL + query;
        const headers = await generateHeader(requestUrl,'GET',"123456abcdef","xml","xml");
        const res = await axios.get(requestUrl,{headers});
        //console.log(res.data)
        // const json = xmlToJson(res.data);
        // console.log(json);
    };

    handleFeedItems = async (e)=>{
        e.preventDefault();
        const query = "?feedType=item";
        const requestUrl = WALMART_FEED_URL + query;
        //const xml = jsonToXml(jo);
        //console.log(xml);
        //para - url, method, id
        const headers = generateHeader(requestUrl,'POST',"ashlinApiFeedTest001","xml","xml");
        //const res = await axios.post(requestUrl,xml,{headers});
       //console.log(headers);
    };
    handleGetAllReleaseOrders = async(e)=>{
        e.preventDefault();
        let oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        oneWeekAgo = new Date(oneWeekAgo).toISOString();

        const query = `?createdStartDate=${oneWeekAgo}&&status=Created&limit=200`;
        const requestUrl = WALMART_ORDER_URL + query;

        //NEED CHECK VALIDATION
        const headers = generateHeader(requestUrl,'GET',"ashlinApiFeedTest002","xml","json");
        const res = await axios.get(requestUrl,{headers});
        //console.log(res.data);

        //const orders = res.data.list.elements.order;
        // const response = await orders.map(async order=>{
        //     return await this.handleAcknowledgeOrder(order.purchaseOrderId);
        // });
        // Promise.all(response).then(()=>{
        //     console.log("All Walmart have been acknowledged");
        // });
    };
    handleOrderDetail =async (e)=>{
        e.preventDefault();
        const res = await this.getOrderDetail('Y9869676');
        //console.log(res);
    };
    getOrderDetail = async(purchaseOrderId)=>{
        const query = `${purchaseOrderId}`;
        const requestUrl = WALMART_ORDER_URL + query;
        const headers = generateHeader(requestUrl,'GET',"ashlinApiFeedTest002","json","json");
        return await axios.get(requestUrl,{headers});
    };
    handleAcknowledgeOrder = async(e)=>{
        e.preventDefault();
        const query = `Y9869676/acknowledge`;
        const requestUrl = WALMART_ORDER_URL + query;
        //console.log(requestUrl);
        const headers = generateHeader(requestUrl,'POST',"ashlinApiFeedTest002","json","json");
        //console.log(headers);
        const res =  axios.post(requestUrl,{
            "purchaseOrderId":'Y9869676'
        },{headers});
        //console.log(res);

    };


    handleOrderShipment=async(e)=>{
        e.preventDefault();
        let oneWeekAgo = new Date().toUTCString();
        //console.log(oneWeekAgo);
        const query = `Y9869676/shipping`;
        const requestUrl = proxyurl+ "https://marketplace.walmartapis.com/v3/ca/orders/Y9869676/shipping";
        //console.log(requestUrl);
        const headers = generateHeader(requestUrl,'POST',"ashlinApiFeedTest004","json","json");
        const res = await axios.post(requestUrl,ship,{headers});
        //console.log(res);
    };


    handleOrderRefund=async(e)=>{
        e.preventDefault();
        const query = `Y9869676/refund`;
        const requestUrl = WALMART_ORDER_URL+ query;
        //console.log(requestUrl);
        const headers = generateHeader(requestUrl,'POST',"ashlinApiFeedTest004","json","json");
        // const res = await axios.post(requestUrl,refund,{headers});
        // console.log(res);
    };

    // handleUpdateInventory = async(e)=>{
    //     e.preventDefault();
    //     const res = await WalmartInventoryUpdate([{sku:'NECKTIE02-MICRO-18',quantity:0},{sku:'NECKTIE02-MICRO-01',quantity:0}]);
    //     const status = await WalmartFeedStatus(res.data.feedId);
    //     console.log(status);
    // };

    handleCancelOrder = async(e)=>{
        e.preventDefault();
        const query = `Y9871974/cancel`;
        const requestUrl = WALMART_ORDER_URL+ query;
        const headers = generateHeader(requestUrl,'POST',"ashlinApiFeedTest005","json","json");
        const res = await axios.post(requestUrl,cancel,{headers});
        //console.log(res);
    };
    handleRetireItem=async (e)=>{
        e.preventDefault();
        const requestUrl = WALMART_ITEM_URL +'/TAG47-09-38';
        //console.log(requestUrl);
        const headers = generateHeader(requestUrl,'DELETE',"ashlinApiFeedTest005","xml","json");
        const res = await axios.delete(requestUrl,{headers});
        //console.log(res);
    };
    render(){
        return(
            <div>
                <h1 className={'text-center'}>Walmart Api Testing</h1>
                <button className={'btn btn-success'} onClick={e=>this.handleSubmit(e)}>Generate Signature</button>
                <button className={'btn btn-primary'} onClick={e=>this.handleJsToXml(e)}>Transfer to Xml</button>
                <button className={'btn btn-danger'} onClick={e=>this.handleGetItem(e)}>Get Item</button>
                <hr/>
                <button className={'btn btn-danger'} onClick={e=>this.handleFeedItems(e)}>Feed Items</button>
                <hr/>
                <button className={'btn btn-danger'} onClick={e=>this.handleGetAllReleaseOrders(e)}>Get Released Order</button>
                <hr/>
                <button className={'btn btn-danger'} onClick={e=>this.handleOrderDetail(e)}>Get Order Detail</button>
                <hr/>
                <button className={'btn btn-danger'} onClick={e=>this.handleAcknowledgeOrder(e)}>Acknowledge Order</button>
                <hr/>
                <button className={'btn btn-danger'} onClick={e=>this.handleOrderShipment(e)}>Ship Order</button>
                <hr/>
                <button className={'btn btn-success'} onClick={e=>this.handleOrderRefund(e)}>Order Refund</button>

                <hr/>
                <button className={'btn btn-success'} onClick={e=>this.handleUpdateInventory(e)}>Update Inventory</button>
                <hr/>
                <button className={'btn btn-success'} onClick={e=>this.handleCancelOrder(e)}>Cancel Order</button>
                <hr/>
                <button className={'btn btn-success'} onClick={e=>this.handleRetireItem(e)}>Retire an Item</button>
            </div>
        )
    }
}
const ship = {
    "orderShipment": {
        "orderLines": {
            "orderLine": [
                {
                    "lineNumber": "1",
                    "orderLineStatuses": {
                        "orderLineStatus": [
                            {
                                "status": "Shipped",
                                "statusQuantity": {
                                    "unitOfMeasurement": "EA",
                                    "amount": "1"
                                },
                                "trackingInfo": {
                                    "shipDateTime": new Date().toUTCString(),
                                    "carrierName": {
                                        "otherCarrier": null,
                                        "carrier": "UPS"
                                    },
                                    "methodCode": "Express",
                                    "trackingNumber": "12345",
                                    "trackingURL": "www.fedex.com"
                                }
                            }
                        ]
                    }
                }
            ]
        }
    }
};
const refund = {
    "orderRefund": {
        "purchaseOrderId": "Y9869676",
        "orderLines": {
            "orderLine": [
                {
                    "lineNumber": "1",
                    "refunds": {
                        "refund": [
                            {
                                "refundComments": "test test",
                                "refundCharges": {
                                    "refundCharge": [
                                        {
                                            "refundReason": "Merchandise not received",
                                            "charge": {
                                                "chargeType": "PRODUCT",
                                                "chargeName": "Item Price",
                                                "chargeAmount": {
                                                    "currency": "CAD",
                                                    "amount": -19.97
                                                },
                                                "tax": {
                                                    "taxName": "Item Price Tax",
                                                    "taxAmount": {
                                                        "currency": "CAD",
                                                        "amount": -2.59
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                }
            ]
        }
    }
};

const cancel = {
    "orderCancellation": {
        "orderLines": {
            "orderLine": [
                {
                    "lineNumber": "1",
                    "orderLineStatuses": {
                        "orderLineStatus": [
                            {
                                "status": "Cancelled",
                                "cancellationReason": "CUSTOMER_REQUESTED_SELLER_TO_CANCEL",
                                "statusQuantity": {
                                    "unitOfMeasurement": "EA",
                                    "amount": "1"
                                }
                            }
                        ]
                    }
                }
            ]
        }
    }
};
const inventory = {
    "InventoryFeed": {
        "_attributes": {
            "xmlns": "http://walmart.com/"
        },
        "InventoryHeader": {
            "version": {
                "_text": "1.4"
            }
        },
        "inventory": [
            {
                "sku": {
                    "_text": "NECKTIE02-MICRO-18"
                },
                "quantity": {
                    "unit": {
                        "_text": "EACH"
                    },
                    "amount": {
                        "_text": "0"
                    }
                },
                "fulfillmentLagTime": {
                    "_text": "0"
                }
            },
            {
                "sku": {
                    "_text": "NECKTIE02-MICRO-01"
                },
                "quantity": {
                    "unit": {
                        "_text": "EACH"
                    },
                    "amount": {
                        "_text": "0"
                    }
                },
                "fulfillmentLagTime": {
                    "_text": "0"
                }
            },
        ]
    }
};
export default WalmartApi;