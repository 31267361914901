import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {connect} from "react-redux";
import ManualOrderConfirmationModal from '../Modal/ManualOrderComfirmationModal';
import { SubmitOrderModel } from '../../Utils/requestHelper';
class ManualOrder extends Component{
    constructor(props){
        super(props);
        this.state={
            OrderNumber:'',
            EndCustomerOrderNo:'',
            WarehouseCode:14,
            Priority:"",

            Name:"",
            ContactName:"",
            ContactPhone:"",
            ContactPhoneExt:"",

            AddressLine1:"",
            AddressLine2:"",
            AddressLine3:"",

            City:"",
            State:"",
            CountryCode:"CA",
            Zip:"",

            Comment:"",

            ServiceType:"BESTPRICE",
            useCustomized:false,

            CarrierCode:"CANPAR",
            ServiceCode:{label:"Canpar Normal",value:"01"},
            ServiceCodeGroup:[
                {label:"Normal",value:"01"},
                {label:"Express",value:"05"}
            ],
            selectedSKU:{},
            Quantity:"",

            OrderDetail:[]

        }
    }
    handleOnchange=(e)=>{
        const value =e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const name = e.target.name;
        this.setState({
            [name]:value})
        //},()=>console.log(this.state))
    };
    handleOnSelectSKU = (e)=>{
        const value = e.target.value;
        if(value !== "optionDefaultHeader") {
            const obj = JSON.parse(value);
            this.setState({
                selectedSKU:obj
            })
        }else{
            this.setState({
                selectedSKU:{}
            })
        }

    };
    handleAddProduct=(e)=>{
        e.preventDefault();
        const OrderDetail = this.state.OrderDetail;
        let ItemName = this.state.selectedSKU.Description;
        const index = this.state.selectedSKU.Description.indexOf("Preferred Packaging");
        if(index !==undefined)
            ItemName = this.state.selectedSKU.Description.slice(0,index-1);
        const orderLine = {
            "OrderLine": (OrderDetail.length+1).toString(),
            "ItemCode": this.state.selectedSKU.SKU,
            "ItemQty": parseInt(this.state.Quantity),
            "ItemName": ItemName,
            "ItemDescription": this.state.selectedSKU.Description,
            "uofm": this.state.selectedSKU.UofM,
            "per_pack": "1"
        };
        OrderDetail.push(orderLine);
        this.setState({
            OrderDetail
        })
    };
    handleRemoveOrderLine=(e,index)=>{
        e.preventDefault();
        const OrderDetail = this.state.OrderDetail;
        OrderDetail.splice(index,1);
        this.setState({
            OrderDetail
        })
    };

    handleCarrierCodeOnchange=(e)=>{
        const value = e.target.value;
        let ServiceCodeGroup = [];
        let ServiceCode = {};
        if(value==="CANP"){
            ServiceCodeGroup = [
                {label:"Canada Post DOM.EP",value:"DOM.EP"},
                {label:"Canada Post DOM.XP",value:"DOM.XP"},
                {label:"Canada Post USA.EP",value:"USA.EP"}
            ];
            ServiceCode = {label:"Canada Post DOM.EP",value:"DOM.EP"};
        }else if(value==="UPS"){
            ServiceCodeGroup = [
                {label:"UPS Groupd",value:"11"},
            ];
            ServiceCode = {label:"UPS Groupd",value:"11"};
        }else if(value==="FEDEX"){
            ServiceCodeGroup = [
                {label:"FEDEX_GROUND",value:"FEDEX_GROUND"},
                {label:"FEDEX_2DAY",value:"FEDEX_2DAY"},
                {label:"FEDEX_OVERNITE",value:"FEDEX_OVERNITE"}
            ];
            ServiceCode = {label:"FEDEX_GROUND",value:"FEDEX_GROUND"};
        }else{
            ServiceCodeGroup = [
                {label:"Canpar Normal",value:"01"},
                {label:"Canpar Express",value:"05"}
            ];
            ServiceCode = {label:"Canpar Normal",value:"01"};
        }
        this.setState({
            CarrierCode:value,
            ServiceCodeGroup,
            ServiceCode
        },()=>console.log(this.state))
    };
    handleCarrierServiceOnchange=(e)=>{
      this.setState({
          ServiceCode:e.target.value
      },()=>console.log(this.state))
    };
    handleSendOrder=e=>{
        e.preventDefault();
        const now = new Date(new Date().toString().split('GMT')[0]+' UTC').toISOString();
        const d = now.slice(0,19).replace("T", " ");
        let OrderDelivery;
        if(!this.state.useCustomized){
            OrderDelivery={
                "ServiceType":this.state.ServiceType
            };
        }else{
            OrderDelivery={
                "CarrierCode":this.state.CarrierCode,
                "ServiceCode":this.state.ServiceCode.value
            }
        }
        const order = {
            "OrderHeader": {
                "OrderNumber": this.state.OrderNumber,
                "EndCustomerOrderNo": this.state.EndCustomerOrderNo,
                "ThirdPartyOrderNo": this.state.EndCustomerOrderNo,
                "OrderDateCode": "CR",
                "OrderDate": d,
                "Comment": this.state.Comment,
                "ShipTo": {
                    "Name" : this.state.Name,
                    "AddressLine1"	: this.state.AddressLine1,
                    "AddressLine2"	: this.state.AddressLine2,
                    "AddressLine3"	: this.state.AddressLine3,
                    "City"	: this.state.City,
                    "State"	: this.state.State,
                    "CountryCode"	: this.state.CountryCode,
                    "ContactName"	: this.state.ContactName,
                    "ContactPhone"	:	this.state.ContactPhone,
                    "ContactPhoneExt": this.state.ContactPhoneExt,
                    "Zip"	:	this.state.Zip
                },
                "ShipFrom": {
                    "Name" : "ASHLIN-BPG Marketing Inc",
                    "AddressLine1"	: "100 METROPOLITAN ROAD",
                    "City"	:	"SCARBOROUGH",
                    "State"	:"ON",
                    "CountryCode"	:	"CA",
                    "ContactName"	:	"NANCY TACCONE",
                    "ContactPhone"	:	"9058553027",
                    "Zip"	:	"M1R 5A2"
                },
                "WarehouseCode": this.state.WarehouseCode,
                "OrderStatus": "NEW",
                "OrderType": "NO",
                "Priority": this.state.Priority,
                "Version": ""
            },
             "OrderDetail": this.state.OrderDetail,
             "OrderDelivery": OrderDelivery
        }

        const data = SubmitOrderModel(order);
        console.log(data);
    };
    handleCreateNewOrder=e=>{
        e.preventDefault();
        this.setState({
            OrderNumber:'',
            EndCustomerOrderNo:'',
            WarehouseCode:14,
            Priority:"",

            Name:"",
            ContactName:"",
            ContactPhone:"",
            ContactPhoneExt:"",

            AddressLine1:"",
            AddressLine2:"",
            AddressLine3:"",

            City:"",
            State:"",
            CountryCode:"CA",
            Zip:"",

            Comment:"",

            ServiceType:"BESTPRICE",
            useCustomized:false,

            CarrierCode:"CANPAR",
            ServiceCode:{label:"Canpar Normal",value:"01"},
            ServiceCodeGroup:[
                {label:"Normal",value:"01"},
                {label:"Express",value:"05"}
            ],
            selectedSKU:{},
            Quantity:"",

            OrderDetail:[]

        })
    };
    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar />
                </div>
                <ManualOrderConfirmationModal {...this.state} submitOrder={this.handleSendOrder}/>
                <div className={'col'}>
                    <br/>
                    <h1 className={'text-center'}>Create Manual Order
                    </h1>

                    <button className={'btn-danger float-right'} onClick={this.handleCreateNewOrder} >Reset ALL Data</button>

                    <table className={'table'}>
                        <tbody>
                            <tr>
                                <th scope="col">Order #: &nbsp;
                                    <input type="text"  name={'OrderNumber'} onChange={this.handleOnchange}
                                           value={this.state.OrderNumber}/>
                                </th>
                                <th scope="col">Customer #: &nbsp;
                                    <input type="text"  name={'EndCustomerOrderNo'} onChange={this.handleOnchange}
                                           value={this.state.EndCustomerOrderNo}/>
                                </th>
                                <th scope="col">Ware House &nbsp;
                                    <select name={'WarehouseCode'}  onChange={this.handleOnchange}  value={this.state.WarehouseCode}>
                                        <option value="14">Lean Supply</option>
                                        {/*<option value="10">10</option>*/}
                                    </select> &nbsp;
                                </th>
                                <th scope="col">Priority &nbsp;
                                    <select name={'Priority'}  onChange={this.handleOnchange}  value={this.state.Priority}>
                                        <option value="">Normal</option>
                                        <option value="RUSH">RUSH</option>
                                    </select> &nbsp;
                                </th>
                            </tr>

                            <tr>
                                <th scope="col">Name: &nbsp;
                                    <input type="text"  name={'Name'} onChange={this.handleOnchange}
                                           value={this.state.Name}/>
                                </th>
                                <th scope="col">Contact Name: &nbsp;
                                    <input type="text"  name={'ContactName'} onChange={this.handleOnchange}
                                           value={this.state.ContactName}/>
                                </th>
                                <th scope="col">Phone #: &nbsp;
                                    <input type="text"  name={'ContactPhone'} onChange={this.handleOnchange}
                                           value={this.state.ContactPhone}/>
                                </th>
                                <th scope="col">Contact Phone Ext: &nbsp;
                                    <input type="text"  name={'ContactPhoneExt'} onChange={this.handleOnchange}
                                           value={this.state.ContactPhoneExt}/>
                                </th>
                            </tr>

                            <tr>
                                <th scope="col">Address Line1: &nbsp;
                                    <input type="text"  name={'AddressLine1'} onChange={this.handleOnchange}
                                           value={this.state.AddressLine1}/>
                                </th>
                                <th scope="col">AddressLine 2: &nbsp;
                                    <input type="text"  name={'AddressLine2'} onChange={this.handleOnchange}
                                           value={this.state.AddressLine2}/>
                                </th>
                                <th scope="col">AddressLine 3: &nbsp;
                                    <input type="text"  name={'AddressLine3'} onChange={this.handleOnchange}
                                           value={this.state.AddressLine3}/>
                                </th>
                                <th scope="col">City: &nbsp;
                                    <input type="text"  name={'City'} onChange={this.handleOnchange}
                                           value={this.state.City}/>
                                </th>
                            </tr>

                            <tr>
                                {/*<th scope="col">City: &nbsp;*/}
                                    {/*<input type="text"  name={'City'} onChange={this.handleOnchange}*/}
                                           {/*value={this.state.City}/>*/}
                                {/*</th>*/}
                                <th scope="col">State: &nbsp;
                                    <input type="text"  name={'State'} onChange={this.handleOnchange}
                                           value={this.state.State}/>
                                </th>
                                <th scope="col">Country Code: &nbsp;
                                    <input type="text"  name={'CountryCode'} onChange={this.handleOnchange}
                                           value={this.state.CountryCode}/>
                                </th>
                                <th scope="col">Zip Code: &nbsp;
                                    <input type="text"  name={'Zip'} onChange={this.handleOnchange}
                                           value={this.state.Zip}/>
                                </th>
                                <th scope="col">
                                    {/*Use the cheapest: &nbsp;*/}
                                    {/*<input type="checkbox"  name={'useCheapest'} onChange={this.handleCarrierOnchange}*/}
                                           {/*value={this.state.useCheapest  } checked={this.state.useCheapest}  />*/}
                                    {/*<br/>*/}

                                {/*Use the fastest: &nbsp;*/}
                                    {/*<input type="checkbox"  name={'useFastest'} onChange={this.handleCarrierOnchange}*/}
                                           {/*value={this.state.useFastest} checked={this.state.useFastest}  />*/}
                                    {/*<br/>*/}
                                Shipment Type:&nbsp; <select name={'ServiceType'}  onChange={this.handleOnchange}  value={this.state.ServiceType}>
                                    <option value="BESTPRICE">Cheapest</option>
                                    <option value="BESTETA">Fastest</option>
                                    <option value="CUSTOMERPICKUP">Customer Pickup</option>
                                </select> &nbsp;
                                    <br/>
                                Customize Carrier: &nbsp;
                                    <input type="checkbox"  name={'useCustomized'} onChange={this.handleOnchange}
                                           value={this.state.useCustomized } checked={this.state.useCustomized}   />
                                    <br/>
                                </th>
                            </tr>
                        </tbody>
                    </table>
                    {this.state.useCustomized &&
                    <div>
                        <div scope="col">Carrier &nbsp;
                            <select name={'CarrierCode'}  onChange={this.handleCarrierCodeOnchange} >
                                <option value="CANPAR">CANPAR</option>
                                <option value="CANP">CANADA POST</option>
                                <option value="UPS">UPS</option>
                                <option value="FEDEX">FEDEX</option>
                            </select> &nbsp;
                            Carrier Service&nbsp;
                            <select name={'ServiceCodeGroup'}  onChange={this.handleCarrierServiceOnchange} value={this.state.ServiceCode.value}>
                                {
                                    this.state.ServiceCodeGroup.map((sc,i)=>{
                                        return <option value={sc.value} key={i}>{sc.label}</option>
                                    })
                                }
                                {/*<option value="CANPAR">CANPAR</option>*/}
                                {/*<option value="CANP">CANADA POST</option>*/}
                                {/*<option value="UPS">UPS</option>*/}
                                {/*<option value="FEDEX">FEDEX</option>*/}
                            </select> &nbsp;
                        </div>
                    </div>
                    }
                    <hr/>
                    <span style={{fontWeight:'bold'}}>Comment:</span> <input type="text"  name={'Comment'} onChange={this.handleOnchange} style={{width:'100%'}}
                           value={this.state.Comment}/>
                    <hr/>
                    <h2>Order Detail: </h2>
                    <div className={'d-flex justify-content-center'}>
                        SKU: &nbsp; <select name={'selectedSKU'}  onChange={this.handleOnSelectSKU}  >
                        <option value="optionDefaultHeader" >-- select a Product --</option>
                        {this.props.allSKUs.map((product,i)=>{
                            return <option value={JSON.stringify(product)} key={i}>{product.SKU}</option>
                        })}
                                 </select>
                        Quantity: <input type="text"  name={'Quantity'} onChange={this.handleOnchange}
                                         value={this.state.Quantity}/>
                        <button className={'btn btn-success'} onClick={this.handleAddProduct}>Add Product</button>
                        {
                            this.state.OrderDetail.length > 0 &&
                            <button className={'btn btn-danger'} type={'button'}
                                    data-toggle="modal" data-target="#ManualOrderConfirmationModal" data-show="true"
                            >Confirm Order</button>
                        }
                    </div>
                    <hr/>
                    {this.state.OrderDetail.length > 0 &&
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Line #</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Name</th>
                                <th scope="col">Remove Order Line</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.OrderDetail.map((ol, i) => {
                                return (
                                    <tr key={i}>
                                        <th scope="row">{++i}</th>
                                        <th>{ol.ItemCode}</th>
                                        <td>{ol.ItemQty}</td>
                                        <td>{ol.ItemName}</td>
                                        <td>
                                            <button className={'btn-danger'}
                                                    onClick={(e) => this.handleRemoveOrderLine(e, i)}>Remove
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return {
        allSKUs: state.allSKUs
    }
};
export default connect(mapStateToProps)(ManualOrder);
// export default ManualOrder;