import React from 'react';
import $ from "jquery";
import {
    withGoogleMap,
    withScriptjs,
    GoogleMap,
    Marker,
    InfoWindow
  } from "react-google-maps";
  import { Descriptions, Divider, Empty } from 'antd';


const createManualOrderShipment = (props)=>{
    const order = props.order;
    const selectedRow = props.selectedRow;
    const BrightPearlWarehousesName = props.brightPearlWarehouses.map(w=>w.name);
    const shippingRates = props.shippingRates;
    let orderRows;
    if(props.orderItems.length > 0){
        orderRows = props.orderItems;
    }else{
        orderRows = props.order.orderRows
    }
    let selectedGoogleMapInfo = props.selectedGoogleMapInfo

    const MyGoogleMap = withGoogleMap(props => {
        return(
        <GoogleMap
          defaultCenter = { selectedGoogleMapInfo.geometry.location }
          defaultZoom = { 13 }
        >
            <Marker position={ selectedGoogleMapInfo.geometry.location } />
        </GoogleMap>
     )});

    function checkValidPhoneNumber(telephone){
        const phoneno = /^\(?([0-9]{3})\)? ([0-9]{3})-([0-9]{4})$/;
        if(telephone.match(phoneno)) {
            return telephone
          }
          else {
            return "Need Change the phone format to (xxx)xxx-xxxx On BP";
          }
    }

    function checkValidStateOrCounty(stateOrCounty){
        if (stateOrCounty == stateOrCounty.toUpperCase() && stateOrCounty.length === 2){
            return stateOrCounty
        } else {
            return "Need Change the State/County to abbreviations style i.e. ON QC BC etc. On BP"
        }
    }

    //console.log(props.shippingInfo);
    return(<div className="modal fade" id="CreateManualShipmentModal" tabIndex="-1" role="dialog" data-backdrop="static"
                aria-labelledby="CreateManualShipmentModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title text-center" id="CreateManualShipmentModalTitle"> Order ID: {selectedRow.BrightpearlOrderId} - Status: <span style={{color:'green'}}>{selectedRow.Status}</span></h1>
                    <button type="button" className="close" onClick={props.handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body row">
                    <div className={'col'}>
                        <h2>Order Information</h2>
                    <table style={{boxShadow: "0 10px 6px -6px #777"}} className="table table-bordered CreateManualShipmentModalTable">
                        <thead>
                        <tr>
                            <th scope="col"> </th>
                            <th scope="col">Delivery address</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th scope="row">Name</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.addressFullName !==undefined)?order.parties.delivery.addressFullName:""}</td>
                        </tr>
                        <tr>
                            <th scope="row">Company</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.companyName !==undefined)?order.parties.delivery.companyName:"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">Street</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.addressLine1 !==undefined)?order.parties.delivery.addressLine1:"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">Suburb</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.addressLine2 !==undefined)?order.parties.delivery.addressLine2:"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">City</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.addressLine3 !==undefined)?order.parties.delivery.addressLine3:"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">State/county</th>
                            <td style={{color: "red",fontWeight: "bold", fontSize: "20px"}}>{(order.parties!==undefined&&order.parties.delivery.addressLine4 !==undefined)? checkValidStateOrCounty(order.parties.delivery.addressLine4):"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">Postcode</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.postalCode !==undefined)?order.parties.delivery.postalCode:"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">Country</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.country !==undefined)?order.parties.delivery.country:"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">Telephone</th>
                            <td style={{color: "red",fontWeight: "bold", fontSize: "20px"}}>{(order.parties!==undefined&&order.parties.delivery.telephone !==undefined)? checkValidPhoneNumber(order.parties.delivery.telephone) :"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">Mobile</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.mobileTelephone !==undefined)?order.parties.delivery.mobileTelephone:"" }</td>
                        </tr>
                        <tr>
                            <th scope="row">Email address</th>
                            <td>{(order.parties!==undefined&&order.parties.delivery.email !==undefined)?order.parties.delivery.email:"" }</td>
                        </tr>
                        </tbody>
                    </table>
                    {
                        (orderRows!==undefined && props.orderItems.length > 0)&&
                            <table style={{boxShadow: "0 10px 6px -6px #777"}} className="table">
                                <thead>
                                <tr>
                                <th scope="col">Line#</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Qty</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orderRows.map((ol,index)=>{
                                    return <tr key={index}>
                                    <th scope="row">{ol.OrderLine}</th>
                                    <td>{ol.ItemCode}</td>
                                    <td>{ol.ItemQty}</td>
                                    </tr>
                                    })
                                }
                                </tbody>
                            </table>
                    }
                    {
                        (orderRows!==undefined && props.orderItems.length === 0)&&
                        <table style={{boxShadow: "0 10px 6px -6px #777"}} className="table">
                            <thead>
                            <tr>
                                <th scope="col">Line#</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Name</th>
                                <th scope="col">Qty</th>
                                <th scope="col">Line Total</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                orderRows.map((ol,index)=>{
                                    //console.log(ol)
                                    return <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>{ol.productSku}</td>
                                        <td>{ol.productName}</td>
                                        <td>{parseInt(ol.quantity.magnitude)}</td>
                                        <td>{parseFloat(ol.rowValue.rowNet.value).toFixed(2)}</td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    }
                    </div>
                    <div className={'col'}>
                        <h2>Google Map</h2>
                        {selectedGoogleMapInfo ? <div>
                        <MyGoogleMap
                            containerElement={ <div style={{ height: `500px`, width: '580px', boxShadow: "0 8px 6px -6px black" }} /> }
                            mapElement={ <div style={{ height: `100%` }} /> }
                        />
                        <Divider/>
                        <Descriptions title="Formatted Address">
                            <Descriptions.Item label="google address"><span style={{color: "red", backgroundColor: "yellow"}}>{selectedGoogleMapInfo.formatted_address}</span></Descriptions.Item>
                        </Descriptions>

                        </div> : <Empty     description={
                            <span>
                                Cannot find this address by google map and this means the address on BP is not correct
                            </span>
                            } image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </div>
                    <div className={'col'}>
                        <h2>Shipment Information</h2>
                        {props.isProcessing &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Processing...</div>}
                        <table className="table" style={{margin:'16px 0 0',marginBottom: 30, boxShadow: "0 10px 6px -6px #777"}}>
                            <tbody>
                            {(order.parties!==undefined&&order.parties.delivery.countryIsoCode !=="CA") &&
                            <tr>
                                <th>FEIN #</th>
                                <td>
                                    <input name={'FEIN'} type={'text'} value={props.FEIN} style={{width:'100px',textAlign:'center'}} onChange={props.simpleOnchange}/>
                                </td>
                            </tr>
                            }
                            <tr>
                                <th>Ship Warehouse</th>
                                <td>
                                    <select name={'Warehouse'}  style={{width:'100%',height:'30px'}} onChange={props.shipmentOnChange} value={selectedRow.Warehouse}>
                                        {BrightPearlWarehousesName.map((op,i)=>{
                                            return <option value={op} key={i}>{op}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>Total Value</th>
                                <td>
                                    <input name={'TotalValue'} type={'text'} value={props.shippingInfo.TotalValue} style={{width:'100px',textAlign:'center'}} onChange={props.shippingInfoOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th>Declare Value / Insurance?</th>
                                <td>
                                    <input type="checkbox"  name={'declareValueInsurance'} style={{width:'30px',height:'30px'}} checked={props.declareValueInsurance} onChange={props.simpleOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th>Weight</th>
                                <td>
                                    <input name={'ShippableWeightGrams'} type={'text'} value={props.shippingInfo.ShippableWeightGrams} style={{width:'100px',textAlign:'center'}} onChange={props.shippingInfoOnchange}/>Gram
                                </td>
                            </tr>
                            <tr>
                                <th style={{color:'red'}}>!!! Size ( For Each Box ) !!!</th>
                                <th>
                                    L:<input name={'ShippableHeightCm'} type={'text'} style={{width:'50px',textAlign:'center'}}  value={props.shippingInfo.ShippableHeightCm} onChange={props.shippingInfoOnchange}/>(cm) &nbsp;&nbsp;&nbsp;
                                    W:<input name={'ShippableWidthCm'}  type={'text'} style={{width:'50px',textAlign:'center'}} value={props.shippingInfo.ShippableWidthCm} onChange={props.shippingInfoOnchange}/>(cm) &nbsp;&nbsp;&nbsp;
                                    H:<input name={'ShippableDepthCm'} type={'text'} style={{width:'50px',textAlign:'center'}} value={props.shippingInfo.ShippableDepthCm} onChange={props.shippingInfoOnchange}/>(cm)
                                </th>
                            </tr>
                            <tr>
                                <th style={{color:'red'}}>!!! Number of Box !!!</th>
                                <td>
                                    <input name={'numberOfBox'} type={'text'} value={props.numberOfBox} style={{width:'100px',textAlign:'center'}} onChange={props.simpleOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th>Pickup Schedule</th>
                                <th>
                                    FROM:&nbsp;<select name={'scheduleStart'}  onChange={props.scheduleOnChange} value={props.scheduleStart}>
                                        <option value= "1200" >1200</option>
                                        <option value= "1230" >1230</option>
                                        <option value= "1300" >1300</option>
                                        <option value= "1330" >1330</option>
                                        <option value= "1400" >1400</option>
                                        <option value= "1430" >1430</option>
                                        <option value= "1500" >1500</option>
                                        <option value= "1530" >1530</option>
                                        <option value= "1600" >1600</option>
                                    </select>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    TO:&nbsp;<select name={'scheduleEnd'}  onChange={props.scheduleOnChange} value={props.scheduleEnd}>
                                        <option value= "1300" >1300</option>
                                        <option value= "1330" >1330</option>
                                        <option value= "1400" >1400</option>
                                        <option value= "1430" >1430</option>
                                        <option value= "1500" >1500</option>
                                        <option value= "1530" >1530</option>
                                        <option value= "1600" >1600</option>
                                        <option value= "1630" >1630</option>
                                        <option value= "1700" >1700</option>
                                    </select>
                                </th>
                            </tr>
                            <tr>
                                <th>Reference #1</th>
                                <td>
                                    <input name={'reference1'} type={'text'} value={props.Reference1} style={{width:'100px',textAlign:'center'}} onChange={props.simpleOnchange}/>
                                </td>
                            </tr>
                            <tr>
                                <th>Reference #2</th>
                                <td>
                                    <input name={'reference2'} type={'text'} value={props.Reference2} style={{width:'100px',textAlign:'center'}} onChange={props.simpleOnchange}/>
                                </td>
                            </tr>
                            { selectedRow.Status !== "USPSCreated" &&
                            <tr>
                                <th>Use 3rd Party Account ?</th>
                                <td>
                                    <input type="checkbox"  name={'use3rdPartyAccount'} style={{width:'30px',height:'30px'}} checked={props.use3rdPartyAccount} onChange={props.simpleOnchange}/>
                                </td>
                            </tr>
                            }
                            {
                                (props.use3rdPartyAccount && selectedRow.Status !== "USPSCreated") &&
                                <tr>
                                    <th>3rd Party Account #</th>
                                    <td>
                                        <input type="text"  name={'thirdPartyAccountNum'}  value={props.thirdPartyAccountNum} onChange={props.simpleOnchange}/>
                                    </td>
                                </tr>
                            }
                            {
                                (props.use3rdPartyAccount && selectedRow.Status !== "USPSCreated") &&
                                <tr>
                                    <th>3rd Party Account Address</th>
                                    <td>
                                        {/*/!*<textarea name={'thirdPartyAccountAddress'} rows={'3'} value={props.thirdPartyAccountAddress} onChange={props.simpleOnchange} style={{width:'100%'}}/>*!/*/}
                                        {/*Name <input name={'thirdPartyAccountAddressName'} value={props.thirdPartyAccountAddressName} onChange={props.simpleOnchange} style={{width:'50%'}}/><br/>*/}
                                        {/*Address Line1 <input name={'thirdPartyAccountAddress1'} value={props.thirdPartyAccountAddress1} onChange={props.simpleOnchange} style={{width:'50%'}}/><br/>*/}
                                        {/*Address Line2 <input name={'thirdPartyAccountAddress2'} value={props.thirdPartyAccountAddress2} onChange={props.simpleOnchange} style={{width:'50%'}}/><br/>*/}
                                        {/*Address Line3 <input name={'thirdPartyAccountAddress3'} value={props.thirdPartyAccountAddress3} onChange={props.simpleOnchange} style={{width:'50%'}}/><br/>*/}
                                        {/*City<input name={'thirdPartyAccountAddressCity'} value={props.thirdPartyAccountAddressCity} onChange={props.simpleOnchange} />State<input name={'thirdPartyAccountAddressStateCode'} value={props.thirdPartyAccountAddressStateCode} onChange={props.simpleOnchange} /><br/>*/}
                                        <span className={'row'} style={{margin:'0 5px'}}>
                                            <span className={'col'} style={{fontWeight:'bold'}}>Country Code</span><input className={'col'} name={'thirdPartyAccountAddressCountryCode'} value={props.thirdPartyAccountAddressCountryCode} onChange={props.simpleOnchange} /><br/>
                                        </span>
                                        <span className={'row'} style={{margin:'0 5px'}}>
                                            <span className={'col'} style={{fontWeight:'bold'}}>Postal Code</span><input className={'col'} name={'thirdPartyAccountAddressPostalCode'} style={{width:'80%'}} value={props.thirdPartyAccountAddressPostalCode} onChange={props.simpleOnchange} />
                                        </span>
                                    </td>
                                </tr>
                            }


                            {/*{selectedRow.Status !== "USPSCreated" &&*/}
                                {/*<tr>*/}
                                    {/*<th>Carrier</th>*/}
                                    {/*<td>*/}
                                        {/*{(order.parties!==undefined&&order.parties.delivery.countryIsoCode !=="CA") &&*/}
                                        {/*<select name={'selectedCarrier'}  style={{width:'100%',height:'30px'}}  onChange={props.carrierOnchange} value={props.selectedCarrier}>*/}
                                            {/*<option value="optionDefaultHeader" >-- select a Carrier --</option>*/}
                                            {/*<option value="USPS" >USPS</option>*/}
                                            {/*<option value="FedEx" >FedEx</option>*/}
                                            {/*<option value="Purolator" >Purolator</option>*/}
                                            {/*<option value="UPS" >UPS</option>*/}
                                        {/*</select>*/}
                                        {/*}*/}
                                        {/*{(order.parties!==undefined&&order.parties.delivery.countryIsoCode ==="CA") &&*/}
                                        {/*<select name={'selectedCarrier'}  style={{width:'100%',height:'30px'}}  onChange={props.carrierOnchange} value={props.selectedCarrier}>*/}
                                            {/*<option value="optionDefaultHeader" >-- select a Carrier --</option>*/}
                                            {/*<option value="Canada Post" >Canada Post</option>*/}
                                            {/*<option value="UPS" >UPS</option>*/}
                                            {/*<option value="Canpar" >Canpar</option>*/}
                                            {/*<option value="FedEx" >FedEx</option>*/}
                                            {/*<option value="Purolator" >Purolator</option>*/}
                                        {/*</select>*/}
                                        {/*}*/}
                                    {/*</td>*/}
                                {/*</tr>*/}
                            {/*}*/}
                            {
                                (props.selectedCarrier !=="USPS" && selectedRow.Status !== "USPSCreated")&&
                                <tr>
                                    <th>Options</th>
                                    <td>
                                        <select name={'selectedOptions'}  style={{width:'100%',height:'30px'}} onChange={props.simpleOnchange} value={props.selectedOptions}>
                                            <option value="optionDefaultHeader" >-- No action required --</option>
                                            <option value="Signature Required" >Signature Required</option>
                                            <option value="Do not safe drop" >Do not safe drop</option>
                                            <option value="Leave at door" >Leave at door</option>
                                        </select>
                                    </td>
                                </tr>
                            }
                            {
                                (props.selectedCarrier !=="USPS" && selectedRow.Status !== "USPSCreated")&&
                                <tr>
                                    <td colSpan={'2'}>
                                        <button className={'btn btn-success btn-block'} type={'button'} onClick={props.getRates}>Get Estimate</button>
                                    </td>
                                </tr>
                            }
                            {/*{(shippingRates.length!==0 && props.selectedCarrier !=="USPS" && selectedRow.Status !== "USPSCreated") &&*/}
                                {/*<tr>*/}
                                    {/*<th>Service</th>*/}
                                    {/*<td>*/}
                                        {/*<select name={'selectedShippingMethod'}  style={{width:'100%',height:'30px'}} onChange={props.simpleOnchange} value={props.selectedShippingMethod}>*/}
                                            {/*{shippingRates.map((op,i)=>{*/}
                                                {/*return <option value={op.serviceName} key={i}>{op.serviceName} - ${op.totalCost} - by {op.deliverByDate}</option>*/}
                                            {/*})}*/}
                                        {/*</select>*/}
                                    {/*</td>*/}
                                {/*</tr>*/}
                            {/*}*/}
                            </tbody>
                        </table>
                        {(selectedRow.Status !== "USPSCreated" && order.parties!==undefined&&order.parties.delivery.countryIsoCode ==="CA")&&
                            <table style={{boxShadow: "0 10px 6px -6px #777"}} className={'table'}>
                                <tbody>
                                { (props.CanparRate.length> 0 || props.CanadaPostRate.length> 0 || props.UPSRate.length > 0 || props.FedExRate.length > 0 || props.PurolatorRate.length > 0)&&
                                    <tr>
                                        <th scope="col">*</th>
                                        <th scope="col">Carrier</th>
                                        <th scope="col">Service</th>
                                        <th scope="col">Total Cost (CAD)</th>
                                        <th scope="col">Deliver By Date</th>
                                    </tr>
                                }
                                {props.CanadaPostRate.map((r,i)=>{
                                    return <tr key={i}>
                                        <td scope="col"><input type={'radio'} name={'selectedShippingMethod'}  onChange={()=>props.carrierOnchange('Canada Post',r)} style={{width:'25px',height:'25px'}}/></td>
                                        <td scope="col">Canada Post</td>
                                        <td scope="col">{r.serviceName}</td>
                                        <td scope="col">{r.totalCost}</td>
                                        <td scope="col">{r.deliverByDate}</td>
                                    </tr>
                                })}
                                {props.CanparRate.map((r,i)=>{
                                    return <tr key={i}>
                                        <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('Canpar',r)} style={{width:'25px',height:'25px'}}/></td>
                                        <td scope="col">Canpar</td>
                                        <td scope="col">{r.serviceName}</td>
                                        <td scope="col">{r.totalCost}</td>
                                        <td scope="col">{r.deliverByDate}</td>
                                    </tr>
                                })}
                                {props.UPSRate.map((r,i)=>{
                                    return <tr key={i}>
                                        <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('UPS',r)} style={{width:'25px',height:'25px'}}/></td>
                                        <td scope="col">UPS</td>
                                        <td scope="col">{r.Service.Description}</td>
                                        <td scope="col">{r.TotalCharges.MonetaryValue}</td>
                                        <td scope="col">{r.Delivery}</td>
                                    </tr>
                                })}
                                {props.FedExRate.map((r,i)=>{
                                    return <tr key={i}>
                                        <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('FedEx',r)} style={{width:'25px',height:'25px'}}/></td>
                                        <td scope="col">FedEx</td>
                                        <td scope="col">{r.serviceName}</td>
                                        <td scope="col">{r.totalCost}</td>
                                        <td scope="col">{r.deliverByDate}</td>
                                    </tr>
                                })}
                                {props.PurolatorRate.map((r,i)=>{
                                    return <tr key={i}>
                                        <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('Purolator',r)} style={{width:'25px',height:'25px'}}/></td>
                                        <td scope="col">Purolator</td>
                                        <td scope="col">{r.serviceName}</td>
                                        <td scope="col">{r.totalCost}</td>
                                        <td scope="col">{r.deliverByDate}</td>
                                    </tr>
                                })}
                                </tbody>
                            </table>
                        }
                        {(selectedRow.Status !== "USPSCreated" && order.parties!==undefined&&order.parties.delivery.countryIsoCode !=="CA")&&
                        <table style={{boxShadow: "0 10px 6px -6px #777"}} className={'table'}>
                            <tbody>
                            { (props.CanadaPostRate.length> 0 || props.UPSRate.length > 0 || props.FedExRate.length > 0 || props.PurolatorRate.length > 0 )&&
                            <tr>
                                <th scope="col">*</th>
                                <th scope="col">Carrier</th>
                                <th scope="col">Service</th>
                                <th scope="col">Total Cost (CAD)</th>
                                <th scope="col">Deliver By Date</th>
                            </tr>
                            }
                            <tr>
                                <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('USPS',{})} style={{width:'25px',height:'25px'}}/></td>
                                <td scope="col">USPS</td>
                                <td scope="col"></td>
                                <td scope="col"></td>
                                <td scope="col"></td>
                            </tr>

                            {props.UPSRate.map((r,i)=>{
                                return <tr key={i}>
                                    <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('UPS',r)} style={{width:'25px',height:'25px'}}/></td>
                                    <td scope="col">UPS</td>
                                    <td scope="col">{r.Service.Description}</td>
                                    <td scope="col">{r.TotalCharges.MonetaryValue}</td>
                                    <td scope="col">{r.Delivery}</td>
                                </tr>
                            })}
                            {props.FedExRate.map((r,i)=>{
                                return <tr key={i}>
                                    <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('FedEx',r)} style={{width:'25px',height:'25px'}}/></td>
                                    <td scope="col">FedExRate</td>
                                    <td scope="col">{r.serviceName}</td>
                                    <td scope="col">{r.totalCost}</td>
                                    <td scope="col">{r.deliverByDate}</td>
                                </tr>
                            })}
                            {props.CanadaPostRate.map((r,i)=>{
                                return <tr key={i}>
                                    <td scope="col"><input type={'radio'} name={'selectedShippingMethod'}  onChange={()=>props.carrierOnchange('Canada Post',r)} style={{width:'25px',height:'25px'}}/></td>
                                    <td scope="col">Canada Post</td>
                                    <td scope="col">{r.serviceName}</td>
                                    <td scope="col">{r.totalCost}</td>
                                    <td scope="col">{r.deliverByDate}</td>
                                </tr>
                            })}
                            {props.PurolatorRate.map((r,i)=>{
                                return <tr key={i}>
                                    <td scope="col"><input type={'radio'} name={'selectedShippingMethod'} onChange={()=>props.carrierOnchange('Purolator',r)} style={{width:'25px',height:'25px'}}/></td>
                                    <td scope="col">Purolator</td>
                                    <td scope="col">{r.serviceName}</td>
                                    <td scope="col">{r.totalCost}</td>
                                    <td scope="col">{r.deliverByDate}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                        }
                        <br/>
                            {props.creatingShipment &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"> </i> &nbsp; Creating Shipment...<br/><br/></div>}
                        {
                            (selectedRow.Status !== "USPSCreated" && ( props.selectedCarrier==="USPS" || props.selectedShippingMethod !==""))&&
                            <button className={'btn col btn-success'} type={'button'}
                                    onClick={props.createShipment}>Create Shipment</button>
                        }
                        {
                            (selectedRow.Status === "USPSCreated" && props.attachBase64===undefined)&&
                            <button className={'btn col btn-success'} type={'button'}
                                    onClick={()=>{$('#AshlinProformaInvoiceModal').modal('show');}}>Generate Proforma Invoice</button>
                        }
                        {
                            (selectedRow.Status === "USPSCreated" && selectedRow.Warehouse==="LS14 LeanSupply 3PL" && props.attachBase64!==undefined)&&
                            <button className={'btn col btn-success'} type={'button'}
                                    onClick={props.sendUSPSto3PL}>Send to 3PL</button>
                        }
                        {
                            (selectedRow.Status === "USPSCreated" && selectedRow.Warehouse==="Main warehouse")&&
                            <button className={'btn col btn-success'} type={'button'}
                                    onClick={props.sendUSPSLocal}>Ship Locally</button>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>)
};
export default createManualOrderShipment