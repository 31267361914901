import React,{ useState} from 'react';
import TextInputParallel from '../../bootstrapComponent/textInputParallel';
import SelectInput from '../../bootstrapComponent/selectInput';
import CheckBox from '../../bootstrapComponent/checkBox';
import SelectTrueFalse from '../../bootstrapComponent/selectInputWithTrueFalse';
import TextParallelWithAddition from '../../bootstrapComponent/textParallelWithAddition';
import TextArea from '../../bootstrapComponent/textArea'
import SelectInputWithDefault from '../../bootstrapComponent/selectInputWithDefault';
import TextAreaShort from '../../bootstrapComponent/textAreaShort';
import {countries} from '../../../Utils/country';
const UpdateDesignsModal = ({id,subFamilies,updateDesign,onChange, updateItem, Activate,
                                DeActivate,translate,ashlinDesign,shipmentCalculation,translateWithField,generateSEOSection,onModalClose})=>{
    const ashlinSubFamilyCode = subFamilies.map(i=>i.shortENG);
    const ashlinDesignCode = ashlinDesign.map(i=>i.designCode);
    //console.log(updateDesign.pickedSubFamily);
    const selectSubFamily = subFamilies.find(f=>f.subFamilyCode === updateDesign.pickedSubFamily);
    //console.log(selectSubFamily.shortENG);
    const [selectedDesignCode,setSelectedDesignCode] = useState('optionDefaultHeader');
   
    const onUpdateModalClose = async() => {
        setSelectedDesignCode('optionDefaultHeader');
        onModalClose()
    }

    const onChangeSelect = (e) => {
        setSelectedDesignCode(e.target.value);
        onChange(e,'updateDesign');
    }

     return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="upDateDesignModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="upDateDesignModalLabel" style={{textAlign:'center !important'}}>Update A Design</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onUpdateModalClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">

                        <SelectInput  id={'update_design_code'} label={'Design Code'} name={'designCode'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={ashlinDesignCode}  defaultValue={selectedDesignCode}   onChange={(e)=>onChangeSelect(e)} />
                        <TextInputParallel id='update_design_designHTSDescription' label={'Design HTS Description'}
                                           value={updateDesign.designHTSDescription} name='designHTSDescription' onChange={(e)=>onChange(e,'updateDesign')}/>
                        {selectSubFamily !==undefined?
                            <SelectInput  id={'update_design_sub_family'} label={'Associated Sub-Family'} name={'pickedSubFamily'} width={'450px'} defaultValue={selectSubFamily.shortENG}
                                      style={{fontWeight:'bold',marginBottom:'0'}}     value={ashlinSubFamilyCode}    onChange={(e)=>onChange(e,'updateDesign')}/>:
                            <SelectInput  id={'update_design_sub_family'} label={'Associated Sub-Family'} name={'pickedSubFamily'} width={'450px'} defaultValue={updateDesign.pickedSubFamily}
                                          style={{fontWeight:'bold',marginBottom:'0'}}     value={ashlinSubFamilyCode}    onChange={(e)=>onChange(e,'updateDesign')}/>

                        }
                        <SelectInput  id={'update_design_brand'} label={'Brand'} name={'brand'} width={'450px'} defaultValue={updateDesign.brand}
                                      style={{fontWeight:'bold',marginBottom:'0'}}     value={['6ixHide™','Ashlin®','PoppyBuzz™']}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right'}}>
                                <p style={{fontSize:"15px", fontWeight:"bold"}}>Design-Service Flag:</p>
                            </div>
                            <div className={'col-8'} style={{paddingLeft:'0'}}>
                                <div className={'row'}>
                                    <select style={{width:'50%',margin:'0 17px',fontSize:'20px !important'}} value={updateDesign.designServiceFlag} onChange={(e)=>onChange(e,'updateDesign')} name={'designServiceFlag'} className='col'>
                                        <option value='design'>Design</option>
                                        <option value='service'>Service</option>
                                    </select>
                                    <CheckBox  value={updateDesign.giftBox === 1}  id={'add-design-gitfBox'} style={{width:'40%'}} className={'col'}
                                               onChange={(e)=>onChange(e,'updateDesign')} name={'giftBox'} label={'Gift Box'}/>
                                </div>
                            </div>
                        </div>
                        <TextInputParallel id='update_design_ashlin_name' label={'Internal Ashlin Name'}
                                           value={updateDesign.internalAshlinName} name='internalAshlinName' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_general_name' label={'General Name'}
                                           value={updateDesign.generalName} name='generalName' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_online_name' label={'Online Name'}
                                           value={updateDesign.onlineName} name='onlineName' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectInputWithDefault  id={'update_design_country'} label={'Country of Origin'} name={'country'} width={'450px'} defaultValue={updateDesign.country}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}     value={countries}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectInputWithDefault  id={'update_design_shipping_packaging'} label={'Shipping Packaging'} name={'shippingPackaging'} width={'450px'} defaultValue={updateDesign.shippingPackaging}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}     value={["BUBBLE PACK","CARTON"]}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <div className={'row justify-content-center'}>
                            <CheckBox  value={updateDesign.active === 1}  id={'update-design-active'}
                                       onChange={(e)=>onChange(e,'updateDesign')} name={'active'} label={'Is this Design Active'}/>
                        </div>
                        <hr/>
                        <div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                                </div>
                                <div className={'col-1'} >
                                    <button className={'btn-info'} style={{fontSize:'15px !important',marginLeft:'-35px'}} onClick={()=>{translate('updateDesign')}}>TRANSLATE</button>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center'}}>Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.shortENG}
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'shortENG'}>
                                    </TextAreaShort>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.shortFR}
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'shortFR'}>
                                    </TextAreaShort>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center',marginTop:'15px'}}>Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.longENG} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'longENG'}>
                                    </TextAreaShort>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.longFR} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'longFR'}>
                                    </TextAreaShort>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center'}}>Web Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.webENG} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'webENG'}>
                                    </TextAreaShort>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.webFR} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'webFR'}>
                                    </TextAreaShort>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center'}}>Trend Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.shortTrendENG}
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'shortTrendENG'}>
                                    </TextAreaShort>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.shortTrendFR}
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'shortTrendFR'}>
                                    </TextAreaShort>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center',margin:'15px'}}>Trend Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.longTrendENG} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'longTrendENG'}>
                                    </TextAreaShort>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.longTrendFR} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'longTrendFR'}>
                                    </TextAreaShort>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <TextInputParallel id='update_design_num_component' label={'Number of Component'}
                                           value={updateDesign.numComponent} name='numComponent' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_imprint_height' label={'Imprint Height(cm)'}
                                           value={updateDesign.imprintHeight} name='imprintHeight' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_imprint_width' label={'Imprint Width(cm)'}
                                           value={updateDesign.imprintWidth} name='imprintWidth' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextParallelWithAddition id='update_design_product_height' label={'Product Height(cm)'}
                                                  value={updateDesign.productHeight} name='productHeight' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextParallelWithAddition id='update_design_product_width' label={'Product Width(cm)'}
                                                  value={updateDesign.productWidth} name='productWidth' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextParallelWithAddition id='update_design_product_depth' label={'Product Depth(cm)'}
                                                  value={updateDesign.productDepth} name='productDepth' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_product_weight' label={'Product Weight (grams)'}
                                           value={updateDesign.productWeight} name='productWeight' onChange={(e)=>onChange(e,'updateDesign')}/>


                        <SelectTrueFalse id={'update_design_flat'} label={'Can this item be shipped flat?'} name={'flat'} width={'450px'}
                                         defaultValue={updateDesign.flat}  style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>

                        <button onClick={(e)=>shipmentCalculation(e,'updateDesign')} className={'btn-block btn-success'}>Calculate Shipment </button>
                        <TextParallelWithAddition id='update_design_product_height' label={'Shipment Height(cm)'}
                                                  value={updateDesign.shipmentHeight} name='shipmentHeight' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextParallelWithAddition id='update_design_product_width' label={'Shipment Width(cm)'}
                                                  value={updateDesign.shipmentWidth} name='shipmentWidth' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextParallelWithAddition id='update_design_product_depth' label={'Shipment Depth(cm)'}
                                                  value={updateDesign.shipmentDepth} name='shipmentDepth' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_product_weight' label={'Shipment Weight (grams)'}
                                           value={updateDesign.shipmentWeight} name='shipmentWeight' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <hr/>
                        <TextInputParallel id='update_design_shoulder_drop_length' label={'Shoulder Drop Length(cm)'}
                                           value={updateDesign.shoulderDropLength} name='shoulderDropLength' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_handle_total_length' label={'Handle Total Length(cm)'}
                                           value={updateDesign.handleTotalLength} name='handleTotalLength' onChange={(e)=>onChange(e,'updateDesign')}/>
                        <TextInputParallel id='update_design_card_slot' label={'Card Slots'}
                                           value={updateDesign.cardSlots} name='cardSlots' onChange={(e)=>onChange(e,'updateDesign')}/>

                        <SelectInputWithDefault  id={'update_design_notable_strap'} label={'Notable Strap Features'} name={'notableStrap'} width={'450px'} defaultValue={updateDesign.notableStrap}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}
                                                 value={['1 long','2 long','2 short','1 short','top handle strap','2 short straps + long strap','no strap']}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectInputWithDefault  id={'update_design_size_differentiation'} label={'Size Differentiation'} name={'sizeDifferentiation'} width={'450px'} defaultValue={updateDesign.sizeDifferentiation}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}
                                                 value={['Mini','Small','Medium','Large','X-Large','XX-Large']}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectInputWithDefault  id={'update_design_bill_compartment'} label={'Bill Compartment'} name={'billCompartment'} width={'450px'} defaultValue={updateDesign.billCompartment}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}
                                                 value={['2','1','0']}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectInputWithDefault  id={'update_design_closure'} label={'Closure'} name={'closure'} width={'450px'} defaultValue={updateDesign.closure}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}
                                                 value={['Zippered','Flap','Open','Button','Fold-Over','Drawstring','Velcro']}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectInput  id={'update_design_style'} label={'STYLE'} name={'style'} width={'450px'}
                                      style={{fontWeight:'bold',marginBottom:'0'}} defaultValue={updateDesign.style}
                                      value={['Bi-Fold','Tri-Fold','Clutch','Zippered Clutch','Wristlet','Wristlet with Strap',
                                          'Card Holder','Change Bag','Checkbook','Utility Kit','Drawstring','Bucket','Mini-Knapsack',
                                          'Coin Holder','Cosmetic Bag','Envelope','Evening Bag','Money Clip','Select Style']}    onChange={(e)=>onChange(e,'updateDesign')}/>

                        <SelectTrueFalse id={'update_design_protective_feet'} label={'Protective Feet'} name={'protectiveFeet'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}  defaultValue={updateDesign.protectiveFeet}     onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_inner_pocket'} label={'Inner Pocket'} name={'innerPocket'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}  defaultValue={updateDesign.innerPocket}       onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_outside_pocket'} label={'Outside Pocket'} name={'outsidePocket'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}  defaultValue={updateDesign.outsidePocket}       onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_dust_bag'} label={'Dust Bag Included'} name={'dustBagIncluded'} width={'450px'} defaultValue={updateDesign.dustBagIncluded}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_authenticity_card'} label={'Authenticity Card Included'} name={'authenticityCardIncluded'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}   defaultValue={updateDesign.authenticityCardIncluded}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <hr/>
                        <SelectTrueFalse id={'update_design_monogrammed'} label={'Can this item be monogrammed?'} name={'monogrammed'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}   defaultValue={updateDesign.monogrammed}    onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_imprinted'} label={'Can this item be imprinted?'} name={'imprinted'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}   defaultValue={updateDesign.imprinted}    onChange={(e)=>onChange(e,'updateDesign')}/>

                        <SelectTrueFalse id={'update_design_strap'} label={'Does this item have s strap'} name={'strap'} width={'450px'} defaultValue={updateDesign.strap}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_detachable'} label={'Is the strap detachable?'} name={'detachable'} width={'450px'}
                                         defaultValue={updateDesign.detachable}   style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_enclosure'} label={'Does this item have a zipped enclosure?'} name={'enclosure'} width={'450px'}
                                         defaultValue={updateDesign.enclosure}   style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>

                        <SelectTrueFalse id={'update_design_folded'} label={'Can this item be shipped folded?'} name={'folded'} width={'450px'}
                                         defaultValue={updateDesign.folded}   style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>
                        <hr/>
                        <div className={'row'} >
                            <div className={'col-3'}>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                            </div>
                            <div className={'col-1'} >
                                <button className={'btn-info'} style={{fontSize:'15px !important',marginLeft:'-35px'}} onClick={()=>{translateWithField('updateDesign','options')}}>TRANSLATE</button>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>FRENCH</h5>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 1</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op1ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op1ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op1FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op1FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 2</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op2ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op2ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op2FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op2FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 3</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op3ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op3ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op3FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op3FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 4</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op4ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op4ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op4FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op4FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 5</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op5ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op5ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.op5FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'op5FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <hr/>



                        <label className={'bg-info text-white btn-block'} style={{fontSize:'20px',textAlign:'center'}}>SEO SECTION</label>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <button className={'btn-success'} onClick={(e)=>generateSEOSection(e,updateDesign,'updateDesign')}>Generate SEO</button>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                            </div>
                            <div className={'col-1'} >
                                <button className={'btn-info'} style={{fontSize:'15px !important',marginLeft:'-35px'}} onClick={()=>{translateWithField('updateDesign','SEOSections')}}>TRANSLATE</button>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>FRENCH</h5>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Short Description</h5>
                            </div>
                            <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.SEOShortENG !== undefined? updateDesign.SEOShortENG:""}
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'SEOShortENG'}>
                                    </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.SEOShortFR !== undefined? updateDesign.SEOShortFR:""}
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'SEOShortFR'}>
                                    </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center',marginTop:'15px'}}>SEO Long Description</h5>
                            </div>
                            <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.SEOLongENG !== undefined? updateDesign.SEOLongENG:""} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'SEOLongENG'}>
                                    </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                    <TextAreaShort style={{width:'100%'}} value={updateDesign.SEOLongFR !== undefined? updateDesign.SEOLongFR:""} rows="5"
                                              onChange={(e)=>onChange(e,'updateDesign')} name={'SEOLongFR'}>
                                    </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 1</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp1ENG !== undefined? updateDesign.SEOOp1ENG:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp1ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp1FR !== undefined? updateDesign.SEOOp1FR:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp1FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 2</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp2ENG !== undefined? updateDesign.SEOOp2ENG:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp2ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp2FR !== undefined? updateDesign.SEOOp2FR:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp2FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 3</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp3ENG !== undefined? updateDesign.SEOOp3ENG:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp3ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp3FR !== undefined? updateDesign.SEOOp3FR:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp3FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 4</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp4ENG !== undefined? updateDesign.SEOOp4ENG:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp4ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp4FR !== undefined? updateDesign.SEOOp4FR:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp4FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 5</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp5ENG !== undefined? updateDesign.SEOOp5ENG:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp5ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={updateDesign.SEOOp5FR !== undefined? updateDesign.SEOOp5FR:""} rows={'1'}
                                          onChange={(e)=>onChange(e,'updateDesign')} name={'SEOOp5FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <TextArea label={'SEO Meta Title'} value={updateDesign.SEOMetaTitle !== undefined? updateDesign.SEOMetaTitle:""} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateDesign')} name={'SEOMetaTitle'} />
                        <TextArea label={'SEO Meta Description'} value={updateDesign.SEOMetaDescription !== undefined? updateDesign.SEOMetaDescription:""}
                                  onChange={(e)=>onChange(e,'updateDesign')} name={'SEOMetaDescription'} />
                        <TextArea label={'SEO Meta Keywords'} value={updateDesign.SEOMetaKeywords !== undefined? updateDesign.SEOMetaKeywords:""}
                                  onChange={(e)=>onChange(e,'updateDesign')} name={'SEOMetaKeywords'} />
                        <TextArea label={'SEO Title'} value={updateDesign.SEOTitle !== undefined? updateDesign.SEOTitle:""} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateDesign')} name={'SEOTitle'} />
                        <TextArea label={'SEO Description'} value={updateDesign.SEODescription !== undefined? updateDesign.SEODescription:""}
                                  onChange={(e)=>onChange(e,'updateDesign')} name={'SEODescription'} />


                        <hr/>
                        <SelectTrueFalse id={'update_design_display_web'} label={'Display on the website?'} name={'displayOnWeb'} width={'450px'} defaultValue={updateDesign.displayOnWeb}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>
                        <SelectTrueFalse id={'update_design_display_corporate'} label={'Display on the corporate site?'} name={'displayOnCorporate'} width={'450px'} defaultValue={updateDesign.displayOnCorporate}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'updateDesign')}/>
                        <hr/>
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>updateItem(e,'updateDesign')}>Update Design</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'updateDesign','designCode')} >Deactivate Design</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'updateDesign','designCode')}>Activate Design</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default UpdateDesignsModal;

// SEOShortENG:'',
//     SEOShortFR:'',
//     SEOLongENG:'',
//     SEOLongFR:'',
//     SEOOp1ENG:'',
//     SEOOp1FR:'',
//     SEOOp2ENG:'',
//     SEOOp2FR:'',
//     SEOOp3ENG:'',
//     SEOOp3FR:'',
//     SEOOp4ENG:'',
//     SEOOp4FR:'',
//     SEOOp5ENG:'',
//     SEOOp5FR:'',
//     SEOMetaTitle:'',
//     SEOMetaKeywords:'',
//     SEOMetaDescription:'',
//     SEOTitle:'',
//     SEODescription:''