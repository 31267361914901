import { message } from "antd";
import axios from "axios/index";
import $ from "jquery";
import React, { Component } from "react";
import {
  addSkuPantoneColor,
  getAllSKUNumberList,
  getCostMatrix,
  getGetSkuPantoneColor,
  getHtsCa,
  getHtsUs,
  getSKU,
  getSkuTierCost,
  postNewImage,
  updateSKU,
  updateSkuPantoneColor,
  updateSkuTierCost,
} from "../../Utils/ashlinDesign";
import { baseURL } from "../../Utils/requestHelper";
import validationChecker from "../../Utils/Validation/ValidationChecker";
import AddImages from "../BigCommerce/components/addImages";
import NavItem from "../bootstrapComponent/navItem";
import TabContentItem from "../bootstrapComponent/tabContentItem";
import TextArea from "../bootstrapComponent/textArea";
import UpdateSKUInfo from "./UpdateSKUInfo";
class UpdateSKU extends Component {
  _mounted = false;
  constructor(props) {
    super(props);
    this.state = {
      skuNumberList: this.props.skuNumberList,
      HtsCdn: [],
      HtsUs: [],
      selectedSkuDetail: {},
      skuPantoneColor: {},
      newPantoneColor: false,
      tierMatrix: null,
      skuCost: null,
      newSkuCost: false,
      BASE: [0],
      MODEL: [0],
      LIFE: [0],
      GROUP: [0],
      MAIN: [0],
      isUploading: false,
    };
  }
  componentWillUnmount() {
    this._mounted = false;
  }
  getData = async () => {
    const skuNumberList = await getAllSKUNumberList();
    const HtsCdn = await getHtsCa();
    const HtsUs = await getHtsUs();
    Promise.all([skuNumberList]).then(() => {
      if (this._mounted) {
        this.setState({
          skuNumberList,
          HtsCdn,
          HtsUs,
        });
      }
    });
  };
  componentDidMount() {
    this._mounted = true;
    this.getData();
  }
  /*******UPDATE SKU INFO START********/
  handleSKUNunberOnchange = async (e) => {
    const selected = e.target.value;
    if (selected === "optionDefaultHeader") {
      this.setState({
        selectedSkuDetail: {},
      });
    } else {
      const selectedSkuDetail = await getSKU(selected);
      let skuPantoneColor = await getGetSkuPantoneColor(selected);
      let newPantoneColor = this.state.newPantoneColor;
      const skuCost = await getSkuTierCost(selected);

      //console.log(skuCost);
      if (skuPantoneColor === "No record") {
        skuPantoneColor = {};
        newPantoneColor = true;
      } else {
        newPantoneColor = false;
      }
      if (skuCost.Qty1Price === undefined) {
        alert("Need update Cost Table");
        const tierMatrixRes = await getCostMatrix(
          selectedSkuDetail.PricingTier
        );
        const tierMatrix = tierMatrixRes.data;
        skuCost.PricingTier = selectedSkuDetail.PricingTier;
        skuCost.KaplUnitCostCadS = 1;
        this.calculateSkuCost(skuCost, tierMatrix);
        //console.log(tierMatrixRes);
        //console.log(skuCost);
        this.setState(
          {
            tierMatrix,
            skuCost,
            selectedSkuDetail,
            skuPantoneColor,
            newPantoneColor,
            BASE: [0],
            MODEL: [0],
            LIFE: [0],
            GROUP: [0],
            MAIN: [0],
            newSkuCost: true,
          },
          () => {
            $("#tierCostModal").modal("show");
          }
        );
      } else {
        this.setState({
          selectedSkuDetail,
          skuPantoneColor,
          newPantoneColor,
          tierMatrix: null,
          skuCost: null,
          BASE: [0],
          MODEL: [0],
          LIFE: [0],
          GROUP: [0],
          MAIN: [0],
          newSkuCost: false,
        });
      }
    }
  };
  handleSkuPantoneColorOnChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const skuPantoneColor = this.state.skuPantoneColor;
    skuPantoneColor[name] = value;
    this.setState({
      skuPantoneColor,
    });
  };
  handleHtsOnchange = async (e) => {
    const value = e.target.value;
    const name = e.target.name;
    if (value !== "optionDefaultHeader") {
      const HtsArray = this.state[name];
      const selectedSkuDetail = this.state.selectedSkuDetail;
      let selected;
      if (name === "HtsCdn") {
        selected = HtsArray.find((h) => h.HtsCa1 === value);
        selectedSkuDetail.DutyCdn = selected.CaDuty;
        selectedSkuDetail.HtsCdn = value;
      } else {
        selected = HtsArray.find((h) => h.HtsUs1 === value);
        selectedSkuDetail.DutyUs = selected.UsDuty;
        selectedSkuDetail.HtsUs = value;
      }
      this.setState({
        selectedSkuDetail,
      });
    }
  };
  handleSKUDetailOnChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    const selectedSkuDetail = this.state.selectedSkuDetail;
    selectedSkuDetail[name] = value;
    this.setState({
      selectedSkuDetail,
    });
  };

  handleChangeTierPrice = async (e) => {
    e.preventDefault();
    const tierMatrixRes = await getCostMatrix(
      this.state.selectedSkuDetail.PricingTier
    );
    const tierMatrix = tierMatrixRes.data;
    const skuCost = await getSkuTierCost(
      this.state.selectedSkuDetail.SkuAshlin
    );
    this.calculateSkuCost(skuCost, tierMatrix);
    // console.log(tierMatrixRes);
    // console.log(skuCost);
    this.setState(
      {
        tierMatrix,
        skuCost,
      },
      () => {
        $("#tierCostModal").modal("show");
      }
    );
  };
  calculateSkuCost = (skuCost, tierMatrix) => {
    skuCost.Qty1Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._1CStandardDelivery
    ).toFixed(2);
    skuCost.Qty6Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._6CStandardDelivery
    ).toFixed(2);
    skuCost.Qty24Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._24CStandardDelivery
    ).toFixed(2);
    skuCost.Qty50Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._50CStandardDelivery
    ).toFixed(2);
    skuCost.Qty100Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._100CStandardDelivery
    ).toFixed(2);
    skuCost.Qty250Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._250CStandardDelivery
    ).toFixed(2);
    skuCost.Qty500Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._500CStandardDelivery
    ).toFixed(2);
    skuCost.Qty1000Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._1000CStandardDelivery
    ).toFixed(2);
    skuCost.Qty2500Price = (
      parseFloat(skuCost.KaplUnitCostCadS) * tierMatrix._2500CStandardDelivery
    ).toFixed(2);
  };
  handleSkuCostChange = (event) => {
    event.preventDefault();
    // console.log(event);
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const skuCost = this.state.skuCost;
    skuCost[name] = value;
    if (name === "KaplUnitCostCadS") {
      this.calculateSkuCost(skuCost, this.state.tierMatrix);
      this.setState({
        skuCost,
      });
    } else {
      this.setState({
        skuCost,
      });
    }
  };
  handlePricingTierOnchange = async (event) => {
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const skuCost = this.state.skuCost;
    skuCost[name] = value;
    const tierMatrixRes = await getCostMatrix(value);
    const tierMatrix = tierMatrixRes.data;
    this.calculateSkuCost(skuCost, tierMatrix);
    this.setState({
      skuCost,
    });
  };
  handleUpDateCostMatrix = async (e, create) => {
    e.preventDefault();
    const res = await updateSkuTierCost(this.state.skuCost, create);
    alert("Supplier Tier Cost Price Updating Finished!");
    $("#tierCostModal").modal("hide");
    this.setState({
      newSkuCost: false,
    });
  };

  checkFormValidation = (newSkuInfo) => {
    let isValid = false;
    let {
      PricingTier,
      HtsCdn,
      HtsUs,
      BasePrice,
      MinOrder,
      ReorderLevel,
      ReorderQuantity,
    } = newSkuInfo;
    let { skuPantoneColor, skuCost, tierMatrix } = this.state;
    let {
      DesignerColorName,
      PantoneColorName,
      Red,
      Green,
      Blue,
    } = skuPantoneColor;
    const formString = {
      DesignerColorName,
      PantoneColorName,
      HtsCdn,
      HtsUs,
    };

    const formInt = {
      PricingTier,
      MinOrder,
      ReorderLevel,
      ReorderQuantity,
      Red,
      Green,
      Blue,
    };
    const formFloat = {
      BasePrice,
    };
    const checkNullObj = { skuCost, tierMatrix };
    for (let property in checkNullObj) {
      if (!validationChecker.checkIsNull(checkNullObj[property], property)) {
        return isValid;
      }
    }
    for (let property in formString) {
      if (!validationChecker.checkString(formString[property], property)) {
        return isValid;
      }
    }
    for (let property in formInt) {
      if (!validationChecker.checkInt(formInt[property], property)) {
        return isValid;
      }
    }
    for (let property in formFloat) {
      if (!validationChecker.checkFloat(formFloat[property], property)) {
        return isValid;
      }
    }
    isValid = true;
    return isValid;
  };

  handleUpdateSKUInfo = async (e) => {
    e.preventDefault();
    this.setState(
      {
        isUploading: true,
      },
      async () => {
        const newSkuInfo = this.state.selectedSkuDetail;
        const d = new Date(
          new Date().toString().split("GMT")[0] + " UTC"
        ).toISOString();
        newSkuInfo.DateUpdated = d.slice(0, 19);

        if (!this.checkFormValidation(newSkuInfo)) {
          this.setState({
            isUploading: false,
          });
          return;
        }

        const skuRes = await updateSKU(newSkuInfo);
        const pantoneColorRes = await this.handleUpdatePantoneColor();
        Promise.all([skuRes, pantoneColorRes]).then(() => {
          this.setState({
            isUploading: false,
          });
          message.success(
            `${this.state.selectedSkuDetail.SkuAshlin} update finish!`
          );
        });
      }
    );
  };
  handleUpdatePantoneColor = async () => {
    if (Object.keys(this.state.skuPantoneColor).length !== 0) {
      if (
        this.state.skuPantoneColor.DesignerColorName !== undefined &&
        this.state.skuPantoneColor.PantoneColorName !== undefined &&
        this.state.skuPantoneColor.Red !== undefined &&
        this.state.skuPantoneColor.Green !== undefined &&
        this.state.skuPantoneColor.Blue !== undefined
      ) {
        if (this.state.newPantoneColor) {
          const newColor = this.state.skuPantoneColor;
          newColor.Sku = this.state.selectedSkuDetail.SkuAshlin;
          return await addSkuPantoneColor(newColor);
        } else {
          //update existing color
          return await updateSkuPantoneColor(this.state.skuPantoneColor);
        }
      }
    }
  };
  /*******UPDATE SKU INFO FINISH********/

  /*******ADD NEW IMAGES START********/
  handleImageOnDrop = (accepted, index, imageType) => {
    //if(this.state.selectedChannel === "BigCommerce Ashlin") {
    let images = this.state[imageType];
    const todayDate = new Date().toISOString().slice(0, 10);
    let fileName = `${this.state.selectedSkuDetail.SkuAshlin}_${imageType}_${
      index + 1
    }_${todayDate}`;
    if (accepted[0].type === "image/jpeg") {
      fileName += ".jpg";
    } else if (accepted[0].type === "image/png") {
      fileName += ".png";
    }
    const newFiles = new File(accepted, fileName, { type: accepted[0].type });
    newFiles.preview = accepted[0].preview;
    images.pop();
    images.push(newFiles);
    images.push(0);
    this.setState({
      [imageType]: images,
    });
    // }
  };
  handleRemoveSelect = (e, index, imageType) => {
    e.preventDefault();
    const images = this.state[imageType];
    images.splice(index, 1);
    this.setState({
      [imageType]: images,
    });
  };
  handleSubmitImage = async (
    headers,
    designCode,
    image,
    imageType,
    lastModified
  ) => {
    /********************* POST TO HOSTINGER START ****************************/
    let data = new FormData();
    data.append("image", image);
    data.append("folder", designCode);
    data.append(
      "vince",
      "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9"
    );
    await axios.post("https://ashlinapp.xyz/api/image-uploads.php", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const hostingerURL = `https://ashlinapp.xyz/ProductMediaContent/${designCode}/${image.name}`;

    /************************* POST TO HOSTINGER FINISH ************************/

    /********************* POST TO GOOGLE DRIVE START ****************************/

    /*************Get Folder Path**********************/
    const searchFolderName = designCode;
    //this master Folder ID will be Product Media Content's ID
    const masterFolderID = "0B1wi-RUM_bTlYi1uTnE2eGlsSDQ";
    const query = `q=name+%3d+%27${searchFolderName}%27&mimeType+%3d+%27application/vnd.google-apps.folder%27`;
    const resSearchFolder = await axios.get(
      `https://www.googleapis.com/drive/v3/files?${query}`,
      { headers }
    );
    //Destination Folder for new SKU images on Google Drive
    let folder;
    if (resSearchFolder.data.files.length === 0) {
      //console.log('Folder does not exist!');
      const newFolder = {
        name: searchFolderName,
        mimeType: "application/vnd.google-apps.folder",
        parents: [masterFolderID],
      };
      const resNewFolder = await axios.post(
        "https://www.googleapis.com/drive/v3/files",
        newFolder,
        { headers }
      );
      folder = resNewFolder.data;
    } else {
      //console.log('Find folder!');
      folder = resSearchFolder.data.files[0];
    }
    /**********Create New File**********************/
    const newFile = {
      name: image.name,
      parents: [folder.id],
    };
    const resNewFile = await axios.post(
      "https://www.googleapis.com/drive/v3/files",
      newFile,
      { headers }
    );
    const resUpdatedFile = await axios.patch(
      `https://www.googleapis.com/upload/drive/v3/files/${resNewFile.data.id}`,
      image,
      { headers }
    );
    const googleURL = `https://drive.google.com/uc?export=download&id=${resUpdatedFile.data.id}`;

    /********************* POST TO GOOGLE DRIVE FINISH ****************************/
    const hostingerData = {
      Sku: this.state.selectedSkuDetail.SkuAshlin,
      ImageType: imageType,
      ImageUrl: hostingerURL,
      Host: "Hostinger",
      LastModifiedDate: lastModified,
    };
    const googleData = {
      Sku: this.state.selectedSkuDetail.SkuAshlin,
      ImageType: imageType,
      ImageUrl: googleURL,
      Host: "Google",
      LastModifiedDate: lastModified,
    };

    /********************* SAVE URL IN ASHLIN DATABASE START ****************************/

    //Save URL in Ashlin Database
    const ashlinInput = [hostingerData, googleData];
    Promise.all([resNewFile, resUpdatedFile]).then(async () => {
      await postNewImage(ashlinInput);
    });
    /********************** SAVE URL IN ASHLIN DATABASE FINISH ***************************/
  };
  handleUploadImage = (e) => {
    e.preventDefault();
    this.setState(
      {
        isUploading: true,
      },
      async () => {
        const designCode = this.state.selectedSkuDetail.SkuAshlin.split("-")[0];
        const tokenRes = await axios.get(`${baseURL}api/google-token`);
        const token = tokenRes.data;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        const d = new Date(
          new Date().toString().split("GMT")[0] + " UTC"
        ).toISOString();
        const last = d.slice(0, 19);
        //MAIN
        const resMAIN = await this.state.MAIN.map(async (image) => {
          if (image !== 0) {
            return await this.handleSubmitImage(
              headers,
              designCode,
              image,
              "MAIN",
              last
            );
          }
        });

        //BASE
        const resBASE = await this.state.BASE.map(async (image) => {
          if (image !== 0) {
            return await this.handleSubmitImage(
              headers,
              designCode,
              image,
              "BASE",
              last
            );
          }
        });

        //MODEL
        const resMODEL = await this.state.MODEL.map(async (image) => {
          if (image !== 0) {
            return await this.handleSubmitImage(
              headers,
              designCode,
              image,
              "MODEL",
              last
            );
          }
        });

        //LIFE
        const resLIFE = await this.state.LIFE.map(async (image) => {
          if (image !== 0) {
            return await this.handleSubmitImage(
              headers,
              designCode,
              image,
              "LIFE",
              last
            );
          }
        });

        //GROUP
        const resGROUP = await this.state.GROUP.map(async (image) => {
          if (image !== 0) {
            return await this.handleSubmitImage(
              headers,
              designCode,
              image,
              "GROUP",
              last
            );
          }
        });
        Promise.all([
          ...resMAIN,
          ...resBASE,
          ...resMODEL,
          ...resLIFE,
          ...resGROUP,
        ]).then(() => {
          this.setState(
            {
              isUploading: false,
              BASE: [0],
              MODEL: [0],
              LIFE: [0],
              GROUP: [0],
              MAIN: [0],
            },
            () => {
              alert("Image Upload Completed!");
            }
          );
        });
      }
    );
  };
  /*******ADD NEW IMAGES FINISH********/
  render() {
    return (
      <div className="container-fluid row">
        {/*<div className={'col-3'}>*/}
        {/*<MainSideBar/>*/}
        {/*</div>*/}
        <div className={"col"}>
          <br />
          <h2 className={"text-secondary"} style={{ textAlign: "center" }}>
            Update SKU
            <span style={{ color: "red" }}>
              (You have to fill all info for step 1)
            </span>
          </h2>
          <br />
          <ul className="nav nav-tabs nav-fill">
            <NavItem
              toggleType="tab"
              id="#updateSKUInfo"
              data="Step1 SKU BASIC INFO"
              active={true}
            />
            <NavItem
              toggleType="tab"
              id="#updateSKUSEOContent"
              data="Step2 SEO CONTENT"
            />
            <NavItem
              toggleType="tab"
              id="#updateImageURL"
              data="Step3 Add New Image to Google Drive & Hostinger"
            />
            {/*<NavItem toggleType='tab' id="#updateHTSCode" data="HTS CODES"  />*/}
            {/*<NavItem toggleType='tab' id="#updateChannelList" data="CHANNEL LIST"  />*/}
          </ul>
          <form className="tab-content">
            <TabContentItem
              id="updateSKUInfo"
              style={{ height: "450px", margin: "30px 0 10px" }}
              active={true}
            >
              <UpdateSKUInfo
                skuNumList={this.props.skuNumberList}
                selectedSkuDetail={this.state.selectedSkuDetail}
                skuPantoneColor={this.state.skuPantoneColor}
                HtsCdn={this.state.HtsCdn}
                HtsUs={this.state.HtsUs}
                SKUNunberOnchange={this.handleSKUNunberOnchange}
                SKUDetailOnChange={this.handleSKUDetailOnChange}
                HtsOnchange={this.handleHtsOnchange}
                SkuPantoneColorOnChange={this.handleSkuPantoneColorOnChange}
                changeTierPrice={this.handleChangeTierPrice}
                tierCostMatrix={this.state.tierMatrix}
                skuCost={this.state.skuCost}
                newSkuCost={this.state.newSkuCost}
                skuCostOnchange={this.handleSkuCostChange}
                upDateCostMatrix={this.handleUpDateCostMatrix}
                pricingTierOnchange={this.handlePricingTierOnchange}
              />
            </TabContentItem>
            <TabContentItem
              id="updateSKUSEOContent"
              style={{ height: "450px", margin: "30px 0 10px" }}
            >
              {this.state.isUploading && (
                <div className={"text-center text-success display-4"}>
                  <i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting
                  Update...
                  <br />
                  <br />
                </div>
              )}
              <TextArea
                label={"Meta Title"}
                value={this.state.selectedSkuDetail.MetaTitle}
                rows={"1"}
                onChange={this.handleSKUDetailOnChange}
                name={"MetaTitle"}
              />
              <TextArea
                label={"Meta Keywords"}
                value={this.state.selectedSkuDetail.MetaKeywords}
                onChange={this.handleSKUDetailOnChange}
                name={"MetaKeywords"}
              />
              <TextArea
                label={"Meta Description"}
                value={this.state.selectedSkuDetail.MetaDescription}
                onChange={this.handleSKUDetailOnChange}
                name={"MetaDescription"}
              />
              <TextArea
                label={"Sku SEO Title"}
                value={this.state.selectedSkuDetail.SkuSeoTitle}
                rows={"1"}
                onChange={this.handleSKUDetailOnChange}
                name={"SkuSeoTitle"}
              />
              <TextArea
                label={"Sku SEO Description"}
                value={this.state.selectedSkuDetail.SkuSeoDesc}
                onChange={this.handleSKUDetailOnChange}
                name={"SkuSeoDesc"}
              />
              {Object.keys(this.state.selectedSkuDetail).length !== 0 && (
                <button
                  onClick={this.handleUpdateSKUInfo}
                  className={"btn btn-danger"}
                >
                  Update SKU Basic Information(except Images)
                </button>
              )}
            </TabContentItem>
            <TabContentItem
              id="updateImageURL"
              style={{ height: "450px", margin: "30px 0 10px" }}
            >
              {Object.keys(this.state.selectedSkuDetail).length !== 0 && (
                <div>
                  <h3 style={{ color: "red", background: "yellow" }}>
                    Noted: You have to upload at least 5 images in total
                  </h3>
                  {this.state.isUploading && (
                    <div className={"text-center text-success display-4"}>
                      <i className="fa fa-spinner fa-spin"></i> &nbsp; Uploading
                      Images...
                      <br />
                      <br />
                    </div>
                  )}
                  {this.state.MAIN.length +
                    this.state.BASE.length +
                    this.state.MODEL.length +
                    this.state.LIFE.length +
                    this.state.GROUP.length >
                    5 && (
                    <button
                      type="submit"
                      className="btn btn-primary btn-lg btn-block"
                      onClick={this.handleUploadImage}
                    >
                      Add New Images
                    </button>
                  )}
                  <AddImages
                    mainImages={this.state.MAIN}
                    baseImages={this.state.BASE}
                    modelImages={this.state.MODEL}
                    lifeImages={this.state.LIFE}
                    groupImages={this.state.GROUP}
                    imageOnDrop={this.handleImageOnDrop}
                    removeSelect={this.handleRemoveSelect}
                  />
                </div>
              )}
            </TabContentItem>

            {/*<TabContentItem id="updateHTSCode"  style={{height:"500px",margin:'30px 0 10px'}}>*/}
            {/*<NewHTSCode onChange={this.handleOnChange} value={HTS}/>*/}
            {/*</TabContentItem>*/}
            {/*<TabContentItem id="updateChannelList"  style={{height:"450px",margin:'30px 0 10px'}}>*/}

            {/*</TabContentItem>*/}
          </form>
        </div>
      </div>
    );
  }
}

export default UpdateSKU;
