import React,{Component} from 'react';
import AddSubFamilyModal from './addSubFamiliesModal';
import UpdateSubFamilyModal from './updateSubFamiliesModal';
import { sageCategories } from '../../../Utils/channelCategories/sageCategories';
import { sageThemes } from "../../../Utils/channelCategories/sageThemes";
import { ESPCategories } from '../../../Utils/channelCategories/ESPCategories'
import {uDuCatCategories } from '../../../Utils/channelCategories/uDuCatCategories';
import { asiCategories, asiIndustries} from '../../../Utils/channelCategories/asiCategories';
import axios from 'axios';
import { legacyFamiliesURL, legacyHtsCaURL,legacyHtsCaModelMapper, legacyHtsUsURL,legacyHtsUsModelMapper, legacySubFamiliesURL, legacySubFamiliesModelMapper} from "../../../Utils/ashlinDesign";
class ProductSubFamilies extends Component{
    constructor(props){
        super(props);
        this.state={
            ashlinFamilies:[],
            hstCA:[],
            hstUS:[],
            ashlinSubFamilies:[],
            sageCategories:sageCategories,
            sageThemes:sageThemes,
            ESPCategories:ESPCategories,
            uDuCatCategories:uDuCatCategories,
            asiCategories:asiCategories,
            asiIndustries:asiIndustries

        }
    }

    handleGetSubFamilies = async()=>{
        const ashlinFamiliesResponse = await axios.get(legacyFamiliesURL);
        const ashlinFamilies = ashlinFamiliesResponse.data.map(family=>family.SuperFamily);
        const ashlinSubFamiliesResponse = await axios.get(legacySubFamiliesURL);
        const ashlinSubFamilies = ashlinSubFamiliesResponse.data.map(subFamily=>legacySubFamiliesModelMapper(subFamily));
        const ashlinHtsCaResponse = await axios.get(legacyHtsCaURL);
        const ashlinHtsCa = ashlinHtsCaResponse.data.map(hts=>legacyHtsCaModelMapper(hts));
        const ashlinHtsUsResponse = await axios.get(legacyHtsUsURL);
        const ashlinHtsUs = ashlinHtsUsResponse.data.map(hts=>legacyHtsUsModelMapper(hts));
        Promise.all([ashlinFamiliesResponse,ashlinHtsCaResponse,ashlinHtsUsResponse,ashlinSubFamiliesResponse]).then(()=>{
            this.setState({
                ashlinFamilies:ashlinFamilies,
                hstCA:ashlinHtsCa,
                hstUS:ashlinHtsUs,
                ashlinSubFamilies:ashlinSubFamilies
            })
        })

    };
    render(){
        return(
            <div className='container-fluid'>
                <br/>
                <h2 className={'text-secondary'} style={{textAlign:'center'}}>Manager Sub-Family</h2>
                <br/>
                <br/>
                <div className={'row justify-content-center'}>
                    <button className={' btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px",fontSize:'30px'}} data-toggle="modal" data-target="#add_new_sub_family" onClick={this.handleGetSubFamilies} >Add Sub-Family</button>
                    <AddSubFamilyModal id={'add_new_sub_family'}
                           families = {this.state.ashlinFamilies} addSubFamily = {this.props.addSubFamily} subFamilies = {this.state.ashlinSubFamilies}
                                       onChange={this.props.onChange} addItem = {this.props.addItem}
                           Activate={this.props.Activate} DeActivate={this.props.DeActivate}
                           translate={this.props.translate} htsCa={this.state.hstCA} htsUs = {this.state.hstUS}
                                       handleCategory={this.props.handleCategory}
                           sageCategories={this.state.sageCategories} sageThemes={this.state.sageThemes}
                           ESPCategories={this.state.ESPCategories} uDuCatCategories={this.state.uDuCatCategories}
                           asiCategories={this.state.asiCategories} asiIndustries={this.state.asiIndustries}
                    />
                    <div className={'col-1'}> </div>
                    <button className={'btn-primary '} style={{borderRadius:'50%',width:"200px",height:"200px", fontSize:'30px'}} data-toggle="modal" data-target="#update_sub_family" onClick={this.handleGetSubFamilies}>Update Sub-Family</button>
                    <UpdateSubFamilyModal id={'update_sub_family'}
                          families = {this.state.ashlinFamilies} htsCa={this.state.hstCA} htsUs = {this.state.hstUS}
                          subFamilies = {this.state.ashlinSubFamilies} updateSubFamily = {this.props.updateSubFamily}
                          onChange={(a,b)=>this.props.onChange(a,b,this.state.ashlinSubFamilies)}  updateItem = {this.props.updateItem}
                          Activate={this.props.Activate} DeActivate={this.props.DeActivate}
                          translate={this.props.translate}
                          handleCategory={this.props.handleCategory}
                          sageCategories={this.state.sageCategories} sageThemes={this.state.sageThemes}
                          ESPCategories={this.state.ESPCategories} uDuCatCategories={this.state.uDuCatCategories}
                          asiCategories={this.state.asiCategories} asiIndustries={this.state.asiIndustries}
                    />
                </div>
            </div>
        )
    }
}

export default ProductSubFamilies;