import NodeRSA from 'node-rsa';
import { jsonToXml, xmlToJson, decodeXml } from "./json_xml_converter";
import moment from 'moment';
import { baseURL, proxyurl } from "./requestHelper";
import axios from 'axios';

export const WALMART_FEED_URL = 'https://marketplace.walmartapis.com/v3/ca/feeds';
export const WALMART_ITEM_URL = 'https://marketplace.walmartapis.com/v3/ca/items';
export const WALMART_ORDER_URL = 'https://marketplace.walmartapis.com/v3/ca/orders/';

/******************GENERATE REQUEST HEADER WITH SIGNATURE************************/
const generateSignature = async (url, method, timestamp) => {
    const walmart = await axios.get(baseURL + 'api/walmart-marketplace');
    const key = new NodeRSA();
    key.importKey(Buffer.from(walmart.data.WALMART_PRIVATE_KEY, 'base64'), 'pkcs8-private-der');
    const privateKey = key.exportKey();
    const data = `${walmart.data.CUSTOMER_ID}\n${url}\n${method}\n${timestamp}\n`;
    return new NodeRSA(privateKey, { signingScheme: 'sha256' }).sign(data).toString('base64');
};
export const generateHeader = async (url, method, id, contentType, returnType) => {

    const requestType = contentType === "json" ? "application/json" : contentType === "xml" ? "application/xml" : "multipart/form-data";
    const responseType = returnType === "json" ? "application/json" : "application/xml";

    const timestamp = Date.now();
    const signature = await generateSignature(url, method, timestamp);
    const walmart = await axios.get(baseURL + 'api/walmart-marketplace');
    return {
        "WM_SVC.NAME": "Walmart Marketplace",
        "WM_TENANT_ID": "WALMART.CA",
        "WM_LOCALE_ID": "en_CA",
        "WM_QOS.CORRELATION_ID": id,
        "WM_SEC.TIMESTAMP": timestamp,
        "WM_SEC.AUTH_SIGNATURE": signature,
        "WM_CONSUMER.ID": walmart.data.CUSTOMER_ID,
        "WM_CONSUMER.CHANNEL.TYPE": walmart.data.WALMART_CHANNEL_TYPE,
        "Content-Type": requestType,
        "Accept": responseType
    }
};

/******************CHECK FEED STATUS************************/
export const WalmartFeedStatus = async (feedID) => {
    const encodeFeedID = encodeURIComponent(feedID);
    const query = `/${encodeFeedID}?includeDetails=true`;
    const requestUrl = WALMART_FEED_URL + query;
    const headers = await generateHeader(requestUrl, 'GET', "ashlinApiBulkFeedStatus", "json", "json");
    //console.log(headers);
    return await axios.get(requestUrl, { headers });

};
export const FeedBackObject = (array) => {
    const d = new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString();
    const last = d.slice(0, 19);
    return array.map(i => {
        return {
            "Sku": i.sku,
            "Active": 1,
            "ChannelName": "WalmartCa",
            "LastModifiedDate": last,
            "MerchanSku": i.wpid

        }
    });
};

/******************BULK UPLOAD ITEMS************************/
const walmartItems = (items, mode) => {

    const MPItemArray = items.map(item => {
        return walmartItem(item, mode);
    });

    return {
        "_declaration": {
            "_attributes": {
                "version": "1.0",
                "encoding": "UTF-8",
                "standalone": "yes"
            }
        },
        "MPItemFeed": {
            "_attributes": {
                "xmlns:ns3": "https://walmart.com/"
            },
            "MPItemFeedHeader": {
                "version": {
                    "_text": "3.1"
                },
                "mart": {
                    "_text": "WALMART_CA"
                },
                "locale": {
                    "_text": "en_CA"
                }
            },
            "MPItem": MPItemArray
        }
    };
};
const walmartItem = (i, mode) => {

    const variantGroupId = i.Sku.split('-').map(c => c.trim());
    const mainImage = i.Urls.HostingerUrls.BASE.shift();
    const swatchImageUrl = `https://ashlinapp.xyz/Colors/${variantGroupId[2]}.jpg`;
    const secondaryImages = i.Urls.HostingerUrls.BASE.map(u => {
        return {
            "_text": u
        }
    });
    let gender = i.Gender.toUpperCase().replace("'", "");
    if (gender === "LADIES" || gender === "WOMENS") {
        gender = "Women";
    } else if (gender === "MENS") {
        gender = "Men"
    } else {
        gender = "Unisex"
    }

    const keywords = i.MetaKeywords === "" ? i.ShortDescription : i.MetaKeywords;
    let processMode = "CREATE";
    //let processMode="REPLACE_ALL";
    let Price = 0;
    let ProductName = "";
    if (mode === "modify") {
        processMode = "REPLACE_ALL";
        ProductName = i.ProductName;
        //ProductName = i.Brand + " DESIGNER | " + i.Name + " " + i.ShortDescription.replace('.','');
        Price = parseFloat(i.Price) * 1.05.toFixed(2);
    } else {
        Price = (i.Price - 0.03) * 1.05.toFixed(2);
        ProductName = i.Brand + "DESIGNER | " + i.Name + " " + i.ShortDescription.replace('.', '');
    }
    let keyFeatures = "";
    if (i.keyFeature1 !== "") {
        // keyFeatures.push(
        // {
        //     "_text":  i.keyFeature1.replace(/[^a-zA-Z0-9 '"]/g, '')
        // });
        keyFeatures += "•" + i.keyFeature1.replace(/[^a-zA-Z0-9 '"]/g, '') + "<br/>";
    }
    if (i.keyFeature2 !== "") {
        // keyFeatures.push(
        //     {
        //         "_text":  i.keyFeature2.replace(/[^a-zA-Z0-9 '"]/g, '')
        //     });
        if (keyFeatures.length + i.keyFeature2.length < 500)
            keyFeatures += "•" + i.keyFeature2.replace(/[^a-zA-Z0-9 '"]/g, '') + '<br/>';
    }
    if (i.keyFeature3 !== "") {
        // keyFeatures.push(
        //     {
        //         "_text":  i.keyFeature3.replace(/[^a-zA-Z0-9 '"]/g, '')
        //     });
        if (keyFeatures.length + i.keyFeature3.length < 500)
            keyFeatures += "•" + i.keyFeature3.replace(/[^a-zA-Z0-9 '"]/g, '') + '<br/>';
    }
    if (i.keyFeature4 !== "") {
        // keyFeatures.push(
        //     {
        //         "_text":  i.keyFeature4.replace(/[^a-zA-Z0-9 '"]/g, '')
        //     });
        if (keyFeatures.length + i.keyFeature4.length < 500)
            keyFeatures += "•" + i.keyFeature4.replace(/[^a-zA-Z0-9 '"]/g, '') + '<br/>';
    }
    if (i.keyFeature5 !== "") {
        // keyFeatures.push(
        //     {
        //         "_text":  i.keyFeature5.replace(/[^a-zA-Z0-9 '"]/g, '')
        //     });
        if (keyFeatures.length + i.keyFeature5.length < 500)
            keyFeatures += "•" + i.keyFeature5.replace(/[^a-zA-Z0-9 '"]/g, '');
    }
    //console.log(keyFeatures.length);
    if (secondaryImages.length === 0) {
        return {
            "processMode": {
                "_text": processMode
            },
            "sku": {
                "_text": i.Sku
            },
            "productIdentifiers": {
                "productIdentifier": {
                    "productIdType": {
                        "_text": "UPC"
                    },
                    "productId": {
                        "_text": i.Upc
                    }
                }
            },
            "MPProduct": {
                "SkuUpdate": {
                    "_text": "No"
                },
                "productName": {
                    "_text": ProductName
                },
                "ProductIdUpdate": {
                    "_text": "No"
                },
                "category": {
                    "CarriersAndAccessoriesCategory": {
                        "CasesAndBags": {
                            "shortDescription": {
                                "_text": i.ExtendedDescription.replace(/(\r\n\t|\n|\r\t)/gm, "")
                            },
                            "keyFeatures": {
                                "keyFeaturesValue": {
                                    "_text": keyFeatures
                                }
                            },
                            "brand": {
                                "_text": i.Brand
                            },
                            "multipackQuantity": {
                                "_text": "1"
                            },
                            "mainImageUrl": {
                                "_text": mainImage
                            },
                            "pattern": {
                                "_text": keywords
                            },
                            "material": {
                                "_text": i.Material
                            },
                            "gender": {
                                "_text": gender
                            },
                            "ageGroup": {
                                "ageGroupValue": [
                                    {
                                        "_text": "Child"
                                    },
                                    {
                                        "_text": "Teen"
                                    },
                                    {
                                        "_text": "Adult"
                                    }
                                ]
                            },
                            "variantGroupId": {
                                "_text": `${variantGroupId[0]}-${variantGroupId[1]}`
                            },
                            "variantAttributeNames": {
                                "variantAttributeName": {
                                    "_text": "color"
                                }
                            },
                            "color": {
                                "_text": i.Color
                            },
                            "countryOfOriginTextiles": {
                                "_text": "Imported"
                            },
                            "swatchImages": {
                                "swatchImage": {
                                    "swatchImageUrl": {
                                        "_text": swatchImageUrl
                                    },
                                    "swatchVariantAttribute": {
                                        "_text": "color"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "MPOffer": {
                "price": {
                    "_text": Price.toFixed(2)
                },
                "StartDate": {
                    "_text": "2018-07-10"
                },
                "ShippingWeight": {
                    "measure": {
                        "_text": (i.WeightGrams * 0.00220462).toFixed(3)
                    },
                    "unit": {
                        "_text": "lb"
                    }
                },
                "ProductTaxCode": {
                    "_text": "2038345"
                },
                "shipsInOriginalPackaging": {
                    "_text": "Yes"
                }
            }
        }
    } else {
        return {
            "processMode": {
                "_text": processMode
            },
            "sku": {
                "_text": i.Sku
            },
            "productIdentifiers": {
                "productIdentifier": {
                    "productIdType": {
                        "_text": "UPC"
                    },
                    "productId": {
                        "_text": i.Upc
                    }
                }
            },
            "MPProduct": {
                "SkuUpdate": {
                    "_text": "No"
                },
                "productName": {
                    "_text": ProductName
                },
                "ProductIdUpdate": {
                    "_text": "No"
                },
                "category": {
                    "CarriersAndAccessoriesCategory": {
                        "CasesAndBags": {
                            "shortDescription": {
                                "_text": i.ExtendedDescription.replace(/(\r\n\t|\n|\r\t)/gm, "")
                            },
                            "keyFeatures": {
                                "keyFeaturesValue": {
                                    "_text": keyFeatures
                                }
                            },
                            "brand": {
                                "_text": i.Brand
                            },
                            "multipackQuantity": {
                                "_text": "1"
                            },
                            "mainImageUrl": {
                                "_text": mainImage
                            },
                            "productSecondaryImageURL": {
                                "productSecondaryImageURLValue": secondaryImages
                            },
                            "pattern": {
                                "_text": keywords
                            },
                            "material": {
                                "_text": i.Material
                            },
                            "gender": {
                                "_text": gender
                            },
                            "ageGroup": {
                                "ageGroupValue": [
                                    {
                                        "_text": "Child"
                                    },
                                    {
                                        "_text": "Teen"
                                    },
                                    {
                                        "_text": "Adult"
                                    }
                                ]
                            },
                            "variantGroupId": {
                                "_text": `${variantGroupId[0]}-${variantGroupId[1]}`
                            },
                            "variantAttributeNames": {
                                "variantAttributeName": {
                                    "_text": "color"
                                }
                            },
                            "color": {
                                "_text": i.Color
                            },
                            "countryOfOriginTextiles": {
                                "_text": "Imported"
                            },
                            "swatchImages": {
                                "swatchImage": {
                                    "swatchImageUrl": {
                                        "_text": swatchImageUrl
                                    },
                                    "swatchVariantAttribute": {
                                        "_text": "color"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "MPOffer": {
                "price": {
                    "_text": Price.toFixed(2)
                },
                "StartDate": {
                    "_text": "2018-07-10"
                },
                "ShippingWeight": {
                    "measure": {
                        "_text": (i.WeightGrams * 0.00220462).toFixed(3)
                    },
                    "unit": {
                        "_text": "lb"
                    }
                },
                "ProductTaxCode": {
                    "_text": "2038345"
                },
                "shipsInOriginalPackaging": {
                    "_text": "Yes"
                }
            }
        }
    }
};


export const WalmartFeedItems = async (items, mode) => {
    const walmartJson = walmartItems(items, mode);
    // console.log(walmartJson);
    const xmlToUpload = jsonToXml(walmartJson); //decodeXml(jsonToXml(walmartJson));

    //console.log(xmlToUpload);
    const query = "?feedType=item";
    const requestUrl = WALMART_FEED_URL + query;
    //para - url, method, id
    const headers = await generateHeader(requestUrl, 'POST', "ashlinApiBulkFeed", "form", "xml");

    return await axios.post(requestUrl, xmlToUpload, { headers });

};
export const WalmartGetAnItem = async (sku) => {
    const query = `/${sku}`;
    const requestUrl = WALMART_ITEM_URL + query;
    //para - url, method, id
    const headers = await generateHeader(requestUrl, 'GET', "ashlinApiGetAnItem", "form", "xml");
    const res = await axios.get(requestUrl, { headers });
    return JSON.parse(xmlToJson(res.data));
};
/******************BULK UPDATE INVENTORY************************/
const walmartInventory = (items) => {

    const inventory = items.map(i => {
        return {
            "sku": {
                "_text": i.Sku
            },
            "quantity": {
                "unit": {
                    "_text": "EACH"
                },
                "amount": {
                    "_text": i.Inventory
                }
            },
            "fulfillmentLagTime": {
                "_text": "0"
            }
        }
    });
    return {
        "InventoryFeed": {
            "_attributes": {
                "xmlns": "https://walmart.com/"
            },
            "InventoryHeader": {
                "version": {
                    "_text": "1.4"
                }
            },
            "inventory": inventory
        }
    }
};
export const WalmartInventoryUpdate = async (items) => {
    const requestUrl = WALMART_FEED_URL + "?feedType=inventory";
    const headers = await generateHeader(requestUrl, 'POST', "ashlinApiInventoryUpdate", "form", "json");
    const inventory = walmartInventory(items);
    const xml = jsonToXml(inventory);
    return await axios.post(requestUrl, xml, { headers });
};


/*********************BULK UPDATE STARTDATE*****************/
const walmartStatDateItems = (items, startDate) => {

    const updateItems = items.map(item => {
        return {
            "processMode": {
                "_text": "REPLACE_ALL"
            },
            "sku": {
                "_text": item.Sku
            },
            "productIdentifiers": {
                "productIdentifier": {
                    "productIdType": {
                        "_text": "UPC"
                    },
                    "productId": {
                        "_text": item.Upc
                    }
                }
            },
            "MPOffer": {
                "StartDate": {
                    "_text": startDate
                },
                "price": {
                    "_text": (item.Price - 0.03).toFixed(2)
                },
                "ShippingWeight": {
                    "measure": {
                        "_text": (item.WeightGrams * 0.00220462).toFixed(3)
                    },
                    "unit": {
                        "_text": "lb"
                    }
                },
                "ProductTaxCode": {
                    "_text": "2038345"
                }
            }
        }
    });

    return {
        "_declaration": {
            "_attributes": {
                "version": "1.0",
                "encoding": "UTF-8",
                "standalone": "yes"
            }
        },
        "MPItemFeed": {
            "_attributes": {
                "xmlns:ns3": "https://walmart.com/"
            },
            "MPItemFeedHeader": {
                "version": {
                    "_text": "3.1"
                },
                "mart": {
                    "_text": "WALMART_CA"
                },
                "locale": {
                    "_text": "en_CA"
                }
            },
            "MPItem": updateItems
        }
    }
};
export const walmartUpdateStartDate = async (items, startDate) => {
    const requestUrl = WALMART_FEED_URL + "?feedType=item";
    const headers = await generateHeader(requestUrl, 'POST', "ashlinApiStartDateUpdate", "form", "json");
    const updateItems = walmartStatDateItems(items, startDate);
    const xml = jsonToXml(updateItems);
    return await axios.post(requestUrl, xml, { headers });
};

/******************RETIRE A Product ************************/
export const walmartRetireProduct = async (sku) => {
    const requestUrl = WALMART_ITEM_URL + `/${sku}`;
    const headers = await generateHeader(requestUrl, 'DELETE', "ashlinApiRetireAProduct", "form", "json");
    return await axios.delete(requestUrl, { headers });
};

export const walmartFeedStandardPrices = async (priceList) => {
    let data = `<?xml version="1.0" encoding="UTF-8"?>
<PriceFeed xmlns="https://walmart.com/">
  <PriceHeader>
    <version>1.5.1</version>
  </PriceHeader>`;
    priceList.forEach(p => {
        data += `  <Price>
    <itemIdentifier>
      <sku>${p.sku}</sku>
    </itemIdentifier>
    <pricingList>
      <pricing>
        <currentPrice>
          <value currency="${p.currency}" amount="${p.listPrice}"/>
        </currentPrice>
      </pricing>
    </pricingList>
  </Price>`
    });
    data += `</PriceFeed>`;
    const xml = "--Ashlin\n\n" + data + "\n--Ashlin--";
    const requestUrl = WALMART_FEED_URL + "?feedType=price";
    const headers = await generateHeader(requestUrl, 'POST', "ashlinApiPriceUpdate", "form", "xml");
    return await axios.post(requestUrl, xml, { headers });
};

export const walmartFeedDiscountPrices = async (priceList) => {
    let data = `<?xml version="1.0" encoding="UTF-8"?>
<PriceFeed xmlns="https://walmart.com/">
  <PriceHeader>
    <version>1.5.1</version>
  </PriceHeader>`;
    priceList.forEach(p => {
        data += `  <Price>
    <itemIdentifier>
      <sku>${p.sku}</sku>
    </itemIdentifier>
    <pricingList>
      <pricing effectiveDate="${moment(p.discountStartDate).toISOString()}" expirationDate="${moment(p.discountEndDate).toISOString()}" processMode="UPSERT">
        <currentPrice>
          <value currency="${p.currency}" amount="${p.listPrice}"/>
        </currentPrice>
         <currentPriceType>REDUCED</currentPriceType>
          <comparisonPrice>
        <value currency="${p.currency}" amount="${p.regularPrice}"/>
      </comparisonPrice>
      </pricing>
    </pricingList>
  </Price>`
    });
    data += `</PriceFeed>`;
    const xml = "--Ashlin\n\n" + data + "\n--Ashlin--";
    //console.log(xml);
    const requestUrl = WALMART_FEED_URL + "?feedType=promo";
    const headers = await generateHeader(requestUrl, 'POST', "ashlinApiPriceUpdate", "form", "xml");
    return await axios.post(requestUrl, xml, { headers });
};


export const WalmartDiscount = async (json) => {
    const requestUrl = WALMART_FEED_URL + "?feedType=price";
    const xmlToUpload = jsonToXml(json);
    //console.log(xmlToUpload);
    const headers = await generateHeader(requestUrl, 'POST', "ashlinApiDiscount", "form", "xml");
    //console.log(headers);
    return await axios.post(requestUrl, xmlToUpload, { headers });
};

export const walmartSearchProductBySku = async (sku) => {
    const query = `/${sku}`;
    const requestUrl = WALMART_ITEM_URL + query;
    //para - url, method, id
    const headers = await generateHeader(requestUrl, 'GET', "ashlinApiGetAnItem", "form", "xml");

    try {
        const res = (await axios.get(requestUrl, { headers })).data;
        const resDOM = new DOMParser().parseFromString(res, 'application/xml');

        const listPriceDOM = resDOM.getElementsByTagName('ns2:amount');

        const listPrice = listPriceDOM[0].textContent;

        const currencyDOM = resDOM.getElementsByTagName('ns2:currency');
        const currency = currencyDOM[0].textContent;

        return {
            sku,
            listPrice,
            currency,
            regularPrice: listPrice,
            discount: false,

        }
    } catch (e) {
        return undefined;
    }
};

export const WalmartDiscountMapper = (prices) => {
    return {
        "_declaration": {
            "_attributes": {
                "version": "1.0",
                "encoding": "UTF-8"
            }
        },
        "PriceFeed": {
            "_attributes": {
                "xmlns": "https://walmart.com/"
            },
            "PriceHeader": {
                "version": {
                    "_text": "1.5.1"
                }
            },
            "Price": prices
        }
    };
};

export const WalmartMarConfirmShipment = () => {
    const data = {
        "orderShipment": {
            "orderLines": {
                "orderLine": [
                    {
                        "lineNumber": "1",
                        "orderLineStatuses": {
                            "orderLineStatus": [
                                {
                                    "status": "Shipped",
                                    "statusQuantity": {
                                        "unitOfMeasurement": "EACH",
                                        "amount": "1"
                                    },
                                    "trackingInfo": {
                                        "shipDateTime": new Date().getTime(),
                                        "carrierName": {
                                            "otherCarrier": null,
                                            "carrier": "UPS"
                                        },
                                        "methodCode": "Express",
                                        "trackingNumber": "12345",
                                        "trackingURL": "www.fedex.com"
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        }
    }
};