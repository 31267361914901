import React, { Component } from 'react';
import {getBPheader, getIdBySKU, getInventoryById} from '../brightpearl'
class BpTest extends Component{
    constructor(props){
        super(props);
        this.state={
            sku:"",
            inventory:0
        }
    }

    handleOnchange=(e)=>{
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
           [name]:value
        });
    };
    handleOnCheck=async (e)=>{
        e.preventDefault();
        try{
            const BPHeaders = await getBPheader();
            const id =await getIdBySKU(this.state.sku,BPHeaders);
            const inventory = await getInventoryById(id);
            this.setState({
                inventory
            })
        }catch (e) {
            alert(e)
        }
    };

    render(){
        return(
            <div>
                <h1>Check Bright Inventory by SKU</h1>
                <input value={this.state.sku} onChange={this.handleOnchange} name={'sku'} className={'float-right'}/>
                <h1 >SKU: {this.state.sku} ------------------- Inventory: {this.state.inventory}</h1>
                <button className={'btn float-right'} onClick={this.handleOnCheck} >Check Inventory</button>
            </div>
        )
    }

}

export default BpTest;