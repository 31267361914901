import React from 'react';
const CheckBox = ({value,id,onChange,name,label, className, style})=>{
    return(
        <div className={`form-check ${className}`} style={style}>
            <input className="form-check-input" type="checkbox"  value={value} checked={value}
                   id={id}  onChange={onChange} name={name}/>
            <label className="form-check-label" htmlFor={id} style={{fontSize:'25px'}}>
                {label}
            </label>
        </div>
    )
};
export default CheckBox;