import React from 'react';
import ListItem from './shipping_package_list_item';
const shipping_package_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
            <tr>
                <th>NAME</th>
                <th>LENGTH (CM)</th>
                <th>WIDTH (CM)</th>
                <th>HEIGHT (CM)</th>
                <th>VOLUME (CM<sup>3</sup>)</th>
                <th><button className={'btn-success'} type={'button'} data-toggle="modal" data-target="#newShippingPackage">Add New</button></th>
            </tr>
            </thead>
            <tbody>
            {props.list.map((item,index)=><ListItem item={item} key={index} index={index} upDateShippingPackage={props.upDateShippingPackage}
                                                    shippingPackageOnchange={props.shippingPackageOnchange}/>)}
            </tbody>
        </table>
    )
};
export default shipping_package_list;