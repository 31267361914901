import React, { Component } from 'react';
import {connect } from 'react-redux';
import * as actions from '../actions';
import { Link } from 'react-router-dom';
class Navbar extends Component{

    authButton=()=>{
        if(this.props.authenticated){
            return <Link to={'#'} className={`nav-link ashlinNavLink `} onClick={()=>this.props.logout()} >Sign Out</Link>
        }
    };
    adminButton=()=>{
        if(this.props.authenticated.indexOf("Admin") > -1){
            return <Link to={'/admin'} className={`nav-link adminClassName`}>Admin</Link>
        }
    };
    render(){

    const path = window.location.pathname;
    const insightsClassName = path==="/insights" ? "active" : "";
    const orderClassName = (path==="/ace")? "active" : "";
    const adminClassName = (path==="/admin")? "active" : "";
    const ashlinManualOrderClassName = (path==="/ashlinManualOrders")? "active" : "";
    return (
        <div>
            {this.props.authenticated&&
            <nav className="navbar navbar-expand-lg navbar-light bg-light" style={{boxShadow: "-2px 4px 10px -2px rgba(0,0,0,0.44)"}}>
                <Link className="navbar-brand" to="/"><img src="/logo.ico" alt="logo" className="nav-logo" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#AshlinNav"
                        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="AshlinNav">
                    <ul className="navbar-nav" style={{width:'100%'}}>
                        <li className="nav-item">
                            <Link className={`nav-link ashlinNavLink`} to="/insights">Insights</Link>
                        </li>
                        {/*<li className="nav-item">*/}
                            {/*<Link className={`nav-link ashlinNavLink ${orderClassName}`} to="/ace">ACE</Link>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <Link className={`nav-link ashlinNavLink`} to="/ashlinManualOrders">Manual Order</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ashlinNavLink`} to="/uniShip">UniShip</Link>
                        </li>
                        <li className="nav-item ashlinNavLink">
                            {this.adminButton()}
                        </li>
                        <li className="nav-item float-right" style={{marginLeft:'50%'}}>
                            {this.authButton()}
                        </li>
                    </ul>
                </div>
            </nav>}
        </div>
    );
    }
}
const mapStateToProps=(state)=>{
    return {authenticated:state.authenticated}
};

export default connect(mapStateToProps,actions)(Navbar);