import React from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import { Link } from 'react-router-dom';
const Amazon = ()=>{
    return(
        <div className='container-fluid row'>
            <div className={'col-3'}>
                <MainSideBar/>
            </div>
            <div className={'col-9'} style={{textAlign:'center'}}>
                <br/>
                <h1 >Amazon</h1>
                <hr/>
                <br/>
                <br/>
                <div>
                    <div className={'row justify-content-around'}>
                        <Link className={'btn btn-primary col-4'} to='./amazon/FBA'>Manage Amazon FBA Location</Link>

                    </div>
                    <br/>
                    <div className={'row justify-content-around'}>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Amazon;