export const bestbuyMarketplaceOrders = {
    "orders": [
        {
            "acceptance_decision_date": "2018-11-05T15:46:15Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "200974069",
            "created_date": "2018-11-05T14:56:44Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Saint-Ludger-de-Milot",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Jordan",
                    "lastname": "Cote",
                    "phone": "418-515-0021",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "746 Rue Gaudreault",
                    "street_2": "",
                    "zip_code": "G0W 2B0"
                },
                "civility": "",
                "customer_id": "31D3BA19-4214-4CF4-AF04-32DDAAEB454D",
                "firstname": "Jordam",
                "lastname": "Cote",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Saint-Ludger-de-Milot",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Jordan",
                    "lastname": "Cote",
                    "phone": "418-515-0021",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "746 Rue Gaudreault",
                    "street_2": "",
                    "zip_code": "G0W 2B0"
                }
            },
            "customer_debited_date": "2018-11-05T15:56:56.628Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-05T19:16:53Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "200974069-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 15.12,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 1.97,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 1.97,
                    "created_date": "2018-11-05T14:56:44Z",
                    "debited_date": "2018-11-05T15:56:56Z",
                    "description": null,
                    "last_updated_date": "2018-11-05T17:03:10Z",
                    "offer_id": 3973797,
                    "offer_sku": "T7538-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "54276319",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 80.85,
                    "price_additional_info": null,
                    "price_unit": 80.85,
                    "product_medias": [],
                    "product_sku": "12941101",
                    "product_title": "Ashlin Leather 2-Section Utility Kit - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-11-05T17:03:10Z",
                    "refunds": [],
                    "shipped_date": "2018-11-05T16:58:19Z",
                    "shipping_price": 19.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 1,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 4.04,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 17.09,
                    "total_price": 100.8
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 80.85,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": "CPAR",
            "shipping_company": "CPAR",
            "shipping_price": 19.95,
            "shipping_tracking": "D420749820000000121001",
            "shipping_tracking_url": "https://www.canpar.ca/en/track/TrackingAction.do?reference=D420749820000000121001",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCRemote",
            "shipping_zone_label": "Quebec Remote",
            "total_commission": 17.09,
            "total_price": 100.8
        },
        {
            "acceptance_decision_date": "2018-11-12T19:20:43Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "201077987",
            "created_date": "2018-11-12T17:32:02Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Delta",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Marchien",
                    "lastname": "Archer",
                    "phone": "604-946-8255",
                    "phone_secondary": "604-946-8255",
                    "state": "BC",
                    "street_1": "17-3871 River Road West",
                    "street_2": "",
                    "zip_code": "v4k3n2"
                },
                "civility": "",
                "customer_id": "9D455096-80F9-4234-9ECF-1739B516493A",
                "firstname": "Marchien",
                "lastname": "Archer",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Delta",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Marchien",
                    "lastname": "Archer",
                    "phone": "604-946-8255",
                    "phone_secondary": "604-946-8255",
                    "state": "BC",
                    "street_1": "17-3871 River Road West",
                    "street_2": "",
                    "zip_code": "v4k3n2"
                }
            },
            "customer_debited_date": "2018-11-12T19:32:04.542Z",
            "has_customer_message": true,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-15T17:38:14Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "201077987-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 11.89,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 1.55,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 1.55,
                    "created_date": "2018-11-12T17:32:02Z",
                    "debited_date": "2018-11-12T19:32:04Z",
                    "description": null,
                    "last_updated_date": "2018-11-15T17:38:14Z",
                    "offer_id": 3969356,
                    "offer_sku": "7517-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "54399351",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 69.3,
                    "price_additional_info": null,
                    "price_unit": 69.3,
                    "product_medias": [],
                    "product_sku": "12940144",
                    "product_title": "Ashlin Leather Pocket Wallet - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-11-15T17:38:14Z",
                    "refunds": [],
                    "shipped_date": "2018-11-15T17:34:36Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.5,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 3.47,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "total_commission": 13.44,
                    "total_price": 79.25
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 69.3,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": "CPAR",
            "shipping_company": "CPAR",
            "shipping_price": 9.95,
            "shipping_tracking": "D420749820000000125001",
            "shipping_tracking_url": "https://www.canpar.ca/en/track/TrackingAction.do?reference=D420749820000000125001",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "BCLocal",
            "shipping_zone_label": "BC Local",
            "total_commission": 13.44,
            "total_price": 79.25
        },
        {
            "acceptance_decision_date": "2018-11-27T16:20:32Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "201881793",
            "created_date": "2018-11-23T22:55:25Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Calgary",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Renelle",
                    "lastname": "Bryden",
                    "phone": "403-698-6736",
                    "phone_secondary": "",
                    "state": "AB",
                    "street_1": "49 Sidon Cres SW",
                    "street_2": "",
                    "zip_code": "T3H 2B5"
                },
                "civility": "",
                "customer_id": "BC9B98C7-0DC7-4A4A-BC79-B2D43E613347",
                "firstname": "Renelle",
                "lastname": "Bryden",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Calgary",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Renelle",
                    "lastname": "Bryden",
                    "phone": "403-698-6736",
                    "phone_secondary": "",
                    "state": "AB",
                    "street_1": "49 Sidon Cres SW",
                    "street_2": "",
                    "zip_code": "T3H 2B5"
                }
            },
            "customer_debited_date": "2018-11-27T16:33:22.097Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-28T14:08:11Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "201881793-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 9.58,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 1.25,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 1.25,
                    "created_date": "2018-11-23T22:55:25Z",
                    "debited_date": "2018-11-27T16:33:22Z",
                    "description": null,
                    "last_updated_date": "2018-11-28T14:08:11Z",
                    "offer_id": 3973797,
                    "offer_sku": "T7538-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55065505",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 49.94,
                    "price_additional_info": null,
                    "price_unit": 24.97,
                    "product_medias": [],
                    "product_sku": "12941101",
                    "product_title": "Ashlin Leather 2-Section Utility Kit - Black",
                    "promotions": [],
                    "quantity": 2,
                    "received_date": "2018-11-28T14:08:11Z",
                    "refunds": [],
                    "shipped_date": "2018-11-28T14:02:18Z",
                    "shipping_price": 13.9,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.7,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 2.5,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "total_commission": 10.83,
                    "total_price": 63.84
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 49.94,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canpar",
            "shipping_price": 13.9,
            "shipping_tracking": "D420749820000000153001",
            "shipping_tracking_url": "https://www.canpar.ca/en/track/TrackingAction.do;jsessionid=6511788CEC0AB6ECE046669E4D4AE27A",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ABLocal",
            "shipping_zone_label": "Alberta Local",
            "total_commission": 10.83,
            "total_price": 63.84
        },
        {
            "acceptance_decision_date": "2018-11-27T16:10:36Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202003594",
            "created_date": "2018-11-25T13:13:43Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Barrie",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Nina",
                    "lastname": "Liu",
                    "phone": "647-918-0190",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "25 Lucas Ave",
                    "street_2": "",
                    "zip_code": "L4N 9N2"
                },
                "civility": "",
                "customer_id": "96B835C5-C228-46B7-B55A-C02DEA4A6F41",
                "firstname": "Nina",
                "lastname": "Liu",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Barrie",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Nina",
                    "lastname": "Liu",
                    "phone": "647-918-0190",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "25 Lucas Ave",
                    "street_2": "",
                    "zip_code": "L4N 9N2"
                }
            },
            "customer_debited_date": "2018-11-27T17:20:57.199Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-28T14:08:11Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202003594-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.49,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.58,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.58,
                    "created_date": "2018-11-25T13:13:43Z",
                    "debited_date": "2018-11-27T17:20:57Z",
                    "description": null,
                    "last_updated_date": "2018-11-28T14:08:11Z",
                    "offer_id": 2995875,
                    "offer_sku": "TAG0444-00-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55210014",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 19.97,
                    "price_additional_info": null,
                    "price_unit": 19.97,
                    "product_medias": [],
                    "product_sku": "12619546",
                    "product_title": "Ashlin® DESIGNER | ALABAMA Luggage tags Set of 4",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-11-28T14:08:11Z",
                    "refunds": [],
                    "shipped_date": "2018-11-28T14:04:36Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.6,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 5.07,
                    "total_price": 29.92
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 19.97,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canpar",
            "shipping_price": 9.95,
            "shipping_tracking": "D420749820000000150001",
            "shipping_tracking_url": "https://www.canpar.ca/en/track/TrackingAction.do?locale=en&type=0&reference=D420749820000000150001",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 5.07,
            "total_price": 29.92
        },
        {
            "acceptance_decision_date": "2018-11-27T16:10:02Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202038715",
            "created_date": "2018-11-25T20:01:20Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Mississauga",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Catch",
                    "lastname": "Catomeris",
                    "phone": "226-927-7272",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "5739 Bell Harbour Dr.",
                    "street_2": "",
                    "zip_code": "L5M 5L1"
                },
                "civility": "",
                "customer_id": "FB0C2121-5517-4E40-B0AA-E3D0B215EB48",
                "firstname": "Catriona",
                "lastname": "Catomeris",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Mississauga",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Catch",
                    "lastname": "Catomeris",
                    "phone": "226-927-7272",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "5739 Bell Harbour Dr.",
                    "street_2": "",
                    "zip_code": "L5M 5L1"
                }
            },
            "customer_debited_date": "2018-11-27T16:22:59.105Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-28T14:13:11Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202038715-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 5.24,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.68,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.68,
                    "created_date": "2018-11-25T20:01:20Z",
                    "debited_date": "2018-11-27T16:22:59Z",
                    "description": null,
                    "last_updated_date": "2018-11-28T14:13:11Z",
                    "offer_id": 2995876,
                    "offer_sku": "T7794-18-09",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55250819",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 24.97,
                    "price_additional_info": null,
                    "price_unit": 24.97,
                    "product_medias": [],
                    "product_sku": "12619487",
                    "product_title": "Ashlin® DESIGNER | DEANGELO Bifold ticket wallet",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-11-28T14:13:11Z",
                    "refunds": [],
                    "shipped_date": "2018-11-28T14:05:48Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 3.25,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 5.92,
                    "total_price": 34.92
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 24.97,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canpar",
            "shipping_price": 9.95,
            "shipping_tracking": "D420749820000000154001",
            "shipping_tracking_url": "https://www.canpar.ca/en/track/TrackingAction.do?locale=en&type=0&reference=D420749820000000154001",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 5.92,
            "total_price": 34.92
        },
        {
            "acceptance_decision_date": "2018-11-27T19:17:40Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202215020",
            "created_date": "2018-11-27T18:22:33Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Goderich",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "SHirley",
                    "lastname": "Schmidt",
                    "phone": "519-524-4177",
                    "phone_secondary": "226-222-0240",
                    "state": "ON",
                    "street_1": "154 Elgin Ave W",
                    "street_2": "",
                    "zip_code": "N7A 2E6"
                },
                "civility": "",
                "customer_id": "20AB897E-4CD1-470F-A163-28727CDEA632",
                "firstname": "SHirley",
                "lastname": "Schmidt",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Goderich",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "SHirley",
                    "lastname": "Schmidt",
                    "phone": "519-524-4177",
                    "phone_secondary": "226-222-0240",
                    "state": "ON",
                    "street_1": "154 Elgin Ave W",
                    "street_2": "",
                    "zip_code": "N7A 2E6"
                }
            },
            "customer_debited_date": "2018-11-27T19:28:05.327Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-28T16:48:17Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202215020-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.49,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.58,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.58,
                    "created_date": "2018-11-27T18:22:33Z",
                    "debited_date": "2018-11-27T19:28:05Z",
                    "description": null,
                    "last_updated_date": "2018-11-28T16:48:17Z",
                    "offer_id": 3969363,
                    "offer_sku": "7931-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55454670",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 19.97,
                    "price_additional_info": null,
                    "price_unit": 19.97,
                    "product_medias": [],
                    "product_sku": "12940151",
                    "product_title": "Ashlin Leather Men's Wallet - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-11-28T16:48:17Z",
                    "refunds": [],
                    "shipped_date": "2018-11-28T16:40:12Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.6,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 5.07,
                    "total_price": 29.92
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 19.97,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canpar",
            "shipping_price": 9.95,
            "shipping_tracking": "D420749820000000156001",
            "shipping_tracking_url": "https://www.canpar.ca/en/track/TrackingAction.do?locale=en&type=0&reference=D420749820000000156001",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 5.07,
            "total_price": 29.92
        },
        {
            "acceptance_decision_date": "2018-11-28T13:53:21Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202243108",
            "created_date": "2018-11-28T04:47:14Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "OTTAWA",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "SAMIA",
                    "lastname": "IBRAHIM",
                    "phone": "613-424-9441",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "864 PARADISE CRESCENT",
                    "street_2": "",
                    "zip_code": "K4A 3S4"
                },
                "civility": "",
                "customer_id": "5E9447C6-7555-4CFD-9569-24131773C1E2",
                "firstname": "SAMIA",
                "lastname": "IBRAHIM",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "OTTAWA",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "SAMIA",
                    "lastname": "IBRAHIM",
                    "phone": "613-424-9441",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "864 PARADISE CRESCENT",
                    "street_2": "",
                    "zip_code": "K4A 3S4"
                }
            },
            "customer_debited_date": "2018-11-28T14:20:50.531Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-29T13:58:14Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202243108-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 4.49,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.58,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.58,
                    "created_date": "2018-11-28T04:47:14Z",
                    "debited_date": "2018-11-28T14:20:50Z",
                    "description": null,
                    "last_updated_date": "2018-11-29T13:58:14Z",
                    "offer_id": 3969403,
                    "offer_sku": "L8773-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55485984",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 19.97,
                    "price_additional_info": null,
                    "price_unit": 19.97,
                    "product_medias": [],
                    "product_sku": "12940120",
                    "product_title": "Ashlin Leather Companion Bag - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-11-29T13:58:14Z",
                    "refunds": [],
                    "shipped_date": "2018-11-29T13:54:49Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.6,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 5.07,
                    "total_price": 29.92
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 19.97,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canpar",
            "shipping_price": 9.95,
            "shipping_tracking": "D420749820000000157001",
            "shipping_tracking_url": "https://www.canpar.ca/en/track/TrackingAction.do;jsessionid=505BC939B557186E15B686759D268FA0",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 5.07,
            "total_price": 29.92
        },
        {
            "acceptance_decision_date": "2018-11-30T19:44:14Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202359028",
            "created_date": "2018-11-30T16:12:30Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Laval",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Angeline",
                    "lastname": "Chay",
                    "phone": "514-546-4156",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "965 Rue De Magellan",
                    "street_2": "",
                    "zip_code": "H7R 0A1"
                },
                "civility": "",
                "customer_id": "F30B95A0-28C0-43FA-8853-9272DDAC2F1F",
                "firstname": "Angeline",
                "lastname": "Chay",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Laval",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Angeline",
                    "lastname": "Chay",
                    "phone": "514-546-4156",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "965 Rue De Magellan",
                    "street_2": "",
                    "zip_code": "H7R 0A1"
                }
            },
            "customer_debited_date": "2018-11-30T19:52:37.173Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-11-30T20:58:10Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202359028-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.49,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.58,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.58,
                    "created_date": "2018-11-30T16:12:30Z",
                    "debited_date": "2018-11-30T19:52:37Z",
                    "description": null,
                    "last_updated_date": "2018-11-30T20:58:10Z",
                    "offer_id": 2995854,
                    "offer_sku": "RFID7728-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55614532",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 19.97,
                    "price_additional_info": null,
                    "price_unit": 19.97,
                    "product_medias": [],
                    "product_sku": "12619481",
                    "product_title": "Ashlin® BARRON RFID Blocking Mens Wallet with Angled Pockets, Black (-01-)",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-11-30T20:58:10Z",
                    "refunds": [],
                    "shipped_date": "2018-11-30T20:50:52Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.5,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 1,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 5.07,
                    "total_price": 29.92
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 19.97,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": null,
            "shipping_price": 9.95,
            "shipping_tracking": null,
            "shipping_tracking_url": null,
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCLocal",
            "shipping_zone_label": "Quebec Local",
            "total_commission": 5.07,
            "total_price": 29.92
        },
        {
            "acceptance_decision_date": "2018-12-03T17:20:28Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202369547",
            "created_date": "2018-11-30T23:32:32Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Kelowna",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Carol",
                    "lastname": "Husch",
                    "phone": "250-807-7851",
                    "phone_secondary": "250-826-6145",
                    "state": "BC",
                    "street_1": "370 Poonian St",
                    "street_2": "",
                    "zip_code": "V1X 7Z1"
                },
                "civility": "",
                "customer_id": "11908D90-B550-47B9-97B8-7C3860CED6D3",
                "firstname": "Carol",
                "lastname": "Husch",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Kelowna",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Carol",
                    "lastname": "Husch",
                    "phone": "250-807-7851",
                    "phone_secondary": "250-826-6145",
                    "state": "BC",
                    "street_1": "370 Poonian St",
                    "street_2": "",
                    "zip_code": "V1X 7Z1"
                }
            },
            "customer_debited_date": "2018-12-03T17:32:19.311Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-04T18:19:52Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202369547-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 3.74,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.49,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.49,
                    "created_date": "2018-11-30T23:32:32Z",
                    "debited_date": "2018-12-03T17:32:19Z",
                    "description": null,
                    "last_updated_date": "2018-12-04T15:43:14Z",
                    "offer_id": 3406544,
                    "offer_sku": "RFID5728-00-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55626413",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 14.97,
                    "price_additional_info": null,
                    "price_unit": 14.97,
                    "product_medias": [],
                    "product_sku": "12869226",
                    "product_title": "Ashlin® DESIGNER | BERNARD RFID Bifold wallet 10 card pockets",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-04T15:43:14Z",
                    "refunds": [],
                    "shipped_date": "2018-12-04T15:38:25Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.5,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0.75,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "total_commission": 4.23,
                    "total_price": 24.92
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 14.97,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "CanadaPost",
            "shipping_price": 9.95,
            "shipping_tracking": "8094769928705910",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/2006980000169922",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "BCLocal",
            "shipping_zone_label": "BC Local",
            "total_commission": 4.23,
            "total_price": 24.92
        },
        {
            "acceptance_decision_date": "2018-12-03T17:21:21Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202379589",
            "created_date": "2018-12-01T16:25:21Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Toronto",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Lesley",
                    "lastname": "Taylor",
                    "phone": "647-206-1422",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "151 Hastings Avenue",
                    "street_2": "",
                    "zip_code": "M4L 2L4"
                },
                "civility": "",
                "customer_id": "5F6C58F2-BDC1-45C1-9AA2-D21838FD5B5D",
                "firstname": "Lesley",
                "lastname": "Taylor",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Toronto",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Lesley",
                    "lastname": "Taylor",
                    "phone": "647-206-1422",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "151 Hastings Avenue",
                    "street_2": "",
                    "zip_code": "M4L 2L4"
                }
            },
            "customer_debited_date": "2018-12-03T18:21:18.631Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-04T18:38:14Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202379589-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.19,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.54,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.54,
                    "created_date": "2018-12-01T16:25:21Z",
                    "debited_date": "2018-12-03T18:21:18Z",
                    "description": null,
                    "last_updated_date": "2018-12-04T18:38:14Z",
                    "offer_id": 2995894,
                    "offer_sku": "L02-07-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55637881",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 17.99,
                    "price_additional_info": null,
                    "price_unit": 17.99,
                    "product_medias": [],
                    "product_sku": "12619530",
                    "product_title": "Ashlin® DESIGNER | SOPHIA Double lipstick case with mirror",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-04T18:38:14Z",
                    "refunds": [],
                    "shipped_date": "2018-12-04T18:31:43Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.34,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 4.73,
                    "total_price": 27.94
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 17.99,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 9.95,
            "shipping_tracking": "2006980000169922",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/2006980000169922",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 4.73,
            "total_price": 27.94
        },
        {
            "acceptance_decision_date": "2018-12-05T17:58:55Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202473805",
            "created_date": "2018-12-05T16:57:03Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Montreal",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Joelle",
                    "lastname": "Auger",
                    "phone": "514-458-0975",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "2297 Av De Lorimier",
                    "street_2": "",
                    "zip_code": "H2K 3X4"
                },
                "civility": "",
                "customer_id": "D46039DF-79FD-457D-830F-47B5380D17E1",
                "firstname": "Joelle",
                "lastname": "Auger",
                "locale": "fr_CA",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Montreal",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Joelle",
                    "lastname": "Auger",
                    "phone": "514-458-0975",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "2297 Av De Lorimier",
                    "street_2": "",
                    "zip_code": "H2K 3X4"
                }
            },
            "customer_debited_date": "2018-12-05T18:21:01.553Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-06T14:53:13Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202473805-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 5.24,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.68,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.68,
                    "created_date": "2018-12-05T16:57:03Z",
                    "debited_date": "2018-12-05T18:21:01Z",
                    "description": null,
                    "last_updated_date": "2018-12-06T14:53:13Z",
                    "offer_id": 3973797,
                    "offer_sku": "T7538-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "FR"
                        }
                    ],
                    "order_line_id": "55749479",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 24.97,
                    "price_additional_info": null,
                    "price_unit": 24.97,
                    "product_medias": [],
                    "product_sku": "12941101",
                    "product_title": "Ashlin Leather 2-Section Utility Kit - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-06T14:53:13Z",
                    "refunds": [],
                    "shipped_date": "2018-12-06T14:46:10Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.5,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 1.25,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 5.92,
                    "total_price": 34.92
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 24.97,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 9.95,
            "shipping_tracking": "3950328391837928",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328391837928",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCLocal",
            "shipping_zone_label": "Quebec Local",
            "total_commission": 5.92,
            "total_price": 34.92
        },
        {
            "acceptance_decision_date": "2018-12-07T16:25:18Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202529160",
            "created_date": "2018-12-07T16:18:35Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "North York",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Denise",
                    "lastname": "MacKinnon",
                    "phone": "416-553-4771",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "1100-2235 Sheppard Ave. East",
                    "street_2": "",
                    "zip_code": "M2J 5B5"
                },
                "civility": "",
                "customer_id": "A4F655E7-96F7-4A22-B121-851978BB3640",
                "firstname": "Denise",
                "lastname": "MacKinnon",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "North York",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Denise",
                    "lastname": "MacKinnon",
                    "phone": "416-553-4771",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "1100-2235 Sheppard Ave. East",
                    "street_2": "",
                    "zip_code": "M2J 5B5"
                }
            },
            "customer_debited_date": "2018-12-07T16:37:55.078Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-07T18:43:17Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202529160-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.64,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.6,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.6,
                    "created_date": "2018-12-07T16:18:35Z",
                    "debited_date": "2018-12-07T16:37:55Z",
                    "description": null,
                    "last_updated_date": "2018-12-07T18:43:17Z",
                    "offer_id": 3394190,
                    "offer_sku": "RFID5748-07-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55812437",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12619474",
                    "product_title": "Ashlin® DESIGNER | EDWARD RFID Blocking Slim Mens Wallet with Double Billfold",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-07T18:43:17Z",
                    "refunds": [],
                    "shipped_date": "2018-12-07T18:35:38Z",
                    "shipping_price": 14.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.94,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.07,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 5.24,
                    "total_price": 30.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 14.95,
            "shipping_tracking": "3950328677080925",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328677080925",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 5.24,
            "total_price": 30.9
        },
        {
            "acceptance_decision_date": "2018-12-07T22:04:51Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202537718",
            "created_date": "2018-12-07T20:47:20Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Prevost",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Denis",
                    "lastname": "Savard",
                    "phone": "450-224-1891",
                    "phone_secondary": "450-821-9694",
                    "state": "QC",
                    "street_1": "1516 montee sauvage",
                    "street_2": "",
                    "zip_code": "J0R 1T0"
                },
                "civility": "",
                "customer_id": "3241A83A-175A-4BBB-8D99-E5A3E05E556B",
                "firstname": "Denis",
                "lastname": "Savard",
                "locale": "fr_CA",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Prevost",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Denis",
                    "lastname": "Savard",
                    "phone": "450-224-1891",
                    "phone_secondary": "450-821-9694",
                    "state": "QC",
                    "street_1": "1516 montee sauvage",
                    "street_2": "",
                    "zip_code": "J0R 1T0"
                }
            },
            "customer_debited_date": "2018-12-07T22:18:20.551Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-11T14:33:13Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202537718-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 5.39,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.7,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.7,
                    "created_date": "2018-12-07T20:47:20Z",
                    "debited_date": "2018-12-07T22:18:20Z",
                    "description": null,
                    "last_updated_date": "2018-12-11T14:33:13Z",
                    "offer_id": 3973797,
                    "offer_sku": "T7538-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "FR"
                        }
                    ],
                    "order_line_id": "55822169",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12941101",
                    "product_title": "Ashlin Leather 2-Section Utility Kit - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-11T14:33:13Z",
                    "refunds": [],
                    "shipped_date": "2018-12-11T14:25:57Z",
                    "shipping_price": 19.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 1,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0.8,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 6.09,
                    "total_price": 35.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 19.95,
            "shipping_tracking": "3950328055458933",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328055458933",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCRemote",
            "shipping_zone_label": "Quebec Remote",
            "total_commission": 6.09,
            "total_price": 35.9
        },
        {
            "acceptance_decision_date": "2018-12-09T00:04:55Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202565566",
            "created_date": "2018-12-08T22:12:42Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "St. JOHN'S",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Laurie",
                    "lastname": "Oliver",
                    "phone": "709-351-1002",
                    "phone_secondary": "",
                    "state": "NL",
                    "street_1": "3 Gorman ave",
                    "street_2": "",
                    "zip_code": "A1G 1E5"
                },
                "civility": "",
                "customer_id": "2E5C75B3-01FC-461A-856F-66917ADE392F",
                "firstname": "Laurie",
                "lastname": "Oliver",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "St. JOHN'S",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Laurie",
                    "lastname": "Oliver",
                    "phone": "709-351-1002",
                    "phone_secondary": "",
                    "state": "NL",
                    "street_1": "3 Gorman ave",
                    "street_2": "",
                    "zip_code": "A1G 1E5"
                }
            },
            "customer_debited_date": "2018-12-09T00:12:26.035Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-11T14:33:13Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202565566-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 3.89,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.51,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.51,
                    "created_date": "2018-12-08T22:12:42Z",
                    "debited_date": "2018-12-09T00:12:26Z",
                    "description": null,
                    "last_updated_date": "2018-12-11T14:33:13Z",
                    "offer_id": 2995854,
                    "offer_sku": "RFID7728-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55853871",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12619481",
                    "product_title": "Ashlin® BARRON RFID Blocking Mens Wallet with Angled Pockets, Black (-01-)",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-11T14:33:13Z",
                    "refunds": [],
                    "shipped_date": "2018-12-11T14:25:58Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.49,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.39,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 4.4,
                    "total_price": 25.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 9.95,
            "shipping_tracking": "3950328068400936",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328068400936",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "NLLocal",
            "shipping_zone_label": "Newfoundland/Labrador Local",
            "total_commission": 4.4,
            "total_price": 25.9
        },
        {
            "acceptance_decision_date": "2018-12-10T14:29:20Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202598125",
            "created_date": "2018-12-10T14:22:03Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Granby",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Francois",
                    "lastname": "Dumouchel",
                    "phone": "450-361-2072",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "150 Denison ouest",
                    "street_2": "",
                    "zip_code": "J2G 4C8"
                },
                "civility": "",
                "customer_id": "4B582311-74AA-49C6-9D12-CFE5002F1CD7",
                "firstname": "Francois",
                "lastname": "Dumouchel",
                "locale": "fr_CA",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Granby",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Francois",
                    "lastname": "Dumouchel",
                    "phone": "450-361-2072",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "150 Denison ouest",
                    "street_2": "",
                    "zip_code": "J2G 4C8"
                }
            },
            "customer_debited_date": "2018-12-10T14:37:24.891Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-11T14:33:13Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202598125-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 6.87,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.89,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.89,
                    "created_date": "2018-12-10T14:22:03Z",
                    "debited_date": "2018-12-10T14:37:24Z",
                    "description": null,
                    "last_updated_date": "2018-12-11T14:33:13Z",
                    "offer_id": 3973797,
                    "offer_sku": "T7538-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "FR"
                        }
                    ],
                    "order_line_id": "55891270",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 31.9,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12941101",
                    "product_title": "Ashlin Leather 2-Section Utility Kit - Black",
                    "promotions": [],
                    "quantity": 2,
                    "received_date": "2018-12-11T14:33:13Z",
                    "refunds": [],
                    "shipped_date": "2018-12-11T14:25:58Z",
                    "shipping_price": 13.9,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.7,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 1.6,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 7.76,
                    "total_price": 45.8
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 31.9,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 13.9,
            "shipping_tracking": "3950328058922936",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328058922936",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCLocal",
            "shipping_zone_label": "Quebec Local",
            "total_commission": 7.76,
            "total_price": 45.8
        },
        {
            "acceptance_decision_date": "2018-12-11T18:47:42Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202627126",
            "created_date": "2018-12-11T17:12:33Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Saint-Eustache",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Gabrielle",
                    "lastname": "Joly",
                    "phone": "514-827-8416",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "176 Rue De Martigny",
                    "street_2": "",
                    "zip_code": "J7P 4K2"
                },
                "civility": "",
                "customer_id": "4CB401DB-CC60-49F6-9473-663C985D7B77",
                "firstname": "Gabrielle",
                "lastname": "Joly",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Saint-Eustache",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Gabrielle",
                    "lastname": "Joly",
                    "phone": "514-827-8416",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "176 Rue De Martigny",
                    "street_2": "",
                    "zip_code": "J7P 4K2"
                }
            },
            "customer_debited_date": "2018-12-11T18:57:06.476Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-12T21:28:18Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202627126-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 3.89,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.51,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.51,
                    "created_date": "2018-12-11T17:12:33Z",
                    "debited_date": "2018-12-11T18:57:06Z",
                    "description": null,
                    "last_updated_date": "2018-12-12T21:28:18Z",
                    "offer_id": 3973797,
                    "offer_sku": "T7538-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55924649",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12941101",
                    "product_title": "Ashlin Leather 2-Section Utility Kit - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-12T21:28:18Z",
                    "refunds": [],
                    "shipped_date": "2018-12-12T21:23:08Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.5,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0.8,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 4.4,
                    "total_price": 25.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 9.95,
            "shipping_tracking": "3950328545919937",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328545919937",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCLocal",
            "shipping_zone_label": "Quebec Local",
            "total_commission": 4.4,
            "total_price": 25.9
        },
        {
            "acceptance_decision_date": "2018-12-13T07:16:57Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202673794",
            "created_date": "2018-12-13T05:43:21Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Ottawa",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Chantal",
                    "lastname": "Banks",
                    "phone": "343-542-9301",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "1415-1310 mcwatters road",
                    "street_2": "",
                    "zip_code": "K2C 3N8"
                },
                "civility": "",
                "customer_id": "15EF7293-7CF3-4414-865A-3208F7A4D6C1",
                "firstname": "Chantal",
                "lastname": "Banks",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Ottawa",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Chantal",
                    "lastname": "Banks",
                    "phone": "343-542-9301",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "1415-1310 mcwatters road",
                    "street_2": "",
                    "zip_code": "K2C 3N8"
                }
            },
            "customer_debited_date": "2018-12-13T12:20:58.025Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-13T18:18:14Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202673794-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 3.89,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.51,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.51,
                    "created_date": "2018-12-13T05:43:21Z",
                    "debited_date": "2018-12-13T12:20:58Z",
                    "description": null,
                    "last_updated_date": "2018-12-13T18:18:14Z",
                    "offer_id": 2995856,
                    "offer_sku": "RFID756-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "55978332",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12619479",
                    "product_title": "Ashlin® DESIGNER | DERMOT RFID Blocking Mens Wallet with Flipup Section",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-13T18:18:14Z",
                    "refunds": [],
                    "shipped_date": "2018-12-13T18:12:27Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.07,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 4.4,
                    "total_price": 25.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 9.95,
            "shipping_tracking": "3950328709774938",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328709774938",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 4.4,
            "total_price": 25.9
        },
        {
            "acceptance_decision_date": "2018-12-15T03:01:39Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202733636",
            "created_date": "2018-12-15T01:07:09Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Stoney Creek",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Julianne",
                    "lastname": "Bowlby",
                    "phone": "905-561-0208",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "1911 Rymal Rd. E.",
                    "street_2": "",
                    "zip_code": "L8J 2R6"
                },
                "civility": "",
                "customer_id": "3EA36BC9-CF53-4BF8-A8B1-4D2A42D2E958",
                "firstname": "Julianne",
                "lastname": "Bowlby",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Stoney Creek",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Julianne",
                    "lastname": "Bowlby",
                    "phone": "905-561-0208",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "1911 Rymal Rd. E.",
                    "street_2": "",
                    "zip_code": "L8J 2R6"
                }
            },
            "customer_debited_date": "2018-12-15T03:21:09.210Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-17T18:38:14Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202733636-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 3.89,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.51,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.51,
                    "created_date": "2018-12-15T01:07:09Z",
                    "debited_date": "2018-12-15T03:21:09Z",
                    "description": null,
                    "last_updated_date": "2018-12-17T18:38:14Z",
                    "offer_id": 5609762,
                    "offer_sku": "RFIDL8352-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56055335",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "13325175",
                    "product_title": "Ashlin® DESIGNER | BLONDELLE RFID Blocking Ladies Wallet with Cheque Mate",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-17T18:38:14Z",
                    "refunds": [],
                    "shipped_date": "2018-12-17T18:33:10Z",
                    "shipping_price": 9.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.07,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 4.4,
                    "total_price": 25.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 9.95,
            "shipping_tracking": "3950328335903948",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/resultList?searchFor=3950328335903948",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 4.4,
            "total_price": 25.9
        },
        {
            "acceptance_decision_date": "2018-12-15T17:01:42Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202744723",
            "created_date": "2018-12-15T16:02:11Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Woodbridge",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Michael",
                    "lastname": "Pittini",
                    "phone": "905-856-6954",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "11 Bayside Court",
                    "street_2": "",
                    "zip_code": "L4L 8J8"
                },
                "civility": "",
                "customer_id": "AECF1814-D348-465C-BB82-C76A74C67410",
                "firstname": "Michael",
                "lastname": "Pittini",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Woodbridge",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Michael",
                    "lastname": "Pittini",
                    "phone": "905-856-6954",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "11 Bayside Court",
                    "street_2": "",
                    "zip_code": "L4L 8J8"
                }
            },
            "customer_debited_date": "2018-12-15T17:12:14.672Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-17T17:53:17Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202744723-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.64,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.6,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.6,
                    "created_date": "2018-12-15T16:02:11Z",
                    "debited_date": "2018-12-15T17:12:14Z",
                    "description": null,
                    "last_updated_date": "2018-12-17T17:53:17Z",
                    "offer_id": 2995883,
                    "offer_sku": "RFID6036-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56068045",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12619509",
                    "product_title": "Ashlin® DESIGNER | FRANCOIS RFID BLOCKING Business Card Holder Gusseted with ID section",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-17T17:53:17Z",
                    "refunds": [],
                    "shipped_date": "2018-12-17T17:48:52Z",
                    "shipping_price": 14.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.95,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.07,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 5.24,
                    "total_price": 30.9
                },
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 2.09,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.27,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.27,
                    "created_date": "2018-12-15T16:02:11Z",
                    "debited_date": "2018-12-15T17:12:14Z",
                    "description": null,
                    "last_updated_date": "2018-12-17T17:53:17Z",
                    "offer_id": 2995938,
                    "offer_sku": "PEN02-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56068046",
                    "order_line_index": 2,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 9.95,
                    "price_additional_info": null,
                    "price_unit": 9.95,
                    "product_medias": [],
                    "product_sku": "12619535",
                    "product_title": "Ashlin® DESIGNER | L'AQUILA Double Pen Case",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-17T17:53:17Z",
                    "refunds": [],
                    "shipped_date": "2018-12-17T17:48:52Z",
                    "shipping_price": 3.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 0.51,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.29,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 2.36,
                    "total_price": 13.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 25.9,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 18.9,
            "shipping_tracking": "3950328271817941",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328271817941",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 7.6,
            "total_price": 44.8
        },
        {
            "acceptance_decision_date": "2018-12-17T18:48:28Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202801593",
            "created_date": "2018-12-17T18:47:29Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Levis",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Maude",
                    "lastname": "Tremblay",
                    "phone": "418-928-9110",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "29 Rue Du Tamarac",
                    "street_2": "",
                    "zip_code": "G6J 1W3"
                },
                "civility": "",
                "customer_id": "CBA1880C-6F52-457A-BA0D-9E579594DC3F",
                "firstname": "Maude",
                "lastname": "Tremblay",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Levis",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Maude",
                    "lastname": "Tremblay",
                    "phone": "418-928-9110",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "29 Rue Du Tamarac",
                    "street_2": "",
                    "zip_code": "G6J 1W3"
                }
            },
            "customer_debited_date": "2018-12-17T18:57:31.229Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-17T22:33:10Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202801593-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 4.64,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.6,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.6,
                    "created_date": "2018-12-17T18:47:29Z",
                    "debited_date": "2018-12-17T18:57:31Z",
                    "description": null,
                    "last_updated_date": "2018-12-17T22:33:10Z",
                    "offer_id": 3973797,
                    "offer_sku": "T7538-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56132786",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12941101",
                    "product_title": "Ashlin Leather 2-Section Utility Kit - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-17T22:33:10Z",
                    "refunds": [],
                    "shipped_date": "2018-12-17T22:29:35Z",
                    "shipping_price": 14.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.75,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0.8,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 5.24,
                    "total_price": 30.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 14.95,
            "shipping_tracking": "3950328427273942",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328427273942",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCLocal",
            "shipping_zone_label": "Quebec Local",
            "total_commission": 5.24,
            "total_price": 30.9
        },
        {
            "acceptance_decision_date": "2018-12-22T16:27:20Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202934466",
            "created_date": "2018-12-22T15:57:01Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "North York",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Zenglian",
                    "lastname": "Liu",
                    "phone": "613-981-0093",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "117 Benjamin Boake Trail",
                    "street_2": "",
                    "zip_code": "M3J 3C8"
                },
                "civility": "",
                "customer_id": "4F2C955E-B8A8-463B-8083-2DF4BF1434B9",
                "firstname": "Zenglian",
                "lastname": "Liu",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "North York",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Zenglian",
                    "lastname": "Liu",
                    "phone": "613-981-0093",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "117 Benjamin Boake Trail",
                    "street_2": "",
                    "zip_code": "M3J 3C8"
                }
            },
            "customer_debited_date": "2018-12-22T16:37:27.323Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-28T15:44:38Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202934466-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 7.18,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.93,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.93,
                    "created_date": "2018-12-22T15:57:01Z",
                    "debited_date": "2018-12-22T16:37:27Z",
                    "description": null,
                    "last_updated_date": "2018-12-27T19:14:26Z",
                    "offer_id": 5609762,
                    "offer_sku": "RFIDL8352-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56283256",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 47.85,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "13325175",
                    "product_title": "Ashlin® DESIGNER | BLONDELLE RFID Blocking Ladies Wallet with Cheque Mate",
                    "promotions": [],
                    "quantity": 3,
                    "received_date": "2018-12-27T19:14:26Z",
                    "refunds": [],
                    "shipped_date": "2018-12-27T19:09:56Z",
                    "shipping_price": 0,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 6.22,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 8.11,
                    "total_price": 47.85
                },
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 2.39,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.31,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.31,
                    "created_date": "2018-12-22T15:57:01Z",
                    "debited_date": "2018-12-22T16:37:27Z",
                    "description": null,
                    "last_updated_date": "2018-12-27T19:14:26Z",
                    "offer_id": 2995849,
                    "offer_sku": "RFIDT7774-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56283257",
                    "order_line_index": 2,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12619505",
                    "product_title": "Ashlin® DESIGNER | MONACO RFID Blocking Zippered Travel Wallet",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-27T19:14:26Z",
                    "refunds": [],
                    "shipped_date": "2018-12-27T19:09:56Z",
                    "shipping_price": 0,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.07,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 2.7,
                    "total_price": 15.95
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 63.8,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post / Canpar",
            "shipping_price": 0,
            "shipping_tracking": "8094769656657109 / D4207498200000001800001",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/resultList?searchFor=8094769656657109  /",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 10.81,
            "total_price": 63.8
        },
        {
            "acceptance_decision_date": "2018-12-22T20:27:23Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "202940176",
            "created_date": "2018-12-22T18:37:22Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Montreal",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Marc",
                    "lastname": "Buisson",
                    "phone": "514-885-8917",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "709-1650 Rue Saint-Timothee",
                    "street_2": "",
                    "zip_code": "H2L 3P1"
                },
                "civility": "",
                "customer_id": "7C4F479F-73A9-4198-9FDC-FDCA8B283C04",
                "firstname": "Marc",
                "lastname": "Buisson",
                "locale": "fr_FR",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Montreal",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Marc",
                    "lastname": "Buisson",
                    "phone": "514-885-8917",
                    "phone_secondary": "",
                    "state": "QC",
                    "street_1": "709-1650 Rue Saint-Timothee",
                    "street_2": "",
                    "zip_code": "H2L 3P1"
                }
            },
            "customer_debited_date": "2018-12-22T20:37:36.446Z",
            "has_customer_message": true,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2018-12-27T14:33:12Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "202940176-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.64,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.6,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.6,
                    "created_date": "2018-12-22T18:37:22Z",
                    "debited_date": "2018-12-22T20:37:36Z",
                    "description": null,
                    "last_updated_date": "2018-12-27T14:33:12Z",
                    "offer_id": 3969354,
                    "offer_sku": "6447-07-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56289677",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12940193",
                    "product_title": "Ashlin JARVIS Lambskin Napa Leather Men's Wallet - Midnight",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2018-12-27T14:33:12Z",
                    "refunds": [],
                    "shipped_date": "2018-12-27T14:29:37Z",
                    "shipping_price": 14.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0.75,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0.8,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "QST"
                        }
                    ],
                    "total_commission": 5.24,
                    "total_price": 30.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 14.95,
            "shipping_tracking": "3950328516668109",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328516668109",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "QCLocal",
            "shipping_zone_label": "Quebec Local",
            "total_commission": 5.24,
            "total_price": 30.9
        },
        {
            "acceptance_decision_date": "2019-01-02T04:28:02Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "203427819",
            "created_date": "2019-01-02T03:51:38Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Kelowna",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Donna",
                    "lastname": "Stuike",
                    "phone": "250-862-8844",
                    "phone_secondary": "",
                    "state": "BC",
                    "street_1": "972 Ryder Dr",
                    "street_2": "",
                    "zip_code": "V1Y 7T5"
                },
                "civility": "",
                "customer_id": "E6770352-BF0E-43BB-BA57-8E9A46B92848",
                "firstname": "Donna",
                "lastname": "Stuike",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Kelowna",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Donna",
                    "lastname": "Stuike",
                    "phone": "250-862-8844",
                    "phone_secondary": "",
                    "state": "BC",
                    "street_1": "972 Ryder Dr",
                    "street_2": "",
                    "zip_code": "V1Y 7T5"
                }
            },
            "customer_debited_date": "2019-01-02T04:36:50.937Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2019-01-02T14:28:13Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "203427819-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_32007",
                    "category_label": "Handbags & Purses",
                    "commission_fee": 23.47,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 3.05,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 3.05,
                    "created_date": "2019-01-02T03:51:38Z",
                    "debited_date": "2019-01-02T04:36:50Z",
                    "description": null,
                    "last_updated_date": "2019-01-02T14:28:13Z",
                    "offer_id": 3969332,
                    "offer_sku": "B8262-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56852027",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 156.45,
                    "price_additional_info": null,
                    "price_unit": 156.45,
                    "product_medias": [],
                    "product_sku": "12940132",
                    "product_title": "Ashlin Eliza Leather Organizer Bag - Black",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2019-01-02T14:28:13Z",
                    "refunds": [],
                    "shipped_date": "2019-01-02T14:24:46Z",
                    "shipping_price": 0,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 7.82,
                            "code": "GST"
                        },
                        {
                            "amount": 0,
                            "code": "PST"
                        }
                    ],
                    "total_commission": 26.52,
                    "total_price": 156.45
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 156.45,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 0,
            "shipping_tracking": "3950328353634114",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328353634114",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "BCLocal",
            "shipping_zone_label": "BC Local",
            "total_commission": 26.52,
            "total_price": 156.45
        },
        {
            "acceptance_decision_date": "2019-01-04T00:17:42Z",
            "can_cancel": false,
            "channel": null,
            "commercial_id": "203501519",
            "created_date": "2019-01-03T22:36:55Z",
            "currency_iso_code": "CAD",
            "customer": {
                "billing_address": {
                    "city": "Hamilton",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Orlando",
                    "lastname": "Gundayao",
                    "phone": "905-538-4012",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "85 East 31st Street",
                    "street_2": "",
                    "zip_code": "L8V 3N9"
                },
                "civility": "",
                "customer_id": "BF54510F-A7D7-4140-92A9-E01309E23A38",
                "firstname": "Orlando",
                "lastname": "Gundayao",
                "locale": "en_US",
                "shipping_address": {
                    "additional_info": "",
                    "city": "Hamilton",
                    "civility": "",
                    "company": "",
                    "country": "Canada",
                    "country_iso_code": null,
                    "firstname": "Orlando",
                    "lastname": "Gundayao",
                    "phone": "905-538-4012",
                    "phone_secondary": "",
                    "state": "ON",
                    "street_1": "85 East 31st Street",
                    "street_2": "",
                    "zip_code": "L8V 3N9"
                }
            },
            "customer_debited_date": "2019-01-04T00:27:18.166Z",
            "has_customer_message": false,
            "has_incident": false,
            "has_invoice": false,
            "last_updated_date": "2019-01-04T14:23:12Z",
            "leadtime_to_ship": 0,
            "order_additional_fields": [],
            "order_id": "203501519-A",
            "order_lines": [
                {
                    "can_refund": true,
                    "cancelations": [],
                    "category_code": "CAT_37937",
                    "category_label": "Wallets & Clutches",
                    "commission_fee": 4.64,
                    "commission_rate_vat": 13,
                    "commission_taxes": [
                        {
                            "amount": 0.6,
                            "code": "ON_HST",
                            "rate": 13
                        }
                    ],
                    "commission_vat": 0.6,
                    "created_date": "2019-01-03T22:36:55Z",
                    "debited_date": "2019-01-04T00:27:18Z",
                    "description": null,
                    "last_updated_date": "2019-01-04T14:23:12Z",
                    "offer_id": 2995856,
                    "offer_sku": "RFID756-18-01",
                    "offer_state_code": "11",
                    "order_line_additional_fields": [
                        {
                            "code": "language",
                            "type": "STRING",
                            "value": "EN"
                        }
                    ],
                    "order_line_id": "56932212",
                    "order_line_index": 1,
                    "order_line_state": "RECEIVED",
                    "order_line_state_reason_code": null,
                    "order_line_state_reason_label": null,
                    "price": 15.95,
                    "price_additional_info": null,
                    "price_unit": 15.95,
                    "product_medias": [],
                    "product_sku": "12619479",
                    "product_title": "Ashlin® DESIGNER | DERMOT RFID Blocking Mens Wallet with Flipup Section",
                    "promotions": [],
                    "quantity": 1,
                    "received_date": "2019-01-04T14:23:12Z",
                    "refunds": [],
                    "shipped_date": "2019-01-04T14:17:58Z",
                    "shipping_price": 14.95,
                    "shipping_price_additional_unit": null,
                    "shipping_price_unit": null,
                    "shipping_taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 1.94,
                            "code": "HST"
                        }
                    ],
                    "taxes": [
                        {
                            "amount": 0,
                            "code": "GST"
                        },
                        {
                            "amount": 2.07,
                            "code": "HST"
                        }
                    ],
                    "total_commission": 5.24,
                    "total_price": 30.9
                }
            ],
            "order_state": "RECEIVED",
            "order_state_reason_code": null,
            "order_state_reason_label": null,
            "paymentType": "",
            "payment_type": "",
            "payment_workflow": "PAY_ON_ACCEPTANCE",
            "price": 15.95,
            "promotions": {
                "applied_promotions": [],
                "total_deduced_amount": 0
            },
            "quote_id": null,
            "shipping_carrier_code": null,
            "shipping_company": "Canada Post",
            "shipping_price": 14.95,
            "shipping_tracking": "3950328875910116",
            "shipping_tracking_url": "https://www.canadapost.ca/trackweb/en#/details/3950328875910116",
            "shipping_type_code": "M",
            "shipping_type_label": "Ground Regular",
            "shipping_zone_code": "ONLocal",
            "shipping_zone_label": "Ontario Local",
            "total_commission": 5.24,
            "total_price": 30.9
        }
    ],
    "total_count": 24
}