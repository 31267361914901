import React from 'react';
const asi_product_list_item = ({item,selectProduct,toUpdateList,editingOnChange,finishEditing,isCalculating,setDefaultImage,
                                   AsiProductTypes,AsiCategories,calculateNewPrices,availableImagesOnClick })=>{
    const index = toUpdateList.findIndex(i=>i.ExternalProductId === item.ExternalProductId);
    const selected = index !== -1;
    let toUpdateItem,price,originalPrice,primaryImage;
    if(selected){
        toUpdateItem = toUpdateList[index];
        price = toUpdateItem.PriceGrids.find(p=>p.Description.includes("Blank Pricing, 20-25 business days"));
        originalPrice = price !== undefined ? price.Prices[0].ListPrice:0;
        if(toUpdateItem.BasePrice === undefined){
            toUpdateItem.BasePrice = originalPrice
        }
    }
    if(item!==undefined && item!==null)
        primaryImage = item.Images.find(img=>img.IsPrimary)
    if(item!==null && item!==undefined){
        return(
            <li className= 'list-group-item'>
                <div className={'row'}>
                    <div className={'col-1'}>
                        <input type="checkbox" name={item.ExternalProductId} style={{margin:'45px 20px',width:'30px',height:'30px'}}  checked={selected} onChange={(e)=>selectProduct(e,item)} />
                    </div>
                    <div className={'col-2'}>
                        { primaryImage.ImageURL !== undefined &&  <img src={primaryImage.ImageURL}  width={'120px'} height={'120px'} alt={'Avatar'}/> }
                    </div>
                    <div className={'col-9'}>
                        <h3>{item.Name}</h3>
                        <p>{item.Description}</p>
                    </div>
                </div>
                {
                    (selected && !toUpdateItem.FinishedEditing) &&
                    <div style={{backgroundColor: "#e4d79f"}}>
                        <h2 className={'text-center'}>Editing Product </h2>
                        <button type={'button'} className={'float-right'} name={'FinishedEditing'} onClick={(e)=>finishEditing(e,index)}>Finish Editing</button>
                        <table className="table" style={{margin:'16px 0 0'}}>
                            <tbody>
                            <tr>
                                <th style={{textAlign:'center'}}>Product Name</th>
                                <td colSpan="3">
                                    <input type="text"  name={'Name'}  style={{width:'100%'}} value={toUpdateItem.Name} onChange={(e)=>editingOnChange(e,index)}/>
                                </td>
                            </tr>
                            <tr>
                                <th style={{textAlign:'center'}}>Product Type</th>
                                <td>
                                    {
                                        AsiProductTypes.length !==0 &&
                                        <select className="form-control" value={toUpdateItem.ProductType} name={'ProductType'} onChange={(e)=>editingOnChange(e,index)}>
                                            {AsiProductTypes.map((op,i)=>{
                                                return <option value={op} key={i}>{op}</option>
                                            })}
                                        </select>
                                    }
                                </td>
                                <th  style={{textAlign:'center'}}>Product Category</th>
                                <td>
                                    {
                                        AsiCategories.length !==0 &&
                                        <select className="form-control" value={toUpdateItem.Categories[0]} name={'Categories'} onChange={(e)=>editingOnChange(e,index)}>
                                            {AsiCategories.map((op,i)=>{
                                                return <option value={op} key={i}>{op}</option>
                                            })}
                                        </select>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <th style={{textAlign:'center'}}>Product Description</th>
                                <td colSpan="3">
                                    <textarea rows={'4'}  name={'Description'}  style={{width:'100%'}} value={toUpdateItem.Description} onChange={(e)=>editingOnChange(e,index)}/>
                                </td>
                            </tr>
                            <tr>
                                <th style={{textAlign:'center'}}>Base Price (USD)</th>
                                <td colSpan="3">
                                    <input type="text"  name={'BasePrice'}   value={toUpdateItem.BasePrice} onChange={(e)=>editingOnChange(e,index)}/>
                                    {toUpdateItem.BasePrice !== originalPrice &&
                                        <span>
                                            <button className={'btn-danger'} type={'button'} onClick={()=>calculateNewPrices(toUpdateItem,index)}>Calculate New prices Grids</button>&nbsp;&nbsp;
                                            {isCalculating&&<span className={"text-center text-success"}><i className="fa fa-spinner fa-spin"></i> &nbsp; <span style={{fontWeight:'bold',fontSize:'20px'}}>Calculating...</span></span>}
                                        </span>
                                    }
                                </td>

                            </tr>
                            {
                                toUpdateItem.ProductSKUs !== undefined && toUpdateItem.ProductSKUs.map((s,imgIndex)=>{
                                    const imageIndex = toUpdateItem.Images.findIndex(i=>i.Configurations[0].Value[0] === s.Configurations[0].Value[0]);
                                    const availableImages = toUpdateItem.availableImages.filter(i=>i.Sku===s.SKU);
                                    const defaultImageStyle = toUpdateItem.Images[imageIndex].NewImageURL === undefined ? "green":"";
                                    const isPrimary = toUpdateItem.Images[imageIndex].IsPrimary;
                                    return <tr key={imgIndex}>
                                            <th style={{textAlign:'center'}}>
                                                {s.SKU} <br/>
                                                <br/>
                                                Set as Primary<br/>
                                                <input type={'radio'} name={'IsPrimary'} defaultChecked={isPrimary} onClick={(e)=>setDefaultImage(e,index,imageIndex)}/>
                                            </th>
                                            <td colSpan="3">
                                                <div className={'row'}>
                                                    <div className={'col-2'}>
                                                        <button type={'button'} onClick={()=>availableImagesOnClick(index,imageIndex,toUpdateItem.Images[imageIndex].ImageURL)} style={{padding:'6px',backgroundColor:defaultImageStyle}}>
                                                            <img src={toUpdateItem.Images[imageIndex].ImageURL} width={'120px'} height={'120px'} alt={'asi image'}/>
                                                        </button>
                                                    </div>
                                                    <div className={'col-1'}>
                                                        <span style={{fontWeight:'bold',width:'120px'}}>Available Images:</span>
                                                    </div>
                                                    <div className={'col'}>
                                                        {
                                                            availableImages.map((img,key)=>{
                                                                const url = img.ImageUrl.replace("uc?export=download&","thumbnail?");

                                                                const selectedStyle = toUpdateItem.Images[imageIndex].NewImageURL !== undefined && toUpdateItem.Images[imageIndex].NewImageURL === img.ImageUrl?"green":"";

                                                                return <button key={key} type={'button'} onClick={()=>availableImagesOnClick(index,imageIndex,img.ImageUrl)} style={{margin:'0 10px',backgroundColor:selectedStyle,padding:'6px'}}><img src={url}  alt={"availableImages"} width={'120px'} height={'120px'} /></button>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                }
            </li>
        )}else{
        return(null);
    }
};
export default asi_product_list_item;