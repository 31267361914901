import React, { Component } from 'react';
import {
    findProductBySku,
    getStoreInfo,
    getVariants,
    getImages,
    deleteProductImage,
    addImage
} from '../../Utils/bigCommerce';
import { baseURL } from '../../Utils/requestHelper';
import axios from "axios/index";
class SyncImage extends Component{
    constructor(props){
        super(props);
        this.state={
            sku:"",
            isSync:false
        }
    }
    handleOnchange=(e)=>{
        this.setState({
            sku:e.target.value
        })
    };
    //'WINE01RND-00-01'
    handleSyncProduct = async(e)=> {
        e.preventDefault();
        this.setState({
            isSync:true
        },async()=>{
            const storeInfo = await getStoreInfo('BigCommerce Ashlin');
            const findRes = await findProductBySku(storeInfo,this.state.sku );
            const productBG = findRes.data.data[0];
            //console.log(findRes);
            const findVariantRes = await getVariants(storeInfo,productBG.id);
            const variants = findVariantRes.data.data;
            //console.log(variants);


            const oldProductImagesRes = await getImages(storeInfo,productBG.id);

            const oldImages = oldProductImagesRes.data.data;

            const deleteImagesRes  = await oldImages.map(async (image)=>{
                return deleteProductImage(storeInfo,image.product_id,image.id);
            });

            await Promise.all(deleteImagesRes).then(()=>{
            });

            const addNewImagesRes = variants.map(async(pro,index)=>{
                const productDetailRes = await axios.get(`${baseURL}api/LegacySku/detail/${pro.sku}`);
                const product= productDetailRes.data[0];

                const imageDescription = product.Brand + " DESIGNER | " + product.Name + " " + product.ShortDescription.replace('.','')+" "+product.Sku;
                let mainImageRes,imagesGROUPRes;

                if(product.Urls.HostingerUrls.MAIN[0]!==undefined) {
                    //console.log(index);
                    if(index === 0) {
                        mainImageRes = await addImage(storeInfo, productBG.id, true, 1, imageDescription, product.Urls.HostingerUrls.MAIN[0]);
                    }else{
                        mainImageRes = await addImage(storeInfo, productBG.id, false, 1, imageDescription, product.Urls.HostingerUrls.MAIN[0]);
                    }
                }

                const imagesRes =await product.Urls.GoogleDriveUrls.BASE.map(async (img,index)=>{
                    return await addImage(storeInfo,productBG.id,false,(3),imageDescription+` BASE ${index}`,img);
                });
                const imagesModelRes =await product.Urls.GoogleDriveUrls.MODEL.map(async (img,index)=>{
                    return await addImage(storeInfo,productBG.id,false,(5),imageDescription+` MODEL ${index}`,img);
                });
                const imagesLIFERes =await product.Urls.GoogleDriveUrls.LIFE.map(async (img,index)=>{
                    return await addImage(storeInfo,productBG.id,false,(7),imageDescription+` LIFE ${index}`,img);
                });
                if(index ===0) {
                    imagesGROUPRes = await product.Urls.GoogleDriveUrls.GROUP.map(async (img, index) => {
                        return await addImage(storeInfo, productBG.id, false, (9), imageDescription + ` GROUP ${index}`, img);
                    });
                }
                await Promise.all([mainImageRes,imagesRes,imagesModelRes,imagesLIFERes,imagesGROUPRes]).then(()=>{
                });
            });

            await Promise.all(addNewImagesRes).then(()=>{
                alert("Finish Sync");
                this.setState({
                    isSync:false
                });
            });
        });
    };
    render(){
        return(
            <div>
                <h1 style={{textAlign:'center'}}>BigCommerce Sync Product Images and Image Description</h1>
                <br/><br/><br/><br/><br/>
                {this.state.isSync &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Updating, please wait...<br/><br/></div>}
                <div className={'row justify-content-md-center'}>
                    <div className={'col-md-auto'}>
                        <input className={'center'} value={this.state.sku} onChange={this.handleOnchange}/>
                    </div>
                    <div className={'col-md-auto'}>
                        <button className={'btn center'} onClick={this.handleSyncProduct}>Sync Product Image</button>
                    </div>
                </div>
            </div>)
    }
}
export default SyncImage;