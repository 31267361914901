import React from 'react';

const SubmitOrderItemDetailModal =(props)=>{

    return(
        <div className="modal fade" id="submitOrderDetail" tabIndex="-1" role="dialog" aria-labelledby="submitOrderDetailModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h1 className="modal-title text-center" id="submitOrderDetailModalLabel">Order Details
                        </h1>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h3 className="text-center">
                            <span className='font-weight-bold'>Status: &nbsp; </span>{props.OrderHeader.OrderStatus}

                        </h3>
                        <hr/>
                        <h1>
                           Tracking Number: &nbsp; <input type={'text'} name={"manualOrderTrackingNum"} value={props.manualOrderTrackingNum} onChange={props.handleStateOnchange}/>
                            <button type="button" className="btn btn-danger float-right" onClick={()=>props.handleShipLocally(props.OrderHeader.OrderNumber)}>Send Locally</button>
                        </h1>
                        <form  className="container-fluid">
                            <hr />
                            <h4 className="text-center">Order Summary</h4>
                            <div className={'row'}>
                                <p className={'col'} style={{textAlign:'center'}}><span className="font-weight-bold"> Order Number:</span> {props.OrderHeader.ThirdPartyOrderNo}</p>
                                <p className={'col'} style={{textAlign:'center'}}><span className="font-weight-bold"> Order Date:</span> {props.OrderHeader.OrderDate.slice(0,10)}</p>
                                <p className={'col'} style={{textAlign:'center'}}><span className="font-weight-bold"> Channel Name:</span> {props.channel}</p>
                            </div>
                            <hr />
                            <h4 className="text-center">Buyer / Recipient Info</h4>
                            <div className={'row'}>
                                <div className={'col-4'}>
                                    <p><span className="font-weight-bold "> Warehouse Code:</span> <input className="float-right" value={props.OrderHeader.WarehouseCode} name={'WarehouseCode'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Priority:</span> <input className="float-right" value={props.OrderHeader.Priority} name={'Priority'} onChange={props.handleOnChange} /></p>
                                    <p><span className="font-weight-bold"> Version:</span> <input className="float-right" value={props.OrderHeader.Priority} name={'Version'} onChange={props.handleOnChange} /></p>
                                </div>
                                <div className={'col-8'}>
                                    <h4>Ship To:</h4>
                                    <div className={'row'}>
                                    <div className={'col'}>
                                    <p><span className="font-weight-bold"> Company Name:</span><input className="float-right" value={props.OrderHeader.ShipTo.Name} name={'Name'} onChange={(e)=>props.handleOnChange(e,'ShipTo')} /></p>
                                    <p><span className="font-weight-bold"> Contact Name:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactName} name={'ContactName'} onChange={(e)=>props.handleOnChange(e,'ShipTo')}  /> </p>
                                    <p><span className="font-weight-bold"> Contact Phone:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactPhone} name={'ContactPhone'} onChange={(e)=>props.handleOnChange(e,'ShipTo')} /> </p>
                                    <p><span className="font-weight-bold"> Contact PhoneExt:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactPhoneExt} name={'ContactPhoneExt'} onChange={(e)=>props.handleOnChange(e,'ShipTo')}  /> </p>
                                    <p><span className="font-weight-bold"> Contact Email:</span><input className="float-right" value={props.OrderHeader.ShipTo.ContactEmail} name={'ContactEmail'} onChange={(e)=>props.handleOnChange(e,'ShipTo')} /> </p>
                                    </div>
                                    <div className={'col'}>
                                    <p><span className="font-weight-bold"> AddressLine1:</span><input className="float-right" value={props.OrderHeader.ShipTo.AddressLine1} name={'AddressLine1'} onChange={(e)=>props.handleOnChange(e,'ShipTo')}  /> </p>
                                    <p><span className="font-weight-bold"> AddressLine2:</span><input className="float-right" value={props.OrderHeader.ShipTo.AddressLine2} name={'AddressLine2'} onChange={(e)=>props.handleOnChange(e,'ShipTo')} /> </p>
                                    <p><span className="font-weight-bold"> AddressLine3:</span><input className="float-right" value={props.OrderHeader.ShipTo.AddressLine3} name={'AddressLine3'} onChange={(e)=>props.handleOnChange(e,'ShipTo')} /> </p>
                                    <p><span className="font-weight-bold"> City:</span><input className="float-right" value={props.OrderHeader.ShipTo.City} name={'City'} onChange={(e)=>props.handleOnChange(e,'ShipTo')}  /> </p>
                                    <p><span className="font-weight-bold"> State:</span><input className="float-right" value={props.OrderHeader.ShipTo.State} name={'State'} onChange={(e)=>props.handleOnChange(e,'ShipTo')}  /> </p>
                                    <p><span className="font-weight-bold"> Country:</span><input className="float-right" value={props.OrderHeader.ShipTo.CountryCode} name={'CountryCode'} onChange={(e)=>props.handleOnChange(e,'ShipTo')}  /> </p>
                                    <p><span className="font-weight-bold"> Zip Code:</span><input className="float-right" value={props.OrderHeader.ShipTo.Zip} name={'Zip'} onChange={(e)=>props.handleOnChange(e,'ShipTo')}  /> </p>
                                    </div>
                                    </div>

                                </div>
                            </div>

                            <hr />
                            <h4 className="text-center">Order Items Detail</h4>
                            {

                                props.OrderDetail.map((o,i)=>{
                                    const id = `new_order_detail_${i}`;
                                    return (
                                        <div key={i} id={id} className={'row'}>
                                            <div className={'col'}><span className="font-weight-bold"> Item SKU: </span><input value={o.ItemCode} name={'ItemCode'} onChange={(e)=>props.handleOnChange(e,'OrderDetail',i)}  /></div>
                                            <div className={'col'}><span className="font-weight-bold"> Item Name: </span>{o.ItemName}</div>
                                            <div className={'col'}><span className="font-weight-bold"> Item Qty: </span><input value={o.ItemQty} name={'ItemQty'} onChange={(e)=>props.handleOnChange(e,'OrderDetail',i)}  /></div>
                                            <div className={'col'}><span className="font-weight-bold"> Item PO #: </span>{o.poNo}</div>
                                            <button className='btn btn-danger' type={'button'} onClick={(e)=>props.handleAddItem(e,o,id)}>Add</button>
                                            <button className='btn btn-danger' type={'button'} onClick={(e)=>props.handleRemoveOrderLine(props.OrderHeader,o)}>Remove this Line</button>
                                        </div>
                                    )})

                            }
                            <br />
                            <hr/>
                            <div className="form-group">
                                <label htmlFor="comment">Comment</label>
                                <input type="text" className="form-control" id="comment"
                                       value={props.OrderHeader.Comment} name={'Comment'}
                                       onChange={props.handleOnChange}/>
                            </div>
                            <br/>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-danger" onClick={props.handleReSubmitOrder} disabled={true}>Re-Submit Order</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}


export default SubmitOrderItemDetailModal;