import React, { Component } from 'react';
import { createLoomisLabelOnSftp } from '../ashlinDesign';
import { testData,credentials} from "../loomis";
class LoomisTest extends Component{

    handleProcessShipment=async()=>{
        const LSOrder = testData;
        await createLoomisLabelOnSftp(LSOrder,"0.5",credentials);
    };
    render(){
        return(
            <div>
                <button className={'btn btn-danger'} type={'button'} onClick={this.handleProcessShipment} >Process Shipment</button>
            </div>
        )
    }
}

export default LoomisTest;