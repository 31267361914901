import React from 'react';
const fba_location_list_item = ({item,editingIndex,FBALocationOnchange,index,enableFBAEditing,updateFBALocation,cancelUpdate})=>{
        const editing = editingIndex===index;
        const readOnly = !editing;
        const className = editing? "table table-bordered table-dark":"table table-bordered";
        return(
            <li className= 'list-group-item' style={{backgroundColor:'#dee2e6'}}>
                <table className={className}  style={{marginBottom:'0'}}>
                    <thead>
                        <tr>
                            <th colSpan={'2'} style={{textAlign:'center'}}>
                               #{(index+1)} Location Name
                            </th>
                            <th colSpan={'3'}>
                                <input value={item.Name} style={{width:'100%'}} name={'Name'}  readOnly={readOnly} onChange={(e)=>FBALocationOnchange(e,index)}/>
                            </th>
                            {
                                editing &&
                                <th>
                                     <button type={'button'} className={'btn-danger'}  onClick={()=>updateFBALocation(index)}>Update FBA Location</button> &nbsp;&nbsp;
                                    <button type={'button'} className={'btn-secondary'}  onClick={()=>cancelUpdate()}>Cancel</button>
                                </th>
                            }
                            {
                                !editing &&
                                <th>
                                    <button type={'button'} className={'btn-primary'} style={{display:'block',margin:'auto'}} onClick={()=>enableFBAEditing(index)}>Edit FBA Location</button>
                                </th>
                            }

                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <th style={{textAlign:'right'}}>Address Line1</th>
                            <td>
                                <input value={item.Address1} style={{width:'100%'}} readOnly={readOnly}  name={'Address1'} onChange={(e)=>FBALocationOnchange(e,index)}/>
                            </td>
                            <th style={{textAlign:'right'}}>Address Line2

                            </th>
                            <td>
                                <input value={item.Address2}  style={{width:'100%'}}  readOnly={readOnly}  name={'Address2'} onChange={(e)=>FBALocationOnchange(e,index)}/>
                            </td>
                            <th style={{textAlign:'right'}}>PostCode

                            </th>
                            <td>
                                <input value={item.Zip} style={{width:'100%'}} readOnly={readOnly}  name={'Zip'} onChange={(e)=>FBALocationOnchange(e,index)}/>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th style={{textAlign:'right'}}>City

                            </th>
                            <td>
                                <input value={item.City} style={{width:'100%'}} readOnly={readOnly}  name={'City'} onChange={(e)=>FBALocationOnchange(e,index)}/>
                            </td>
                            <th style={{textAlign:'right'}}>State

                            </th>
                            <td>
                                <input value={item.State} style={{width:'100%'}} readOnly={readOnly}  name={'State'} onChange={(e)=>FBALocationOnchange(e,index)}/>
                            </td>
                            <th style={{textAlign:'right'}}>Country

                            </th>
                            <td>
                                <input value={item.CountryCode} style={{width:'100%'}}  readOnly={readOnly}  name={'CountryCode'}  onChange={(e)=>FBALocationOnchange(e,index)}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </li>
        );
};
export default fba_location_list_item;