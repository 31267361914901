import React from 'react';
const SelectInputWithDefault =({id,label,name,value,onChange,style, width, defaultValue})=>{
    return(
        <div className='row ' style={style}>
            <div className='col-4' style={{margin:'auto 0', textAlign:'right'}}>
                <label htmlFor={id} style={style}>{label}:</label>
            </div>
            <div className='col-7' style={{padding:'0',width:width}}>
                <select name={name} value={defaultValue}  onChange={onChange} className="form-control" id={id}>
                    {value.map((op,i)=>{
                        return <option value={op} key={i}>{op}</option>
                    })}
                </select>
            </div>
        </div>
    );
};
export default SelectInputWithDefault;