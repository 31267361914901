import React from 'react';
import EmailConfigurationListItemDetail from "./email_configuration_list_item_detail";
const email_configuration_list_item = ({item,index,emailConfigurationOnChange,updateEmailConfiguration,showEmailConfigurationOptionDetail,selectedEmailConfiguration,testSendEmail,emailConfigurationEditorOnchange,selectedEmailConfigurationIndex})=>{
    return(
        <tr>
            <td>
                <span style={{fontWeight:'bold'}}>{item.Description}</span>
            </td>
            <td>
                {item.Recipient}
            </td>
            <td>
                {item.Subject}
            </td>
            <td>
                <button type={'button'} onClick={()=>showEmailConfigurationOptionDetail(index)}>View Detail</button>
                {selectedEmailConfigurationIndex===index &&
                <EmailConfigurationListItemDetail item={selectedEmailConfiguration} index={selectedEmailConfigurationIndex} emailConfigurationOnChange={emailConfigurationOnChange} updateEmailConfiguration={updateEmailConfiguration}
                                                  testSendEmail={testSendEmail} emailConfigurationEditorOnchange={emailConfigurationEditorOnchange}
                />
                }
            </td>
        </tr>
    )

};
export default email_configuration_list_item;