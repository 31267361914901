import React from 'react';
import ListItem from './discount_detail_list_item';
const discount_detail_list = ({searchBox,onChange,list,channelOnSelect,discountPriceOnchange,discountPercentage})=>{
    //console.log(props);
    return(
        <div className={'list-group'}>
            {/*<input type={'text'} name={'searchBox'} value={searchBox}*/}
                   {/*style={{width:'100%',lineHeight:'30px',fontSize:'30px',border:'5px solid gray',fontWeight:''}}*/}
                   {/*placeholder={'Search SKU here'} onChange={onChange}*/}
            {/*/>*/}
            <table className="table discountDetailTable">
                <tbody>
                <tr>
                    <th>SKU</th>
                    <th>Original Price(CAD)</th>
                    <th>Discount Price(CAD)</th>
                    <th>Apply to</th>
                </tr>
                {list.map((item,index)=><ListItem item={item} key={index}  index={index} discountPercentage={discountPercentage}
                                                  channelOnSelect={channelOnSelect} discountPriceOnchange={discountPriceOnchange}
                />)}
                </tbody>
            </table>

        </div>
    )
};
export default discount_detail_list;