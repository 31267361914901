import React from 'react';
import { Link } from 'react-router-dom';
const OrderSideBar = (props)=>{
    const path = window.location.pathname;
    const ordersclassName = path==="/orders" ? "sidebar-selected" : "";
    const submittedOrdersclassName = path==="/submittedOrders" ? "sidebar-selected" : "";
      return(
          <ul className="list-group ashlinSideBar">
              <Link to='/orders'>
                  <button className={`list-group-item d-flex justify-content-between align-items-center ${ordersclassName}`} >
                     New Orders
                      <span className="badge badge-primary badge-pill">{props.newOrders}</span>
                  </button>
              </Link>
              <Link to='/submittedOrders'>
                  <button className={`list-group-item d-flex justify-content-between align-items-center ${submittedOrdersclassName}`} >
                      Submitted Orders
                      <span className="badge badge-primary badge-pill">{props.submittedOrders}</span>
                  </button>
              </Link>
          </ul>
      );
};

export default OrderSideBar;