import React from 'react';
import ListItem from './shipping_option_list_item';
import $ from "jquery";
const shipping_option_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
            <tr>
                <th>CHANNEL NAME</th>
                <th>OPTIONAL LEVEL</th>
                <th>CARRIER NAME</th>
                <th>SERVICE TYPE</th>
                <th><button className={'btn-success'} onClick={()=>{
                    $('#newShippingOptionDetail').modal('show');
                }}>Add new Shipping Option</button></th>
            </tr>
            </thead>
            <tbody>
            {props.list.map((item,index)=><ListItem item={item} index={index} key={index}  updateShippingOption={props.updateShippingOption} selectedShippingOption={props.selectedShippingOption}
                                                    shippingOptionOnChange={props.shippingOptionOnChange} showShippingOptionDetail={props.showShippingOptionDetail}/>)}
            </tbody>
        </table>
    )
};
export default shipping_option_list;
