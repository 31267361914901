import React, {Component} from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {baseURL, headers} from '../../Utils/requestHelper'
import  axios from 'axios';
import {getAllSKUNumberList, getHtsCa, getHtsUs} from "../../Utils/ashlinDesign";
class PriceManager extends Component {
    _fileReader = undefined;
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            currentStatus: 'NEW',
            skuNumberList:[],
            //price
            priceTemplate: '',
            rushOrder: false,
            imprinted: false,
            netPricing:false,
            selectedSKUNumber:"",
            selectedCurrency:"CAD",
            quickPrice: undefined,
            eCommercePrice:undefined,

            //export
            exportTemplate:'',
            toUploadText:'',
        }
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    getData = async() => {
        const skuNumberList = await getAllSKUNumberList();
        Promise.all([skuNumberList]).then(()=>{
            if(this._mounted){
                this.setState({
                    skuNumberList,
                })
            }
        });
    };
    componentDidMount(){
        this._mounted = true;
        this.getData();
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.type === "checkbox" ? e.target.checked : e.target.value;
        this.setState({
            [name]:value
        })
    };

    handleGeneratePrice = async()=>{
        const Sku = this.state.selectedSKUNumber;
        const Currency = this.state.selectedCurrency;
        const RushOrder = this.state.rushOrder;
        const Imprinted = this.state.imprinted;
        const NetPricing = this.state.netPricing;
        if(Sku === ""){
            alert("Please select a SKU!");
        }else{
            const data = {
                Sku,
                Currency,
                RushOrder,
                Imprinted,
                NetPricing
            };
            const URL = baseURL + 'api/ExportPrice/quick-price';
            const response = await axios.post(URL,data,{headers});
            this.setState({
               quickPrice: response.data
            })

        }
    };
    handleRenderView=()=>{
        const currentStatus = this.state.currentStatus;
        const exportTemplate = this.state.exportTemplate;
        const  priceTemplate = this.state. priceTemplate;
        switch (currentStatus) {
            case 'PRICE_GENERATOR':
                switch (priceTemplate) {
                    case 'QUICK_PRICE_GENERATOR':
                        return this.renderQuickPrice();
                    case 'QUICK_E_COMMERCE_PRICING':
                        return this.renderECommercePricing();
                    default:
                        return this.renderPriceGenerator();
                }
            case 'PRICE_EXPORT':
                if(exportTemplate !== ""){
                    return this.renderPriceExportWithTemplate();
                }else{
                    return this.renderPriceExport();
                }
            default:
                return this.renderNew();
        }
    };
    renderQuickPrice = ()=>{
        const skuNumberList = this.state.skuNumberList;
        const quickPrice = this.state.quickPrice;
        return <div>
            <h1 style={{margin:'10px', padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Quick Pricing</h1>
            <div className={'row'}>
                <div className={'col-6'}>
                    <table className="table table-bordered QuickPriceTable" style={{fontSize:'35px'}}>
                        <thead>
                        <tr>
                            <th colSpan={2}>Pricing Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>
                                SKU
                            </th>
                            <td>
                                <select name={'selectedSKUNumber'}  onChange={this.handleOnchange} className={'quick-pricing-sku'} >
                                    <option value="" >-- select a SKU --</option>
                                    {skuNumberList.map((op,i)=>{
                                        return <option value={op} key={i}>{op}</option>
                                    })}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Rush Order?
                            </th>
                            <td>
                                <input type={'checkbox'} checked={this.state.rushOrder} name={'rushOrder'} style={{width:'40px',height:'40px', verticalAlign: 'middle'}} onChange={this.handleOnchange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Imprinted?
                            </th>
                            <td>
                                <input type={'checkbox'} checked={this.state.imprinted} name={'imprinted'} style={{width:'40px',height:'40px', verticalAlign: 'middle'}} onChange={this.handleOnchange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Net Pricing?
                            </th>
                            <td>
                                <input type={'checkbox'} checked={this.state.netPricing} name={'netPricing'} style={{width:'40px',height:'40px', verticalAlign: 'middle'}} onChange={this.handleOnchange}/>
                            </td>
                        </tr>
                        <tr>
                            <th>
                               Currency
                            </th>
                            <td>
                                <select name={'selectedCurrency'}  onChange={this.handleOnchange} className={'quick-pricing-sku'} >
                                    <option value="CAD" >CAD</option>
                                    <option value="USD" >USD</option>
                                    <option value="AUD" >AUD</option>
                                    <option value="EUR" >EUR</option>
                                    <option value="GBP" >GBP</option>
                                    <option value="INR" >INR</option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type={'button'} className={'btn btn-success btn-block'} onClick={this.handleGeneratePrice}>Generate Price</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'col-6'}>
                    {quickPrice !== undefined &&
                        <table className="table table-bordered QuickPriceInfoTable" style={{fontSize:'20px'}}>
                            <thead>
                            <tr>
                                <th colSpan={2}>Pricing Mapping Information</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Minimum Order</th>
                                <th style={{color:'red'}}>{quickPrice.MinOrder}</th>
                            </tr>
                            <tr>
                                <th>Minimum Imprinted Order</th>
                                <th style={{color:'red'}}>{quickPrice.ImprintedMinOrder}</th>
                            </tr>
                            <tr>
                                <th>Price (1)</th>
                                <td>{quickPrice.qty1}</td>
                            </tr>
                            <tr>
                                <th>Price (6)</th>
                                <td>{quickPrice.qty6}</td>
                            </tr>
                            <tr>
                                <th>Price (24)</th>
                                <td>{quickPrice.qty24}</td>
                            </tr>
                            <tr>
                                <th>Price (50)</th>
                                <td>{quickPrice.qty50}</td>
                            </tr>
                            <tr>
                                <th>Price (100)</th>
                                <td>{quickPrice.qty100}</td>
                            </tr>
                            <tr>
                                <th>Price (250)</th>
                                <td>{quickPrice.qty250}</td>
                            </tr>
                            <tr>
                                <th>Price (500)</th>
                                <td>{quickPrice.qty500}</td>
                            </tr>
                            <tr>
                                <th>Price (1000)</th>
                                <td>{quickPrice.qty1000}</td>
                            </tr>
                            <tr>
                                <th>Price (2500)</th>
                                <td>{quickPrice.qty2500}</td>
                            </tr>
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    };
    handleGenerateECommercePrice = async ()=>{
        const Sku = this.state.selectedSKUNumber;
        const Currency = this.state.selectedCurrency;
        if(Sku === ""){
            alert("Please select a SKU!");
        }else{
            const data = {
                Sku,
                Currency,
            };
            const URL = baseURL + 'api/ExportPrice/ecommerce-price';
            const response = await axios.post(URL,data,{headers});
            this.setState({
                eCommercePrice: response.data
            })

        }
    };
    renderECommercePricing = ()=>{
        const skuNumberList = this.state.skuNumberList;
        const eCommercePrice = this.state.eCommercePrice;
        return <div>
            <h1 style={{margin:'10px', padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>E-Commerce Pricing</h1>
            <div className={'row'}>
                <div className={'col-6'}>
                    <table className="table table-bordered QuickPriceTable" style={{fontSize:'30px'}}>
                        <tbody>
                        <tr>
                            <th>
                                SKU
                            </th>
                            <td>
                                <select name={'selectedSKUNumber'}  onChange={this.handleOnchange} className={'quick-pricing-sku'} >
                                    <option value="" >-- select a SKU --</option>
                                    {skuNumberList.map((op,i)=>{
                                        return <option value={op} key={i}>{op}</option>
                                    })}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Currency
                            </th>
                            <td>
                                <select name={'selectedCurrency'}  onChange={this.handleOnchange} className={'quick-pricing-sku'} >
                                    <option value="CAD" >CAD</option>
                                    <option value="USD" >USD</option>
                                    <option value="AUD" >AUD</option>
                                    <option value="EUR" >EUR</option>
                                    <option value="GBP" >GBP</option>
                                    <option value="INR" >INR</option>
                                </select>
                            </td>
                        </tr>
                        {eCommercePrice !== undefined &&
                        <tr>
                            <th>Shippable Height (cm)</th>
                            <td>{eCommercePrice.ShippableHeightCm}</td>
                        </tr>
                        }
                        {eCommercePrice !== undefined &&
                        <tr>
                            <th>Shippable Width (cm)</th>
                            <td>{eCommercePrice.ShippableWidthCm}</td>
                        </tr>
                        }
                        {eCommercePrice !== undefined &&
                        <tr>
                            <th>Shippable Depth (cm)</th>
                            <td>{eCommercePrice.ShippableDepthCm}</td>
                        </tr>
                        }
                        {eCommercePrice !== undefined &&
                        <tr>
                            <th>Shippable Weight (grams)</th>
                            <td>{eCommercePrice.ShippableWeightGrams}</td>
                        </tr>
                        }
                        <tr>
                            <td colSpan={2}>
                                <button type={'button'} className={'btn btn-success btn-block'} onClick={this.handleGenerateECommercePrice}>Generate Price</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className={'col-6'}>
                    {eCommercePrice !== undefined &&
                    <table className="table table-bordered QuickPriceInfoTable" style={{fontSize:'20px'}}>
                        <thead>
                        <tr>
                            <th>Channel</th>
                            <th>MSRP</th>
                            <th>NetPrice</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            eCommercePrice.Prices.map((channel,index)=>{
                                return  <tr key={index}>
                                    <th>{channel.ChannelName}</th>
                                    <td>{channel.MSRP}</td>
                                    <td>{channel.NetPrice}</td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                    }
                </div>
            </div>
        </div>
    };
    renderPriceGenerator = ()=>{
        return <div>
            <br/>
            <h1 style={{margin:'10px', padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Please Choose Type</h1>
            <br/><br/>
            <div className={'row'}>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.setState({priceTemplate:'QUICK_PRICE_GENERATOR'})} >
                        <h3>Quick Price Generator</h3>
                    </div>
                </div>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.setState({priceTemplate:'QUICK_E_COMMERCE_PRICING'})} >
                        <h3>Quick e-Commerce Pricing</h3>
                    </div>
                </div>
            </div>
        </div>
    };
    handleFileChosen = (file)=>{
        this._fileReader = new FileReader();
        this._fileReader.onloadend = this.handleFileRead;
        this._fileReader.readAsText(file);
    };
    handleFileRead = (e)=>{
        const content = this._fileReader.result;
        this.setState({
            toUploadText: content
        })
    };
    handleSubmit = async (pattern)=>{
        const URL = baseURL + 'api/ExportPrice/' + pattern;
        const toUploadText = this.state.toUploadText;
        const uploadText = toUploadText.split("\n");

        const response = await axios.get(URL,{headers});
        const ashlinData = response.data;

        const uploadData = uploadText.map(line=>{
           const row =  line.split(',');
           const sku = ashlinData.find(s=>s.Sku == row[0]);
           if(sku){
               return [sku.Sku,sku.Qty,sku.BasePrice,sku.PricingTier,sku.MiniOrder,"FIND"].join();
           }else{
               return line;
           }
        });
        const data = {
            Data: uploadData
        };
        //console.log(data);
        try {
            const response = await axios({
                url: URL,
                method: 'POST',
                headers:{headers},
                data:data,
                responseType: 'blob', // important
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export_price.xlsx`);
            document.body.appendChild(link);
            link.click();
        }  catch (e) {
            return '';
        }

    };
    renderPriceExportWithTemplate =()=>{
        const exportTemplate = this.state.exportTemplate;
        return <div>
            <br/>
            <h1 style={{margin:'10px', padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Export {exportTemplate} Price</h1>
            <br/><br/>
            <input type={'file'} id={'file'} className={'input-file'} accept={'.csv'} onChange={e=>this.handleFileChosen(e.target.files[0])}/>
            <button type={'button'} className={'btn btn-success'} onClick={()=>this.handleSubmit('amazon-business')}>Submit</button>
        </div>
    };
    renderPriceExport=()=>{
        return <div>
            <br/>
            <h1 style={{margin:'10px', padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Please Choose Template to Export</h1>
            <br/><br/>
            <div className={'row'}>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.setState({exportTemplate:'AMAZON_BUSINESS'})} >
                        <h3>Amazon Business Price</h3>
                    </div>
                </div>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>{}} >
                        <h3>????????????</h3>
                    </div>
                </div>
            </div>
        </div>
    };
    renderNew =()=>{
        return <div>
            <br/>
            <h1 style={{margin:'10px', padding:'50px 5px'}} className={'display-4 font-weight-bold text-info'}>Welcome to Price Management</h1>
            <br/><br/>
            <div className={'row'}>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.setState({currentStatus:'PRICE_GENERATOR'})} >
                        <h3>PRICE GENERATOR</h3>
                    </div>
                </div>
                <div className={'col-6'}>
                    <div className={'bg-info'} style={{margin:'10px', padding:'50px 5px'}} onClick={()=>this.setState({currentStatus:'PRICE_EXPORT'})} >
                        <h3>PRICE EXPORT</h3>
                    </div>
                </div>
            </div>
        </div>
    };
    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col-9'} style={{textAlign:'center'}}>
                    <br/>
                    <div className={'row'}>
                        <h1 className={'text-muted display-2 font-weight-bold col-9'}>Price Manager</h1>
                        <button type={'button'} className={'btn btn-primary col-3'} onClick={()=>this.setState({
                            currentStatus: 'NEW',
                            //price
                            priceTemplate: '',
                            rushOrder: false,
                            imprinted: false,
                            netPricing:false,
                            selectedSKUNumber:"",
                            selectedCurrency:"CAD",
                            quickPrice: undefined,

                            //export
                            exportTemplate:'',
                            toUploadText:'',
                        })}>Reset</button>
                    </div>
                    <hr/>
                    {this.handleRenderView()}
                </div>
            </div>
        )}
}
export default PriceManager;