import React from 'react';
import TextInputParallel from '../../bootstrapComponent/textInputParallel';
import SelectInput from '../../bootstrapComponent/selectInput';
const AddColorModal = ({id,colors,addColor,onChange, addItem, Activate, DeActivate,translate,ashlinColors})=>{
    const ashlinColorCode = ashlinColors.map(i=>i.colorCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="addColorModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="addColorModalLabel" style={{textAlign:'center !important'}}>Add A Color</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TextInputParallel id='add_color_code' label={'Color Code'}
                                           value={addColor.colorCode} name='colorCode' onChange={(e)=>onChange(e,'addColor')}/>
                        <SelectInput  id={'add_asi_colors'} label={'Associated ASI Colors'} name={'pickedColor'} width={'450px'}
                             style={{fontWeight:'bold'}}     value={colors}    onChange={(e)=>onChange(e,'addColor')}/>
                        <br/>
                        <div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                                </div>
                                <div className={'col-2'}>
                                    <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>translate('addColor')}>TRANSLATE</button>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addColor.shortENG}
                                              onChange={(e)=>onChange(e,'addColor')} name={'shortENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addColor.shortFR}
                                              onChange={(e)=>onChange(e,'addColor')} name={'shortFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addColor.longENG} rows="5"
                                              onChange={(e)=>onChange(e,'addColor')} name={'longENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addColor.longFR} rows="5"
                                              onChange={(e)=>onChange(e,'addColor')} name={'longFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-2'}>
                                    <h5 style={{textAlign:'center'}}>Web Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addColor.webENG} rows="5"
                                              onChange={(e)=>onChange(e,'addColor')} name={'webENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-2'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addColor.webFR} rows="5"
                                              onChange={(e)=>onChange(e,'addColor')} name={'webFR'}>
                                    </textarea>
                                </div>
                            </div>

                        </div>
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>addItem(e,'addColor',ashlinColorCode)}>Add Color</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'addColor','colorCode')} >Deactivate Color</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'addColor','colorCode')}>Activate Color</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default AddColorModal