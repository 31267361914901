import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
const  BestBuyDiscountModal = ({item, onChange,addToList,removeFromList,modifiedList,changeStartDate,changeEndDate, handleRestSkuDetail})=>{
    const isAdded = modifiedList.findIndex(i=>i.shop_sku === item.shop_sku);
    return(
        <div className="modal fade" id="BestBuyDiscountModal" tabIndex="-1" role="dialog"
             aria-labelledby="BestBuyDiscountTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="BestBuyDiscountTitle">BESTBUY Product Information Detail</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleRestSkuDetail}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>SKU: </span>{item.shop_sku === undefined ? "" : item.shop_sku}
                            </div>
                        </div>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Name: </span>{item.product_title === undefined ? "" : item.product_title }
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Price: </span><input value={item.price === undefined ? "" : item.price} name='price' onChange={onChange}/>
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Apply Discount: </span>
                                <input
                                name="applyDiscount"
                                type="checkbox"
                                checked={item.applyDiscount!==undefined?item.applyDiscount:false}
                                onChange={onChange} />
                            </div>
                        </div>
                        <hr/>
                        {
                            item.applyDiscount&&
                            <div className={'row'}>
                                <div className={'col'}>
                                    <span className='font-weight-bold '>Discount Price: </span><input value={item.discountPrice!==undefined?item.discountPrice:""} name='discountPrice' onChange={onChange}/>
                                </div>
                                <div className={'col'}>
                                    <span className='font-weight-bold '>Start Date: </span>
                                    <DatePicker
                                        selected={item.discountStartDate}
                                        onChange={changeStartDate}
                                    />
                                </div>
                                <div className={'col'}>
                                    <span className='font-weight-bold '>End Date: </span>
                                    <DatePicker
                                        selected={item.discountEndDate}
                                        onChange={changeEndDate}
                                    />
                                </div>
                            </div>

                        }

                    </div>
                    <div className="modal-footer row">
                        { isAdded !== -1 &&  <button type="button" className="btn btn-secondary col" data-dismiss="modal" onClick={removeFromList}>Remove fromL Modified List</button>}
                        {isAdded === -1 && <button type="button" className="btn btn-danger col" data-dismiss="modal" onClick={addToList}>Add to Modified List</button> }
                    </div>
                </div>
            </div>
        </div>
    )

};
export default BestBuyDiscountModal;
