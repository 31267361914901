import axios from 'axios';
import {baseURL,UPS_TEST_ENVIRONMENT, proxyurl} from "../requestHelper";

//Origin, X-Requested-With, Content-Type, Accept
export const getUPSCredentials = async ()=>{
    const res =  (await axios.get(`${baseURL}api/ups`)).data;
    //console.log(res);
    return res;
};

const headers = {
    "headers": {
        "Content-Type": "application/json",
        "Accept": "application/json"
    }
};

export const getUPSPackageRate = async (data,credentials,isExpress,ShippingMatrices,shippingMethodsFilter)=>{

    const URL = UPS_TEST_ENVIRONMENT ? proxyurl + "https://wwwcie.ups.com/rest/Rate" : proxyurl+"https://onlinetools.ups.com/rest/Rate";

    let weight,length,width,height;
    weight = (data.PackageInfo.Weight * 2.20462).toFixed(0);
    weight = weight == 0 ? "1" : weight;

    length = parseInt(data.PackageInfo.Length) == 0? "1" : parseInt(data.PackageInfo.Length).toString();
    width = parseInt(data.PackageInfo.Width) == 0? "1" : parseInt(data.PackageInfo.Width).toString();
    height = parseInt(data.PackageInfo.Height) == 0? "1" : parseInt(data.PackageInfo.Height).toString();

    const requestData = {
        "UPSSecurity":{
            "UsernameToken":{
                "Username":credentials.Username,
                "Password":credentials.Password
            },
            "ServiceAccessToken":{
                "AccessLicenseNumber":credentials.AccessLicenseNumber
            }
        },
        "RateRequest":{
            "Request":{
                "RequestOption":"Shop",
                "TransactionReference":{
                    "CustomerContext":"Your Customer Context"
                }
            },
            "Shipment":{
                "Shipper":{   //Ashlin or 3rd party
                    "Name":"Ashlin-BPG Marketing",
                    "ShipperNumber":credentials.ShipperNumber,
                    "Address":{
                        "AddressLine":[
                            "2351 Royal Windsor Dr,",
                            "Unit# 10 ",
                            ""
                        ],
                        "City":"Mississauga",
                        "StateProvinceCode":"ON", //2
                        "PostalCode":"L5J4S7", //1...9
                        "CountryCode":"CA" //2
                    }
                },
                "ShipTo":{
                    "Name": data.ShipTo.Name,
                    "Address":{
                        "AddressLine":[
                            data.ShipTo.AddressLine1,
                            data.ShipTo.AddressLine2,
                            data.ShipTo.AddressLine3
                        ],
                        "City":data.ShipTo.City,
                        "StateProvinceCode": data.ShipTo.State,
                        "PostalCode": data.ShipTo.Zip,
                        "CountryCode": data.ShipTo.CountryCode
                    }
                },
                "ShipFrom":{
                    "Name": data.ShipFrom.Name,
                    "Address":{
                        "AddressLine":[
                            data.ShipFrom.AddressLine1,
                            data.ShipFrom.AddressLine2,
                            data.ShipFrom.AddressLine3
                        ],
                        "City":data.ShipFrom.City,
                        "StateProvinceCode": data.ShipFrom.State,
                        "PostalCode": data.ShipFrom.Zip,
                        "CountryCode": data.ShipFrom.CountryCode
                    }
                },
                "Service":{
                    "Code":"03",
                    "Description":"Service Code Description"
                },
                "Package":{
                    "PackagingType":{
                        "Code":"02",
                        "Description":"Rate"
                    },
                    "Dimensions":{
                        "UnitOfMeasurement":{
                            "Code":"CM",
                            "Description":"Centimeters"
                        },
                        "Length":  length,
                        "Width": width,
                        "Height": height
                    },
                    "PackageWeight":{
                        "UnitOfMeasurement":{
                            "Code":"Lbs",
                            "Description":"pounds"
                        },
                        "Weight": weight
                    }
                },
                "ShipmentRatingOptions":{
                    "NegotiatedRatesIndicator":""
                }
            }
        }
    };
    //console.log(JSON.stringify(requestData))
    try {
        const res = await axios.post(URL,requestData,headers);
        const resRates = res.data.RateResponse.RatedShipment;

        const result =  resRates.map(rate=>{
            //console.log(rate)
            if(rate.GuaranteedDelivery !==undefined){
                let d = new Date();
                d.setDate(d.getDate() + parseInt(rate.GuaranteedDelivery.BusinessDaysInTransit));
                const dayOfWeek = d.getDay();
                if(dayOfWeek===0){
                    d.setDate(d.getDate() + 1);
                }
                if(dayOfWeek===6){
                    d.setDate(d.getDate() + 2);
                }
                rate.Delivery = d.toISOString().slice(0,10);

            }

            const service = ShippingMatrices.find(s=>s.ServiceCode === rate.Service.Code);
            rate.Service.Description = service.DisplayedName;
            rate.isExpress = service.IsExpress;
            if(service)
                return rate;
            return service;
        });

        if(shippingMethodsFilter.length > 0){
            const conditions = shippingMethodsFilter.filter(s=>s.Carrier==="UPS").map(s=>s.ServiceCode);
            return  result.filter(r=> r && conditions.some(el=> r.Service.Code.includes(el)))
        }
        return result.filter(r=> r && r.isExpress === isExpress);
    }catch (e) {
        return [];
    }
};

export const createUPSShipment = async(data,credentials)=>{
    // console.log(data);
    const URL = UPS_TEST_ENVIRONMENT ? proxyurl + "https://wwwcie.ups.com/rest/Ship" : proxyurl +"https://onlinetools.ups.com/rest/Ship";

    let paymentInformation,weight,length,width,height,packageServiceOptions;


    if(data.Use3rdPartyAccount){
        paymentInformation = {
            "ShipmentCharge":{
                "Type":"01",
                "BillThirdParty":{
                    "AccountNumber": data.ThirdPartyAccountNum,
                    "Address":{
                        "PostalCode": data.ThirdPartyAccountAddress.Zip.toUpperCase().replace(' ',''),
                        "CountryCode": data.ThirdPartyAccountAddress.CountryCode
                    }
                }
            },
            //"SplitDutyVATIndicator":""
        }
    }else{
        paymentInformation = {
            "ShipmentCharge":{
                "Type":"01",
                    "BillShipper":{
                    "AccountNumber": credentials.ShipperNumber,
                }
            }
        }
    }
    weight = (data.PackageInfo.Weight * 2.20462).toFixed(0);
    weight = weight == 0 ? "1" : weight;


    length = (data.PackageInfo.Length * 0.393701).toFixed(0);
    length = length == 0 ? "1" : length;

    width = (data.PackageInfo.Width * 0.393701).toFixed(0);
    width = width == 0 ? "1" : width;

    height = (data.PackageInfo.Height * 0.393701).toFixed(0);
    height = height == 0 ? "1" : height;

    const numberOfBox = parseInt(data.NumberOfBox);
    let packages = [];

    for (let i = 1; i <= numberOfBox; i++) {
        const js =  {
            "Packaging":{
                "Code":"02",
                "Description":""
            },
            "Dimensions":{
                "UnitOfMeasurement":{
                    "Code":"IN",
                    "Description":"Inches"
                },
                "Length":  length,
                "Width": width,
                "Height": height
            },
            "PackageWeight":{
                "UnitOfMeasurement":{
                    "Code":"LBS",
                    "Description":"pounds"
                },
                "Weight": (weight / numberOfBox).toFixed(0)
            }
        };
        packages.push(js);
    }


    const requestData = {
        "UPSSecurity":{
            "UsernameToken":{
                "Username":credentials.Username,
                "Password":credentials.Password
            },
            "ServiceAccessToken":{
                "AccessLicenseNumber":credentials.AccessLicenseNumber
            }
        },
        "ShipmentRequest":{
            "Request":{
                "RequestOption":"validate",
                "TransactionReference":{
                    "CustomerContext":""
                }
            },
            "Shipment":{
                "NumOfPiecesInShipment": data.NumberOfBox,
                "ReferenceNumber":[
                    {"Value":data.Reference1!==''?data.Reference1 : data.Order.id.toString()},
                    {"Value":data.Reference2!==''?data.Reference2 : `Number of Box: ${data.NumberOfBox}`}
                ],
                "Description": "no description",
                "Shipper": {
                    "Name":"Ashlin-BPG Marketing",
                    "AttentionName": "Nancy Taccone",
                    "ShipperNumber":credentials.ShipperNumber,
                    "Phone":{
                        "Number":"9058553027"
                    },
                    "Address":{
                        "AddressLine":[
                            "2351 Royal Windsor Dr,",
                            "Unit# 10 ",
                            ""
                        ],
                        "City":"Mississauga",
                        "StateProvinceCode":"ON",
                        "PostalCode":"L5J4S7",
                        "CountryCode":"CA"
                    }
                },
                "ShipTo":{
                    "Name": data.ShipTo.Name===""?data.ShipTo.ContactName:data.ShipTo.Name,
                    "AttentionName":data.ShipTo.ContactName,
                    "Phone":{
                        "Number":data.ShipTo.ContactPhone
                    },
                    "Address":{
                        "AddressLine":[
                            data.ShipTo.AddressLine1,
                            data.ShipTo.AddressLine2,
                            data.ShipTo.AddressLine3
                        ],
                        "City":data.ShipTo.City,
                        "StateProvinceCode": data.ShipTo.State,
                        "PostalCode": data.ShipTo.Zip,
                        "CountryCode": data.ShipTo.CountryCode
                    }
                },
                "ShipFrom":{
                    "Name": data.ShipFrom.Name,
                    "AttentionName":data.ShipFrom.ContactName,
                    "Phone":{
                        "Number":data.ShipFrom.ContactPhone
                    },
                    "Address":{
                        "AddressLine":[
                            data.ShipFrom.AddressLine1,
                            data.ShipFrom.AddressLine2,
                            data.ShipFrom.AddressLine3
                        ],
                        "City":data.ShipFrom.City,
                        "StateProvinceCode": data.ShipFrom.State,
                        "PostalCode": data.ShipFrom.Zip,
                        "CountryCode": data.ShipFrom.CountryCode
                    }
                },
                "PaymentInformation":paymentInformation,
                "Service":{
                    "Code": data.Service,
                    "Description":""
                },
                // "ShipmentRatingOptions": {
                //     "NegotiatedRatesIndicator": "0"
                // },

                "Package": packages
            },
            "LabelSpecification":{
                "LabelImageFormat":{
                    "Code":"GIF",
                    "Description":"GIF"
                },
                "HTTPUserAgent":"Chrome/72.0.3626.121"
            }
        }
    };

    if(data.DeclareValueInsurance) {
        let value = data.PackageInfo.TotalValue;
        value = value > 500? 500 : value;
        requestData.ShipmentRequest.Shipment.Package.PackageServiceOptions = {
            "DeclaredValue": {
                "Type": {
                    "Code" : "01",
                    "Description":""
                },
                "CurrencyCode": data.PackageInfo.ItemDetails[0].Currency,
                "MonetaryValue": value.toString()
            }};
    }

    //console.log(JSON.stringify(requestData));
    const res = await axios.post(URL,requestData,headers);
    //console.log(res);
    if(res.data.Fault){
        return {
            "shipmentId": undefined,
            "error" : res.data.Fault.detail.Errors.ErrorDetail.PrimaryErrorCode.Description
        }
    }else{

        if(data.NumberOfBox === '1'){
            return {
                "shipmentId": res.data.ShipmentResponse.ShipmentResults.ShipmentIdentificationNumber,
                "tracking": res.data.ShipmentResponse.ShipmentResults.ShipmentIdentificationNumber,
                "labels":[res.data.ShipmentResponse.ShipmentResults.PackageResults.ShippingLabel.GraphicImage],
                "service": UPSServiceCodesMapper(data.ShipTo.CountryCode,data.Service)
            };
        }else{
            const labels = res.data.ShipmentResponse.ShipmentResults.PackageResults.map(re=>re.ShippingLabel.GraphicImage);
            const trackingNumbers = res.data.ShipmentResponse.ShipmentResults.PackageResults.map(re=>re.TrackingNumber);
            return {
                "shipmentId": res.data.ShipmentResponse.ShipmentResults.ShipmentIdentificationNumber,
                "service": UPSServiceCodesMapper(data.ShipTo.CountryCode,data.Service),
                "tracking": trackingNumbers.join(" | "),
                "labels":labels
            };
        }
    }
};

export const voidUPSShipment = async(shipmentId,credentials)=>{
    const URL = UPS_TEST_ENVIRONMENT ? proxyurl + "https://wwwcie.ups.com/rest/Void" : proxyurl +"https://onlinetools.ups.com/rest/Void";
    const id = UPS_TEST_ENVIRONMENT ? "1ZISDE016691676846":shipmentId;
    const requestData = {
        "UPSSecurity":{
            "UsernameToken":{
                "Username":credentials.Username,
                "Password":credentials.Password
            },
            "ServiceAccessToken":{
                "AccessLicenseNumber":credentials.AccessLicenseNumber
            }
        },
        "VoidShipmentRequest": {
            "Request": {
                "TransactionReference": {
                    "CustomerContext": "Your Customer Context"
                }
            },
            "VoidShipment": {
                "ShipmentIdentificationNumber": id
            }
        }
    };
    try {
        const res = await axios.post(URL,requestData,headers);
        //console.log(res);
        if(res.data.Fault){
            return res.data.Fault.detail.Errors.ErrorDetail.PrimaryErrorCode.Description
        }else{
            return res.data.VoidShipmentResponse.Response.ResponseStatus.Description !== undefined ?res.data.VoidShipmentResponse.Response.ResponseStatus.Description.toUpperCase(): undefined;
        }
    }catch (e) {
        return "Can't void this label";
    }
};

export const recoverUPSLabel = async(data,credentials)=>{
    const URL = UPS_TEST_ENVIRONMENT ? proxyurl + "https://wwwcie.ups.com/rest/LBRecovery" : proxyurl +"https://onlinetools.ups.com/rest/LBRecovery";
};

const UPSServiceCodesMapper = (CountryCode,ServiceCode)=>{
    switch(CountryCode.toUpperCase()){
        case "CA":
            switch (ServiceCode){
                case "02":
                    return {
                        "name":"UPS Expedited",
                        "isExpress":false};
                case "13":
                    return {
                        "name":"UPS Express Saver",
                        "isExpress":true};
                case "12":
                    return {
                        "name":"UPS 3 Day Select",
                        "isExpress":true};
                case "70":
                    return {
                        "name":"UPS Access Point Economy",
                        "isExpress":true};
                case "01":
                    return {
                        "name":"UPS Express",
                        "isExpress":true};
                case "14":
                    return {
                        "name":"UPS Express Early",
                        "isExpress":true};
                case "65":
                    return {
                        "name":"UPS Express Saver",
                        "isExpress":true};
                case "11":
                    return {
                        "name":"UPS Standard",
                        "isExpress":false};
                case "08":
                    return {
                        "name":"UPS Worldwide Expedited",
                        "isExpress":true};
                case "07":
                    return {
                        "name":"UPS Worldwide Express",
                        "isExpress":true};
                case "54":
                    return {
                        "name":"UPS Express Early",
                        "isExpress":true};
                default:
                    return {
                        "name":"Can't find service code in CA",
                        "isExpress":false};
            }
        case "US":
            switch (ServiceCode){
                case "02":
                    return {
                        "name":"UPS 2nd Day Air",
                        "isExpress":true};
                case "59":
                    return {
                        "name":"UPS 2nd Day Air A.M.",
                        "isExpress":true};
                case "12":
                    return {
                        "name":"UPS 3 Day Select",
                        "isExpress":true};
                case "03":
                    return {
                        "name":"UPS Ground",
                        "isExpress":false};
                case "01":
                    return {
                        "name":"UPS Next Day Air",
                        "isExpress":true};
                case "14":
                    return {
                        "name":"UPS Next Day Air Early",
                        "isExpress":true};
                case "13":
                    return {
                        "name":"UPS Next Day Air Saver",
                        "isExpress":true};
                case "75":
                    return {
                        "name":"UPS Heavy Goods",
                        "isExpress":false};
                default:
                    return {
                        "name":"Can't find service code in US",
                        "isExpress":false};
            }
        default:
            return {
            "name":"Can't find country",
            "isExpress":false};
    }
};