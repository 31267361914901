import React from 'react';
import ListItem from './asi_product_list_item';
const asi_product_list = (props)=>{
    //console.log(props);
    return(
        <div className={'list-group'}>
            {props.list.map((item,index)=><ListItem item={item} key={index} selectProduct={props.selectProduct} setDefaultImage={props.setDefaultImage}
                                                    toUpdateList={props.toUpdateList} editingOnChange={props.editingOnChange}
                                                    finishEditing = {props.finishEditing}   isCalculating={props.isCalculating}
                                                    AsiProductTypes={props.AsiProductTypes} AsiCategories={props.AsiCategories}
                                                    calculateNewPrices={props.calculateNewPrices} availableImagesOnClick={props.availableImagesOnClick}
            />)}
        </div>
    )
};
export default asi_product_list;