import React from 'react';
const confirmManualOrderShipment = (props)=>{
    const order = props.order;
    const selectedRow = props.selectedRow;
    const BrightPearlWarehousesName = props.brightPearlWarehouses.map(w=>w.name);
    let orderRows;
    if(props.orderItems.length > 0){
        orderRows = props.orderItems;
    }else{
        orderRows = order.orderRows
    }
    return(<div className="modal fade" id="ConfirmManualShipmentModal" tabIndex="-1" role="dialog" data-backdrop="static"
                aria-labelledby="ConfirmManualShipmentModalTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title text-center" id="ConfirmManualShipmentModalTitle"> Order ID: {selectedRow.BrightpearlOrderId} - Status: <span style={{color:'green'}}>{selectedRow.Status}</span></h1>
                    <button type="button" className="close" onClick={props.handleClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body row">
                    <div className={'col'}>
                        <h2>Order Information</h2>
                        <table className="table table-bordered ConfirmManualShipmentModalTable">
                            <thead>
                            <tr>
                                <th scope="col"> </th>
                                <th scope="col">Delivery address</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th scope="row">Name</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressFullName !==undefined)?order.parties.delivery.addressFullName:""}</td>
                            </tr>
                            <tr>
                                <th scope="row">Company</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.companyName !==undefined)?order.parties.delivery.companyName:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Street</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine1 !==undefined)?order.parties.delivery.addressLine1:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Suburb</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine2 !==undefined)?order.parties.delivery.addressLine2:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">City</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine3 !==undefined)?order.parties.delivery.addressLine3:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">State/county</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.addressLine4 !==undefined)?order.parties.delivery.addressLine4:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Postcode</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.postalCode !==undefined)?order.parties.delivery.postalCode:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Country</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.country !==undefined)?order.parties.delivery.country:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Telephone</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.telephone !==undefined)?order.parties.delivery.telephone:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Mobile</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.mobileTelephone !==undefined)?order.parties.delivery.mobileTelephone:"" }</td>
                            </tr>
                            <tr>
                                <th scope="row">Email address</th>
                                <td>{(order.parties!==undefined&&order.parties.delivery.email !==undefined)?order.parties.delivery.email:"" }</td>
                            </tr>
                            </tbody>
                        </table>
                        {
                            (orderRows!==undefined && props.orderItems.length > 0)&&
                            <table className="table table-bordered">
                                <thead>
                                <tr>
                                    <th scope="col">Line#</th>
                                    <th scope="col">SKU</th>
                                    <th scope="col">Qty</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    orderRows.map((ol,index)=>{
                                        return <tr key={index}>
                                            <th scope="row">{ol.OrderLine}</th>
                                            <td>{ol.ItemCode}</td>
                                            <td>{ol.ItemQty}</td>
                                        </tr>
                                    })
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className={'col'}>
                        <h2>Shipment Information</h2>
                        <table className="table  table-bordered" style={{margin:'16px 0 0'}}>
                            <tbody>
                            {(order.parties!==undefined&&order.parties.delivery.countryIsoCode !=="CA") &&
                            <tr>
                                <th>FEIN #</th>
                                <td>
                                    <input name={'FEIN'} type={'text'} value={props.FEIN} style={{width:'100px',textAlign:'center'}} onChange={props.simpleOnchange}/>
                                </td>
                            </tr>
                            }
                            <tr>
                                <th>Ship Warehouse</th>
                                <td>
                                    <select name={'Warehouse'}  style={{width:'100%',height:'30px'}} onChange={props.shipmentOnChange} value={selectedRow.Warehouse}>
                                        {BrightPearlWarehousesName.map((op,i)=>{
                                            return <option value={op} key={i}>{op}</option>
                                        })}
                                    </select>
                                </td>
                            </tr>
                            {
                                selectedRow.Warehouse === "Main warehouse" &&
                                <tr>
                                    <th colSpan={'2'}><button type={'button'} className={'btn btn-danger btn-block'} onClick={props.shipLocal}>Ship Locally and Confirm Shipment</button></th>
                                </tr>
                            }
                            {
                                selectedRow.Warehouse === "LS14 LeanSupply 3PL" &&
                                <tr>
                                    <th>Comment <br/><br/>
                                        <p>If is KAL/CROSS DOCK ORDER, add additional comment with Box number and Qty</p>
                                    </th>
                                    <td><textarea rows={'5'}  name={'Comment'} onChange={props.simpleOnchange} style={{width:'100%'}} value={props.Comment}/></td>
                                </tr>
                            }
                            {
                                selectedRow.Warehouse === "LS14 LeanSupply 3PL" &&
                                <tr>
                                    <th>IS KAL/CROSS DOCK ORDER? <input type={'checkbox'} style={{width:'40px',height:'40px', verticalAlign: 'middle'}} checked={props.crossDockOrder} name={'crossDockOrder'} onChange={props.simpleOnchange}/><br/>
                                        {
                                            props.crossDockOrder &&
                                              <span style={{color:'red'}}>TOTAL BOX QTY:
                                                  <input type={'text'} name={'totalBoxNumber'} style={{color:'red',fontWeight:'bold',textAlign:'center'}} onChange={props.simpleOnchange} value={props.totalBoxNumber}/>
                                              </span>
                                        }
                                    </th>
                                    <th><button type={'button'} className={'btn btn-success btn-block'} onClick={props.checkFulFill}>Check 3PL Fulfillment</button></th>
                                    <th><h3 style={{color:"red"}}>Is Order Urgent?</h3><input type={'checkbox'} style={{width:'40px',height:'40px', verticalAlign: 'middle'}} checked={props.isUrgentShipment} name={'isUrgentShipment'} onChange={props.simpleOnchange}/></th>
                                </tr>
                            }
                            {
                                (selectedRow.Warehouse === "LS14 LeanSupply 3PL" && ( props.canFulfill ||  props.crossDockOrder) &&order.parties.delivery.countryIsoCode!=="CA") &&
                                <tr>
                                    <th colSpan={'2'}><button type={'button'} className={'btn btn-success btn-block'} onClick={props.generateProforma}>Generate Invoice</button></th>
                                </tr>
                            }
                            {
                                (selectedRow.Warehouse === "LS14 LeanSupply 3PL" && ( props.canFulfill ||  props.crossDockOrder)) &&
                                <tr>
                                    <th colSpan={'2'}><button type={'button'} className={'btn btn-danger btn-block'} onClick={props.sendTo3PL}>Send Order to 3PL</button></th>
                                </tr>
                            }
                            </tbody>
                        </table>
                        <br/>

                    </div>
                    {
                        (orderRows!==undefined && props.orderItems.length === 0)&&
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th scope="col">Line#</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Description</th>
                                <th scope="col">Qty</th>
                                <th scope="col">HTS</th>
                                <th scope="col">Duty</th>
                                <th scope="col">Line Total</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                orderRows.map((ol,index)=>{
                                    return <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td><input type={'text'} name={'sku'} onChange={e=>props.replaceSKU(e,index)} value={ol.productSku}/></td>
                                        <td>{ol.productName}</td>
                                        <td>{parseInt(ol.quantity.magnitude)}</td>
                                        <td><select name={'htsCode'}  defaultValue={order.parties.delivery.countryIsoCode==="US"?ol.HtsUs:ol.HtsCa}
                                                    onChange={e=>props.htsCodeOnchange(e,index)}
                                        >
                                            {
                                                order.parties.delivery.countryIsoCode==="US"? props.HtsUs.map((h,i)=>{
                                                    return <option value={h.HtsUs1} key={i}> {h.HtsUs1}</option>
                                                }):props.HtsCa.map((h,i)=>{
                                                    return <option value={h.HtsCa1} key={i}> {h.HtsCa1}</option>
                                                })
                                            }
                                        </select></td>
                                        <td>{order.parties.delivery.countryIsoCode==="US"?ol.UsDuty:ol.CaDuty}</td>
                                        <td>{parseFloat(ol.rowValue.rowNet.value).toFixed(2)}</td>
                                        <td><button onClick={e=>props.deleteRow(e,index)} className={'btn-danger'}>DELETE</button></td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                    }
                </div>
            </div>
        </div>
    </div>)
};
export default confirmManualOrderShipment