import React from 'react';

const inventoryTable = ({skuList})=>{
    const tableBody = skuList.map((item,index)=>{
       const sku =  item.Sku;
       const inventory = item.Inventory ;
       //console.log(item);
       return <tr key={index}>
                <td>{sku}</td>
                <td>{inventory}</td>
             </tr>
    });
    return(
        <div style={{maxHeight:'640px',minWidth:'360px',overflowY:'auto'}}>
            <table className={'table table-bordered'} style={{textAlign:'center'}}>
                <thead className="thead-dark">
                    <tr>
                        <th>SKU on Channel</th>
                        <th>Inventory on Brightpearl</th>
                    </tr>
                </thead>
                <tbody>
                {tableBody}
                </tbody>
            </table>
        </div>
    )
};

export default inventoryTable;