import React from 'react';
import ListItem from './signature_list_item';
const signature_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
            <tr>
                <th>NAME</th>
                <th>TITLE</th>
                <th>CURRENT SIGNATURE</th>
                <th>NEW SIGNATURE</th>
                <th>ACTION</th>
            </tr>
            </thead>
            <tbody>
            {props.list.map((item,index)=><ListItem item={item} key={index} index={index} updateSignature={props.updateSignature} newSignatureFile={props.newSignatureFile}
                                                    removeSelectImage={props.removeSelectImage}   signatureOnChange ={props.signatureOnChange} imageOnDrop={props.imageOnDrop}/>)}
            </tbody>
        </table>
    )
};
export default signature_list ;