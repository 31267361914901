import React from "react";
import TextInputParallel from "../bootstrapComponent/textInputParallel";

const TierCostModal = ({
  tierCostMatrix,
  skuCost,
  onChange,
  upDateCostMatrix,
  create,
  pricingTierOnchange,
}) => {
  //console.log(tierCostMatrix);
  //console.log(skuCost);

  return (
    <div
      className="modal fade"
      id="tierCostModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="tierCostModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-center" id="tierCostModalTitle">
              Please Confirm Tier Cost Price
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <TextInputParallel
              label={"SKU"}
              value={skuCost.Sku}
              onChange={() => {}}
              readonly={true}
            />

            <TextInputParallel
              label={"BP Product Id"}
              value={skuCost.ProductId}
              name={"ProductId"}
              onChange={(e) => onChange(e)}
              readonly={skuCost.ReadOnlyProductId}
            />

            <TextInputParallel
              label={"Min Order QTY"}
              value={skuCost.MinimumOrderQuantity}
              name={"MinimumOrderQuantity"}
              onChange={(e) => onChange(e)}
            />

            <TextInputParallel
              label={"Tier Selected"}
              value={skuCost.PricingTier}
              name={"PricingTier"}
              onChange={(e) => pricingTierOnchange(e)}
            />
            <TextInputParallel
              label={"Min Order QTY"}
              value={skuCost.MinimumOrderQuantity}
              name={"MinimumOrderQuantity"}
              onChange={(e) => onChange(e)}
            />
            <TextInputParallel
              label={"Supplier Price"}
              value={skuCost.KaplUnitCostCadS}
              name={"KaplUnitCostCadS"}
              onChange={(e) => onChange(e)}
            />
            <TextInputParallel
              label={"Qty1Price"}
              value={skuCost.Qty1Price}
              name={"Qty1Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty6Price"}
              value={skuCost.Qty6Price}
              name={"Qty6Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty24Price"}
              value={skuCost.Qty24Price}
              name={"Qty24Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty50Price"}
              value={skuCost.Qty50Price}
              name={"Qty50Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty100Price"}
              value={skuCost.Qty100Price}
              name={"Qty100Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty250Price"}
              value={skuCost.Qty250Price}
              name={"Qty250Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty500Price"}
              value={skuCost.Qty500Price}
              name={"Qty500Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty1000Price"}
              value={skuCost.Qty1000Price}
              name={"Qty1000Price"}
              onChange={() => {}}
              readonly={true}
            />
            <TextInputParallel
              label={"Qty2500Price"}
              value={skuCost.Qty2500Price}
              name={"Qty2500Price"}
              onChange={() => {}}
              readonly={true}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Go Back
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={(e) => upDateCostMatrix(e, create)}
            >
              Update Database
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TierCostModal;
