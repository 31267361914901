import React from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import { Link } from 'react-router-dom';
const BigCommerce = ()=>{
    return(
        <div className='container-fluid row'>
            <div className={'col-3'}>
                <MainSideBar/>
            </div>
            <div className={'col-9'} style={{textAlign:'center'}}>
                <br/>
                <h1 >Big Commerce</h1>
                <hr/>
                <br/>
                <br/>
                <div>
                    <div className={'row justify-content-around'}>
                        <Link className={'btn btn-primary col-4'} to='./bigCommerce/manageImage'>Manage Image</Link>
                        <Link className={'btn btn-primary col-4 '} to={'./bigCommerce/bulkUpdateDescription'}>Bulk Update Details</Link>
                    </div>
                    <br/>
                    <div className={'row justify-content-around'}>
                        <Link className={'btn btn-primary col-4'} to='./bigCommerce/updateSwatch'>Bulk Update Swatch</Link>
                    </div>
                    {/*<br/>*/}
                    {/*<div className={'row justify-content-around'}>*/}
                        {/*<Link className={'btn btn-primary col-4 '} to={'./bigCommerce/description'}>Description</Link>*/}
                        {/*<Link className={'btn btn-primary col-4 '} to={'./bigCommerce/product'}>Products</Link>*/}
                    {/*</div>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<div className={'row justify-content-around'}>*/}
                        {/*<Link className={'btn btn-primary col-4 '} to={'./bigCommerce/optionSet'}>Option Sets</Link>*/}
                        {/*/!*<button className={'btn btn-primary col-4 '}>Detail Price</button>*!/*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
};
export default BigCommerce;