import { LoadingOutlined } from "@ant-design/icons";
import { message, Spin } from "antd";
import axios from "axios";
import $ from "jquery";
import React, { Component } from "react";
import { connect } from "react-redux";
import ReactTable from "react-table";
import "react-table/react-table.css";
import * as actions from "../../actions";
import {
  updateDeliveryDate,
  upDateStatusAndNote,
} from "../../Utils/brightpearl";
import { LeanSupplyModelMapper } from "../../Utils/orderModels";
import {
  baseURL,
  cancelOrderModel,
  cancelOrderURL,
  ENABLE_BRIGHTPEARL,
  getOrderStatusURL,
  headers,
  PRODUCTION,
  proxyurl,
  SubmitOrderModel,
  submitOrderURL,
  submittedOrdersURL,
} from "../../Utils/requestHelper";
import OrderDispatchResultsModal from "../Modal/OrderDispatchResultsModal";
import OrderStatusDetailModal from "../Modal/OrderStatusDetailModal";
import MainSideBar from "../sideBar/MainSideBar";

class SubmittedOrder extends Component {
  constructor() {
    super();
    this.state = {
      submittedOrders: [],
      allSubmittedOrders: [],
      ordersToSave: [],
      orderDispatchResults: [],
      selectedOrder: null,
      showLoadingIcon: false,
      showOrderDetailsModal: false,
      showSaveOrderStatusButton: false,
      showUpdateOrderStatusButton: true,
      showOrderDispatchResultsModal: false,
      showOnlyNewOrders: false,
    };
  }

  //Download submitted orders from Ashlin database
  async componentDidMount() {
    const submitted = this.props.fetchSubmittedOrder();
    Promise.all([submitted]).then(() => {
      this.setState({
        submittedOrders: this.props.submittedOrders
          ? this.props.submittedOrders
          : [],
        allSubmittedOrders: this.props.submittedOrders
          ? this.props.submittedOrders
          : [],
      });
    });
  }

  // 8/24/18 the following has been changed to be implemented using functional programming

  // for the "Update Order Statuses" button

  getOrderStatusQueryModel = (order) => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    const orderHandle =
      order.ReissueNumber > 0
        ? order.ChannelOrderId + "-" + order.ReissueNumber
        : order.ChannelOrderId;
    const orderStatusQueryModel = {
      Authorization: auth,
      OrderHeader: {
        OrderHandle: orderHandle,
      },
    };
    return orderStatusQueryModel;
  };

  getOrderStatusQueryResponse = async (orderStatusQueryModel) => {
    const url = proxyurl + getOrderStatusURL;
    const response = await axios.post(url, orderStatusQueryModel, headers);
    return response;
  };

  parseOrderStatusQueryResponse = (orderStatusQueryResponse) => {
    //console.log(orderStatusQueryResponse);
    if (orderStatusQueryResponse.data.CallResponse.ReturnValue.length > 0) {
      const data = orderStatusQueryResponse.data.CallResponse.ReturnValue.filter(
        (val) => val.OrderStatus !== "Canceled" && val.TrackingNumber !== ""
      )[0];
      return data;
    } else {
      const data = orderStatusQueryResponse.data.CallResponse.ReturnValue;
      return data;
    }
  };

  getOrderStatusUpdate = (orderStatusQueryResponseData) => {
    const update = {
      OrderStatus: orderStatusQueryResponseData.OrderStatus,
      ShipmentStatus: orderStatusQueryResponseData.ShipmentStatus,
      TrackingNumber: orderStatusQueryResponseData.TrackingNo,
      ShippingCarrier: orderStatusQueryResponseData.CarrierName,
    };
    return update;
  };

  getOrderUpdate = async (order) => {
    const orderUpdate = this.getOrderStatusUpdate(
      this.parseOrderStatusQueryResponse(
        await this.getOrderStatusQueryResponse(
          this.getOrderStatusQueryModel(order)
        )
      )
    );
    return orderUpdate;
  };

  shouldUpdateOrder = (order) => {
    const result =
      order.OrderHandle !== undefined &&
      order.OrderHandle !== "" &&
      order.OrderStatus !== undefined &&
      order.OrderStatus !== "" &&
      (order.OrderStatus === "Entered" ||
        (order.OrderStatus === "Completed" &&
          (order.TrackingNumber === undefined || order.TrackingNumber === "")));
    return result;
  };

  getOriginalOrUpdatedOrder = async (order) =>
    this.shouldUpdateOrder(order)
      ? Object.assign(order, await this.getOrderUpdate(order))
      : order;

  getOrderStatusUpdates = async (submittedOrders) =>
    await Promise.all(submittedOrders.map(this.getOriginalOrUpdatedOrder));

  shouldSaveNewOrderStatus = (order) => {
    const result =
      order.OrderStatus !== "Entered" &&
      order.FulfillmentStatus !== "Shipped" &&
      order.FulfillmentStatus !== "Shipped locally" &&
      order.FulfillmentStatus !== "Closed" &&
      order.FulfillmentStatus !== "Cancelled" &&
      order.TrackingNumber !== undefined &&
      order.TrackingNumber !== "";
    return result;
  };

  getOrderStatusUpdatesNextState = (submittedOrders) => {
    const ordersToSave = submittedOrders.filter(this.shouldSaveNewOrderStatus);
    const nextState = {
      submittedOrders: submittedOrders,
      ordersToSave: ordersToSave,
      showLoadingIcon: false,
      showSaveOrderStatusButton: ordersToSave.length > 0,
      showUpdateOrderStatusButton: ordersToSave.length <= 0,
    };
    return nextState;
  };

  updateOrderStatuses = async (prevState) =>
    this.getOrderStatusUpdatesNextState(
      await this.getOrderStatusUpdates(prevState.submittedOrders)
    );

  handleUpdateOrderStatuses = async () => {
    this.setState(() => ({ showLoadingIcon: true }));
    const nextState = await this.updateOrderStatuses(this.state);
    //console.log(nextState);
    this.setState(nextState);
  };

  // for the "Save Order Statuses" button

  orderIsCompleted = (order) =>
    order.OrderStatus === "Completed" &&
    order.TrackingNumber !== undefined &&
    order.TrackingNumber !== "";

  updateCompletedOrdersOnBrightpearl = async (orders) => {
    if (ENABLE_BRIGHTPEARL && PRODUCTION) {
      orders.filter(this.orderIsCompleted).forEach(async (order) => {
        //console.log(order);
        let BPResponse = await updateDeliveryDate(order.ERPOrderId);
        //console.log(BPResponse);
        BPResponse = await upDateStatusAndNote(
          order.ERPOrderId,
          order.TrackingNumber
        );
        //console.log(BPResponse);
      });
    }
  };

  saveOrderStatusesToServer = async (ordersToSave) => {
    // alas, a side effect!
    await this.updateCompletedOrdersOnBrightpearl(ordersToSave);

    // the rest remains as functional code
    const response = await axios.put(submittedOrdersURL, ordersToSave, headers);
    const responseData = response.data;
    //console.log(responseData);
    return responseData;
  };

  getLatestOrderState = (orderDispatchResult) => orderDispatchResult.Order;

  getLatestOrderStates = (orderStatusSavesResponseData) =>
    orderStatusSavesResponseData.map(this.getLatestOrderState);

  /*
    saveOrderStatusesToServer = async (ordersToSave) => {
        const latestOrderStates = this.getLatestOrderStates(await this.saveOrderStatusesToServer(ordersToSave));
        console.log(latestOrderStates);
        return latestOrderStates;
    }
    */

  orderMatches = (orderToMatch) => (currentOrder) =>
    currentOrder.ChannelOrderId === orderToMatch.ChannelOrderId;

  getOriginalOrSavedOrder = (savedOrders) => (order) => {
    const matchingOrders = savedOrders.filter(this.orderMatches(order));
    return matchingOrders.length > 0 ? matchingOrders[0] : order;
  };

  //getOrderStatusSaves = async (prevState) => await prevState.submittedOrders.map(this.getOriginalOrSavedOrder(await this.saveOrderStatusesToServer(prevState.ordersToSave)));

  getOrderStatusSavesNextState = (prevState) => (
    orderStatusSavesResponseData
  ) => {
    const nextState = {
      submittedOrders: prevState.submittedOrders.map(
        this.getOriginalOrSavedOrder(
          this.getLatestOrderStates(orderStatusSavesResponseData)
        )
      ),
      ordersToSave: [],
      orderDispatchResults: orderStatusSavesResponseData,
      showLoadingIcon: false,
      showSaveOrderStatusButton: false,
      showUpdateOrderStatusButton: true,
      showOrderDispatchResultsModal: true,
    };
    return nextState;
  };

  saveOrderStatuses = async (prevState) =>
    this.getOrderStatusSavesNextState(prevState)(
      await this.saveOrderStatusesToServer(prevState.ordersToSave)
    );

  handleSaveOrderStatuses = async () => {
    this.setState(() => ({ showLoadingIcon: true }));
    const nextState = await this.saveOrderStatuses(this.state);
    //console.log(nextState);
    this.setState(nextState);
  };

  handleCloseOrderDispatchResultsModal = () => {
    this.setState({
      orderDispatchResults: [],
      selectedOrder: null,
      showOrderDispatchResultsModal: false,
    });
  };

  // 8/24/18 new, functionally programmed code above

  /*
    oldHandleUpdateOrderStatuses = async () => {
        this.setState({showLoadingIcon: true});

        const url = proxyurl + getOrderStatusURL;

        const data = this.state.submittedOrders.filter(order => {
            const shouldGetStatus =
                (order.OrderHandle !== undefined && order.OrderHandle !== "")
                && (order.OrderStatus !== undefined && order.OrderStatus !== "")
                && (order.OrderStatus === "Entered"
                    || (order.OrderStatus === "Completed" &&
                        (order.TrackingNumber === undefined || order.TrackingNumber === "")));
            return shouldGetStatus;
        });

        const requests = await data.map((d) => {
            return this.submitRequest(d, url);
        });

        const responses = await Promise.all(requests);

        let ordersToSave = this.state.submittedOrders.filter(order =>
            order.OrderStatus !== "Entered"
            && order.FulfillmentStatus !== "Shipped"
            && order.FulfillmentStatus !== "Shipped locally"
            && order.FulfillmentStatus !== "Closed"
            && order.FulfillmentStatus !== "Cancelled");

        this.setState({showLoadingIcon: false});
        $('#saveOrderStatuses').show();

    };

    //check a single order status and update order status for state
    submitRequest = async (obj, url) => {
        //console.log(obj);
        const data = this.getOrderStatusQueryModel(obj);
        //console.log(data);
        const response = await axios.post(url, data, headers);
        //console.log(response);
        this.state.submittedOrders[index] = shdskjdfhsd;
        const lists = this.state.submittedOrders;
        const resultSet = response.data.CallResponse.ReturnValue;
        let found;
        if (resultSet.length > 1) {
            found = resultSet.find(function (element) {
                return element.OrderID.toString() === obj.OrderHandle;
            });
        } else {
            found = resultSet;
        }
        const index = lists.findIndex(order => order.OrderHandle === found.OrderID.toString());
        lists[index].OrderStatus = found.OrderStatus;
        lists[index].ShipmentStatus = found.ShipmentStatus;
        lists[index].TrackingNumber = found.TrackingNo;
        this.setState((prevState, props) => {
            submittedOrderLists: lists
        });
        return response;
    };

    //Update order status to Ashlin database, only update status if NOT "Entered"
    saveOrderStatuses = async () => {
        let data = this.state.updatableOrders;
        data = data.map(i => {
            if (i.OrderStatus === "Canceled") {
                i.ReissueNumber++;
            }
            return i;
        });

        //8/20/18 mock orders are only to be created via Postman
        //        data = this.state.submittedOrderLists.filter(order=>order.OrderStatus === "Entered");
        //        data.forEach(d=>{
        //            d.TrackingNumber = 'testTracking';
        //            d.OrderStatus = 'Completed';
        //        });

        console.log(data);
        //Send request to brightpearl to update delivery date and order status
        if (ENABLE_BRIGHTPEARL && PRODUCTION) {
            data.forEach(async order => {
                if (order.orderStatus === "Completed" && order.TrackingNumber !== "") {
                    console.log(order);
                    let BPResponse = await updateDeliveryDate(order.ERPOrderId);
                    console.log(BPResponse);
                    //need feedback information
                    BPResponse = await upDateStatusAndNote(order.ERPOrderId, order.trackingNumber);
                    console.log(BPResponse);
                }
            });
        }

        if (data.length > 0) {
            const response = await axios.put(orderStatusURL, data, headers);
            Promise.all([response]).then(() => {

            })
        }

        $('#saveOrderStatuses').hide();

        //window.location.reload();
    };
    */

  //Get order detail from Ashlin database and show it on the detail modal
  handleOpenOrderDetailsModal = async (select) => {
    message.loading(`Getting ${select.ERPOrderId} details`, 0);
    const leanSupplyOrder = await axios.get(
      `${baseURL}api/lean-supply/orders/submitted/${select.LeanSupplyOrderId}/details`
    );
    const order = LeanSupplyModelMapper(leanSupplyOrder.data);
    order.OrderStatus = select;
    this.setState({
      selectedOrder: order,
      showOrderDetailsModal: true,
    }); //, this.handleDetail)
    message.destroy();
  };

  handleCloseOrderDetailsModal = () => {
    this.setState({
      selectedOrder: null,
      showOrderDetailsModal: false,
    });
  };

  /*
    //Show order detail modal
    handleDetail = () => {
        //console.log(this.state.selected);
        $('#OrderStatusDetailModal').modal('show');
    };
    */

  //handle modal on change event and update current selected order.
  handleOnChange = (e, field, index) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    const selected = this.state.selectedOrder;
    if (field === "ShipTo") {
      selected.OrderHeader.ShipTo[name] = value;
    }
    if (field === "OrderDetail") {
      if (name === "ItemQty") {
        selected.OrderDetail[index][name] =
          value !== "" ? parseInt(value, 10) : value;
      } else {
        selected.OrderDetail[index][name] = value;
      }
    } else {
      selected.OrderHeader[name] = value;
    }
    this.setState(
      {
        selected,
      },
      () => console.log(selected)
    );
  };

  //Cancel an order, need confirm with lean supply
  handleCancelOrder = async (e) => {
    e.preventDefault();
    let confirmBox = window.confirm("Do you confirm to cancel this order?");
    if (confirmBox) {
      const orderHandle = this.state.selectedOrder.OrderStatus.orderHandle;
      const data = cancelOrderModel(orderHandle);
      const url = proxyurl + cancelOrderURL;
      const response = await axios.post(url, data, headers);
    }
    //Update status for current UI.
    this.handleUpdateOrderStatuses();
  };

  handleReIssueOrder = async (e) => {
    e.preventDefault();
    let confirmBox = window.confirm("Do you confirm to reIssue this order?");
    if (confirmBox) {
      const selected = this.state.selectedOrder;
      selected.OrderHeader.Comment += `This is an RE-ISSUE order to replace original Order number: ${selected.OrderHeader.OrderNumber}`;
      selected.OrderHeader.OrderNumber = `${selected.OrderHeader.OrderNumber}-1`;
      const added = $(".order_detail_added");
      if (added.length !== 0) {
        const index = selected.OrderDetail.length;
        const addedValue = added.map((i) => added[i].value);
        const addItem = {
          ItemCode: addedValue[0],
          ItemDescription: addedValue[1],
          ItemName: addedValue[1],
          ItemQty: parseInt(addedValue[2], 10),
          poNo: addedValue[3],
          uofm: "EACH",
          OrderLine: index + 1,
        };
        selected.OrderDetail[index] = addItem;
      }
      const data = SubmitOrderModel(selected);
      //console.log(data);
      //console.log(selected);
      const url = proxyurl + submitOrderURL;
      const response = await axios.post(url, data, headers);
      selected.OrderStatus.orderStatus =
        response.data.CallResponse.ResponseText === "Order created successfully"
          ? "Entered"
          : response.data.CallResponse.ResponseText;
      selected.OrderStatus.orderHandle = response.data.CallResponse.ReturnValue;
      selected.OrderStatus.FulfillmentStatus = "Acknowledged";
      //console.log(selected.OrderStatus);
      const res = await axios.put(
        submittedOrdersURL,
        [selected.OrderStatus],
        headers
      );
      //console.log(res);
      added.remove();
      window.location.reload();
    }
  };

  handleAddItem = (e, value, id) => {
    e.preventDefault();
    $(
      `<div  class="row">` +
        `<div class="col"><span class="font-weight-bold"> Item SKU: </span><input value=${value.ItemCode} name="ItemCode" class="order_detail_added" /></div>` +
        `<div class="col"><span class="font-weight-bold"> Item Name: </span><input value=${value.ItemName} name="ItemName" class="order_detail_added" readonly/></div>` +
        `<div class="col"><span class="font-weight-bold"> Item Qty: </span><input value=${value.ItemQty} name="ItemQty" class="order_detail_added" /></div>` +
        `<div class="col"><span class="font-weight-bold"> Item PO #: </span><input value=${value.poNo} name="ItempoNo" class="order_detail_added" readonly/></div></div>`
    ).insertAfter(`#${id}`);
  };
  handleOrderFilter = (event) => {
    const target = event.target;
    const value = target.checked;
    if (value) {
      const orders = this.state.allSubmittedOrders;
      const filteredOrders = orders.filter((o) => {
        return !(
          o.FulfillmentStatus === "Closed" ||
          o.FulfillmentStatus === "Cancelled"
        );
      });
      this.setState(
        {
          showOnlyNewOrders: value,
          submittedOrders: filteredOrders,
        },
        () => {
          //console.log(this.state.submittedOrders)
        }
      );
    } else {
      const orders = this.state.allSubmittedOrders;
      this.setState({
        showOnlyNewOrders: value,
        submittedOrders: orders,
      });
    }
  };
  render() {
    return (
      <div className="container-fluid row">
        <div className={"col-3"}>
          <MainSideBar />
        </div>
        <div className={"col"}>
          <OrderStatusDetailModal
            show={
              this.state.showOrderDetailsModal &&
              this.state.selectedOrder !== null
            }
            order={this.state.selectedOrder}
            handleClose={this.handleCloseOrderDetailsModal}
            handleCancelOrder={this.handleCancelOrder}
            handleOnChange={this.handleOnChange}
            handleReIssueOrder={this.handleReIssueOrder}
            handleAddItem={this.handleAddItem}
          />
          <OrderDispatchResultsModal
            show={this.state.showOrderDispatchResultsModal}
            orderDispatchResults={this.state.orderDispatchResults}
            handleClose={this.handleCloseOrderDispatchResultsModal}
          />
          <br />
          <h1 className={"text-center"}>Dispatched Orders</h1>
          <div style={{ marginLeft: "50%" }}>
            -- SHOW ONLY NEW DISPATCHED ORDERS:{" "}
            <input
              name="showOnlyNewOrders"
              type="checkbox"
              style={{ width: "20px", height: "20px", marginRight: "15px" }}
              checked={this.state.showOnlyNewOrders}
              onChange={this.handleOrderFilter}
            />
          </div>

          {this.state.showLoadingIcon && (
            <div className={"text-center text-success display-4"}>
              <i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...
            </div>
          )}
          <hr />
          <div style={{ textAlign: "center" }}>
            <div className="row">
              <div className="col-4">
                {this.state.showSaveOrderStatusButton && (
                  <button
                    onClick={this.handleSaveOrderStatuses}
                    id="saveOrderStatuses"
                    /*style={{ display: 'none' }}*/ className={
                      "btn btn-danger btn-block"
                    }
                  >
                    Update Statuses in Database
                  </button>
                )}
              </div>
              <div className="col-4 ml-auto">
                {this.state.showUpdateOrderStatusButton && (
                  <button
                    onClick={this.handleUpdateOrderStatuses}
                    id="updateOrderStatuses"
                    className={"btn btn-primary btn-block"}
                  >
                    Check Order Statuses
                  </button>
                )}
              </div>
            </div>
            {this.state.submittedOrders.length > 0 ? (
              <ReactTable
                data={this.state.submittedOrders}
                filterable
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={[
                  {
                    columns: [
                      {
                        Header: "Sales Channel",
                        id: "SalesChannel",
                        accessor: (d) => d.ChannelId,
                        Cell: ({ value }) => {
                          let backgroundColor = "";
                          switch (value) {
                            case "fc216960-10e3-44df-9ac6-861a7bc07986":
                              value = "Walmart Canada";
                              backgroundColor = "#167ef6";
                              break;
                            case "6d1daf13-1912-4ef1-a22e-67fe3205be37":
                              value = "Staples.ca";
                              backgroundColor = "#e4606d";
                              break;
                            case "1fb910a2-ddef-46dd-b99c-15307f27080b":
                              value = "StaplesAdvantage CAN";
                              backgroundColor = "#ff4b90";
                              break;
                            case "f0b45479-3727-498d-b214-54608fe3cb99":
                              value = "Hudson's Bay Company";
                              backgroundColor = "#e1b143";
                              break;
                            case "4753cc0f-74c1-4684-ba0e-05e0238963c6":
                              value = "Mirakl Best Buy Canada Marketplace";
                              backgroundColor = "#49fae6";
                              break;
                            case "7648dc8e-4709-4764-af12-9cd72576b5db":
                              value = "Best Buy Canada";
                              backgroundColor = "#0bc7fa";
                              break;
                            case "9abd550e-8af8-4d5b-aa0c-a9d50efd9bc0":
                              value = "Walmart Marketplace";
                              backgroundColor = "#007DC6";
                              break;

                            case "c6955887-f0b9-401f-b71d-27723e749d6b":
                              value = "Corporate US";
                              backgroundColor = "#2fcc9e";
                              break;
                            case "82f44b4b-5767-4a9e-b43f-1d33399a3ffc":
                              value = "ASHLIN Leather - BIG COMMERCE";
                              backgroundColor = "#2fcc9e";
                              break;
                            case "7653c23a-20a0-4e4b-911b-d2cb84b514aa":
                              value = "AshlinLeather.com-AMAZON.COM USA";
                              backgroundColor = "#2fcc9e";
                              break;
                            case "92b1f329-8d2e-46b3-ace1-6e06ef97fe8e":
                              value = "AshlinLeather.com--AMAZON.CA CANADA";
                              backgroundColor = "#2fcc9e";
                              break;
                            case "75678d60-9da0-4974-942f-e04008cd0da1":
                              value =
                                "eBay: ashlinleather.com - ebay.ca Canada";
                              backgroundColor = "#2fcc9e";
                              break;
                            case "fd2d9514-c2e3-43e8-98fb-358e8bbc5674":
                              value = "AshlinLeather.com-AMAZON.CA - CANADA";
                              backgroundColor = "#2fcc9e";
                              break;

                            case "27d3cf14-1f25-46e0-b31d-8a98074cb3f5":
                              value = "Corporate Canada";
                              backgroundColor = "#2fcc9e";
                              break;
                            case "fbfd012b-ad4f-4535-a71b-fbc177d9e2d7":
                              value = "AshlinLeather.com--AMAZON.COM USA";
                              backgroundColor = "#2fcc9e";
                              break;
                            case "e7446e77-71e0-4592-9dcb-07e7398c88a1":
                              value = "POPPYBUZZ - BIGCOMMERCE";
                              backgroundColor = "#2fcc9e";
                              break;
                            default:
                              value = "Channel Info Missing";
                          }
                          return (
                            <div
                              style={{
                                backgroundColor: backgroundColor,
                              }}
                            >
                              {value}
                            </div>
                          );
                        },
                        filterMethod: (filter, row) => {
                          switch (filter.value) {
                            case "all":
                              return true;
                            case "Walmart Canada":
                              return (
                                row[filter.id] ===
                                "fc216960-10e3-44df-9ac6-861a7bc07986"
                              );
                            case "Staples.ca":
                              return (
                                row[filter.id] ===
                                "6d1daf13-1912-4ef1-a22e-67fe3205be37"
                              );
                            case "StaplesAdvantage CAN":
                              return (
                                row[filter.id] ===
                                "1fb910a2-ddef-46dd-b99c-15307f27080b"
                              );
                            case "Hudson's Bay Company":
                              return (
                                row[filter.id] ===
                                "f0b45479-3727-498d-b214-54608fe3cb99"
                              );
                            case "Mirakl Best Buy Canada Marketplace":
                              return (
                                row[filter.id] ===
                                "4753cc0f-74c1-4684-ba0e-05e0238963c6"
                              );
                            case "Best Buy Canada":
                              return (
                                row[filter.id] ===
                                "7648dc8e-4709-4764-af12-9cd72576b5db"
                              );
                            default:
                              return row[filter.id] === "";
                          }
                        },
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={(event) => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                          >
                            <option value="all">Show All</option>
                            <option value="Staples.ca">Staples Exchange</option>
                            <option value="StaplesAdvantage CAN">
                              Staples Advantage
                            </option>
                            <option value="Walmart Canada">
                              Walmart Canada
                            </option>
                            <option value="Hudson's Bay Company">
                              Hudson's Bay
                            </option>
                            <option value="Mirakl Best Buy Canada Marketplace">
                              Walmart Marketplace
                            </option>
                            <option value="Best Buy Canada">Best Buy</option>
                          </select>
                        ),
                      },
                      {
                        Header: "Channel Order #",
                        accessor: "ChannelOrderId",
                        filterMethod: (filter, row) =>
                          row[filter.id] !== undefined
                            ? row[filter.id].startsWith(filter.value)
                            : "",
                      },
                      {
                        Header: "Brightpearl #",
                        accessor: "ERPOrderId",
                        filterMethod: (filter, row) =>
                          row[filter.id] !== undefined
                            ? row[filter.id].startsWith(filter.value)
                            : "",
                      },
                      {
                        Header: "3PL #",
                        accessor: "OrderHandle",
                        filterMethod: (filter, row) =>
                          row[filter.id] !== undefined
                            ? row[filter.id].startsWith(filter.value)
                            : "",
                      },
                      // {
                      //     Header: "Order Status",
                      //     accessor: "OrderStatus",
                      //     id: "OrderStatus",
                      //     Cell: ({ value }) => (<div
                      //         style={{
                      //             backgroundColor: getBackGroundColor(value)
                      //         }}
                      //     >{value}</div>),
                      //     filterMethod: (filter, row) => {
                      //         if (filter.value === "all") {
                      //             return true;
                      //         }
                      //         if (filter.value === "Entered") {
                      //             return row[filter.id] === "Entered";
                      //         }
                      //         if (filter.value === "Completed") {
                      //             return row[filter.id] === "Completed";
                      //         }
                      //         if (filter.value === "Canceled") {
                      //             return row[filter.id] === "Canceled";
                      //         }
                      //         return row[filter.id] === "Item already exists.";
                      //     },
                      //     Filter: ({ filter, onChange }) =>
                      //         <select
                      //             onChange={event => onChange(event.target.value)}
                      //             style={{ width: "100%" }}
                      //             value={filter ? filter.value : "all"}
                      //         >
                      //             <option value="all">Show All</option>
                      //             <option value="Entered">Entered</option>
                      //             <option value="Completed">Completed</option>
                      //             <option value="Canceled">Canceled</option>
                      //         </select>
                      // },
                      {
                        Header: "Fulfillment Status",
                        accessor: "FulfillmentStatus",
                        id: "FulfillmentStatus",
                        filterMethod: (filter, row) => {
                          if (filter.value === "all") {
                            return true;
                          }
                          if (filter.value === "Entered") {
                            return row[filter.id] === "Entered";
                          }
                          if (
                            filter.value ===
                            "Acknowledged and awaiting shipment"
                          ) {
                            return (
                              row[filter.id] ===
                                "Acknowledged and awaiting shipment" ||
                              row[filter.id] === "Received"
                            );
                          }
                          if (filter.value === "Shipped and awaiting invoice") {
                            return (
                              row[filter.id] === "Shipped and awaiting invoice"
                            );
                          }
                          if (filter.value === "Closed") {
                            return row[filter.id] === "Closed";
                          }
                          return row[filter.id] === "Item already exists.";
                        },
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={(event) => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                          >
                            <option value="all">Show All</option>
                            <option value="Entered">Entered</option>
                            <option value="Acknowledged and awaiting shipment">
                              Acknowledged and awaiting shipment
                            </option>
                            <option value="Shipped and awaiting invoice">
                              Shipped and awaiting invoice
                            </option>
                            <option value="Closed">Closed</option>
                          </select>
                        ),
                      },
                      {
                        Header: "Carrier",
                        id: "TrackingNumber",
                        filterable: false,
                        accessor: (d) => d.TrackingNumber,
                        Cell: ({ value }) => {
                          if (value) {
                            if (
                              value.toUpperCase().includes("NET") ||
                              value.toUpperCase().includes("LSHP")
                            ) {
                              value = "Loomis";
                            } else if (value.includes("Z")) {
                              value = "UPS";
                            } else if (value.includes("D")) {
                              value = "Canpar";
                            } else if (!isNaN(value) && value.length === 16) {
                              value = "Canada Post";
                            } else if (!isNaN(value) && value.length === 12) {
                              value = "FedEx";
                            } else {
                              value = "Unknown Carrier";
                            }

                            return <div>{value}</div>;
                          } else {
                            return <></>;
                          }
                        },
                        Filter: ({ filter, onChange }) => (
                          <select
                            onChange={(event) => onChange(event.target.value)}
                            style={{ width: "100%" }}
                            value={filter ? filter.value : "all"}
                          >
                            <option value="all">Show All</option>
                            <option value="Entered">Entered</option>
                            <option value="Acknowledged and awaiting shipment">
                              Acknowledged and awaiting shipment
                            </option>
                            <option value="Shipped and awaiting invoice">
                              Shipped and awaiting invoice
                            </option>
                            <option value="Closed">Closed</option>
                          </select>
                        ),
                      },
                      {
                        Header: "Tracking #",
                        accessor: "TrackingNumber",
                      },
                      {
                        Header: "Processed Date",
                        accessor: "ProcessedDate",
                        Cell: ({ value }) => {
                          if (value) {
                            if (value === "0001-01-01T00:00:00") {
                              return <div></div>;
                            } else {
                              value = value.substring(0, 19).replace("T", " ");
                              return <div>{value}</div>;
                            }
                          } else {
                            return <></>;
                          }
                        },
                        filterMethod: (filter, row) =>
                          row[filter.id] !== undefined
                            ? row[filter.id].startsWith(filter.value)
                            : "",
                      },
                      {
                        Header: "Shipped Date",
                        accessor: "ShippedDate",
                        Cell: ({ value }) => {
                          if (value) {
                            if (value === "0001-01-01T00:00:00") {
                              return <div></div>;
                            } else {
                              value = value.substring(0, 19).replace("T", " ");
                              return <div>{value}</div>;
                            }
                          } else {
                            return <></>;
                          }
                        },
                        filterMethod: (filter, row) =>
                          row[filter.id] !== undefined
                            ? row[filter.id].startsWith(filter.value)
                            : "",
                      },
                      // {
                      //     Header:"Last Updated Time",
                      //     accessor:"LastUpdated",
                      //     Cell: ({ value }) => {
                      //         if (value) {
                      //             //2019-01-22 17:49:51.4427938
                      //             value = value.substring(0,19).replace("T"," ");
                      //             return (
                      //                 <div
                      //                 >{value}
                      //                 </div>)
                      //         }
                      //     },
                      //     filterMethod: (filter, row) =>
                      //         row[filter.id]!==undefined? row[filter.id].startsWith(filter.value):""
                      // }
                    ],
                  },
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
                getTrProps={(state, rowInfo) => {
                  return {
                    onClick: (e) => {
                      this.handleOpenOrderDetailsModal(rowInfo.original);
                    },
                  };
                }}
              />
            ) : (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 32 }} spin />}
              />
            )}
          </div>
          <br />
        </div>
      </div>
    );
  }
}

const getBackGroundColor = (value) => {
  switch (value) {
    case "Canceled":
      return "yellow";
    case "Shipped":
      return "green";
    case "Manually ON Hold":
      return "orange";
    case "System hold":
      return "orange";
    case "Shortage on Order":
      return "orange";
    default:
      return "";
  }
};
const mapStateToProps = (state) => {
  return {
    newOrders: state.newOrders,
    submittedOrders: state.submittedOrders,
  };
};

export default connect(mapStateToProps, actions)(SubmittedOrder);
