import React from 'react';
import ListItem from './brightpearl_channel_info_list_item';
const brightpearl_channel_info_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
            <tr>
                <th>NAME</th>
                <th>ASHLIN ID</th>
                <th>BRIGHTPEARL ID</th>
                <th>CONTACT ID</th>
                <th>PREFERRED CARRIER</th>
                <th>SHIPPING METHOD ID</th>
                <th>DISCOUNT RATE</th>
                <th><button className={'btn-success'} type={'button'} data-toggle="modal" data-target="#newBrightpearlChannelInfo">Add New</button></th>
            </tr>
            </thead>
            <tbody>
            {props.list.map((item,index)=><ListItem item={item} key={index} index={index} upDateBrightpearlChannelInfo={props.upDateBrightpearlChannelInfo}
                                                    brightpearlChannelInfoOnchange={props.brightpearlChannelInfoOnchange}/>)}
            </tbody>
        </table>
    )
};
export default brightpearl_channel_info_list;