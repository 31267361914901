import React from 'react';
const discount_sku_list_item = ({item,skuOnSelect,index})=>{
    //console.log(item);
    return(
        <li className= 'list-group-item'>
            <input type="checkbox"  style={{width:'30px',height:'30px'}}
                   checked={item.isSelected}  onChange={(e)=>skuOnSelect(e,item.index,index,item.sku)}/>
            <span style={{fontSize:'30px',fontWeight:'bold', marginLeft:'5px'}}>{item.sku}</span>
        </li>
    )};

export default discount_sku_list_item;