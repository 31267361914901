import React from 'react';
const SelectInputWithTrueFalse =({id,label,name,onChange,style, width,defaultValue})=>{

    return(
        <div className='row ' style={style}>
            <div className='col-4' style={{margin:'auto 0', textAlign:'right'}}>
                <label htmlFor={id} style={style}>{label}</label>
            </div>
            <div className='col-7' style={{padding:'0',width:width}}>
                <select name={name}  onChange={onChange} className="form-control" id={id} value={defaultValue}>
                    <option value='1'>True</option>
                    <option value='0'>False</option>
                </select>
            </div>
        </div>
    );
};
export default SelectInputWithTrueFalse;