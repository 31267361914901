import React, {Component}from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';
import MainSideBar from '../sideBar/MainSideBar';
import {
    getBackEndScheduler,getBundledItems,
    getCompanyInfos,
    getSignatures, updateSignature,
    updateBackEndScheduler, updategetCompanyInfo,
    getShippingOptions, updateShippingOption,
    getEmailConfigurations, updateEmailConfiguration, sendPDFEmail,
    getBrightpearlChannelInfos,updateBrightpearlChannelInfo,deleteundledItems,
    updateShippingPackage,getShippingPackages,getShippingMatrices,updateShippingMatrix
} from '../../Utils/ashlinDesign';
import NavItem from '../bootstrapComponent/navItem';
import TabContentItem from '../bootstrapComponent/tabContentItem';

import BackendSchedulerList from './components/backend_scheduler_list'
import CompanyInfoList from './components/company_info_list'
import SignatureInfoList from './components/signature_list';
import ShippingOptionList from './components/shipping_option_list';
import NewShippingOption from "./components/new_shipping_option_detail";
import EmailConfigurationList from './components/email_configuration_list';
import NewEmailConfiguration from "./components/new_email_configuration_detail";
import BrightpearlChannelInfoList from './components/brightpearl_channel_info_list';
import NewBrightpearlChannelInfo from './components/new_brightpearl_channel_info_detail';
import ShippingPackage from './components/shipping_package_list';
import NewShippingPackage from './components/new_shipping_package_detail'
import BundledItemList from './components/bundled_item_list';
import $ from "jquery";
import NewBundledItem from "./components/new_bundled_item_detail";
import ShippingMatrix from './components/shipping_matrix_list';
import NewShippingMatrix from './components/new_shipping_matrix_detail';
import ErrorLogTable from './components/ErrorLogTable';
class Admin extends Component{
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            isLoading:false,
            backEndScheduler:[],

            AshlinInfos:[],
            Signatures:[],
            ShippingOptions:[],
            EmailConfigurations:[],
            BrightpearlChannelInfos:[],
            ShippingPackages:[],
            BundledItems:[],
            ShippingMatrices:[],
            selectedShippingOption:{
                ChannelName:'',
                CarrierName:'',
                OptionLevel:'',
                ServiceType:'',
                BillingAddress:'',
                UserName:'',
                Password:'',
                AccountNum:'',
                ContractNum:'',
                PaidByNum:'',
                APICredentials:''
            },
            selectedEmailConfiguration:{
                Description:'',
                Recipient:'',
                Subject:'',
                EmailBody:''
            },
            selectedEmailConfigurationIndex:0,
            newSignatureFile:undefined,

        }
    }
    onChange =(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        })
    };
    componentDidMount(){
        this._mounted = true;
        this.getData();
        if(!this._mounted){
            if(this.props.authenticated !== undefined && this.props.authenticated.indexOf("Admin") === -1){
                this.props.history.push('/insights');
            }
        }
    }
    getData=async()=>{
        const backEndScheduler  = await getBackEndScheduler();
        const AshlinInfos = await getCompanyInfos();
        const Signatures = await getSignatures();
        const ShippingOptions = await getShippingOptions();
        const EmailConfigurations = await getEmailConfigurations();
        const BrightpearlChannelInfos = await getBrightpearlChannelInfos();
        const ShippingPackages = await getShippingPackages();
        const BundledItems = await getBundledItems();
        const ShippingMatrices = await getShippingMatrices();
        this.setState({
            isLoading:false,
            AshlinInfos,
            Signatures,
            ShippingOptions,
            backEndScheduler,
            EmailConfigurations,
            BrightpearlChannelInfos,
            ShippingPackages,
            BundledItems,
            ShippingMatrices
        });
    };
    componentWillUnmount() {
        this._mounted = false;
    }
    backEndSchedulerHourOnChange = (e,index)=>{
        let value = parseInt(e.target.value);
        if(isNaN(value) || value===0 )
            value = 1;
        if(value > 6)
            value = 6;
        const backEndScheduler = this.state.backEndScheduler;
        backEndScheduler[index].Hours = value;
        this.setState({
            backEndScheduler
        })
    };
    backEndSchedulerTimeOnChange = (e,index)=>{
        const backEndScheduler = this.state.backEndScheduler;
        backEndScheduler[index].Time = e.target.value;
        this.setState({
            backEndScheduler
        })
    };
    updateScheduler = async (scheduler)=>{
        await updateBackEndScheduler(scheduler);
        alert("Update finish");
    };
    companyInfoOnChange = (e,index)=>{
        const name = e.target.name;
        const value = e.target.value;
        const AshlinInfos = this.state.AshlinInfos;
        AshlinInfos[index][name]=value;
        this.setState({
            AshlinInfos
        })
    };
    updateCompanyInfo = async (companyInfo)=>{
        await updategetCompanyInfo(companyInfo);
        alert("Update finish");
    };
    signatureOnChange = (e,index)=>{
        const name = e.target.name;
        const value = e.target.value;
        const Signatures = this.state.Signatures;
        Signatures[index][name]=value;
        this.setState({
            Signatures
        })
    };
    updateSignature = async(signature,index)=>{
        const Signatures = this.state.Signatures;
        Signatures[index] = signature;
        if(this.state.newSignatureFile!==undefined && this.state.newSignatureFile.name === index.toString()){
            const reader = new FileReader();
            reader.readAsDataURL(this.state.newSignatureFile);
            reader.onload = async (event) => {
                signature.SignatureBase64 = event.target.result;
                await updateSignature(signature);
                Signatures[index].SignatureBase64 = event.target.result;
                this.setState({
                    Signatures,
                    newSignatureFile:undefined,
                });
            }
        }else{
            await updateSignature(signature);
            this.setState({
                Signatures,
                newSignatureFile:undefined,
            });
        }
        alert("Finish Update!");
    };

    handleImageOnDrop=(accepted,index)=>{
        const newSignatureFile = new File(accepted,index,{type: accepted[0].type});
        newSignatureFile.preview = accepted[0].preview;
        this.setState({
            newSignatureFile
        })

    };
    handleRemoveSelectImage=()=>{
        this.setState({
            newSignatureFile:undefined
        })
    };
    shippingOptionOnChange=(e,item)=>{
        const name = e.target.name;
        const value = e.target.value;
        const ShippingOptions = this.state.ShippingOptions;
        const index = ShippingOptions.findIndex(s=>s.Id === item.Id);
        ShippingOptions[index][name] = value;
        this.setState({
            ShippingOptions
        })
    };
    updateShippingOption= async (shippingOption)=>{
        const d = new Date();
        shippingOption.LastModifiedDate = d.toISOString().slice(0,19);
        await updateShippingOption(shippingOption);
        alert("Update finish");
        $('#shippingOptionListItemDetail').modal('hide');
    };

    addNewShippingOptionToList = (newShippingOption)=>{
        const ShippingOptions = this.state.ShippingOptions.slice();
        ShippingOptions.push(newShippingOption);
        this.setState({
            ShippingOptions
        })
    };
    showShippingOptionDetail=(index)=>{
        const selectedShippingOption = this.state.ShippingOptions[index];
        this.setState({
            selectedShippingOption
        },()=>{
            $('#shippingOptionListItemDetail').modal('show')
        });
    };

    addNewEmailConfigurationToList = (newEmailConfiguration)=>{
        const EmailConfigurations = this.state.EmailConfigurations.slice();
        EmailConfigurations.push(newEmailConfiguration);
        this.setState({
            EmailConfigurations
        })
    };
    emailConfigurationOnChange=(e,item)=>{
        const name = e.target.name;
        const value = e.target.value;
        const EmailConfigurations  = this.state.EmailConfigurations;
        const index = EmailConfigurations .findIndex(s=>s.Id === item.Id);
        EmailConfigurations[index][name] = value;
        this.setState({
            EmailConfigurations
        })
    };
    emailConfigurationEditorOnchange = (e,index)=>{
        const EmailConfigurations  = this.state.EmailConfigurations;
        const value = e.target.getContent();
        EmailConfigurations[index].EmailBody = value;
        this.setState({
            EmailConfigurations
        });
    };
    updateEmailConfiguration= async (emailConfiguration)=>{
        const d = new Date();
        emailConfiguration.LastModifiedDate = d.toISOString().slice(0,19);
        await updateEmailConfiguration(emailConfiguration);
        alert("Update finish");
        $('#emailConfigurationListItemDetail').modal('hide');
    };

    testSendEmail = async()=>{
        const selectedEmailConfiguration = this.state.selectedEmailConfiguration;
        const recipients = selectedEmailConfiguration.Recipient.split("\n").map(email=>email.trim()).filter(email=>email!=="");
        await sendPDFEmail(recipients,selectedEmailConfiguration.Subject,selectedEmailConfiguration.EmailBody,"","PROFORMA_INVOICE.pdf");
    };
    showEmailConfigurationOptionDetail=(index)=>{
        const selectedEmailConfiguration = this.state.EmailConfigurations[index];
        this.setState({
            selectedEmailConfiguration,
            selectedEmailConfigurationIndex: index
        },()=>{
            $('#emailConfigurationListItemDetail').modal('show')
        });
    };
    upDateBrightpearlChannelInfo = async(brightpearlChannelInfo)=>{
        await updateBrightpearlChannelInfo(brightpearlChannelInfo);
        alert("Update finish");
    };
    upDateShippingPackage = async(shippingPackage)=>{
        await updateShippingPackage(shippingPackage);
        alert("Update finish");
    };
    shippingPackageOnchange = (e,index)=>{
        const name = e.target.name;
        const value = e.target.value;
        const ShippingPackages = this.state.ShippingPackages;
        ShippingPackages[index][name] = value;
        if(name !== "Name"){
            ShippingPackages[index].VolumeCM3 = (parseFloat(ShippingPackages[index].LengthCM) * parseFloat(ShippingPackages[index].WidthCM) * parseFloat(ShippingPackages[index].HeightCM)).toFixed(0);
        }
        this.setState({
            ShippingPackages
        })
    };
    brightpearlChannelInfoOnchange = (e,index)=>{
        const name = e.target.name;
        const value = e.target.value;
        const BrightpearlChannelInfos = this.state.BrightpearlChannelInfos;
        BrightpearlChannelInfos[index][name] = value;
        this.setState({
            BrightpearlChannelInfos
        })
    };
    addNewBrightpearlChannelInfoToList = (brightpearlChannelInfo)=>{
        const BrightpearlChannelInfos = this.state.BrightpearlChannelInfos.slice();
        BrightpearlChannelInfos.push(brightpearlChannelInfo);
        this.setState({
            BrightpearlChannelInfos
        })
    };
    addNewShippingPackageToList = (shippingPackage)=>{
        const ShippingPackages = this.state.ShippingPackages.slice();
        ShippingPackages.push(shippingPackage);
        this.setState({
            ShippingPackages
        })
    };

    addNewBundledItemToList = (bundledItemToList)=>{
        const BundledItems = this.state.BundledItems.slice();
        BundledItems.push(bundledItemToList);
        this.setState({
            BundledItems
        })
    };
    handleDeleteBundledItem = async (index)=>{
        const BundledItems = this.state.BundledItems.slice();
        const product = (BundledItems.splice(index, 1))[0];
        //console.log(product);
        let confirmBox = window.confirm(`Do you confirm to delete ${product.Sku}?`);
        if (confirmBox) {
            await deleteundledItems(product.Items);
            this.setState({
                BundledItems
            })
        }
    };
    upDateShippingMatrix = async(shippingMatrix)=>{
        await updateShippingMatrix(shippingMatrix);
        alert("Update finish");
    };
    shippingMatrixOnchange = (e,index)=>{
        const name = e.target.name;
        const value =  e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const ShippingMatrices = this.state.ShippingMatrices;
        ShippingMatrices[index][name] = value;
        this.setState({
            ShippingMatrices
        })
    };
    addNewShippingMatrixToList = (shippingMatrix)=>{
        const ShippingMatrices = this.state.ShippingMatrices.slice();
        ShippingMatrices.push(shippingMatrix);
        this.setState({
            ShippingMatrices
        })
    };
    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'} style={{paddingLeft: "0"}}>
                    <MainSideBar/>
                </div>
                <div className={'col-9'} style={{textAlign:'center'}}>
                    <br/>
                    <h1 style={{textAlign:'center'}}>Admin Dashboard</h1>
                    <br/>
                    <ul className="nav nav-tabs nav-fill">
                        <NavItem toggleType='tab' id="#backend_scheduler" data="Backend Scheduler"  active={true} />
                        <NavItem toggleType='tab' id="#company_info" data="Company Info"  />
                        <NavItem toggleType='tab' id="#signature_info" data="Signature Info"  />
                        <NavItem toggleType='tab' id="#shipping_option" data="Shipping Option"  />
                        <NavItem toggleType='tab' id="#email_configuration" data="Email Configuration"  />
                        <NavItem toggleType='tab' id="#brightpearl_channel" data="Brightpearl Channel"  />
                        <NavItem toggleType='tab' id="#bundled_item" data="Bundled Item"  />
                        <NavItem toggleType='tab' id="#shipping_package" data="Shipping Package"  />
                        <NavItem toggleType='tab' id="#shipping_matrix" data="Shipping Matrix"  />
                        <NavItem toggleType='tab' id="#error_log" data="Error Log"  />
                    </ul>
                    <div className="tab-content">
                        <TabContentItem id="backend_scheduler"  style={{height:"400px",margin:'30px 0 10px'}} active={true} >
                            <h2 className={'text-secondary'}>Backend Scheduler</h2>
                            <BackendSchedulerList
                                list={this.state.backEndScheduler} backEndSchedulerHourOnChange={this.backEndSchedulerHourOnChange}
                                updateScheduler = {this.updateScheduler} backEndSchedulerTimeOnChange={this.backEndSchedulerTimeOnChange}
                            />
                        </TabContentItem>
                        <TabContentItem id="company_info"  style={{height:"500px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Company Info</h2>
                            <CompanyInfoList
                                list={this.state.AshlinInfos}   companyInfoOnChange={this.companyInfoOnChange}
                                updateCompanyInfo = {this.updateCompanyInfo}
                            />
                        </TabContentItem>
                        <TabContentItem id="signature_info"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Signature Info</h2>
                            <SignatureInfoList
                                list={this.state.Signatures} signatureOnChange={this.signatureOnChange } removeSelectImage={this.handleRemoveSelectImage}
                                updateSignature={this.updateSignature} imageOnDrop={this.handleImageOnDrop} newSignatureFile={this.state.newSignatureFile}
                            />
                        </TabContentItem>
                        <TabContentItem id="shipping_option"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Shipping Option</h2>
                            <ShippingOptionList list={this.state.ShippingOptions} updateShippingOption={this.updateShippingOption} selectedShippingOption={this.state.selectedShippingOption}
                                                shippingOptionOnChange={this.shippingOptionOnChange} showShippingOptionDetail={this.showShippingOptionDetail}/>
                            <NewShippingOption addNewShippingOptionToList={this.addNewShippingOptionToList}/>
                        </TabContentItem>
                        <TabContentItem id="email_configuration"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Email Configuration</h2>
                            <EmailConfigurationList list={this.state.EmailConfigurations}
                                                    updateEmailConfiguration={this.updateEmailConfiguration}
                                                    selectedEmailConfiguration={this.state.selectedEmailConfiguration}
                                                    emailConfigurationOnChange={this.emailConfigurationOnChange}
                                                    emailConfigurationEditorOnchange={this.emailConfigurationEditorOnchange}
                                                    showEmailConfigurationOptionDetail={this.showEmailConfigurationOptionDetail}
                                                    testSendEmail = {this.testSendEmail} selectedEmailConfigurationIndex={this.state.selectedEmailConfigurationIndex}
                            />
                            <NewEmailConfiguration addNewEmailConfigurationToList={this.addNewEmailConfigurationToList}/>
                        </TabContentItem>
                        <TabContentItem id="brightpearl_channel"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Brightpearl Channel</h2>
                            <BrightpearlChannelInfoList
                                list={this.state.BrightpearlChannelInfos} upDateBrightpearlChannelInfo={this.upDateBrightpearlChannelInfo}
                                brightpearlChannelInfoOnchange={this.brightpearlChannelInfoOnchange}
                            />
                            <NewBrightpearlChannelInfo addNewBrightpearlChannelInfoToList={this.addNewBrightpearlChannelInfoToList} />
                        </TabContentItem>
                        <TabContentItem id="bundled_item"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Bundled Item</h2>
                            <BundledItemList
                                list={this.state.BundledItems}
                                deleteBundledItem = {this.handleDeleteBundledItem}
                            />
                            <NewBundledItem addNewBundledItemToList={this.addNewBundledItemToList} />
                        </TabContentItem>

                        <TabContentItem id="shipping_package"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Shipping Package</h2>
                            <ShippingPackage
                                list={this.state.ShippingPackages} upDateShippingPackage={this.upDateShippingPackage}
                                shippingPackageOnchange = {this.shippingPackageOnchange}
                            />
                            <NewShippingPackage addNewShippingPackageToList = {this.addNewShippingPackageToList} />
                        </TabContentItem>

                        <TabContentItem id="shipping_matrix"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Shipping Matrix</h2>
                            <ShippingMatrix
                                list={this.state.ShippingMatrices} upDateShippingMatrix ={this.upDateShippingMatrix}
                                shippingMatrixOnchange = {this.shippingMatrixOnchange}
                            />
                            <NewShippingMatrix addNewShippingMatrixToList = {this.addNewShippingMatrixToList} />
                        </TabContentItem>
                        <TabContentItem id="error_log"  style={{height:"450px",margin:'30px 0 10px'}} >
                            <h2 className={'text-secondary'}>Error Log</h2>
                            <ErrorLogTable/>
                        </TabContentItem>

                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return {
        authenticated:state.authenticated
    }
};

export default connect(mapStateToProps,actions)(Admin);