export const uDuCatCategories =[
    "Acrylic Products",
    "Advertising Gifts",
    "Albums",
    "Amenity Kits",
    "Apparel",
    "Aprons",
    "Attache Case",
    "Attachments",
    "Auto Accessories",
    "Automotive Essentials",
    "Automotive Kits",
    "Awards",
    "Baby Products" ,
    "Back Packs",
    "Badges",
    "Bags (Accessories)",
    "Bags (Business & Exec)",
    "Bags (Canvas)",
    "Bags (Cinchbag)",
    "Bags (Computer)",
    "Bags (Cosmetic/Toiletry)",
    "Bags (Cotton)",
    "Bags (Day)",
    "Bags (Document)",
    "Bags (Drawstring)",
    "Bags (Duffel)",
    "Bags (Ecological)",
    "Bags (Fashion)",
    "Bags (Garment)",
    "Bags (Lunch)",
    "Bags (Messenger)",
    "Bags (Misc.)",
    "Bags (Nylon)",
    "Bags (Roll)",
    "Bags (Shoe)",
    "Bags (Shopping)",
    "Bags (Slingpack)",
    "Bags (Sport)",
    "Bags (Tablet)",
    "Bags (Tote)",
    "Bags (Travel)",
    "Bags (Wine)",
    "Bags",
    "Balls (Baseball)",
    "Balls (Golf)",
    "Balls (sport)",
    "Balls (Stress)",
    "Balls",
    "Bandanas",
    "Barbecues",
    "Bathrobes",
    "Batteries",
    "BBQ Accessories",
    "BBQs",
    "Beauty Products",
    "Belt Buckles",
    "Belts",
    "Beverageware",
    "Bibs",
    "Binders",
    "Blankets (Polar Fleece)",
    "Blankets (Recreational)",
    "Blankets",
    "Bookmarks",
    "Bottles (Vacuum)",
    "Bottles (Water)",
    "Bottles",
    "Boxes",
    "Bracelets / Wrist Bands",
    "Briefcases",
    "Business & Exec. Gifts",
    "Business Organizers",
    "Calculators",
    "Calendars",
    "Cameras",
    "Camping Equipment",
    "Candy",
    "Caps (Baseball)",
    "Caps (Cotton)",
    "Caps (Golf)",
    "Caps",
    "Cards (Business)",
    "Cards (Calendar)",
    "Cards (Plastic)",
    "Cards",
    "Cases (Business)",
    "Cases (Catalog)",
    "Cases (Computer)",
    "Cases (Mobile)",
    "Cases (Tablets)",
    "Cases (Various)",
    "Cases (Writing)",
    "Cases",
    "Chamois",
    "Childrens Products",
    "Chocolate, Candy",
    "Clips",
    "Cloth Products",
    "Clothes",
    "Coasters",
    "Coin Purses",
    "Computer Accessories (Desk Mouse)",
    "Computer Accessories (Desk Mouse, Optical)",
    "Computer Accessories",
    "Conference Accessories",
    "Cubes (Photo)",
    "Cubes",
    "Cufflinks",
    "Cups",
    "Custom Made Products",
    "Desk & Office Products",
    "Desk Accessories",
    "Desk Mats",
    "Diaries (desk)",
    "Diaries, Planners, Agendas",
    "Dogtags",
    "Dresses",
    "Drinkware (Insulated)",
    "Drinkware (Stainless Steel)",
    "Drinkware (Thermos)",
    "Drinkware",
    "Electronics (Flash Drive)",
    "Electronics",
    "Emergency Kits",
    "Environmentally Friendly Products",
    "Exercise Equipment",
    "Eyewear",
    "Fanny Packs",
    "First Aid/Survival Kits and Products",
    "Fishing Accessories",
    "Flashlights",
    "Fleece",
    "Folders",
    "Food Containers",
    "Food Gifts",
    "Food",
    "Frames",
    "Furkins",
    "Games, Toys",
    "Giftware (Incentive Program)",
    "Giftware",
    "Glassware",
    "Gloves",
    "Golf (Accessories)",
    "Golf (Balls)",
    "Golf (Tools)",
    "Golf Promotions",
    "Golf",
    "Golf-Caddies",
    "Green Products",
    "Handbags/Purses",
    "Hats (Bucket)",
    "Hats",
    "Headbands",
    "Headphones",
    "Headwear",
    "Health & Pharmaceutical Products",
    "Hockey",
    "Holders (Badges)",
    "Holders (Business Card)",
    "Holders (Card)",
    "Holders (Change)",
    "Holders (Coupon)",
    "Holders (Credit Card)",
    "Holders (Note)",
    "Holders (Paper)",
    "Holders (Passport)",
    "Holders",
    "Holiday Gifts",
    "Hoodies",
    "Housewares/Home Decor",
    "Identification Tags",
    "iPhone, iPad Accessories",
    "iPhones",
    "Jackets (Leather)",
    "Jackets (Spring, Fall)",
    "Jackets",
    "Jewelry",
    "Journals",
    "Key Chains",
    "Key Tags & Holders",
    "Kitchen Accessories",
    "Labels",
    "Laminated Products",
    "Lanyards",
    "Lapel Pins",
    "Laser Pointers",
    "Leather Products",
    "Leatherette",
    "Lifestyles",
    "Lighting Products",
    "Luggage",
    "Made in Canada - Plastic Products",
    "Made in Canada",
    "Magnets",
    "Magnifiers",
    "Manicure Accessories",
    "Mats (Floor)",
    "Mats",
    "Measuring Devices",
    "Melton Leather",
    "Memo Pads",
    "Mirror Cases",
    "Mirrors",
    "Mitts",
    "Mobile Phone Accessories",
    "Money Clips",
    "Money",
    "Mouse Pads",
    "Mugs (Thermal, Stainless Steel)",
    "Mugs (Travel)",
    "Mugs",
    "Musical Instruments",
    "NAFTA",
    "Napkins",
    "Note Pads",
    "Notebooks",
    "Nylon",
    "Openers (Bottle)",
    "Openers",
    "Organizers",
    "Outdoor Products",
    "Outerwear",
    "Oven Mitts",
    "Packaging",
    "Padfolio",
    "Pants",
    "Paper Products",
    "Party Accessories",
    "Pen & Paper Sets",
    "Pen Sets",
    "Pencil Cases",
    "Pens (Ball Point)",
    "Pens (Metal Ball Point)",
    "Pens",
    "Pet Products",
    "Pewter Products",
    "Photo Frames",
    "Pillows",
    "Pocket Planners",
    "Portfolios",
    "Pot Holders",
    "Prints",
    "Pullovers",
    "Rain Gear",
    "Rain Products",
    "Recycled Products",
    "Safety & Security Items",
    "Safety Kits",
    "Safety, Health & Comfort",
    "Scarves",
    "Shirts (Casual)",
    "Shirts (Crew Neck)",
    "Shirts (Sport)",
    "Shirts",
    "Shorts",
    "Smart Phone Accessories",
    "Souvenirs",
    "Spa Products",
    "Sporting Goods",
    "Sports",
    "Sportswear",
    "Stainless Steel Products",
    "Stationery",
    "Sticky Notes",
    "Stress Relievers",
    "Sublimated Apparel",
    "Sublimated Products",
    "Sublimated Wearables",
    "Summer Items",
    "Sunglasses",
    "Sunscreen",
    "Sweaters",
    "Sweatshirts",
    "Tableware",
    "Tags (Luggage)",
    "Tags",
    "Tank Top",
    "Technology",
    "Telephone Accessories",
    "Ties",
    "Time Management Organizers & Refills",
    "Timepieces",
    "Tissue Packs",
    "Tools",
    "Towels (Beach)",
    "Towels (Golf)",
    "Towels (Skate)",
    "Towels (Sport)",
    "Towels",
    "Travel Products",
    "Trays",
    "T-Shirts",
    "Tumblers",
    "Umbrellas",
    "Uniforms",
    "Union Made",
    "USB Drives",
    "Vests",
    "Vinyl Products (Custom Made)",
    "Vinyl",
    "Visor Organizers",
    "Wallets (Auto Warranty)",
    "Wallets (Business Card)",
    "Wallets (Ticket)",
    "Wallets (Travel)",
    "Wallets",
    "Watches (Fashion)",
    "Watches",
    "Wearables",
    "Wine Accessories",
    "Wine",
    "Winter Products",
    "Woman owned business",
    "Wood Products",
    "Workwear",
    "Writing Instruments",
    "Writing Pad",
    "Zipper Pulls",
    ];