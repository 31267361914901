export const asiCategories=[
    'ACADEMIC',
    'ADULT',
    'ANIMAL',
    'ANNIVERSARY',
    'AROMATHERAPY',
    'AUTO RACING',
    'AUTOMOBILE',
    'AVIATION',
    'BABY',
    'BASEBALL',
    'BASKETBALL',
    'BEACH',
    'BEAUTY',
    'BEREAVEMENT',
    'BICYCLING',
    'BIODEGRADABLE',
    'BIRTHDAY',
    'BOWLING',
    'BOXING',
    'BREAST CANCER',
    'CAMPING',
    'CASINO',
    'CELESTIAL',
    'CHANUKAH',
    'CHEERLEADING',
    'CHRISTMAS',
    'COLLEGIATE',
    'COMMUNICATIONS',
    'COMPANY PICNIC',
    'COMPUTER',
    'CONSTRUCTION',
    'CULTURAL',
    'DENTAL',
    'DIRECT-MAIL',
    'EASTER',
    'ECO & ENVIRONMENTALLY FRIENDLY',
    'EDUCATION',
    'EMPLOYEE RECOGNITION',
    'ENTERTAINMENT',
    'EQUESTRIAN',
    'FATHER\'S DAY',
    'FINANCIAL',
    'FIREFIGHTERS',
    'FISHING',
    'FOOD',
    'FOOTBALL',
    'GAMBLING',
    'GARDENING',
    'GLOBAL',
    'GOLF',
    'GOLF TOURNAMENT',
    'GRADUATION',
    'GRAND-OPENINGS',
    'GYMNASTICS',
    'HALLOWEEN',
    'HAWAIIAN',
    'HEALTH & FITNESS',
    'HEALTH CARE',
    'HOCKEY',
    'HOLIDAYS',
    'HUNTING',
    'JUDICIAL',
    'JULY 4TH',
    'KARATE',
    'LACROSSE',
    'LOVE',
    'MARTIAL ARTS',
    'MEDICAL',
    'MILITARY',
    'MOTHER\'S DAY',
    'MUSIC',
    'MYTHICAL',
    'NATIVE AMERICANS',
    'NAUTICAL',
    'NEW YEAR',
    'NUMBER ONE',
    'ORGANIC',
    'ORGANIZATIONS',
    'PATRIOTIC',
    'PERFORMING ARTS',
    'PHARMACEUTICAL',
    'PHYSICALLY CHALLENGED',
    'POLICE',
    'POLITICAL',
    'POOL',
    'PREHISTORIC',
    'RACQUETBALL',
    'RAILROAD',
    'REAL ESTATE',
    'RECYCLED',
    'RELAXATION',
    'RELIGIOUS',
    'RETIREMENT',
    'ROMANCE',
    'SAFETY',
    'SAFETY FAIR',
    'SAILING',
    'SEASONS',
    'SEMINARS',
    'SKATING',
    'SKIING',
    'SOCCER',
    'SOFTBALL',
    'SPACE EXPLORATION',
    'SPORTS',
    'ST. PATRICK\'S DAY',
    'SUBSTANCE ABUSE AWARENESS',
    'SUSTAINABLE',
    'SWIMMING',
    'TENNIS',
    'THANK YOU',
    'THANKSGIVING',
    'TRACK AND FIELD',
    'TRADE SHOW',
    'TRANSPORTATION',
    'TRAVEL',
    'USA',
    'VACATIONS',
    'VALENTINE\'S DAY',
    'VOLLEYBALL',
    'WEDDING',
    'WELLNESS',
    'WESTERN',
    'WILDERNESS',
    'WRESTLING',
    'ZODIAC'
];

export const asiIndustries=[
    'Automotive',
    'Corporate',
    'Education',
    'Finance',
    'Healthcare',
    'Non-Profit',
    'Real Estate'
];