import React from 'react';
import ImageDropZone from '../../../Utils/imageUpload/imageDropZone';
const signature_list_item = ({item, index,  signatureOnChange , updateSignature,imageOnDrop,newSignatureFile,removeSelectImage })=>{
    return(
        <tr>
            <th><input type={'text'} onChange={(e)=>signatureOnChange(e,index)}  style={{width:'100%'}} name={'Name'} value={item.Name} /></th>
            <td><input type={'text'} onChange={(e)=>signatureOnChange(e,index)}  style={{width:'100%'}} name={'Title'} value={item.Title} /></td>
            <td><img src={item.SignatureBase64} style={{width:'200px', height:'200px'}}/></td>
            <td>
                {
                    (newSignatureFile===undefined || parseInt(newSignatureFile.name) !== index) &&
                    <ImageDropZone className={'col'} onDrop={(accepted) => imageOnDrop(accepted,index)}
                                   sizeDescription={""}
                    />
                }
                {
                    (newSignatureFile!==undefined && parseInt(newSignatureFile.name) === index) &&
                        <span>
                            <img src={newSignatureFile.preview} style={{width:'200px', height:'200px'}}/>
                             <button onClick={removeSelectImage} type={'button'} style={{verticalAlign:'top'}}>X</button>
                        </span>

                }

            </td>
            <td>
                <button className={'btn-danger'} type={'button'} onClick={()=>updateSignature(item,index)}>Update</button>
            </td>
        </tr>
    );
};
export default signature_list_item;