import axios from 'axios';
import {baseURL,PRODUCTION, proxyurl } from "./requestHelper";
import moment from 'moment';
// import { bestbuyMarketplaceOrders } from './test/bestbuyMarketOrders'
const Json2csvParser = require('json2csv').Parser;

const sellerId = "AM2N";

//sand Box
const Authorization = "18ccd7210f5b4def842fbe472737b581";
const SecretKey = "22E09D7A-40BB-4239-B8B4-585558B2023D";

// RequestURLs
const newEggFeedDataURL = proxyurl + `https://api.newegg.com/marketplace/can/datafeedmgmt/feeds/submitfeed?sellerid=${sellerId}&requesttype=ITEM_DATA`;
const updateNewEggItemUrl = proxyurl +`https://api.newegg.com/marketplace/can/contentmgmt/item/inventoryandprice?sellerid=${sellerId}`;
const getNewEggItemPriceUrl = proxyurl +`https://api.newegg.com/marketplace/can/contentmgmt/item/price?sellerid=${sellerId}`;
const getNewEggItemInventoryUrl = proxyurl +`https://api.newegg.com/marketplace/can/contentmgmt/item/inventory?sellerid=${sellerId}&version=304`




// const newEggFeedDataURL = 'https://api.newegg.com/marketplace/can/datafeedmgmt/feeds/submitfeed?sellerid=AK9J&requesttype=ITEM_DATA';
// const Authorization = "00de7c36e09f4be3bed804d36de6a290";
// const SecretKey = "88462C56-A315-42F5-B4CF-D1366C86CC01";

const headers = {
    'Content-Type': 'application/json',
    'Accept' : 'application/json',
    'SecretKey': SecretKey,
    'Authorization': Authorization
};


//List new sku on newEgg
export const createNewEggFeedItems = async (items) =>{


    const model = {
        activationMark: "True",
        subcategoryID: "1507",
        action: "Create Item"
    }

    // console.log(items)

    const newEggItems ={
        "NeweggEnvelope": {
            "Header": {
                "DocumentVersion": "1.0"
            },
            "MessageType": "BatchItemCreation",
            "Overwrite": "No",
            "Message": {
                "Itemfeed": {
                    "SummaryInfo": { "SubCategoryID": model.subcategoryID },
                    "Item": [

                    ]
                }
            }

        }
    }

    items.forEach(item => {
        //console.log(item)
        const newEggItem = makeActionOfNewEggItem(item, model, model.action)
        newEggItems.NeweggEnvelope.Message.Itemfeed.Item.push(newEggItem)

    });
    
    return await axios.post(newEggFeedDataURL, newEggItems ,{headers})
    
    // return newEggItems
}

//helper for making Actions for items on newEgg
const makeActionOfNewEggItem = (item,model,action) =>{

    const ProductName = item.Brand + " DESIGNER | " + item.Name + " " + item.ShortDescription.replace('.','');
    //console.log(item.Urls)
    const newItem = {
        // "Action": action,
        "Action": "Create Item",
        "BasicInfo": {
            "SellerPartNumber": item.Sku,
            "Manufacturer": "ASHLIN-BPG Marketing Inc.",
            "ManufacturerPartNumberOrISBN":  item.Upc,
            "WebsiteShortTitle": ProductName,
            "ProductDescription": item.ExtendedDescription,
            "ItemDimension": {
                "ItemLength": item.HeightCm,
                "ItemWidth": item.WidthCm,
                "ItemHeight": item.DepthCm
            },
            "ItemWeight": item.WeightGrams,
            "ItemCondition": "New",
            "PacksOrSets": "1",
            "ItemPackage": "OEM",
            "ShippingRestriction": "No",

            // "WalletsKeyholdersColor" : "black",
            // "WalletsKeyholdersGender": "MENS",
            // "WalletsKeyholdersSize": "5",
            // "WalletsKeyholdersType": "MENS", 


            "SellingPrice": item.Price,
            "Shipping": "Default",
            "Inventory": item.QtyOnHand,
            "ActivationMark": model.activationMark,
            "ItemImages": {
                "Image": {
                    "ImageUrl": item.Urls.HostingerUrls.MAIN ? item.Urls.HostingerUrls.MAIN[0] : item.Urls.GoogleDriveUrls.BASE[0],
                    "IsPrimary": "True"
                }
            },
            "SubCategoryProperty": {
                "CostumeAccessories": {
                "CostumeAccBrand": "String",
                "CostumeAccModel": "1-Bay",
                "CostumeAccGender": "Male",
                "CostumeAccAge": "Adult",
                "CostumeAccType": "Sets",
                "CostumeAccTheme": "Animals & Insects",
                "CostumeAccOccasion": "1st Birthday",
                "CostumeAccSize": '1.00\" L x 3.00\" W x 8.00\" H',
                "CostumeAccColor": "Aspen Black",
                "CostumeAccMaterial": "String",
                "CostumeAccCareInstructions": "String",
                //template
                "WalletsKeyholdersColor": "Aspen Black",
                "WalletsKeyholdersGender": "Male",
                "WalletsKeyholdersSize":"1.00\" L x 3.00\" W x 8.00\" H",
                "WalletsKeyholdersType": "Sets"
                },
                // template
                // "WalletsKeyholdersColor" : "Black",
                // "WalletsKeyholdersGender": "MENS",
                // "WalletsKeyholdersSize": "#5",
                // "WalletsKeyholdersType": "MENS", 
                "WalletsKeyholdersColor": "Aspen Black",
                "WalletsKeyholdersGender": "Male",
                "WalletsKeyholdersSize":"1.00\" L x 3.00\" W x 8.00\" H",
                "WalletsKeyholdersType": "Sets"
            }
               
        }
    }

    return newItem
}


// Cancell Order on NewEgg
const cancellOrderFromNewEgg = () => {

}


// Update Item Field for item listed on NewEgg
export const updateItemFieldFromNewEgg = async (skuNum, fieldObj) => {
    const upDatedItemBody = {
        "Type" : 1,
        "Value": skuNum,
        ...fieldObj
    }
    return await axios.put(updateNewEggItemUrl, upDatedItemBody ,{headers})

}


export const getItemPriceFromNewEgg = async (skuNum) => {
    const getItemPriceFromNewEggBody = {
        "Type": "1",
        "Value": skuNum
    }
    return await axios.post(getNewEggItemPriceUrl, getItemPriceFromNewEggBody ,{headers})
}

export const getItemInventoryFromNewEgg = async (skuNum) => {
    const getItemInventoryFromNewEggBody = {
        "Type": "1",
        "Value": skuNum
    }
    return await axios.post(getNewEggItemInventoryUrl, getItemInventoryFromNewEggBody ,{headers})
       
}

