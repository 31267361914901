import {baseURL, FEDEX_TEST_ENVIRONMENT,sleep, proxyurl } from "../requestHelper";
import axios from "axios";
import momentTimeZone from "moment-timezone";

const webServicesURL = FEDEX_TEST_ENVIRONMENT ? proxyurl + "https://wsbeta.fedex.com:443/web-services" : proxyurl+"https://ws.fedex.com:443/web-services";
const headsers = {
    headers:{ 'Content-Type': 'text/xml' }
};
export const getFedExCredentials = async()=>{
    if(FEDEX_TEST_ENVIRONMENT){
        return {
            'key':'tINEB6UGYDnFpzcG',
            'password':'L618D7pLX8nPloQCJY3FSPO4z',
            'account':'510087240',
            'meter':'119131152',

        }
    }  else{
        const res =  (await axios.get(`${baseURL}api/fedex`)).data;
        //console.log(res);
        return res;
    }
};

export const getTodayDate = ()=>{
    const d = new Date();
    const myTimezone = "America/Toronto";
    return momentTimeZone(d).tz(myTimezone).format();
};

const serviceMapper = (s)=>{
    switch (s) {
        case "FEDEX_GROUND":
            return {
                "name":s,
                "isExpress": false};
        case "GROUND_HOME_DELIVERY":
            return {
                "name": s,
                "isExpress": false};
        case "INTERNATIONAL_ECONOMY":
            return {
                "name": s,
                "isExpress": false};
        default:
            return {
                "name": s,
                "isExpress": true};

    }
};

export const getFedExRate = async (data, credentials,isExpress,ShippingMatrices,shippingMethodsFilter) =>{
    const today = getTodayDate();
    const xml = `<SOAP-ENV:Envelope xmlns:SOAP-ENV="http://schemas.xmlsoap.org/soap/envelope/" xmlns="http://fedex.com/ws/rate/v24">
                   <SOAP-ENV:Body>
                      <RateRequest>
                         <WebAuthenticationDetail>
                            <UserCredential>
                              <Key>${credentials.key}</Key>
                               <Password>${credentials.password}</Password>
                            </UserCredential>
                         </WebAuthenticationDetail>
                         <ClientDetail>
                            <AccountNumber>${credentials.account}</AccountNumber>
                            <MeterNumber>${credentials.meter}</MeterNumber>
                         </ClientDetail>
                         <TransactionDetail>
                            <CustomerTransactionId>${data.Order.id}</CustomerTransactionId>
                         </TransactionDetail>
                         <Version>
                            <ServiceId>crs</ServiceId>
                            <Major>24</Major>
                            <Intermediate>0</Intermediate>
                            <Minor>0</Minor>
                         </Version>
                         <RequestedShipment>
                            <ShipTimestamp>${today}</ShipTimestamp>
                         
                            <DropoffType>REGULAR_PICKUP</DropoffType>
                            <TotalWeight>
                               <Units>KG</Units>
                               <Value>${data.PackageInfo.Weight}</Value>
                            </TotalWeight>
                             <PreferredCurrency>CAD</PreferredCurrency>
                            <Shipper>
                               <Contact>
                                  <CompanyName>${data.ShipFrom.Name}</CompanyName>
                                  <PhoneNumber>${data.ShipFrom.ContactPhone}</PhoneNumber>
                               </Contact>
                               <Address>
                                  <StreetLines>${data.ShipFrom.AddressLine1}</StreetLines>
                                  <StreetLines>${data.ShipFrom.AddressLine2}</StreetLines>
                                  <City>${data.ShipFrom.City}</City>
                                  <StateOrProvinceCode>${data.ShipFrom.State}</StateOrProvinceCode>
                                  <PostalCode>${data.ShipFrom.Zip}</PostalCode>
                                  <CountryCode>${data.ShipFrom.CountryCode}</CountryCode>
                               </Address>
                            </Shipper>
                            <Recipient>
                                <Contact>
                                  <CompanyName>${data.ShipTo.Name}</CompanyName>
                                  <PhoneNumber>${data.ShipTo.ContactPhone}</PhoneNumber>
                               </Contact>
                               <Address>
                                  <StreetLines>${data.ShipTo.AddressLine1}</StreetLines>
                                  <StreetLines>${data.ShipTo.AddressLine2}</StreetLines>
                                  <City>${data.ShipTo.City}</City>
                                  <StateOrProvinceCode>${data.ShipTo.State}</StateOrProvinceCode>
                                  <PostalCode>${data.ShipTo.Zip}</PostalCode>
                                  <CountryCode>${data.ShipTo.CountryCode}</CountryCode>
                               </Address>
                            </Recipient>
                           
                            <RateRequestTypes>LIST</RateRequestTypes>
                            <PackageCount>1</PackageCount>
                            <RequestedPackageLineItems>
                               <GroupPackageCount>1</GroupPackageCount>
                               <Weight>
                                  <Units>KG</Units>
                                  <Value>${data.PackageInfo.Weight}</Value>
                               </Weight>
                               <Dimensions>
                                  <Length>${parseInt(data.PackageInfo.Length)}</Length>
                                  <Width>${parseInt(data.PackageInfo.Width)}</Width>
                                  <Height>${parseInt(data.PackageInfo.Height)}</Height>
                                  <Units>CM</Units>
                               </Dimensions>
                            </RequestedPackageLineItems>
                         </RequestedShipment>
                      </RateRequest>
                   </SOAP-ENV:Body>
                </SOAP-ENV:Envelope>`;

    try {
        const res = (await axios.post(webServicesURL,xml,headsers)).data;
        const document =  new DOMParser().parseFromString(res, 'application/xml');
        const shipments = [...document.getElementsByTagName('RateReplyDetails')];
        //console.log(shipments);
        const result =  shipments.map(q=>{

            const serviceCode = q.getElementsByTagName('ServiceType')[0].textContent;

            const service = ShippingMatrices.find(s=>s.ServiceCode === serviceCode);

            const amount = q.getElementsByTagName('TotalNetChargeWithDutiesAndTaxes')[0];
            if(service)
                return {
                    serviceCode: serviceCode,
                    serviceName: service.DisplayedName,
                    isExpress: service.IsExpress,
                    totalCost:amount.getElementsByTagName('Amount')[0].textContent,
                    deliverByDate:'',
                };
            return service;
        });
        //console.log(result);
        if(shippingMethodsFilter.length > 0){
            const conditions = shippingMethodsFilter.filter(s=>s.Carrier==="FEDEX").map(s=>s.ServiceCode);

            return  result.filter(r=>r && conditions.some(el=> r.serviceCode.includes(el)));

        }
        return result.filter(r=>r && r.isExpress === isExpress);
    }catch (e) {
        return [];
    }
};

export const createFedExShipment = async (data,credentials)=> {
    //console.log(data);
    const today = getTodayDate();
    let paymentInformation, dutiesPayment, total=0 , customsValue, insurance, reference;
    if (data.Use3rdPartyAccount) {
        paymentInformation = `<v23:ShippingChargesPayment>
                               <v23:PaymentType>THIRD_PARTY</v23:PaymentType>
                               <v23:Payor>
                                  <v23:ResponsibleParty>
                                     <v23:AccountNumber>${data.ThirdPartyAccountNum}</v23:AccountNumber>
                                  </v23:ResponsibleParty>
                               </v23:Payor>
                            </v23:ShippingChargesPayment>`;

        dutiesPayment = `<v23:DutiesPayment>
                                     <v23:PaymentType>THIRD_PARTY</v23:PaymentType>
                                     <v23:Payor>
                                        <v23:ResponsibleParty>
                                           <v23:AccountNumber>${data.ThirdPartyAccountNum}</v23:AccountNumber>
                                        </v23:ResponsibleParty>
                                     </v23:Payor>
                                  </v23:DutiesPayment>`;
    } else {
        paymentInformation = `<v23:ShippingChargesPayment>
                               <v23:PaymentType>SENDER</v23:PaymentType>
                               <v23:Payor>
                                  <v23:ResponsibleParty>
                                     <v23:AccountNumber>${credentials.account}</v23:AccountNumber>
                                  </v23:ResponsibleParty>
                               </v23:Payor>
                            </v23:ShippingChargesPayment>`;
        dutiesPayment = `<v23:DutiesPayment>
                                     <v23:PaymentType>SENDER</v23:PaymentType>
                                     <v23:Payor>
                                        <v23:ResponsibleParty>
                                           <v23:AccountNumber>${credentials.account}</v23:AccountNumber>
                                        </v23:ResponsibleParty>
                                     </v23:Payor>
                                  </v23:DutiesPayment>`;
    }

    if(data.DeclareValueInsurance) {
        let value = data.PackageInfo.TotalValue;
        value = value > 500? 500 : value;
        insurance = `<v23:TotalInsuredValue>
                        <v23:Currency>${data.PackageInfo.ItemDetails[0].Currency}</v23:Currency>
                        <v23:Amount>${value}</v23:Amount>
                     </v23:TotalInsuredValue>`;
    }else{
        insurance = '';
    }
    if(data.Reference1 !== ""){
        reference = `  <v23:CustomerReferences>
                  <v23:CustomerReferenceType>CUSTOMER_REFERENCE</v23:CustomerReferenceType>
                  <v23:Value>${data.Reference1}</v23:Value>
               </v23:CustomerReferences>`;
    }else{
        reference = '';
    }
    const Commodities = data.PackageInfo.ItemDetails.map(item=>{
        total += item.Amount;
        return `<v23:Commodities>
                    <v23:Name>${item.Name}</v23:Name>
                    <v23:NumberOfPieces>1</v23:NumberOfPieces>
                    <v23:Description>${item.Description}</v23:Description>
                    <v23:CountryOfManufacture>CA</v23:CountryOfManufacture>
                    <v23:Weight>
                        <v23:Units>KG</v23:Units>
                        <v23:Value>${(item.Weight/1000).toFixed(2)}</v23:Value>
                    </v23:Weight>
                    <v23:Quantity>${item.Qty}</v23:Quantity>
                    <v23:QuantityUnits>EA</v23:QuantityUnits>
                    <v23:UnitPrice>
                        <v23:Currency>${item.Currency}</v23:Currency>
                        <v23:Amount>${item.Amount}</v23:Amount>
                    </v23:UnitPrice>
                    <v23:CustomsValue>
                        <v23:Currency>${item.Currency}</v23:Currency>
                        <v23:Amount>${item.Amount}</v23:Amount>
                    </v23:CustomsValue>
                </v23:Commodities>`
    });
    if(Commodities.length > 0){
        customsValue = `<v23:CustomsValue>
                            <v23:Currency>${data.PackageInfo.ItemDetails[0].Currency}</v23:Currency>
                            <v23:Amount>${total}</v23:Amount>
                        </v23:CustomsValue>`;
    }else{
        customsValue = '';
    }
   //  console.log(customsValue);
   //  console.log(Commodities);
    const numberOfBox = parseInt(data.NumberOfBox);
    const sleepRes = await sleep(3000);
    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v23="http://fedex.com/ws/ship/v23">
                   <soapenv:Header/>
                   <soapenv:Body>
                      <v23:ProcessShipmentRequest>
                      
                         <v23:WebAuthenticationDetail>
                            <v23:UserCredential>
                              <v23:Key>${credentials.key}</v23:Key>
                               <v23:Password>${credentials.password}</v23:Password>
                            </v23:UserCredential>
                         </v23:WebAuthenticationDetail>
                         <v23:ClientDetail>
                            <v23:AccountNumber>${credentials.account}</v23:AccountNumber>
                            <v23:MeterNumber>${credentials.meter}</v23:MeterNumber>
                         </v23:ClientDetail>
                         <v23:TransactionDetail>
                            <v23:CustomerTransactionId>${data.Order.id}</v23:CustomerTransactionId>
                         </v23:TransactionDetail>
                         
                         <v23:Version>
                            <v23:ServiceId>ship</v23:ServiceId>
                            <v23:Major>23</v23:Major>
                            <v23:Intermediate>0</v23:Intermediate>
                            <v23:Minor>0</v23:Minor>
                         </v23:Version>
         
                         <v23:RequestedShipment>
                            <v23:ShipTimestamp>${today}</v23:ShipTimestamp>
                            <v23:DropoffType>REGULAR_PICKUP</v23:DropoffType>
              
                            <v23:ServiceType>${data.Service}</v23:ServiceType> 
             
                            <v23:PackagingType>YOUR_PACKAGING</v23:PackagingType>
                           <v23:TotalWeight>
                              <v23:Units>KG</v23:Units>
                              <v23:Value>${data.PackageInfo.Weight}</v23:Value>
                           </v23:TotalWeight>
            
                            ${insurance}                            

                            <v23:Shipper>    
                               <v23:Contact>
                                  <v23:PersonName>Nancy Taccone</v23:PersonName>
                                  <v23:CompanyName>Ashlin-BPG Marketing</v23:CompanyName>
                                  <v23:PhoneNumber>9058553027</v23:PhoneNumber>
                               </v23:Contact>
                               <v23:Address>
                                  <v23:StreetLines>2351 Royal Windsor Dr</v23:StreetLines>
                                  <v23:StreetLines>Unit #10</v23:StreetLines>
                                  <v23:City>Mississauga</v23:City>
                                  <v23:StateOrProvinceCode>ON</v23:StateOrProvinceCode>
                                  <v23:PostalCode>L5J4S7</v23:PostalCode>
                                  <v23:CountryCode>CA</v23:CountryCode>
                               </v23:Address>
                            </v23:Shipper>
                            
                            <v23:Recipient> 
                               <v23:Contact>
                                  <v23:PersonName>${data.ShipTo.ContactName}</v23:PersonName>
                                  <v23:CompanyName>${data.ShipTo.Name}</v23:CompanyName>
                                  <v23:PhoneNumber>${data.ShipTo.ContactPhone}</v23:PhoneNumber>
                               </v23:Contact>
                               <v23:Address>
                                  <v23:StreetLines>${data.ShipTo.AddressLine1}</v23:StreetLines>
                                  <v23:StreetLines>${data.ShipTo.AddressLine2}</v23:StreetLines>
                                  <v23:City>${data.ShipTo.City}</v23:City>
                                  <v23:StateOrProvinceCode>${data.ShipTo.State}</v23:StateOrProvinceCode>
                                  <v23:PostalCode>${data.ShipTo.Zip}</v23:PostalCode>
                                  <v23:CountryCode>${data.ShipTo.CountryCode}</v23:CountryCode>
                               </v23:Address>
                            </v23:Recipient>
                        
            
                            <v23:Origin>   
                               <v23:Contact>
                                  <v23:PersonName>${data.ShipFrom.ContactName}</v23:PersonName>
                                  <v23:CompanyName>${data.ShipFrom.Name}</v23:CompanyName>
                                  <v23:PhoneNumber>${data.ShipFrom.ContactPhone}</v23:PhoneNumber>
                               </v23:Contact>
                               <v23:Address>
                                  <v23:StreetLines>${data.ShipFrom.AddressLine1}</v23:StreetLines>
                                  <v23:StreetLines>${data.ShipFrom.AddressLine2}</v23:StreetLines>
                                  <v23:City>${data.ShipFrom.City}</v23:City>
                                  <v23:StateOrProvinceCode>${data.ShipFrom.State}</v23:StateOrProvinceCode>
                                  <v23:PostalCode>${data.ShipFrom.Zip}</v23:PostalCode>
                                  <v23:CountryCode>${data.ShipFrom.CountryCode}</v23:CountryCode>
                               </v23:Address>
                            </v23:Origin>
                
                            ${paymentInformation}
                            
                         
                            
                            
                               <v23:CustomsClearanceDetail>
                                  
                                  ${dutiesPayment}
                                  
                                  <v23:DocumentContent>NON_DOCUMENTS</v23:DocumentContent>
                                  
                                  
                                  ${customsValue}
              
                                  ${Commodities.join('')}
                                  
                                  
                               </v23:CustomsClearanceDetail>
                            

                            <v23:LabelSpecification>
                               <v23:LabelFormatType>COMMON2D</v23:LabelFormatType>
                               <v23:ImageType>PNG</v23:ImageType>
                            </v23:LabelSpecification>
              
              
                            <v23:PackageCount>${numberOfBox}</v23:PackageCount>
                            
                            
                            <v23:RequestedPackageLineItems>
                                <v23:SequenceNumber>1</v23:SequenceNumber>
                               <v23:Weight>
                                  <v23:Units>KG</v23:Units>
                                  <v23:Value>${(parseFloat(data.PackageInfo.Weight)/numberOfBox).toFixed(1)}</v23:Value>
                               </v23:Weight>
                               <v23:Dimensions>
                                  <v23:Length>${parseInt(data.PackageInfo.Length)}</v23:Length>
                                  <v23:Width>${parseInt(data.PackageInfo.Width)}</v23:Width>
                                  <v23:Height>${parseInt(data.PackageInfo.Height)}</v23:Height>
                                  <v23:Units>CM</v23:Units>
                               </v23:Dimensions>
                               <v23:ItemDescription>${data.PackageInfo.Description}</v23:ItemDescription>  
                               ${reference}
                            </v23:RequestedPackageLineItems>
               
                         </v23:RequestedShipment>
                      </v23:ProcessShipmentRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;
    //console.log(xml);
    const res = (await axios.post(webServicesURL, xml, headsers)).data;
    const shipmentDOM = new DOMParser().parseFromString(res, 'application/xml');
    //console.log(shipmentDOM);

    let tracking = shipmentDOM.getElementsByTagName('TrackingNumber');
    const label = shipmentDOM.getElementsByTagName('Image');
    if (tracking.length > 0 && label.length > 0 ) {
        tracking = tracking[0].textContent;
        const formId = shipmentDOM.getElementsByTagName('FormId');
        const labels = [label[0].textContent];

        if (numberOfBox > 1) {
            for (let i = 2; i <= numberOfBox; i++) {
                const addXML = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v23="http://fedex.com/ws/ship/v23">
                   <soapenv:Header/>
                   <soapenv:Body>
                      <v23:ProcessShipmentRequest>
                      
                         <v23:WebAuthenticationDetail>
                            <v23:UserCredential>
                              <v23:Key>${credentials.key}</v23:Key>
                               <v23:Password>${credentials.password}</v23:Password>
                            </v23:UserCredential>
                         </v23:WebAuthenticationDetail>
                         <v23:ClientDetail>
                            <v23:AccountNumber>${credentials.account}</v23:AccountNumber>
                            <v23:MeterNumber>${credentials.meter}</v23:MeterNumber>
                         </v23:ClientDetail>
                         <v23:TransactionDetail>
                            <v23:CustomerTransactionId>${data.Order.id}</v23:CustomerTransactionId>
                         </v23:TransactionDetail>
                         
                         <v23:Version>
                            <v23:ServiceId>ship</v23:ServiceId>
                            <v23:Major>23</v23:Major>
                            <v23:Intermediate>0</v23:Intermediate>
                            <v23:Minor>0</v23:Minor>
                         </v23:Version>
         
                         <v23:RequestedShipment>
                            <v23:ShipTimestamp>${today}</v23:ShipTimestamp>
                            <v23:DropoffType>REGULAR_PICKUP</v23:DropoffType>
              
                            <v23:ServiceType>${data.Service}</v23:ServiceType> 
             
                            <v23:PackagingType>YOUR_PACKAGING</v23:PackagingType>
                         
                                           

                            <v23:Shipper>    
                               <v23:Contact>
                                  <v23:PersonName>Nancy Taccone</v23:PersonName>
                                  <v23:CompanyName>Ashlin-BPG Marketing</v23:CompanyName>
                                  <v23:PhoneNumber>9058553027</v23:PhoneNumber>
                               </v23:Contact>
                               <v23:Address>
                                  <v23:StreetLines>2351 Royal Windsor Dr</v23:StreetLines>
                                  <v23:StreetLines>Unit #10</v23:StreetLines>
                                  <v23:City>Mississauga</v23:City>
                                  <v23:StateOrProvinceCode>ON</v23:StateOrProvinceCode>
                                  <v23:PostalCode>L5J4S7</v23:PostalCode>
                                  <v23:CountryCode>CA</v23:CountryCode>
                               </v23:Address>
                            </v23:Shipper>
                            
                            <v23:Recipient> 
                               <v23:Contact>
                                  <v23:PersonName>${data.ShipTo.ContactName}</v23:PersonName>
                                  <v23:CompanyName>${data.ShipTo.Name}</v23:CompanyName>
                                  <v23:PhoneNumber>${data.ShipTo.ContactPhone}</v23:PhoneNumber>
                               </v23:Contact>
                               <v23:Address>
                                  <v23:StreetLines>${data.ShipTo.AddressLine1}</v23:StreetLines>
                                  <v23:StreetLines>${data.ShipTo.AddressLine2}</v23:StreetLines>
                                  <v23:City>${data.ShipTo.City}</v23:City>
                                  <v23:StateOrProvinceCode>${data.ShipTo.State}</v23:StateOrProvinceCode>
                                  <v23:PostalCode>${data.ShipTo.Zip}</v23:PostalCode>
                                  <v23:CountryCode>${data.ShipTo.CountryCode}</v23:CountryCode>
                               </v23:Address>
                            </v23:Recipient>
                        
            
                            <v23:Origin>   
                               <v23:Contact>
                                  <v23:PersonName>${data.ShipFrom.ContactName}</v23:PersonName>
                                  <v23:CompanyName>${data.ShipFrom.Name}</v23:CompanyName>
                                  <v23:PhoneNumber>${data.ShipFrom.ContactPhone}</v23:PhoneNumber>
                               </v23:Contact>
                               <v23:Address>
                                  <v23:StreetLines>${data.ShipFrom.AddressLine1}</v23:StreetLines>
                                  <v23:StreetLines>${data.ShipFrom.AddressLine2}</v23:StreetLines>
                                  <v23:City>${data.ShipFrom.City}</v23:City>
                                  <v23:StateOrProvinceCode>${data.ShipFrom.State}</v23:StateOrProvinceCode>
                                  <v23:PostalCode>${data.ShipFrom.Zip}</v23:PostalCode>
                                  <v23:CountryCode>${data.ShipFrom.CountryCode}</v23:CountryCode>
                               </v23:Address>
                            </v23:Origin>
                
                            ${paymentInformation}
                            
                         
                            
                            
                               <v23:CustomsClearanceDetail>
                                  
                                  ${dutiesPayment}
                                  
                                  <v23:DocumentContent>NON_DOCUMENTS</v23:DocumentContent>
                                  
                                  
                                  ${customsValue}
              
                                  ${Commodities.join('')}
                                  
                                  
                               </v23:CustomsClearanceDetail>
                            

                            <v23:LabelSpecification>
                               <v23:LabelFormatType>COMMON2D</v23:LabelFormatType>
                               <v23:ImageType>PNG</v23:ImageType>
                            </v23:LabelSpecification>
              
                            <v23:MasterTrackingId> 
                                    <v23:TrackingNumber>${tracking}</v23:TrackingNumber>
                            </v23:MasterTrackingId>
                            <v23:PackageCount>${numberOfBox}</v23:PackageCount>
                            
                            
                            <v23:RequestedPackageLineItems>
                                <v23:SequenceNumber>${i}</v23:SequenceNumber>
                               <v23:Weight>
                                  <v23:Units>KG</v23:Units>
                                  <v23:Value>${(parseFloat(data.PackageInfo.Weight) / numberOfBox).toFixed(1)}</v23:Value>
                               </v23:Weight>
                               <v23:Dimensions>
                                  <v23:Length>${parseInt(data.PackageInfo.Length)}</v23:Length>
                                  <v23:Width>${parseInt(data.PackageInfo.Width)}</v23:Width>
                                  <v23:Height>${parseInt(data.PackageInfo.Height)}</v23:Height>
                                  <v23:Units>CM</v23:Units>
                               </v23:Dimensions>
                               <v23:ItemDescription>${data.PackageInfo.Description}</v23:ItemDescription>  
                               ${reference}
                            </v23:RequestedPackageLineItems>
               
                         </v23:RequestedShipment>
                      </v23:ProcessShipmentRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;

                const res = (await axios.post(webServicesURL, addXML, headsers)).data;
                const DOM = new DOMParser().parseFromString(res, 'application/xml');
                const label = DOM.getElementsByTagName('Image');
                labels.push(label[0].textContent)
            }
        }
        //console.log(labels);

        return {
            "tracking": tracking,
            "labels" : labels,
            "formId" : formId.length>0 ? formId[0].textContent : "",
            "service" : data.Service
        };
    }
    return undefined;
};

export const voidFedExShipment = async (data,credentials) =>{
    let formId = '';
    if(data.ShippingLabelId){
        formId = `<v23:FormId>${data.ShippingLabelId}</v23:FormId>`;
    }
    const xml = `<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:v23="http://fedex.com/ws/ship/v23">
                   <soapenv:Header/>
                   <soapenv:Body>
                      <v23:DeleteShipmentRequest>
                     
                         <v23:WebAuthenticationDetail>
                            <v23:UserCredential>
                              <v23:Key>${credentials.key}</v23:Key>
                               <v23:Password>${credentials.password}</v23:Password>
                            </v23:UserCredential>
                         </v23:WebAuthenticationDetail>
                         <v23:ClientDetail>
                            <v23:AccountNumber>${credentials.account}</v23:AccountNumber>
                            <v23:MeterNumber>${credentials.meter}</v23:MeterNumber>
                         </v23:ClientDetail>
                         <v23:TransactionDetail>
                            <v23:CustomerTransactionId>${data.BrightpearlOrderId}</v23:CustomerTransactionId>
                         </v23:TransactionDetail>
                         
                         
                         <v23:Version>
                            <v23:ServiceId>ship</v23:ServiceId>
                            <v23:Major>23</v23:Major>
                            <v23:Intermediate>0</v23:Intermediate>
                            <v23:Minor>0</v23:Minor>
                         </v23:Version>  

                         <v23:TrackingId>
                             <v23:TrackingIdType>FEDEX</v23:TrackingIdType>
                             ${formId}
                             <v23:TrackingNumber>${data.TrackingNumber}</v23:TrackingNumber>
                         </v23:TrackingId>
                         <v23:DeletionControl>DELETE_ALL_PACKAGES</v23:DeletionControl>
                      </v23:DeleteShipmentRequest>
                   </soapenv:Body>
                </soapenv:Envelope>`;

    const res = (await axios.post(webServicesURL, xml, headsers)).data;
    const voidDOM = new DOMParser().parseFromString(res, 'application/xml');
    //console.log(voidDOM);

    const result = voidDOM.getElementsByTagName('HighestSeverity')[0].textContent;
    const message = voidDOM.getElementsByTagName('Message')[0].textContent;
    return result === "SUCCESS" ? result : message ;
};