import React from 'react';
import TextInputParallel from '../../bootstrapComponent/textInputParallel';
import SelectInput from '../../bootstrapComponent/selectInput';
import SelectInputWithObject from '../../bootstrapComponent/selectInputWithObject';
import CheckBox from '../../bootstrapComponent/checkBox';
import SelectTrueFalse from '../../bootstrapComponent/selectInputWithTrueFalse';
import TextParallelWithAddition from '../../bootstrapComponent/textParallelWithAddition';
import SelectInputWithDefault from '../../bootstrapComponent/selectInputWithDefault';
import TextAreaShort from '../../bootstrapComponent/textAreaShort';
import TextArea from '../../bootstrapComponent/textArea'
import {countries} from '../../../Utils/country';

const AddDesignsModal = ({id,subFamilies,addDesign,onChange, addItem, Activate, ashlinDesign,
                             DeActivate,translate,shipmentCalculation, translateWithField,generateSEOSection})=>{

    const ashlinDesignCode = ashlinDesign.map(i=>i.designCode);
    const ashlinDesignName = ashlinDesign.map(i=>i.internalAshlinName);
    const ashlinSubFamilyCode = subFamilies.map(i=>{
        return{
            key:i.shortENG,
            value:i.subFamilyCode};
    });
    //console.log(ashlinSubFamilyCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="addDesignModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg " role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="addDesignModalLabel" style={{textAlign:'center !important'}}>Add A Design</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <TextInputParallel id='add_design_code' label={'Design Code'}
                                           value={addDesign.designCode} name='designCode' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_designHTSDescription' label={'Design HTS Description'}
                                           value={addDesign.designHTSDescription} name='designHTSDescription' onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectInputWithObject  id={'add_design_sub_family'} label={'Associated Sub-Family'} name={'pickedSubFamily'} width={'450px'}
                                      style={{fontWeight:'bold',marginBottom:'0'}}     value={ashlinSubFamilyCode}    onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectInput  id={'add_design_brand'} label={'Brand'} name={'brand'} width={'450px'}
                        style={{fontWeight:'bold',marginBottom:'0'}}     value={['6ixHide™','Ashlin®','PoppyBuzz™']}    onChange={(e)=>onChange(e,'addDesign')}/>
                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right'}}>
                                <p style={{fontSize:"15px", fontWeight:"bold"}}>Design-Service Flag:</p>
                            </div>
                            <div className={'col-8'} style={{paddingLeft:'0'}}>
                                <div className={'row'}>
                                <select style={{width:'50%',margin:'0 17px',fontSize:'20px !important',}} onChange={(e)=>onChange(e,'addDesign')} name={'designServiceFlag'} className='col'>
                                    <option value='design'>Design</option>
                                    <option value='service'>Service</option>
                                </select>
                                <CheckBox  value={addDesign.giftBox === 1}  id={'add-design-gitfBox'} style={{width:'40%'}} className={'col'}
                                           onChange={(e)=>onChange(e,'addDesign')} name={'giftBox'} label={'Gift Box'}/>
                                </div>
                            </div>
                        </div>
                        <TextInputParallel id='add_design_ashlin_name' label={'Internal Ashlin Name'}
                                           value={addDesign.internalAshlinName} name='internalAshlinName' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_general_name' label={'General Name'}
                                           value={addDesign.generalName} name='generalName' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_online_name' label={'Online Name'}
                                           value={addDesign.onlineName} name='onlineName' onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectInputWithDefault  id={'add_design_country'} label={'Country of Origin'} name={'country'} width={'450px'} defaultValue={addDesign.country}
                                      style={{fontWeight:'bold',marginBottom:'0'}}     value={countries}    onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectInputWithDefault  id={'add_design_shipping_packaging'} label={'Shipping Packaging'} name={'shippingPackaging'} width={'450px'} defaultValue={addDesign.shippingPackaging}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}     value={["BUBBLE PACK","CARTON"]}    onChange={(e)=>onChange(e,'addDesign')}/>
                        <hr/>
                        <div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                                </div>
                                <div className={'col-1'} >
                                    <button className={'btn-info'} style={{fontSize:'15px !important',marginLeft:'-35px'}} onClick={()=>{translate('addDesign')}}>TRANSLATE</button>
                                </div>
                                <div className={'col-4'}>
                                    <h5 style={{textAlign:'center'}}>FRENCH</h5>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center'}}>Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.shortENG}
                                              onChange={(e)=>onChange(e,'addDesign')} name={'shortENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.shortFR}
                                              onChange={(e)=>onChange(e,'addDesign')} name={'shortFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center',marginTop:'15px'}}>Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.longENG} rows="5"
                                              onChange={(e)=>onChange(e,'addDesign')} name={'longENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.longFR} rows="5"
                                              onChange={(e)=>onChange(e,'addDesign')} name={'longFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center'}}>Web Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.webENG} rows="5"
                                              onChange={(e)=>onChange(e,'addDesign')} name={'webENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.webFR} rows="5"
                                              onChange={(e)=>onChange(e,'addDesign')} name={'webFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center'}}>Trend Short Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.shortTrendENG}
                                              onChange={(e)=>onChange(e,'addDesign')} name={'shortTrendENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.shortTrendFR}
                                              onChange={(e)=>onChange(e,'addDesign')} name={'shortTrendFR'}>
                                    </textarea>
                                </div>
                            </div>
                            <div className={'row'} >
                                <div className={'col-3'}>
                                    <h5 style={{textAlign:'center',margin:'15px'}}>Trend Long Description</h5>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.longTrendENG} rows="5"
                                              onChange={(e)=>onChange(e,'addDesign')} name={'longTrendENG'}>
                                    </textarea>
                                </div>
                                <div className={'col-1'}>
                                </div>
                                <div className={'col-4'}>
                                    <textarea style={{width:'100%'}} value={addDesign.longTrendFR} rows="5"
                                              onChange={(e)=>onChange(e,'addDesign')} name={'longTrendFR'}>
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <TextInputParallel id='add_design_num_component' label={'Number of Component'}
                                           value={addDesign.numComponent} name='numComponent' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_imprint_height' label={'Imprint Height(cm)'}
                                           value={addDesign.imprintHeight} name='imprintHeight' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_imprint_width' label={'Imprint Width(cm)'}
                                           value={addDesign.imprintWidth} name='imprintWidth' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextParallelWithAddition id='add_design_product_height' label={'Product Height(cm)'}
                                           value={addDesign.productHeight} name='productHeight' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextParallelWithAddition id='add_design_product_width' label={'Product Width(cm)'}
                                                  value={addDesign.productWidth} name='productWidth' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextParallelWithAddition id='add_design_product_depth' label={'Product Depth(cm)'}
                                                  value={addDesign.productDepth} name='productDepth' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_product_weight' label={'Product Weight (grams)'}
                                           value={addDesign.productWeight} name='productWeight' onChange={(e)=>onChange(e,'addDesign')}/>

                        <SelectTrueFalse id={'add_design_flat'} label={'Can this item be shipped flat?'} name={'flat'} width={'450px'}
                                         defaultValue={addDesign.flat}  style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>

                        <button onClick={(e)=>shipmentCalculation(e,'addDesign')} className={'btn-block btn-success'}>Calculate Shipment </button>
                        <TextParallelWithAddition id='add_design_product_height' label={'Shipment Height(cm)'}
                                                  value={addDesign.shipmentHeight} name='shipmentHeight' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextParallelWithAddition id='add_design_product_width' label={'Shipment Width(cm)'}
                                                  value={addDesign.shipmentWidth} name='shipmentWidth' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextParallelWithAddition id='add_design_product_depth' label={'Shipment Depth(cm)'}
                                                  value={addDesign.shipmentDepth} name='shipmentDepth' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_product_weight' label={'Shipment Weight (grams)'}
                                           value={addDesign.shipmentWeight} name='shipmentWeight' onChange={(e)=>onChange(e,'addDesign')}/>

                        <hr/>
                        <TextInputParallel id='add_design_shoulder_drop_length' label={'Shoulder Drop Length(cm)'}
                                           value={addDesign.shoulderDropLength} name='shoulderDropLength' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_handle_total_length' label={'Handle Total Length(cm)'}
                                           value={addDesign.handleTotalLength} name='handleTotalLength' onChange={(e)=>onChange(e,'addDesign')}/>
                        <TextInputParallel id='add_design_card_slot' label={'Card Slots'}
                                           value={addDesign.cardSlots} name='cardSlots' onChange={(e)=>onChange(e,'addDesign')}/>

                        <SelectInputWithDefault  id={'add_design_notable_strap'} label={'Notable Strap Features'} name={'notableStrap'} width={'450px'} defaultValue={addDesign.notableStrap}
                                      style={{fontWeight:'bold',marginBottom:'0'}}
                                      value={['1 long','2 long','2 short','1 short','top handle strap','2 short straps + long strap','no strap']}    onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectInputWithDefault  id={'add_design_size_differentiation'} label={'Size Differentiation'} name={'sizeDifferentiation'} width={'450px'} defaultValue={addDesign.sizeDifferentiation}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}
                                                 value={['Mini','Small','Medium','Large','X-Large','XX-Large']}    onChange={(e)=>onChange(e,'addDesign')}/>

                        <SelectInputWithObject  id={'add_design_sub_family'} label={'Bill Compartment'} name={'billCompartment'} width={'450px'} defaultValue={addDesign.billCompartment}
                                                style={{fontWeight:'bold',marginBottom:'0'}}     value={[{key:"Double",value:2},{key:"Single",value:1},{key:"None",value:0}]}    onChange={(e)=>onChange(e,'addDesign')}/>


                        <SelectInputWithDefault  id={'add_design_closure'} label={'Closure'} name={'closure'} width={'450px'} defaultValue={addDesign.closure}
                                                 style={{fontWeight:'bold',marginBottom:'0'}}
                                                 value={['Zippered','Flap','Open','Button','Fold-Over','Drawstring','Velcro']}    onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectInput  id={'add_design_style'} label={'STYLE'} name={'style'} width={'450px'}
                                      style={{fontWeight:'bold',marginBottom:'0'}}
                                      value={['Bi-Fold','Tri-Fold','Clutch','Zippered Clutch','Wristlet','Wristlet with Strap',
                                          'Card Holder','Change Bag','Checkbook','Utility Kit','Drawstring','Bucket','Mini-Knapsack',
                                          'Coin Holder','Cosmetic Bag','Envelope','Evening Bag','Money Clip','Select Style']}    onChange={(e)=>onChange(e,'addDesign')}/>

                        <SelectTrueFalse id={'add_design_protective_feet'} label={'Protective Feet'} name={'protectiveFeet'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}  defaultValue={addDesign.protectiveFeet}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_inner_pocket'} label={'Inner Pocket'} name={'innerPocket'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}  defaultValue={addDesign.innerPocket}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_outside_pocket'} label={'Outside Pocket'} name={'outsidePocket'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}  defaultValue={addDesign.outsidePocket}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_dust_bag'} label={'Dust Bag Included'} name={'dustBagIncluded'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_authenticity_card'} label={'Authenticity Card Included'} name={'authenticityCardIncluded'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <hr/>
                        <SelectTrueFalse id={'add_design_monogrammed'} label={'Can this item be monogrammed?'} name={'monogrammed'} width={'450px'}
                                      style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_imprinted'} label={'Can this item be imprinted?'} name={'imprinted'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>

                        <SelectTrueFalse id={'add_design_strap'} label={'Does this item have s strap'} name={'strap'} width={'450px'}
                                         defaultValue={addDesign.strap}        style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_detachable'} label={'Is the strap detachable?'} name={'detachable'} width={'450px'}
                                         defaultValue={addDesign.detachable}  style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_enclosure'} label={'Does this item have a zipped enclosure?'} name={'enclosure'} width={'450px'}
                                         defaultValue={addDesign.enclosure}  style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>

                        <SelectTrueFalse id={'add_design_folded'} label={'Can this item be shipped folded?'} name={'folded'} width={'450px'}
                                         defaultValue={addDesign.folded}  style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <hr/>
                        <div className={'row'} >
                            <div className={'col-3'}>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                            </div>
                            <div className={'col-1'} >
                                <button className={'btn-info'} style={{fontSize:'15px !important',marginLeft:'-35px'}} onClick={()=>translateWithField('addDesign','options')}>TRANSLATE</button>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>FRENCH</h5>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 1</h5>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op1ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op1ENG'}>
                                </textarea>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op1FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op1FR'}>
                                </textarea>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 2</h5>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op2ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op2ENG'}>
                                </textarea>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op2FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op2FR'}>
                                </textarea>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 3</h5>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op3ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op3ENG'}>
                                </textarea>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op3FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op3FR'}>
                                </textarea>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 4</h5>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op4ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op4ENG'}>
                                </textarea>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op4FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op4FR'}>
                                </textarea>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>Option 5</h5>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op5ENG} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op5ENG'}>
                                </textarea>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%',reSize:'none'}} value={addDesign.op5FR} rows={'1'}
                                          onChange={(e)=>onChange(e,'addDesign')} name={'op5FR'}>
                                </textarea>
                            </div>
                        </div>
                        <hr/>


                        <label className={'bg-info text-white btn-block'} style={{fontSize:'20px',textAlign:'center'}}>SEO SECTION</label>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <button className={'btn-success'} onClick={(e)=>generateSEOSection(e,addDesign,'addDesign')}>Generate SEO</button>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                            </div>
                            <div className={'col-1'} >
                                <button className={'btn-info'} style={{fontSize:'15px !important',marginLeft:'-35px'}} onClick={()=>{translateWithField('addDesign','SEOSections')}}>TRANSLATE</button>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>FRENCH</h5>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Short Description</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%'}} value={addDesign.SEOShortENG !== undefined? addDesign.SEOShortENG:""}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOShortENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%'}} value={addDesign.SEOShortFR !== undefined? addDesign.SEOShortFR:""}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOShortFR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center',marginTop:'15px'}}>SEO Long Description</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%'}} value={addDesign.SEOLongENG !== undefined? addDesign.SEOLongENG:""} rows="5"
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOLongENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%'}} value={addDesign.SEOLongFR !== undefined? addDesign.SEOLongFR:""} rows="5"
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOLongFR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 1</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp1ENG !== undefined? addDesign.SEOOp1ENG:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp1ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp1FR !== undefined? addDesign.SEOOp1FR:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp1FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 2</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp2ENG !== undefined? addDesign.SEOOp2ENG:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp2ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp2FR !== undefined? addDesign.SEOOp2FR:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp2FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 3</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp3ENG !== undefined? addDesign.SEOOp3ENG:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp3ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp3FR !== undefined? addDesign.SEOOp3FR:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp3FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 4</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp4ENG !== undefined? addDesign.SEOOp4ENG:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp4ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp4FR !== undefined? addDesign.SEOOp4FR:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp4FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-3'}>
                                <h5 style={{textAlign:'center'}}>SEO Option 5</h5>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp5ENG !== undefined? addDesign.SEOOp5ENG:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp5ENG'}>
                                </TextAreaShort>
                            </div>
                            <div className={'col-1'}>
                            </div>
                            <div className={'col-4'}>
                                <TextAreaShort style={{width:'100%',reSize:'none'}} value={addDesign.SEOOp5FR !== undefined? addDesign.SEOOp5FR:""} rows={'1'}
                                               onChange={(e)=>onChange(e,'addDesign')} name={'SEOOp5FR'}>
                                </TextAreaShort>
                            </div>
                        </div>
                        <TextArea label={'SEO Meta Title'} value={addDesign.SEOMetaTitle !== undefined? addDesign.SEOMetaTitle:""} rows={'1'}
                                  onChange={(e)=>onChange(e,'addDesign')} name={'SEOMetaTitle'} />
                        <TextArea label={'SEO Meta Description'} value={addDesign.SEOMetaDescription !== undefined? addDesign.SEOMetaDescription:""}
                                  onChange={(e)=>onChange(e,'addDesign')} name={'SEOMetaDescription'} />
                        <TextArea label={'SEO Meta Keywords'} value={addDesign.SEOMetaKeywords !== undefined? addDesign.SEOMetaKeywords:""}
                                  onChange={(e)=>onChange(e,'addDesign')} name={'SEOMetaKeywords'} />
                        <TextArea label={'SEO Title'} value={addDesign.SEOTitle !== undefined? addDesign.SEOTitle:""} rows={'1'}
                                  onChange={(e)=>onChange(e,'addDesign')} name={'SEOTitle'} />
                        <TextArea label={'SEO Description'} value={addDesign.SEODescription !== undefined? addDesign.SEODescription:""}
                                  onChange={(e)=>onChange(e,'addDesign')} name={'SEODescription'} />





                        <SelectTrueFalse id={'add_design_display_web'} label={'Display on the website?'} name={'displayOnWeb'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <SelectTrueFalse id={'add_design_display_corporate'} label={'Display on the corporate site?'} name={'displayOnCorporate'} width={'450px'}
                                         style={{fontWeight:'bold',marginBottom:'0'}}      onChange={(e)=>onChange(e,'addDesign')}/>
                        <hr/>
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>addItem(e,'addDesign',{ashlinDesignCode,ashlinDesignName} )}>Add Design</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'addDesign','designCode')} >Deactivate Design</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'addDesign','designCode')}>Activate Design</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default AddDesignsModal;