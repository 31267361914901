import axios from "axios";
import { findBestMatch } from "string-similarity";
import { priceList } from "./ashlinDesign";
import { baseURL } from "./requestHelper";

const productionMode = true;
const ASI_URL = productionMode
  ? "https://productservice.asicentral.com/api/v5"
  : "https://productservice.sand-asicentral.com/api/v5";
const ASI_CONNECT_URL = "https://asiservice.asicentral.com/credit/v1";
const headers = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};
export const getCredentials = async () => {
  //PRODUCTION
  if (productionMode) {
    return (await axios.get(`${baseURL}api/asi`)).data;
  } else {
    //TESTING
    return {
      Asi: "37138",
      UserName: "intern1002@ashlinbpg.com",
      Password: "password1",
    };
  }
};
export const getAuth = async (credentials) => {
  try {
    return (await axios.post(`${ASI_URL}/login`, credentials, headers)).data
      .AccessToken;
  } catch (e) {
    return "Authentication Error";
  }
};
export const getAsiProduct = async (XID, token) => {
  headers.headers.AuthToken = token;
  try {
    return (await axios.get(`${ASI_URL}/products/${XID}`, headers)).data;
  } catch (e) {
    return null;
  }
};
export const postAsiProduct = async (data, token) => {
  headers.headers.AuthToken = token;
  try {
    return (await axios.post(`${ASI_URL}/products`, data, headers)).data;
  } catch (e) {
    return null;
  }
};
export const getAsiCategories = async () => {
  const res = await axios.get(
    "https://productservice.sand-asicentral.com/api/v5/lookup/categorieslist"
  );
  return res.data.categories;
};
export const getAsiCustomerCreditSummary = async (asiNum, token) => {
  headers.headers.AuthToken = token;
  try {
    return (
      await axios.get(
        `${ASI_CONNECT_URL}/creditsummary/?asiNumber=${asiNum}`,
        headers
      )
    ).data;
  } catch (e) {
    return null;
  }
};
export const getAsiProductTypes = async () => {
  const res = await axios.get(
    "https://productservice.sand-asicentral.com/api/v5/lookup/producttypes"
  );
  return res.data.productTypes;
};
export const generalPrices = [
  {
    IsBasePrice: false,
    Description: "Hot Foil Stamping",
    PriceIncludes: "4 Square Inchees (ie. 2x2 inches)",
    Sequence: 1,
    ServiceCharge: "Optional",
    UpchargeType: "Die Charge",
    UpchargeUsageType: "Per Order",
    Prices: [
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "98.45",
        NetCost: "78.76",
        DiscountCode: "V",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "CAD",
      },
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "93.75",
        NetCost: "75.0",
        DiscountCode: "V",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "USD",
      },
    ],
    PriceConfigurations: [
      {
        Criteria: "Imprint Method",
        Value: ["HOT FOIL STAMPING"],
      },
    ],
  },
  {
    IsBasePrice: false,
    Description: "Hot Foil Stamping - Over Sized Logos",
    PriceIncludes: "Per Square Inch",
    Sequence: 2,
    ServiceCharge: "Optional",
    UpchargeType: "Run Charge",
    UpchargeUsageType: "Per Quantity",
    Prices: [
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "5.0",
        NetCost: "5.0",
        DiscountCode: "Z",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "CAD",
      },
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "5.0",
        NetCost: "5.0",
        DiscountCode: "Z",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "USD",
      },
    ],
    PriceConfigurations: [
      {
        Criteria: "Imprint Method",
        Value: ["HOT FOIL STAMPING"],
      },
    ],
  },
  {
    IsBasePrice: false,
    Description: "Blind Debossing",
    PriceIncludes: "4 Square Inches (ie. 2x2 inches)",
    Sequence: 3,
    ServiceCharge: "Optional",
    UpchargeType: "Die Charge",
    UpchargeUsageType: "Per Order",
    Prices: [
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "98.45",
        NetCost: "78.76",
        DiscountCode: "V",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "CAD",
      },
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "93.75",
        NetCost: "75.0",
        DiscountCode: "V",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "USD",
      },
    ],
    PriceConfigurations: [
      {
        Criteria: "Imprint Method",
        Value: ["BLIND DEBOSSING"],
      },
    ],
  },
  {
    IsBasePrice: false,
    Description: "Blind Debossing - Over Sized Logos",
    PriceIncludes: "Per Square Inch",
    Sequence: 4,
    ServiceCharge: "Optional",
    UpchargeType: "Run Charge",
    UpchargeUsageType: "Per Quantity",
    Prices: [
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "5.0",
        NetCost: "5.0",
        DiscountCode: "Z",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "CAD",
      },
      {
        Sequence: 1,
        Qty: 1,
        ListPrice: "5.0",
        NetCost: "5.0",
        DiscountCode: "Z",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: false,
        Currency: "USD",
      },
    ],
    PriceConfigurations: [
      {
        Criteria: "Imprint Method",
        Value: ["BLIND DEBOSSING"],
      },
    ],
  },
  {
    IsBasePrice: false,
    Description: "Embroidery - Available Upon Request",
    Sequence: 5,
    ServiceCharge: "Optional",
    UpchargeType: "Imprint Option Charge",
    UpchargeUsageType: "Other",
    Prices: [
      {
        Sequence: 1,
        Qty: 0,
        ListPrice: "0.0",
        NetCost: "0.0",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: true,
        Currency: "CAD",
      },
      {
        Sequence: 1,
        Qty: 0,
        ListPrice: "0.0",
        NetCost: "0.0",
        PriceUnit: {
          ItemsPerUnit: "1",
        },
        IsQUR: true,
        Currency: "USD",
      },
    ],
    PriceConfigurations: [
      {
        Criteria: "Imprint Option",
        Value: ["Embroidery - Available Upon Request"],
        OptionName: "Embroidery - Available Upon Request",
      },
    ],
  },
];
const priceListMapper = (priceList, NetList, Currency) => {
  return [
    {
      Sequence: 1,
      Qty: 1,
      ListPrice: priceList.QTY1,
      NetCost: NetList.QTY1,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 2,
      Qty: 6,
      ListPrice: priceList.QTY6,
      NetCost: NetList.QTY6,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 3,
      Qty: 24,
      ListPrice: priceList.QTY24,
      NetCost: NetList.QTY24,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 4,
      Qty: 50,
      ListPrice: priceList.QTY50,
      NetCost: NetList.QTY50,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 5,
      Qty: 100,
      ListPrice: priceList.QTY100,
      NetCost: NetList.QTY100,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 6,
      Qty: 250,
      ListPrice: priceList.QTY250,
      NetCost: NetList.QTY250,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 7,
      Qty: 500,
      ListPrice: priceList.QTY500,
      NetCost: NetList.QTY500,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 8,
      Qty: 1000,
      ListPrice: priceList.QTY1000,
      NetCost: NetList.QTY1000,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
    {
      Sequence: 9,
      Qty: 2500,
      ListPrice: priceList.QTY2500,
      NetCost: NetList.QTY2500,
      DiscountCode: "R",
      PriceUnit: {
        ItemsPerUnit: "1",
      },
      IsQUR: false,
      Currency: Currency,
    },
  ];
};
export const pricesDetail = (basePrice, priceList) => {
  if (basePrice === 0) {
    return [
      {
        IsBasePrice: true,
        IsQUR: true,
        Description: "",
        PriceIncludes: "",
        Sequence: 1,
        Currency: "USD",
        Prices: [],
      },
    ];
  } else {
    return [
      {
        IsBasePrice: true,
        //"IsQUR": false,
        Description: "Blank Pricing, 20-25 business days",
        PriceIncludes: "Blank Product Only",
        Sequence: 0,
        //"Currency": "USD",
        Prices: [
          ...priceListMapper(
            priceList.Retail_CODED_STD_BLANK_USD,
            priceList.Distributor_NET_STD_BLANK_USD,
            "USD"
          ),
          ...priceListMapper(
            priceList.Retail_CODED_STD_BLANK_CAD,
            priceList.Distributor_NET_STD_BLANK_CAD,
            "CAD"
          ),
        ],
        PriceConfigurations: [
          {
            Criteria: "Production Time",
            Value: ["20-25 business days"],
          },
          {
            Criteria: "Imprint Option",
            Value: ["Blank Pricing"],
            OptionName: "Blank Pricing or Imprinted Pricing",
          },
        ],
      },
      {
        IsBasePrice: true,
        //"IsQUR": false,
        Description: "Blank Pricing, 10 business days",
        PriceIncludes: "Blank Product Only",
        Sequence: 6,
        //"Currency": "USD",
        Prices: [
          ...priceListMapper(
            priceList.Retail_CODED_RUSH_BLANK_USD,
            priceList.Distributor_NET_RUSH_BLANK_USD,
            "USD"
          ),
          ...priceListMapper(
            priceList.Retail_CODED_RUSH_BLANK_CAD,
            priceList.Distributor_NET_RUSH_BLANK_CAD,
            "CAD"
          ),
        ],
        PriceConfigurations: [
          {
            Criteria: "Rush Service",
            Value: ["10 business days"],
          },
          {
            Criteria: "Imprint Option",
            Value: ["Blank Pricing"],
            OptionName: "Blank Pricing or Imprinted Pricing",
          },
        ],
      },
      {
        IsBasePrice: true,
        //"IsQUR": false,
        Description: "Imprinted Pricing, 20-25 business days",
        PriceIncludes: "a Single Location Imprint",
        Sequence: 7,
        //"Currency": "USD",
        Prices: [
          ...priceListMapper(
            priceList.Retail_CODED_STD_IMP_USD,
            priceList.Distributor_NET_STD_IMP_USD,
            "USD"
          ),
          ...priceListMapper(
            priceList.Retail_CODED_STD_IMP_CAD,
            priceList.Distributor_NET_STD_IMP_CAD,
            "CAD"
          ),
        ],
        PriceConfigurations: [
          {
            Criteria: "Production Time",
            Value: ["20-25 business days"],
          },
          {
            Criteria: "Imprint Option",
            Value: ["Imprinted Pricing"],
            OptionName: "Blank Pricing or Imprinted Pricing",
          },
        ],
      },
      {
        IsBasePrice: true,
        //"IsQUR": false,
        Description: "Imprinted Pricing, 10 business days",
        PriceIncludes: "a Single Location Imprint",
        Sequence: 8,
        //"Currency": "USD",
        Prices: [
          ...priceListMapper(
            priceList.Retail_CODED_RUSH_IMP_USD,
            priceList.Distributor_NET_RUSH_IMP_USD,
            "USD"
          ),
          ...priceListMapper(
            priceList.Retail_CODED_RUSH_IMP_CAD,
            priceList.Distributor_NET_RUSH_IMP_CAD,
            "CAD"
          ),
        ],
        PriceConfigurations: [
          {
            Criteria: "Rush Service",
            Value: ["10 business days"],
          },
          {
            Criteria: "Imprint Option",
            Value: ["Imprinted Pricing"],
            OptionName: "Blank Pricing or Imprinted Pricing",
          },
        ],
      },
    ];
  }
};
export const deleteProduct = async (XID, token) => {
  headers.headers.AuthToken = token;
  return await axios.delete(`${ASI_URL}/product/${XID}`, headers);
};
export const oldModelToNewModel = (
  oldAsiProduct,
  ashlinProduct,
  asiProductTypes,
  newAsiProduct,
  AsiCategories
) => {
  //Confirmed time
  // console.log(oldAsiProduct);
  // console.log(newAsiProduct);
  const d = new Date(new Date().toString().split("GMT")[0] + " UTC");
  d.setFullYear(d.getFullYear() + 1);
  const oneYearLater = d.toISOString().slice(0, 19);
  const category = findBestMatch(
    ashlinProduct.AsiCategory.toUpperCase(),
    AsiCategories
  ).bestMatch.target;
  if (newAsiProduct === null) {
    const IDs = ashlinProduct.Sku.split("-").map((id) => id.trim());
    const Themes = ashlinProduct.AsiThemes.split(";")
      .filter((i) => i !== "")
      .map((i) => {
        return {
          Name: i.trim(),
        };
      });
    const Industries = ashlinProduct.AsiIndustries.split(";")
      .filter((i) => i !== "")
      .map((i) => {
        return {
          Name: i.trim(),
        };
      });
    const ProductType = findBestMatch(
      ashlinProduct.SuperFamily.toUpperCase(),
      asiProductTypes
    ).bestMatch.target;
    let name = `${IDs[0]}-${IDs[1]} ${ashlinProduct.ShortDescription}`;
    name.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
    oldAsiProduct.ExternalProductId = `${IDs[0]}-${IDs[1]}`;
    oldAsiProduct.Name = name.length > 60 ? name.substring(0, 59) : name;
    oldAsiProduct.AsiProdNo = "";
    oldAsiProduct.Inventory = {};
    oldAsiProduct.FOBPoints = [
      {
        Name: "Mississauga, ON L5J 4S7 Canada",
      },
    ];
    oldAsiProduct.LineNames = [];
    if (!productionMode) {
      oldAsiProduct.FOBPoints = [];
    }
    oldAsiProduct.PriceConfirmedThru = oneYearLater;
    if (oldAsiProduct.Categories.length === 0) {
      oldAsiProduct.Categories.push(category);
    }
    oldAsiProduct.ProductSKUs = [
      {
        SKU: ashlinProduct.Sku,
        Hazmat: "Unspecified",
        Configurations: [
          {
            Criteria: "Product Color",
            Value: [ashlinProduct.ColorFamily],
          },
        ],
      },
    ];
    oldAsiProduct.ProductNumbers = [
      {
        ProductNumber: ashlinProduct.Upc11,
        Configurations: [
          {
            Criteria: "Product Color",
            Value: [ashlinProduct.ColorFamily],
          },
        ],
      },
    ];
    oldAsiProduct.Images = [
      {
        ImageURL: oldAsiProduct.Images[0].ImageURL,
        Rank: 1,
        IsPrimary: true,
        Configurations: [
          {
            Criteria: "Product Color",
            Value: [ashlinProduct.ColorFamily],
          },
        ],
      },
    ];
    oldAsiProduct.ProductConfigurations = {
      Colors: [
        {
          Name: ashlinProduct.ColorFamily,
          Alias: ashlinProduct.ColorFamily,
        },
      ],
      Materials: [
        {
          Name: ashlinProduct.AsiMaterial,
          Alias: ashlinProduct.Material,
        },
      ],
      Sizes: {
        Dimension: {
          Values: [
            {
              Value: [
                {
                  Attribute: "Depth",
                  Value: ashlinProduct.DepthCm.toFixed(2),
                  Unit: "cm",
                },
                {
                  Attribute: "Width",
                  Value: ashlinProduct.WidthCm.toFixed(2),
                  Unit: "cm",
                },
                {
                  Attribute: "Height",
                  Value: ashlinProduct.HeightCm.toFixed(2),
                  Unit: "cm",
                },
              ],
            },
          ],
        },
      },
      Themes: Themes,
      Options: [
        {
          OptionType: "Imprint",
          Name: "Embroidery - Available Upon Request",
          Values: [
            {
              Value: "Embroidery - Available Upon Request",
            },
          ],
          AdditionalInformation: "",
          CanOnlyOrderOne: false,
          RequiredForOrder: false,
        },
        {
          OptionType: "Imprint",
          Name: "Blank Pricing or Imprinted Pricing",
          Values: [
            {
              Value: "Blank Pricing",
            },
            {
              Value: "Imprinted Pricing",
            },
          ],
          AdditionalInformation: "",
          CanOnlyOrderOne: false,
          RequiredForOrder: false,
        },
        {
          OptionType: "Imprint",
          Name: "HOT FOIL Color Options",
          Values: [
            {
              Value: "Gold",
            },
            {
              Value: "Silver",
            },
            {
              Value: "Custom Colors - Available Upon Request",
            },
          ],
          AdditionalInformation: "",
          CanOnlyOrderOne: false,
          RequiredForOrder: false,
        },
      ],
      Origins: [{ Name: ashlinProduct.Country.toUpperCase() }],
      TradeNames: [
        {
          Name: "Ashlin®",
        },
      ],
      ImprintMethods: [
        {
          Type: "FOIL STAMPED",
          Alias: "Hot Foil Stamping",
        },
        {
          Type: "EMBOSSED",
          Alias: "Blind Debossing",
        },
        {
          Type: "UNIMPRINTED",
          Alias: "UNIMPRINTED",
        },
        {
          Type: "EMBROIDERED",
          Alias: "EMBROIDERED",
        },
      ],
      ProductionTime: [
        {
          BusinessDays: "20-25",
          Details: "4-5 Weeks Delivery",
        },
      ],
      RushTime: {
        Available: true,
        Values: [
          {
            BusinessDays: "10",
            Details: "2-2.5 Weeks Delivery (If Possible)",
          },
        ],
      },
      ImprintSize: [
        {
          Value: `4 cm x 4 cm / 1.57" x 1.57"`,
        },
      ],
      ItemWeight: {
        Values: [
          {
            Value: [
              {
                Value: ashlinProduct.WeightGrams.toFixed(2),
                Unit: "grams",
              },
            ],
          },
        ],
      },
      Warranty: [
        {
          Name: "WARRANTY AVAILABLE",
        },
        {
          Name: "WARRANTY LENGTH",
          Comments: "1 year",
        },
        {
          Name: "WARRANTY TYPE",
          Comments: "Limited",
        },
      ],
      ShippingEstimates: {
        Dimensions: {
          Length: ashlinProduct.ShippableDepthCm.toFixed(2),
          Width: ashlinProduct.ShippableWidthCm.toFixed(2),
          Height: ashlinProduct.ShippableHeightCm.toFixed(2),
          LengthUnit: "cm",
          WidthUnit: "cm",
          HeightUnit: "cm",
        },
        Weight: [
          {
            Value: ashlinProduct.ShippableWeightGrams.toFixed(2),
            Unit: "grams",
          },
        ],
      },
      Industries: Industries,
    };
    oldAsiProduct.Hazmat = "Unspecified";
    oldAsiProduct.ProductType = ProductType;
    //oldAsiProduct.ProductType = "Drinkware";
    //oldAsiProduct.ProductType = "Bags & Luggage"
    //oldAsiProduct.ProductType = "School, Office, & Craft Supplies";
    return oldAsiProduct;
  } else {
    if (newAsiProduct.ProductSKUs === undefined) {
      newAsiProduct.ProductSKUs = [];
    }
    newAsiProduct.ProductSKUs.push({
      SKU: ashlinProduct.Sku,
      Hazmat: "Unspecified",
      Configurations: [
        {
          Criteria: "Product Color",
          Value: [ashlinProduct.ColorFamily],
        },
      ],
    });

    //Add product number
    if (newAsiProduct.ProductNumbers === undefined) {
      newAsiProduct.ProductNumbers = [];
    }
    newAsiProduct.ProductNumbers.push({
      ProductNumber: ashlinProduct.Upc11,
      Configurations: [
        {
          Criteria: "Product Color",
          Value: [ashlinProduct.ColorFamily],
        },
      ],
    });

    //Add new Color
    newAsiProduct.ProductConfigurations.Colors.push({
      Name: ashlinProduct.ColorFamily,
      Alias: ashlinProduct.ColorFamily,
    });

    //Add new Images
    newAsiProduct.Images.push({
      ImageURL: oldAsiProduct.Images[0].ImageURL,
      Rank: newAsiProduct.Images.length + 1,
      IsPrimary: false,
      Configurations: [
        {
          Criteria: "Product Color",
          Value: [ashlinProduct.ColorFamily],
        },
      ],
    });
    oldAsiProduct.FOBPoints = [
      {
        Name: "Mississauga, ON L5J 4S7 Canada",
      },
    ];
    oldAsiProduct.LineNames = [];

    if (!productionMode) {
      oldAsiProduct.FOBPoints = [];
    }
    return newAsiProduct;
  }
};
export const updateOldProduct = async (
  asiProductTypes,
  token,
  ashlinProduct,
  AsiCategories
) => {
  headers.headers.AuthToken = token;

  const oldAsiProduct = await getAsiProduct(ashlinProduct.Sku, token);
  const IDs = ashlinProduct.Sku.split("-").map((id) => id.trim());
  const newAsiProduct = await getAsiProduct(`${IDs[0]}-${IDs[1]}`, token);
  const updatedData = oldModelToNewModel(
    oldAsiProduct,
    ashlinProduct,
    asiProductTypes,
    newAsiProduct,
    AsiCategories
  );

  return await axios.post(`${ASI_URL}/product`, updatedData, headers);
};
export const postProduct = async (
  item,
  asiProducts,
  AsiCategories,
  asiProductTypes,
  token
) => {
  //Check if the product existing
  headers.headers.AuthToken = token;
  const prices = await priceList(item);
  const data = productModel(
    item,
    AsiCategories,
    prices,
    asiProductTypes,
    asiProducts
  );
  //console.log(JSON.stringify(data));
  try {
    return await axios.post(`${ASI_URL}/product`, data, headers);
  } catch (e) {
    return null;
  }
};

export const productModel = (
  item,
  AsiCategories,
  priceList,
  asiProductTypes,
  asiProducts
) => {
  //Confirmed time
  const d = new Date(new Date().toString().split("GMT")[0] + " UTC");
  d.setFullYear(d.getFullYear() + 1);
  const oneYearLater = d.toISOString().slice(0, 19);
  //console.log(item);

  //Category
  const category = findBestMatch(item.AsiCategory.toUpperCase(), AsiCategories)
    .bestMatch.target;

  //Themes
  const Themes = item.AsiThemes.split(";")
    .filter((i) => i !== "")
    .map((i) => {
      return {
        Name: i.trim(),
      };
    });
  const Industries = item.AsiIndustries.split(";")
    .filter((i) => i !== "")
    .map((i) => {
      return {
        Name: i.trim(),
      };
    });

  //ProductKeywords
  const ProductKeywords = item.MetaKeywords.includes(";")
    ? item.MetaKeywords.split(";")
        .map((k) => k.trim())
        .filter((i) => i !== "" && i.length < 31)
    : item.MetaKeywords.split("|").map((k) => k.trim());

  //ProductType
  let ProductType = findBestMatch(
    item.SuperFamily.toUpperCase(),
    asiProductTypes
  ).bestMatch.target;
  if (ProductType === "Test Product Type") {
    ProductType = "Other";
  }
  const IDs = item.Sku.split("-").map((id) => id.trim());

  const productCode = `${IDs[0]}-${IDs[1]} `;
  let shortDescription = `${item.ShortDescription.replace(
    /[&\/\\#,+()$~%.'":*?<>{}]/g,
    ""
  )}`;
  const materialCode = ` #${item.Material}`;
  let name;
  if (productCode.length + shortDescription.length + materialCode.length > 60) {
    const length = 57 - productCode.length - materialCode.length;
    shortDescription = shortDescription.substring(0, length);
    name = productCode + shortDescription + "..." + materialCode;
  } else {
    name = productCode + shortDescription + materialCode;
  }

  const summary = item.ShortDescription.replace(".", "");
  //console.log(name);
  const priceDetail = pricesDetail(item.Price, priceList);
  // const priceDetail = item.Price !==0 ? [
  //     {
  //         "IsBasePrice": true,
  //         "IsQUR": false,
  //         "Description": "Blank Pricing, 20-25 business days",
  //         "PriceIncludes": "Blank Product Only",
  //         "Sequence": 0,
  //         "Currency": "USD",
  //         "Prices": priceListMapper(priceList.Retail_CODED_STD_BLANK_USD,priceList.Distributor_NET_STD_BLANK_USD),
  //         "PriceConfigurations": [
  //             {
  //                 "Criteria": "Production Time",
  //                 "Value": [
  //                     "20-25 business days"
  //                 ]
  //             },
  //             {
  //                 "Criteria": "Imprint Option",
  //                 "Value": [
  //                     "Blank Pricing"
  //                 ],
  //                 "OptionName": "Blank Pricing or Imprinted Pricing"
  //             }
  //         ]
  //     },
  //     {
  //         "IsBasePrice": true,
  //         "IsQUR": false,
  //         "Description": "Blank Pricing, 10 business days",
  //         "PriceIncludes": "Blank Product Only",
  //         "Sequence": 6,
  //         "Currency": "USD",
  //         "Prices": priceListMapper(priceList.Retail_CODED_RUSH_BLANK_USD,priceList.Distributor_NET_RUSH_BLANK_USD),
  //         "PriceConfigurations": [
  //             {
  //                 "Criteria": "Rush Service",
  //                 "Value": [
  //                     "10 business days"
  //                 ]
  //             },
  //             {
  //                 "Criteria": "Imprint Option",
  //                 "Value": [
  //                     "Blank Pricing"
  //                 ],
  //                 "OptionName": "Blank Pricing or Imprinted Pricing"
  //             }
  //         ]
  //     },
  //     {
  //         "IsBasePrice": true,
  //         "IsQUR": false,
  //         "Description": "Imprinted Pricing, 20-25 business days",
  //         "PriceIncludes": "a Single Location Imprint",
  //         "Sequence": 7,
  //         "Currency": "USD",
  //         "Prices": priceListMapper(priceList.Retail_CODED_STD_IMP_USD,priceList.Distributor_NET_STD_IMP_USD),
  //         "PriceConfigurations": [
  //             {
  //                 "Criteria": "Production Time",
  //                 "Value": [
  //                     "20-25 business days"
  //                 ]
  //             },
  //             {
  //                 "Criteria": "Imprint Option",
  //                 "Value": [
  //                     "Imprinted Pricing"
  //                 ],
  //                 "OptionName": "Blank Pricing or Imprinted Pricing"
  //             }
  //         ]
  //     },
  //     {
  //         "IsBasePrice": true,
  //         "IsQUR": false,
  //         "Description": "Imprinted Pricing, 10 business days",
  //         "PriceIncludes": "a Single Location Imprint",
  //         "Sequence": 8,
  //         "Currency": "USD",
  //         "Prices": priceListMapper(priceList.Retail_CODED_RUSH_IMP_USD,priceList.Distributor_NET_RUSH_IMP_USD),
  //         "PriceConfigurations": [
  //             {
  //                 "Criteria": "Rush Service",
  //                 "Value": [
  //                     "10 business days"
  //                 ]
  //             },
  //             {
  //                 "Criteria": "Imprint Option",
  //                 "Value": [
  //                     "Imprinted Pricing"
  //                 ],
  //                 "OptionName": "Blank Pricing or Imprinted Pricing"
  //             }
  //         ]
  //     }] : [ {
  //     "IsBasePrice": true,
  //     "IsQUR": true,
  //     "Description": "",
  //     "PriceIncludes": "",
  //     "Sequence": 1,
  //     "Currency": "USD",
  //     "Prices": []
  // }];
  const productSKUsDetail =
    asiProducts !== null
      ? asiProducts.ProductSKUs.map((sku) => {
          return {
            SKU: sku.SKU,
            Hazmat: "UNSPECIFIED",
            Configurations: sku.Configurations,
          };
        })
      : [];
  const productNumbersDetail =
    asiProducts !== null ? asiProducts.ProductNumbers : [];
  const imagesDetail = asiProducts !== null ? asiProducts.Images : [];
  const colorsDetail =
    asiProducts !== null ? asiProducts.ProductConfigurations.Colors : [];
  return {
    ExternalProductId: `${IDs[0]}-${IDs[1]}`,
    Name: name,
    Description: item.ExtendedDescription,
    Summary: summary.length > 130 ? summary.substring(0, 130) : summary,
    // "AsiProdNo": "RFID5728-07-01", ??????
    //"ProductDataSheet"//  ??
    //"SKU": "mens-polo2", //  ??

    // "Inventory": {
    //     "InventoryLink": "",
    //     "InventoryStatus": "IN STOCK",
    //     "InventoryQuantity": ""
    //     //"InventoryQuantity": "" NOT use
    // },
    // "BreakoutByPrice": false,
    CanShipInPlainBox: false,
    // "SEOFlag": false, NOT use
    DistributorViewOnly: false,
    ItemAssembled: true,
    // "SupplierContact": true,
    // "LineNames": [],

    // "Catalogs": [
    //     {
    //         "CatalogName": "2017 Ashlin Executive Catalog",
    //         "CatalogPage": ""
    //     }
    // ],

    DistributorOnlyComments:
      "Our products are border and NAFTA friendly and are easily imported into USA Mexico and European Economic Community Countries.Prices do not include import duties or taxes. For other import requirements please call and we will assist you.",
    //"ProductDisclaimer": "Not responsible for color fade or shrinking after wash",   //ADD LATER
    AdditionalProductInfo:
      "Our products are border and NAFTA friendly and are easily imported into USA Mexico and European Economic Community Countries.Prices do not include import duties or taxes. For other import requirements please call and we will assist you.",
    AdditionalShippingInfo: `May ship Ground or Air. Shippable Weight: ${item.WeightGrams} grams`,
    //"AdditionalImprintInfo": "dark shirts require white base before imprint colors", //Save for later
    PriceConfirmedThru: oneYearLater,
    CanOrderLessThanMinimum: false,
    //"UpcCode": "123456",    NOT USE
    //"DeliveryOption": "None", //For future use
    // "IndustrySegment": "Promotional", //For future use
    // "Availability":[
    //     {
    //         "ParentCriteria":"Product Color",
    //         "ChildCriteria":"Size",
    //         "AvailableVariations":[
    //             {
    //                 "ParentValue":[
    //                     item.ColorFamily
    //                 ],
    //                 "ChildValue":[
    //                     {
    //                         "Attribute":"Depth",
    //                         "Value":item.DepthCm,
    //                         "Unit":"cm"
    //                     },
    //                     {
    //                         "Attribute":"Width",
    //                         "Value":item.WidthCm,
    //                         "Unit":"cm"
    //                     },
    //                     {
    //                         "Attribute":"Height",
    //                         "Value":item.HeightCm,
    //                         "Unit":"cm"
    //                     }
    //                 ]
    //             }
    //         ]
    //     }
    // ],
    ProductKeywords: ["Award Programs", ...ProductKeywords],
    FOBPoints: [
      {
        Name: "Mississauga, ON L5J 4S7 Canada",
      },
    ],
    Categories: [category],
    // "ComplianceCerts": [     //NOT NOW
    //     "OSHA"
    // ],
    // "SafetyWarnings": [
    //     "CHOKING HAZARD - SMALL PARTS""Images":
    // ]

    Images: [
      {
        ImageURL: item.Urls.GoogleDriveUrls.SMALL[0],
        Rank: imagesDetail.length + 1,
        IsPrimary: true,
        Configurations: [
          {
            Criteria: "Product Color",
            Value: [item.ColorFamily],
          },
        ],
      },
      ...imagesDetail,
    ],
    PriceGrids: [...generalPrices, ...priceDetail],
    ProductSKUs: [
      {
        SKU: item.Sku,
        Hazmat: "Unspecified",
        Configurations: [
          {
            Criteria: "Product Color",
            Value: [item.ColorFamily],
          },
        ],
      },
      ...productSKUsDetail,
    ],
    ProductNumbers: [
      {
        ProductNumber: item.Upc11,
        Configurations: [
          {
            Criteria: "Product Color",
            Value: [item.ColorFamily],
          },
        ],
      },
      ...productNumbersDetail,
    ],
    PriceType: "L", //? Want both??

    // "InventoryAPI": false,
    // "OrderCreationAPI": false,
    // "OrderStatusAPI": false,
    // "PricingAPI": false,
    ProductConfigurations: {
      // "ImprintColors": {
      //     "Type": "COLR",
      //     "Values": [
      //         {
      //             "Name": "Tea Pink",
      //         },
      //         {
      //             "Name": "Wooden Classic Gold",
      //         }
      //     ]
      // },
      // "Samples": {
      //     "SpecSampleAvailable": true,
      //     "SpecInfo": "Comments about Spec Sample",
      //     "ProductSampleAvailable": true,
      //     "ProductSampleInfo": "Comments about Product Sample"
      // },
      Colors: [
        {
          Name: item.ColorFamily,
          Alias: item.ColorFamily,
        },
        ...colorsDetail,
      ],
      Materials: [
        {
          Name: item.AsiMaterial,
          Alias: item.Material,
        },
      ],
      Sizes: {
        Dimension: {
          Values: [
            {
              Value: [
                {
                  Attribute: "Depth",
                  Value: item.DepthCm.toFixed(2),
                  Unit: "cm",
                },
                {
                  Attribute: "Width",
                  Value: item.WidthCm.toFixed(2),
                  Unit: "cm",
                },
                {
                  Attribute: "Height",
                  Value: item.HeightCm.toFixed(2),
                  Unit: "cm",
                },
              ],
            },
          ],
        },
      },
      // "Shapes": [
      //     {
      //         "Name": "OVAL/HEART",
      //         "CustomerOrderCode": "SHAP-OVL-444"
      //     }
      // ],
      Themes: Themes,
      Options: [
        {
          OptionType: "Imprint",
          Name: "Embroidery - Available Upon Request",
          Values: [
            {
              Value: "Embroidery - Available Upon Request",
            },
          ],
          AdditionalInformation: "",
          CanOnlyOrderOne: false,
          RequiredForOrder: false,
        },
        {
          OptionType: "Imprint",
          Name: "Blank Pricing or Imprinted Pricing",
          Values: [
            {
              Value: "Blank Pricing",
            },
            {
              Value: "Imprinted Pricing",
            },
          ],
          AdditionalInformation: "",
          CanOnlyOrderOne: false,
          RequiredForOrder: false,
        },
        {
          OptionType: "Imprint",
          Name: "HOT FOIL Color Options",
          Values: [
            {
              Value: "Gold",
            },
            {
              Value: "Silver",
            },
            {
              Value: "Custom Colors - Available Upon Request",
            },
          ],
          AdditionalInformation: "",
          CanOnlyOrderOne: false,
          RequiredForOrder: false,
        },
      ],
      Origins: [{ Name: item.Country.toUpperCase() }],
      TradeNames: [
        {
          Name: "Ashlin®",
        },
      ],
      // "Packaging": [
      //     {
      //         "Name": "BULK"
      //     },
      //     {
      //         "Name": "POLY BAG"
      //     },
      //     {
      //         "Name": "GIFT BOXES"
      //     }
      // ],
      ImprintMethods: [
        {
          Type: "FOIL STAMPED",
          Alias: "Hot Foil Stamping",
        },
        {
          Type: "EMBOSSED",
          Alias: "Blind Debossing",
        },
        {
          Type: "UNIMPRINTED",
          Alias: "UNIMPRINTED",
        },
        {
          Type: "EMBROIDERED",
          Alias: "EMBROIDERED",
        },
      ],
      ProductionTime: [
        {
          BusinessDays: "20-25",
          Details: "4-5 Weeks Delivery",
        },
      ],
      RushTime: {
        Available: true,
        Values: [
          {
            BusinessDays: "10",
            Details: "2-2.5 Weeks Delivery (If Possible)",
          },
        ],
      },
      ImprintSize: [
        {
          Value: `4 cm x 4 cm / 1.57" x 1.57"`,
        },
      ],
      ItemWeight: {
        Values: [
          {
            Value: [
              {
                Value: item.WeightGrams.toFixed(2),
                Unit: "grams",
              },
            ],
          },
        ],
      },
      Warranty: [
        {
          Name: "WARRANTY AVAILABLE",
        },
        {
          Name: "WARRANTY LENGTH",
          Comments: "1 year",
        },
        {
          Name: "WARRANTY TYPE",
          Comments: "Limited",
        },
      ],
      ShippingEstimates: {
        // "NumberOfItems": [
        //     {
        //         "Value": "36",
        //         "Unit": "per Case"
        //     }
        // ],
        Dimensions: {
          Length: item.ShippableDepthCm.toFixed(2),
          Width: item.ShippableWidthCm.toFixed(2),
          Height: item.ShippableHeightCm.toFixed(2),
          LengthUnit: "cm",
          WidthUnit: "cm",
          HeightUnit: "cm",
        },
        Weight: [
          {
            Value: item.ShippableWeightGrams.toFixed(2),
            Unit: "grams",
          },
        ],
      },
      Industries: Industries,
    },
    Hazmat: "Unspecified",
    ProductType: ProductType,
    MarketSegments: ["CANALL", "USAALL"],
    //"ProductType" : "School, Office, & Craft Supplies"
  };
};
const existProductModel = (newItem, asiProducts) => {
  //Add new Availability
  // asiProducts.Availability.AvailableVariations.ParentValue.push({
  //     "ParentCriteria":"Product Color",
  //     "ChildCriteria":"Size",
  //     "AvailableVariations":[
  //         {
  //             "ParentValue":[
  //
  //             ],
  //             "ChildValue":[
  //                 {
  //                     "Attribute":"Depth",
  //                     "Value":newItem.DepthCm,
  //                     "Unit":"cm"
  //                 },
  //                 {
  //                     "Attribute":"Width",
  //                     "Value":newItem.WidthCm,
  //                     "Unit":"cm"
  //                 },
  //                 {
  //                     "Attribute":"Height",
  //                     "Value":newItem.HeightCm,
  //                     "Unit":"cm"
  //                 }
  //             ]
  //         }
  //     ]
  // });

  //Add product Sku
  asiProducts.ProductSKUs.push({
    SKU: newItem.Sku,
    Hazmat: "Unspecified",
    Configurations: [
      {
        Criteria: "Product Color",
        Value: [newItem.ColorFamily],
      },
    ],
  });

  //Add product number
  asiProducts.ProductNumbers.push({
    ProductNumber: newItem.Upc11,
    Configurations: [
      {
        Criteria: "Product Color",
        Value: [newItem.ColorFamily],
      },
    ],
  });

  //Add new Color
  asiProducts.ProductConfigurations.Colors.push({
    Name: newItem.ColorFamily,
    Alias: newItem.ColorFamily,
  });

  //Add new Images
  asiProducts.Images.push({
    ImageURL: newItem.Urls.GoogleDriveUrls.BASE[0],
    Rank: asiProducts.Images.length + 1,
    IsPrimary: false,
    Configurations: [
      {
        Criteria: "Product Color",
        Value: [newItem.ColorFamily],
      },
    ],
  });
  return asiProducts;
};
