import React, { Component } from 'react';
import ReactTable from "react-table";
import {
    getExceptionRecords,
    deleteExceptionById,
    fixExceptionById
} from '../../../Utils/ashlinDesign';
import { button} from 'bootstrap';
class ErrorLogTable extends Component{


    async componentDidMount(){
        const exceptionRecordList = await getExceptionRecords();
        this.setState({
            exceptionRecordList
        })
    }

    refreshPage = async() =>{
        const exceptionRecordList = await getExceptionRecords();
        this.setState({
            exceptionRecordList
        })
    }

    handleDeleteExceptionRecord = async (record) => {
        await deleteExceptionById(record);
        this.refreshPage();
    }

    handleFixExceptionRecord = async (record) => {
        await fixExceptionById(record);
        this.refreshPage();
    }


    constructor(props){
        super(props)
        this.state = {
            exceptionRecordList : [],
            //Table columns
            columns: [
                {
                    Header: 'Id',
                    id: 'Id',
                    accessor: row => {
                        return row.Id.toUpperCase();
                    } 
                },
                {
                    Header: 'ExceptionDate',
                    accessor: 'ExceptionDate' // String-based value accessors!
                },
                {
                    Header: 'ExceptionDetail',
                    accessor: 'ExceptionDetail' // String-based value accessors!
                },
                {
                    Header: 'ExceptionFunctionName',
                    accessor: 'ExceptionFunctionName' // String-based value accessors!
                },
                {
                    Header: 'Status',
                    accessor: 'Status' // String-based value accessors!
                },
                {
                    Header: 'Action',
                    sortable: false,
                    filterable: false,
                    Cell: (pro) =>
                        <button className='btn btn-warning' onClick={()=>this.handleFixExceptionRecord(pro.original)}>
                            Fix
                        </button>
                },
                {
                    Header: 'Delete',
                    sortable: false,
                    filterable: false,
                    Cell: (pro) =>
                        <button className='btn btn-danger' onClick={()=>this.handleDeleteExceptionRecord(pro.original)}>
                            Delete
                        </button>
                }
            ]
        }
    }

    render(){
        return(
            <div>
                <h1>building it</h1>
            {/* <ReactTable
                data={this.state.exceptionRecordList}
                columns={this.state.columns}
                minRows={1}
            />
            <h3 style={{color: "red"}}>Total records: {this.state.exceptionRecordList.length}</h3> */}
            </div>
        )
    }



}

export default ErrorLogTable;