import React, { Component, useState } from 'react';
import ReactTable from "react-table";
import "react-table/react-table.css";
import MainSideBar from '../sideBar/MainSideBar';
import ProformaInvoice from './components/ProformaInvoice';
import {
    getAllSKUNumberList,
    getAshlinManualOrders,
    getBrightpearlChannelInfos,
    getCompanyInfoById,
    getDesignByCode,
    getEmailConfigurationById,
    updateAshlinManualOrder,
    getSignatureById,
    getHtsUs, postOrderRecords,
    getSkuWithHts, getAshlinMaualOrderItems,
    getHtsCa, sendPDFEmail, getHTSDetail, getDesignsPacking, getCanadaPostLabel, getShippingMatrices
} from '../../Utils/ashlinDesign';

import { handleError } from "../../Utils/ErrorHandler/ErrorHandler";

import CreateNewManualOrderModal from './components/createNeworder';
import {
    getBPheader,
    getTaxCodes,
    getAllWarehouses,
    manualOrderCarrierMapping,
    getOrderDetailsByOrderID,
    updateOrderStatus,
    getUSCustomerFEINNumber,
    updateShipmentMethodAndDeliveryDate,
    ProvinceMapper,
    postShippedEvent,
    createGoodsOutNote,
    searchGoodsOutNote,
    updateGoodsOutNote,
    shippingMethods,
    channelsMapping, checkOrderExpress
} from "../../Utils/brightpearl";
import { getAuth, getCredentials } from "../../Utils/asi";
import ViewManualOrderDetailModal from "./components/viewManualOrderDetailModal";
import CreateManualOrderShipment from "./components/createManualOrderShipment";
import ConfirmManualOrderShipment from "./components/confirmManualOrderShipment";
import $, { proxy } from "jquery";
import {
    generateCanadaPostGetRatesXML, getCanadaPostToken, voidCanadaPostShipment, postCanadaPostEndOfDay, getCPManifestLinkBetweenDateRange,
    getCanadaPostRates, generateCanadaPostCreateShipment, createCanadaPostContactShipment, getCanadaPostManifestFromArtifact
} from '../../Utils/ShippingService/CanadaPost';
import _ from 'lodash';
import PullOrderFromBrightpearl from './components/pullBrightPearlOrder';
import axios from "axios/index";
import { createChitChatsShipment, getChitChatsShipment } from "../../Utils/chitChats";
import { getCanparRates, getCanparCredentials, processCanparShipment, voidCanparShipment, postCanparEndOfDay, getCanparManifest } from '../../Utils/ShippingService/Canpar'
import {
    currentDateTime, sleep,
    getBlanceByWarehouseCode, getOrderId,
    sendOrderToLeanSupply, base64ToPDF, postLabel, postManifest,
    SubmitOrderModel, submittedOrdersURL, base64ToNewTab, ENABLE_BRIGHTPEARL, ENABLE_LEANSUPPLY,
    getGoogleMapInfo,
    proxyurl
} from "../../Utils/requestHelper";
import async from "async";
import { getUPSCredentials, getUPSPackageRate, createUPSShipment, voidUPSShipment } from '../../Utils/ShippingService/UPS';
import { getFedExCredentials, getFedExRate, createFedExShipment, voidFedExShipment } from '../../Utils/ShippingService/FedEx';
import { getPurolatorCredentials, getPurolatorRates, createPurolatorShipment, voidPurolatorShipment, voidPickUp } from '../../Utils/ShippingService/Purolator';
import { message, Form, Modal, DatePicker, Select, Row, Col, Tabs, Button } from "antd";
import moment from 'moment';
import PDFProvider from '../../js/pdfProvider';
import { saveSync } from 'save-file'
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;
class AshlinManualOrders extends Component {
    _mounted = false;
    constructor(props) {
        super(props);
        this.state = {
            ashlinManualOrderList: [],
            skuNumberList: [],
            BrightpearlChannelInfos: [],
            brightPearlWarehouses: [],
            taxCodes: [],
            brightpearlHeader: "",
            isDownLoading: false,
            asiToken: "",

            HtsCa: [],
            HtsUs: [],
            SkuWithHts: [],
            selectedOrder: {},
            selectedRow: {},
            selectedGoogleMapInfo: undefined,
            orderItems: [],
            //Create Shipment
            AshlinInfo: undefined,
            Signature: undefined,
            ProformaEmail: undefined,
            UrgentEmail: undefined,
            AshlinSalesEmail: undefined,
            EmailList: '',
            shippingInfo: {
                ShippableDepthCm: 0,
                ShippableHeightCm: 0,
                ShippableWidthCm: 0,
                ShippableWeightGrams: 0,
                ShippingDescription: "",
                ShippingItems: [],
                TotalValue: 0
            },
            numberOfBox: '1',
            reference1: '',
            reference2: '',
            isCommercialOrder: false,
            use3rdPartyAccount: false,
            declareValueInsurance: false,
            thirdPartyAccountNum: '',
            thirdPartyAccountAddressName: '',
            thirdPartyAccountAddress1: '',
            thirdPartyAccountAddress2: '',
            thirdPartyAccountAddress3: '',
            thirdPartyAccountAddressCity: '',
            thirdPartyAccountAddressStateCode: '',
            thirdPartyAccountAddressCountryCode: '',
            thirdPartyAccountAddressPostalCode: '',

            selectedCarrier: 'optionDefaultHeader',
            selectedOptions: 'optionDefaultHeader',
            shippingRates: [],
            selectedShippingMethod: '',
            creatingShipment: false,
            FEIN: '',
            attachBase64: undefined,

            //
            CanparCre: {},
            CanparRate: [],

            CanadaPostCre: {},
            CanadaPostRate: [],

            UPSRate: [],
            UPSCre: {},

            FedExRate: [],
            FedExCre: {},

            PurolatorRate: [],
            PurolatorCre: {},

            canFulfill: false,
            DesignsPacking: [],
            Comment: "Please *** DO NOT use any shipping account ***, ",

            ShippingMatrices: [],

            isProcessing: false,

            scheduleStart: "1530",
            scheduleEnd: "1630",
            crossDockOrder: false,
            isUrgentShipment: false,
            totalBoxNumber: '1',
            showEndOfDay: false,
        };
    }
    componentWillUnmount() {
        this._mounted = false;
    }
    componentDidMount() {
        this._mounted = true;
        this.getData();
        this.getAshlinInfo();
        this.getAshlinHTS();
        this.getShipperInfo();
    }
    getAshlinInfo = async () => {
        try {
            const AshlinInfo = await getCompanyInfoById('D8AE77CD-3551-4373-BE57-8057AF0F49F1');
            const Signature = await getSignatureById('8D1D8DBB-4805-4BE9-83C8-EEE441AAE7E5');
            const ProformaEmail = await getEmailConfigurationById('A1F51FC9-E333-4377-A06D-54959E050FD0');
            const AshlinSalesEmail = await getEmailConfigurationById('FE2C6D16-96FE-4297-829C-62DBC922A733');
            const UrgentEmail = await getEmailConfigurationById('7D69A105-22B5-48E5-BE4C-2A63FF3DFA63');
            const EmailList = ProformaEmail.Recipient;
            const DesignsPacking = await getDesignsPacking();
            const ShippingMatrices = await getShippingMatrices();
            this.setState({
                AshlinInfo,
                Signature,
                ProformaEmail,
                EmailList,
                DesignsPacking,
                ShippingMatrices,
                AshlinSalesEmail,
                UrgentEmail
            })
        } catch (error) {
            handleError(error, "getAshlinInfo")
        }
    };
    getShipperInfo = async () => {
        try {
            const CanparCre = await getCanparCredentials();
            const CanadaPostCre = await getCanadaPostToken();
            const UPSCre = await getUPSCredentials();
            const FedExCre = await getFedExCredentials();
            const PurolatorCre = await getPurolatorCredentials();
            this.setState({
                CanparCre,
                CanadaPostCre,
                UPSCre,
                FedExCre,
                PurolatorCre
            })
        } catch (error) {
            handleError(error, "getShipperInfo")
        }
    };
    getAshlinHTS = async () => {
        try {
            const HtsCa = await getHtsCa();
            const HtsUs = await getHtsUs();
            const SkuWithHts = await getSkuWithHts();

            this.setState({
                HtsCa,
                HtsUs,
                SkuWithHts
            }, () => {
                setTimeout(
                    this.setState({
                        isLoading: false
                    })
                    , 10000);
            });
        } catch (error) {
            handleError(error, "getAshlinHTS")
        }
    };
    getData = async () => {
        try {
            this.setState({
                isDownLoading: true
            }, async () => {
                const ashlinManualOrderList = await getAshlinManualOrders();

                const skuNumberList = await getAllSKUNumberList();
                const headers = await getBPheader();
                const brightPearlWarehouses = await getAllWarehouses(headers);
                const taxCodes = await getTaxCodes(headers);
                const BrightpearlChannelInfos = await getBrightpearlChannelInfos();
                const credentials = await getCredentials();
                const asiToken = await getAuth(credentials);
                this.setState({
                    ashlinManualOrderList,
                    skuNumberList,
                    taxCodes,
                    isDownLoading: false,
                    BrightpearlChannelInfos,
                    brightPearlWarehouses,
                    brightpearlHeader: headers,
                    asiToken
                });
            })
        } catch (error) {
            handleError(error, "getData")
        }
    };
    handleSimpleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]: value
        });
    };
    handleViewOrder = async (rowInfo) => {
        let confirmBox = window.confirm(`Do you confirm to view order detail for this order?`);
        try {
            if (confirmBox) {
                //
                const selectedOrder = await getOrderDetailsByOrderID(rowInfo.BrightpearlOrderId, this.state.brightpearlHeader);
                const temp = Object.entries(selectedOrder.orderRows);
                selectedOrder.orderRows = temp.map(arr => {
                    arr[1].rowId = arr[0];
                    return arr[1];
                });

                if (selectedOrder !== undefined) {
                    this.setState({
                        selectedOrder,
                        selectedRow: rowInfo
                    }, () => $('#viewManualOrderDetailModal').modal('toggle'))
                } else {
                    alert("Can't find this order on Brightpearl!");
                }
            }
        } catch (error) {
            handleError(error, "handleViewOrder")
        }
    };
    handleCloseViewOrderModal = () => {
        this.setState({
            selectedOrder: {},
            selectedRow: {},
            orderItems: [],
            crossDockOrder: false,
            totalBoxNumber: '1',
        }, () => $('#viewManualOrderDetailModal').modal('hide'))
    };
    handleCloseCreateShipmentModal = () => {
        this.setState({
            selectedOrder: {},
            selectedRow: {},
            shippingInfo: {
                ShippableDepthCm: 0,
                ShippableHeightCm: 0,
                ShippableWidthCm: 0,
                ShippableWeightGrams: 0,
                ShippingDescription: "",
                ShippingItems: [],
                TotalValue: 0
            },
            isCommercialOrder: false,
            use3rdPartyAccount: false,
            numberOfBox: '1',
            reference1: '',
            reference2: '',
            declareValueInsurance: false,
            thirdPartyAccountNum: '',
            thirdPartyAccountAddressName: '',
            thirdPartyAccountAddress1: '',
            thirdPartyAccountAddress2: '',
            thirdPartyAccountAddress3: '',
            thirdPartyAccountAddressCity: '',
            thirdPartyAccountAddressStateCode: '',
            thirdPartyAccountAddressCountryCode: '',
            thirdPartyAccountAddressPostalCode: '',
            selectedCarrier: 'optionDefaultHeader',
            selectedGoogleMapInfo: undefined,
            selectedOptions: 'optionDefaultHeader',
            shippingRates: [],
            selectedShippingMethod: '',
            FEIN: '',
            attachBase64: undefined,
            orderItems: [],
            CanparRate: [],
            CanadaPostRate: [],
            UPSRate: [],
            FedExRate: [],
            PurolatorRate: [],
            scheduleStart: "1530",
            scheduleEnd: "1630",
            crossDockOrder: false,
            totalBoxNumber: '1',
        }, () => $('#CreateManualShipmentModal').modal('hide'))
    };
    handleCloseConfirmShipmentModal = () => {
        this.setState({
            selectedOrder: {},
            selectedRow: {},
            FEIN: '',
            attachBase64: undefined,
            orderItems: [],
            canFulfill: false,
            crossDockOrder: false,
            totalBoxNumber: '1',
            Comment: "Please *** DO NOT use any shipping account ***, "
        }, () => $('#ConfirmManualShipmentModal').modal('hide'))
    };
    handleOrderAttachFile = (base64String) => {
        this.setState({
            attachBase64: base64String
        })
    };
    handleCreateShipmentWarehouseOnChange = (e) => {
        const value = e.target.value;
        const selectedRow = this.state.selectedRow;
        selectedRow.Warehouse = value;
        this.setState({
        }, () => console.log(this.state.selectedRow))
    };
    handleShippingInfoOnchange = (e) => {
        const name = e.target.name;
        let value = e.target.value;
        const shippingInfo = this.state.shippingInfo;

        if (value === "" || value === "0" || isNaN(value)) {
            value = 1;
        } else {
            value = parseInt(value);
        }
        shippingInfo[name] = value;
        this.setState({
            shippingInfo
        });
    };
    handleCarrierOnchange = (Carrier, Service) => {
        let selectedOptions = this.state.selectedOptions;
        let selectedShippingMethod;
        // if(selectedOptions === "optionDefaultHeader"){
        switch (Carrier) {
            case 'Canada Post':
                //selectedOptions = "Signature Required";
                selectedOptions = 'Do not safe drop';
                selectedShippingMethod = Service.serviceCode;
                break;
            case 'Canpar':
                //selectedOptions = "Signature Required";
                selectedOptions = 'Do not safe drop';
                selectedShippingMethod = Service.serviceCode;
                break;
            case 'UPS':
                //selectedOptions = "Signature Required";
                selectedOptions = 'Do not safe drop';
                selectedShippingMethod = Service.Service.Code;
                break;
            case 'FedEx':
                //selectedOptions = "Signature Required";
                selectedOptions = 'Do not safe drop';
                selectedShippingMethod = Service.serviceCode;
                break;
            case 'Purolator':
                selectedOptions = 'Do not safe drop';
                selectedShippingMethod = Service.serviceCode;
                break;
            default:
                selectedOptions = 'optionDefaultHeader';
                selectedShippingMethod = Service.serviceName;
        }
        // }

        this.setState({
            selectedCarrier: Carrier,
            selectedOptions,
            selectedShippingMethod: selectedShippingMethod
        });
    };
    handleGetRates = () => {
        try {
            this.setState({
                isProcessing: true
            }, async () => {
                const bpOrder = this.state.selectedOrder;
                const ShippingMatrices = this.state.ShippingMatrices;

                let shippingMethodsFilter = [];
                const shippingLine = bpOrder.orderRows.find(row => row.productName.includes("Shipping"));

                if (shippingLine) {
                    //console.log(shippingLine);
                    shippingMethodsFilter = ShippingMatrices.filter(s => {
                        if (shippingLine.productName.includes(s.ChannelBigCommerceCode))
                            return s;
                        const AmazonConditions = s.ChannelAmazonCode.split(',');
                        if (AmazonConditions.some(el => shippingLine.productName.includes(el)))
                            return s;
                        const eBayConditions = s.ChannelEbayCode.split(',');
                        if (eBayConditions.some(el => shippingLine.productName.includes(el)))
                            return s;

                    });
                    //console.log(shippingMethodsFilter);
                }

                const data = this.stateToDataMapper();
                //console.log(data);
                const isExpress = checkOrderExpress(bpOrder.orderRows);
                //console.log(isExpress);
                if (bpOrder.parties.delivery.countryIsoCode === "CA") {
                    const CanparRate = _.orderBy(await getCanparRates(data, this.state.CanparCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.totalCost), ['totalCost', 'deliverByDate'], ['asc']);
                    const CanadaPostRate = _.orderBy(await getCanadaPostRates(data, this.state.CanadaPostCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.totalCost), ['totalCost', 'deliverByDate'], ['asc']);
                    const UPSRate = _.orderBy(await getUPSPackageRate(data, this.state.UPSCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.TotalCharges.MonetaryValue), ['MonetaryValue', 'Delivery'], ['asc']);
                    const FedExRate = _.orderBy(await getFedExRate(data, this.state.FedExCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.totalCost), ['totalCost'], ['asc']);
                    const PurolatorRate = _.orderBy(await getPurolatorRates(data, this.state.PurolatorCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.totalCost), ['totalCost', 'deliverByDate'], ['asc']);

                    this.setState({
                        CanparRate,
                        CanadaPostRate,
                        UPSRate,
                        FedExRate,
                        PurolatorRate,
                        isProcessing: false
                    }, () => {
                        const preferredCarrier = this.state.BrightpearlChannelInfos.find(c => c.ChannelId === bpOrder.assignment.current.channelId);
                        if (preferredCarrier) {
                            alert(`Preferred Shipping Carrier: *** ${preferredCarrier.PreferredShippingCarrier} *** OR Choose the LOWEST cost`);
                        }

                    });
                } else {
                    //const UPSRate = _.orderBy(await getUPSPackageRate(data, this.state.UPSCre,isExpress), rate => parseFloat(rate.TotalCharges.MonetaryValue),['MonetaryValue','Delivery'],['asc']);
                    const UPSRate = _.orderBy(await getUPSPackageRate(data, this.state.UPSCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.TotalCharges.MonetaryValue), ['MonetaryValue', 'Delivery'], ['asc']);
                    const FedExRate = _.orderBy(await getFedExRate(data, this.state.FedExCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.totalCost), ['totalCost'], ['asc']);
                    const CanadaPostRate = _.orderBy(await getCanadaPostRates(data, this.state.CanadaPostCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.totalCost), ['totalCost', 'deliverByDate'], ['asc']);
                    const PurolatorRate = _.orderBy(await getPurolatorRates(data, this.state.PurolatorCre, isExpress, ShippingMatrices, shippingMethodsFilter), rate => parseFloat(rate.totalCost), ['totalCost', 'deliverByDate'], ['asc']);
                    this.setState({
                        UPSRate,
                        FedExRate,
                        CanadaPostRate,
                        PurolatorRate,
                        isProcessing: false
                    }, () => {
                        const preferredCarrier = this.state.BrightpearlChannelInfos.find(c => c.ChannelId === bpOrder.assignment.current.channelId);
                        if (preferredCarrier) {
                            alert(`Preferred Shipping Carrier: ${preferredCarrier.PreferredShippingCarrier}`);
                        }
                    });
                }
            });
        } catch (error) {
            handleError(error, "handleGetRates")
        }
    };
    stateToDataMapper = () => {
        let ShipFrom, GroupType;
        switch (this.state.selectedRow.Warehouse) {
            case 'LS14 LeanSupply 3PL':
                ShipFrom = {
                    "Name": "Lean Supply Solutions",
                    "AddressLine1": "3100 Caravelle Dr",
                    "AddressLine2": "",
                    "AddressLine3": "",
                    "City": "Mississauga",
                    "State": "ON",
                    "CountryCode": "CA",
                    "ContactName": "Customer Service Tedlo",
                    "ContactPhone": "4167488982",
                    "ContactPhoneExt": "",
                    "Zip": "L4V1K9"
                };
                GroupType = "3PL";
                break;
            default:
                ShipFrom = {
                    "Name": "Ashlin-BPG Marketing",
                    "AddressLine1": "2351 Royal Windsor Dr",
                    "AddressLine2": "Unit #10",
                    "AddressLine3": "",
                    "City": "Mississauga",
                    "State": "ON",
                    "CountryCode": "CA",
                    "ContactName": "Nancy Taccone",
                    "ContactPhone": "9058553027",
                    "ContactPhoneExt": "",
                    "Zip": "L5J4S7"
                };
                GroupType = "Ashlin"
        }
        return {
            Options: this.state.selectedOptions !== "optionDefaultHeader" ? this.state.selectedOptions : "No options",
            Use3rdPartyAccount: this.state.use3rdPartyAccount,
            DeclareValueInsurance: this.state.declareValueInsurance,
            ThirdPartyAccountNum: this.state.thirdPartyAccountNum,
            ThirdPartyAccountAddress: {
                "Name": this.state.thirdPartyAccountAddressName,
                "AddressLine1": this.state.thirdPartyAccountAddress1,
                "AddressLine2": this.state.thirdPartyAccountAddress2,
                "AddressLine3": this.state.thirdPartyAccountAddress3,
                "City": this.state.thirdPartyAccountAddressCity,
                "State": this.state.thirdPartyAccountAddressStateCode.toUpperCase(),
                "CountryCode": this.state.thirdPartyAccountAddressCountryCode.toUpperCase(),
                "Zip": this.state.thirdPartyAccountAddressPostalCode.replace(/\s/g, '').toUpperCase()
            },
            IsCommercialOrder: this.state.isCommercialOrder,
            PackageInfo: {
                Weight: (this.state.shippingInfo.ShippableWeightGrams / 1000).toFixed(2),
                Length: this.state.shippingInfo.ShippableHeightCm.toFixed(1),
                Width: this.state.shippingInfo.ShippableWidthCm.toFixed(1),
                Height: this.state.shippingInfo.ShippableDepthCm.toFixed(1),
                Description: this.state.shippingInfo.ShippingDescription,
                ItemDetails: this.state.shippingInfo.ItemDetails,
                TotalValue: this.state.shippingInfo.TotalValue
            },
            ShipTo: {
                "Name": this.state.selectedOrder.parties.delivery.companyName !== undefined ? this.state.selectedOrder.parties.delivery.companyName.replace(/[^a-z0-9 ]/gi, '') : "",
                "AddressLine1": this.state.selectedOrder.parties.delivery.addressLine1,
                "AddressLine2": this.state.selectedOrder.parties.delivery.addressLine2 !== undefined ? this.state.selectedOrder.parties.delivery.addressLine2 : "",
                "AddressLine3": "",
                "City": this.state.selectedOrder.parties.delivery.addressLine3,
                "State": this.state.selectedOrder.parties.delivery.countryIsoCode === "GB" ? "" : ProvinceMapper(this.state.selectedOrder.parties.delivery.addressLine4),
                "CountryCode": this.state.selectedOrder.parties.delivery.countryIsoCode,
                "ContactName": this.state.selectedOrder.parties.delivery.addressFullName !== undefined ? this.state.selectedOrder.parties.delivery.addressFullName : "",
                "ContactPhone": this.state.selectedOrder.parties.delivery.telephone !== undefined ? this.state.selectedOrder.parties.delivery.telephone.replace(/\D+/g, '') : this.state.selectedOrder.parties.billing.telephone !== undefined ? this.state.selectedOrder.parties.billing.telephone.replace(/\D+/g, '') : "",
                "ContactPhoneExt": "",
                "Zip": this.state.selectedOrder.parties.delivery.postalCode.replace(/\s/g, '').toUpperCase()
            },
            ShipFrom,
            GroupType,
            Order: this.state.selectedOrder,
            Service: this.state.selectedShippingMethod,
            NumberOfBox: this.state.numberOfBox,
            Reference1: this.state.reference1,
            Reference2: this.state.reference2,
        };
    };
    handleCreateLabel = async (rowInfo) => {
        try {
            message.loading("Loading data...", 0)
            let confirmBox = window.confirm(`Do you confirm to create shipping label for this order?`);
            if (confirmBox) {
                //rowInfo.BrightpearlOrderId  811716
                const selectedOrder = await getOrderDetailsByOrderID(rowInfo.BrightpearlOrderId, this.state.brightpearlHeader);
                if (selectedOrder !== undefined) {
                    let shippingInfo = {
                        ShippableDepthCm: 0,
                        ShippableHeightCm: 0,
                        ShippableWidthCm: 0,
                        ShippableWeightGrams: 0,
                        ShippingDescription: "",
                        ShippingItems: [],
                        ItemDetails: [],
                        TotalValue: 0
                    };
                    const temp = Object.entries(selectedOrder.orderRows);
                    selectedOrder.orderRows = Object.values(temp.map(arr => {
                        arr[1].rowId = arr[0];
                        return arr[1];
                    }));
                    const weightRows = Object.values(selectedOrder.orderRows).filter((row => row.productSku !== undefined && row.productSku !== "" && !row.productSku.includes('SVC') && !row.productSku.includes('CUS') && !row.productSku.includes('Shipping')));
                    console.log(weightRows);
                    const res = await weightRows.map(async row => {
                        if (row.productSku !== undefined && row.productSku !== "" && !row.productSku.includes('SVC') && !row.productSku.includes('CUS') && !row.productSku.includes('Shipping')) {
                            const designCode = (row.productSku.split('-'))[0];
                            const ashlinDesign = await getDesignByCode(designCode);
                            if (ashlinDesign !== null) {
                                let value = parseInt(row.rowValue.rowNet.value);
                                value = value === 0 ? 1 : value;
                                shippingInfo.ShippableDepthCm = shippingInfo.ShippableDepthCm < ashlinDesign.ShippableDepthCm ? parseInt(ashlinDesign.ShippableDepthCm) : shippingInfo.ShippableDepthCm;
                                shippingInfo.ShippableHeightCm = shippingInfo.ShippableHeightCm < ashlinDesign.ShippableHeightCm ? parseInt(ashlinDesign.ShippableHeightCm) : shippingInfo.ShippableHeightCm;
                                shippingInfo.ShippableWidthCm = shippingInfo.ShippableWidthCm < ashlinDesign.ShippableWidthCm ? parseInt(ashlinDesign.ShippableWidthCm) : shippingInfo.ShippableWidthCm;
                                shippingInfo.ShippableWeightGrams += parseInt((parseFloat(ashlinDesign.ShippableWeightGrams) * parseInt(row.quantity.magnitude)));
                                shippingInfo.ShippingDescription += `${parseInt(row.quantity.magnitude)}, ${ashlinDesign.GeneralName}  `;
                                shippingInfo.TotalValue += value;
                                shippingInfo.ShippingItems.push(ashlinDesign.GeneralName);
                                const itemDetail = {
                                    "Name": ashlinDesign.GeneralName,
                                    "Piece": 1,
                                    "Description": `${ashlinDesign.HTSDescription}`,
                                    "Weight": shippingInfo.ShippableWeightGrams,
                                    "Qty": parseInt(row.quantity.magnitude),
                                    "Currency": row.rowValue.rowNet.currencyCode,
                                    "Amount": value,
                                    "Hts": row.HtsUs,
                                    "Sku": row.productSku
                                };
                                shippingInfo.ItemDetails.push(itemDetail);
                            }
                        }
                    });

                    shippingInfo.ShippableDepthCm = shippingInfo.ShippableDepthCm < 1 ? 1 : shippingInfo.ShippableDepthCm;
                    shippingInfo.ShippableHeightCm = shippingInfo.ShippableHeightCm < 1 ? 1 : shippingInfo.ShippableHeightCm;
                    shippingInfo.ShippableWidthCm = shippingInfo.ShippableWidthCm < 1 ? 1 : shippingInfo.ShippableWidthCm;
                    shippingInfo.ShippableWeightGrams = shippingInfo.ShippableWeightGrams < 450 ? 450 : shippingInfo.ShippableWeightGrams;
                    //get hts code and description here
                    selectedOrder.orderRows.forEach(ol => {
                        const hts = this.state.SkuWithHts.find(hts => hts.Sku === ol.productSku);
                        if (hts !== undefined) {
                            if (hts.CaDuty !== undefined && hts.UsDuty !== undefined) {
                                ol.HtsCa = hts.HtsCa;
                                ol.HtsUs = hts.HtsUs;
                                let renderedHts = selectedOrder.parties.delivery.countryIsoCode === "US" ? hts.HtsUs : hts.HtsCa;
                                ol.CaDuty = hts.CaDuty.toString().startsWith("0.0") ? hts.CaDuty * 100 : hts.CaDuty;
                                ol.UsDuty = hts.UsDuty.toString().startsWith("0.0") ? hts.UsDuty * 100 : hts.UsDuty;
                                ol.HtsDescription = `[${hts.Sku}] ${hts.DesignDescription}-${hts.MaterialDesignDescription}, HTS:${renderedHts}`;
                            }
                        } else {
                            ol.HtsCa = "4205.00.0000";
                            ol.HtsUs = "4205.00.8000";
                            ol.CaDuty = 0;
                            ol.UsDuty = 0;
                            ol.HtsDescription = "";
                        }
                    });
                    let FEIN = "";
                    //IF US Customer, try to get FEIN Number
                    if (selectedOrder.parties.delivery.countryIsoCode === "US") {
                        const res = await getUSCustomerFEINNumber(selectedOrder.parties.customer.contactId);
                        if (res !== undefined)
                            FEIN = res;
                    }

                    //search chitchat items
                    const orderItems = await getAshlinMaualOrderItems(rowInfo.Id);

                    //get googleMap info and give it to CreateManualShipmentModal
                    try {
                        let selectedGoogleMapInfoResponse = await getGoogleMapInfo(selectedOrder.parties.delivery.addressLine1)
                        if (selectedGoogleMapInfoResponse.data.status === "OK") {
                            let selectedGoogleMapInfo = selectedGoogleMapInfoResponse.data.results[0]
                            this.setState({ selectedGoogleMapInfo })
                        }
                    } catch (err) {
                        alert("There is an error occuring by calling GOOGLE MAP and make sure the address on BP is correct")
                    }



                    Promise.all(res).then(() => {

                        this.setState({
                            selectedOrder,
                            selectedRow: rowInfo,
                            shippingInfo,
                            FEIN,
                            orderItems
                        }, () => {
                            $('#CreateManualShipmentModal').modal('toggle')
                            message.destroy()
                        }

                        )
                    })

                } else {
                    alert("Can't find this order on Brightpearl!");
                }
            }
        } catch (error) {
            handleError(error, "handleCreateLabel")
        }
    };
    handleCreateShipment = async () => {
        try {
            const bpOrder = this.state.selectedOrder;
            const selectedRow = this.state.selectedRow;
            const selectedWarehouse = this.state.brightPearlWarehouses.find(w => w.name === selectedRow.Warehouse);
            const ProductsTotal = bpOrder.orderRows.map(row => {
                if (row.productSku === undefined)
                    return;
                return {
                    "productId": row.productId,
                    "salesOrderRowId": parseInt(row.rowId),
                    "quantity": parseInt(row.quantity.magnitude)
                }
            });
            console.log(ProductsTotal)
            const Products = ProductsTotal.filter(od => od !== undefined);
            let d = new Date();
            const date = d.toISOString().slice(0, 10);

            const data = this.stateToDataMapper();
            let service = this.state.ShippingMatrices.find(s => s.ServiceCode === data.Service);
            service = service === undefined ? data.Service : service.DisplayedName;
            //console.log(service);
            this.setState({
                creatingShipment: true
            }, async () => {

                if (this.state.selectedCarrier === "USPS") {
                    console.log(this.state.shippingInfo);
                    let searchCode = bpOrder.parties.delivery.postalCode.includes('-') ? bpOrder.parties.delivery.postalCode.split('-')[0] : bpOrder.parties.delivery.postalCode;
                    const searchAddress = (await axios.get(proxyurl + `https://geocoder.ca/?postal=${searchCode}&geoit=xml&json=1&standard=1&showcountry=1&moreinfo=1`)).data;
                    const provinceCode = searchAddress.standard.prov;

                    const chitchatsShipmentId = await createChitChatsShipment(bpOrder, this.state.shippingInfo, provinceCode);
                    console.log(chitchatsShipmentId);

                    const shipmentDetail = await getChitChatsShipment(chitchatsShipmentId);
                    console.log(shipmentDetail);
                    selectedRow.ShippingCarrier = "USPS";
                    selectedRow.ShippingService = "Ground";
                    selectedRow.ShippingLabelId = shipmentDetail.id;
                    selectedRow.TrackingNumber = shipmentDetail.carrier_tracking_code;
                    selectedRow.LabelDetail = shipmentDetail.postage_label_png_url;
                    selectedRow.LabelURL = shipmentDetail.postage_label_png_url;
                    selectedRow.Status = "USPSCreated";

                    const note = `USPS Tracking #: ${shipmentDetail.carrier_tracking_code}`;

                    //console.log(note);

                    if (ENABLE_BRIGHTPEARL) {
                        await updateOrderStatus(bpOrder.id, 20, note, this.state.brightpearlHeader);


                        //Create Goods-Out Note
                        const goodsOutNote = {
                            "warehouses": [
                                {
                                    "releaseDate": date,
                                    "warehouseId": selectedWarehouse.id,
                                    "transfer": false,
                                    "products": Products

                                }
                            ],
                            "priority": false,
                            "shippingMethodId": 5,
                            "labelUri": ""
                        };

                        await createGoodsOutNote(bpOrder.id, goodsOutNote, this.state.brightpearlHeader);

                        const shippingData = [
                            {
                                "op": "replace",
                                "path": "/delivery/shippingMethodId",
                                "value": 5
                            }
                        ];

                        await updateShipmentMethodAndDeliveryDate(bpOrder.id, shippingData, this.state.brightpearlHeader);

                        await sleep(3000);

                        const goodsOutNoteId = await searchGoodsOutNote(bpOrder.id, this.state.brightpearlHeader);
                        if (goodsOutNoteId !== null) {
                            //update goods out tracking reference
                            const newNote = {
                                "priority": false,
                                "shipping": {
                                    "shippingMethodId": 5,
                                    "reference": selectedRow.TrackingNumber,
                                },
                                "labelUri": ""
                            };

                            // console.log(newNote);
                            await updateGoodsOutNote(goodsOutNoteId, newNote, this.state.brightpearlHeader);
                            //update goods out event to shipped

                            //This event can be handled by allocated and invoice on BP
                            await postShippedEvent(goodsOutNoteId, this.state.brightpearlHeader);


                        }
                    }
                    await updateAshlinManualOrder(selectedRow);

                }
                else if (this.state.selectedCarrier === "Canada Post") {
                    //console.log(selectedRow)
                    let fileName;
                    if (selectedRow.Warehouse === "LS14 LeanSupply 3PL") {
                        fileName = `3PL-${selectedRow.BrightpearlOrderId}.pdf`
                    } else {
                        fileName = `Ashlin-${selectedRow.BrightpearlOrderId}.pdf`
                    }
                    const labelURL = `https://ashlinapp.xyz/Labels/CanadaPost/${fileName}`;

                    if (selectedRow.LabelDetail) {
                        const labelDetail = JSON.parse(selectedRow.LabelDetail);
                        const labelBlob = await getCanadaPostLabel(labelDetail.labelURL, this.state.CanadaPostCre);
                        await postLabel(labelBlob, fileName, "CanadaPost");
                        window.open(labelURL, '_blank');
                    } else {
                        const canadaPostContactShipment = await createCanadaPostContactShipment(data, this.state.CanadaPostCre);
                        if (canadaPostContactShipment.shipmentId) {
                            const labelDetail = JSON.parse(canadaPostContactShipment.label);
                            const labelBlob = await getCanadaPostLabel(labelDetail.labelURL, this.state.CanadaPostCre);
                            labelDetail.label = labelURL;
                            selectedRow.ShippingCarrier = "Canada Post";
                            selectedRow.ShippingService = service;
                            selectedRow.ShippingLabelId = canadaPostContactShipment.shipmentId;
                            selectedRow.TrackingNumber = canadaPostContactShipment.tracking;
                            selectedRow.LabelDetail = JSON.stringify(labelDetail);
                            selectedRow.LabelURL = labelURL;
                            selectedRow.Status = "LabelCompleted";
                            await updateAshlinManualOrder(selectedRow);
                            await postLabel(labelBlob, fileName, "CanadaPost");
                            window.open(labelURL, '_blank');
                        } else {
                            alert(canadaPostContactShipment.error);
                        }
                    }

                }
                else if (this.state.selectedCarrier === "Canpar") {
                    const canparShipment = await processCanparShipment(data, this.state.CanparCre);
                    if (canparShipment.shipmentId) {

                        const labelDetail = canparShipment.labels.map(async (label, index) => {
                            const image = base64ToNewTab(label, 'application/png');
                            const url = `https://ashlinapp.xyz/Labels/Canpar/${selectedRow.BrightpearlOrderId}_${index}.png`;
                            await postLabel(image, `${selectedRow.BrightpearlOrderId}_${index}.png`, "Canpar");
                            window.open(url, '_blank');
                            return url
                        });
                        Promise.all(labelDetail).then((promise) => promise.join(" | "))
                            .then(async promiseValue => {
                                selectedRow.ShippingLabelId = canparShipment.shipmentId;
                                selectedRow.TrackingNumber = canparShipment.tracking;
                                selectedRow.LabelURL = promiseValue;
                                selectedRow.LabelDetail = promiseValue;
                                selectedRow.ShippingService = service;
                                selectedRow.ShippingCarrier = "Canpar";
                                selectedRow.Status = "LabelCompleted";
                                await updateAshlinManualOrder(selectedRow);
                            });

                        selectedRow.ShippingService = service;
                        selectedRow.ShippingCarrier = "Canpar";
                        selectedRow.TrackingNumber = canparShipment.tracking;
                        selectedRow.Status = "LabelCompleted";

                    } else {
                        alert(canparShipment.error)
                    }
                }
                else if (this.state.selectedCarrier === "UPS") {
                    //console.log(this.state);
                    const upsShipment = await createUPSShipment(data, this.state.UPSCre);
                    if (upsShipment.shipmentId) {
                        const labelDetail = upsShipment.labels.map(async (label, index) => {
                            const image = base64ToNewTab(label, 'application/gif');
                            const url = `https://ashlinapp.xyz/Labels/UPS/${selectedRow.BrightpearlOrderId}_${index}.gif`;
                            await postLabel(image, `${selectedRow.BrightpearlOrderId}_${index}.gif`, "UPS");
                            window.open(url, '_blank');
                            return url
                        });
                        Promise.all(labelDetail).then((promise) => promise.join(" | "))
                            .then(async promiseValue => {
                                selectedRow.ShippingLabelId = upsShipment.shipmentId;
                                selectedRow.TrackingNumber = upsShipment.tracking;
                                selectedRow.LabelURL = promiseValue;
                                selectedRow.LabelDetail = promiseValue;
                                selectedRow.ShippingService = service;
                                selectedRow.ShippingCarrier = "UPS";
                                selectedRow.Status = "LabelCompleted";
                                await updateAshlinManualOrder(selectedRow);
                            });

                        selectedRow.ShippingService = service;
                        selectedRow.ShippingCarrier = "UPS";
                        selectedRow.TrackingNumber = upsShipment.tracking;
                        selectedRow.Status = "LabelCompleted";

                    } else {
                        alert(upsShipment.error)
                    }
                }
                else if (this.state.selectedCarrier === "FedEx") {
                    //console.log(this.state);
                    const fedExShipment = await createFedExShipment(data, this.state.FedExCre);
                    //console.log(fedExShipment);
                    if (fedExShipment) {
                        const labelDetail = fedExShipment.labels.map(async (label, index) => {
                            const image = base64ToNewTab(label, 'application/png');
                            const labelURL = `https://ashlinapp.xyz/Labels/FedEx/${selectedRow.BrightpearlOrderId}_${index}.png`;
                            await postLabel(image, `${selectedRow.BrightpearlOrderId}_${index}.png`, "FedEx");
                            window.open(labelURL, '_blank');
                            return labelURL
                        });
                        Promise.all(labelDetail).then((promise) => promise.join(" | "))
                            .then(async promiseValue => {
                                selectedRow.ShippingCarrier = "FedEx";
                                selectedRow.ShippingService = service;
                                selectedRow.ShippingLabelId = fedExShipment.formId;
                                selectedRow.TrackingNumber = fedExShipment.tracking;
                                selectedRow.LabelURL = promiseValue;
                                selectedRow.LabelDetail = promiseValue;
                                selectedRow.Status = "LabelCompleted";
                                await updateAshlinManualOrder(selectedRow);
                            });
                        selectedRow.ShippingService = service;
                        selectedRow.ShippingCarrier = "FedEx";
                        selectedRow.TrackingNumber = fedExShipment.tracking;
                        selectedRow.Status = "LabelCompleted";
                    } else {
                        alert("Create FedEx label failed!")
                    }
                }
                else if (this.state.selectedCarrier === "Purolator") {
                    const purolatorShipment = await createPurolatorShipment(data, this.state.PurolatorCre);
                    if (purolatorShipment) {
                        selectedRow.ShippingCarrier = "Purolator";
                        selectedRow.ShippingService = service;
                        selectedRow.ShippingLabelId = purolatorShipment.tracking;
                        selectedRow.TrackingNumber = purolatorShipment.tracking;
                        selectedRow.LabelDetail = purolatorShipment.pickupId;
                        selectedRow.LabelURL = purolatorShipment.labels;
                        selectedRow.Status = "LabelCompleted";
                        //console.log(selectedRow);
                        await updateAshlinManualOrder(selectedRow);
                        const labels = purolatorShipment.labels.split(' | ');
                        labels.forEach(label => window.open(label, '_blank'));
                    } else {
                        alert("Create Purolator label failed!")
                    }
                }
                else {

                }
                const shippingMethodId = manualOrderCarrierMapping(this.state.selectedCarrier, this.state.selectedShippingMethod, bpOrder.parties.delivery.countryIsoCode);
                const shippingData = [
                    {
                        "op": "replace",
                        "path": "/delivery/shippingMethodId",
                        "value": shippingMethodId
                    },
                    // {
                    //     "op": "replace",
                    //     "path": "/warehouseId",
                    //     "value": selectedWarehouse.id
                    // },
                ];
                //console.log(shippingData);
                if (ENABLE_BRIGHTPEARL) {
                    await updateShipmentMethodAndDeliveryDate(bpOrder.id, shippingData, this.state.brightpearlHeader);
                }
                //await updateAshlinManualOrder(selectedRow);
                //DO reset
                this.setState({
                    creatingShipment: false,
                    selectedOrder: {},
                    selectedRow: {},
                    shippingInfo: {
                        ShippableDepthCm: 0,
                        ShippableHeightCm: 0,
                        ShippableWidthCm: 0,
                        ShippableWeightGrams: 0,
                        ShippingDescription: "",
                        ShippingItems: [],
                        TotalValue: 0
                    },
                    numberOfBox: '1',
                    reference1: '',
                    reference2: '',
                    isCommercialOrder: false,
                    use3rdPartyAccount: false,
                    declareValueInsurance: false,
                    thirdPartyAccountNum: '',
                    thirdPartyAccountAddressName: '',
                    thirdPartyAccountAddress1: '',
                    thirdPartyAccountAddress2: '',
                    thirdPartyAccountAddress3: '',
                    thirdPartyAccountAddressCity: '',
                    thirdPartyAccountAddressStateCode: '',
                    thirdPartyAccountAddressCountryCode: '',
                    thirdPartyAccountAddressPostalCode: '',
                    selectedCarrier: 'optionDefaultHeader',
                    selectedOptions: 'optionDefaultHeader',
                    shippingRates: [],
                    selectedShippingMethod: '',
                    FEIN: '',
                    attachBase64: undefined,
                    orderItems: [],
                    CanparRate: [],
                    CanadaPostRate: [],
                    UPSRate: [],
                    FedExRate: [],
                    PurolatorRate: [],
                    scheduleStart: "1530",
                    scheduleEnd: "1630",
                    crossDockOrder: false,
                    totalBoxNumber: '1',
                }, () => {
                    alert("Shipping label complete! Please verify the label.");
                    $('#CreateManualShipmentModal').modal('hide');
                })
            });
        } catch (error) {
            handleError(error, "handleCreateShipment")
        }
    };

    handleCheckLabel = (rowInfo) => {
        let confirmBox = window.confirm(`Do you confirm to view shipping label for this order?`);
        if (confirmBox) {
            if (rowInfo.Status === "USPSCreated") {
                const labelURL = `https://chitchats.com/labels/shipments/${rowInfo.ShippingLabelId}.png`;
                window.open(labelURL, '_blank');
            } else {
                if (rowInfo.ShippingCarrier === "USPS|Canpar") {
                    const labelURLs = rowInfo.LabelURL.split('|');
                    labelURLs.forEach(url => window.open(url, '_blank'));
                } else {
                    const labelURLs = rowInfo.LabelURL.split(' | ');
                    labelURLs.forEach(url => window.open(url, '_blank'));
                }
            }
        }
    };

    handleVoidLabel = (rowInfo) => {
        try {
            this.setState({
                selectedRow: rowInfo
            }, async () => {
                const selectedRow = this.state.selectedRow;
                let confirmBox = window.confirm(`Do you confirm to void ${selectedRow.ShippingCarrier} shipment for this order?`);
                if (confirmBox) {
                    const carrier = selectedRow.ShippingCarrier;
                    let voidRes;
                    if (carrier === "Canpar") {
                        voidRes = await voidCanparShipment(selectedRow.ShippingLabelId, this.state.CanparCre);

                    }
                    else if (carrier === "Canada Post") {
                        const labelDetail = JSON.parse(selectedRow.LabelDetail);
                        voidRes = await voidCanadaPostShipment(labelDetail.self, this.state.CanadaPostCre);
                    }
                    else if (carrier === "UPS") {
                        voidRes = await voidUPSShipment(selectedRow.ShippingLabelId, this.state.UPSCre);
                    }
                    else if (carrier === "FedEx") {
                        voidRes = await voidFedExShipment(selectedRow, this.state.FedExCre);
                    }
                    else if (carrier === "Purolator") {
                        await voidPickUp(this.state.PurolatorCre, selectedRow.LabelDetail);
                        voidRes = await voidPurolatorShipment(this.state.PurolatorCre, selectedRow.ShippingLabelId);
                    }
                    if (voidRes === "SUCCESS") {
                        selectedRow.ShippingCarrier = "";
                        selectedRow.ShippingService = "";
                        selectedRow.ShippingLabelId = "";
                        selectedRow.TrackingNumber = "";
                        selectedRow.LabelDetail = "";
                        selectedRow.LabelURL = "";
                        selectedRow.Status = "OrderCreated";
                        await updateAshlinManualOrder(selectedRow);
                        alert("SUCCESSFULLY VOID!");
                    } else {
                        alert(voidRes);
                    }

                }

                this.setState({
                    selectedRow
                })
            });
        } catch (error) {
            handleError(error, "handleVoidLabel")
        }

    };
    handleCompleteOrder = (rowInfo) => {
        try {
            this.setState({
                selectedRow: rowInfo
            }, async () => {
                const selectedRow = this.state.selectedRow;
                let confirmBox = window.confirm(`Do you confirm to confirm shipment for this order?`);
                if (confirmBox) {

                    const selectedOrder = await getOrderDetailsByOrderID(selectedRow.BrightpearlOrderId, this.state.brightpearlHeader);

                    if (selectedOrder !== undefined) {
                        const temp = Object.entries(selectedOrder.orderRows);
                        selectedOrder.orderRows = temp.map(arr => {
                            arr[1].rowId = arr[0];
                            return arr[1];
                        });
                        //get hts code and description here
                        selectedOrder.orderRows.forEach(ol => {
                            const hts = this.state.SkuWithHts.find(hts => hts.Sku === ol.productSku);

                            if (hts !== undefined) {
                                if (hts.CaDuty !== undefined && hts.UsDuty !== undefined) {
                                    ol.HtsCa = hts.HtsCa;
                                    ol.HtsUs = hts.HtsUs;
                                    let renderedHts = selectedOrder.parties.delivery.countryIsoCode === "US" ? hts.HtsUs : hts.HtsCa;
                                    ol.CaDuty = hts.CaDuty.toString().startsWith("0.0") ? hts.CaDuty * 100 : hts.CaDuty;
                                    ol.UsDuty = hts.UsDuty.toString().startsWith("0.0") ? hts.UsDuty * 100 : hts.UsDuty;
                                    ol.HtsDescription = `[${hts.Sku}] ${hts.DesignDescription}-${hts.MaterialDesignDescription}, HTS:${renderedHts}`;
                                }
                            } else {
                                ol.HtsCa = "4205.00.0000";
                                ol.HtsUs = "4205.00.8000";
                                ol.CaDuty = 0;
                                ol.UsDuty = 0;
                                ol.HtsDescription = "";
                            }
                        });
                        let FEIN = "";
                        //IF US Customer, try to get FEIN Number
                        if (selectedOrder.parties.delivery.countryIsoCode === "US") {
                            const res = await getUSCustomerFEINNumber(selectedOrder.parties.customer.contactId);
                            if (res !== undefined)
                                FEIN = res;
                        }

                        //search chitchat items
                        const orderItems = await getAshlinMaualOrderItems(rowInfo.Id);
                        this.setState({
                            selectedOrder,
                            selectedRow: rowInfo,
                            FEIN,
                            orderItems,
                        }, () => {
                            $('#ConfirmManualShipmentModal').modal('toggle')
                        }
                        )
                    } else {
                        alert("Can't find this order on Brightpearl!");
                    }
                }
            })
        } catch (error) {
            handleError(error, "handleCompleteOrder")
        }
    };
    handleSendUSPSto3PL = async () => {
        try {
            this.setState({
                creatingShipment: true,
            }, async () => {
                const bpOrder = this.state.selectedOrder;

                const ShipTo = {
                    "Name": "CHITCHATS EXPRESS INC",
                    "AddressLine1": "1735 Bayly St. Unit 7D",
                    "AddressLine2": "SHIPPING/RECEIVING - USPS SHIPMENTS",
                    "AddressLine3": "#ITEMS FOR USPS",
                    "City": "Pickering",
                    "State": "ON",
                    "CountryCode": "CA",
                    "ContactName": "CHITCHATS EXPRESS",
                    "ContactPhone": "905-492-3607",
                    "ContactPhoneExt": "",
                    "Zip": "L1W 3G7"
                };
                let OrderItemMessage = "";
                let OrderDetail;
                if (this.state.orderItems.length > 0) {
                    OrderDetail = this.state.orderItems.map(ol => {
                        return {
                            "OrderLine": ol.OrderLine,
                            "ItemCode": ol.ItemCode,
                            "ItemQty": ol.ItemQty,
                            "ItemName": ol.ItemName,
                            "ItemDescription": ol.ItemDescription,
                            "uofm": "EACH",
                            "per_pack": "1"
                        };
                    }
                    )
                } else {
                    OrderDetail = Object.values(bpOrder.orderRows).filter((row => row.productSku !== undefined && row.productSku !== "" && !row.productSku.includes('SVC') && !row.productSku.includes('Shipping'))).map((r, index) => {
                        OrderItemMessage += r.productSku + '\n';
                        return {
                            "OrderLine": `${index + 1}`,
                            "ItemCode": r.productSku,
                            "ItemQty": parseInt(r.quantity.magnitude),
                            "ItemName": r.productName,
                            "ItemDescription": r.productName,
                            "uofm": "EACH",
                            "per_pack": "1"
                        }
                    });
                }

                //console.log(OrderDetail);
                const leanSupplyInventory = await getBlanceByWarehouseCode(14);
                let counter = 0;
                const fulFullRes = await OrderDetail.map(async o => {
                    //console.log(o);
                    if (o.ItemCode !== undefined) {
                        const needQty = o.ItemQty;
                        //HERE check Lean Supply
                        //console.log(leanSupplyInventory);
                        const stockLevel = await leanSupplyInventory.find(s => s.sku === o.ItemCode);
                        if (stockLevel === undefined || needQty > parseInt(stockLevel.qty)) {
                            counter++
                        }

                    }
                });
                //console.log(counter);
                Promise.all(fulFullRes).then(async () => {
                    if (counter > 0) {
                        alert("Can't fulfill this order!");
                    } else {
                        let confirmBox = window.confirm(`Do you confirm to send this order? \n\nShipping Item:\n${OrderItemMessage}`);
                        if (confirmBox) {
                            const order = {
                                "OrderHeader": {
                                    "OrderNumber": `Ashlin-${bpOrder.id}`,
                                    "EndCustomerOrderNo": bpOrder.parties.customer.contactId,
                                    "ThirdPartyOrderNo": bpOrder.id,
                                    "OrderDateCode": "CR",
                                    "OrderDate": bpOrder.createdOn.slice(0, 19).replace("T", " "),
                                    //"Comment": `Please use Ashlin Canpar Account to ship to Chitchat, USPS label URL: https://chitchats.com/labels/shipments/${this.state.selectedRow.ShippingLabelId}.png `,
                                    "Comment": `Please DO NOT use Ashlin Canpar Account, we will send Canpar label through email, USPS label URL: https://chitchats.com/labels/shipments/${this.state.selectedRow.ShippingLabelId}.png `,
                                    "ShipTo": ShipTo,
                                    "ShipFrom": {
                                        "Name": "WAREHOUSE"
                                    },
                                    "WarehouseCode": "14",
                                    "OrderStatus": "NEW",
                                    "OrderType": "NO",
                                    "Priority": "",
                                    "Version": ""
                                },
                                "OrderDetail": OrderDetail,
                                "OrderDelivery": {
                                    "CarrierCode": "CANPAR",
                                    "ServiceCode": "01",
                                    "FreightCharges": "3RDPARTY",
                                    "ChargeToAccount": "42074982"
                                }
                            };
                            const data = SubmitOrderModel(order);

                            if (this.state.attachBase64 !== undefined) {
                                data.OrderAttachment = {
                                    "FileType": "PDF",
                                    "FileName": "PROFORMA  INVOICE",
                                    "File": this.state.attachBase64.split(',')[1]
                                }
                            }

                            //send to 3PL
                            //console.log(data);
                            const leanSupplyRes = await sendOrderToLeanSupply(data);
                            //console.log(leanSupplyRes);
                            const orderHandle = leanSupplyRes.data.OrderConfirm.OrderHandle;

                            //Update Database
                            const selectedRow = this.state.selectedRow;
                            //selectedRow.Status = "LabelCompleted";
                            selectedRow.Status = "DispatchedTo3PL";
                            const updateRes = await updateAshlinManualOrder(selectedRow);

                            //Update brightpearl
                            const note = `Order is shipped from ${selectedRow.Warehouse}`;
                            const brightPearlRes = await updateOrderStatus(bpOrder.id, 44, note, this.state.brightpearlHeader);


                            // Insert to a new row to Lean Supply Row
                            //////////////////////////////////////////////////////
                            let leanSupplyChannelId;
                            const channel = this.state.BrightpearlChannelInfos.find(c => c.ChannelId === bpOrder.assignment.current.channelId);
                            //console.log(channel)
                            if (channel) {
                                leanSupplyChannelId = channel.AshlinChannelId;
                            } else {
                                leanSupplyChannelId = 'C5C64492-786C-4F52-B89B-35A70328B225';
                            }
                            const processDate = currentDateTime();
                            const leanSupplyOrder = [{
                                "ChannelId": leanSupplyChannelId,
                                "ChannelOrderId": bpOrder.reference !== undefined ? bpOrder.reference : "",
                                "ERPOrderId": bpOrder.id,
                                "FulfillmentStatus": "Received",
                                "OrderHandle": orderHandle,
                                "OrderStatus": "Entered",
                                "ProcessedDate": processDate,
                                "LastUpdated": processDate
                            }];
                            //console.log(leanSupplyOrder);
                            const response = await axios.put(submittedOrdersURL, leanSupplyOrder, {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            });
                            //console.log(response);
                            /////////////////////////////////////////////////////////////

                            //Create ashlin order record
                            let SalesChannel;
                            if (bpOrder.assignment.current.channelId !== undefined) {
                                const sc = channelsMapping.find(s => s.id === bpOrder.assignment.current.channelId);
                                if (sc) {
                                    SalesChannel = sc.name
                                } else {
                                    SalesChannel = "Unknown Sales Channel"
                                }
                            } else {
                                SalesChannel = "Unknown Sales Channel"
                            }
                            const processedBy = localStorage.getItem('user');
                            const ashlinOrderRecord = [{
                                "BrightpearlOrderId": bpOrder.id,
                                "Carrier": "USPS",
                                "Currency": bpOrder.currency.orderCurrencyCode,
                                "ProcessedDate": processDate,
                                "SaleChannelName": SalesChannel,
                                "SaleChannelOrderId": bpOrder.reference,
                                "ShippedDate": processDate,
                                "ShippedFrom": selectedRow.Warehouse,
                                "Status": "Shipped",
                                "TotalValue": bpOrder.totalValue.total,
                                "TrackingNumber": selectedRow.TrackingNumber,
                                "AttnName": bpOrder.parties.delivery.addressFullName,
                                "CompanyName": bpOrder.parties.delivery.companyName,
                                "PostalCode": bpOrder.parties.delivery.postalCode,
                                "ProcessedBy": processedBy,
                                "EmailAddress": bpOrder.parties.customer.email !== undefined ? bpOrder.parties.customer.email : "",
                                "EmailNotification": "NEED"
                            }];

                            //console.log(ashlinOrderRecord);
                            const orderRecordRes = await postOrderRecords(ashlinOrderRecord);

                            Promise.all([leanSupplyRes, brightPearlRes, updateRes]).then(async () => {
                                alert("Order Sent to Lean Supply");
                                if (this.state.attachBase64 !== undefined) {
                                    const recipients = this.state.ProformaEmail.Recipient.split("\n").map(email => email.trim()).filter(email => email !== "");
                                    const subject = this.state.ProformaEmail.Subject.replace("[bpOrder.id]", bpOrder.id);
                                    let emailBody = this.state.ProformaEmail.EmailBody.replace("[bpOrder.id]", bpOrder.id);
                                    emailBody = emailBody.replace("[orderHandle]", orderHandle);
                                    await sendPDFEmail(recipients, subject, emailBody, this.state.attachBase64, "PROFORMA_INVOICE.pdf");
                                }
                                this.setState({
                                    selectedRow,
                                    FEIN: "",
                                    creatingShipment: false,
                                    attachBase64: undefined

                                }, () => {
                                    $('#CreateManualShipmentModal').modal('hide');
                                })
                            });
                        }
                    }
                });
            });
        } catch (error) {
            handleError(error, "handleSendUSPSto3PL")
        }
    };
    handleSendUSPSLocal = async () => {

    };

    handleInsertNewLine = (newAshlinManualOrder) => {
        const ashlinManualOrderList = this.state.ashlinManualOrderList.slice();
        ashlinManualOrderList.unshift(newAshlinManualOrder);
        this.setState({
            ashlinManualOrderList
        })
    };

    handleReplaceSKU = (e, index) => {
        const selectedOrder = this.state.selectedOrder;
        selectedOrder.orderRows[index].productSku = e.target.value.toUpperCase();
        this.setState({
            selectedOrder
        }, () => console.log(selectedOrder.orderRows));

    };
    handleHtsCodeOnchange = (e, index) => {
        //console.log(e.target.value);
        const newHts = e.target.value;
        const selectedOrder = this.state.selectedOrder;

        if (selectedOrder.parties.delivery.countryIsoCode === "US") {
            selectedOrder.orderRows[index].HtsUs = newHts;
            const newUsDuty = this.state.HtsUs.find(h => h.HtsUs1 === newHts);
            //console.log(newUsDuty);
            selectedOrder.orderRows[index].UsDuty = newUsDuty.UsDuty;
        } else {
            selectedOrder.orderRows[index].HtsCa = newHts;
            const newCaDuty = this.state.HtsCa.find(h => h.HtsCa1 === newHts);
            //console.log(newCaDuty);
            selectedOrder.orderRows[index].CaDuty = newCaDuty.CaDuty;
        }
        this.setState({ selectedOrder });
    };
    handleDeleteRow = (e, index) => {
        //console.log(this.state);
        e.preventDefault();
        const selectedOrder = this.state.selectedOrder;
        selectedOrder.orderRows.splice(index, 1);
        this.setState({
            selectedOrder
        });
    };
    handleCheckFulFill = async () => {
        try {
            const selectedOrder = this.state.selectedOrder;
            const orderRows = selectedOrder.orderRows;
            //console.log(orderRows);
            const leanSupplyInventory = await getBlanceByWarehouseCode(14);
            let counter = 0;
            const fulFullRes = await orderRows.map(async o => {
                //console.log(o);
                if (o.productSku !== undefined) {
                    const needQty = parseInt(o.quantity.magnitude);
                    //HERE check Lean Supply
                    //console.log(leanSupplyInventory);
                    const stockLevel = await leanSupplyInventory.find(s => s.sku === o.productSku);
                    if (stockLevel === undefined) {
                        counter++
                    } else {
                        //console.log(stockLevel)
                        if (needQty > parseInt(stockLevel.qty)) {
                            counter++
                        }
                    }

                }
            });
            Promise.all(fulFullRes).then(() => {
                if (counter > 0) {
                    alert("Can't fulfill this order!");
                } else {
                    alert("This order can be fulfilled");
                    this.setState({
                        canFulfill: true
                    })
                }
            })
        } catch (error) {
            handleError(error, "handleCheckFulFill")
        }
    };
    handleShipLocal = async () => {
        try {
            const selectedRow = this.state.selectedRow;
            const selectedOrder = this.state.selectedOrder;
            let d = new Date();
            const date = d.toISOString().slice(0, 10);

            const note = `${selectedRow.ShippingCarrier} Tracking #: ${selectedRow.TrackingNumber}`;
            const ProductsTotal = selectedOrder.orderRows.map(row => {
                if (row.productSku === undefined)
                    return;
                return {
                    "productId": row.productId,
                    "salesOrderRowId": parseInt(row.rowId),
                    "quantity": parseInt(row.quantity.magnitude)
                }
            });
            const Products = ProductsTotal.filter(od => od !== undefined);
            const goodsOutNote = {
                "warehouses": [
                    {
                        "releaseDate": date,
                        "warehouseId": 2,
                        "transfer": false,
                        "products": Products

                    }
                ],
                "priority": false,
                "shippingMethodId": selectedOrder.delivery.shippingMethodId,
                "labelUri": ""
            };

            let confirmBox = window.confirm(`Do you confirm to ship locally?`);
            if (confirmBox) {
                //Update BP
                if (ENABLE_BRIGHTPEARL) {
                    await updateOrderStatus(selectedOrder.id, 33, note, this.state.brightpearlHeader);
                    //Create Goods-Out Note
                    await createGoodsOutNote(selectedOrder.id, goodsOutNote, this.state.brightpearlHeader);
                    await sleep(3000);
                    const goodsOutNoteId = await searchGoodsOutNote(selectedOrder.id, this.state.brightpearlHeader);
                    if (goodsOutNoteId !== null) {
                        //update goods out tracking reference
                        const newNote = {
                            "priority": false,
                            "shipping": {
                                "shippingMethodId": selectedOrder.delivery.shippingMethodId,
                                "reference": selectedRow.TrackingNumber,
                            },
                            "labelUri": ""
                        };
                        //console.log(newNote);
                        await updateGoodsOutNote(goodsOutNoteId, newNote, this.state.brightpearlHeader);
                        //update goods out event to shipped
                        //This event can be handled by allocated and invoice on BP
                        await postShippedEvent(goodsOutNoteId, this.state.brightpearlHeader);
                    }
                }

                //Each Carrier End of Date

                if (selectedRow.ShippingCarrier === "Canpar") {
                    const canparEndOfDayID = await postCanparEndOfDay(this.state.CanparCre);
                    if (canparEndOfDayID !== "At least one shipment required|At least one shipment required") {
                        const canparManifest = await getCanparManifest(canparEndOfDayID, this.state.CanparCre);
                        const manifest = base64ToNewTab(canparManifest, 'application/png');
                        await postManifest(manifest, `${selectedRow.BrightpearlOrderId}.pdf`, "Canpar", "Canpar");
                        const manifestURL = `https://ashlinapp.xyz/Manifests/Canpar/${selectedRow.BrightpearlOrderId}.pdf`;
                        const labelDetail = JSON.parse(selectedRow.LabelDetail);
                        labelDetail.manifest = manifestURL;
                        selectedRow.LabelDetail = JSON.stringify(labelDetail);
                        window.open(manifestURL, '_blank');
                    }
                    selectedRow.Status = "Closed";
                } else if (selectedRow.ShippingCarrier === "Canada Post") {
                    selectedRow.Status = "NeedCanadaPostEndOfDay";
                } else {
                    selectedRow.Status = "Closed";
                }

                await updateAshlinManualOrder(selectedRow);

                //Create ashlin order record
                const processDate = currentDateTime();

                let SalesChannel;
                if (selectedOrder.assignment.current.channelId !== undefined) {
                    const sc = channelsMapping.find(s => s.id === selectedOrder.assignment.current.channelId);
                    if (sc) {
                        SalesChannel = sc.name
                    } else {
                        SalesChannel = "Unknown Sales Channel"
                    }
                } else {
                    SalesChannel = "Unknown Sales Channel"
                }
                const processedBy = localStorage.getItem('user');
                const ashlinOrderRecord = [{
                    "BrightpearlOrderId": selectedOrder.id,
                    "Carrier": selectedRow.ShippingCarrier,
                    "Currency": selectedOrder.currency.orderCurrencyCode,
                    "ProcessedDate": processDate,
                    "SaleChannelName": SalesChannel,
                    "SaleChannelOrderId": selectedOrder.reference,
                    "ShippedDate": processDate,
                    "ShippedFrom": selectedRow.Warehouse,
                    "Status": "Shipped",
                    "TotalValue": selectedOrder.totalValue.total,
                    "TrackingNumber": selectedRow.TrackingNumber,
                    "AttnName": selectedOrder.parties.delivery.addressFullName,
                    "CompanyName": selectedOrder.parties.delivery.companyName,
                    "PostalCode": selectedOrder.parties.delivery.postalCode,
                    "ProcessedBy": processedBy,
                    "EmailAddress": selectedOrder.parties.customer.email !== undefined ? selectedOrder.parties.customer.email : "",
                    "EmailNotification": "NEED"
                }];

                //console.log(ashlinOrderRecord);
                const orderRecordRes = await postOrderRecords(ashlinOrderRecord);


                //send email   AshlinSalesEmail
                let shipTo = `${selectedOrder.parties.delivery.addressFullName}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.companyName}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.addressLine1} ${selectedOrder.parties.delivery.addressLine2}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.addressLine3}, ${selectedOrder.parties.delivery.addressLine4}, ${selectedOrder.parties.delivery.country}, ${selectedOrder.parties.delivery.postalCode}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.telephone}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.email}<br/>`;

                let billTo = `${selectedOrder.parties.billing.addressFullName}<br/>`;
                billTo += `${selectedOrder.parties.billing.companyName}<br/>`;
                billTo += `${selectedOrder.parties.billing.addressLine1} ${selectedOrder.parties.billing.addressLine2}<br/>`;
                billTo += `${selectedOrder.parties.billing.addressLine3}, ${selectedOrder.parties.billing.addressLine4}, ${selectedOrder.parties.billing.country}, ${selectedOrder.parties.billing.postalCode}<br/>`;
                billTo += `${selectedOrder.parties.billing.telephone}<br/>`;
                billTo += `${selectedOrder.parties.billing.email}<br/>`;

                const recipients = this.state.AshlinSalesEmail.Recipient.split("\n").map(email => email.trim()).filter(email => email !== "");
                let subject = this.state.AshlinSalesEmail.Subject.replace("[order_number]", selectedOrder.id);
                subject = subject.replace("[company_name]", selectedOrder.parties.billing.companyName !== undefined ? selectedOrder.parties.billing.companyName : "");
                let emailBody = this.state.AshlinSalesEmail.EmailBody.replace("[order_number]", selectedOrder.id);
                emailBody = emailBody.replace("[order_number]", selectedOrder.id);
                emailBody = emailBody.replace("[ship_to]", shipTo);
                emailBody = emailBody.replace("[bill_to]", billTo);
                emailBody = emailBody.replace("[po_number]", selectedOrder.reference);
                emailBody = emailBody.replace("[shipped_date]", processDate);
                emailBody = emailBody.replace("[carrier_code]", selectedRow.ShippingCarrier);
                emailBody = emailBody.replace("[service_code]", selectedRow.ShippingService);
                emailBody = emailBody.replace("[tracking_number]", selectedRow.TrackingNumber);
                await sendPDFEmail(recipients, subject, emailBody, '', '');

                this.setState({
                    selectedRow,
                }, () => {
                    $('#ConfirmManualShipmentModal').modal('hide');
                })
            }
        } catch (error) {
            handleError(error, "handleShipLocal")
        }
    };

    handleSendTo3PL = async () => {
        try {
            const crossDockOrder = this.state.crossDockOrder;
            const selectedRow = this.state.selectedRow;
            let d = new Date();
            const date = d.toISOString().slice(0, 10);
            let Comment = this.state.Comment;
            Comment += "  Please use the label from: " + selectedRow.LabelURL + " ";
            const selectedOrder = this.state.selectedOrder;
            const orderRows = selectedOrder.orderRows;
            const OrderDetailTotal = orderRows.map((ol, index) => {
                if (ol.productSku === undefined)
                    return;
                return {
                    "OrderLine": (++index).toString(),
                    "ItemCode": ol.productSku,
                    "ItemQty": parseInt(ol.quantity.magnitude),
                    "ItemName": ol.productName,
                    "ItemDescription": ol.productName,
                    "uofm": "EACH",
                    "per_pack": "1"
                }

            });
            const note = `${selectedRow.ShippingCarrier} Tracking #: ${selectedRow.TrackingNumber}`;
            const ProductsTotal = orderRows.map(row => {
                if (row.productSku === undefined)
                    return;
                return {
                    "productId": row.productId,
                    "salesOrderRowId": parseInt(row.rowId),
                    "quantity": parseInt(row.quantity.magnitude)
                }
            });
            let OrderDetail = OrderDetailTotal.filter(od => od !== undefined);
            if (crossDockOrder) {
                const totalBoxNumber = this.state.totalBoxNumber;
                OrderDetail = [
                    {
                        "OrderLine": "1",
                        "ItemCode": "CROSS DOCK BOX",
                        "ItemQty": totalBoxNumber,
                        "ItemName": "Cross Dock Box",
                        "ItemDescription": "Cross Dock Box",
                        "uofm": "EACH",
                        "per_pack": "1"
                    }
                ]
            }
            const Products = ProductsTotal.filter(od => od !== undefined);
            const goodsOutNote = {
                "warehouses": [
                    {
                        "releaseDate": date,
                        "warehouseId": 5,
                        "transfer": false,
                        "products": Products

                    }
                ],
                "priority": false,
                "shippingMethodId": selectedOrder.delivery.shippingMethodId,
                "labelUri": ""
            };
            let OrderItemMessage = "";
            let PackingInfo = " ***Preferred Packaging: BUBBLE PACK***";
            OrderDetail.forEach(o => {
                OrderItemMessage += o.ItemCode + '\n';
                const designCode = o.ItemCode.split('-')[0];
                const isCarton = this.state.DesignsPacking.find(d => d.DesignServiceCode === designCode);
                if (isCarton !== undefined) {
                    PackingInfo = " ***Preferred Packaging: CARTON***";
                }
            });
            let ShipTo;
            if (selectedRow.ShippingCarrier === "USPS") {
                ShipTo = {
                    "Name": "CHITCHATS EXPRESS INC",
                    "AddressLine1": "1735 Bayly St. Unit 7D",
                    "AddressLine2": "SHIPPING/RECEIVING - USPS SHIPMENTS",
                    "AddressLine3": "#ITEMS FOR USPS",
                    "City": "Pickering",
                    "State": "ON",
                    "CountryCode": "CA",
                    "ContactName": "CHITCHATS EXPRESS",
                    "ContactPhone": "905-492-3607",
                    "ContactPhoneExt": "",
                    "Zip": "L1W 3G7"
                }
            } else {
                ShipTo = {
                    "Name": selectedOrder.parties.delivery.companyName !== undefined ? selectedOrder.parties.delivery.companyName : "",
                    "AddressLine1": selectedOrder.parties.delivery.addressLine1,
                    "AddressLine2": selectedOrder.parties.delivery.addressLine2 !== undefined ? selectedOrder.parties.delivery.addressLine2 : "",
                    "AddressLine3": "",
                    "City": selectedOrder.parties.delivery.addressLine3.replace(',', ''),
                    "State": selectedOrder.parties.delivery.addressLine4,
                    "CountryCode": selectedOrder.parties.delivery.countryIsoCode,
                    "ContactName": selectedOrder.parties.delivery.addressFullName,
                    "ContactPhone": selectedOrder.parties.delivery.telephone,
                    "ContactPhoneExt": "",
                    "Zip": selectedOrder.parties.delivery.postalCode
                }
            }
            const order = {
                "OrderHeader": {
                    "OrderNumber": `Ashlin-${selectedOrder.id}`,
                    "EndCustomerOrderNo": selectedOrder.parties.customer.contactId,
                    "ThirdPartyOrderNo": selectedOrder.id,
                    "OrderDateCode": "CR",
                    "OrderDate": selectedOrder.createdOn.slice(0, 19).replace("T", " "),
                    "Comment": Comment + PackingInfo,
                    "ShipTo": ShipTo,
                    "ShipFrom": {
                        "Name": "WAREHOUSE"
                    },
                    "WarehouseCode": "14",//this.state.WarehouseCode,
                    "OrderStatus": "NEW",
                    "OrderType": "NO",
                    "Priority": "",//this.state.Priority,
                    "Version": ""
                },
                "OrderDetail": OrderDetail,
                "OrderDelivery": {
                    "ServiceCode": "STD",
                    "CarrierCode": "STD",
                }
            };
            if (selectedRow.ShippingCarrier === "Canpar") {
                order.OrderDelivery = {
                    "CarrierCode": "CANPAR",
                    "ServiceCode": "01",
                    "FreightCharges": "3RDPARTY",
                    "ChargeToAccount": "42074982"
                }
            } else if (selectedRow.ShippingCarrier === "Canada Post") {
                order.OrderDelivery = {
                    "CarrierCode": "CANP",
                    "ServiceCode": "DOM.EP",
                    "FreightCharges": "3RDPARTY",
                    "ChargeToAccount": "3950328"
                }
            }

            const data = SubmitOrderModel(order);
            //console.log(data);
            //console.log(goodsOutNote);
            let confirmBox = window.confirm(`Do you confirm to send to 3PL?`);
            if (confirmBox) {
                if (ENABLE_BRIGHTPEARL) {
                    await updateOrderStatus(selectedOrder.id, 33, note, this.state.brightpearlHeader);
                    //Create Goods-Out Note
                    await createGoodsOutNote(selectedOrder.id, goodsOutNote, this.state.brightpearlHeader);
                    await sleep(3000);
                    const goodsOutNoteId = await searchGoodsOutNote(selectedOrder.id, this.state.brightpearlHeader);
                    if (goodsOutNoteId !== null) {
                        //update goods out tracking reference
                        const newNote = {
                            "priority": false,
                            "shipping": {
                                "shippingMethodId": selectedOrder.delivery.shippingMethodId,
                                "reference": selectedRow.TrackingNumber,
                            },
                            "labelUri": ""
                        };
                        //console.log(newNote);
                        await updateGoodsOutNote(goodsOutNoteId, newNote, this.state.brightpearlHeader);
                        //update goods out event to shipped
                        //This event can be handled by allocated and invoice on BP
                        await postShippedEvent(goodsOutNoteId, this.state.brightpearlHeader);
                    }
                }

                if (ENABLE_LEANSUPPLY) {
                    const leanSupplyRes = await sendOrderToLeanSupply(data);
                    //console.log(leanSupplyRes);
                    const orderHandle = leanSupplyRes.data.OrderConfirm.OrderHandle;
                    alert("Order Sent to Lean Supply");
                    if (this.state.attachBase64 !== undefined) {
                        const recipients = this.state.ProformaEmail.Recipient.split("\n").map(email => email.trim()).filter(email => email !== "");
                        const subject = this.state.ProformaEmail.Subject.replace("[bpOrder.id]", selectedOrder.id);
                        let emailBody = this.state.ProformaEmail.EmailBody.replace("[bpOrder.id]", selectedOrder.id);
                        emailBody = emailBody.replace("[orderHandle]", orderHandle);
                        await sendPDFEmail(recipients, subject, emailBody, this.state.attachBase64, "PROFORMA_INVOICE.pdf");
                    }

                    //if this order is very urgent, it will send another email to 3pl
                    if (this.state.isUrgentShipment) {
                        const recipients = this.state.UrgentEmail.Recipient.split("\n").map(email => email.trim()).filter(email => email !== "");
                        const subject = this.state.UrgentEmail.Subject.replace("[orderHandle]", orderHandle);
                        let emailBody = this.state.UrgentEmail.EmailBody.replace("[bpOrder.id]", selectedOrder.id);
                        emailBody = emailBody.replace("[orderHandle]", orderHandle);
                        await sendPDFEmail(recipients, subject, emailBody, '', '');
                    }

                }

                //Each Carrier End of Date
                if (selectedRow.Status === "USPSCreated") {
                    selectedRow.Status = "DispatchedTo3PL";
                }
                else {
                    if (selectedRow.ShippingCarrier === "Canpar") {
                        const canparEndOfDayID = await postCanparEndOfDay(this.state.CanparCre);
                        if (canparEndOfDayID !== "At least one shipment required|At least one shipment required") {
                            const canparManifest = await getCanparManifest(canparEndOfDayID, this.state.CanparCre);
                            const manifest = base64ToNewTab(canparManifest, 'application/png');
                            await postManifest(manifest, `${selectedRow.BrightpearlOrderId}.pdf`, "Canpar", "Canpar");
                            const manifestURL = `https://ashlinapp.xyz/Manifests/Canpar/${selectedRow.BrightpearlOrderId}.pdf`;
                            // const labelDetail = JSON.parse(selectedRow.LabelDetail);
                            // labelDetail.manifest = manifestURL;
                            selectedRow.LabelDetail = selectedRow.LabelDetail + " | " + manifestURL;
                            //selectedRow.LabelDetail = JSON.stringify(labelDetail);
                            window.open(manifestURL, '_blank');
                        }
                        selectedRow.Status = "Closed";
                    } else if (selectedRow.ShippingCarrier === "Canada Post") {
                        selectedRow.Status = "NeedCanadaPostEndOfDay";
                    } else {
                        selectedRow.Status = "Closed";
                    }
                }
                await updateAshlinManualOrder(selectedRow);

                //Create ashlin order record
                const processDate = currentDateTime();
                let SalesChannel;
                if (selectedOrder.assignment.current.channelId !== undefined) {
                    const sc = channelsMapping.find(s => s.id === selectedOrder.assignment.current.channelId);
                    if (sc) {
                        SalesChannel = sc.name
                    } else {
                        SalesChannel = "Unknown Sales Channel"
                    }
                } else {
                    SalesChannel = "Unknown Sales Channel"
                }
                const processedBy = localStorage.getItem('user');

                const ashlinOrderRecord = [{
                    "BrightpearlOrderId": selectedOrder.id,
                    "Carrier": selectedRow.ShippingCarrier,
                    "Currency": selectedOrder.currency.orderCurrencyCode,
                    "ProcessedDate": processDate,
                    "SaleChannelName": SalesChannel,
                    "SaleChannelOrderId": selectedOrder.reference,
                    "ShippedDate": processDate,
                    "ShippedFrom": selectedRow.Warehouse,
                    "Status": "Shipped",
                    "TotalValue": selectedOrder.totalValue.total,
                    "TrackingNumber": selectedRow.TrackingNumber,
                    "AttnName": selectedOrder.parties.delivery.addressFullName,
                    "CompanyName": selectedOrder.parties.delivery.companyName,
                    "PostalCode": selectedOrder.parties.delivery.postalCode,
                    "ProcessedBy": processedBy,
                    "EmailAddress": selectedOrder.parties.customer.email !== undefined ? selectedOrder.parties.customer.email : "",
                    "EmailNotification": "NEED"
                }];

                //console.log(ashlinOrderRecord);
                const orderRecordRes = await postOrderRecords(ashlinOrderRecord);


                //send email   AshlinSalesEmail
                let shipTo = `${selectedOrder.parties.delivery.addressFullName}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.companyName}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.addressLine1} ${selectedOrder.parties.delivery.addressLine2}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.addressLine3}, ${selectedOrder.parties.delivery.addressLine4}, ${selectedOrder.parties.delivery.country}, ${selectedOrder.parties.delivery.postalCode}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.telephone}<br/>`;
                shipTo += `${selectedOrder.parties.delivery.email}<br/>`;

                let billTo = `${selectedOrder.parties.billing.addressFullName}<br/>`;
                billTo += `${selectedOrder.parties.billing.companyName}<br/>`;
                billTo += `${selectedOrder.parties.billing.addressLine1} ${selectedOrder.parties.billing.addressLine2}<br/>`;
                billTo += `${selectedOrder.parties.billing.addressLine3}, ${selectedOrder.parties.billing.addressLine4}, ${selectedOrder.parties.billing.country}, ${selectedOrder.parties.billing.postalCode}<br/>`;
                billTo += `${selectedOrder.parties.billing.telephone}<br/>`;
                billTo += `${selectedOrder.parties.billing.email}<br/>`;

                const recipients = this.state.AshlinSalesEmail.Recipient.split("\n").map(email => email.trim()).filter(email => email !== "");
                let subject = this.state.AshlinSalesEmail.Subject.replace("[order_number]", selectedOrder.id);
                subject = subject.replace("[company_name]", selectedOrder.parties.billing.companyName !== undefined ? selectedOrder.parties.billing.companyName : "");
                let emailBody = this.state.AshlinSalesEmail.EmailBody.replace("[order_number]", selectedOrder.id);
                emailBody = emailBody.replace("[order_number]", selectedOrder.id);
                emailBody = emailBody.replace("[ship_to]", shipTo);
                emailBody = emailBody.replace("[bill_to]", billTo);
                emailBody = emailBody.replace("[po_number]", selectedOrder.reference);
                emailBody = emailBody.replace("[shipped_date]", processDate);
                emailBody = emailBody.replace("[carrier_code]", selectedRow.ShippingCarrier);
                emailBody = emailBody.replace("[service_code]", selectedRow.ShippingService);
                emailBody = emailBody.replace("[tracking_number]", selectedRow.TrackingNumber);
                await sendPDFEmail(recipients, subject, emailBody, '', '');

                this.setState({
                    selectedRow,
                    isDownLoading: false,
                    attachBase64: undefined,
                    canFulfill: false,
                    Comment: "Please *** DO NOT use any shipping account ***, ",
                    isProcessing: false,
                    crossDockOrder: false,
                    totalBoxNumber: '1',
                }, () => {
                    $('#ConfirmManualShipmentModal').modal('hide');
                })
            }
        } catch (error) {
            handleError(error, "handleSendTo3PL")
        }

    };

    usps3plToChitChats = async () => {
        try {
            const toChitChatsOrders = this.state.ashlinManualOrderList.filter(o => o.Status === "DispatchedTo3PL" && o.ShippingCarrier === "USPS");
            //console.log(toChitChatsOrders);
            let shippingInfo = {
                ShippableDepthCm: 0,
                ShippableHeightCm: 0,
                ShippableWidthCm: 0,
                ShippableWeightGrams: 0,
                LeanSupplyOrderId: "",
                BrightpearlOrderId: "",
            };
            if (toChitChatsOrders.length > 0) {
                async.mapSeries(toChitChatsOrders, async (order, callback) => {
                    const selectedOrder = await getOrderDetailsByOrderID(order.BrightpearlOrderId, this.state.brightpearlHeader);
                    if (selectedOrder !== undefined) {
                        const temp = Object.entries(selectedOrder.orderRows);
                        selectedOrder.orderRows = Object.values(temp.map(arr => {
                            arr[1].rowId = arr[0];
                            return arr[1];
                        }));
                        const weightRows = Object.values(selectedOrder.orderRows).filter((row => row.productSku !== undefined && row.productSku !== "" && !row.productSku.includes('SVC') && !row.productSku.includes('Shipping')));
                        const res = await weightRows.map(async row => {
                            if (row.productSku !== undefined && row.productSku !== "" && !row.productSku.includes('SVC') && !row.productSku.includes('Shipping')) {
                                const designCode = (row.productSku.split('-'))[0];
                                const ashlinDesign = await getDesignByCode(designCode);
                                shippingInfo.ShippableDepthCm = shippingInfo.ShippableDepthCm < ashlinDesign.ShippableDepthCm ? parseInt(ashlinDesign.ShippableDepthCm) : shippingInfo.ShippableDepthCm;
                                shippingInfo.ShippableHeightCm = shippingInfo.ShippableHeightCm < ashlinDesign.ShippableHeightCm ? parseInt(ashlinDesign.ShippableHeightCm) : shippingInfo.ShippableHeightCm;
                                shippingInfo.ShippableWidthCm = shippingInfo.ShippableWidthCm < ashlinDesign.ShippableWidthCm ? parseInt(ashlinDesign.ShippableWidthCm) : shippingInfo.ShippableWidthCm;
                                shippingInfo.ShippableWeightGrams += parseInt((parseFloat(ashlinDesign.ShippableWeightGrams) * parseInt(row.quantity.magnitude)));
                                shippingInfo.BrightpearlOrderId += `${order.BrightpearlOrderId}_`;
                                //canpar min weight must be greater than 0.1
                                shippingInfo.ShippableWeightGrams += 0.1
                            }
                        });

                        shippingInfo.ShippableDepthCm = shippingInfo.ShippableDepthCm < 1 ? 1 : shippingInfo.ShippableDepthCm;
                        shippingInfo.ShippableHeightCm = shippingInfo.ShippableHeightCm < 1 ? 1 : shippingInfo.ShippableHeightCm;
                        shippingInfo.ShippableWidthCm = shippingInfo.ShippableWidthCm < 1 ? 1 : shippingInfo.ShippableWidthCm;
                    }
                    const leanSupplyID = await getOrderId(order.BrightpearlOrderId);
                    if (leanSupplyID !== "") {
                        shippingInfo.LeanSupplyOrderId += `${leanSupplyID},`;
                    }
                    await setTimeout(() => {
                        return callback(null, order);
                    }, 1000);
                }, async (err, orders) => {
                    const canparData = {
                        ShipFrom: {
                            "Name": "Lean Supply Solutions",
                            "AddressLine1": "3100 Caravelle Dr",
                            "AddressLine2": "",
                            "AddressLine3": "",
                            "City": "Mississauga",
                            "State": "ON",
                            "CountryCode": "CA",
                            "ContactName": "Customer Service Tedlo",
                            "ContactPhone": "4167488982",
                            "ContactPhoneExt": "",
                            "Zip": "L4V1K9"
                        },
                        ShipTo: {
                            "Name": "CHITCHATS EXPRESS INC",
                            "AddressLine1": "1735 Bayly St. Unit 7D",
                            "AddressLine2": "SHIPPING/RECEIVING - USPS SHIPMENTS",
                            "AddressLine3": "#ITEMS FOR USPS",
                            "City": "Pickering",
                            "State": "ON",
                            "CountryCode": "CA",
                            "ContactName": "CHITCHATS EXPRESS",
                            "ContactPhone": "9054923607",
                            "ContactPhoneExt": "",
                            "Zip": "L1W3G7"
                        },
                        PackageInfo: {
                            Weight: (shippingInfo.ShippableWeightGrams / 1000).toFixed(2),
                            Length: shippingInfo.ShippableHeightCm.toFixed(1),
                            Width: shippingInfo.ShippableWidthCm.toFixed(1),
                            Height: shippingInfo.ShippableDepthCm.toFixed(1),
                        },
                        Order: {
                            id: orders[0].BrightpearlOrderId
                        }
                    };
                    //console.log(canparData);
                    canparData.NumberOfBox = "1";
                    let message = `Do you confirm to send this shipment from 3PL to ChitChats?\n\n`;
                    message += `Brightpearl ID: ${shippingInfo.BrightpearlOrderId}\n`;
                    message += `Height(cm): ${shippingInfo.ShippableHeightCm}\n`;
                    message += `Width(cm): ${shippingInfo.ShippableWidthCm}\n`;
                    message += `Depth(cm): ${shippingInfo.ShippableDepthCm}\n`;
                    message += `Weight(g): ${shippingInfo.ShippableWeightGrams}\n`;
                    let confirmBox = window.confirm(message);
                    if (confirmBox) {
                        const LeanSupplyNumbers = shippingInfo.LeanSupplyOrderId.slice(0, -1);
                        const AshlinNumbers = shippingInfo.BrightpearlOrderId.slice(0, -1);
                        const canparShipment = await processCanparShipment(canparData, this.state.CanparCre);
                        //console.log(canparShipment)
                        const label = base64ToNewTab(canparShipment.labels[0], 'application/png');
                        const labelURL = `https://ashlinapp.xyz/Labels/Canpar/${AshlinNumbers}.png`;
                        const manifestURL = `https://ashlinapp.xyz/Manifests/Canpar/${AshlinNumbers}.pdf`;
                        await sleep(5000);
                        const canparEndOfDayID = await postCanparEndOfDay(this.state.CanparCre);
                        //console.log(canparEndOfDayID);
                        const canparManifest = await getCanparManifest(canparEndOfDayID, this.state.CanparCre);
                        const manifest = base64ToNewTab(canparManifest, 'application/png');
                        const labelDetail = {
                            "label": labelURL,
                            "manifest": manifestURL
                        };
                        const updateRes = orders.map(async order => {
                            order.ShippingCarrier += "|Canpar";
                            order.ShippingLabelId += `|${canparShipment.shipmentId}`;
                            order.TrackingNumber += `|${canparShipment.tracking}`;
                            order.LabelDetail = JSON.stringify(labelDetail);
                            order.LabelURL += `|${labelURL}`;
                            order.Status = "Closed";
                            await updateAshlinManualOrder(order);
                        });
                        Promise.all(updateRes).then(async () => {
                            await postLabel(label, `${AshlinNumbers}.png`, "Canpar");
                            await postManifest(manifest, `${AshlinNumbers}.pdf`, "Canpar", "Canpar");
                            window.open(labelURL, '_blank');
                            window.open(manifestURL, '_blank');

                            //send email
                            const recipients = ['archit.sandesara@consulero.com', 'ashlin@ashlinbpg.com', 'shragoobar@leansupplysolutions.com', 'cstedlo@leansupplysolutions.com'];
                            const subject = `Canpar Label for ChitChats USPS shipment, Order #${LeanSupplyNumbers}`;
                            const emailDetail = `Hi there,<br/><br/>
                                        Please use the attached Canpar label to ship Lean Supply #${LeanSupplyNumbers} to ChitChats for USPS orders<br/>
                                        You can also get label through ${labelURL} <br/><br/>
                                        Thanks <br/>`;

                            await sendPDFEmail(recipients, subject, emailDetail, canparShipment.labels[0], "CanparLabel.png");

                            const ashlinManualOrderList = this.state.ashlinManualOrderList
                            this.setState({
                                ashlinManualOrderList
                            })
                        })

                    }

                });
            }
        } catch (error) {
            handleError(error, "usps3plToChitChats")
        }
    };
    handleGenerateProforma = () => {
        $('#AshlinProformaInvoiceModal').modal('toggle');
    };
    handleDeleteProformaRow = (rowId) => {
        const selectedOrder = this.state.selectedOrder;
        selectedOrder.orderRows = selectedOrder.orderRows.filter(or => or.rowId !== rowId);
        this.setState({
            selectedOrder
        })
    };
    handleReplaceRowValue = (e, rowId) => {
        const selectedOrder = this.state.selectedOrder;
        const value = e.target.value;
        const row = selectedOrder.orderRows.find(or => or.rowId === rowId);
        row.HtsDescription = value;
        this.setState({
            selectedOrder
        })
    };
    handleScheduleOnChange = (e) => {
        const value = e.target.value;
        const name = e.target.name;
        const temp = parseInt(value);
        let start = parseInt(this.state.scheduleStart);
        let end = parseInt(this.state.scheduleEnd);
        if (name === "scheduleStart") {
            if (temp >= end) {
                end = temp + 100;
                start = temp;
            } else {
                start = temp;
            }
        } else {
            if (temp <= start) {
                end = temp;
                start = temp - 100;
            } else {
                end = temp;
            }
        }

        this.setState({
            scheduleStart: start.toString(),
            scheduleEnd: end.toString()
        })
    };

    setEndOfDay = (value) => {
        this.setState({
            showEndOfDay: value
        });
    }

    disabledDate = (current) => {
        return (current && current > moment().endOf('day')) || (current && current < moment().subtract(2, 'months'));
    }

    mergePDFFiles = async (pdfArray) => {

        PDFProvider.mergeBetweenPDF(pdfArray)
            .then((res) => {

                if (res && res.hasOwnProperty("pdfFile")) {
                    if (res.pdfFile) {

                        if (res.pdfNotMergedList.length !== pdfArray.length) {
                            const fileName = "output_merge_" + new Date().toISOString().replace(":", "_").replace("T", "_").replace("Z", "") + ".pdf"
                            saveSync(res.pdfFile, fileName);
                            return res.pdfFile;
                        }

                        if (res.pdfNotMergedList.length > 0) {
                            var tempMsg = "";
                            if (res.pdfNotMergedList.length > 0 && res.pdfNotMergedList.length === pdfArray.length) {
                                tempMsg = "No merge PDF output could be done. Following files have problem and need to be merged manually: " + res.pdfNotMergedList.join(", ")
                            } else {
                                tempMsg = "Following files have problem and need to be merged manually: " + res.pdfNotMergedList.join(", ")
                            }

                            console.log("[LOG] " + tempMsg)
                        }
                        else {
                            tempMsg = "Merge totally successfull and downloaded!"
                            console.log("[LOG] " + tempMsg)
                        }
                    }
                } else {
                    tempMsg = "Internal error at merging! Send this error to the developer in charge."
                    console.log(tempMsg)
                }
            })
            .catch((err) => {
                console.log("[LOG] " + err)
            })
    }

    render() {

        const { ashlinManualOrderList } = this.state;

        const EndOfDayModal = () => {

            const [loading, setLoading] = useState(false);
            const handleEndOfDay = async (startDate, endDate, warehouse, createManifest) => {
                startDate = moment(startDate).format("YYYY-MM-DD")
                endDate = moment(endDate).format("YYYY-MM-DD")
                //  console.log("End of Day clicked "+moment(startDate).format("YYYY-MM-DD")+"-"+warehouse);
                try {
                    //  const today = new Date().toJSON().slice(0, 10);
                    //     /********************** Canada Post *********************/
                    //const ashlinManualOrderList = this.state.ashlinManualOrderList;
                    var manifest = null;
                    if (createManifest) {
                        manifest = await postCanadaPostEndOfDay(moment(startDate).format("YYYY-MM-DD"), warehouse, this.state.CanadaPostCre);
                    } else {
                        manifest = await getCPManifestLinkBetweenDateRange(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), this.state.CanadaPostCre);
                    }

                    if (manifest !== null && manifest.length > 0) {
                        var pdfFiles = [];
                        await Promise.all(manifest.map(async url => {

                            const labelBlob = await getCanadaPostManifestFromArtifact(url, this.state.CanadaPostCre);
                            var file_object = new File([labelBlob], 'xyz.pdf', { type: 'application/pdf' });
                            pdfFiles.push(file_object);

                        }))
                        PDFProvider.mergeBetweenPDF(pdfFiles)
                            .then((res) => {
                                if (res && res.hasOwnProperty("pdfFile")) {
                                    if (res.pdfFile) {
                                        //  console.log("after merge ");
                                        var fileName = "";
                                        if (createManifest) {
                                            fileName = "EndOfDay_" + moment(startDate).format("YYYY_MM_DD") + ".pdf"
                                        } else {
                                            fileName = "Manifest_between" + moment(startDate).format("YYYY_MM_DD") + "_and_" + moment(endDate).format("YYYY_MM_DD") + ".pdf";
                                        }
                                        saveSync(res.pdfFile, fileName);
                                    }

                                }
                            })
                        //  await postManifest(pdfFile, `Ashlin-${today}.pdf`, today, "CanadaPost");
                        //  const manifestURL = `https://ashlinapp.xyz/Manifests/CanadaPost/${today}/Ashlin-${today}.pdf`;
                        // window.open(manifestURL, '_blank');
                        if (createManifest) {
                            var rowsToUpdate = null;
                            if (warehouse === "3PL") {
                                rowsToUpdate = ashlinManualOrderList.filter(o => o.ShippingCarrier === "Canada Post" && (o.Status === "NeedCanadaPostEndOfDay" || o.Status === "LabelCompleted") && o.LabelURL.includes("3PL") && o.LastUpdated.includes(moment(startDate).format("YYYY-MM-DD")));
                            } else if (warehouse === "Ashlin") {
                                rowsToUpdate = ashlinManualOrderList.filter(o => o.ShippingCarrier === "Canada Post" && (o.Status === "NeedCanadaPostEndOfDay" || o.Status === "LabelCompleted") && o.LabelURL.includes("Ashlin") && o.LastUpdated.includes(moment(startDate).format("YYYY-MM-DD")));
                            }
                            if (rowsToUpdate !== null) {
                                const updateCanadaPost = await [...rowsToUpdate].map(async row => {
                                    row.Status = 'Closed';
                                    await updateAshlinManualOrder(row);
                                });


                                const ashlinManualOrderList = await getAshlinManualOrders()
                                setLoading(false);

                                this.setState({
                                    ashlinManualOrderList: ashlinManualOrderList,
                                    showEndOfDay: false
                                })

                            } else {
                                setLoading(false);
                                this.setState({
                                    showEndOfDay: false
                                })
                            }
                        } else {
                            setLoading(false);
                        }

                    } else {
                        if (createManifest) {
                            message.error("No shipment available for manifest on " + startDate + ".", 1);
                        } else {
                            message.error("No Manifest available between " + startDate + " and " + endDate, 1);
                        }
                        setLoading(false);
                    }


                    //     const canadaPostAshlin = ashlinManualOrderList.filter(o => o.ShippingCarrier === "Canada Post" && o.Status === "NeedCanadaPostEndOfDay" && o.LabelURL.includes("Ashlin"));
                    //     const canadaPost3PL = ashlinManualOrderList.filter(o => o.ShippingCarrier === "Canada Post" && o.Status === "NeedCanadaPostEndOfDay" && o.LabelURL.includes("3PL"));
                    //     console.log(canadaPostAshlin.length + " " + canadaPost3PL.length);

                    //     if (canadaPostAshlin.length > 0) {
                    //         const manifest = await postCanadaPostEndOfDay("Ashlin", this.state.CanadaPostCre);
                    //         console.log(manifest);
                    //         if (manifest !== null) {
                    //             const labelBlob = await getCanadaPostLabel(manifest, this.state.CanadaPostCre);
                    //             await postManifest(labelBlob, `Ashlin-${today}.pdf`, today, "CanadaPost");
                    //             const manifestURL = `https://ashlinapp.xyz/Manifests/CanadaPost/${today}/Ashlin-${today}.pdf`;
                    //             window.open(manifestURL, '_blank');
                    //         } else {
                    //             message.error('No shipment available for manifest.', 4);
                    //         }
                    //     }


                    //     if (canadaPost3PL.length > 0) {
                    //         console.log()
                    //         const manifest = await postCanadaPostEndOfDay("3PL", this.state.CanadaPostCre);
                    //         if (manifest !== null) {
                    //             const labelBlob = await getCanadaPostLabel(manifest, this.state.CanadaPostCre);
                    //             await postManifest(labelBlob, `3PL-${today}.pdf`, today, "CanadaPost");
                    //             const manifestURL = `https://ashlinapp.xyz/Manifests/CanadaPost/${today}/3PL-${today}.pdf`;
                    //             window.open(manifestURL, '_blank');
                    //         } else {
                    //             message.error('No shipment available for manifest.', 4);
                    //         }
                    //     }

                } catch (error) {
                    console.log(error);
                    handleError(error, "handleEndOfDay")
                }
                /********************** Canada Post *********************/
            };

            return (
                <Modal
                    title={null}
                    centered
                    visible={this.state.showEndOfDay}
                    onCancel={() => this.setEndOfDay(false)}
                    footer={null}
                // okButtonProps={{ loading: loading }}
                // okText="OK"
                // cancelText="Cancel"
                // onOk={() => {
                //     form
                //         .validateFields()
                //         .then((values) => {
                //             setLoading(true);
                //             handleEndOfDay(values);
                //         })
                //         .catch((info) => {
                //             console.log('Validate Failed:', info);
                //         });
                // }}
                >

                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Create Manifest" key="1">
                            <Form
                                onFinish={(values) => {
                                    const date = moment(values.date).format('LL')
                                    if (window.confirm(`Do you confirm to create Manifest for all the Canada Post shipment labels created on ${date}?`)) {
                                        setLoading(true);
                                        handleEndOfDay(values.date, values.date, values.warehouse, true);
                                    }
                                }}
                                layout="horizontal"
                                style={{ margin: "0", padding: "0" }}
                                name="form_in_modal"

                            >
                                <Row style={{ marginTop: "20px" }}>
                                    <Col span={12} >
                                        <Form.Item
                                            name="date"
                                            label="Date"
                                            initialValue={moment()}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input the date!',
                                                },
                                            ]}

                                        >

                                            <DatePicker
                                                size={'large'}
                                                allowClear={false}
                                                disabledDate={this.disabledDate}
                                                format="YYYY-MM-DD"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="warehouse"
                                            label=""
                                            initialValue="3PL"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please select from the drop down!',
                                                },
                                            ]}
                                        >
                                            <Select size={'large'}>
                                                <Option value="3PL">3PL</Option>
                                                <Option value="Ashlin">Ashlin</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row type="flex">
                                    <Form.Item
                                        wrapperCol={{
                                            span: 24,
                                            offset: 12
                                        }}
                                    >

                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            size="large"
                                            loading={loading}
                                        >
                                            Create Manifest for End of Day
                  </Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </TabPane>
                        <TabPane tab="Download Existing Manifest" key="2">
                            <Form
                                onFinish={(values) => {
                                    setLoading(true);
                                    //  console.log(values);
                                    handleEndOfDay(values.date_range[0], values.date_range[1], "", false);
                                }}
                                layout="horizontal"
                                style={{ margin: "0", padding: "0" }}
                                name="form_in_modal"

                            >
                                <Row style={{ marginTop: "20px", marginLeft: "15px" }}>
                                    <Col span={24} >
                                        <Form.Item
                                            name="date_range"
                                            label="Date Range"

                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input the date!',
                                                },
                                            ]}

                                        >
                                            <RangePicker size={'large'} disabledDate={this.disabledDate} />
                                        </Form.Item>
                                        <Row type="flex">
                                            <Form.Item
                                                wrapperCol={{
                                                    span: 24,
                                                    offset: 12
                                                }}
                                            >

                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                    loading={loading}
                                                >
                                                    Download Existing Manifest
                  </Button>
                                            </Form.Item>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>


                        </TabPane>
                        {/* <TabPane tab="Tab 3" key="3">
      Content of Tab Pane 3
    </TabPane> */}
                    </Tabs>


                </Modal>

            )
        }

        return (
            <div className='container-fluid row'>
                <EndOfDayModal />
                <div className={'col-3'}>
                    <MainSideBar />
                </div>
                <div className={'col'}>
                    <br />
                    <h1 className={"text-center"}> Ashlin Manual Orders </h1>
                    {this.state.isUpLoading && <div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                    {this.state.isDownLoading && <div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Downloading Data...</div>}
                    <div className="row">
                        <div className="col-4">
                            <button type={'button'} className={'btn btn-danger btn-block'} data-toggle="modal" data-target="#pullOrderFromBrightpearlModal" >Load Order from Brightpearl</button>
                        </div>
                        <div className="col-4">
                            <button type={'button'} className={'btn btn-primary btn-block'} onClick={() => { this.setEndOfDay(true) }} >End of Day</button>
                        </div>
                        <div className="col-4">
                            <button type={'button'} className={'btn btn-success btn-block'} data-toggle="modal" data-target="#CreateNewManualOrderModal" >Create New order</button>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-4">
                            {/*<button type={'button'} className={'btn btn-danger btn-block'} >Load Order from Brightpearl</button>*/}
                        </div>
                        <div className="col-4">
                            <button type={'button'} className={'btn btn-primary btn-block'} onClick={this.usps3plToChitChats} >USPS 3PL to ChitChats</button>
                        </div>
                        <div className="col-4">
                            {/*<button type={'button'} className={'btn btn-success btn-block'}>Create New order</button>*/}
                        </div>
                    </div>
                    <div style={{ textAlign: "center" }}>
                        <CreateNewManualOrderModal
                            skuNumberList={this.state.skuNumberList} taxCodes={this.state.taxCodes}
                            brightpearlHeader={this.state.brightpearlHeader}
                            BrightpearlChannelInfos={this.state.BrightpearlChannelInfos}
                            brightPearlWarehouses={this.state.brightPearlWarehouses}
                            handleInsertNewLine={this.handleInsertNewLine}
                            asiToken={this.state.asiToken}
                        />
                        <ViewManualOrderDetailModal order={this.state.selectedOrder} selectedRow={this.state.selectedRow} handleClose={this.handleCloseViewOrderModal} />
                        <CreateManualOrderShipment selectedGoogleMapInfo={this.state.selectedGoogleMapInfo} order={this.state.selectedOrder} selectedRow={this.state.selectedRow} brightPearlWarehouses={this.state.brightPearlWarehouses}
                            handleClose={this.handleCloseCreateShipmentModal} shipmentOnChange={this.handleCreateShipmentWarehouseOnChange} simpleOnchange={this.handleSimpleOnChange}
                            shippingInfo={this.state.shippingInfo} shippingInfoOnchange={this.handleShippingInfoOnchange} use3rdPartyAccount={this.state.use3rdPartyAccount} declareValueInsurance={this.state.declareValueInsurance}
                            thirdPartyAccountNum={this.state.thirdPartyAccountNum} createShipment={this.handleCreateShipment}
                            selectedCarrier={this.state.selectedCarrier} carrierOnchange={this.handleCarrierOnchange} selectedOptions={this.state.selectedOptions} creatingShipment={this.state.creatingShipment}
                            getRates={this.handleGetRates} isCommercialOrder={this.state.isCommercialOrder} shippingRates={this.state.shippingRates}
                            sendUSPSto3PL={this.handleSendUSPSto3PL} sendUSPSLocal={this.handleSendUSPSLocal} FEIN={this.state.FEIN} attachBase64={this.state.attachBase64}
                            orderItems={this.state.orderItems}
                            numberOfBox={this.state.numberOfBox}
                            Reference1={this.state.reference1}
                            Reference2={this.state.reference2}
                            CanparRate={this.state.CanparRate} CanadaPostRate={this.state.CanadaPostRate} UPSRate={this.state.UPSRate} FedExRate={this.state.FedExRate} PurolatorRate={this.state.PurolatorRate}
                            selectedShippingMethod={this.state.selectedShippingMethod}

                            thirdPartyAccountAddressName={this.state.thirdPartyAccountAddressName}
                            thirdPartyAccountAddress1={this.state.thirdPartyAccountAddress1}
                            thirdPartyAccountAddress2={this.state.thirdPartyAccountAddress2}
                            thirdPartyAccountAddress3={this.state.thirdPartyAccountAddress3}
                            thirdPartyAccountAddressCity={this.state.thirdPartyAccountAddressCity}
                            thirdPartyAccountAddressStateCode={this.state.thirdPartyAccountAddressStateCode}
                            thirdPartyAccountAddressCountryCode={this.state.thirdPartyAccountAddressCountryCode}
                            thirdPartyAccountAddressPostalCode={this.state.thirdPartyAccountAddressPostalCode} scheduleOnChange={this.handleScheduleOnChange}
                            isProcessing={this.state.isProcessing} scheduleStart={this.state.scheduleStart} scheduleEnd={this.state.scheduleEnd}
                        />

                        <ConfirmManualOrderShipment
                            order={this.state.selectedOrder} selectedRow={this.state.selectedRow} brightPearlWarehouses={this.state.brightPearlWarehouses}
                            handleClose={this.handleCloseConfirmShipmentModal} shipmentOnChange={this.handleCreateShipmentWarehouseOnChange} simpleOnchange={this.handleSimpleOnChange}
                            FEIN={this.state.FEIN} attachBase64={this.state.attachBase64}
                            orderItems={this.state.orderItems}
                            HtsCa={this.state.HtsCa}
                            HtsUs={this.state.HtsUs}
                            replaceSKU={this.handleReplaceSKU}
                            htsCodeOnchange={this.handleHtsCodeOnchange}
                            deleteRow={this.handleDeleteRow}
                            checkFulFill={this.handleCheckFulFill}
                            canFulfill={this.state.canFulfill}
                            shipLocal={this.handleShipLocal}
                            sendTo3PL={this.handleSendTo3PL}
                            Comment={this.state.Comment}
                            crossDockOrder={this.state.crossDockOrder}
                            isUrgentShipment={this.state.isUrgentShipment}
                            totalBoxNumber={this.state.totalBoxNumber}
                            generateProforma={this.handleGenerateProforma}
                        />


                        <ProformaInvoice bpOrder={this.state.selectedOrder} FEIN={this.state.FEIN} shippingInfo={this.state.shippingInfo} replaceRowValue={this.handleReplaceRowValue}
                            thirdPartyAccountNum={this.state.thirdPartyAccountNum} selectedCarrier={this.state.selectedCarrier} deleteProformaRow={this.handleDeleteProformaRow}
                            AshlinInfo={this.state.AshlinInfo} Signature={this.state.Signature} orderAttachFile={this.handleOrderAttachFile}
                        />

                        <PullOrderFromBrightpearl brightpearlHeader={this.state.brightpearlHeader}
                            BrightpearlChannelInfos={this.state.BrightpearlChannelInfos}
                            brightPearlWarehouses={this.state.brightPearlWarehouses}
                            ashlinManualOrderList={this.state.ashlinManualOrderList}
                            handleInsertNewLine={this.handleInsertNewLine}
                        />
                        <ReactTable
                            data={ashlinManualOrderList}
                            filterable
                            defaultFilterMethod={(filter, row) =>
                                String(row[filter.id]) === filter.value}
                            columns={[
                                {
                                    Header: "Order Information",
                                    headerClassName: 'ashlin-react-table-header-info',
                                    columns: [
                                        {
                                            Header: "Sales Channel",
                                            id: 'SalesChannel',
                                            width: 300,
                                            accessor: d => d.SaleChannelId,
                                            Cell: ({ value }) => {
                                                let backgroundColor = "";
                                                switch (value) {
                                                    // case '27D3CF14-1F25-46E0-B31D-8A98074CB3F5':
                                                    //     value = 'Quotes';
                                                    //     backgroundColor = "#808080";
                                                    //     break;
                                                    case 'FC216960-10E3-44DF-9AC6-861A7BC07986':
                                                        value = 'Walmart Canada';
                                                        backgroundColor = "#167ef6";
                                                    case '8CD644DA-94FC-46A6-8B09-59CB15874760':
                                                        value = 'WALMART.CA Marketplace';
                                                        backgroundColor = "#167ef6";
                                                        break;
                                                    case '6D1DAF13-1912-4EF1-A22E-67FE3205BE37':
                                                        value = 'Staples.ca';
                                                        backgroundColor = "#e4606d";
                                                        break;
                                                    case '1FB910A2-DDEF-46DD-B99C-15307F27080B':
                                                        value = 'StaplesAdvantage CAN';
                                                        backgroundColor = "#ff4b90";
                                                        break;
                                                    case 'F0B45479-3727-498D-B214-54608FE3CB99':
                                                        value = "Hudson's Bay Company";
                                                        backgroundColor = "#e1b143";
                                                        break;
                                                    case '4753CC0F-74C1-4684-BA0E-05E0238963C6':
                                                        value = "Mirakl Best Buy Canada Marketplace";
                                                        backgroundColor = "#0A4ABF";
                                                        break;
                                                    case '7648DC8E-4709-4764-AF12-9CD72576B5DB':
                                                        value = 'Best Buy Canada';
                                                        backgroundColor = "#1c3ffa";
                                                        break;
                                                    case 'C6955887-F0B9-401F-B71D-27723E749D6B':
                                                        value = 'Corporate US';
                                                        backgroundColor = "#10c0fa";
                                                        break;
                                                    case '27D3CF14-1F25-46E0-B31D-8A98074CB3F5':
                                                        value = 'Corporate Canada';
                                                        backgroundColor = "#31fae6";
                                                        break;
                                                    case '75678D60-9DA0-4974-942F-E04008CD0DA1':
                                                        value = 'eBay: ashlinleather.com - ebay.ca Canada';
                                                        backgroundColor = "#86B817";
                                                        break;
                                                    case '92B1F329-8D2E-46B3-ACE1-6E06EF97FE8E':
                                                        value = 'AshlinLeather.com--AMAZON.CA CANADA';
                                                        backgroundColor = "#FF9900";
                                                        break;
                                                    case '7653C23A-20A0-4E4B-911B-D2CB84B514AA':
                                                        value = 'AshlinLeather.com-AMAZON.COM USA';
                                                        backgroundColor = "#FF9900";
                                                        break;
                                                    case 'FD2D9514-C2E3-43E8-98FB-358E8BBC5674':
                                                        value = 'AshlinLeather.com-AMAZON.CA - CANADA';
                                                        backgroundColor = "#FF9900";
                                                        break;
                                                    case 'FBFD012B-AD4F-4535-A71B-FBC177D9E2D7':
                                                        value = 'AshlinLeather.com--AMAZON.COM USA';
                                                        backgroundColor = "#FF9900";
                                                        break;
                                                    case '82F44B4B-5767-4A9E-B43F-1D33399A3FFC':
                                                        value = 'ASHLIN Leather - BIG COMMERCE';
                                                        backgroundColor = "#f907fa";
                                                        break;
                                                    case 'E7446E77-71E0-4592-9DCB-07E7398C88A1':
                                                        value = 'POPPYBUZZ - BIGCOMMERCE';
                                                        backgroundColor = "#f907fa";
                                                        break;
                                                    case '453B98AB-00D4-4824-9B37-A2C34ED5ADEE':
                                                        value = 'AMAZON VENDOR CENTRAL';
                                                        backgroundColor = "#FF9900";
                                                        break;
                                                    case '2A5CB1F0-E0F0-4B66-BFD7-137FF8F1118A':
                                                        value = 'ETSY.COM';
                                                        backgroundColor = "#1492df";
                                                        break;
                                                    default:
                                                        value = 'Channel Info Missing'
                                                }
                                                return (
                                                    <div
                                                        style={{
                                                            backgroundColor: backgroundColor
                                                        }}
                                                    >{value}
                                                    </div>)
                                            },
                                            filterMethod: (filter, row) => {
                                                switch (filter.value) {
                                                    case 'all':
                                                        return true;
                                                    // case 'Corporate Canada':
                                                    //     return row[filter.id] === "27D3CF14-1F25-46E0-B31D-8A98074CB3F5";
                                                    case 'Walmart Canada':
                                                        return row[filter.id] === "FC216960-10E3-44DF-9AC6-861A7BC07986";
                                                    case 'WALMART.CA Marketplace':
                                                        return row[filter.id] === "8CD644DA-94FC-46A6-8B09-59CB15874760";
                                                    case 'Staples.ca':
                                                        return row[filter.id] === "6D1DAF13-1912-4EF1-A22E-67FE3205BE37";
                                                    case 'StaplesAdvantage CAN':
                                                        return row[filter.id] === "1FB910A2-DDEF-46DD-B99C-15307F27080B";
                                                    case "Hudson's Bay Company":
                                                        return row[filter.id] === "F0B45479-3727-498D-B214-54608FE3CB99";
                                                    case "Mirakl Best Buy Canada Marketplace":
                                                        return row[filter.id] === "4753CC0F-74C1-4684-BA0E-05E0238963C6";
                                                    case 'Best Buy Canada':
                                                        return row[filter.id] === "7648DC8E-4709-4764-AF12-9CD72576B5DB";

                                                    case 'Corporate US':
                                                        return row[filter.id] === "C6955887-F0B9-401F-B71D-27723E749D6B";
                                                    case 'Corporate Canada':
                                                        return row[filter.id] === "27D3CF14-1F25-46E0-B31D-8A98074CB3F5";
                                                    case 'eBay: ashlinleather.com - ebay.ca Canada':
                                                        return row[filter.id] === "75678D60-9DA0-4974-942F-E04008CD0DA1";
                                                    case 'AshlinLeather.com--AMAZON.CA CANADA':
                                                        return row[filter.id] === "92B1F329-8D2E-46B3-ACE1-6E06EF97FE8E";
                                                    case 'AshlinLeather.com-AMAZON.COM USA':
                                                        return row[filter.id] === "7653C23A-20A0-4E4B-911B-D2CB84B514AA";
                                                    case 'AshlinLeather.com-AMAZON.CA - CANADA':
                                                        return row[filter.id] === "FD2D9514-C2E3-43E8-98FB-358E8BBC5674";
                                                    case 'AshlinLeather.com--AMAZON.COM USA':
                                                        return row[filter.id] === "FBFD012B-AD4F-4535-A71B-FBC177D9E2D7";
                                                    case 'ASHLIN Leather - BIG COMMERCE':
                                                        return row[filter.id] === "82F44B4B-5767-4A9E-B43F-1D33399A3FFC";
                                                    case 'POPPYBUZZ - BIGCOMMERCE':
                                                        return row[filter.id] === "E7446E77-71E0-4592-9DCB-07E7398C88A1";
                                                    case 'AMAZON VENDOR CENTRAL':
                                                        return row[filter.id] === "453B98AB-00D4-4824-9B37-A2C34ED5ADEE";
                                                    default:
                                                        return row[filter.id] === ""
                                                }
                                            },
                                            Filter: ({ filter, onChange }) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{ width: "100%" }}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">Show All</option>
                                                    <option value="Staples.ca">Staples Exchange</option>
                                                    <option value="StaplesAdvantage CAN">Staples Advantage</option>
                                                    <option value="Walmart Canada">Walmart Canada</option>
                                                    <option value="WALMART.CA Marketplace">WALMART.CA Marketplace</option>
                                                    <option value="Hudson's Bay Company">Hudson's Bay</option>
                                                    <option value="Mirakl Best Buy Canada Marketplace">Walmart Marketplace</option>
                                                    <option value="Best Buy Canada">Best Buy</option>

                                                    <option value="Corporate US">Corporate US</option>
                                                    <option value="Corporate Canada">Corporate Canada</option>
                                                    <option value="eBay: ashlinleather.com - ebay.ca Canada">eBay: ashlinleather.com - ebay.ca Canada</option>
                                                    <option value="AshlinLeather.com--AMAZON.CA CANADA">AshlinLeather.com--AMAZON.CA CANADA</option>
                                                    <option value="AshlinLeather.com-AMAZON.COM USA">AshlinLeather.com-AMAZON.COM USA</option>
                                                    <option value="AshlinLeather.com-AMAZON.CA - CANADA">AshlinLeather.com-AMAZON.CA - CANADA</option>
                                                    <option value="AshlinLeather.com--AMAZON.COM USA">AshlinLeather.com--AMAZON.COM USA</option>
                                                    <option value="ASHLIN Leather - BIG COMMERCE">ASHLIN Leather - BIG COMMERCE</option>
                                                    <option value="POPPYBUZZ - BIGCOMMERCE">POPPYBUZZ - BIGCOMMERCE</option>
                                                </select>

                                        },

                                        {
                                            Header: "Order ID",
                                            id: 'OrderId',
                                            accessor: d => d.SaleChannelOrderId,
                                            filterMethod: (filter, row) =>
                                                row[filter.id] !== undefined ? row[filter.id].startsWith(filter.value) : ""
                                        },

                                        {
                                            Header: "Brightpearl ID",
                                            id: 'BPId',
                                            accessor: d => d.BrightpearlOrderId,
                                            filterMethod: (filter, row) =>
                                                row[filter.id] !== undefined ? row[filter.id].startsWith(filter.value) : ""
                                        },

                                        {
                                            Header: "Last Updated",
                                            id: 'LastUpdated',
                                            accessor: d => d.LastUpdated,
                                            filterMethod: (filter, row) =>
                                                row[filter.id] !== undefined ? row[filter.id].startsWith(filter.value) : "",
                                            Cell: ({ value }) => {
                                                return <div>{value.replace("T", " ")}</div>
                                            }
                                        },

                                        {
                                            Header: "Status",
                                            id: 'Status',
                                            accessor: d => d.Status,
                                            filterMethod: (filter, row) => {
                                                if (filter.value === "all") {
                                                    return true;
                                                }
                                                if (filter.value === "OrderCreated") {
                                                    return row[filter.id] === "OrderCreated";
                                                }
                                                if (filter.value === "USPSCreated") {
                                                    return row[filter.id] === "USPSCreated";
                                                }
                                                if (filter.value === "LabelCompleted") {
                                                    return row[filter.id] === "LabelCompleted";
                                                }
                                                if (filter.value === "Closed") {
                                                    return row[filter.id] === "Closed";
                                                }
                                                return row[filter.id] === "";
                                            },
                                            Filter: ({ filter, onChange }) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{ width: "100%" }}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">Show All</option>
                                                    <option value="OrderCreated">Order Created</option>
                                                    <option value="USPSCreated">USPS Created</option>
                                                    <option value="LabelCompleted">Label Completed</option>
                                                    <option value="Closed">Closed</option>
                                                </select>
                                        },
                                    ]
                                },
                                {
                                    Header: "Shipping Information",
                                    headerClassName: 'ashlin-react-table-header-info',
                                    columns: [
                                        {
                                            Header: "Shipping Carrier",
                                            id: 'ShippingCarrier',
                                            accessor: d => d.ShippingCarrier,
                                            filterMethod: (filter, row) =>
                                                row[filter.id] !== undefined ? row[filter.id].startsWith(filter.value) : ""
                                        },
                                        {
                                            Header: "Shipping Service",
                                            id: 'ShippingService',
                                            accessor: d => d.ShippingService,
                                            filterMethod: (filter, row) =>
                                                row[filter.id] !== undefined ? row[filter.id].startsWith(filter.value) : ""
                                        },
                                        {
                                            Header: "Tracking Number",
                                            id: 'TrackingNumber',
                                            accessor: d => d.TrackingNumber,
                                            filterMethod: (filter, row) =>
                                                row[filter.id] !== undefined ? row[filter.id].startsWith(filter.value) : ""
                                        },
                                        {
                                            Header: "Warehouse",
                                            id: 'Warehouse',
                                            accessor: d => d.Warehouse,
                                            filterMethod: (filter, row) => {
                                                if (filter.value === "all") {
                                                    return true;
                                                }
                                                if (filter.value === "Main warehouse") {
                                                    return row[filter.id] === "Main warehouse";
                                                }
                                                if (filter.value === "LS14 LeanSupply 3PL") {
                                                    return row[filter.id] === "LS14 LeanSupply 3PL";
                                                }
                                                if (filter.value === "AMAZON FBA CANADA") {
                                                    return row[filter.id] === "AMAZON FBA CANADA";
                                                }
                                                if (filter.value === "AMAZON FBA USA") {
                                                    return row[filter.id] === "AMAZON FBA USA";
                                                }
                                                if (filter.value === "AMAZON FBA AUSTRALIA") {
                                                    return row[filter.id] === "AMAZON FBA AUSTRALIA";
                                                }
                                                return row[filter.id] === "";
                                            },
                                            Filter: ({ filter, onChange }) =>
                                                <select
                                                    onChange={event => onChange(event.target.value)}
                                                    style={{ width: "100%" }}
                                                    value={filter ? filter.value : "all"}
                                                >
                                                    <option value="all">Show All</option>
                                                    <option value="Main warehouse">Main Warehouse</option>
                                                    <option value="LS14 LeanSupply 3PL">LeanSupply 3PL</option>
                                                    <option value="AMAZON FBA CANADA">AMAZON FBA CANADA</option>
                                                    <option value="AMAZON FBA USA">AMAZON FBA USA</option>
                                                    <option value="AMAZON FBA AUSTRALIA">AMAZON FBA AUSTRALIA</option>
                                                </select>
                                        }
                                    ]
                                },
                                {
                                    Header: "Action",
                                    headerClassName: 'ashlin-react-table-header-info',
                                    columns: [
                                        {
                                            Header: "ACTION",
                                            filterable: false,
                                            width: 200,
                                            Cell: (row) => {
                                                const status = row.original.Status;
                                                if (status === "OrderCreated") {
                                                    return <div>
                                                        <i title={'View Order'} className="fa fa-search fa-2x text-info" aria-hidden="true" onClick={() => this.handleViewOrder(row.original)} > </i>
                                                                &nbsp;&nbsp;
                                                             <i title={'Create Shipping Label'} className={'fa fa-sticky-note fa-2x text-muted'} onClick={() => this.handleCreateLabel(row.original)}> </i>
                                                    </div>

                                                } else if (status === "USPSCreated") {
                                                    return <div>
                                                        <i title={'View Order'} className="fa fa-search fa-2x text-info" aria-hidden="true" onClick={() => this.handleViewOrder(row.original)} > </i>
                                                        &nbsp;&nbsp;
                                                        <i title={'View Shipping Label'} className="fa fa-print fa-2x text-muted" aria-hidden="true" onClick={() => this.handleCheckLabel(row.original)} > </i>
                                                        &nbsp;&nbsp;
                                                        {/*<i title={'Create Shipping Label'} className={'fa fa-sticky-note fa-2x text-muted'} onClick={()=> this.handleCreateLabel(row.original)}> </i>*/}
                                                        {/*&nbsp;&nbsp;*/}
                                                        <i title={'Confirm Shipment'} className="fa fa-paper-plane fa-2x text-danger" aria-hidden="true" onClick={() => this.handleCompleteOrder(row.original)} > </i>
                                                    </div>
                                                }
                                                else if (status === "LabelCompleted") {
                                                    return <div>
                                                        <i title={'View Order'} className="fa fa-search fa-2x text-info" aria-hidden="true" onClick={() => this.handleViewOrder(row.original)} > </i>
                                                        &nbsp;&nbsp;
                                                        <i title={'View Shipping Label'} className="fa fa-print fa-2x text-muted" aria-hidden="true" onClick={() => this.handleCheckLabel(row.original)} > </i>
                                                        &nbsp;&nbsp;
                                                        <i title={'Void Shipping Label'} className="fa fa-ban fa-2x text-danger" aria-hidden="true" onClick={() => this.handleVoidLabel(row.original)} > </i>
                                                        &nbsp;&nbsp;
                                                        <i title={'Confirm Shipment'} className="fa fa-paper-plane fa-2x text-danger" aria-hidden="true" onClick={() => this.handleCompleteOrder(row.original)} > </i>
                                                    </div>
                                                }
                                                else {
                                                    return <div>
                                                        <i title={'View Order'} className="fa fa-search fa-2x text-info" aria-hidden="true" onClick={() => this.handleViewOrder(row.original)} > </i>
                                                        <i title={'View Shipping Label'} className="fa fa-print fa-2x text-muted" aria-hidden="true" onClick={() => this.handleCheckLabel(row.original)} > </i>
                                                    </div>
                                                }
                                            }
                                        }
                                    ]
                                }
                            ]}
                            defaultPageSize={10}
                            className="-striped -highlight orderTable"

                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default AshlinManualOrders;