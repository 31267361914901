import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
class Asi extends Component{
    render(){
        return (
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>
                    <embed src="https://espupdates.asicentral.com/login?401" style={{width:'100%',height:'100%'}}/>
                    <br />
                </div>
            </div>
        );
    }
}

export default Asi