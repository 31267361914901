import React from 'react';
import { Link } from 'react-router-dom';
const  ChannelSideBar =()=>{
    const path = window.location.pathname;
    const bigCommerceClassName = path==="/bigCommerce" ? "sidebar-selected" : "";
    const asiClassName = path==="/asi" ? "sidebar-selected" : "";
    const leanSupplyInventoryClassName = path==="/leanSupplyInventory" ? "sidebar-selected" : "";
    const amazonClassName = path==="/amazon" ? "sidebar-selected" : "";
    const brightpearlClassName = path==="/brightpearl" ? "sidebar-selected" : "";
    const inventoryTransferClassName = path==="/inventoryTransfer" ? "sidebar-selected" : "";
    return(
        <ul className="list-group ashlinSideBar">
            <Link to='/leanSupplyInventory'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${leanSupplyInventoryClassName}`} >
                    Lean Supply Inventory
                </button>
            </Link>
            <Link to='/inventoryTransfer'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${inventoryTransferClassName}`} >
                    Inventory Transfer
                </button>
            </Link>
            <Link to='/bigCommerce'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${bigCommerceClassName}`} >
                    Big Commerce
                </button>
            </Link>
            <Link to='/amazon'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${amazonClassName}`} >
                   Amazon
                </button>
            </Link>
            <Link to='/brightpearl'>
                <button className={`list-group-item d-flex justify-content-between align-items-center ${brightpearlClassName}`} >
                   Bright Pearl
                </button>
            </Link>
            {/*<Link to='/asi'>*/}
                {/*<button className={`list-group-item d-flex justify-content-between align-items-center ${asiClassName}`} >*/}
                    {/*ASI*/}
                {/*</button>*/}
            {/*</Link>*/}
        </ul>
    );

};
export default ChannelSideBar;