import React from 'react';
const  SkuDetailModal = ({sku})=>{
    return(
        <div className="modal fade" id="skuDetailModal" tabIndex="-1" role="dialog"
             aria-labelledby="skuDetailModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="skuDetailModalTitle">Product Information Detail</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>SKU: </span>{sku.Sku}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Brand: </span>{sku.Brand}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>UPC: </span>{sku.Upc}
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Material: </span>{sku.Material}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Color: </span>{sku.Color}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Gender: </span>{sku.Gender}
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Short Description: </span>{sku.ShortDescription}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>Price: </span>{sku.Price}
                            </div>
                            <div className={'col'}>
                                <span className='font-weight-bold '>WeightGrams: </span>{sku.WeightGrams}
                            </div>
                        </div>
                        <hr/>
                        <span className='font-weight-bold '>Long Description: </span>{sku.ExtendedDescription}
                        <hr/>
                        { sku.Urls !==undefined &&
                            sku.Urls.HostingerUrls.BASE.map((url,index)=>{
                                return <img src={url} key={index} style={{width:'250px',height:'250px'}} className={'col'} />
                            })
                        }
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">CLOSE</button>
                        {/*<button type="button" className="btn btn-danger" onClick={props.onCreate}>Confirm and Submit</button>*/}
                    </div>
                </div>
            </div>
        </div>
    )

};
export default SkuDetailModal;
