import React, {Component} from 'react';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import jsPDF from 'jspdf';
import moment from 'moment';
class AshlinInvoice extends Component{

    constructor(props){
        super(props);
        this.state={
            dateOfExportation:null,
            exportReference:'',
            countryOfExport:'',
            countryOfOrigin:'CANADA',
            fedexInternationAWB:'',
            box:['','','','','','','','','','','','','','',''],
            HtsDes:['','','','','','','','','','','','','','',''],
            totalBoxes:0,
            totalValue:0
        }
    }
    handleChangeExportationDate=(date)=>{
        let totalValue = 0;
        if(this.props.orderRows!==undefined){
            this.props.orderRows.forEach(ol=>{
                totalValue += parseFloat(ol.rowValue.rowNet.value);
            });
        }
        this.setState({
            dateOfExportation:date,
            totalValue
        });
    };
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]:value
        })
    };
    handleClose=()=>{
        $('#ProformaInvoiceModal').modal('hide');
        this.setState({
            dateOfExportation:null,
            exportReference:'',
            countryOfExport:'',
            countryOfOrigin:'CANADA',
            fedexInternationAWB:'',
            box:['','','','','','','','','','','','','','',''],
            HtsDes:['','','','','','','','','','','','','','',''],
            totalBoxes:0,
            totalValue:0
        })
    };
    handleBoxOnChange=(e,index)=>{
        const box = this.state.box;
        const value = e.target.value;
        if(box[index]===undefined){
            box.splice(index, 0, value);
        }else{
            box[index]=value
        }
        this.setState({
            box
        });
    };
    handleHtsDesOnChange=(e,index)=>{
        const HtsDes = this.state.HtsDes;
        const value = e.target.value;
        if(HtsDes[index]===undefined){
            HtsDes.splice(index, 0, value);
        }else{
            HtsDes[index]=value
        }
        this.setState({
            HtsDes
        });
    };
    handleGenerateInvoicePDF= async ()=>{
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'pt',
            format: 'a4'
        });
        const date = moment(this.props.dateOfExportation).format('MM/DD/YYYY');


        if(this.props.parties.delivery.addressFullName===""||this.props.parties.delivery.addressLine3===""||this.props.parties.delivery.addressLine4===""||this.props.parties.delivery.postalCode===""||this.props.parties.delivery.country===""){
            let message = "";

            if(this.props.parties.delivery.addressFullName===""){
                message += "Full Name\n";
            }
            if(this.props.parties.delivery.addressLine3===""){
                message += "City\n";
            }
            if(this.props.parties.delivery.addressLine4===""){
                message += "Province\n";
            }
            if(this.props.parties.delivery.postalCode===""){
                message += "Postal Code\n";
            }
            if(this.props.parties.delivery.country===""){
                message += "Country\n";
            }


            alert(`please correct order in Brightpearl for the following ship to details : \n\n\n${message}` )
        }else{
            doc.setFontSize(25);
            //text(text, x, y, flags) → {jsPDF}  //595.28/2=297.64,  841.89/2 = 421
            doc.text('PROFORMA  INVOICE',165,40,null);
            doc.setFontSize(12);
            /********* LINE 1 START  ********  EACH LINE HEIGHT 20, FOR FONT 12 */
            doc.rect(10,      50, 288, 20,'S');
            doc.rect(297.64, 50, 288, 20,'S');
            doc.text(`DATE OF EXPORTATION    ${date}`,20,65,null);
            doc.text(`EXPORT REFERENCE    ${this.state.exportReference}`,310,65,null);
            /********* LINE 1 FINISH  *********/

            /********* LINE 2 START  *********/
            doc.rect(10,     70, 288, 265,'S');
            doc.rect(297.64, 70, 288, 130,'S');
            doc.text("SHIPPER/ EXPORTER",20,85,null);
            doc.text('CONSIGNEE (Complete name & Address)',310,85,null);

            doc.text("ASHLIN-BPG Marketing Inc",85,155,null);
            doc.text("2351 Royal Windsor Drive, #10, Mississauga, ON.",25,200,null);
            doc.text("L5J 4S7 Canada",115,245,null);
            doc.text("GST#: 818203465RT0001",20,300,null);

            doc.text(`${this.props.parties.delivery.addressFullName}`,320,105,null);
            doc.text(`${this.props.parties.delivery.companyName !== undefined ? this.props.parties.delivery.companyName : ""}`,320,123,null);
            doc.text(`${this.props.parties.delivery.addressLine1}`,320,141,null);
            doc.text(`${this.props.parties.delivery.addressLine2!==undefined?this.props.parties.delivery.addressLine2:""}`,320,159,null);
            doc.text(`${this.props.parties.delivery.addressLine3}  ${this.props.parties.delivery.addressLine4}  ${this.props.parties.delivery.postalCode}`,320,177,null);
            doc.text(`${this.props.parties.delivery.country} Phone# ${this.props.parties.delivery.telephone}`,320,195,null);
            /********* LINE 2 FINISH  *********/

            /********* LINE 3 START  *********/
            doc.text("TRADE REGISTER NO.:",20,325,null);
            doc.text('IMPORTER IF OTHER THAN CONSIGNEE',310,215,null);

            if(this.props.FEIN===undefined || this.props.FEIN===""){
                doc.rect(297.64, 70, 288, 285,'S');
                doc.text(`${this.props.AshlinInfo.Name}`,320,235,null);
                doc.text(`${ this.props.AshlinInfo.Address1}, ${ this.props.AshlinInfo.Address2}`,320,253,null);
                doc.text(`${this.props.AshlinInfo.City}, ${this.props.AshlinInfo.State} ${this.props.AshlinInfo.Zip}`,320,271,null);
                doc.text(`${this.props.AshlinInfo.Country}`,320,289,null);

                doc.text(`Phone#: ${this.props.AshlinInfo.Phone} `,320,307,null);
                doc.text(`Email: ${this.props.AshlinInfo.Email}`,320,325,null);
                doc.text(`GST #: ${this.props.AshlinInfo.GST}`,320,343,null);
            }else{
                doc.rect(297.64, 70, 288, 265,'S');
                doc.text(`${this.props.parties.billing.companyName}`,320,235,null);
                doc.text(`${this.props.parties.billing.addressLine1}`,320,253,null);
                doc.text(`${this.props.parties.billing.addressLine2}`,320,271,null);
                doc.text(`${this.props.parties.billing.addressLine3}  ${this.props.parties.billing.addressLine4}  ${this.props.parties.billing.postalCode}`,320,289,null);

                doc.text(`${this.props.parties.billing.country}`,320,307,null);
                doc.text(`${this.props.parties.billing.telephone}`,320,325,null);
            }


            /********* LINE 3 FINISH  *********/

            /********* LINE 4 START  *********/
            doc.rect(10,      335, 288, 20,'S');

            doc.rect(10,      355, 288, 20,'S');
            doc.rect(297.64, 355, 288, 20,'S');
            doc.rect(10,      375, 288, 20,'S');
            doc.rect(297.64, 375, 288, 20,'S');
            doc.text(`COUNTRY OF EXPORT:  ${this.state.countryOfExport}`,20,350,null);
            if(this.props.FEIN!==undefined &&  this.props.FEIN!==""){
                doc.rect(297.64, 335, 288, 20,'S');
                doc.text(`FEIN#:  ${this.props.FEIN}`,310,350,null);
            }
            doc.text(`COUNTRY OF ORIGIN OF GOODS:  ${this.state.countryOfOrigin}`,20,370,null);
            doc.text(`UPS#:  ${this.props.ChargeToAccount}`,310,370,null);
            doc.text(`Country of Ultimate Destination: US`,20,390,null);
            doc.text(`FEDEX INTERNATION AWB NO.:  ${this.state.fedexInternationAWB}`,310,390,null);
            /********* LINE 4 FINISH  *********/
            /********* LINE 5 START  *********/
            doc.rect(10,      395, 60, 30,'S');
            doc.text(`No. of`,25,410,null);
            doc.text(`Packages`,15,420,null);

            doc.rect(70,      395, 326, 30,'S');
            doc.text(`Complete Description of Goods`,150,415,null);

            doc.rect(396,      395, 40, 30,'S');
            doc.text(`QTY`,403,415,null);

            doc.rect(436,      395, 50, 30,'S');
            doc.text(`Unit`,451,410,null);
            doc.text(`Value`,446,420,null);

            doc.rect(486,      395, 100, 30,'S');
            doc.text(`Total`,521,410,null);
            doc.text(`Value`,521,420,null);

            let height = 425;
            let totalAmount = 0;
            this.props.orderRows.forEach((ol,index)=>{
                const qty = parseInt(ol.quantity.magnitude);
                //const lineTotal = parseFloat(ol.rowValue.rowNet.value).toFixed(2);
                const lineTotal = ol.rowValue.rowNet.value!=0 ? parseFloat(ol.rowValue.rowNet.value).toFixed(2) : 1;
                const unitPrice = (lineTotal / qty).toFixed(2);
                const boxNum = this.state.box[index] !== undefined? this.state.box[index]:"";
                doc.rect(10,      height, 60, 20,'S');
                doc.text(`${boxNum}`,20,(height+15),null);

                doc.rect(70,      height, 326, 20,'S');
                doc.setFontSize(7);
                doc.text(`${ol.HtsDescription}`,80,(height+13),null);
                doc.setFontSize(12);
                doc.rect(396,      height, 40, 20,'S');
                doc.text(`${qty}`,413,(height+15),null);

                doc.rect(436,      height, 50, 20,'S');
                doc.text(`$${unitPrice}`,440,(height+15),null);

                doc.rect(486,      height, 100, 20,'S');
                doc.text(`$${lineTotal}`,506,(height+15),null);
                height += 20;
                //totalAmount += parseFloat(ol.rowValue.rowNet.value);
                totalAmount += ol.rowValue.rowNet.value!=0 ?parseFloat(ol.rowValue.rowNet.value): 1;
            });

            //const currencyRate = await axios.get('https://free.currencyconverterapi.com/api/v6/convert?q=CAD_USD&compact=ultra');
            //const section901 = (totalAmount*currencyRate.data.CAD_USD < 800)?"SECTION 901 less than $800's USD":"";
            const section901 = (totalAmount*0.75 < 800)?"SECTION 901 less than $800's USD":"";
            //console.log(section901);
            let unique = [...new Set(this.state.box)].length-1 === 0?"":[...new Set(this.state.box)].length-1 ;
            doc.rect(10,      height, 60, 30,'S');
            doc.text(`Total No.`,15,(height+15),null);
            doc.text(`of Pkgs`,20,(height+25),null);

            doc.rect(10,      (height+30), 60, 20,'S');
            doc.text(`${unique}`,35,(height+45),null);

            doc.rect(70,      height, 366, 50,'S');
            doc.text(`INVOICE / VALUE DECLARED FOR CUSTOMS PURPOSE ONLY`,75,(height+20),null);
            doc.text(`${section901}`,160,(height+40),null);

            doc.rect(436,      height, 50, 30,'S');
            doc.text(`Currency`,437,(height+20),null);
            doc.rect(436,      (height+30), 50, 20,'S');
            doc.text(`CDN$'s`,440,(height+45),null);

            doc.rect(486,      height, 100, 50,'S');
            doc.text(`$${totalAmount.toFixed(2)}`,506,(height+40),null);
            /********* LINE 5 FINISH  *********/
            /********* LINE 6 START  *********/
            doc.rect(10,      (height+50), 576, 80,'S');
            doc.text('I DECLARE ALL THE INFORMATION CONTAINED IN THIS INVOICE TO BE TRUE AND CORRECT.',25,(height+75),null);

            doc.text('I DECLARE ALL THE INFORMATION CONTAINED IN THIS INVOICE TO BE TRUE AND CORRECT.',25,(height+75),null);

            doc.line(25, (height+105), 305, (height+105));
            doc.text(`${this.props.Signature.Name} (${this.props.Signature.Title}) `,25,(height+100),null);
            const imageType = this.props.Signature.SignatureBase64.split(';')[0].split('/')[1];
            doc.addImage(this.props.Signature.SignatureBase64, imageType, 215, (height+75), 100, 50);
            doc.text('SIGNATURE OF SHIPPER (Name, Title & Signature)',25,(height+120),null);
            doc.text(`${date}`,480,(height+120),null);
            /********* LINE 6 FINISH  *********/
            //doc.output('dataurlnewwindow');
            window.open(doc.output('bloburl'), '_blank');
            //console.log(doc.output('datauristring'));

            // const blob = doc.output('blob');
            // const file = new File([blob], "PROFORMA  INVOICE");
            this.props.orderAttachFile(doc.output('datauristring'));
        }
    };
    render(){

        return(
            <div className="modal fade" id="ProformaInvoiceModal" tabIndex="-1" role="dialog"
                 aria-labelledby="ProformaInvoiceModal" aria-hidden="true" style={{backgroundColor:'floralwhite'}}>
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content" style={{backgroundColor:'floralwhite'}}>
                        <div className="modal-header">
                            <h3 className="modal-title text-center" id="ProformaInvoiceModalTitle" style={{fontWeight:'bold'}}> PROFORMA  INVOICE</h3>
                            <button type="button" className="close"
                                    onClick={this.handleClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <table className={'table table-bordered ProformaInvoice'}>
                                <thead>
                                    <tr>
                                        <th scope="col-6">
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    DATE OF EXPORTATION
                                                </div>
                                                <div className={'col'}>
                                                    <DatePicker
                                                        selected={this.state.dateOfExportation}
                                                        onChange={this.handleChangeExportationDate}
                                                    />
                                                </div>
                                            </div>
                                        </th>
                                        <th scope="col-6">
                                            <div className={'row'}>
                                                <div className={'col'}>
                                                    EXPORT REFERENCE
                                                </div>
                                                <div className={'col'}>
                                                   <input type={'text'} onChange={this.handleOnchange} name={'exportReference'} value={this.state.exportReference}/>
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">SHIPPER/ EXPORTER</th>
                                        <th scope="row">CONSIGNEE (Complete name & Address)</th>
                                    </tr>
                                    <tr>
                                        <th rowSpan="2" style={{textAlign:'center'}}>
                                            <br/>
                                            <br/>
                                            <br/><br/>
                                           ASHLIN-BPG Marketing Inc
                                            <br/>
                                            <br/>
                                            2351 Rpyal Windsor Drive, #10, Mississauga, ON.
                                            <br/>
                                            <br/>
                                            L5J 4S7 Canada
                                            <br/>
                                            <br/><br/>
                                            <br/><br/>
                                            <br/><br/>
                                            <br/>
                                            <p style={{textAlign:'left',fontWeight:'normal'}}>TRADE REGISTER NO.:</p>
                                        </th>
                                        <td style={{textAlign:'center',fontWeight:'bold'}}>
                                            <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.delivery.addressFullName!==undefined)?this.props.parties.delivery.addressFullName:""}</p>
                                            <p style={{padding:'0'}}>{(this.props.parties!==undefined&&this.props.parties.delivery.companyName !==undefined)?this.props.parties.delivery.companyName:"" }</p>
                                            <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.delivery.addressLine1 !==undefined)?this.props.parties.delivery.addressLine1:"" }</p>
                                            <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.delivery.addressLine2 !==undefined)?this.props.parties.delivery.addressLine2:"" }</p>
                                            <p style={{padding:'0'}}>
                                                {(this.props.parties!==undefined&&this.props.parties.delivery.addressLine3 !==undefined)?this.props.parties.delivery.addressLine3:"" }&nbsp;
                                                {(this.props.parties!==undefined&&this.props.parties.delivery.addressLine4 !==undefined)?this.props.parties.delivery.addressLine4:"" }&nbsp;
                                                {(this.props.parties!==undefined&&this.props.parties.delivery.postalCode !==undefined)?this.props.parties.delivery.postalCode:"" }
                                            </p>
                                            <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.delivery.country !==undefined)?this.props.parties.delivery.country:"" }</p>
                                            <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.delivery.telephone !==undefined)?this.props.parties.delivery.telephone:"" }</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        {
                                            (this.props.FEIN===undefined || this.props.FEIN==="")&&
                                                <td style={{textAlign:'center'}}>
                                                    <p style={{fontWeight:'bold',textAlign:'left'}}>IMPORTER IF OTHER THAN CONSIGNEE</p>
                                                    <p style={{padding:'0',margin:'0'}}>{this.props.AshlinInfo!==undefined? this.props.AshlinInfo.Name:""}</p>
                                                    <p style={{padding:'0',margin:'0'}}>{this.props.AshlinInfo!==undefined? this.props.AshlinInfo.Address1:""},&nbsp;{this.props.AshlinInfo!==undefined? this.props.AshlinInfo.Address2:""}</p>
                                                    <p style={{padding:'0',margin:'0'}}>
                                                        {this.props.AshlinInfo!==undefined? this.props.AshlinInfo.City:""}&nbsp;,
                                                        {this.props.AshlinInfo!==undefined? this.props.AshlinInfo.State:""}&nbsp;
                                                        {this.props.AshlinInfo!==undefined? this.props.AshlinInfo.Zip:""}&nbsp;
                                                    </p>
                                                    <p style={{padding:'0',margin:'0'}}>{this.props.AshlinInfo!==undefined? this.props.AshlinInfo.Country:""}</p>
                                                    <p style={{padding:'0',margin:'0'}}>Phone#: {this.props.AshlinInfo!==undefined? this.props.AshlinInfo.Phone:""},&nbsp;
                                                        Email: {this.props.AshlinInfo!==undefined? this.props.AshlinInfo.Email:""}
                                                    </p>
                                                    <p style={{padding:'0',margin:'0'}}>GST #: {this.props.AshlinInfo!==undefined? this.props.AshlinInfo.GST:""}</p>
                                                </td>
                                        }
                                        {

                                            (this.props.FEIN!==undefined && this.props.FEIN!=="")&&
                                                <td style={{textAlign:'center'}}>
                                                    <p style={{fontWeight:'bold',textAlign:'left'}}>IMPORTER IF OTHER THAN CONSIGNEE</p>
                                                    <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.billing.companyName !==undefined)?this.props.parties.billing.companyName:"" }</p>
                                                    <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.billing.addressLine1 !==undefined)?this.props.parties.billing.addressLine1:"" }</p>
                                                    <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.billing.addressLine2 !==undefined)?this.props.parties.billing.addressLine2:"" }</p>
                                                    <p style={{padding:'0',margin:'0'}}>
                                                        {(this.props.parties!==undefined&&this.props.parties.billing.addressLine3 !==undefined)?this.props.parties.billing.addressLine3:"" }&nbsp;
                                                        {(this.props.parties!==undefined&&this.props.parties.billing.addressLine4 !==undefined)?this.props.parties.billing.addressLine4:"" }&nbsp;
                                                        {(this.props.parties!==undefined&&this.props.parties.billing.postalCode !==undefined)?this.props.parties.billing.postalCode:"" }
                                                    </p>
                                                    <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.billing.country !==undefined)?this.props.parties.billing.country:"" }</p>
                                                    <p style={{padding:'0',margin:'0'}}>{(this.props.parties!==undefined&&this.props.parties.billing.telephone !==undefined)?this.props.parties.billing.telephone:"" }</p>
                                                </td>
                                        }
                                    </tr>
                                    <tr>
                                        <td>
                                            COUNTRY OF EXPORT  <input type={'text'} onChange={this.handleOnchange} name={'countryOfExport'} value={this.state.countryOfExport}/>
                                        </td>
                                        <td>
                                            FEIN# <input type={'text'} readOnly value={this.props.FEIN}/>&nbsp;
                                            GST# <input type={'text'} readOnly value={'818203465RT0001'}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            COUNTRY OF ORIGIN OF GOODS  <input type={'text'} onChange={this.handleOnchange} name={'countryOfOrigin'} value={this.state.countryOfOrigin}/>
                                        </td>
                                        <td>
                                            3rd Party Shipping Account # <input type={'text'} readOnly
                                                value={this.props.ChargeToAccount!==""?`${this.props.CarrierCode}-#${this.props.ChargeToAccount}`:""}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            Country of Ultimate Destination &nbsp;&nbsp; <input type={'text'} readOnly  value={"US"}/>
                                        </td>
                                        <td>
                                            FEDEX INTERNATION AWB # <input type={'text'} name={'fedexInternationAWB'} onChange={this.handleOnchange}  value={this.state.fedexInternationAWB}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <table className={'table table-bordered ProformaInvoice'}>
                                <thead>
                                    <tr>
                                        <th style={{textAlign:'center'}}>No. of Packages</th>
                                        <th style={{textAlign:'center'}}>Complete Description of Goods</th>
                                        <th style={{textAlign:'center'}}>QTY</th>
                                        <th style={{textAlign:'center'}}>Unit Value</th>
                                        <th style={{textAlign:'center'}}>Total Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.orderRows !== undefined&&this.props.orderRows.map((ol,index)=>{
                                        const qty = parseInt(ol.quantity.magnitude);
                                        const lineTotal = parseFloat(ol.rowValue.rowNet.value).toFixed(2);
                                        const unitPrice = (lineTotal / qty).toFixed(2);
                                        return <tr key={index}>
                                            <td style={{textAlign:'center'}}><input type={'text'} style={{textAlign:'center'}} value={this.state.box[index]} name={index}  onChange={(e)=>this.handleBoxOnChange(e,index)}/></td>
                                            {/*<td><input type={'text'} name={'htsDec'} value={this.state.HtsDes[index]} onChange={(e)=>this.handleHtsDesOnChange(e,index)} />&nbsp;-&nbsp;HTS code {ol.HtsUs}</td>*/}
                                            <td style={{textAlign:'center'}}> {ol.HtsDescription}</td>
                                            <td style={{textAlign:'center'}}>{qty}</td>
                                            <td style={{textAlign:'right'}}>${unitPrice}</td>
                                            <td style={{textAlign:'right'}}>${lineTotal}</td>
                                        </tr>
                                    })}
                                    <tr>
                                        <td style={{textAlign:'center'}}>Total No of Pkgs. <input style={{textAlign:'center'}} type={'text'}  readOnly value={[...new Set(this.state.box)].length-1}/></td>
                                        <td colSpan={'2'} style={{textAlign:'center',fontWeight:'bold'}}>VALUE DECLARED FOR CUSTOMS PURPOSE ONLY</td>
                                        <td style={{textAlign:'center'}}>Currency CDN$'s</td>
                                        <td style={{textAlign:'right'}}>Total Value &nbsp;&nbsp;&nbsp;&nbsp;${this.state.totalValue}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={'5'}>
                                            I DECLARE ALL THE INFORMATION CONTAINED IN THIS INVOICE TO BE TRUE AND CORRECT.
                                            <br/>
                                            <br/>
                                            <div className={'row justify-content-between'}>
                                                <div className={'col-6'}>
                                                    <p>{this.props.Signature!==undefined?`${this.props.Signature.Name}  `:""}{this.props.Signature!==undefined?`(${this.props.Signature.Title}) `:""}
                                                        {this.props.Signature!==undefined &&
                                                        <img src={this.props.Signature.SignatureBase64} alt="signature" width={'100px'} height={'40px'}/>
                                                        }
                                                    </p>
                                                    <span style={{borderTop:'solid 1px black'}}>SIGNATURE OF SHIPPER (Name, Title & Signature)</span>
                                                </div>
                                                <div className={'col-3 '}>
                                                    <DatePicker
                                                        selected={this.state.dateOfExportation}
                                                        onChange={this.handleChangeExportationDate}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer row" style={{width:'90%',margin:"0 auto"}}>
                            <button type={'button'} className={'btn btn-success'} onClick={this.handleGenerateInvoicePDF}>GENERATE INVOICE PDF</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default AshlinInvoice;