import CryptoJS from 'crypto-js';
import  axios from 'axios';
import moment from 'moment';
import { proxyurl } from './requestHelper';

const headerSetting = {
    headers:{
        //'Content-Type': 'x-www-form-urlencoded',
        //'User-Agent': 'Ashlinapp/1.0 (Language=Javascript)',
        'Content-Type': 'text/xml',
    }
};

export const credentials = (countryCode)=>{
    switch (countryCode) {
        case 'US':
            return {
                merchantToken: 'A39FLDSMAE9BX5',  //AWSAccessKeyId
                developerId : '890553858175',
                accessKeyId: 'AKIAIQGHWGGHEA3X626Q',  //AWSAccessKeyId
                clientSecret : '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'  //MWSAuthToken , secretKey
            };
        case 'CA':
            return {
                merchantToken: 'A1HR7VS5TXAEOM',  //AWSAccessKeyId
                developerId : '183723537466',
                accessKeyId : 'AKIAISYNJ7U2Z6TGYL2Q',  //AWSAccessKeyId
                clientSecret : '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'  //MWSAuthToken , secretKey
            }
    }

};

const endpoints = (countryCode)=>{
    switch (countryCode) {
        case 'US':
            return {
                uri: proxyurl+'https://mws.amazonservices.com',
                marketplaceId : 'ATVPDKIKX0DER',
                domain:'mws.amazonservices.com',
            };
        case 'AU':
            return {
                uri: proxyurl+'https://mws.amazonservices.com',
                marketplaceId : 'A39IBJ37TRP1C6',
            };
        default:
            return {
                uri: proxyurl+'https://mws.amazonservices.ca',
                domain:'mws.amazonservices.ca',
                marketplaceId : 'A2EUQ1WTGCTBG2',
            }
    }
};

const generateTimeStamp = ()=>{
    const now = new Date();
    return  now.toISOString().substring(0, 19) + 'Z';
};

const signSignature = (domain,service,query, secret)=>{
    //console.log(query);
    const message = `POST\n`+
                    `${domain}\n`+
                    `${service}\n`+ query;
    //console.log(message);

    const hash = CryptoJS.HmacSHA256(message, secret);
    //console.log('hash',hash.toString());
    const hashInBase64 = CryptoJS.enc.Base64.stringify(hash);
    //console.log('base64',hashInBase64);
    return  encodeURIComponent(hashInBase64);
};



export const amazonSearchProductBySku = async (credentials,sku,countryCode)=>{
    const endpoint = endpoints(countryCode);
    let query =  `AWSAccessKeyId=${encodeURIComponent(credentials.accessKeyId)}`;
        query += `&Action=${encodeURIComponent('GetMyPriceForSKU')}`;
        query += `&MarketplaceId=${encodeURIComponent(endpoint.marketplaceId)}`;
        query += `&SellerId=${encodeURIComponent(credentials.merchantToken)}`;
        query += `&SellerSKUList.SellerSKU.1=${encodeURIComponent(sku)}`;
        query += `&SignatureMethod=${encodeURIComponent('HmacSHA256')}`;
        query += `&SignatureVersion=${encodeURIComponent('2')}`;
        //query += `&Timestamp=2019-07-22T20%3A27%3A51Z`;
        query += `&Timestamp=${encodeURIComponent(generateTimeStamp())}`;
        query += `&Version=${encodeURIComponent('2011-10-01')}`;

        const signature = signSignature(endpoint.domain, '/Products/2011-10-01' , query, credentials.clientSecret);

        query += `&Signature=${signature}`;
    const url = `${endpoint.uri}/Products/2011-10-01?${query}`;
    try{
        const res = (await axios.post(url,{}, headerSetting)).data;
        const resDOM =  new DOMParser().parseFromString(res, 'application/xml');

        const error = resDOM.getElementsByTagName('Error');
        if(error.length > 0){
            alert(error[0].textContent);
            return undefined;
        }else{
            const listPriceDOM = resDOM.getElementsByTagName('ListingPrice');

            const listPrice = listPriceDOM[0].children[1].textContent;
            const currency = listPriceDOM[0].children[0].textContent;

            const regularPriceDOM = resDOM.getElementsByTagName('RegularPrice');
            const regularPrice = regularPriceDOM[0].children[1].textContent;
            return {
                sku,
                listPrice,
                regularPrice,
                discount: parseFloat(listPrice) < parseFloat(regularPrice),
                currency
            }
        }
    }catch (e) {
        return undefined
    }
};

export const amazonFeedPrices = async (credentials, priceList,countryCode)=>{
    const endpoint = endpoints(countryCode);
    let data = `<?xml version="1.0" encoding="utf-8" ?>
                    <AmazonEnvelope
                        xmlns:xsi="https://www.w3.org/2001/XMLSchema-instance" xsi:noNamespaceSchemaLocation="amzn-envelope.xsd">
                        <Header>
                            <DocumentVersion>1.01</DocumentVersion>
                            <MerchantIdentifier>${credentials.merchantToken}</MerchantIdentifier>
                        </Header>
                        <MessageType>Price</MessageType>`;
           priceList.forEach((p,index)=>{
               let id = index + 1;
               if(p.applyDiscount){
                    data +=`<Message>
                                <MessageID>${id}</MessageID>
                                <Price>
                                    <SKU>${p.sku}</SKU>
                                    <StandardPrice currency="${p.currency}">${p.regularPrice}</StandardPrice>
                                    <Sale>
                                        <StartDate>${moment(p.discountStartDate).format( 'YYYY-MM-DDTHH:mm:ssZ')}</StartDate>
                                        <EndDate>${moment(p.discountEndDate).format( 'YYYY-MM-DDTHH:mm:ssZ')}</EndDate>
                                        <SalePrice currency="${p.currency}">${p.listPrice}</SalePrice>
                                    </Sale>
                                </Price>
                            </Message>`
               }else{
                    data += `<Message>
                                <MessageID>${id}</MessageID>
                                <Price>
                                    <SKU>${p.sku}</SKU>
                                    <StandardPrice currency="${p.currency}">${p.listPrice}</StandardPrice>
                                </Price>
                            </Message>`
               }
           });
           data += `</AmazonEnvelope>`

    //console.log(data);
    const md5 = CryptoJS.MD5(data);
    //console.log('md5',md5);
    const md5Base64 = CryptoJS.enc.Base64.stringify(md5);
    //console.log('md5Base64',md5Base64);
    let query =  `AWSAccessKeyId=${encodeURIComponent(credentials.accessKeyId)}`;
        query += `&Action=${encodeURIComponent('SubmitFeed')}`;
        query += `&ContentMD5Value=${encodeURIComponent(md5Base64)}`;
        query += `&FeedType=${encodeURIComponent('_POST_PRODUCT_PRICING_DATA_')}`;
        query += `&MarketplaceIdList.Id.1=${encodeURIComponent(endpoint.marketplaceId)}`;
        query += `&Merchant=${encodeURIComponent(credentials.merchantToken)}`;
        query += `&PurgeAndReplace=false`;
        query += `&SignatureMethod=${encodeURIComponent('HmacSHA256')}`;
        query += `&SignatureVersion=${encodeURIComponent('2')}`;
        query += `&Timestamp=${encodeURIComponent(generateTimeStamp())}`;
        //query += `&Timestamp=2019-07-25T13%3A15%3A56Z`;
        query += `&Version=${encodeURIComponent('2009-01-01')}`;

        const signature = signSignature(endpoint.domain, '/' , query, credentials.clientSecret);

        query += `&Signature=${signature}`;
    const url = `${endpoint.uri}/?${query}`;
    //console.log(url)
    //console.log(query)
    try{
        const res = (await axios.post(url,data, headerSetting)).data;
        //console.log(res)
        const resDOM =  new DOMParser().parseFromString(res, 'application/xml');

        const error = resDOM.getElementsByTagName('Error');
        if(error.length > 0){
            alert(error[0].textContent);
            return undefined;
        }else{
            return true;
        }
    }catch (e) {
        return undefined
    }
};

