import React from 'react';
const brightpearl_contact_list_item = ({item,selectContact})=>{
        return(
            <li className= 'list-group-item'>
                <div className={'row'}>
                    <div className={'col-2'}>
                        <input type="radio" name={'selectedBPCustomer'} style={{marginTop:'10px',width:'30px',height:'30px'}}
                               onChange={(e)=>selectContact(e,item)} />
                    </div>
                    <div className={'col-10'}>
                        <span style={{fontWeight:'bold'}}> {item[4]} </span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style={{fontWeight:'bold'}}> {item[5]} </span><br/>
                        <span style={{fontWeight:'bold'}}>Email: </span>{item[1]}
                    </div>
                </div>
            </li>
        )

};
export default brightpearl_contact_list_item;