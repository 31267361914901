import React, { Component } from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import ReactTable from "react-table";
import "react-table/react-table.css";
import {getAsiProduct, getAuth, getCredentials, getAsiProductTypes, getAsiCategories} from '../../Utils/asi';
import moment from 'moment';
import SelectInput from '../bootstrapComponent/selectInput';
import {
    getChannelList,
    getListedSkusWithBrightId,
    legacySkusURL,
    updateSkuChannelListing,getListedSkus,deleteSkuChannelListing
} from "../../Utils/ashlinDesign";
import $ from "jquery";
import BulkModifyProductDetailModal from '../Modal/BulkModifyProductDetailModal';
import BestBuyDiscountModal from '../Modal/BestBuyDiscountModal';
import BigCommerceModifyProductDetailModal from '../Modal/BigCommerceModifyProductDetailModal';
import {WalmartGetAnItem, WalmartFeedItems, walmartRetireProduct} from '../../Utils/walmartMarketplace';
import NewEggModifyProductModal from '../Modal/NewEggModifyProductModal';
import{updateItemFieldFromNewEgg, getItemPriceFromNewEgg, getItemInventoryFromNewEgg} from '../../Utils/newEggMarketplace';
import axios from "axios/index";
import BrightPearlProductDetailModal from '../Modal/BrightPearlProductDetailModal';
import AsiModifyProductModal from '../Modal/AsiModifyProductModal';
import { getBestBuyOffer, bestBuy_offer_discount,updateOffers  }  from "../../Utils/bestbuyMarketplace";
import { getStoreInfo,findProductById,getVariant,calculateBulKPrice,getVariantOptionValue,addVariantImage,updateVariantOption,
    filterBulkPriceRule,productDescription,updateProduct,getBulkPricingRules,updateVariantOptionValue,updateProductImage,
    deleteBulkPricingRules,getVariantOptions,deleteVariant,deleteVariantOptionValue,getImages,deleteProduct  } from "../../Utils/bigCommerce";
import {
    getProductByID,
    getProductPriceListByProductID,
    getProductInventory,updateProductInventory,
    upDatePriceList,getBPheader
} from '../../Utils/brightpearl';
class BulkModifyProduct extends Component{
    constructor(props){
        super(props);
        this.state = {
            channelList:[],
            selectChannel:"",
            skuList:[],
            isUpLoading:false,
            isDownLoading:false,
            selectedSku:{},
            selectedSkuDetail:null,
            modifiedList:[],
            SkuSwatch:{
                swatchColor:undefined,
                isChangeSwatch:false,
                currentSwatchValue:null,
                isChangeImage:false,
                imageFile:undefined,
                currentSelectId:''
            },
            selectedPriceListID:"",
            isEditingStock:false,
            editWarehouseId:'',
            updatedInventory:{}
        };
    }
    componentDidMount(){
        this.loadData();
    }
    loadData = async()=>{
        const channelList = await getChannelList();
        this.setState({
            channelList :channelList.data
        })
    };
    handleOnchange=(e)=>{
        const selectChannel = e.target.value;
        if(selectChannel !== 'optionDefaultHeader'){
            //get Skus listed on the selected Channel
            this.setState({
                isDownLoading:true
            },async ()=>{
                const listedSkusRes = await getListedSkus(selectChannel);
                // updateItemFieldFromNewEgg("5704-07-01","SellingPrice", 99)

                let listedSkus = listedSkusRes.data;
                if(selectChannel === "ASI"){
                    // listedSkus = [...new Set(listedSkus.map(sku => sku.MerchanSku))];
                    // listedSkus = listedSkus.map(sku=>{return {"Sku":sku}});
                    // console.log(listedSkus);
                    this.props.history.push('/asiUpdate');
                }
                Promise.all([listedSkusRes]).then(()=>{
                    this.setState({
                        selectChannel,
                        skuList:listedSkus,
                        isDownLoading:false
                    });
                })
            });
        }
    };
    handleDetail=async()=>{
        if(this.state.selectChannel === "WalmartCa"){
            const walmartRes =await WalmartGetAnItem(this.state.selectedSku.Sku);
            const ashlinRes = await axios.get(legacySkusURL+`/detail/${this.state.selectedSku.Sku}`);
            //console.log(walmartRes);
            const selectedSkuDetail = ashlinRes.data[0];
            selectedSkuDetail.Price = parseFloat(walmartRes['ns2:ItemResponses']['ns2:ItemResponse']['ns2:price']['ns2:amount']['_text']);
            selectedSkuDetail.ProductName = walmartRes['ns2:ItemResponses']['ns2:ItemResponse']['ns2:productName']['_text'].replace('Â','');
            this.setState({
                selectedSkuDetail:selectedSkuDetail
            },()=>{
                $('#BulkModifyProductDetailModal').modal('show');
            });
        }else if(this.state.selectChannel === "BestBuy"){

            const bestBuyOffer = await getBestBuyOffer (this.state.selectedSku.Sku);
            this.setState({
                selectedSkuDetail:bestBuyOffer
            },()=>{
                // console.log(bestBuyOffer)
                $('#BestBuyDiscountModal').modal('show');
            });

        }else if(this.state.selectChannel === "BigCommerce Ashlin" || this.state.selectChannel === "BigCommerce Poppybuzz"){
            const  SkuSwatch = this.state.SkuSwatch;
            SkuSwatch.isChangeSwatch=false;
            SkuSwatch.currentSwatchValue=null;
            SkuSwatch.isChangeImage=false;
            SkuSwatch.imageFile=undefined;
            this.setState({
                isDownLoading:true,
                SkuSwatch
            },async()=>{
                const Ids = this.state.selectedSku.MerchanSku.split('+');
                const productId = Ids[0];
                const variantId = Ids[1];
                const storeInfo =await getStoreInfo(this.state.selectChannel);
                const bigCommerceDesignRes = await findProductById(storeInfo,productId);
                const bigCommerceDesign = bigCommerceDesignRes.data.data;
                //console.log(bigCommerceDesign);
                bigCommerceDesign.price = (bigCommerceDesign.price / 0.85).toFixed(2);
                const ashlinSkuRes = await axios.get(legacySkusURL+`/detail/${this.state.selectedSku.Sku}`);
                const priceCa = (parseFloat(ashlinSkuRes.data[0].Price)*1.05).toFixed(2);

                if(this.state.selectChannel === "BigCommerce Poppybuzz"){
                    const imageRes  = await getImages(storeInfo,productId);
                    const bigCommerceProductImages = imageRes.data.data;
                    this.setState({
                        isDownLoading:false,
                        selectedSkuDetail:{bigCommerceDesign,bigCommerceProductImages,ashlinSku:ashlinSkuRes.data[0],priceCa}
                    },()=>{
                        //console.log(this.state);
                        $('#BigCommerceModifyProductDetailModal').modal('show');
                    });
                }else {
                    const bigCommerceSkuRes = await getVariant(storeInfo,productId,variantId);
                    const bigCommerceSku = bigCommerceSkuRes.data.data;
                    this.setState({
                        isDownLoading:false,
                        selectedSkuDetail:{bigCommerceDesign,bigCommerceSku,ashlinSku:ashlinSkuRes.data[0],priceCa}
                    },()=>{
                        $('#BigCommerceModifyProductDetailModal').modal('show');
                    });
                }
            })
        }else if(this.state.selectChannel === "BrightPearl"){
            this.setState({
                selectedPriceListID:"",
                isEditingStock:false,
                editWarehouseId:'',
                selectedSkuDetail:null,
            },async()=>{
                const productID = this.state.selectedSku.MerchanSku;
                const brightProduct =await getProductByID(productID);
                const headers = await getBPheader();
                brightProduct.priceLists = await getProductPriceListByProductID(productID,headers);
                brightProduct.priceLists.splice(3,1);
                brightProduct.inventory = await getProductInventory(productID);
                this.setState({
                    selectedSkuDetail:brightProduct
                },()=>{
                    $('#brightPearlProductDetailModal').modal('show');
                });
            });

        }
        else if(this.state.selectChannel === "ASI"){
            this.setState({
                selectedPriceListID:"",
                isEditingStock:false,
                editWarehouseId:'',
                selectedSkuDetail:null,
            },async()=>{
                //console.log(this.state);
                const credentials = await getCredentials();
                const token = await getAuth(credentials);
                const XID = this.state.selectedSku.Sku;
                const asiProduct =await getAsiProduct (XID,token);
                asiProduct.AsiCategories = await getAsiCategories();
                asiProduct.AsiProductTypes = await getAsiProductTypes();
                this.setState({
                    selectedSkuDetail:asiProduct
                },()=>{
                    $('#asiModifyProductModal').modal('show');
                });
            });

        } else if (this.state.selectChannel === "NewEgg"){
            this.setState({
                selectedPriceListID:"",
                isEditingStock:false,
                editWarehouseId:'',
                selectedSkuDetail:null,
            }, async() => {
                const currentItemInventory = await getItemInventoryFromNewEgg(this.state.selectedSku.Sku)
                const currentItemPrice = await getItemPriceFromNewEgg(this.state.selectedSku.Sku)
                this.setState({
                    selectedSkuDetail:{currentItemInventory, currentItemPrice}
                },()=>{
                    // console.log(this.state.selectedSkuDetail)
                    $('#NewEggModifyProductModal').modal('show');
                });
            })

        }

    };

    handleRestSkuDetail =()=>{
        this.setState({
            selectedSkuDetail:null
        })
    }

    handleModalOnchange=(e,field)=>{
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const selectedSkuDetail = this.state.selectedSkuDetail;
        if(field==="bigCommerceDesign"){
            selectedSkuDetail[name] = value;
        }else{
            selectedSkuDetail[name] = value;
        }
        this.setState({
            selectedSkuDetail
        });
    };
    handleAddToModifiedList=(e)=>{
        e.preventDefault();
        const modifiedList = this.state.modifiedList;
        modifiedList.push(this.state.selectedSkuDetail);
        this.setState({
            modifiedList
        },()=>console.log(this.state.modifiedList));

    };
    handleRemoveFromModifiedList=(e)=>{
        e.preventDefault();
        const remove = this.state.selectedSkuDetail;
        //console.log(remove);
        let modifiedList = this.state.modifiedList;
        if(this.state.selectChannel === "BestBuy"){
            modifiedList = modifiedList.filter(i=>{
                return i.shop_sku !== remove.shop_sku
            });
        }else {
            modifiedList = modifiedList.filter(i=>{
                return i.Sku !== remove.Sku
            });
        }

        this.setState({
            modifiedList
        })
    };
    handleButtonClick=(e,item)=>{
        e.preventDefault();
        this.setState({
            selectedSkuDetail:item
        },()=>{
            if(this.state.selectChannel === "BestBuy") {
                $('#BestBuyDiscountModal').modal('show');
            }
            else{
                $('#BulkModifyProductDetailModal').modal('show');
            }
        });
    };
    handleUpdate=async(e)=>{
        e.preventDefault();
        let label;
        if(this.state.selectChannel === "BestBuy"){
            label = this.state.modifiedList.map(m=>m.shop_sku).join(" \n");
            let confirmBox = window.confirm(`Do you confirm to update selected products to BESTBUY:\n${label}?`);
            if (confirmBox) {
                const bestBuyOffers = this.state.modifiedList.map(o=>bestBuy_offer_discount(o));
                const bestBuyRes = await updateOffers(bestBuyOffers);
                //console.log(bestBuyRes);
                alert("BestBuy Market Update Finish, Import ID: " + bestBuyRes.data.import_id);

            }
        }else {
            label = this.state.modifiedList.map(m=>m.Sku).join(" \n");
            let confirmBox = window.confirm(`Do you confirm to update selected products to WALMARTCA:\n${label}?`);
            if (confirmBox) {
                const feedResponse =await WalmartFeedItems(this.state.modifiedList,'modify');
                //console.log(feedResponse);
                alert("WalmartCA Market Place Update Finish, Feed Status ID: " + feedResponse.data.feedId);
            }
        }

    };
    handleChangeStartDate=(date)=>{
        const selectedSkuDetail = this.state.selectedSkuDetail;
        selectedSkuDetail.discountStartDate = date;
        this.setState({
            selectedSkuDetail
        });
    };
    handleChangeEndDate=(date)=>{
        const selectedSkuDetail = this.state.selectedSkuDetail;
        selectedSkuDetail.discountEndDate = date;
        this.setState({
            selectedSkuDetail
        });
    };
    handleCalculateBulkPriceRule =async (e,product,pricingTier,newPrice)=>{
        e.preventDefault();
        //console.log(newPrice);
        const bulkRuleRes = await calculateBulKPrice(newPrice,pricingTier);
        //console.log(bulkRuleRes);
        const bulkPrice = bulkRuleRes.Bulk;
        const bulkPriceRule = bulkPrice.map((p,i)=>{
            if(parseFloat(p.amount) > (newPrice * 0.85)){
                p.amount = (newPrice * 0.85).toFixed(2);
            }
            if(i>0 && parseFloat(bulkPrice[i].amount)>=parseFloat(bulkPrice[i-1].amount)){
                bulkPrice[i].amount = (parseFloat(bulkPrice[i-1].amount) - 0.01).toFixed(2);
            }
            return p;
        });
        const finalBulkPriceRule=filterBulkPriceRule(bulkPriceRule,parseInt(product.MinOrder));
        const finalImprintedPriceRule =filterBulkPriceRule(bulkPriceRule,parseInt(product.ImprintedMinOrder));
        const newDescription = productDescription(product,finalImprintedPriceRule );
        const selectedSkuDetail = this.state.selectedSkuDetail;
        selectedSkuDetail.bigCommerceDesign.description = newDescription;
        selectedSkuDetail.bigCommerceDesign.bulk_pricing_rules = finalBulkPriceRule;
        selectedSkuDetail.bigCommerceDesign.price = (newPrice*0.85).toFixed(2);
        selectedSkuDetail.bigCommerceDesign.calculated_price = (newPrice*0.85).toFixed(2);
        this.setState({
            selectedSkuDetail
        });
    };
    handleUpdateBigCommerceProduct = async(e)=>{
        e.preventDefault();
        let confirmBox = window.confirm(`Do you confirm to update ${this.state.selectedSku.Sku}?`);
        if (confirmBox) {
            const storeInfo = await getStoreInfo(this.state.selectChannel);
            const bulkPriceRuleRes = await getBulkPricingRules(storeInfo, this.state.selectedSkuDetail.bigCommerceDesign.id);

            const deleteOldBulkPriceRuleRes = await bulkPriceRuleRes.data.data.map(async r => {
                return await deleteBulkPricingRules(storeInfo, this.state.selectedSkuDetail.bigCommerceDesign.id, r.id);
            });
            Promise.all(deleteOldBulkPriceRuleRes).then(async () => {
                const updateProductRes = await updateProduct(storeInfo, this.state.selectedSkuDetail.bigCommerceDesign.id, this.state.selectedSkuDetail.bigCommerceDesign);
                alert('Update Complete');
                $('#BigCommerceModifyProductDetailModal').modal('hide');
            });
        }else{

        }
    };
    handleDeleteBigCommerceProduct=async(e)=>{
        e.preventDefault();
        let confirmBox = window.confirm(`Do you confirm to delete ${this.state.selectedSku.Sku}?`);
        if (confirmBox) {
            const storeInfo = await getStoreInfo(this.state.selectChannel);
            if(this.state.selectChannel === "BigCommerce Ashlin"){
                const Ids = this.state.selectedSku.MerchanSku.split('+');
                const productId = Ids[0];
                const variantId = Ids[1];
                const bigCommerceSkuRes = await getVariant(storeInfo, productId, variantId);
                const optionSetValueId = bigCommerceSkuRes.data.data.option_values[0].id;
                const optionSetId = bigCommerceSkuRes.data.data.option_values[0].option_id;
                const deleteVariantRes = await deleteVariant(storeInfo, productId, variantId);
                const deleteVariantOptionValueRes = await deleteVariantOptionValue(storeInfo, productId, optionSetId, optionSetValueId);
                const deleteSkuChannelListingRes = await deleteSkuChannelListing(this.state.selectChannel, this.state.selectedSku.Sku);
                //Hide
                Promise.all([deleteVariantRes,deleteVariantOptionValueRes,deleteSkuChannelListingRes]).then(async () => {
                    alert('Delete Complete!');
                    $('#BigCommerceModifyProductDetailModal').modal('hide');
                });
            }else{
                const deleteProductRes = await deleteProduct(storeInfo,this.state.selectedSkuDetail.bigCommerceDesign.id);
                const deleteSkuChannelListingRes = await deleteSkuChannelListing(this.state.selectChannel, this.state.selectedSku.Sku);
                Promise.all([deleteProductRes,deleteSkuChannelListingRes]).then(async () => {
                    alert('Delete Complete!');
                    $('#BigCommerceModifyProductDetailModal').modal('hide');
                });
            }
        }else{

        }
    };

    handleChangeSwatchColor = (color) => {
        const SkuSwatch = this.state.SkuSwatch;
        if(this.state.selectChannel === "BigCommerce Ashlin"){
            SkuSwatch.swatchColor = color.hex;
            this.setState({ SkuSwatch});
        }else{
            SkuSwatch.swatchColor = color.hex;
            const valueIndex  = SkuSwatch.currentSwatchValue.option_values.findIndex(i=>i.id===SkuSwatch.currentSelectId);
            SkuSwatch.currentSwatchValue.option_values[valueIndex].value_data.colors[0] = color.hex;
            this.setState({ SkuSwatch});
        }


    };
    handleUpdateSKUSwatch=async(e)=>{
        e.preventDefault();
        if(this.state.SkuSwatch.swatchColor === undefined){
            alert("You must select a color");
        }else {
            let confirmBox = window.confirm(`Do you confirm to change ${this.state.selectedSku.Sku}'s swatch color?`);
            if (confirmBox) {
                const storeInfo = await getStoreInfo(this.state.selectChannel);
                if(this.state.selectChannel === "BigCommerce Ashlin"){
                    const data1 = new FormData();
                    data1.append("image", this.state.SkuSwatch.swatchColor);
                    data1.append("vince", "8sHTj5LV527LqdWAQBZSDEXrohTSBx9FXyPnGBamXaj60vNCsDMpUTEsyqXDF4MSuDij4tEoZB7O1vdgVR1H8FLezeBgcRGndRb5MSd70mhYgO5d82wA1lIfPg95W3fXyQn7PVlcisOJFbjSMEOt6P6W2p5Xu4IMFodO5z94Hm25rQGr6iodKTGOwZruXtUX9zv7NFx9");
                    const response = await axios.post("https://ashlinapp.xyz/api/swatch-uploads.php",data,{
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    const url = `https://ashlinapp.xyz/Swatches/${this.state.SkuSwatch.swatchColor.name}`;
                    const data = {
                        "label": this.state.SkuSwatch.currentSwatchValue.label,
                        "sort_order": this.state.SkuSwatch.currentSwatchValue.sort_order,
                        "value_data": {
                            "image_url": url
                        },
                        "is_default": this.state.SkuSwatch.currentSwatchValue.is_default
                    };
                    const res = await updateVariantOptionValue(storeInfo, this.state.selectedSkuDetail.bigCommerceDesign.id, this.state.selectedSkuDetail.bigCommerceSku.option_values[0].option_id, this.state.selectedSkuDetail.bigCommerceSku.option_values[0].id, data);
                    if (res.status === 200) {
                        alert("Swatch Image Update Successfully!");
                        const SkuSwatch = this.state.SkuSwatch;
                        SkuSwatch.swatchColor = undefined;
                        SkuSwatch.isChangeSwatch = false;
                        SkuSwatch.currentSwatchValue = null;
                        this.setState({
                            SkuSwatch
                        });
                    }
                }else{
                    const res = await updateVariantOption(storeInfo,this.state.selectedSkuDetail.bigCommerceDesign.id,this.state.SkuSwatch.currentSwatchValue.id,this.state.SkuSwatch.currentSwatchValue);
                    if (res.status === 200) {
                        alert("Swatch Color Update Successfully!");
                        const SkuSwatch = this.state.SkuSwatch;
                        SkuSwatch.swatchColor = undefined;
                        SkuSwatch.isChangeSwatch = false;
                        SkuSwatch.currentSwatchValue = null;
                        SkuSwatch.currentSelectId = "";
                        this.setState({
                            SkuSwatch
                        });
                    }
                }
            }
        }

    };
    handleChangeSkuSwatch=async(e)=>{
        e.preventDefault();
        const storeInfo =await getStoreInfo(this.state.selectChannel);
        if(this.state.selectChannel === "BigCommerce Ashlin"){
            const variantOptionValueRes=await getVariantOptionValue(storeInfo,this.state.selectedSkuDetail.bigCommerceDesign.id,this.state.selectedSkuDetail.bigCommerceSku.option_values[0].option_id,this.state.selectedSkuDetail.bigCommerceSku.option_values[0].id);
            const SkuSwatch = this.state.SkuSwatch;
            SkuSwatch.isChangeSwatch=true;
            SkuSwatch.currentSwatchValue=variantOptionValueRes.data.data;
            this.setState({
                SkuSwatch
            });
        }else{
            const optionsRes = await getVariantOptions(storeInfo,this.state.selectedSkuDetail.bigCommerceDesign.id);
            const SkuSwatch = this.state.SkuSwatch;
            SkuSwatch.isChangeSwatch=true;
            SkuSwatch.currentSwatchValue=optionsRes.data.data[0];
            this.setState({
                SkuSwatch
            });
        }

    };
    handleChangeImage=(e)=>{
        e.preventDefault();
        const SkuSwatch = this.state.SkuSwatch;
        SkuSwatch.isChangeImage = true;
        this.setState({
            SkuSwatch
        });
    };
    handleCancelUpdateSKUSwatch=(e)=>{
        e.preventDefault();
        const SkuSwatch = this.state.SkuSwatch;
        SkuSwatch.swatchColor=undefined;
        SkuSwatch.isChangeSwatch=false;
        SkuSwatch.currentSelectId ="";
        this.setState({
            SkuSwatch
        });
    };
    handleUpdateSKUImage=async(e)=>{
        e.preventDefault();
        if(this.state.SkuSwatch.imageFile === undefined){
            alert("You must select an image");
        }else{
            let confirmBox = window.confirm(`Do you confirm to change ${this.state.selectedSku.Sku}'s image?`);
            if (confirmBox) {
                const storeInfo = await getStoreInfo(this.state.selectChannel);
                if(this.state.selectChannel === "BigCommerce Ashlin") {
                    let data = new FormData();
                    data.append("image_file", this.state.SkuSwatch.imageFile);
                    try {
                        await addVariantImage(storeInfo, this.state.selectedSkuDetail.bigCommerceDesign.id, this.state.selectedSkuDetail.bigCommerceSku.id, data);
                        alert("Change SKU Image Successfully!");
                        const SkuSwatch = this.state.SkuSwatch;
                        const selectedSkuDetail = this.state.selectedSkuDetail;
                        selectedSkuDetail.bigCommerceSku.image_url = this.state.SkuSwatch.imageFile.preview;
                        SkuSwatch.isChangeImage = false;
                        SkuSwatch.imageFile = undefined;
                        this.setState({
                            SkuSwatch,
                            selectedSkuDetail
                        });

                    } catch (e) {
                        alert("Image is too big!");
                    }
                }else{
                    const product_id = this.state.selectedSkuDetail.bigCommerceDesign.id;
                    const imgRes = await this.state.SkuSwatch.imageFile.map(async (img,i)=>{
                        let data = new FormData();
                        data.append("image_file", img);

                        try{
                            const oldImg = this.state.selectedSkuDetail.bigCommerceProductImages.filter(i=>i.id===img.id);
                            let res;
                            if(oldImg[0].is_thumbnail){
                                res = await updateProductImage(storeInfo,product_id,img.id,true,data);
                            }else {
                                res = await updateProductImage(storeInfo, product_id, img.id,false, data );
                            }

                            return res;
                        }catch (e) {
                            alert(e);
                        }
                    });

                    Promise.all(imgRes).then(()=>{
                        const SkuSwatch = this.state.SkuSwatch;
                        SkuSwatch.isChangeImage = false;
                        SkuSwatch.imageFile = undefined;
                        this.setState({
                            SkuSwatch
                        });
                    })
                }
            }
        }
    };
    handleCancelUpdateSKUImage=(e)=>{
        e.preventDefault();
        const SkuSwatch = this.state.SkuSwatch;
        SkuSwatch.isChangeImage = false;
        SkuSwatch.imageFile = undefined;
        this.setState({
            SkuSwatch
        });
    };
    handleImageOnDrop=(accepted,id,index)=>{
        if(this.state.selectChannel === "BigCommerce Ashlin"){
            const SkuSwatch = this.state.SkuSwatch;
            SkuSwatch.imageFile = accepted[0];
            this.setState({
                SkuSwatch
            });
        }else{
            const SkuSwatch = this.state.SkuSwatch;
            if(SkuSwatch.imageFile === undefined){
                SkuSwatch.imageFile = [];
            }
            accepted[0].id = id;
            SkuSwatch.imageFile[index]=accepted[0];
            this.setState({
                SkuSwatch
            });
        }

    };
    handleSwatchColorOnDrop=(accepted,id,index)=>{
        if(this.state.selectChannel === "BigCommerce Ashlin"){
            const SkuSwatch = this.state.SkuSwatch;
            SkuSwatch.swatchColor= accepted[0];
            this.setState({
                SkuSwatch
            });
        }else{
            const SkuSwatch = this.state.SkuSwatch;
            if(SkuSwatch.swatchColor === undefined){
                SkuSwatch.swatchColor = [];
            }
            accepted[0].id = id;
            SkuSwatch.swatchColor[index]=accepted[0];
            this.setState({
                SkuSwatch
            });
        }

    };
    handleSelectOptionColor=(e,id)=>{
        e.preventDefault();
        const SkuSwatch = this.state.SkuSwatch;
        const valueIndex = SkuSwatch.currentSwatchValue.option_values.findIndex(i=>i.id===id);
        SkuSwatch.currentSelectId = id;
        SkuSwatch.swatchColor =SkuSwatch.currentSwatchValue.option_values[valueIndex].value_data.colors[0];
        this.setState({
            SkuSwatch
        });
    };


    handlePriceListChange=(e)=>{
        this.setState({
            selectedPriceListID: e.target.value
        })
    };
    handleBrightOnchange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        const product = this.state.selectedSkuDetail;
        const index = product.priceLists.findIndex(p=>p.priceListId === parseInt(this.state.selectedPriceListID));
        product.priceLists[index].quantityPrice[name] = value;
        this.setState({
            selectedSkuDetail:product
        });
    };
    handleInventoryOnChange=(e)=>{
        const name = e.target.name;
        const value = e.target.value;
        const updatedInventory = this.state.updatedInventory;
        updatedInventory[name]=value;
        this.setState({
            updatedInventory
        });

    };
    handleSave=async(e)=>{
        e.preventDefault();
        const headers = await getBPheader();
        const brightPearlPriceListsData = {
            "priceLists": this.state.selectedSkuDetail.priceLists
        };
        const res = await upDatePriceList(this.state.selectedSkuDetail.id,brightPearlPriceListsData,headers);
        //console.log(res);
    };
    handleEditButtonClick=(e,warehouseId)=>{
        e.preventDefault();
        //console.log(warehouseId);
        this.setState({
            isEditingStock:true,
            editWarehouseId:warehouseId,
            updatedInventory:{}
        })
    };
    handleUpdateInventory=async(e)=>{
        e.preventDefault();
        if(this.state.updatedInventory.quantity === undefined || this.state.updatedInventory.cost === undefined || this.state.updatedInventory.reason === undefined){
            alert('Must fill all required data!');
        }else{
            let locationId = 10;
            if(this.state.editWarehouseId===2){
                locationId = 2;
            }
            const data = {
                "corrections": [
                    {
                        "quantity": parseInt(this.state.updatedInventory.quantity),
                        "productId": this.state.selectedSkuDetail.id,
                        "reason": this.state.updatedInventory.reason,
                        "locationId": locationId,
                        "cost": {
                            "currency": "CAD",
                            "value": this.state.updatedInventory.cost
                        }
                    }
                ]
            };
            await updateProductInventory(this.state.editWarehouseId,data);
            const selectedSkuDetail = this.state.selectedSkuDetail;
            selectedSkuDetail.inventory = await getProductInventory(selectedSkuDetail.id);
            this.setState({
                selectedSkuDetail,
                isEditingStock:false,
                editWarehouseId:'',
                updatedInventory:{}
            })

        }
    };
    handleASIOnChange=(e)=>{
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const selectedSkuDetail = this.state.selectedSkuDetail;
        if(name==="Categories"){
            selectedSkuDetail.Categories[0] = value;
        }else{
            selectedSkuDetail[name] = value;
        }
        this.setState({
            selectedSkuDetail
        });
    };
    render(){
        return(
            <div>
                <div className='container-fluid row'>
                    <div className={'col-3'}>
                        <MainSideBar/>
                    </div>
                    <div className={'col-9'}>
                        <br/>
                        <div className={'row'}>
                            <div className={'col'}>
                                <h1 className={'text-center'}>Bulk Modify Product</h1>
                            </div>

                            <div className={'col'}>
                                {
                                    this.state.channelList.length !==0 &&
                                    <SelectInput value={this.state.channelList}
                                                 style={{fontWeight:'bold',fontSize:'20px'}}
                                                 label={"Channel Name"} onChange={(e)=>this.handleOnchange(e)}/>
                                }
                            </div>
                            <div className={'col'}>
                                { this.state.modifiedList[0] && <button className={'btn btn-danger'} onClick={(e)=>this.handleUpdate(e)}>Update Modified Product</button>}
                            </div>
                        </div>
                        <hr/>
                        <div className={'row'}>
                            <div className={'col'}>
                                {this.state.isUpLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                                {this.state.isDownLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Reading data from database...</div>}
                            </div>
                        </div>

                        <div className={'row'}>
                            <div className={'col-1'}>

                            </div>
                            <div className={'col'}>
                                <h2 style={{textAlign:'center'}}>Published SKU List</h2>
                                { this.state.skuList[0] &&

                                <ReactTable
                                    data={this.state.skuList}
                                    filterable
                                    defaultFilterMethod={(filter, row) =>
                                        String(row[filter.id]) === filter.value}
                                    columns={[
                                        {
                                            Header: "Enter SKU below to Search",
                                            id:'Sku',
                                            accessor: item=>item.Sku,
                                            filterMethod: (filter, row) =>
                                                row[filter.id].startsWith(filter.value)

                                        }]
                                    }
                                    defaultPageSize={10}
                                    className="-striped -highlight orderTable"
                                    getTrProps={(state, rowInfo) => {
                                        return {
                                            onClick: () => {
                                                this.setState({
                                                    selectedSku: rowInfo.original
                                                },this.handleDetail);
                                            }
                                        }
                                    }}
                                />
                                }
                            </div>
                            <div className={'col-5'}>
                                {this.state.selectedSkuDetail !== null && this.state.selectChannel === "WalmartCa"?
                                    <BulkModifyProductDetailModal
                                        item={this.state.selectedSkuDetail}
                                        onChange={this.handleModalOnchange}
                                        addToList={this.handleAddToModifiedList}
                                        removeFromList={this.handleRemoveFromModifiedList}
                                        modifiedList = {this.state.modifiedList}
                                        handleRestSkuDetail={this.handleRestSkuDetail}
                                />:null}
                                {this.state.selectedSkuDetail !== null && this.state.selectChannel === "BestBuy"?
                                    < BestBuyDiscountModal
                                        item={this.state.selectedSkuDetail}
                                        onChange={this.handleModalOnchange}
                                        addToList={this.handleAddToModifiedList}
                                        removeFromList={this.handleRemoveFromModifiedList}
                                        modifiedList = {this.state.modifiedList}
                                        changeStartDate = {this.handleChangeStartDate}
                                        changeEndDate = {this.handleChangeEndDate}
                                        handleRestSkuDetail={this.handleRestSkuDetail}
                                    />:null}

                                {this.state.selectedSkuDetail !== null && (this.state.selectChannel === "BigCommerce Ashlin"||this.state.selectChannel === "BigCommerce Poppybuzz")?
                                    < BigCommerceModifyProductDetailModal
                                        item={this.state.selectedSkuDetail}
                                        onChange={this.handleModalOnchange}
                                        calculateBulkPriceRule={this.handleCalculateBulkPriceRule}
                                        updateBigCommerceProduct={this.handleUpdateBigCommerceProduct}
                                        deleteBigCommerceProduct={this.handleDeleteBigCommerceProduct}
                                        SkuSwatch={this.state.SkuSwatch}
                                        changeSwatch={this.handleChangeSkuSwatch}
                                        UpdateSKUSwatch={this.handleUpdateSKUSwatch}
                                        CancelUpdateSKUSwatch={this.handleCancelUpdateSKUSwatch}
                                        ChangeSwatchColor={this.handleChangeSwatchColor}
                                        changeImage={this.handleChangeImage}
                                        UpdateSKUImage={this.handleUpdateSKUImage}
                                        CancelUpdateSKUImage={this.handleCancelUpdateSKUImage}
                                        ImageOnDrop={this.handleImageOnDrop}
                                        SwatchColorOnDrop = {this.handleSwatchColorOnDrop}
                                        SelectOptionColor={this.handleSelectOptionColor}
                                        handleRestSkuDetail={this.handleRestSkuDetail}
                                    />:null}
                                {this.state.selectedSkuDetail !== null && this.state.selectChannel === "BrightPearl"?
                                    <BrightPearlProductDetailModal
                                        product={this.state.selectedSkuDetail}
                                        selectedPriceListID={this.state.selectedPriceListID}
                                        isEditingStock={this.state.isEditingStock}
                                        editWarehouseId={this.state.editWarehouseId}
                                        handlePriceListChange={this.handlePriceListChange}
                                        handleOnchange={this.handleBrightOnchange}
                                        handleSave={this.handleSave}
                                        handleInventoryOnChange={this.handleInventoryOnChange}
                                        handleEditButtonClick={this.handleEditButtonClick}
                                        handleUpdateInventory={this.handleUpdateInventory}
                                        updatedInventory={this.state.updatedInventory}
                                        handleRestSkuDetail={this.handleRestSkuDetail}
                                    />:null
                                }
                                {this.state.selectedSkuDetail !== null && this.state.selectChannel === "ASI"?
                                    <AsiModifyProductModal
                                        product={this.state.selectedSkuDetail}
                                        onChange={this.handleASIOnChange}
                                    />:null
                                }
                                {this.state.selectedSkuDetail !== null && this.state.selectChannel === "NewEgg" ?
                                    <NewEggModifyProductModal
                                    skuInfo={this.state.selectedSku}
                                    itemListOnNewEgg={this.state.selectedSkuDetail}
                                    updateItemFieldFromNewEgg={updateItemFieldFromNewEgg}
                                    handleRestSkuDetail={this.handleRestSkuDetail}
                                    />: null
                            
                                }


                                <h2 style={{textAlign:'center'}}>Modified List</h2>
                                <div style={{maxHeight:'557px',minWidth:'360px',overflowY:'auto'}}>
                                    {this.state.modifiedList.map((item,index)=>{
                                        return <div  key={index}>
                                            <button className={'btn btn-info btn-block'}  onClick={(e)=>this.handleButtonClick(e,item)}>{this.state.selectChannel === "BestBuy"?item.shop_sku:item.Sku}</button>
                                        </div>

                                    })}
                                </div>
                            </div>
                            <div className={'col-1'}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default BulkModifyProduct;