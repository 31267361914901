import { inventoryURL,headers } from './requestHelper';
import axios from 'axios';
export const staplesCaInventoryFileGenerator=(vendorNum,list)=>{
    let str = "";
    str +=  "Vendor Number,Seller Item ID,Staples SKU,Facility,ItemType,Condition,OnHandQty,NextAvailableQty,NextAvailableDate,LeadTimeMinDays,LeadTimeMaxDays,AvailableEndDate,AvailabilityStatus,UnitOfMeasure\n";
    list.forEach(item=>{
        str +=`${vendorNum},${item.Sku},${item.MerchanSku},,,NEW,${item.Inventory},,,,,,Available,EA\n`;
    });
    return str;
};


export const staplesInventoryUpdate=async(channelName,fileStream)=>{
    const data = {
        "ChannelName":channelName,
        "FileStream":fileStream
    };

    return await axios.post(inventoryURL,data,{headers});
};
