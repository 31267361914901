import React, { Component } from 'react';
import MainSideBar from '../../sideBar/MainSideBar';
import { getListedSkus,getSkuDetail,priceList,getSKUImageforASI } from "../../../Utils/ashlinDesign";
import {getAuth, getCredentials, getAsiProduct, getAsiProductTypes, getAsiCategories,pricesDetail,generalPrices,postAsiProduct} from "../../../Utils/asi";
import Lists from './component/asi_product_list';
class ASI_Update extends Component{
    _mounted = false;
    constructor(){
        super();
        this.state={
            XIDs : [],
            isDownLoading:true,
            isUpLoading:false,
            currentPageNumber:1,
            currentShownList:[],
            wholeList:[],
            loadedPageNumber:[1],
            toUpdateList:[],
            asiProductTypes:[],
            asiCategories:[],
            isCalculating:false,
            search:''
        }
    }
    componentDidMount(){
        this._mounted = true;
        this.loadData();
    };
    loadData = async()=>{
        let XIDs = (await getListedSkus("ASI")).data;
        XIDs = [...new Set(XIDs.map(i => i.MerchanSku))];
        XIDs = XIDs.map(i=>{return {"XID":i}});
        const start = (this.state.currentPageNumber -1) * 10;
        const end = this.state.currentPageNumber  * 10 ;
        const currentShownXIDx= XIDs.slice(start,end);
        const credentials = await getCredentials();
        const token = await getAuth(credentials);
        const currentShownListRes = currentShownXIDx.map(async xid=>{
            return await getAsiProduct(xid.XID,token);
        });

        const asiProductTypes = await getAsiProductTypes();
        const asiCategories = await getAsiCategories();

        Promise.all(currentShownListRes ).then(currentShownList=>{
        //     console.log(JSON.stringify(currentShownList));
            //console.log(JSON.stringify(currentShownList));
            if(this._mounted) {
                this.setState({
                    XIDs,
                    currentShownList,
                    wholeList:currentShownList.filter(i=>i!==null),
                    isDownLoading:false,
                    asiProductTypes,
                    asiCategories
                });
            }
        })

    };
    componentWillUnmount() {
        this._mounted = false;
    }
    handleClickNextBtn=()=>{
        const currentPageNumber = this.state.currentPageNumber + 1;
        const loadedPageNumber = this.state.loadedPageNumber;
        const start = (currentPageNumber -1) * 10;
        const end = currentPageNumber  * 10 ;
        if(loadedPageNumber.includes(currentPageNumber)){
            //console.log("read old")
            const currentShownList = this.state.wholeList.slice(start,end);
            this.setState({
                currentShownList,
                currentPageNumber
            });
        }else{
            this.setState({
                isDownLoading:true
            },async()=>{
                //console.log("read new")
                const XIDs = this.state.XIDs;
                const currentShownXIDx = XIDs.slice(start,end);
                const credentials = await getCredentials();
                const token = await getAuth(credentials);
                const currentShownListRes = currentShownXIDx.map(async xid=>{
                    return await getAsiProduct(xid.XID,token);
                });
                Promise.all(currentShownListRes ).then(currentShownList=>{
                    const wholeList = [...this.state.wholeList,...currentShownList.filter(i=>i!==null)];
                    loadedPageNumber.push(currentPageNumber);
                    if(this._mounted) {
                        this.setState({
                            wholeList,
                            currentShownList,
                            currentPageNumber,
                            loadedPageNumber,
                            isDownLoading:false
                        });
                    }
                })
            })

        }


    };
    handleClickPreviousBtn=()=>{
        const currentPageNumber = this.state.currentPageNumber - 1;
        const start = (currentPageNumber -1) * 10;
        const end = currentPageNumber  * 10 ;
        const currentShownList = this.state.wholeList.slice(start,end);
        this.setState({
            currentShownList,
            currentPageNumber
        });
    };
    handleProductOnSelect=async(e,item)=>{
        if(e.target.checked){
            const toUpdateList = this.state.toUpdateList;
            const toUpdateItem = JSON.parse(JSON.stringify(item));
            const availableImages =await getSKUImageforASI(toUpdateItem.ExternalProductId);
            toUpdateItem.FinishedEditing = false;
            toUpdateItem.availableImages = availableImages;

            toUpdateList.push(toUpdateItem);
            this.setState({
                toUpdateList
            })
        }else{
            const toUpdateList = this.state.toUpdateList;
            const index = toUpdateList.findIndex(i=>i.ExternalProductId === item.ExternalProductId);
            toUpdateList.splice(index,1);
            this.setState({
                toUpdateList
            })
        }
    };

    handleEditingOnChange=(e,index)=>{
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        const toUpdateList = this.state.toUpdateList;
        if(name==="Categories"){
            toUpdateList[index].Categories[0]= value;
        }else{
            toUpdateList[index][name] = value;
        }
        this.setState({
            toUpdateList
        })
    };
    handleFinishEditing=(e,index)=>{
        const name = e.target.name;
        const toUpdateList = this.state.toUpdateList;
        toUpdateList[index][name] = true;

        const currentShownList = this.state.currentShownList;
        const currentShownListIndex = currentShownList.findIndex(item=>item.ExternalProductId === toUpdateList[index].ExternalProductId);
        currentShownList[currentShownListIndex] = toUpdateList[index];

        const wholeList = this.state.wholeList;
        const wholeListIndex = wholeList.findIndex(item=>item.ExternalProductId === toUpdateList[index].ExternalProductId);
        wholeList[wholeListIndex] = toUpdateList[index];

        this.setState({
            toUpdateList,
            currentShownList,
            wholeList
        })
    };
    handleCalculateNewPrices=(toUpdateItem,index)=>{
        this.setState({
            isCalculating:true
        },async()=>{
            let priceDetail;
            if( parseInt(toUpdateItem.BasePrice) === 0 || toUpdateItem.BasePrice==="" ){
                //IF base price set to 0, contact for pricing
                priceDetail = pricesDetail(0,null)
            }else{
                const ashlinSKU = toUpdateItem.ProductSKUs[0].SKU;
                const ashlinSKUDetail = await getSkuDetail(ashlinSKU);
                ashlinSKUDetail.Price =  parseFloat(toUpdateItem.BasePrice) / 0.85 / 1.05;
                const prices = await priceList(ashlinSKUDetail);
                priceDetail = pricesDetail(toUpdateItem.BasePrice,prices)
            }
            toUpdateItem.PriceGrids = [
                ...generalPrices,
                ...priceDetail
            ];
            const toUpdateList = this.state.toUpdateList;
            toUpdateList[index] = toUpdateItem;
            this.setState({
                toUpdateList,
                isCalculating:false
            },()=>alert("New prices calculated!"))
        });

    };
    handleAvailableImagesOnClick =(index,imageIndex,url)=>{
        const toUpdateList = this.state.toUpdateList;
        if(toUpdateList[index].Images[imageIndex].ImageURL=== url && toUpdateList[index].Images[imageIndex].NewImageURL !== undefined){
           delete toUpdateList[index].Images[imageIndex].NewImageURL;
        }else{
            toUpdateList[index].Images[imageIndex].NewImageURL = url;
        }
        this.setState({
            toUpdateList
        })

    };
    handleSetDefaultImage = (e,index,imageIndex)=>{
        const toUpdateList = this.state.toUpdateList;
        toUpdateList[index].Images = toUpdateList[index].Images.map(img=>{
            return{
                Configurations : img.Configurations,
                IsPrimary : false,
                Rank: (img.Rank+1),
                ImageURL:img.ImageURL
            }
        });
        toUpdateList[index].Images[imageIndex].IsPrimary = true;
        toUpdateList[index].Images[imageIndex].Rank = 1;
        this.setState({
            toUpdateList
        })
    };
    handleUpdateProduct=()=>{
        this.setState({
            isUpLoading:true
        },async()=>{
            const credentials = await getCredentials();
            const token = await getAuth(credentials);
            const res = await this.state.toUpdateList.map(async item=>{
                if(item.BasePrice!==undefined){
                    delete item.BasePrice;
                }
                if(item.availableImages!==undefined){
                    delete item.availableImages;
                }
                if(item.FinishedEditing !== undefined){
                    delete item.FinishedEditing;
                }
                if(item.Inventory !== undefined){
                    delete item.Inventory;
                }
                item.Images = item.Images.map(img=>{
                    const url = img.NewImageURL !== undefined? img.NewImageURL:img.ImageURL;
                    return{
                        Configurations : img.Configurations,
                        IsPrimary : img.IsPrimary,
                        Rank: img.Rank,
                        ImageURL:url
                    }
                });

                return await postAsiProduct(item,token);
            });
            Promise.all(res).then(()=>{
                this.setState({
                    isUpLoading:false,
                    toUpdateList:[]
                })
            })
        });


    };
    handleStateOnchange=(e)=>{
        const value = e.target.value;
        const name = e.target.name;
        this.setState({
            [name]:value
        })
    };
    handleOnSearch=()=>{
        this.setState({
            isDownLoading:true
        },async()=>{
            const search = this.state.search.split('-');
            const XID = `${search[0]}-${search[1]}`;
            const credentials = await getCredentials();
            const token = await getAuth(credentials);
            const searchRes = await getAsiProduct(XID,token);
            if(searchRes===null){
                alert(`Can't find ${XID} on ASI`)
            }else{
                this.setState({
                    currentShownList:[searchRes],
                    isDownLoading:false
                });
            }
        })
    };
    render(){
        return(
            <div className='container-fluid row'>
                <div className={'col-3'}>
                    <MainSideBar/>
                </div>
                <div className={'col'}>

                    <br />
                    <h1 className={"text-center"}>Modify ASI Products
                        {this.state.toUpdateList.length!==0 &&
                            <button type={'button'} className={'btn btn-danger float-right'} onClick={this.handleUpdateProduct}> Update changes to ASI</button>
                        }

                    </h1>
                        {this.state.isUpLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Submitting...</div>}
                        {this.state.isDownLoading &&<div className={"text-center text-success display-4"}><i className="fa fa-spinner fa-spin"></i> &nbsp; Downloading Data...</div>}
                    <br/>
                    <div className={'row'}>
                        <div className={'col-3'}>
                            { this.state.currentPageNumber !== 1 &&
                            <button type={'button'} className={'btn btn-block btn-success'} onClick={this.handleClickPreviousBtn}>PREVIOUS</button>
                            }
                        </div>
                        <div className={'col-6'}>
                            <div className={'row'}>
                                <div className={'col-1'}>

                                </div>
                                <div className={'col'}>
                                    <input type={'text'} name={'search'} value={this.state.search} onChange={this.handleStateOnchange}/>
                                    <button type={'btn'} className={'btn btn-success'} onClick={this.handleOnSearch}>Search</button>
                                </div>
                                <div className={'col-1'}>

                                </div>
                            </div>
                        </div>
                        <div className={'col-3'}>
                            { ( !this.state.isDownLoading &&Math.ceil(this.state.XIDs.length/10) !== this.state.currentPageNumber )&&
                            <button type={'button'} className={'btn btn-block btn-success'}
                                    onClick={this.handleClickNextBtn}>NEXT</button>
                            }
                        </div>
                    </div>
                    {
                        this.state.currentShownList.length!==0&&
                        <Lists list={this.state.currentShownList} selectProduct={this.handleProductOnSelect}
                               toUpdateList = {this.state.toUpdateList}  editingOnChange={this.handleEditingOnChange}
                               finishEditing = {this.handleFinishEditing}
                               AsiProductTypes={this.state.asiProductTypes}  AsiCategories={this.state.asiCategories}
                               calculateNewPrices={this.handleCalculateNewPrices}
                               availableImagesOnClick = {this.handleAvailableImagesOnClick}
                               isCalculating = {this.state.isCalculating}
                               setDefaultImage = {this.handleSetDefaultImage}
                        />
                    }

                    <br/>
                    <div className={'row'}>
                        <div className={'col-3'}>
                            { this.state.currentPageNumber !== 1 &&
                                 <button type={'button'} className={'btn btn-block btn-success'} onClick={this.handleClickPreviousBtn}>PREVIOUS</button>
                            }
                        </div>
                        <div className={'col-6'}>
                        </div>
                        <div className={'col-3'}>
                            { ( !this.state.isDownLoading &&Math.ceil(this.state.XIDs.length/10) !== this.state.currentPageNumber )&&
                                <button type={'button'} className={'btn btn-block btn-success'}
                                        onClick={this.handleClickNextBtn}>NEXT</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ASI_Update;