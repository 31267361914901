import React from 'react';
const TextInput =({className,id,label,value,name,placeholder,onChange, type})=>{
    return(
        <div className={`${className} form-group`}>
            <label htmlFor={id} >{label}</label>
            <input type="text" className="form-control" id={id} value={value} type={type}
                   onChange={onChange} name={name} placeholder={placeholder} />
        </div>
    )
};
export default TextInput;