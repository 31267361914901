import React, {Component} from 'react';
import MainSideBar from '../sideBar/MainSideBar';
import {getBPheader, getOrderDetailsByOrderID, getUSCustomerFEINNumber,} from "../../Utils/brightpearl";
import {
    getCompanyInfoById,
    getDesignByCode,
    getCompanyInfos,
    getSignatureById,
    getSkuWithHts, sendPDFEmail
} from "../../Utils/ashlinDesign";
import $ from "jquery";
import ManualProformaInvoiceModal from './manualProformaInvoiceModal';
import _ from "lodash";

class ManualProformaInvoice extends Component {
    _mounted = false;
    constructor(props){
        super(props);
        this.state={
            Signature:undefined,
            AshlinInfo:undefined,
            CompanyInfo:[],
            SkuWithHts:[],
            thirdPartyAccountNum:'',
            bpHeader:'',
            bpOrderID:'',
            bpOrder:undefined,
            FEIN:'',
            attachBase64:undefined,
            useAshlinAsImporter: false,
            emailList:''
        }
    }
    componentDidMount(){
        this._mounted = true;
        this.getData();
    }
    getData=async()=>{
        this.setState({
            isDownLoading:true
        },async()=>{
            const bpHeader = await getBPheader();
            const CompanyInfo = _.orderBy(await getCompanyInfos(),['Name'],['desc']);
            const AshlinInfo = CompanyInfo.find(c=>c.Id==='f572b524-d8c1-4ef3-928e-b3083dde9252');
            const Signature = await getSignatureById('8D1D8DBB-4805-4BE9-83C8-EEE441AAE7E5');
            const SkuWithHts = await getSkuWithHts();
            this.setState({
                isDownLoading:false,
                bpHeader,
                AshlinInfo,
                Signature,
                SkuWithHts,
                CompanyInfo
            });
        })
    };
    componentWillUnmount() {
        this._mounted = false;
    }
    handleOnchange=(e)=>{
        const name = e.target.name;
        const value =e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        this.setState({
            [name]:value
        });
    };
    handleUseAshlinAsImporter=(e)=>{
        if(this.state.FEIN===""){
            const name = e.target.name;
            const value =e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            this.setState({
                [name]:value
            });
        }
    };
    handleGenerateProforma=()=>{
        $('#ManualProformaInvoiceModal').modal('toggle');
    };
    handleLoadBPOrder = async ()=>{
        this.setState({
            bpOrder:undefined,
            FEIN:'',
            attachBase64:undefined,
            useAshlinAsImporter: false,
            emailList:''
        },async()=>{
            let {bpOrderID} = this.state;
            bpOrderID = bpOrderID.replace(/\s/g, '');
            const isnum = /^\d+$/.test(bpOrderID);
            if(!isnum || bpOrderID.length !==6){
                alert("Not a valid Brightpearl Order ID!");
            }else{
                const bpOrder= await getOrderDetailsByOrderID(bpOrderID,this.state.bpHeader);
                if(bpOrder===undefined){
                    alert(`Can't find order ID: ${bpOrderID} on Brightpearl!`);
                }else{

                    const temp = Object.entries(bpOrder.orderRows);
                    bpOrder.orderRows = Object.values(temp.map(arr=>{
                        arr[1].rowId = arr[0];
                        return arr[1];
                    }));

                    //get hts code and description here
                    bpOrder.orderRows .forEach(ol => {
                        const hts = this.state.SkuWithHts.find(hts => hts.Sku === ol.productSku);
                        if(hts !== undefined){
                            if(hts.CaDuty!==undefined &&hts.UsDuty!==undefined ){
                                ol.HtsCa = hts.HtsCa;
                                ol.HtsUs = hts.HtsUs;
                                let renderedHts = bpOrder.parties.delivery.countryIsoCode === "US"? hts.HtsUs: hts.HtsCa;
                                ol.CaDuty = hts.CaDuty.toString().startsWith("0.0")?hts.CaDuty * 100:hts.CaDuty;
                                ol.UsDuty = hts.UsDuty.toString().startsWith("0.0")?hts.UsDuty * 100:hts.UsDuty;
                                ol.HtsDescription = `[${hts.Sku}] ${hts.DesignDescription}-${hts.MaterialDesignDescription}, HTS:${renderedHts}`;
                            }
                        }else {
                            ol.HtsCa = "4205.00.0000";
                            ol.HtsUs = "4205.00.8000";
                            ol.CaDuty = 0;
                            ol.UsDuty = 0;
                            ol.HtsDescription = "";
                        }
                    });


                    let FEIN = "";
                    //IF US Customer, try to get FEIN Number
                    if (bpOrder.parties.delivery.countryIsoCode === "US") {
                        const res = await getUSCustomerFEINNumber(bpOrder.parties.customer.contactId);
                        if (res !== undefined)
                            FEIN = res;
                    }
                    //console.log(bpOrder);
                    this.setState({
                        bpOrder,
                        FEIN
                    })
                }
            }
        });
    };
    handleOrderAttachFile = (base64String)=>{
        //console.log(base64String);
        this.setState({
            attachBase64:base64String
        })
    };
    handleCompanyOnChange = (e)=>{
        const value = e.target.value;
        //console.log(value)
        const AshlinInfo = JSON.parse(value);
        this.setState({
            AshlinInfo
        },()=>console.log(this.state))
    };
    handleReplaceRowValue=(e,index)=>{
        const bpOrder = this.state.bpOrder;
        const name = e.target.name;
        const row = bpOrder.orderRows[index];
        if(name ==="lineTotal"){
            row.rowValue.rowNet.value = e.target.value;
        }else{
            row[name] = e.target.value.toUpperCase();
        }
        this.setState({
            bpOrder
        });
    };
    handleDeleteRow=(e,index)=>{
        e.preventDefault();
        const bpOrder = this.state.bpOrder;
        bpOrder.orderRows.splice(index,1);
        this.setState({
            bpOrder
        });
    };
    handleBPOrderOnChange=(e,addressType)=>{
        const value = e.target.value;
        const name = e.target.name;
        const bpOrder = this.state.bpOrder;
        const address = bpOrder.parties[addressType];
        address[name] = value;
        this.setState({
            bpOrder
        },()=>{console.log(this.state)})
    };
    handleSendEmail=async()=>{
        const recipients = this.state.emailList.split("|").map(email=>email.trim()).filter(email=>email!=="");
        const subject = `Proforma Invoice for Order #${this.state.bpOrder.id}`;
        const emailBody = `<p>Hi there</p><br/><p>The attachment is Proforma Invoice for Order #${this.state.bpOrder.id}</p><br/><p>Thanks</p>`;
        await sendPDFEmail(recipients,subject,emailBody,this.state.attachBase64,"PROFORMA_INVOICE.pdf");
    };
    render(){
        //console.log(this.state)
        const order = this.state.bpOrder;

    return(
        <div className='container-fluid row'>
            <div className={'col-3'}>
                <MainSideBar/>
            </div>
            <ManualProformaInvoiceModal bpOrder={order} FEIN={this.state.FEIN} orderAttachFile={this.handleOrderAttachFile}
                             thirdPartyAccountNum={this.state.thirdPartyAccountNum} selectedCarrier={this.state.selectedCarrier}
                             AshlinInfo={this.state.AshlinInfo} Signature={this.state.Signature} useAshlinAsImporter={this.state.useAshlinAsImporter}
            />

            <div className={'col-9'} style={{textAlign:'center'}}>
                <br/>
                <br/>
                <h1 >Ashlin Proforma</h1>
                <hr/>
                <div className="row">
                    <div className="col-2">
                    </div>
                    <div className="col-4">
                        <input type={'text'} onChange={this.handleOnchange} name='bpOrderID' value={this.state.bpOrderID} style={{height:'100%',fontSize:'30px'}}/>
                    </div>
                    <div className="col-4">
                        <button type={'button'} className={'btn btn-success btn-block'} onClick={this.handleLoadBPOrder} >Load BP Order</button>
                    </div>
                    <div className="col-2">
                    </div>
                </div>
                <hr/>
                <div className={'row'}>
                    {order!==undefined &&
                        <div className={'col'}>
                            <h2>Order Information</h2>
                            <table className="table table-bordered ConfirmManualShipmentModalTable">
                                <thead>
                                <tr>
                                    <th scope="col"> </th>
                                    <th scope="col">Invoice address (IMPORTER)</th>
                                    <th scope="col">Delivery address (SHIP TO)</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th scope="row">Full Name</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.addressFullName !==undefined)?order.parties.billing.addressFullName:""} onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'addressFullName'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.addressFullName !==undefined)?order.parties.delivery.addressFullName:""} onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'addressFullName'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">Company</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.companyName !==undefined)?order.parties.billing.companyName:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'companyName'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.companyName !==undefined)?order.parties.delivery.companyName:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'companyName'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">Street</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.addressLine1 !==undefined)?order.parties.billing.addressLine1:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'addressLine1'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.addressLine1 !==undefined)?order.parties.delivery.addressLine1:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'addressLine1'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">Suburb</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.addressLine2 !==undefined)?order.parties.billing.addressLine2:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'addressLine2'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.addressLine2 !==undefined)?order.parties.delivery.addressLine2:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'addressLine2'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">City</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.addressLine3 !==undefined)?order.parties.billing.addressLine3:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'addressLine3'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.addressLine3 !==undefined)?order.parties.delivery.addressLine3:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'addressLine3'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">State/county</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.addressLine4 !==undefined)?order.parties.billing.addressLine4:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'addressLine4'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.addressLine4 !==undefined)?order.parties.delivery.addressLine4:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'addressLine4'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">Postcode</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.postalCode !==undefined)?order.parties.billing.postalCode:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'postalCode'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.postalCode !==undefined)?order.parties.delivery.postalCode:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'postalCode'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">Country</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.country !==undefined)?order.parties.billing.country:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'country'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.country !==undefined)?order.parties.delivery.country:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'country'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">Telephone</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.telephone !==undefined)?order.parties.billing.telephone:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'telephone'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.telephone !==undefined)?order.parties.delivery.telephone:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'telephone'}/>{(order.parties!==undefined&&order.parties.delivery.telephone !==undefined)?order.parties.delivery.telephone:"" }</td>
                                </tr>
                                <tr>
                                    <th scope="row">Mobile</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.mobileTelephone !==undefined)?order.parties.billing.mobileTelephone:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'mobileTelephone'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.mobileTelephone !==undefined)?order.parties.delivery.mobileTelephone:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'mobileTelephone'}/></td>
                                </tr>
                                <tr>
                                    <th scope="row">Email address</th>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.billing.email !==undefined)?order.parties.billing.email:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'billing')} name={'email'}/></td>
                                    <td style={{padding:'0', height:'20px'}}><input type={'text'} style={{width:'100%', height:'100%', boxSizing:'border-box'}} value={(order.parties!==undefined&&order.parties.delivery.email !==undefined)?order.parties.delivery.email:"" } onChange={(e)=>this.handleBPOrderOnChange(e,'delivery')} name={'email'}/></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                    {order!==undefined &&
                    <div className={'col'}>
                        <h2>Shipment Information</h2>
                        <table className="table  table-bordered" style={{margin:'16px 0 0'}}>
                            <tbody>
                                <tr>
                                    <th>Shipper Info</th>
                                    { (this.state.CompanyInfo && this.state.AshlinInfo)&&
                                        <td>
                                            <select name={'AshlinInfo'}  style={{width:'100%',height:'30px'}} onChange={this.handleCompanyOnChange}>
                                                {this.state.CompanyInfo.map((op,i)=>{
                                                    return <option value={JSON.stringify(op)} key={i}>{op.Name}</option>
                                                })}
                                            </select>
                                        </td>
                                    }
                                </tr>
                                <tr>
                                    <th>FEIN #?</th>
                                    <td>
                                        <input type="text"  name={'FEIN'} value={this.state.FEIN} onChange={this.handleOnchange}/>
                                    </td>
                                </tr>
                                {
                                    this.state.FEIN === "" &&
                                    <tr>
                                        <th>Use Ashlin as Importer ?</th>
                                        <td>
                                            <input type="checkbox"  name={'useAshlinAsImporter'} style={{width:'30px',height:'30px'}} checked={this.state.useAshlinAsImporter} onChange={this.handleUseAshlinAsImporter}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    order!==undefined &&
                                    <tr>
                                        <th colSpan={'2'}><button type={'button'} className={'btn btn-success btn-block'} onClick={this.handleGenerateProforma} >Generate Invoice</button></th>
                                    </tr>
                                }
                                {
                                    this.state.attachBase64!==undefined &&
                                    <tr>
                                        <th>Email List (separate by | )</th>
                                        <td style={{padding:'0', height:'20px'}}>
                                            <input type="text"  style={{width:'100%', height:'100%', boxSizing:'border-box'}} name={'emailList'} value={this.state.emailList} onChange={this.handleOnchange}/>
                                        </td>
                                    </tr>
                                }
                                {
                                    this.state.attachBase64!==undefined &&
                                    <tr>
                                        <th colSpan={'2'}><button type={'button'} className={'btn btn-success btn-block'} onClick={this.handleSendEmail} >Send Email</button></th>
                                    </tr>
                                }

                            </tbody>
                        </table>
                        <br/>
                    </div>
                    }
                </div>
                {order!==undefined &&
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        <th scope="col">Line#</th>
                        <th scope="col">SKU</th>
                        <th scope="col">Description</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Line Total</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        order.orderRows.map((ol,index)=>{
                            return <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td><input type={'text'} name={'productSku'} onChange={e=>this.handleReplaceRowValue(e,index)} value={ol.productSku}/></td>
                                <td><input type={'text'} name={'HtsDescription'} onChange={e=>this.handleReplaceRowValue(e,index)} value={ol.HtsDescription}/></td>
                                <td>{parseInt(ol.quantity.magnitude)}</td>
                                <td><input type={'text'} name={'lineTotal'} onChange={e=>this.handleReplaceRowValue(e,index)} value={ol.rowValue.rowNet.value}/></td>
                                <td><button onClick={e=>this.handleDeleteRow(e,index)} className={'btn-danger'}>DELETE</button></td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>}
            </div>
        </div>
    )}
}
export default ManualProformaInvoice;