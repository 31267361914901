export const ESPCategories = [
    'ADULT NOVELTIES',
    'ALARMS & PROTECTIVE DEVICES',
    'ALMANACS',
    'ANTENNA ACCESSORIES',
    'ANTIBACTERIAL PRODUCTS',
    'ANTIMICROBIAL ENHANCED PRODUCTS',
    'APRONS',
    'ARM BANDS',
    'AUTO ACCESSORIES',
    'AUTO DASH ACCESSORIES',
    'AWARD PROGRAMS',
    'AWARDS',
    'BABY ITEMS',
    'BACKPACKS',
    'BADGE HOLDERS',
    'BAGGAGE TAGS',
    'BAGS',
    'BALLOON ACCESSORIES',
    'BALLOONS',
    'BALLS',
    'BANDAGES',
    'BANDANNAS',
    'BANKS',
    'BANNERS',
    'BAR ACCESSORIES',
    'BARS',
    'BASEBALL CAPS',
    'BASKETS',
    'BATTERIES',
    'BATTERY RECHARGERS & ADAPTORSBEACH BALLS',
    'BEAUTY AIDS',
    'BEVERAGES',
    'BELLS',
    'BELTS',
    'BICYCLE ACCESSORIES',
    'BINDERS',
    'BLANKETS',
    'BOOKMARKS',
    'BOOKS',
    'BOTTLES',
    'BOXES',
    'BRIEFCASES',
    'BRUSHES',
    'BUSINESS CARD HOLDERS',
    'BUSINESS CARDS',
    'BUTTONS',
    'CALENDAR PADS',
    'CALENDARS',
    'CAMERAS',
    'CAMPING EQUIPMENT',
    'CANDY',
    'CANES',
    'CAP & HAT ACCESSORIES',
    'CAPS & HATS',
    'CARABINERS',
    'CARAFE SETS',
    'CARDS',
    'CARTON CUTTERS',
    'CARTSCASES',
    'CERTIFICATE HOLDERS & FRAMES',
    'CHAINS',
    'CHEERING ACCESSORIES',
    'CHEWING GUM',
    'CHOPSTICKS',
    'CLEANERS',
    'CLIPBOARDS',
    'CLIPS-UTILITY',
    'CLOCKS',
    'CLOTHING',
    'COASTERS',
    'COFFEE',
    'COIN PURSES',
    'COINS-TOKENS & MEDALLIONS',
    'COLORING BOOKS',
    'COMPASSES',
    'COMPUTER ACCESSORIES',
    'CONDOMS',
    'CONTAINERS',
    'COOKIE JARS',
    'COOLERS',
    'CORKSCREWS',
    'COSMETIC BAGS',
    'COSTUMES & ACCESSORIES',
    'COVERS',
    'CUPS',
    'CUSTOM PRODUCTS',
    'CUTTERS',
    'DECALSDECANTER SETS',
    'DECORATIONS',
    'DECORATORS',
    'DESK ACCESSORIES',
    'DIALS & SLIDE CHARTS',
    'DIARIES',
    'DISPENSERS',
    'DISPLAY CASES',
    'DOMES',
    'DUFFEL BAGS',
    'EARMUFFS',
    'EARPLUGS',
    'ELECTRONIC DEVICES',
    'ENERGY & SPORTS DRINKS',
    'ENVIRONMENTALLY FRIENDLY PRODUCTS',
    'ERASERS',
    'EXERCISE EQUIPMENT',
    'EXTENSION CORDS',
    'EYEGLASS ACCESSORIES',
    'EYEGLASS CASES & HOLDERS',
    'EYEGLASS CLEANERS',
    'FANS',
    'FLAG ACCESSORIES',
    'FLAGS',
    'FLASHLIGHTS',
    'FLASKS',
    'FLYING SAUCERS & DISCS',
    'FOAM NOVELTIES',
    'FOOD GIFTS',
    'FORKS & SPOONSFRAMES',
    'FUNNELS',
    'FURNITURE',
    'GAME PARTS',
    'GAMES',
    'GARMENT BAGS',
    'GIFT BASKETS & SETS',
    'GIFT WRAP',
    'GLASSES-DRINKING',
    'GLOBES',
    'GLOVES',
    'GLOW PRODUCTS',
    'GOLF/POLO SHIRTS',
    'GOLF ACCESSORIES',
    'GOLF PUTTERS',
    'GPS ACCESSORIES',
    'GREETING CARDS',
    'GRIPPERS',
    'GUNS',
    'HANDKERCHIEFS',
    'HEADBANDS',
    'HEADPHONES',
    'HIGHLIGHTERS',
    'HOLDERS',
    'HUMIDIFIERS & DEHUMIDIFIERS',
    'ICE CUBES',
    'ICE SCRAPERS',
    'INFLATABLES',
    'JACKETS',
    'JARSJELLY BEANS',
    'JEWELRY',
    'JOURNALS',
    'KEY CASES',
    'KEY HOLDERS',
    'KITS',
    'KNIVES',
    'LABELS',
    'LAMPS',
    'LANTERNS',
    'LANYARDS',
    'LAPEL PINS',
    'LAPTOP SLEEVES/CASES',
    'LAWN & GARDEN ACCESSORIES',
    'LED PRODUCTS',
    'LENTICULAR PRODUCTS',
    'LETTER OPENERS',
    'LICENSE HOLDERS',
    'LICENSE PLATE HOLDERS',
    'LIGHT UP NOVELTIES',
    'LIGHTS',
    'LINT REMOVERS',
    'LIP BALM',
    'LIQUID MOTION PRODUCTS',
    'LUGGAGE',
    'LUGGAGE SETS',
    'MAGNETS',
    'MAGNIFIERS',
    'MAKE-UP/COSMETICS',
    'MANICURE TOOLSMARKERS',
    'MATCH-FOLDER SPECIALTIES',
    'MATCHES',
    'MATS',
    'MEASURING DEVICES',
    'MEMO HOLDERS',
    'MEMO PAD & PAPER HOLDERS',
    'MEMO PADS',
    'MEMO PAPER DISPENSERS',
    'MESSENGER BAGS',
    'MICROFIBER CLOTHS',
    'MINIATURES & REPLICAS',
    'MIRRORS',
    'MOBILE ACCESSORIES',
    'MOBILE SLEEVES/CASES',
    'MONEY CLIPS',
    'MOUSE PADS',
    'MUGS & STEINS',
    'MUSICAL SPECIALTIES',
    'NAMEPLATES',
    'NAPKINS',
    'NOISEMAKERS',
    'NOTEBOOKS',
    'OFFICE EQUIPMENT',
    'OFFICE SUPPLIES',
    'OPENERS',
    'ORGANIZERS',
    'ORNAMENTS',
    'PACKAGING BOXES',
    'PAD FOLIOSPADS',
    'PAINT',
    'PALMS/PDA ACCESSORIES',
    'PAPER',
    'PAPER SPECIALTIES',
    'PAPERWEIGHTS',
    'PATCHES',
    'PEDOMETERS',
    'PEN & PENCIL HOLDERS',
    'PENCILS',
    'PENS',
    'PERFORMANCE APPAREL',
    'PET ITEMS',
    'PHONE ACCESSORIES',
    'PHOTO CUBES',
    'PHOTOGRAPHY/DARKROOM ACCESSORIES',
    'PHYSICAL & THERAPEUTIC AIDS',
    'PICKS',
    'PICTURE FRAMES',
    'PICTURES & PAINTINGS',
    'PILL BOXES',
    'PITCHER SETS',
    'PLANTERS',
    'PLANTS',
    'PLATES',
    'PLAYING CARDS',
    'POCKET CONTENTS HOLDERS',
    'POINTERS',
    'POKER CHIPS',
    'POLISHERSPOPCORN',
    'POUCHES',
    'PRE PAID CARDS',
    'PRINTING CONSUMABLES',
    'PROMOTIONS',
    'PROTECTORS',
    'PUPPETS',
    'PURSES',
    'PUZZLES & TRICKS',
    'RACKS & HOLDERS',
    'RADIOS',
    'RAINWEAR',
    'RAZORS',
    'REFLECTORS',
    'RIBBON',
    'RINGS',
    'RULERS',
    'SCALES',
    'SCARVES',
    'SCENTED PRODUCTS',
    'SCISSORS & SHEARS',
    'SCRAPERS',
    'SEEDS',
    'SERVERS',
    'SEWING KITS',
    'SHAKERS',
    'SHARPENERS',
    'SHAVING ACCESSORIES',
    'SHELVES',
    'SHIRTSSHOELACES',
    'SHOES',
    'SHOPPING BAGS',
    'SHOT GLASSES',
    'SIGNS & DISPLAYS',
    'SNOW DOMES',
    'SOAP',
    'SOCKS',
    'SPEAKERS',
    'SPECIALTIES CUSTOM MADE',
    'SPORTS EQUIPMENT & ACCESS.',
    'STAMPS',
    'STANDS',
    'STENCILS & TEMPLATES',
    'STOCKINGS',
    'STOOLS',
    'STOPWATCHES',
    'STRAPS',
    'STRESS RELIEVERS',
    'STUFFED ANIMALS & TOYS',
    'STUFFED ANIMALS & TOYS ACCESSORIES',
    'SUN CATCHERS',
    'SUN PROTECTION PRODUCTS',
    'SUNGLASSES',
    'SUNSCREEN',
    'SUNTAN LOTIONS',
    'SUSPENDERS',
    'SWEAT SHIRTS',
    'T-SHIRTS',
    'TAGSTAPE DISPENSERS',
    'TAPE FLAGS',
    'TAPE MEASURES',
    'TASSELS',
    'TATTOOS',
    'TEA',
    'TEA OR COFFEE SETS',
    'TENNIS BALLS',
    'THERMOMETERS',
    'TIES',
    'TIMERS',
    'TIRE GAUGES',
    'TOOL BOXES',
    'TOOL KITS',
    'TOOLS-HARDWARE',
    'TOOLS-KITCHEN',
    'TOTE BAGS',
    'TOWELETTES',
    'TOWELS',
    'TOYS',
    'TRADE MAGAZINES',
    'TRADE SHOW DISPLAYS',
    'TRADING CARDS',
    'TRAVEL AMENITIES',
    'TRAVEL PROGRAMS',
    'TRAVEL MUGS/CUPS',
    'TRAYS',
    'UMBRELLAS',
    'UNIFORMS',
    'USB/FLASH DRIVESVASES',
    'VESTS',
    'VIDEO EQUIPMENT',
    'WALLETS',
    'WANDS & SCEPTERS',
    'WARMERS',
    'WATCHES',
    'WATER',
    'WHISTLES',
    'WINE & CHAMPAGNE',
    'WINE ACCESSORIES',
    'WINE GLASSES',
    'WRIST RESTS',
    'WRISTBANDS',
    'WRITE ON-WIPE OFF BOARDS',
    'YO-YO\'S',
    'ZIPPER PULLERS',
    'NON WEB PRODUCTS'
];