import React from 'react';
import ListItem from './backend_scheduler_list_item';
const backend_scheduler_list = (props)=>{
    return(
        <table className={'table table-bordered'}>
            <thead>
                <tr>
                    <th>NAME</th>
                    <th>DESCRIPTION</th>
                    <th>FREQUENCY in HOUR</th>
                    <th>Time</th>
                    <th>ACTION</th>
                </tr>
            </thead>
            <tbody>
            {props.list instanceof Map? props.list.map((item,index)=><ListItem item={item} key={index} index={index} updateScheduler={props.updateScheduler}
                                                    backEndSchedulerTimeOnChange={props.backEndSchedulerTimeOnChange}   backEndSchedulerHourOnChange={props.backEndSchedulerHourOnChange}/>):<div></div>}
            </tbody>
        </table>
    )
};
export default backend_scheduler_list;