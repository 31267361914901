import React from 'react';
import CheckBox from '../../bootstrapComponent/checkBox';
import SelectInput from '../../bootstrapComponent/selectInput';
import TextArea from '../../bootstrapComponent/textArea';
import OnlineCategory from './onlinCategory';
const UpdateSubFamilyModal = ({id,families,subFamilies,updateSubFamily,onChange, updateItem, Activate,
                                  DeActivate,translate,htsCa,htsUs,handleCategory,
                                  sageCategories,sageThemes,ESPCategories,uDuCatCategories,asiCategories,asiIndustries})=>{

    const ashlinSubFamilyCode = subFamilies.map(i=>i.subFamilyCode);
    return(
        <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="upDateSubFamilyModalLabel"
             aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h3 className="modal-title" id="upDateSubFamilyModalLabel" style={{textAlign:'center !important'}}>Update A Sub-Family</h3>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <SelectInput  id={'update_ashlin_sub_family'} label={'Sub-Family Code'} name={'subFamilyCode'} width={'450px'}
                                      style={{fontWeight:'bold'}}     value={ashlinSubFamilyCode}    onChange={(e)=>onChange(e,'updateSubFamily')}/>
                        <SelectInput  id={'add_ashlin_sub_families'} label={'Associated Family'} name={'pickedSubFamily'} width={'450px'}
                                      style={{fontWeight:'bold'}}   defaultValue={updateSubFamily.pickedFamily}   value={families}    onChange={(e)=>onChange(e,'updateSubFamily')}/>
                        <hr/>
                        <div className={'row'} >
                            <div className={'col-2'}>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>ENGLISH</h5>
                            </div>
                            <div className={'col-2'}>
                                <button className={'btn-info'} style={{fontSize:'15px !important'}} onClick={()=>translate('updateSubFamily')}>TRANSLATE</button>
                            </div>
                            <div className={'col-4'}>
                                <h5 style={{textAlign:'center'}}>FRENCH</h5>
                            </div>
                        </div>
                        <div className={'row'} >
                            <div className={'col-2'}>
                                <h5 style={{textAlign:'center'}}>Short Description</h5>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%'}} value={updateSubFamily.shortENG}
                                          onChange={(e)=>onChange(e,'updateSubFamily')} name={'shortENG'}>
                                </textarea>
                            </div>
                            <div className={'col-2'}>
                            </div>
                            <div className={'col-4'}>
                                <textarea style={{width:'100%'}} value={updateSubFamily.shortFR}
                                          onChange={(e)=>onChange(e,'updateSubFamily')} name={'shortFR'}>
                                </textarea>
                            </div>
                        </div>
                        <hr/>
                        <TextArea label={'Meta Title'} value={updateSubFamily.metaTitle}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'metaTitle'} />
                        <TextArea label={'Meta Description'} value={updateSubFamily.metaDescription}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'metaDescription'} />
                        <TextArea label={'General Keywords'} value={updateSubFamily.generalKeywords}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'generalKeywords'} />
                        <div className='row'>
                            <div className='col-4' style={{margin:'auto',fontWeight:'bold',textAlign:'right'}}>
                                <h5 htmlFor='add_sub_family_gender'>Gender</h5>
                            </div>
                            <div className='col-8' style={{marginBottom:'7px'}}>
                                <select name={'gender'} onChange={(e)=>onChange(e,'updateSubFamily')} className="form-control" id='add_sub_family_gender' value={updateSubFamily.gender} >
                                    <option value="Men's">Men's</option>
                                    <option value="Women's">Women's</option>
                                    <option value='UNISEX'>UNISEX</option>
                                </select>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right'}}>
                                <h6>Canadian HTS</h6>
                            </div>
                            <div className={'col-8'}>
                                <select style={{width:'48%'}} onChange={(e)=>onChange(e,'updateSubFamily',htsCa)} name={'htsCa'} value={updateSubFamily.htsCaCode}>
                                    <option value=''>-- select an option --</option>
                                    {htsCa.map(i=>{
                                        return(
                                            <option value={i.id} key={i.id}>
                                                {i.id}
                                            </option>
                                        )
                                    })}
                                </select>
                                <input style={{width:'48%',marginLeft:'4%'}}   value={updateSubFamily.htsCaValue} readOnly/>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right'}}>
                                <h6>US HTS</h6>
                            </div>
                            <div className={'col-8'}>
                                <select style={{width:'48%'}} onChange={(e)=>onChange(e,'updateSubFamily',htsUs)} name={'htsUs'} value={updateSubFamily.htsUsCode}>
                                    <option value=''>-- select an option --</option>
                                    {htsUs.map(i=>{
                                        return(
                                            <option value={i.id} key={i.id}>
                                                {i.id}
                                            </option>
                                        )
                                    })}
                                </select>
                                <input style={{width:'48%',marginLeft:'4%'}}   value={updateSubFamily.htsUsValue} readOnly/>
                            </div>
                        </div>
                        <br/>
                        <div className={'row container'}>
                            <div className={'col-4'}>
                                <div className={'row'}>
                                    <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Pricing Tier</h6>
                                    <input className={'col'} style={{}} name={'pricingTier'}  value={updateSubFamily.pricingTier} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                                </div>
                            </div>
                            <div className={'col-4'}>
                                <div className={'row'}>
                                    <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Reorder Qty</h6>
                                    <input className={'col'} style={{}} name={'ReorderQty'}  value={updateSubFamily.ReorderQty} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                                </div>
                            </div>
                            <div className={'col-4'}>
                                <div className={'row'}>
                                    <h6 className={'col'} style={{textAlign:'right',margin:'auto 0'}}>Reorder Lvl</h6>
                                    <input className={'col'} style={{}} name={'ReorderLevel'}  value={updateSubFamily.ReorderLevel} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className={'row justify-content-center'}>
                            <CheckBox  value={updateSubFamily.active === 1}  id={'update-sub-family-active'}
                                       onChange={(e)=>onChange(e,'updateSubFamily')} name={'active'} label={'Is this Sub-Family Active'}/>
                        </div>
                        <hr/>
                        <TextArea label={'Amazon Keywords 1'} value={updateSubFamily.amazonKeywords1} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'amazonKeywords1'} />
                        <TextArea label={'Amazon Keywords 2'} value={updateSubFamily.amazonKeywords2} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'amazonKeywords2'} />
                        <TextArea label={'Amazon Keywords 3'} value={updateSubFamily.amazonKeywords3} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'amazonKeywords3'} />
                        <TextArea label={'Amazon Keywords 4'} value={updateSubFamily.amazonKeywords4} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'amazonKeywords4'} />
                        <TextArea label={'Amazon Keywords 5'} value={updateSubFamily.amazonKeywords5} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'amazonKeywords5'} />

                        <div className={'row'}>
                            <div className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>
                                <h6>Amazon.ca Browse Node</h6>
                            </div>
                            <div className={'col-8'}>
                                <input style={{width:'48%'}} name={'amazonCaNode1'}  value={updateSubFamily.amazonCaNode1} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                                <input style={{width:'48%',marginLeft:'4%'}} name={'amazonCaNode2'}  value={updateSubFamily.amazonCaNode2} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                            </div>
                        </div>

                        <div className={'row'} style={{marginTop:'5px'}}>
                            <div className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>
                                <h6>Amazon.com Browse Node</h6>
                            </div>
                            <div className={'col-8'} style={{marginBottom:'5px'}}>
                                <input style={{width:'48%'}} name={'amazonComNode1'}  value={updateSubFamily.amazonComNode1} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                                <input style={{width:'48%',marginLeft:'4%'}} name={'amazonComNode2'}  value={updateSubFamily.amazonComNode2} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                            </div>
                        </div>
                        <TextArea label={'eBay Category ID'} value={updateSubFamily.eBayCategoryID} rows={'1'}
                                  onChange={(e)=>onChange(e,'updateSubFamily')} name={'eBayCategoryID'} />
                        <div className={'row'} >
                            <div className={'col-4'} style={{textAlign:'right',margin:'auto 0'}}>
                                <h5>staples Class ID/Name</h5>
                            </div>
                            <div className={'col-8'}>
                                <input style={{width:'48%'}} name={'staplesClassID'}  value={updateSubFamily.staplesClassID} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                                <input style={{width:'48%',marginLeft:'4%'}} name={'staplesClassName'}  value={updateSubFamily.staplesClassName} onChange={(e)=>onChange(e,'updateSubFamily')}/>
                            </div>
                        </div>
                        <hr/>
                        <OnlineCategory label={'SAGE[CATEGORY]'} categories={sageCategories}
                                        value={updateSubFamily.sageCategory}
                                        name={'sageCategory'} handleCategory={(a,b)=>handleCategory(a,b,'updateSubFamily')} />
                        <OnlineCategory label={'sageTheme'} categories={sageThemes}
                                        value={updateSubFamily.sageTheme}
                                        name={'sageTheme'} handleCategory={(a,b)=>handleCategory(a,b,'updateSubFamily')} />
                        <OnlineCategory label={'esp'} categories={ESPCategories}
                                        value={updateSubFamily.esp}
                                        name={'esp'} handleCategory={(a,b)=>handleCategory(a,b,'updateSubFamily')} />
                        <OnlineCategory label={'uduCate'} categories={uDuCatCategories}
                                        value={updateSubFamily.uduCate}
                                        name={'uduCate'} handleCategory={(a,b)=>handleCategory(a,b,'updateSubFamily')} />
                        <OnlineCategory label={'asiTheme'} categories={asiCategories}
                                        value={updateSubFamily.asiTheme}
                                        name={'asiTheme'} handleCategory={(a,b)=>handleCategory(a,b,'updateSubFamily')} />
                        <OnlineCategory label={'asiIndustry'} categories={asiIndustries}
                                        value={updateSubFamily.asiIndustry}
                                        name={'asiIndustry'} handleCategory={(a,b)=>handleCategory(a,b,'updateSubFamily')} />
                        <br/>
                        <div className={'row'}>
                            <button type="button" className="btn btn-primary btn-block" onClick={(e)=>updateItem(e,'updateSubFamily')}>Update Sub-Family</button>
                        </div>
                        <br/>
                        {/*<br/><br/>*/}
                        {/*<div className={'row modal-footer'}>*/}
                            {/*<button type="button" className="btn btn-danger col" onClick={(e)=>DeActivate(e,'updateSubFamily','subFamilyCode')} >Deactivate Sub-Family</button>*/}
                            {/*<button type="button" className="btn btn-success col" onClick={(e)=>Activate(e,'updateSubFamily','subFamilyCode')}>Activate Sub-Family</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
    )
};
export default UpdateSubFamilyModal;