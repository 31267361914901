import React from 'react';
const ManualOrderConfirmationModal = (props)=>{
    return(
        <div className="modal fade" id="ManualOrderConfirmationModal" tabIndex="-1" role="dialog"
             aria-labelledby="ManualOrderConfirmationModalTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered " role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title text-center" id="ManualOrderConfirmationModalTitle">Order Confirmation Detail</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className={'row'}>
                            <div className={'col'}>
                                <h2>Ship To:</h2>
                                <div>{props.Name}</div>
                                <div>{props.AddressLine1}</div>
                                <div>{props.AddressLine2}</div>
                                <div>{props.AddressLine3}</div>
                                <div>{props.City}, {props.State}, {props.CountryCode} {props.Zip}</div>
                            </div>
                            <div className={'col'}>
                                <h2>WareHouse & Carrier</h2>
                                <div>WareHouse: {props.WarehouseCode===14?"Lean Supply":""}</div>
                                <div>WareHouse: {props. Priority ===""?"Normal":"Rush"}</div>
                                {
                                    !props.useCustomized&&
                                    <div>
                                        {props.ServiceType}
                                    </div>
                                }
                                {
                                    props.useCustomized&&
                                    <div>
                                        {props.ServiceCode.label}
                                    </div>
                                }

                            </div>
                        </div>
                        <hr/>
                        <div>
                            <ul>
                                {props.OrderDetail.map((ol,i)=>{
                                    return <ol key={i}>{ol.ItemCode} - {ol.ItemName}  &nbsp; <span style={{fontWeight:'bold'}}>X {ol.ItemQty}</span></ol>
                                })}
                            </ul>
                        </div>
                        <p>Comment: {props.Comment}</p>
                    </div>
                    <div className="modal-footer row">
                       <button type="button" className="btn btn-secondary col" data-dismiss="modal" >Go Back</button>
                       <button type="button" className="btn btn-danger col"  onClick={props.submitOrder}>Send Order</button>
                    </div>
                </div>
            </div>
        </div>
    )
    //data-dismiss="modal"
};
export default ManualOrderConfirmationModal;